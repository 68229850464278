import React, { useRef } from 'react';
import { nl2p } from '~/utils';
import { FormattedMessage } from 'react-intl';
import { useIntersection } from 'react-use';

export default ({ record }) => {
  const commentBodyRef = useRef(null);
  const commentBodyInnerRef = useRef(null);

  const intersection = useIntersection(commentBodyInnerRef, {
    root: commentBodyRef.current,
    rootMargin: '100% 0% 0% 0%',
    threshold: 1,
  });

  if (!('note' in record && record.note)) return null;
  return (
    <div
      className={`comments ${
        intersection?.isIntersecting ? '' : 'has-overflow'
      }`}
    >
      <div className="q-and-a pt-4 pb-0">
        <h2>
          <FormattedMessage
            id="orderdetails-comment-header"
            description="Header for Comment"
            defaultMessage="Comments"
          />
        </h2>

        <div
          className="loading-details pt-2 pb-2 comment-body"
          ref={commentBodyRef}
        >
          <div ref={commentBodyInnerRef}>{nl2p(record.note)}</div>
        </div>
      </div>
    </div>
  );
};
