import * as R from 'ramda';
import { Schema } from 'struct';
import * as Api from '~/fetch';
import * as Selector from '~/selectors';
import { IBanner } from '~/store/types';
import { success, error, Result } from '~/services/utils/match';
import moment, { Moment } from 'moment-timezone';

export type Return<T> = Promise<Result<T>>;

const getSchema = R.propOr(null, 'fields');
const getBanner = R.propOr(null, 'banner');
const getBanners = R.propOr(null, 'banners');

export async function schema(): Return<Schema> {
  return await Api.utils.processResponse(await Api.banners.schema(), {
    async 200(res) {
      const data = await res.json();
      const schema = getSchema(data);
      return success(schema);
    },
    async default(res) {
      return error(Selector.form.errors(res.json()));
    },
  });
}

export async function editSchema(banner: IBanner): Return<Schema> {
  return await Api.utils.processResponse(await Api.banners.editSchema(banner), {
    async 200(res) {
      const data = await res.json();
      const schema = getSchema(data);
      return success(schema);
    },
    async default(res) {
      return error(Selector.form.errors(res.json()));
    },
  });
}

export async function add(payload: IBanner): Return<IBanner> {
  const result = await Api.banners.add(payload);
  return await Api.utils.processResponse(result, {
    async 200(res) {
      const data = await res.json();
      return success(getBanner(data));
    },
    async 400(res) {
      const data = await res.json();
      return error(Selector.form.errors(data));
    },
    async default() {
      return error();
    },
  });
}

type IList = {
  solutionId?: string;
};
export async function list(options?: IList | void): Return<IBanner[]> {
  return Api.utils.processResponse(await Api.banners.list(options), {
    async 200(res) {
      const data = await res.json();
      return success(getBanners(data));
    },
    async default() {
      return error();
    },
  });
}

type IPollList = {
  solutionId?: string;
  since: Moment;
};
export async function pollList(options: IPollList): Return<IBanner[]> {
  return Api.utils.processResponse(await Api.banners.pollList(options), {
    async 200(res) {
      const headerDate = res.headers.get('Date');
      if (!headerDate) return error();
      const since = moment(headerDate);
      const data = await res.json();
      return success(getBanners(data), since);
    },
    async default() {
      return error();
    },
  });
}

export async function remove(item: Pick<IBanner, 'id'>) {
  const res = await Api.banners.remove(item);
  return res.ok ? success(item) : error();
}

export async function setPublished(
  item: Pick<IBanner, 'id' | 'ispublished'>
): Return<IBanner> {
  return Api.utils.processResponse(await Api.banners.updateIsPublished(item), {
    async 200(res) {
      return success(getBanner(await res.json()));
    },
    async 400(res) {
      return error(Selector.form.errors(await res.json()));
    },
    async default() {
      return error();
    },
  });
}

export async function update(item: IBanner): Return<IBanner> {
  return Api.utils.processResponse(await Api.banners.update(item), {
    async 200(res) {
      return success(getBanner(await res.json()));
    },
    async 400(res) {
      return error(Selector.form.errors(await res.json()));
    },
    async default() {
      return error();
    },
  });
}
