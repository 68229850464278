import React, { useState, useEffect } from 'react';
import { Switch, Table, Loading } from 'Components/nui';
import { useStoreState } from 'easy-peasy';
import * as R from 'ramda';
import { api } from '~/api';
import { toast } from 'react-toastify';
import { useSharedCustomer } from '..';
import { inArrayIf, canEditCustomer, invertDivisionType } from '~/utils';

const List = ({ preselected, warehouses, customer }) => {
  const [selected, setSelected] = useState(() => preselected.map(x => x.id));
  const editable = useStoreState(canEditCustomer(customer));
  const save = async newSelected => {
    toast('Warehouse settings are saved', {
      type: 'success',
    });

    const data = {
      warehouses: newSelected.map(id => ({ id })),
    };

    try {
      await api.putData(`/customers/${customer.id}/warehouses`, data);
    } catch (err) {
      toast.error(`Something went wrong`);
    }
  };

  const onChange = (c_id, checked) => {
    const newData = checked
      ? R.append(c_id, selected)
      : R.without([c_id], selected);

    setSelected(newData);
    save(newData);
  };

  const columns = [
    ...inArrayIf(editable, {
      title: 'Active',
      key: 'switcher',
      className: 'col-switcher',
      render: (text, record, index) => (
        <div className="td-content">
          <Switch
            checked={selected.includes(record.id)}
            onChange={checked => onChange(record.id, checked)}
          />
        </div>
      ),
    }),
    {
      title: 'Location',
      key: 'location',
      className: 'col-desc',
      render: (text, record, index) => (
        <div className="td-content">{record.desc}</div>
      ),
    },
    {
      title: 'Division',
      key: 'division',
      className: 'col-division',
      render: (text, record, index) => (
        <div className="td-content">
          {record.division.name} ({record.division.shortcode})
        </div>
      ),
    },
  ];

  return (
    <div className="full-page-content">
      <div className="generic-table light">
        <Table className="table-wrapper" columns={columns} data={warehouses} />
      </div>
    </div>
  );
};

export default () => {
  const solution = useStoreState(state => state.auth.solution);
  const solutionDivisions = useStoreState(
    state => state.auth.solutionDivisions
  );

  const [customer] = useSharedCustomer();

  const [preselected, setPreselected] = useState(null);
  const [warehouses, setWarehouses] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      let data = [];
      customer.links
        .map(item => item[invertDivisionType(customer.divisiontype)])
        .forEach(owner => {
          const division = R.find(R.propEq('id', owner.id))(solutionDivisions);
          data = R.concat(
            data,
            division.warehouses.map(item => ({ ...item, division }))
          );
        });

      setWarehouses(R.sortBy(R.compose(R.toLower, R.path(['desc'])))(data));
    };
    if (solution && customer) fetch();
  }, [api, solution, customer]);

  useEffect(() => {
    try {
      const fetch = async () => {
        const response = await api.getData2(
          `/customers/${customer.id}/warehouses`
        );
        setPreselected(R.flatten(R.values(response.warehouses)));
      };
      if (customer) fetch();
    } catch (error) {
      toast.error(
        'Somewthing went wrong while fetching warehouses. Please try again.'
      );
    }
  }, [customer]);

  if (preselected === null || warehouses === null)
    return <Loading size="medium" />;

  return (
    <List
      warehouses={warehouses}
      preselected={preselected}
      customer={customer}
    />
  );
};
