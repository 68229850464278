import React from 'react';
import moment from 'moment-timezone';
import { useAsync } from 'react-use';
import { api } from '~/api';
import { Order } from '~/models';
import { useStoreState } from 'easy-peasy';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { getAddrInfo, routesIncluded, getAddrChoice } from '~/utils';

const EtaLabel = () => (
  <h4>
    <FormattedMessage
      id="marketrow-locationalert-field-eta-label"
      description="Label for `ETA` field on locationalert in Marketrow"
      defaultMessage="ETA"
    />
  </h4>
);

const SelectedLocationHeading = () => (
  <h3>
    <FormattedMessage
      id="marketrow-locationalert-header"
      description="Header for locationalert in Marketrow"
      defaultMessage="Selected location"
    />
  </h3>
);

export default ({ form, order, price, setPrice, schema }) => {
  const [loadingPrice, setLoadingPrice] = useState(false);
  const toetd = order?.etd?.to;
  const etaFreightTypes = ['port', 'zone'];

  const toaddr = form.getFieldValue('toaddr');
  const fromaddr = form.getFieldValue('fromaddr');
  const solution = useStoreState(state => state.auth.solution);

  useAsync(async () => {
    if (!!solution.freight && (toaddr || fromaddr)) {
      setLoadingPrice(true);
      const data = await api.getData(
        {
          type: 'orders',
          id: order.id,
          getResult: x => new Order(x.order),
        },
        { toaddr, fromaddr }
      );
      setLoadingPrice(false);
      setPrice(data.price.val);
    }
  }, [toaddr, fromaddr, solution]);

  if (
    (order.type === 'offer' &&
      !order.index &&
      etaFreightTypes.includes(schema?.toaddr?.freight) &&
      toetd &&
      solution.shippingroutes &&
      schema?.toaddr?.choices &&
      order?.fromaddr?.length) ||
    (order.type !== 'offer' &&
      !order.index &&
      // etaFreightTypes.includes(schema?.fromaddr?.freight) &&
      toetd &&
      solution.shippingroutes &&
      schema?.fromaddr?.choices &&
      order?.toaddr?.length)
  ) {
    const division = form.getFieldValue('division');

    const toaddrInfo = schema.toaddr?.choices
      ? getAddrInfo(toaddr)(getAddrChoice(division)(schema.toaddr.choices))
      : null;
    const fromaddrInfo = schema.fromaddr?.choices
      ? getAddrInfo(fromaddr)(getAddrChoice(division)(schema.fromaddr.choices))
      : null;

    if (
      (order.type === 'offer' && !toaddrInfo) ||
      (order.type !== 'offer' && !fromaddrInfo)
    )
      return (
        <div className="counter-info-box mt-10 mb-10">
          <SelectedLocationHeading />

          <FormattedMessage
            id="marketrow-locationalert-helptext-noLocation"
            description="Helptext for locationalert in Marketrow"
            defaultMessage="No location selected"
          />
        </div>
      );

    const matchingRoutes = solution.shippingroutes.filter(route => {
      let fromFreightIds;
      let toFreightIds;
      if (order.type === 'offer') {
        fromFreightIds = order.fromaddr.map(e => e?.[route.type]?.id);
        toFreightIds = [Object.values(toaddrInfo[2])[0]];
      } else {
        fromFreightIds = [Object.values(fromaddrInfo[2])[0]];
        toFreightIds = order.toaddr.map(e => e?.[route.type]?.id);
      }
      return routesIncluded(fromFreightIds, toFreightIds)(route);
    });

    const sortedRoutes = matchingRoutes.sort(
      (route1, route2) => route1.duration - route2.duration
    );

    return (
      <div className="counter-info-box mt-10 mb-10">
        <SelectedLocationHeading />
        {sortedRoutes.length ? (
          <>
            <div className="half">
              <h4>
                <FormattedMessage
                  id="marketrow-locationalert-field-price-label"
                  description="Label for `Price` field on locationalert in Marketrow"
                  defaultMessage="Price"
                />
              </h4>
              <div className="block">
                <span className="price">
                  {loadingPrice ? (
                    <FormattedMessage
                      id="marketrow-locationalert-field-price-helptext-loading"
                      description="Helptext for `price` field on locationalert in Marketrow when price data is loading"
                      defaultMessage="Loading..."
                    />
                  ) : (
                    price
                  )}
                </span>
                <span className="price-unit">{order.price.unit}</span>
              </div>
            </div>
            {sortedRoutes.map(route => (
              <div key={route.id} className="half">
                <EtaLabel />
                <small>
                  <FormattedMessage
                    id="marketrow-locationalert-field-route-label"
                    description="Label for `Route` field on locationalert in Marketrow"
                    defaultMessage="From {fromAddress} To {toAddress}"
                    values={{
                      fromAddress: route.source.name,
                      toAddress: route.destination.name,
                    }}
                  />
                </small>
                <div>
                  <strong className="etd">
                    {moment(toetd)
                      .add(route.duration, 'day')
                      .format('DD MMM YYYY')}
                  </strong>{' '}
                  <span>
                    <FormattedMessage
                      id="marketrow-locationalert-field-duration-label"
                      description="Label for `duration` field on locationalert in Marketrow"
                      defaultMessage="{duration, plural, one {ETD + # day} other {ETD + # days}}"
                      values={{ duration: route.duration }}
                    />
                  </span>
                </div>
              </div>
            ))}
          </>
        ) : (
          <FormattedMessage
            id="marketrow-locationalert-helptext-no-routes"
            description="Helptext for `No shipping routes found` on locationalert in Marketrow"
            defaultMessage="No shipping routes found"
          />
        )}
      </div>
    );
  }

  return null;
};
