import { useIntl } from 'react-intl';
import { useStoreState } from 'easy-peasy';
import useSortedData from 'Pages/Market/Body/hooks/useSortedData';
import { deduplicateWithMap } from '~/utils';
import * as R from 'ramda';
import { OrderStatus, TradeStatus } from '~/const';
import { capitalize } from '~/utils';

export const useFilterOptions = ({
  commodity,
}: { commodity?: boolean } = {}) => {
  const { formatMessage } = useIntl();
  const solution = useStoreState(state => state.auth.solution);

  const products = commodity
    ? useStoreState(R.pathOr<any[]>([], ['auth', 'commodityFilteredProducts']))
    : useStoreState(state => state.auth.marketplaceProducts(solution.id));

  const tags = useStoreState(state => state.auth.solutionTags);
  const data = useSortedData();

  // group products by owner property if available
  const productOptions = Object.values(
    products.reduce((acc, item) => {
      if (item.owner) {
        if (!acc[item.owner?.shortcode]) {
          acc[item.owner?.shortcode] = {
            value: item.owner?.shortcode,
            label: item.owner?.name,
            children: [],
          };
        }
        acc[item.owner?.shortcode].children.push({
          value: item.id,
          label: item.name,
        });
      } else {
        acc[item.id] = {
          value: item.id,
          label: item.name,
        };
      }
      return acc;
    }, {})
  );

  const groupOptions = tags
    .map(item => ({ value: item.id, label: item.name }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const typeOptions = [
    {
      value: 'offer',
      label: formatMessage({
        id: 'marketplace-filter-field-type-offer',
        defaultMessage: 'Offer',
        description: `Label for 'Offer' in Marketplace filter`,
      }),
      children: [
        {
          value: 'Offer_SFC',
          label: formatMessage({
            id: 'marketplace-filter-field-type-offer-sfc',
            defaultMessage: 'Offer SFC',
            description: `Label for 'Offer SFC' in Marketplace filter`,
          }),
        },
        {
          value: 'Offer_Tradeable',
          label: formatMessage({
            id: 'marketplace-filter-field-type-offer-tradeable',
            defaultMessage: 'Offer Tradeable',
            description: `Label for 'Offer Tradeable' in Marketplace filter`,
          }),
        },
      ],
    },
    {
      value: 'bid',
      label: formatMessage({
        id: 'marketplace-filter-field-type-bid',
        defaultMessage: 'Bid',
        description: `Label for 'Bid' in Marketplace filter`,
      }),
      children: [
        {
          value: 'Bid_SFC',
          label: formatMessage({
            id: 'marketplace-filter-field-type-bid-sfc',
            defaultMessage: 'Bid SFC',
            description: `Label for 'Bid SFC' in Marketplace filter`,
          }),
        },
        {
          value: 'Bid_Tradeable',
          label: formatMessage({
            id: 'marketplace-filter-field-type-bid-tradeable',
            defaultMessage: 'Bid Tradeable',
            description: `Label for 'Bid Tradeable' in Marketplace filter`,
          }),
        },
      ],
    },
  ];

  const etdOptions = [
    {
      value: 'thisWeek',
      label: formatMessage({
        id: 'marketplace-filter-field-etd-this-week',
        defaultMessage: 'This week',
        description: `Label for 'This week' in Marketplace filter`,
      }),
    },
    {
      value: 'thisMonth',
      label: formatMessage({
        id: 'marketplace-filter-field-etd-this-month',
        defaultMessage: 'This month',
        description: `Label for 'This month' in Marketplace filter`,
      }),
    },
    {
      value: 'nextMonth',
      label: formatMessage({
        id: 'marketplace-filter-field-etd-next-Month',
        defaultMessage: 'Next Month',
        description: `Label for 'Next month' in Marketplace filter`,
      }),
    },
    {
      value: 'afterNextMonth',
      label: formatMessage({
        id: 'marketplace-filter-field-etd-after-next-month',
        defaultMessage: 'After next month',
        description: `Label for 'After next month' in Marketplace filter`,
      }),
    },
    {
      value: 'custom',
      label: formatMessage({
        id: 'marketplace-filter-field-custom-range',
        defaultMessage: 'Custom range',
        description: `Label for 'Custom' in Marketplace filter`,
      }),
      hasSubField: true,
    },
  ];

  const publishedDateOptions = [
    {
      value: 'today',
      label: formatMessage({
        id: 'marketplace-filter-field-published-today',
        defaultMessage: 'Today',
        description: `Label for 'Today' in Marketplace filter`,
      }),
    },
    {
      value: 'last24h',
      label: formatMessage({
        id: 'marketplace-filter-field-published-last-24h',
        defaultMessage: 'Last 24 hours',
        description: `Label for 'Last 24 hours' in Marketplace filter`,
      }),
    },
    {
      value: 'last7d',
      label: formatMessage({
        id: 'marketplace-filter-field-published-last-7d',
        defaultMessage: 'Last 7 days',
        description: `Label for 'Last 7 days' in Marketplace filter`,
      }),
    },
    {
      value: 'lastMonth',
      label: formatMessage({
        id: 'marketplace-filter-field-published-last-month',
        defaultMessage: 'Last month',
        description: `Label for 'Last month' in Marketplace filter`,
      }),
    },
    {
      value: 'custom',
      label: formatMessage({
        id: 'marketplace-filter-field-custom',
        defaultMessage: 'Custom range',
        description: `Label for 'Custom' in Marketplace filter`,
      }),
      hasSubField: true,
    },
  ];

  const stateOptions = [
    {
      value: 'tradeable',
      label: formatMessage({
        id: 'marketplace-filter-field-state-tradeable',
        defaultMessage: 'Tradeable',
        description: `Label for 'Tradeable' in Marketplace filter`,
      }),
    },
    {
      value: 'untradeable',
      label: formatMessage({
        id: 'marketplace-filter-field-state-untradeable',
        defaultMessage: 'Untradeable',
        description: `Label for 'Untradeable' in Marketplace filter`,
      }),
    },
    {
      value: 'working',
      label: formatMessage({
        id: 'marketplace-filter-field-state-working',
        defaultMessage: 'Working',
        description: `Label for 'Working' in Marketplace filter`,
      }),
    },
    {
      value: 'viewOnly',
      label: formatMessage({
        id: 'marketplace-filter-field-state-view-only',
        defaultMessage: 'View only',
        description: `Label for 'View only' in Marketplace filter`,
      }),
    },
    {
      value: 'sfcTradeable',
      label: formatMessage({
        id: 'marketplace-filter-field-state-sfc-tradeable-only',
        defaultMessage: 'Sfc tradeable',
        description: `Label for 'Sfc tradeable' in Marketplace filter`,
      }),
    },
  ];

  const statusOptions = OrderStatus.map(s => ({
    value: s,
    label: formatMessage({
      id: `marketplace-filter-field-status-${s}`,
      defaultMessage: capitalize(s),
      description: `Label for '${capitalize(s)}' in Marketplace filter`,
    }),
  }));

  const isSellOptions = [
    {
      value: 0,
      label: formatMessage({
        id: 'filter-field-is-sell-bid',
        defaultMessage: 'Bid',
        description: `Label for 'Bid' in filters`,
      }),
    },
    {
      value: 1,
      label: formatMessage({
        id: 'filter-field-is-sell-offer',
        defaultMessage: 'Offer',
        description: `Label for 'Offer' in filters`,
      }),
    },
  ];

  const originOptions = data
    ? deduplicateWithMap(
        data?.flatMap(item => item.origins ?? []),
        'name'
      )
        .map(x => ({ value: x.id, label: x.name }))
        .sort((a, b) => a.label.localeCompare(b.label))
    : [];

  const tradeStatusOptions = TradeStatus.map(s => ({
    value: s,
    label: formatMessage({
      id: `filter-field-trade-status-${s}`,
      defaultMessage: capitalize(s),
      description: `Label for '${capitalize(s)}' in filter`,
    }),
  }));

  return {
    productOptions,
    groupOptions,
    typeOptions,
    etdOptions,
    publishedDateOptions,
    stateOptions,
    statusOptions,
    originOptions,
    isSellOptions,
    tradeStatusOptions,
  };
};
