import React, { useContext } from 'react';
import classnames from 'classnames';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { Button, Tooltip, Alert } from 'Components/nui';
import * as R from 'ramda';
import { PinnedAccordion } from 'Components/nui/Accordion';
import { useToggle } from 'react-use';

interface IStep {
  id: string;
  url?: string;
  path?: string | string[];
  exact?: boolean;
  title?: React.ReactNode;
  desc?: React.ReactNode;
  summary?: boolean;
  className?: string;
  render?: () => React.ReactNode;
  onSelect?: () => void;
  disabled?: boolean;
  errors?: string[];
  completed?: boolean;
  numbered?: boolean;
}

interface IContext {
  summary: boolean;
  active?: IStep;
  steps: IStep[];
  showSummary: boolean;
  toggleSummary(value?: boolean): void;
}

const WizardContext = React.createContext<IContext>({
  summary: true,
  active: { id: '' },
  steps: [],
  showSummary: false,
  toggleSummary() {},
});

const Step = ({
  id,
  title,
  desc,
  url,
  className,
  onSelect = () => {},
  disabled = false,
  errors = [],
  completed = false,
  numbered = true,
}: IStep) => {
  const { active } = useContext(WizardContext);
  const incomplete = !!errors.length;

  const stepType = numbered ? 'wizard-step' : 'wizard-step-final';

  return (
    <li
      className={classnames(stepType, className, {
        active: active?.id === id,
        disabled,
        incomplete,
        completed,
      })}
    >
      <Tooltip
        title={
          errors.length > 1
            ? `This step has ${errors.length} issues that require attention.`
            : errors[0]
        }
      >
        <h2>
          {disabled ? (
            title
          ) : url ? (
            <Link to={url}>{title}</Link>
          ) : (
            <Button
              type="buttonlink"
              onClick={() => {
                onSelect();
              }}
            >
              {title}
            </Button>
          )}
        </h2>
      </Tooltip>
      <p className="description">{desc}</p>
    </li>
  );
};

interface IWizard {
  className?: string;
  children?: React.ReactNode;
  steps: IStep[];
}
export const Wizard = ({ className, steps = [], children }: IWizard) => {
  const [showSummary, toggleSummary] = useToggle(false);

  const { pathname } = useLocation();
  const active = steps.find(
    step =>
      !!matchPath(pathname, { path: step.path || step.url, exact: step.exact })
  ) || { id: '' };

  const { summary = true } = active || {};
  const classes = { 'showing-summary': showSummary };

  const state = { summary, active, steps, showSummary, toggleSummary };

  return (
    <WizardContext.Provider value={state}>
      <div
        className={classnames(
          'wizard multiple has-summary',
          className,
          classes
        )}
      >
        <div className="wizard-steps">
          <WizardStepper
            type="reverse"
            className="mobile-stepper show-for-small-only"
          />
          <ul>
            {steps.map(step => (
              <Step key={step.id} {...step} />
            ))}
          </ul>
        </div>
        {children}
      </div>
    </WizardContext.Provider>
  );
};

interface IWizardStepper {
  type: 'reverse' | 'buttonlink';
  className?: string;
}
export const WizardStepper = ({ type, className }: IWizardStepper) => {
  const fake: IStep = { id: '' };
  const {
    active = fake,
    steps = [],
    summary,
    //showSummary,
    toggleSummary,
  } = useContext(WizardContext);
  const index = R.findIndex(R.propEq('id', active.id), steps) + 1;
  const total = R.reject(R.propEq('numbered', false), steps as any[]).length;

  const prev = R.pathOr(
    fake,
    [R.findIndex(R.propEq('id', active.id), steps) - 1],
    steps
  );
  const next = R.pathOr(
    fake,
    [R.findIndex(R.propEq('id', active.id), steps) + 1],
    steps
  );

  return (
    <div className={className}>
      {summary && (
        <Button
          className="show-summary nui-beveled"
          type={type}
          size="small"
          title="Show summary"
          onClick={() => {
            toggleSummary();
          }}
        >
          Summary
        </Button>
      )}
      <Link
        to={prev.url || ''}
        className={classnames('icon-angle-left prev', {
          disabled: !prev.url || prev.disabled,
        })}
      />
      {index > total ? (
        <span className="show-for-small-only">Summary</span>
      ) : (
        <span>
          Step <strong>{index}</strong> / <strong>{total}</strong>
        </span>
      )}
      <Link
        to={next.url || ''}
        className={classnames('icon-angle-right next', {
          disabled: !next.url || next.disabled,
        })}
      />
    </div>
  );
};

interface IContent {
  children?: React.ReactNode;
}
export const Content = ({ children }: IContent) => {
  const { summary } = useContext(WizardContext);
  const classes = {
    'no-summary': !summary,
  };

  return (
    <div className={classnames('wizard-content', classes)}>
      <div className="container numbered-sections">
        <WizardStepper
          type="buttonlink"
          className="stepper show-for-medium right"
        />
        {children}
      </div>
    </div>
  );
};

interface ISummary {
  title?: React.ReactNode;
  children?: React.ReactNode;
}
export const Summary = ({ children, title }: ISummary) => {
  const { summary, showSummary, toggleSummary } = useContext(WizardContext);

  if (summary)
    return (
      <div className={classnames('wizard-summary')}>
        <div className="summary-wrapper">
          {title && <h3>{title}</h3>}
          {showSummary && (
            <Button
              type="buttonlink"
              className="closeme"
              title="Hide summary"
              onClick={() => {
                toggleSummary(false);
              }}
            >
              Close
            </Button>
          )}
          {children}
        </div>
      </div>
    );
  return null;
};

export const StepHeading = () => {
  const { active = { id: '' } } = useContext(WizardContext);

  return (
    <>
      <div className="title-block show-for-medium">
        <h2 className="mb-12 mt--7">{active.title}</h2>

        {!!active.errors?.length && (
          <Alert hasicon type="warning">
            <ul className="has-error">
              {active.errors?.map(err => (
                <li key={err}>{err}</li>
              ))}
            </ul>
          </Alert>
        )}
      </div>
      <hr className="dashed mt-0 show-for-medium" />
    </>
  );
};

interface IRouteSteps {
  heading?: boolean;
}
export const RouteSteps = ({ heading = false }: IRouteSteps) => {
  const { active } = useContext(WizardContext);

  return (
    <>
      {heading && <StepHeading />}
      {active?.render && active.render()}
    </>
  );
};

interface IListedData {
  title?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}
export const ListedData = ({ title, children, className }: IListedData) => {
  return (
    <>
      {title && <h3>{title}</h3>}
      <dl className={classnames('summary-list stacked dt-numbered', className)}>
        {children}
      </dl>
    </>
  );
};

interface IListedEntry {
  title?: React.ReactNode;
  data: [string, React.ReactNode][];
}
export const ListedEntry = ({ title, data }: IListedEntry) => {
  return (
    <>
      {title && <dt>{title}</dt>}
      <dd>
        {data.map(
          ([dtitle, dvalue]) =>
            dvalue && (
              <span key={dtitle} className="d-entry">
                <strong className="d-title">{dtitle}</strong>
                <span className="d-value">{dvalue}</span>
              </span>
            )
        )}
      </dd>
    </>
  );
};

interface IStackedData {
  title?: React.ReactNode;
  labelled?: boolean;
  children?: React.ReactNode;
  className?: string;
}
const StackedData = ({
  title,
  labelled = false,
  children,
  className,
}: IStackedData) => (
  <dl className={classnames('summary-list', className, { stacked: !labelled })}>
    {title && (
      <dt>
        <h4>{title}</h4>
      </dt>
    )}
    {children}
  </dl>
);

interface IStackItem {
  title?: React.ReactNode;
  data?: React.ReactNode;
}
export const StackItem = ({ title, data, ...props }: IStackItem) =>
  data ? (
    <>
      {title && <dt {...props}>{title}</dt>}
      <dd>{data}</dd>
    </>
  ) : null;

interface ILabelledInfo {
  title?: string;
  data: [string | React.ReactNode, React.ReactNode, { [name: string]: any }?][];
  className?: string;
}
export const LabelledInfo = ({ data, ...props }: ILabelledInfo) => (
  <StackedData labelled={true} {...props}>
    {data.map(([t, d, p = {}], k) => (
      <StackItem key={`${t?.toString()}${k}`} title={t} data={d} {...p} />
    ))}
  </StackedData>
);

interface IStackedInfo {
  title: string;
  data: string[];
  className?: string;
}
export const StackedInfo = ({ title, data, ...props }: IStackedInfo) => (
  <StackedData labelled={false} title={title} {...props}>
    {data.map((d, i) => <StackItem key={`${i}-${d}`} data={d} />) || 'No data'}
  </StackedData>
);

interface INextStep {
  children?: React.ReactNode;
  className?: string;
}
export const NextStep = ({ children, className }: INextStep) => {
  const fake: IStep = { id: '' };
  const { active = fake, steps } = useContext(WizardContext);
  const { url, disabled } = R.pathOr(
    fake,
    [R.findIndex(R.propEq('id', active.id), steps) + 1],
    steps
  );

  if (!url || disabled) return null;

  return (
    <Link
      to={url}
      className={classnames('nui-button nui-simple inline', className)}
    >
      {children || 'Continue'}
    </Link>
  );
};

interface ISummaryAccordion {
  className?: string;
  children?: React.ReactNode;
}
export const SummaryAccordion = ({
  className = '',
  ...props
}: ISummaryAccordion) => {
  return (
    <div className={classnames('summary-accordion', className)} {...props} />
  );
};

interface IRoutedSummary {
  stepId: string;
  title: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
}
export const RoutedSummary = ({
  stepId,
  title,
  className = '',
  children,
}: IRoutedSummary) => {
  const { active = { id: '' } } = useContext(WizardContext);
  const current = stepId === active.id;

  return (
    <PinnedAccordion
      open={current}
      className={classnames('summary-accordion-entry', className, {
        current,
      })}
    >
      <PinnedAccordion.Header className="summary-accordion-header">
        <PinnedAccordion.Pin
          disabled={current}
          className="summary-accordion-pin"
        >
          <h3>{title}</h3>
        </PinnedAccordion.Pin>
      </PinnedAccordion.Header>
      <PinnedAccordion.Content className="summary-accordion-content">
        {children}
      </PinnedAccordion.Content>
    </PinnedAccordion>
  );
};
