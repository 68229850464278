import React from 'react';
import FNumber from 'Components/FNumber';
import Partner from 'Pages/MarketRow/Partner';
import LoadingDetails from 'Components/Lists/LoadingDetails';
import Locations from 'Components/Lists/Locations';
import ExtraDetails from 'Components/Lists/ExtraDetails';
import Comment from 'Components/Lists/Comment';
import Documents from 'Components/Lists/Documents';
import PrepopulateButtons from 'Pages/MarketRow/PrepopulateButtons';

export const CurrenciesList = ({ order }) => {
  return (
    <div className="list-box">
      <h2>Additional currencies</h2>
      <ul>
        {order.currencies.map(rec => (
          <li key={rec.currency}>
            <strong>{rec.currency}</strong>{' '}
            <span>
              <FNumber value={rec.val} decimalCount={order.priceDecimalCount} />{' '}
              {rec.unit}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export function CounterpartiesList({ counterparties }) {
  if (!counterparties) return null;

  return (
    <div className="list-box">
      <h2>Counterparties</h2>

      <ul>
        {counterparties.map(rec => (
          <li key={rec.id}>{rec.name}</li>
        ))}
      </ul>
    </div>
  );
}

export function OrderDiv({ order }) {
  return (
    <>
      <div className="list-boxes">
        <CounterpartiesList counterparties={order.counterparties} />
        {order.hascurrencies && <CurrenciesList order={order} />}
        {!!order.attachments?.length && (
          <div className="list-box">
            <Documents attachments={order.attachments} />
          </div>
        )}
        <div className="list-box">
          <LoadingDetails loading={order.loading} unit={order.volume.unit} />
        </div>
        {(!!order.fromaddr?.length || !!order.toaddr?.length) && (
          <div className="list-box">
            <Locations fromaddr={order.fromaddr} toaddr={order.toaddr} />
          </div>
        )}
        <div className="list-box">
          <ExtraDetails record={order} />
        </div>

        {order.note && (
          <div className="list-box">
            <Comment record={order} />
          </div>
        )}
        {order.partners && (
          <>
            {'consignee' in order.partners && (
              <div className="list-box">
                <Partner partners={order.partners} type="consignee" />
              </div>
            )}
            {'buyer' in order.partners && (
              <div className="list-box">
                <Partner partners={order.partners} type="buyer" />
              </div>
            )}
          </>
        )}
      </div>
      <PrepopulateButtons order={order} />
    </>
  );
}
