import React, { useState } from 'react';
import classNames from 'classnames';
import { Table } from 'Components/nui';
import moment from 'moment-timezone';
import { OrderDiv } from '../components';
import { useSharedOrder } from '.';
import { Link } from 'react-router-dom';
import YesNo from 'Components/YesNo';
import FNumber from 'Components/FNumber';
import * as R from 'ramda';
import RowToggle from 'Components/nui/RowToggle';

const columns = products => [
  {
    title: 'ID',
    key: 'id',
    className: 'col-id',
    render: (text, record, index) => {
      return (
        <div className="td-content td-col-id-content">
          <Link onClick={e => e.stopPropagation()} to={`/orders/${record.id}`}>
            {record.pid}
          </Link>
        </div>
      );
    },
  },

  {
    title: 'Rev.',
    key: 'rev',
    dataIndex: 'revision',
    render: (text, record, index) => {
      return (
        <div className="col-rev">
          <div className="td-content">{record.revision}</div>
        </div>
      );
    },
  },

  {
    title: 'Type',
    key: 'type',
    render: (text, record, index) => {
      return (
        <div className="td-content">
          <span className={`type-${record.type.toLowerCase()}`}>
            {record.type}
          </span>
        </div>
      );
    },
  },
  {
    title: 'Private',
    key: 'private',
    render: (text, record, index) => {
      return (
        <div className="td-content">
          <YesNo yes={record.isprivate} />
        </div>
      );
    },
  },
  {
    title: 'Product',
    key: 'product',
    render: (text, record, index) => {
      return (
        <div className={'td-col-product'}>
          <div className="td-content">
            <Link
              to={`/products/${record.product.id}/quick`}
              title="View product details"
              onClick={e => e.stopPropagation()}
            >
              {record.product.name}
            </Link>
          </div>
        </div>
      );
    },
    filters: products.map(p => ({
      text: R.prop('name', p),
      value: R.prop('id', p),
    })),
  },
  {
    title: 'Status',
    key: 'status',
    render: (text, record, index) => {
      return (
        <div className="td-content">
          <span className={`status-${record.status.toLowerCase()}`}>
            {record.status}
          </span>
        </div>
      );
    },
  },
  {
    title: 'Created at',
    key: 'createdat',
    render: (text, record, index) => {
      return (
        <div className="td-content">
          {moment(record.created).format('DD MMMM YYYY, HH:mm')}
        </div>
      );
    },
  },
  {
    title: 'Updated at',
    key: 'updatedat',
    render: (text, record, index) => {
      return (
        <div className="td-content">
          {moment(record.updated).format('DD MMMM YYYY, HH:mm')}
        </div>
      );
    },
  },
  {
    title: 'Price',
    key: 'price',
    children: [
      {
        title: 'Value',
        key: 'pricevalue',
        align: 'right',
        render: (text, record, index) => {
          return (
            <div className="td-content align-right">
              <FNumber
                value={record.price.val}
                decimalCount={record.priceDecimalCount}
              />
              {record.currencies && record.currencies.length > 1 && (
                <sup title="Has additional currencies">*</sup>
              )}
            </div>
          );
        },
      },
      {
        title: 'Unit',
        key: 'priceunit',
        render: (text, record, index) => {
          return <div className="td-content">{record.price.unit}</div>;
        },
      },
    ],
  },
  {
    title: 'Volume',
    key: 'volume',
    children: [
      {
        title: 'Executed',
        key: 'volumeexecuted',
        align: 'right',
        render: (text, record, index) => {
          return (
            <div className="td-content align-right">
              {record.volume.executed > 0 ? (
                <FNumber
                  value={record.volume.executed}
                  decimalCount={record.volumeDecimalCount}
                />
              ) : (
                ''
              )}
            </div>
          );
        },
      },
      {
        title: 'Pending',
        key: 'volumepending',
        align: 'right',
        render: (text, record, index) => {
          return (
            <div className="td-content align-right">
              {record.volume.pending > 0 ? (
                <>
                  <FNumber
                    value={record.volume.pending}
                    decimalCount={record.volumeDecimalCount}
                  />
                </>
              ) : (
                ''
              )}
            </div>
          );
        },
      },
      {
        title: 'Unit',
        key: 'volumeunit',
        render: (text, record, index) => {
          return (
            <div className="td-content">
              {record.volume.unit > 0 ? record.volume.unit : ''}
            </div>
          );
        },
      },
    ],
  },
  {
    title: 'Estimated time of departure',
    key: 'etd',
    children: [
      {
        title: 'From',
        key: 'etdfrom',
        render: (text, record, index) => {
          return (
            <div className="td-content">
              {moment(record.frometd).format('DD MMMM YYYY, HH:mm')}
            </div>
          );
        },
      },
      {
        title: 'To',
        key: 'etdto',
        render: (text, record, index) => {
          return (
            <div className="td-content">
              {moment(record.toetd).format('DD MMMM YYYY')}
            </div>
          );
        },
      },
    ],
  },
  {
    title: 'Splittable',
    key: 'splittable',
    render: (text, record, index) => {
      return (
        <div className="td-content">
          <YesNo yes={record.splittable} />
        </div>
      );
    },
  },
];

export default () => {
  const [order] = useSharedOrder();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  if (!order || !order.history || order.history.length === 0) return null;
  return (
    <>
      <div className="full-page-header">
        <hr className="mt--10 show-for-xlarge" />
        <h2 className="light m-0">History</h2>
      </div>
      <div className="full-page-content">
        <Table
          className={classNames(
            'generic-table',
            'expand-table',
            'th-rows',
            'order-history-table'
          )}
          expandIconAsCell={false}
          expandedRowRender={record => <OrderDiv order={record} />}
          columns={columns([])}
          data={order.history}
          expandIcon={({ expanded, onExpand, record }) => (
            <RowToggle
              open={expanded}
              onClick={e => {
                onExpand(record, e);
              }}
            ></RowToggle>
          )}
          onRow={record => {
            const isExpanded = expandedRowKeys.includes(record.id);
            return {
              title: isExpanded
                ? 'Click row to hide details'
                : 'Click row to view details',
            };
          }}
          onExpand={(_, record) => {
            if (expandedRowKeys.includes(record.id)) {
              setExpandedRowKeys(state =>
                R.filter(x => x !== record.id, state)
              );
            } else {
              setExpandedRowKeys(state => R.append(record.id, state));
            }
          }}
        />
      </div>
    </>
  );
};
