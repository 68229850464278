import React from 'react';
import { useSharedTrade } from '.';
import classNames from 'classnames';
import * as R from 'ramda';
import { FormattedMessage } from 'react-intl';

export default () => {
  const [trade] = useSharedTrade();
  const status = R.pathOr('traded', ['status'], trade);

  if (!trade) return null;

  return (
    <div className="order-actions trade-actions">
      <div className="nui-row">
        <div className="ptcol1">
          <h2>
            <FormattedMessage
              id="viewtrade-field-details-label"
              description="Label for 'Details' field on View trade"
              defaultMessage="Details"
            />
          </h2>
          <div className="status">
            <strong className="all-black mr-10">
              <FormattedMessage
                id="viewtrade-field-status-label"
                description="Label for 'Status' field on View trade"
                defaultMessage="Status"
              />
            </strong>
            <span
              className={classNames(`status-${status.toLowerCase()}`, 'inline')}
            >
              {trade.status}
            </span>
          </div>
        </div>
        <div className="ptcol2">
          <h2 className="show-for-medium">
            <span className="show-for-large pl-10">&#xFEFF;</span>
            <FormattedMessage
              id="viewtrade-field-posttrade-label"
              description="'Post trade in 'post trade activity' heading on View trade"
              defaultMessage="Post trade <span>activity</span>"
              values={{
                span: chunks => (
                  <span className="show-for-large">{chunks}</span>
                ),
              }}
            />
          </h2>
        </div>
      </div>
    </div>
  );
};
