import React, { useEffect, useState } from 'react';
import { useScrollToTopOnMount } from '~/hooks';
import Header from './Header';
import { api } from '~/api';
import { useStoreState } from 'easy-peasy';
import { Select, Alert, Form, Button, Loading } from 'Components/nui';
import { FormWrapper } from 'Components/form';
import {
  mergeSchema,
  normalizeChoicesNS,
  submitError,
} from 'Components/form/utils';
import { ShowResult } from 'Components/Result';
import { promisify } from 'es6-promisify';
import * as R from 'ramda';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { parseSearch } from '~/utils';
import qs from 'qs';
import { reportTypes } from '..';
import { v4 as uuid } from 'uuid';

const PForm = ({
  form,
  schema,
  path,
  initialData,
  setReport,
  solution,
  divisionID,
  submitState,
}) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const getFormWrapper = () => {
    let fields = [
      {
        name: 'base_date',
        label: 'Date',
        choices: normalizeChoicesNS,
        props: {
          placeholder: 'Select month',
        },
      },
      {
        name: 'period',
        label: 'Period',
        choices: normalizeChoicesNS,
        props: {
          placeholder: 'Select period',
        },
      },
      {
        name: 'compare_with',
        label: 'Compare with',
        choices: normalizeChoicesNS,
        props: {
          placeholder: 'Select number of periods',
        },
      },
      {
        name: 'start',
        label: 'Start',
      },
      {
        name: 'end',
        label: 'End',
      },
    ];

    return new FormWrapper(form, initialData, mergeSchema(schema, fields));
  };

  const formWrapper = getFormWrapper();
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }
    submitState.setLoading(true);
    let formData = formWrapper.serialize();

    try {
      const params = {
        ...formData,
        solution: solution.id,
        division: divisionID,
      };
      const data = await api.getData2(`/reports/${path}`, params);
      setReport(data.report);
      submitState.setLoading(false);
      toast.success('The report table was updated');
      history.push(`${url}?${qs.stringify(params)}`);
    } catch (err) {
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        err
      );
      if (errors) {
        formWrapper.setErrors(errors);
      } else {
        submitState.setResult(err);
      }
      submitState.setLoading(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      {formWrapper.render()}
      <div className="mb-20">
        <Button
          htmlType="submit"
          type="primary"
          loading={submitState.loading}
          title="Show report"
        >
          Show report
        </Button>
      </div>
    </Form>
  );
};

const WrappedForm = Form.create()(PForm);

const BaseReport = () => {
  useScrollToTopOnMount();
  const { reportType } = useParams();
  const path = R.path([reportType, 'path'], reportTypes);
  const title = R.path([reportType, 'title'], reportTypes);
  const divtype = R.path([reportType, 'divtype'], reportTypes);

  const solution = useStoreState(state => state.auth.solution);
  const divisions = R.filter(
    d => d[divtype],
    useStoreState(state => state.auth.solutionDivisions)
  );

  const [schema, setSchema] = useState();
  const history = useHistory();

  const initialData = parseSearch(R.path(['location', 'search'], history));

  const [divisionID, setDivisionID] = useState(
    initialData.division || divisions[0].id
  );
  const [report, setReport] = useState();

  useEffect(() => {
    const fetch = async () => {
      const params = {
        solution: solution.id,
        division: divisions[0].id,
      };
      const data = await api.getData2(`/reports/${path}/schema`, params);
      setSchema(data.fields);
    };

    fetch();
  }, [path]);

  if (!path) return history.push('/reports');

  return (
    <>
      <Header title={title} />

      <div className="full-page-header">
        <hr />
        <div className="report-filter">
          <div className="nui-fieldset report-filter-select-div">
            <label htmlFor="select-division">Select division</label>
            <div className="select-holder">
              <Select
                id="select-division"
                value={divisionID}
                onChange={setDivisionID}
              >
                {divisions.map(d => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <ShowResult renderError={submitError}>
          {submitState => (
            <div className="nui-form report-filter">
              {schema ? (
                <WrappedForm
                  key="report-filter"
                  schema={schema}
                  path={path}
                  initialData={initialData}
                  setReport={setReport}
                  solution={solution}
                  divisionID={divisionID}
                  submitState={submitState}
                />
              ) : (
                <Loading size="large" />
              )}
            </div>
          )}
        </ShowResult>
      </div>

      <div className="full-page-content">
        {report && report.grid && (
          <div className="generic-table light reports-table">
            <div className="nui-table table-wrapper">
              <div className="nui-table-content">
                <div className="nui-table-body">
                  <table>
                    <thead className="nui-table-thead">
                      <tr>
                        <th>
                          <div className="th-content">&nbsp;</div>
                        </th>
                        {report.grid_headers.map(item => (
                          <th key={uuid()}>
                            <div className="th-content">{item.label}</div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="nui-table-tbody">
                      {report.grid.length === 0 && (
                        <tr key={uuid()} className={`tr-level tr-level-0`}>
                          <td
                            colSpan={report.grid_headers.length + 1}
                            className="pt-20 pb-20"
                          >
                            <Alert hasicon className="align-center">
                              No records to display
                            </Alert>
                          </td>
                        </tr>
                      )}
                      {report.grid.map(row => (
                        <tr
                          key={uuid()}
                          className={`tr-level tr-level-${row.level}`}
                        >
                          <td
                            colSpan={
                              row.cols ? 1 : report.grid_headers.length + 1
                            }
                          >
                            <div className="td-content">{row.label}</div>
                          </td>
                          {row.cols &&
                            row.cols.map(col => (
                              <td key={uuid()} className={col.type}>
                                <div className="td-content">{col.val}</div>
                              </td>
                            ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BaseReport;
