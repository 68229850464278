import React, { useRef, useState, useEffect } from 'react';
import { Loading, Alert, Form, Button, Modal } from 'Components/nui';
import { api } from '~/api';
import { toast } from 'react-toastify';
import { FormWrapper } from 'Components/form';
import { mergeSchema } from 'Components/form/utils';
import { promisify } from 'es6-promisify';
import FilesField from 'Components/form/FilesField';
import { useSharedTrade } from '..';
import { useMountedState, useToggle } from 'react-use';
import { FileLink } from 'Components/Data';
import { useStoreState } from 'easy-peasy';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import * as R from 'ramda';

export default () => {
  return (
    <div className="info-form-area">
      <AttachmentForm />
    </div>
  );
};

const AttachmentForm = () => {
  const [trade] = useSharedTrade();
  const schema = useRef({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    setLoading(true);
    (async () => {
      try {
        const response = await api.request({
          method: 'get',
          url: `/trades/${trade.id}/attachments/schema`,
        });
        if (mounted) schema.current = response.data.fields;
      } catch (err) {
        toast('An error occurred while loading this form.', {
          type: 'error',
        });
        schema.current = 'error';
      }
      if (mounted) setLoading(false);
    })();
    return () => void (mounted = false);
  }, [setLoading, trade, schema]);

  if (loading) return <Loading size="small" />;
  if (schema.current === 'error')
    return (
      <Alert type="error" className="align-center" hasicon>
        <FormattedMessage
          id="viewtrade-tradeactions-attachmentform-field-error"
          description="Error text for Attachment Form"
          defaultMessage="An error occurred."
        />
      </Alert>
    );

  return <WrappedForm schema={schema.current} />;
};

function useFormWrapper({ form, schema, errors }) {
  const [files, setFiles] = useState([]);
  const fields = [
    {
      name: 'files',

      // required: true,
      initialData: [],
      render: () => {
        if (!schema.files) return null;
        return (
          <Form.Item key="form-item-files">
            <FilesField
              errors={errors}
              key="form-files"
              label={
                <FormattedMessage
                  id="viewtrade-tradeactions-attachmentform-field-helptext-upload"
                  description="helptext for Attachment Form: Upload Trade Action  in View trade"
                  defaultMessage="Upload documents related to this trade"
                />
              }
              accept={R.pathOr([], ['files', 'accepted'], schema).join(', ')}
              files={files}
              setFiles={setFiles}
            />
          </Form.Item>
        );
      },
      serializeField: () => {
        if (!schema.files) return null;
        return files.length ? { files } : undefined;
      },
    },
    {
      name: 'public',
      label: (
        <h5>
          <FormattedMessage
            id="viewtrade-tradeactions-attachmentform-field-helptext-visibility"
            description="helptext for Attachment Form: who can see Trade Action in View trade"
            defaultMessage="Who can see this content"
          />
        </h5>
      ),
      initialValue: true,
      type: 'RadioSelect',
      choices: [
        {
          key: 'private',
          label: (
            <FormattedMessage
              id="viewtrade-tradeactions-attachmentform-field-helptext-visibility-private"
              description="helptext for Attachment Form: only you will see Trade Action in View trade"
              defaultMessage="Private - only you will see this content"
            />
          ),
          value: false,
        },
        {
          key: 'public',
          label: (
            <FormattedMessage
              id="viewtrade-tradeactions-attachmentform-field-helptext-visibility-public"
              description="helptext for Attachment Form: other party will see Trade Action in View trade"
              defaultMessage="Public - the other party will also have access to this content"
            />
          ),
          value: true,
        },
      ],
    },
  ];

  return new FormWrapper(
    form,
    { files: [], public: true },
    mergeSchema(schema, fields)
  );
}

const BaseForm = ({ form, schema }) => {
  const [errors, setErrors] = useState([]);
  const [trade, setTrade] = useSharedTrade();
  const formWrapper = useFormWrapper({ form, schema, errors });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);
  const [loading, setLoading] = useState(false);
  const mounted = useMountedState();

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    try {
      await validateFieldsAndScroll();
    } catch (err) {
      setLoading(false);
      return;
    }

    try {
      const fields = formWrapper.serialize();
      if (!fields.files?.length) {
        setErrors([{ description: 'This field cannot be empty' }]);
        setLoading(false);
        return;
      }
      const data = new FormData();
      for (const [name, value] of Object.entries(fields)) {
        if (value !== undefined) {
          if (Array.isArray(value)) for (const v of value) data.append(name, v);
          else data.append(name, value);
        }
      }
      const response = await api.postData(
        `/trades/${trade.id}/attachments`,
        data
      );
      form.resetFields();
      toast.success('Files have been added to the trade');
      if (mounted()) {
        setTrade({
          ...trade,
          attachments: [...response.attachments],
        }); // Add to the shared context
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      toast.error('An error occurred while submitting this form');
    }
  }

  return (
    <div className="nui-form">
      <Form onSubmit={handleSubmit} layout="inline">
        {formWrapper.render()}
        <Button type="primary" loading={loading} htmlType="submit">
          <FormattedMessage
            id="viewtrade-tradeactions-attachmentform-field-button-upload"
            description="button for Attachment Form: Upload Trade Action in View trade"
            defaultMessage="Upload"
          />
        </Button>
      </Form>
    </div>
  );
};

const WrappedForm = Form.create()(BaseForm);

export const FilesModal = ({ files, title }) => {
  const [on, toggle] = useToggle(false);

  const myDivisions = useStoreState(state => state.auth.solutionDivisions).map(
    i => i.shortcode
  );
  const ours = files
    .filter(f => myDivisions.includes(f.division.shortcode))
    .sort((a, b) => {
      if (moment(a.created) < moment(b.created)) return 1;
      if (moment(a.created) > moment(b.created)) return -1;
      return 0;
    });
  const theirs = files
    .filter(f => !myDivisions.includes(f.division.shortcode))
    .sort((a, b) => {
      if (moment(a.created) < moment(b.created)) return 1;
      if (moment(a.created) > moment(b.created)) return -1;
      return 0;
    });

  return (
    <>
      <Button
        onClick={e => {
          toggle(true);
        }}
        type="buttonlink"
        className="view-files"
      >
        {title || 'View documents'} ({files.length})
        <span className="nowrap">
          &#65279;
          <span className="icon-link-ext-alt" />
        </span>
      </Button>

      {on && (
        <Modal close={() => toggle(false)} size="medium">
          <h2 className="mb-20">
            <FormattedMessage
              id="viewtrade-tradeactions-attachmentform-field-modal-header"
              description="Header for Attachment Form modal Trade Action in View Trade"
              defaultMessage="Files"
            />
          </h2>
          {ours.length > 0 && (
            <div className="files-ours">
              <h3>
                <FormattedMessage
                  id="viewtrade-tradeactions-attachmentform-field-modal-helptext-myfiles"
                  description="helptext for Attachment Form modal: My uploaded files Trade Action in View trade"
                  defaultMessage="My uploaded files"
                />
              </h3>
              <ul className="post-trade-files file-list">
                {ours.map(file => (
                  <li className="file-entry" key={file.id}>
                    <FileLink file={file} />
                    <span className="date">
                      <strong className="all-black">
                        {file.isprivate ? (
                          <FormattedMessage
                            id="viewtrade-tradeactions-attachmentform-field-modal-helptext-private"
                            description="helptext for Attachment Form modal: private Trade Action in View trade"
                            defaultMessage="Private"
                          />
                        ) : (
                          <FormattedMessage
                            id="viewtrade-tradeactions-attachmentform-field-modal-helptext-public"
                            description="helptext for Attachment Form modal: Public Trade Action in View trade"
                            defaultMessage="Public"
                          />
                        )}
                      </strong>{' '}
                      | {moment(file.created).format('D MMM YYYY HH:mm')}
                    </span>
                  </li>
                ))}
              </ul>
              <hr />
            </div>
          )}
          {theirs.length > 0 && (
            <div className="files-theirs">
              <h3>
                <FormattedMessage
                  id="viewtrade-tradeactions-attachmentform-field-modal-helptext-uploadedby"
                  description="helptext for Attachment Form modal: Files uploaded by Trade Action in View trade"
                  defaultMessage="Files uploaded by {name}"
                  values={{ name: theirs[0].division.name }}
                />
              </h3>
              <ul className="post-trade-files file-list">
                {theirs.map(file => (
                  <li className="file-entry" key={file.id}>
                    <FileLink file={file} />
                    <span className="date">
                      {moment(file.created).format('D MMM YYYY HH:mm')}
                    </span>
                  </li>
                ))}
              </ul>
              <hr />
            </div>
          )}
          <Button onClick={toggle} type="reverse">
            <FormattedMessage
              id="viewtrade-tradeactions-attachmentform-field-modal-button-close"
              description="button for Attachment Form modal: UplCloseoad Trade Action in View trade"
              defaultMessage="Close"
            />
          </Button>
        </Modal>
      )}
    </>
  );
};
