import React, { useState } from 'react';
import { useSharedTrade } from '..';
import Partner from 'Pages/MarketRow/Partner';
import LoadingDetails from 'Components/Lists/LoadingDetails';
import * as R from 'ramda';
import ExtraDetails from 'Components/Lists/ExtraDetails';
import TradeDetails from 'Components/Lists/TradeDetails';
import Contact from 'Components/Lists/Contact';
import Documents from 'Components/Lists/Documents';
import TradeParties from 'Components/Lists/TradeParties';

export default () => {
  const [trade] = useSharedTrade();

  if (!trade) return null;
  const [mine, theirs] =
    trade.type === 'sale'
      ? [trade.offer.attachments, trade.bid.attachments]
      : [trade.bid.attachments, trade.offer.attachments];

  const orderType = trade.type;

  return (
    <div className="content">
      <TradeDetails trade={trade} />
      <div className="post-trade-lists">
        <TradeParties record={trade} />

        {trade.type && (
          <Contact
            order={
              trade.offer && trade.offer.is_owner ? trade.bid : trade.offer
            }
          />
        )}

        {mine.length > 0 && (
          <div>
            <Documents title="Your documents" attachments={mine} />
          </div>
        )}

        {theirs.length > 0 && (
          <div>
            <Documents
              title={
                orderType === 'sale' ? 'Buyer documents' : 'Seller documents'
              }
              attachments={theirs}
            />
          </div>
        )}

        <LoadingDetails
          loading={trade.loading}
          unit={R.path(['product', 'loadingunit', 'desc'], trade)}
        />

        <ExtraDetails record={trade} bid={trade.bid} offer={trade.offer} />

        {trade.partners && (
          <>
            {'consignee' in trade.partners && (
              <div>
                <Partner partners={trade.partners} type="consignee" />
              </div>
            )}
            {'buyer' in trade.partners && (
              <div>
                <Partner partners={trade.partners} type="buyer" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
