import { allowRolesIf } from 'Components/Authorised';
import { useStoreState } from '~/store';
import { ModelApi } from '../ModelApi';
import * as R from 'ramda';

interface Country {
  name: string;
  code: string;
  region: string;
}

export interface Partner {
  id: string;
  name: string;
  buyer: boolean;
  consignee: boolean;
  companyno: string;
  email: string;
  phone: string;
  street: string;
  suburb: string;
  city: string;
  state: string;
  country: Country;
  port: { id: string; name: string; country: Country };
  zip: string;
  division: { id: string };
}

interface ApiConfig {
  division: string;
}
export class PartnerApi extends ModelApi {
  constructor({ division }: ApiConfig) {
    const url = `/divisions/${division}/partners`;
    super({ url });
  }

  async schema() {
    return await this.request({
      url: `${this.url}/schema`,
      method: 'get',
    });
  }

  async update(data: Partner) {
    return await this.request({
      url: `${this.url}/${data.id}`,
      method: 'put',
      data,
    });
  }

  async add(data: any) {
    return await this.request({
      url: this.url,
      method: 'post',
      data,
    });
  }

  async fetch() {
    return await this.request({ url: this.url, method: 'get' });
  }

  async remove(partner: string) {
    return await this.request({
      url: `${this.url}/${partner}`,
      method: 'delete',
    });
  }
}

export function usePartners() {
  const isBuyer = useStoreState(state => state.auth.traderType.buyer);
  const hasPartners = useStoreState(
    state => state.auth.solutionSettings.hasPartners
  );

  const roles = useStoreState(state =>
    state.auth.solution.roles.map(r => r.division.id)
  );

  const canView = () => hasPartners && isBuyer;
  const canEdit = canView;

  return {
    async schema(division: string) {
      const api = new PartnerApi({ division });
      const response = await api.schema();
      return response.data.fields;
    },

    async update(division: string, data: Partner) {
      const api = new PartnerApi({ division });
      const response = await api.update(data);
      return response.data;
    },

    async add(division: string, data: any) {
      const api = new PartnerApi({ division });
      const response = await api.add(data);
      return response.data;
    },

    async fetch() {
      const data = await Promise.all(
        roles.map(division =>
          new PartnerApi({ division })
            .fetch()
            .then(response => response.data.partners)
        )
      );
      return R.flatten(data) as Partner[];
    },

    async remove({ id, division }: Partner) {
      const api = new PartnerApi({ division: division.id });
      const result = await api.remove(id);
      return result;
    },

    _acl: {
      view: allowRolesIf('guest', 'trader', 'manager')(canView),
      add: allowRolesIf('guest', 'trader', 'manager')(canEdit),
      edit: allowRolesIf('guest', 'trader', 'manager')(canEdit),
      delete: allowRolesIf('guest', 'trader', 'manager')(canEdit),
    },
  };
}
