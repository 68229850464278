import Table from 'Components/NuiTable';
import type { ColumnProps } from 'antd/lib/table';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Partner } from '~/store/models/consignees';
import classnames from 'classnames';
import YesNo from 'Components/YesNo';
import { Button } from 'Components/nui';

interface ICell {
  children: React.ReactNode;
}
const Cell = ({ children, ...props }: ICell) => (
  <td {...props}>
    <div className="td-content">{children}</div>
  </td>
);

interface ITable {
  partners: {
    fetch(): Promise<Partner[]>;
    remove(p: Partner): Promise<any>;
  };
}

export default ({ partners }: ITable) => {
  const [data, setData] = useState<Partner[]>([]);
  const [loading, setLoading] = useState(false);

  const getPartners = async () => {
    try {
      const result = await partners.fetch();
      setData(result);
    } catch (err) {
      toast('Error retrieving data. Please try again later.');
    }
  };

  const deletePartner = async (partner: Partner) => {
    setLoading(true);

    try {
      await partners.remove(partner);
      await getPartners();
      toast('Partner removed successfully');
    } catch (err) {
      toast('Something went wrong. Please try again later.', { type: 'error' });
    }

    setLoading(false);
  };

  useEffect(() => {
    getPartners();
  }, []);

  const toStr = (name: string) => name.toLowerCase().replace(' ', '-');

  const getColumn = (
    title: string,
    render: (p: Partner) => React.ReactNode,
    { className = '', ...other }: Partial<ColumnProps<Partner>> = {}
  ) => ({
    title,
    key: `table-partners-col-${toStr(title)}`,
    className: classnames(`col-partners-${toStr(title)}`, className),
    render,
    ...other,
  });

  const columns: ColumnProps<any>[] = [
    getColumn('Name', partner => partner.name),
    getColumn('Buyer', partner => <YesNo yes={partner.buyer} />),
    getColumn('Consignee', partner => <YesNo yes={partner.consignee} />),
    getColumn('Email', partner => (
      <a href={`mailto:${partner.email}`}>{partner.email}</a>
    )),
    getColumn('Tax number', partner => partner.companyno),
    getColumn('Phone number', partner => partner.phone),
    getColumn('Address', partner =>
      [
        partner.street,
        partner.suburb,
        partner.city,
        partner.state,
        partner.country.name,
        partner.zip,
      ]
        .filter(x => !!x)
        .join(', ')
    ),
    getColumn('Port', partner => partner.port.name),
    getColumn(
      'delete',
      partner => (
        <>
          <Button
            disabled={loading}
            loading={loading}
            icon="trash"
            onClick={() => {
              deletePartner(partner);
            }}
          />
          {
            /* <Link */
            //   to={`/partners/edit/${partner.id}`}
            //   className="nui-button nui-button-secondary nui-button-icon"
            // >
            //   <span className="icon-pencil" />
            /* </Link> */
          }
        </>
      ),
      { title: '', className: 'col-delete' }
    ),
  ];

  const components = {
    body: {
      cell: Cell,
    },
  };

  return (
    <Table
      className="generic-table th-sticky light"
      columns={columns}
      dataSource={data}
      components={components}
      pagination={false}
      rowKey={partner => partner.id}
    />
  );
};
