import React from 'react';
import OrderTable from './Table';
import { useOTable } from '~/store/models';
import { useScrollToTopOnMount } from '~/hooks';
import Header from './Header';
import Helmet from 'Components/Helmet';
import { Loading } from 'Components/nui';
import { FiltersProvider } from 'Components/filters/context';
import { useStoreState } from '~/store';
import OrderFilters from 'Components/filters/OrderFilters';

export default () => {
  useScrollToTopOnMount();

  const orders = useOTable();
  const { pagination } = orders;

  const solution = useStoreState(state => state.auth.solution);

  return (
    <>
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <Header orders={orders} />
      <FiltersProvider store={`order-filters_${solution?.id}`}>
        <div className="full-page-content">
          <OrderFilters orders={orders} />
          {orders.loading() ? (
            <Loading />
          ) : orders.noContent() ? (
            <div className="nui-row full-page-messaging align-center p-100 pb-110">
              <div className="icon-inbox bordered align-center">
                There are currently no orders to display
              </div>
            </div>
          ) : (
            <OrderTable orders={orders} pagination={pagination} />
          )}
        </div>
      </FiltersProvider>
    </>
  );
};
