import Button from 'Components/nui/Button';
import { FormattedMessage } from 'react-intl';
import FilterDropdown from './FilterDropdown';
import { FilterTypes, useFilters } from './context';
import FilterChips from './FilterChips';
import { useFilterOptions } from './useFilterOptions';
import moment from 'moment';
import { useStoreState } from '~/store';
import * as R from 'ramda';

const TradeFilters = ({ trades }) => {
  const {
    productOptions,
    etdOptions,
    publishedDateOptions,
    tradeStatusOptions,
  } = useFilterOptions({ commodity: true });

  const { setFilters } = useFilters();

  const { filters } = trades;

  const solution = useStoreState(state => state.auth.solution);

  const disableOtherparty = solution.tenderlogic === 'purchase';

  const products = useStoreState(
    R.pathOr<any[]>([], ['auth', 'commodityFilteredProducts'])
  );

  const getCustomers = (): any[] => {
    const customers = R.pipe(
      R.chain(R.propOr([], 'customers')),
      R.uniq,
      R.sortBy(R.prop('name')),
      R.map((c: any) => ({ value: c.id, label: c.name }))
    )(products);
    return customers.length > 0 ? customers : [];
  };

  const otherPartyOptions = getCustomers();
  return (
    <div className="filter-row">
      <FilterChips>
        <FilterDropdown
          id="product"
          label={
            <FormattedMessage
              id="order-filter-field-products"
              description="Label for 'Products' in Orders filter"
              defaultMessage="Products"
            />
          }
          type={FilterTypes.CHECKBOXES}
          options={productOptions}
          onChange={value => filters.filter('product', value.product.items)}
          onClear={() => filters.filter('product', null)}
        />

        {tradeStatusOptions.length > 0 && (
          <FilterDropdown
            id="status"
            label={
              <FormattedMessage
                id="order-filter-field-status"
                description="label for 'Status' in Orders filter"
                defaultMessage="Status"
              />
            }
            type={FilterTypes.CHECKBOXES}
            options={tradeStatusOptions}
            onChange={value => filters.filter('status', value.status.items)}
            onClear={() => filters.filter('status', null)}
          />
        )}

        <FilterDropdown
          id="createdOption"
          label={
            <FormattedMessage
              id="order-filter-field-published"
              description="label for 'Published' in Orders filter"
              defaultMessage="Created"
            />
          }
          type={FilterTypes.RADIO}
          options={publishedDateOptions}
          subFieldId="created"
          subFieldType={FilterTypes.DATE}
          onChange={value => {
            switch (value.createdOption.items[0]) {
              case 'today':
                filters.filter('created', [
                  moment().startOf('day'),
                  moment().add(1, 'days').startOf('day'),
                ]);
                break;
              case 'last24h':
                filters.filter('created', [
                  moment().subtract(1, 'days').startOf('day'),
                  moment(),
                ]);
                break;
              case 'last7d':
                filters.filter('created', [
                  moment().subtract(7, 'days').startOf('day'),
                  moment(),
                ]);
                break;
              case 'lastMonth':
                filters.filter('created', [
                  moment().subtract(30, 'days').startOf('day'),
                  moment(),
                ]);
                break;
              case 'custom':
                if (value.created?.items[0] && value.created?.items[1]) {
                  filters.filter('created', [
                    moment(value.created.items[0]),
                    moment(value.created.items[1]),
                  ]);
                } else {
                  filters.filter('created', null);
                }
                break;
            }
          }}
          subFieldOnChange={value => {
            if (value.created.items[0] && value.created.items[1]) {
              filters.filter('created', [
                moment(value.created.items[0]),
                moment(value.created.items[1]),
              ]);
            } else {
              filters.filter('created', null);
            }
          }}
          onClear={() => filters.filter('created', null)}
        />

        <FilterDropdown
          id="etdOption"
          label={
            <FormattedMessage
              id="order-filter-field-etd-short"
              description="label for 'ETD' in Orders filter (short)"
              defaultMessage="ETD"
            />
          }
          type={FilterTypes.RADIO}
          options={etdOptions}
          subFieldId="etd"
          subFieldType={FilterTypes.DATE}
          onChange={value => {
            switch (value.etdOption.items[0]) {
              case 'thisWeek':
                filters.filter('etd', [
                  moment().startOf('week'),
                  moment().endOf('week'),
                ]);
                break;
              case 'thisMonth':
                filters.filter('etd', [
                  moment().startOf('month'),
                  moment().endOf('month'),
                ]);
                break;
              case 'nextMonth':
                filters.filter('etd', [
                  moment().startOf('month').add(1, 'month'),
                  moment().endOf('month').add(1, 'month'),
                ]);
                break;
              case 'afterNextMonth':
                filters.filter('etd', [
                  moment().startOf('month').add(2, 'month'),
                  moment().startOf('month').add(12, 'month'),
                ]);
                break;
              case 'custom':
                if (value.etd.items[0] && value.etd.items[1]) {
                  filters.filter('etd', [
                    moment(value.etd.items[0]),
                    moment(value.etd.items[1]),
                  ]);
                } else {
                  filters.filter('etd', null);
                }
                break;
            }
          }}
          subFieldOnChange={value => {
            if (value.etd.items[0] && value.etd.items[1]) {
              filters.filter('etd', [
                moment(value.etd.items[0]),
                moment(value.etd.items[1]),
              ]);
            } else {
              filters.filter('etd', null);
            }
          }}
          onClear={() => filters.filter('etd', null)}
        />

        {!disableOtherparty && (
          <FilterDropdown
            id="otherparty"
            label={
              <FormattedMessage
                id="filter-field-otherparty"
                description="label for 'Other party' in filter"
                defaultMessage="Other party"
              />
            }
            type={FilterTypes.CHECKBOXES}
            options={otherPartyOptions}
            onChange={value =>
              filters.filter('otherparty', value.otherparty.items)
            }
            onClear={() => filters.filter('otherparty', null)}
          />
        )}

        <Button
          type="buttonlink"
          className="filter-reset"
          onClick={() => {
            setFilters({});
            filters.reset();
          }}
        >
          <FormattedMessage
            id="filter-button-clear-filter"
            description="Button for clear search filters"
            defaultMessage="Clear all filters"
          />
        </Button>
      </FilterChips>
    </div>
  );
};

export default TradeFilters;
