import React, { useState } from 'react';
import { UsingSchema, useSchema, useCustomerRequest } from './EditContext';
import { FormWrapper } from 'Components/form';
import { mergeSchema } from 'Components/form/utils';
import { promisify } from 'es6-promisify';
import { Button, Form } from 'Components/nui';
import { toast } from 'react-toastify';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';
import { NextStep } from 'Components/nui/Wizard';

interface IStep {
  index: number;
  title: string;
  desc: string;
}
const Step = ({ index, title, desc }: IStep) => (
  <div className="form-step">
    <h3 className="numbered outside">{title}</h3>
    <p className="description">{desc}</p>
  </div>
);

export default () => {
  return (
    <div className="content-company-details">
      <UsingSchema schema="company">
        <CompanyForm />
      </UsingSchema>
    </div>
  );
};

function useFormWrapper({ form, schema, customer }: any) {
  const fields = [
    {
      render: () => (
        <Step
          key="step-1"
          index={1}
          title="Company info"
          desc="Add basic information for this company."
        />
      ),
    },
    {
      name: 'name',
      label: 'Name',
      type: 'Input',
    },
    {
      name: 'companyno',
      label: 'Company no',
      type: 'Input',
    },
    {
      name: 'currency',
      label: 'Currency',
      type: 'Select',
      choices: R.pipe(
        R.propOr([], 'choices'),
        R.map(([value, label]) => ({ label, value, key: value }))
      ),
    },
    {
      render: () => (
        <Step
          key="step-2"
          index={2}
          title="Contact details"
          desc="Add contact details for this company"
        />
      ),
    },
    {
      name: 'email',
      label: 'Email',
      type: 'Input',
      props: { type: 'email' },
    },
    {
      name: 'workphone',
      label: 'Phone',
      type: 'Input',
      props: { type: 'tel' },
    },
    {
      name: 'mobilephone',
      label: 'Mobile',
      type: 'Input',
      props: { type: 'tel' },
    },
  ];

  return new FormWrapper(
    form,
    customer?.company || {},
    mergeSchema(schema, fields)
  );
}

const BaseForm = ({ form, schema }: any) => {
  const { customer, actions, validation } = useCustomerRequest();
  const formWrapper = useFormWrapper({ form, schema, customer });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e: any) {
    e.preventDefault();

    setLoading(true);

    try {
      await validateFieldsAndScroll();
    } catch (err) {
      setLoading(false);
      return;
    }

    try {
      const data = formWrapper.serialize();
      const customer = await actions.edit('company', data);
      history.push(`/customer-requests/${customer.id}/address`);
      toast('Customer request updated', { type: 'success' });
    } catch (err) {
      if (err?.response && err.response?.status === 400) {
        const validation = err.response?.data?.errors || [];
        if (validation.length) {
          formWrapper.setErrors(validation[0]?.description || []);
        } else {
          // wtf
          toast('Form did not validate.', { type: 'error' });
          console.error('form did not validate', err);
        }
      } else {
        toast(
          'There was an error while submitting your form. Please try again later.',
          { type: 'error' }
        );
      }
      setLoading(false);
    }

    window.scrollTo(0, 0);
  }

  return (
    <div className="nui-form">
      <Form onSubmit={handleSubmit}>
        <div className="form-steps-container">
          {formWrapper.render()}
          <div className="sticky-btm button-set mt-20 trio trio-for-small">
            <Button
              type="primary"
              disabled={loading}
              loading={loading}
              htmlType="submit"
            >
              {validation.hasCompany ? 'Update' : 'Save and continue'}
            </Button>
            <NextStep />
          </div>
        </div>
      </Form>
    </div>
  );
};

type IWrappedForm = (props: any) => React.ReactElement;
const WrappedForm: IWrappedForm = Form.create()(BaseForm) as any;

const CompanyForm = () => {
  const schema = useSchema('company');
  return <WrappedForm schema={schema} />;
};
