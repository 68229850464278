import React, { useCallback } from 'react';
import { api } from '~/api';
import debounce from 'lodash.debounce';
import { useFilters } from './context';

type IToggleFavourite = {
  tenderId: string;
  offerId: string;
  active: boolean;
  hasSeparator: boolean;
};
export const ToggleFavourite = ({
  tenderId,
  offerId,
  active,
  hasSeparator,
}: IToggleFavourite) => {
  const { setlocalFavourites } = useFilters();

  const updateFav = useCallback(
    debounce(async () => {
      try {
        if (active === false) {
          await api.putData(`/tenders/${tenderId}/favourites/${offerId}`);
        } else if (active === true) {
          await api.deleteData(`/tenders/${tenderId}/favourites/${offerId}`);
        }
      } catch (e) {
        // silently fail for now
      }
    }, 500),
    [active]
  );

  return (
    <button
      className={`fav-button ${hasSeparator ? 'has-separator' : ''}`}
      onClick={e => {
        e.stopPropagation();
        updateFav();
        setlocalFavourites(prev => ({ ...prev, [offerId]: !active }));
      }}
    >
      <svg
        className="favourite"
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="currentColor"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        {active ? (
          <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z" />
        ) : (
          <path d="M12 7.13l.97 2.29.47 1.11 1.2.1 2.47.21-1.88 1.63-.91.79.27 1.18.56 2.41-2.12-1.28-1.03-.64-1.03.62-2.12 1.28.56-2.41.27-1.18-.91-.79-1.88-1.63 2.47-.21 1.2-.1.47-1.11.97-2.27M12 2L9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2z" />
        )}
      </svg>
    </button>
  );
};

export const ToggleFavouriteFilter = () => {
  const { favFilterOn, setFavFilterOn } = useFilters();
  return (
    <button
      className={`tender-filter-button ${favFilterOn ? 'active' : ''}`}
      onClick={() => setFavFilterOn?.(!favFilterOn)}
    >
      <svg
        className="favourite"
        xmlns="http://www.w3.org/2000/svg"
        width="18px"
        height="18px"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z" />
      </svg>
    </button>
  );
};
