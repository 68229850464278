import React from 'react';
import * as R from 'ramda';
import { FormattedMessage } from 'react-intl';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const RefPriceContentColumn = ColumnTree.defineNode(
  'ref-price-content',
  _context => ({
    title: '',
    key: 'ref-price',
    className: 'col-ref-price',
    render: (text, record, index) => {
      return (
        <div className="td-content">
          <span className="tiny-label">
            <FormattedMessage
              id="marketplace-table-cellheading-referenceprice"
              description="Table cell heading"
              defaultMessage="Reference price"
            />
          </span>
          {record.price.std && (
            <span>
              <strong className="all-black">
                {R.path(['price', 'std', 'price'], record)}
              </strong>{' '}
              <span className="unit">
                {R.path(['price', 'std', 'unit'], record)}
              </span>
            </span>
          )}
        </div>
      );
    },
  })
);
