import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { Link } from 'react-router-dom';
import { FormWrapper } from 'Components/form';
import { mergeSchema, normalizeChoices } from 'Components/form/utils';
import { useWarehouses } from './utils';
import { useStoreState } from '~/store';
import * as R from 'ramda';
import { promisify } from 'es6-promisify';
import { Wizard, WizardStep } from 'Components/Wizard';
import { useSiteTitle } from '~/hooks';
import { Alert, Form, Button } from 'Components/nui';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';

function useFormWrapper({ form, schema }) {
  const countries = R.fromPairs(schema.country.choices);
  const fields = [
    {
      name: 'description',
      label: 'Location name or description',
      type: 'Input',
    },
    {
      name: 'default',
      label: 'Default location?',
      type: 'Boolean',
      initialValue: false,
    },
    {
      name: 'street',
      label: 'Street',
      type: 'Input',
    },
    {
      name: 'suburb',
      label: 'Suburb',
      type: 'Input',
    },
    {
      name: 'city',
      label: 'City',
      type: 'Input',
    },
    {
      name: 'state',
      label: 'State',
      type: 'Input',
    },
    {
      name: 'country',
      label: 'Country',
      type: 'Select',
      choices: R.pipe(
        R.propOr([], 'choices'),
        R.filter(([code]) =>
          R.ifElse(
            R.isEmpty,
            R.T,
            R.pipe(R.keys, R.includes(code))
          )(R.pathOr({}, ['port', 'choices'], schema))
        ),
        R.sort(([, first], [, second]) => first.localeCompare(second)),
        R.map(([value, label]) => ({ value, label, key: value }))
      ),
    },
    {
      name: 'zip',
      label: 'Zip',
      type: 'Input',
    },
    {
      name: 'port',
      label: 'Port',
      choices: R.pipe(
        R.propOr({}, 'choices'),
        R.toPairs,
        R.filter(([code]) =>
          R.equals(code, R.propOr(code, 'country', form.getFieldsValue()))
        ),
        R.sort(([first], [second]) =>
          R.propOr('', first, countries).localeCompare(
            R.propOr('', second, countries)
          )
        ),
        R.map(([country, ports]) => ({
          key: country,
          label: countries[country],
          value: ports.map(([value, label]) => ({ value, label, key: value })),
        }))
      ),
    },
    {
      name: 'zone',
      label: 'Zone',
      choices: normalizeChoices(schema.zone?.choices || []),
    },
  ];

  return new FormWrapper(form, {}, mergeSchema(schema, fields));
}

function BaseForm({ form, schema, divisionId, warehouses, onComplete }) {
  const formWrapper = useFormWrapper({ form, schema });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }

    setLoading(true);

    const data = formWrapper.serialize();

    try {
      const result = await warehouses.add(divisionId, data);
      form.resetFields();
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setLoading(false);
      onComplete();
    } catch (err) {
      formWrapper.setErrors(err);
      setLoading(false);
    }
  }

  return (
    <div className="nui-form">
      <Form onSubmit={handleSubmit}>
        {formWrapper.render()}
        <div>
          <Button
            type="primary"
            htmlType="submit"
            disabled={loading}
            className="mb-40"
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}

const WarehouseForm = Form.create()(BaseForm);

function AddWarehouse({ warehouses }) {
  const [divisionId, setDivisionId] = useState('');
  const [schema, setSchema] = useState({});
  const [schemaLoading, setSchemaLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const auth = useStoreState(R.prop('auth'));
  const solution = useStoreState(R.path(['auth', 'solution']));
  const divisions = useStoreState(R.pathOr([], ['auth', 'canManageDivisions']));

  const hasSchema = Object.keys(schema).length;

  const displayFreightMessage =
    !auth.isSolutionOwner &&
    auth.solution.freight &&
    auth.solution.freight !== 'port';

  // XXX uncomment this to have a division selected by default at form load // I prefer this option
  useEffect(() => {
    if (!divisionId) setDivisionId(R.propOr({ id: '' }, 0, divisions).id);
  }, []);

  const getSchema = async () => {
    setSchemaLoading(true);
    const result = await warehouses.schema(divisionId);
    setSchema(result);
    setSchemaLoading(false);
  };

  useEffect(() => {
    if (!hasSchema && divisionId) getSchema();
  }, [divisionId]);

  const onDivisionChange = value => {
    setDivisionId(value);
    getSchema(value);
  };

  const onComplete = () => {
    setCompleted(true);
  };

  return (
    <div className="warehouses-form">
      {completed && (
        <>
          <Alert hasicon className="max mb-20" type="success">
            {displayFreightMessage ? (
              <>
                <strong className="all-black block">
                  Location created successfully
                </strong>
                Your new location has been created. You will be able to utilise
                the location once {solution.owner.name} has activated it. Get in
                touch with {solution.owner.name} if you require a status update.
              </>
            ) : (
              <strong className="all-black">
                Your location has been added successfully.
              </strong>
            )}
          </Alert>
          <h2 className="light pb-20">Add another location</h2>
        </>
      )}
      <Wizard>
        <WizardStep
          stepId={1}
          title="Location's division"
          description="Select the division this location will belong to"
          completed={!!divisionId}
          active={!divisionId}
        >
          <Form.Item label="Division" className="m-0 pb-20 w-400">
            <Select
              defaultValue={R.path([0, 'id'], divisions)}
              value={divisionId}
              onChange={onDivisionChange}
            >
              {divisions.map(d => (
                <Select.Option value={d.id} key={d.id}>
                  {d.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </WizardStep>
        <WizardStep
          stepId={2}
          title="Location details"
          description="Add the description and address details for this location"
          disabled={!divisionId}
          active={divisionId}
        >
          {hasSchema && !schemaLoading ? (
            <WarehouseForm
              schema={schema.fields}
              divisionId={divisionId}
              warehouses={warehouses}
              onComplete={onComplete}
            />
          ) : schemaLoading ? (
            'Loading...'
          ) : (
            <Alert
              type="warning"
              className="mb-10 w-461 pt-10 pb-10 align-center"
            >
              Please select a division.
            </Alert>
          )}
        </WizardStep>
      </Wizard>
    </div>
  );
}

export default function Edit() {
  const warehouses = useWarehouses();

  useSiteTitle('Add location');

  return (
    <>
      <div className="full-page-header">
        <h1 className="inline mt-10 mr-20 mb-0">Add a location</h1>

        <ul className="nui-bc right pt-20">
          <li>
            <BreadcrumbRoot active="locations" />
          </li>
          <li>
            <Link to="/locations">Locations</Link>
          </li>
          <li>New</li>
        </ul>

        <hr />

        <AddWarehouse warehouses={warehouses} />
      </div>
    </>
  );
}
