import React, { useEffect, useState } from 'react';
import { Form, Button, Checkbox, Alert, Loading } from 'Components/nui';
import { FormWrapper } from 'Components/form';
import {
  mergeSchema,
  normalizeChoices,
  submitError,
} from 'Components/form/utils';
import { LoadingState } from 'Components/Layout';
import { ShowResult } from 'Components/Result';
import { promisify } from 'es6-promisify';
import * as R from 'ramda';
import { useAsync, createStateContext, useBoolean } from 'react-use';
import { useParams, Link } from 'react-router-dom';
import Helmet from 'Components/Helmet';
import YesNo from 'Components/YesNo';
import { useSharedProduct } from '..';
import { toast } from 'react-toastify';
import { api } from '~/api';

const [useSharedLoadings, SharedLoadingsProvider] = createStateContext(null);

const submitSuccess = (result, submitState) => (
  <Alert hasicon type="success" className="mb-20">
    <h3>Success</h3>
    <p>Successfully added new loading option.</p>
    <Button
      type="reverse"
      size="small"
      className="mb-10 mt--10"
      onClick={() => {
        submitState.setResult();
      }}
    >
      Add another
    </Button>
  </Alert>
);

const PForm = ({ form, schema, submitState }) => {
  const [product] = useSharedProduct();
  const [loadings, setLoadings] = useSharedLoadings();
  const [fixed, setFixed] = useBoolean(false);

  const getFormWrapper = () => {
    const measure = R.pathOr('', ['loadingunit', 'desc'], product);

    const fields = [
      {
        render: () => (
          <div key="head_shipping">
            <h4 className="m-0 mb-10">Shipping unit</h4>
          </div>
        ),
      },
      {
        key: 'container',
        name: 'container',
        label: 'Unit type',
        choices: normalizeChoices,
      },
      {
        key: 'reefer',
        name: 'reefer',
        label: 'Is reefer',
        initialValue: false,
      },
      {
        render: () => (
          <div>
            <Checkbox checked={fixed} onClick={() => setFixed(state => !state)}>
              Fixed loading weight
            </Checkbox>
          </div>
        ),
      },
      {
        key: 'qty',
        name: 'qty',
        label: `Loading weight (${measure})`,
        initialValue: 0.01,
        props: { disabled: !fixed },
        step: 0.01,
        min: 0.01,
      },
      {
        render: () => (
          <div key="head_packing">
            <h4 className="m-0 mb-10">Packaging</h4>
          </div>
        ),
      },
      {
        key: 'packaging',
        name: 'packaging',
        label: 'Type',
        choices: normalizeChoices,
      },
      {
        key: 'packing_load',
        name: 'packing_load',
        label: 'Number of packaging units',
        step: 1,
        min: 1,
      },
      {
        key: 'variable',
        name: 'variable',
        label: 'Loading variable',
        initialValue: false,
      },
    ];

    return new FormWrapper(form, {}, mergeSchema(schema, fields));
  };

  const formWrapper = getFormWrapper({
    form,
    schema,
    product,
  });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }
    submitState.setLoading(true);
    let data = formWrapper.serialize();
    if (!fixed) {
      data.qty = undefined;
    }

    try {
      const result = await api.getData(
        { type: 'products', id: `${product.id}/loading`, getResult: x => x },
        null,
        { method: 'post', data }
      );
      submitState.setResult(result);
      setLoadings(R.append(result.loading, loadings));
    } catch (err) {
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        err
      );
      if (errors) {
        formWrapper.setErrors(errors);
      } else {
        submitState.setResult(err);
      }
    }
    submitState.setLoading(false);
  }

  return (
    <Form onSubmit={handleSubmit}>
      {formWrapper.render()}
      <Button
        type="secondary"
        htmlType="submit"
        className="m-0 mt-0"
        loading={submitState.loading}
      >
        Save
      </Button>
    </Form>
  );
};

const ListingItem = ({ item }) => {
  const [product] = useSharedProduct();

  const [visible, setVisible] = useState(true);
  const [deleting, setDeleting] = useState(false);

  const deleteLoading = async () => {
    setDeleting(true);
    await api.getData(
      {
        type: 'products',
        id: `${product.id}/loading/${item.id}`,
      },
      null,
      { method: 'delete' }
    );
    setVisible(false);
    toast('Loading configuration removed', {
      type: 'success',
    });
  };

  const measure = R.pathOr('', ['loadingunit', 'desc'], product);

  if (!visible) return null;

  if (deleting)
    return (
      <li>
        <Loading size="medium" />
      </li>
    );

  return (
    <li>
      <label>
        <dl className="list no-ws">
          {item.container && (
            <>
              <dt>Shipping unit type</dt>
              <dd>{item.container}</dd>
            </>
          )}

          {item.reefer && (
            <>
              <dt>Reefer</dt>
              <dd>
                <YesNo yes={item.reefer} />
              </dd>
            </>
          )}

          <dt>Loading weight</dt>
          <dd>
            {item.fixed ? (
              <>
                {item.qty} {measure}
              </>
            ) : (
              'Variable'
            )}
          </dd>

          {item.packaging && (
            <>
              <dt>Packaging type</dt>
              <dd>{item.packaging}</dd>
            </>
          )}

          {'packing_load' in item && (
            <>
              <dt>Number of packages</dt>
              <dd>{item.packing_load}</dd>
            </>
          )}
        </dl>
      </label>

      {/* // !! add modal with confirmation to delete! */}
      <div className="actions">
        <Button
          type="reverse"
          className="nui-button-icon ml-5"
          onClick={deleteLoading}
        >
          <span className="icon-trash"></span>
        </Button>
      </div>
    </li>
  );
};

const List = () => {
  const { id } = useParams();

  const [loadings, setLoadings] = useSharedLoadings();

  useEffect(() => {
    const fetch = async () => {
      const response = await api.getData({
        type: 'products',
        id: `${id}/loading`,
      });

      setLoadings(response);
    };

    fetch();
  }, [api, id, setLoadings]);

  if (loadings === null) return <Loading size="medium" />;

  return (
    <ul className="item-list mt-12 no-ws">
      {loadings.map(item => (
        <ListingItem key={item.id} item={item} />
      ))}
    </ul>
  );
};

const WrappedForm = Form.create()(PForm);

export default () => {
  const { id } = useParams();
  const [product] = useSharedProduct();

  const state = useAsync(() => {
    return api.getData({ type: 'products', id: `${id}/loading/schema` });
  }, [id]);

  if (!product) return null;

  return (
    <>
      <Helmet>
        <title>Edit product {product.name} - Loading options</title>
      </Helmet>
      <SharedLoadingsProvider>
        <List />
        <ShowResult renderSuccess={submitSuccess} renderError={submitError}>
          {submitState => (
            <div className="wrapper">
              <div className="nui-form order-form inset-form mb-20">
                <h3 className="mt--10">New loading option</h3>
                <LoadingState state={state}>
                  {schema => (
                    <WrappedForm
                      key="product-loading"
                      schema={schema}
                      submitState={submitState}
                    />
                  )}
                </LoadingState>
              </div>
            </div>
          )}
        </ShowResult>
      </SharedLoadingsProvider>
      <div className="sticky-btm">
        <Link
          className="button primary mb-10"
          to={`/products/${product.id}/edit/attributes`}
        >
          Next step
        </Link>
      </div>
    </>
  );
};
