import React, { useState, useEffect, useRef } from 'react';
import { Input } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useHistory, useLocation } from 'react-router-dom';
import footerlogo from 'Assets/img/mono-logo-black.png';
import moment from 'moment-timezone';
import { api } from '~/api';
import { getEnv, parseSearch } from '~/utils';
import * as R from 'ramda';
import MaskedInput from 'react-text-mask';
import Helmet from 'Components/Helmet';
import EnvStripe from 'Components/EnvStripe';
import { Button, Form } from 'Components/nui';
import LoadingSite from 'Components/LoadingSite';
import { FormattedMessage, useIntl } from 'react-intl';

const validateEmail = email => /\S+@\S+\.\S+/.test(email);

export default () => {
  const { formatMessage } = useIntl();
  const loading = useStoreState(state => state.auth.loading);
  const setLoading = useStoreActions(actions => actions.auth.setLoading);
  const checkAuth = useStoreActions(actions => actions.auth.checkAuth);

  const clientId = getEnv(import.meta.env.VITE_APP_WEB_CLIENT_ID);

  const history = useHistory();
  const search = parseSearch(R.path(['location', 'search'], history));

  const location = useLocation();
  const camefrom = R.pathOr('/', ['state', 'from'], location);

  const solution = R.path(['solution'], search);
  const urlSolution = R.path(['solution_id'], search);

  useEffect(() => {
    document.body.classList.add('nui-login');
    return () => document.body.classList.remove('nui-login');
  }, []);

  const refEmail = useRef(null);

  const [step, setStep] = useState(search.token ? 2 : 1);
  const [email, setEmail] = useState('');
  const [token, setToken] = useState(search.token || '');

  const initialFi = {
    hasFeedback: false,
    validateStatus: null,
    help: '',
  };
  const [fi, setFi] = useState(initialFi);

  useEffect(() => {
    if (refEmail.current) refEmail.current.focus();
  }, []);

  function resetFi() {
    setFi(initialFi);
  }

  function stepBack() {
    setStep(1);
    resetFi();
  }

  async function authEmail() {
    if (!validateEmail(email)) {
      setFi({
        hasFeedback: true,
        validateStatus: 'error',
        help: formatMessage({
          id: 'sign-in-form-email-error',
          description: 'Label for Email error in sign in form',
          defaultMessage: 'Please provide a valid email address',
        }),
      });
      return;
    }
    setLoading(true);
    setToken('');
    setFi({
      hasFeedback: true,
      validateStatus: 'success',
      help: '',
    });

    try {
      await api.signIn(email, solution);
      setLoading(false);
      setStep(2);
      resetFi();
    } catch (error) {
      setFi({
        hasFeedback: true,
        validateStatus: 'error',
        help: formatMessage({
          id: 'sign-in-form-unknown-error',
          description: 'Label for unknown error in sign in form',
          defaultMessage:
            'Something went wrong. Please refresh the page and try again.',
        }),
      });
    }
  }

  async function callbackAuth() {
    setLoading(true);
    try {
      const response = await api.authenticate(token, clientId);

      setFi({
        hasFeedback: true,
        validateStatus: 'success',
        help: formatMessage({
          id: 'sign-in-form-redirecting',
          description: 'Label for Redirecting in sign in form',
          defaultMessage: 'Redirecting...',
        }),
      });
      const { token_type, user_id, access_token, expires_in } = response;
      await checkAuth({
        token: {
          user_id,
          access_token,
          token_type,
          expires_in,
        },
        target: R.pathOr({}, ['target'], response),
      });
    } catch (error) {
      setLoading(false);
      setFi({
        hasFeedback: true,
        validateStatus: 'error',
        help: R.pathOr(
          formatMessage({
            id: 'sign-in-form-callback-error',
            description: 'Label for callback error in sign in form',
            defaultMessage:
              'An error occurred please refresh the page and try again',
          }),
          ['response', 'data', 'error_description'],
          error
        ),
      });
    }
  }

  useEffect(() => {
    if (token) {
      // try to sign in with token
      callbackAuth();
    } else if (camefrom?.hash !== '#signout') {
      // try to refresh token
      checkAuth({ camefrom, urlSolution });
    }
  }, []);

  // avoid login form flashing when coming from admin link
  if (search.token && loading) {
    return <LoadingSite />;
  }
  return (
    <>
      <Helmet>
        <title>
          {formatMessage({
            id: 'sign-in-page-meta-title',
            description: 'Label for Sign in title',
            defaultMessage: 'Sign in',
          })}
        </title>
      </Helmet>
      <EnvStripe />
      <div className="shell">
        <div className="nui-login-form">
          <h1>
            <FormattedMessage
              id="sign-in-page-title"
              description="Label for Please sign in title"
              defaultMessage="Please sign in"
            />
          </h1>
          <img
            src={`https://cream-media.nuimarkets.com/logo/signin/${
              solution || 'nui'
            }.png`}
            alt="Nui Markets"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src =
                'https://cream-media.nuimarkets.com/logo/signin/nui.png';
            }}
          />

          {step === 1 ? (
            <>
              <Form.Item
                hasFeedback={fi.hasFeedback}
                validateStatus={fi.validateStatus}
                help={fi.help}
              >
                <Input
                  ref={refEmail}
                  placeholder="Your email"
                  id="email"
                  type="email"
                  size="large"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  onPressEnter={authEmail}
                />
              </Form.Item>
              <Button
                type="primary"
                className="w-200"
                loading={loading}
                onClick={authEmail}
              >
                <span>
                  <FormattedMessage
                    id="sign-in-form-continue"
                    description="Label for Continue button in sign in form"
                    defaultMessage="Continue"
                  />
                </span>
              </Button>
            </>
          ) : (
            <>
              {email ? (
                <>
                  <h2>
                    <FormattedMessage
                      id="sign-in-page-form-title-check-your-inbox"
                      description="Label for Check your inbox in sign in form"
                      defaultMessage="Check your inbox"
                    />
                  </h2>
                  <p>
                    <FormattedMessage
                      id="emailTokenMessage"
                      defaultMessage="We just emailed a token to {email}. Please enter it below or {tryAgain} if you have not received anything."
                      values={{
                        email: <strong className="all-black">{email}</strong>,
                        tryAgain: (
                          <button className="inline-button" onClick={stepBack}>
                            try again
                          </button>
                        ),
                      }}
                    />
                  </p>
                </>
              ) : (
                <>
                  {fi && fi.validateStatus === 'success' ? (
                    <h2>
                      <FormattedMessage
                        id="sign-in-page-form-redirecting"
                        description="Label for Redirecting... in sign in form"
                        defaultMessage="Redirecting..."
                      />
                    </h2>
                  ) : (
                    <>
                      <h2>
                        <FormattedMessage
                          id="sign-in-page-form-authentication"
                          description="Label for Authentication in sign in form"
                          defaultMessage="Authentication"
                        />
                      </h2>
                      <p>
                        <FormattedMessage
                          id="sign-in-page-form-no-longer-valid"
                          description="Label for The sign in link is no longer valid in sign in form"
                          defaultMessage="The sign in link is no longer valid."
                        />{' '}
                        <button
                          className="inline-button"
                          onClick={() => {
                            history.push('/signin');
                            stepBack();
                          }}
                        >
                          <FormattedMessage
                            id="sign-in-page-form-start-over"
                            description="Label for Please start over in sign in form"
                            defaultMessage="Please start over"
                          />
                        </button>
                      </p>
                    </>
                  )}
                </>
              )}
              <Form.Item
                hasFeedback={fi.hasFeedback}
                validateStatus={fi.validateStatus}
                help={fi.help}
              >
                <MaskedInput
                  mask={[
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    '-',
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    '-',
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    '-',
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                  ]}
                  className="ant-input ant-input-lg"
                  placeholder="Token"
                  guide={false}
                  id="token"
                  autoComplete="off"
                  onChange={e => setToken(e.target.value)}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      callbackAuth();
                    }
                  }}
                  value={token}
                />
              </Form.Item>
              <div className="pl-20 pr-20 mt--10">
                <Button type="primary" loading={loading} onClick={callbackAuth}>
                  <span>
                    <FormattedMessage
                      id="sign-in-form-verify"
                      description="Label for verify button in sign in form"
                      defaultMessage="Verify"
                    />
                  </span>
                </Button>
              </div>
            </>
          )}
        </div>

        <div className="nui-login-footer">
          <img src={footerlogo} alt="Nui Markets logo" />
          <span data-test-class="playwright-hide">
            &copy; 2014 - {moment().year()} Nui Markets
          </span>{' '}
          <span className="show-for-medium pl-5 pr-5">|</span>
          <span className="block-for-small">
            <strong>
              <FormattedMessage
                id="sign-in-page-footer-trouble-signing-in"
                description="Label for Trouble signing in in sign in page"
                defaultMessage="Trouble signing in?"
              />
            </strong>{' '}
            <a
              href="https://www.nuimarkets.com/contact/"
              title="Contact us if you are having trouble signing in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="sign-in-page-footer-contact-us"
                description="Label for Contact us in sign in page footer"
                defaultMessage="Contact us"
              />
            </a>
          </span>
          <ul>
            <li>
              <a
                href="https://www.nuimarkets.com/privacy/"
                title="Privacy policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage
                  id="sign-in-page-footer-privacy-policy"
                  description="Label for Privacy policy in sign in page footer"
                  defaultMessage="Privacy policy"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.nuimarkets.com/terms/"
                title="Legal terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage
                  id="sign-in-page-footer-legal-terms"
                  description="Label for Legal terms in sign in page footer"
                  defaultMessage="Legal terms"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
