import type { ICustomerRequest } from '~/store/models';
import * as React from 'react';
import { useTable } from './ViewContext';
import * as R from 'ramda';
import classnames from 'classnames';

type TFormStatus = ICustomerRequest['formstatus'];

const statuses = [
  ['incomplete', 'Incomplete'],
  ['rejected', 'Rejected'],
  ['cancelled', 'Cancelled'],
  ['pending', 'Pending'],
  ['processed', 'Processed'],
];

interface ICheckbox {
  name: string;
  value: boolean;
  title: string;
  disabled: boolean;
  onChange: () => void;
}
const Checkbox = ({ name, title, value, disabled, onChange }: ICheckbox) => {
  return (
    <div className={classnames('nui-check-fieldset', { disabled })}>
      <input
        type="checkbox"
        id={name}
        checked={value}
        disabled={disabled}
        onChange={onChange}
      />
      <label htmlFor={name} title={title} className="nui-label">
        <span>{title}</span>
      </label>
    </div>
  );
};

export default () => {
  const { formstatus, filter, loading } = useTable();

  const checked = (status: TFormStatus) => R.includes(status, formstatus);
  const update = (status: TFormStatus) =>
    checked(status)
      ? filter(R.reject(R.equals(status), formstatus))
      : filter([...formstatus, status]);

  return (
    <div className="customer-request-filter-block">
      <span className="status">Filter by status</span>
      {statuses.map(([name, title]) => (
        <Checkbox
          name={name}
          title={title}
          value={checked(name as TFormStatus)}
          disabled={loading}
          onChange={() => {
            update(name as TFormStatus);
          }}
        />
      ))}
    </div>
  );
};
