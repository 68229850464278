import React from 'react';
import * as R from 'ramda';

export default ({ address, fullformat }) => {
  if (fullformat)
    return (
      <address>
        <ul>
          <li>
            <small>Street</small> {R.prop('street', address)}
          </li>
          <li>
            <small>Suburb</small> {R.prop('suburb', address)}
          </li>
          <li>
            <small>City</small> {R.prop('city', address)}
          </li>
          <li>
            <small>State</small> {R.prop('state', address)}
          </li>
          <li>
            <small>Region</small> {R.prop('region', address)}
          </li>
          <li>
            <small>Country</small>{' '}
            {R.pathOr('n/a', ['country', 'name'], address)}
          </li>
          <li>
            <small>Zip</small> {R.prop('zip', address)}
          </li>
        </ul>
      </address>
    );

  return (
    <address>
      {R.prop('street', address)} {R.prop('suburb', address)}{' '}
      {R.prop('city', address)} {R.prop('state', address)}{' '}
      {R.prop('region', address)} {R.path(['country', 'name'], address)}{' '}
      <span className="nowrap">{R.prop('zip', address)}</span>
    </address>
  );
};
