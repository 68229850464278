import React, { useState } from 'react';
import { useStoreActions } from 'easy-peasy';
import { useToggle } from 'react-use';
import classNames from 'classnames';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from '~/api';
import { Button, Modal } from 'Components/nui';
import { FormattedMessage } from 'react-intl';

export default ({ order }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const updateMPlace = useStoreActions(
    actions => actions.marketplace.updateData
  );
  const updateMDepth = useStoreActions(actions => actions.market.update);

  const [deleting, setDeleting] = useState(false);
  const [on, toggle] = useToggle();

  const deleteOrder = async () => {
    setDeleting(true);
    await api.getData(
      {
        type: 'orders',
        id: order.id,
      },
      null,
      { method: 'delete' }
    );

    toast.success('The order was cancelled');

    updateMDepth();
    updateMPlace();
    history.replace(pathname);
    toggle(false);
  };

  return (
    <div className="bid-message">
      <div className="nowrap">
        <Link
          to={`/orders/${order.pid}/edit`}
          className={classNames('button', order.type, 'small')}
        >
          <FormattedMessage
            id="marketrow-button-edit-new"
            description="button for counters 'Edit' in Marketrow"
            defaultMessage="Edit  {orderType, select, bid{bid} offer{offer} other{{orderType}}}"
            values={{ orderType: order.type }}
          />
        </Link>

        <Button type="simple" size="small" onClick={toggle}>
          <FormattedMessage
            id="marketrow-counters-button-delete"
            description="button for counters 'Delete' in Marketrow"
            defaultMessage="Delete"
          />
        </Button>

        {on && (
          <Modal size="small" close={toggle}>
            <h2>
              <FormattedMessage
                id="marketrow-deletemodal-header"
                description="Header for delete modal in Marketrow"
                defaultMessage="Warning"
              />
            </h2>
            <p>
              <FormattedMessage
                id="marketrow-deletemodal-helptext"
                description="helptext for delete modal in Marketrow"
                defaultMessage="Are you sure you want to delete the order? You wouldn't
                  be able to undo this operation."
              />
            </p>

            <hr />
            <div className="button-set">
              <Button type="primary" onClick={deleteOrder} loading={deleting}>
                <FormattedMessage
                  id="marketrow-deletemodal-button-yes"
                  description="button for delete modal: yes proceed in Marketrow"
                  defaultMessage="Yes, proceed"
                />
              </Button>
              <Button type="simple" onClick={toggle}>
                <FormattedMessage
                  id="marketrow-deletemodal-button-no"
                  description="button for counters 'No, go back!' in Marketrow"
                  defaultMessage="No, go back!"
                />
              </Button>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};
