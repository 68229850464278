import React, { useEffect, useState } from 'react';
import { useStoreState } from '~/store';
import {
  Table,
  Tooltip,
  Loading,
  Button,
  Checkbox,
  Input,
} from 'Components/nui';
import { Link } from 'react-router-dom';
import * as R from 'ramda';
import { useScrollToTopOnMount, useBodyClassReverse } from '~/hooks.js';
import { api } from '~/api';
import Header from './Header';
import Helmet from 'Components/Helmet';
import FNumber from 'Components/FNumber';
import { useToggle } from 'react-use';

import { toast } from 'react-toastify';
import YesNo from 'Components/YesNo';
import classnames from 'classnames';
import { InputNumber, Select } from 'antd';
import { canEditCustomer } from '~/utils';
import { invertDivisionType } from '~/utils';
import RowToggle from 'Components/nui/RowToggle';
import CustomerFilters from 'Components/filters/CustomerFilters';
import { FiltersProvider, useFilters } from 'Components/filters/context';
import filterCustomers from 'Components/filters/filterCustomers';

const DetailRowHeader = () => {
  const solution = useStoreState(state => state.auth.solution);

  return (
    <thead>
      <tr>
        <th className="col-seller-name shaded-right">
          <div className="th-content">Sellers</div>
        </th>
        <th className="col-docs nowrap">
          <div className="th-content">Approved</div>
        </th>
        <th className="col-credit nowrap">
          <div className="th-content">Credit limit</div>
        </th>
        <th className="col-credit-limit nowrap">
          <div className="th-content">
            Amount{' '}
            <span className="unit mid-gray inline-block valign-b">
              ({solution.currency})
            </span>
          </div>
        </th>
        <th className="col-credit-remaining nowrap">
          <div className="th-content">
            Remaining{' '}
            <span className="unit mid-gray inline-block valign-b">
              ({solution.currency})
            </span>
          </div>
        </th>
        <th className="col-limit-period nowrap">
          <div className="th-content">Period</div>
        </th>
        <th className="col-payment-term nowrap">
          <div className="th-content">Payment term</div>
        </th>
        <th className="col-reference">
          <div className="th-content">Reference</div>
        </th>
        <th className="col-editme">
          <div className="th-content">&nbsp;</div>
        </th>
      </tr>
    </thead>
  );
};

const EditLinkRow = ({ link, toggle, setRefresh }) => {
  const [schema, setSchema] = useState(null);
  const [reference, setReference] = useState('');
  const [docsapproved, setDocsapproved] = useToggle(false);
  const [credit, setCredit] = useToggle(false);
  const [creditamount, setCreditamount] = useState(0);
  const [creditperiod, setCreditperiod] = useState(30000);
  const [paymentterm, setPaymentterm] = useState('');

  useEffect(() => {
    const fetch = async () => {
      const params = {
        division: link.seller.id,
      };
      const response = await api.getData2(
        `/customers/${link.buyer.id}/schema`,
        params
      );
      setSchema(response.fields);
    };
    fetch();
  }, [link.seller.id, link.buyer.id]);

  useEffect(() => {
    setReference(R.pathOr('', ['reference', 'value'], schema));
    setDocsapproved(R.pathOr(false, ['docsapproved', 'value'], schema));
    setCredit(!R.pathOr(false, ['nocreditlimit', 'value'], schema));
    setCreditamount(R.pathOr(0, ['creditamount', 'value'], schema));
    setCreditperiod(R.pathOr(0, ['creditperiod', 'value'], schema));
    setPaymentterm(R.pathOr('', ['paymentterm', 'value'], schema));
  }, [schema]);

  const save = async () => {
    try {
      const result = await api.putData(`/customers/${link.buyer.id}`, {
        reference,
        docsapproved,
        creditamount,
        creditperiod,
        paymentterm,
        nocreditlimit: !credit,
        division: link.seller.id,
      });
      setRefresh();
      toast.success('Customers details are saved');
      toggle();
    } catch (err) {
      toast.success('Customers details are NOT saved');
    }
  };

  if (!schema) {
    return (
      <tr>
        <td className="col-seller-name shaded-right">
          <div className="td-content">
            {R.pathOr('n/a', ['seller', 'name'], link)} (
            {R.pathOr('n/a', ['seller', 'shortcode'], link)})
          </div>
        </td>
        <td colSpan="7">
          <Loading size="small" />
        </td>
        <td>
          <Button htmlType="button" type="simple" onClick={toggle}>
            Cancel
          </Button>
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td className="col-seller-name shaded-right">
        <div className="td-content">
          {R.pathOr('n/a', ['seller', 'name'], link)} (
          {R.pathOr('n/a', ['seller', 'shortcode'], link)})
        </div>
      </td>

      <td className="col-docs nowrap">
        <div className="td-content">
          <Checkbox checked={docsapproved} onChange={setDocsapproved}>
            <span />
          </Checkbox>
        </div>
      </td>
      <td className="col-credit nowrap">
        <div className="td-content">
          <Checkbox checked={credit} onChange={setCredit}>
            <span />
          </Checkbox>
        </div>
      </td>

      <td className="col-credit-limit nowrap">
        <div className="td-content">
          {credit && (
            <div className="nui-fieldset">
              <div className="input-holder">
                <InputNumber
                  min={0}
                  step={500}
                  size="large"
                  value={creditamount}
                  onChange={v => setCreditamount(v)}
                />
              </div>
            </div>
          )}
        </div>
      </td>
      <td className="col-credit-remaining nowrap">
        <div className="td-content" />
      </td>
      <td className="col-limit-period nowrap">
        <div className="td-content">
          {credit && (
            <div className="nui-fieldset">
              <div className="select-holder">
                <Select
                  value={creditperiod}
                  onChange={value => {
                    setCreditperiod(value);
                  }}
                >
                  {schema.creditperiod.choices.map(item => (
                    <Select.Option value={item[0]} key={item[0]}>
                      {item[1]}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          )}
        </div>
      </td>

      <td className="col-payment-term nowrap">
        <div className="td-content">
          <div className="nui-fieldset">
            <div className="select-holder">
              <Select
                value={paymentterm}
                onChange={value => {
                  setPaymentterm(value);
                }}
              >
                {schema.paymentterm.choices.map(item => (
                  <Select.Option value={item[0]} key={item[0]}>
                    {item[1]}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </td>
      <td className="col-reference">
        <div className="td-content">
          <div className="nui-fieldset">
            <div className="input-holder">
              <Input
                value={reference}
                onChange={e => setReference(e.target.value)}
                className=""
              />
            </div>
          </div>
        </div>
      </td>

      <td className="col-editme">
        <div className="td-content">
          <div className="button-set">
            <Button htmlType="button" type="primary" onClick={save}>
              Update
            </Button>
            <Button htmlType="button" type="simple" onClick={toggle}>
              Cancel
            </Button>
          </div>
        </div>
      </td>
    </tr>
  );
};

const LinkRow = ({ link, setRefresh, editable }) => {
  const [on, toggle] = useToggle();

  if (on) {
    return <EditLinkRow link={link} toggle={toggle} setRefresh={setRefresh} />;
  }

  return (
    <tr>
      <td className="col-seller-name shaded-right">
        <div className="td-content seller-name">
          {R.pathOr('n/a', ['seller', 'name'], link)} (
          {R.pathOr('n/a', ['seller', 'shortcode'], link)})
        </div>
      </td>

      <td className="col-docs nowrap">
        <div className="td-content">
          <YesNo yes={R.pathOr(false, ['seller', 'docsapproved'], link)} />
        </div>
      </td>
      <td className="col-credit nowrap">
        <div className="td-content">
          <YesNo yes={!link.nocreditlimit} />
        </div>
      </td>

      {link.nocreditlimit ? (
        <>
          <td className="col-credit-limit nowrap">
            <div className="td-content" />
          </td>
          <td className="col-credit-remaining nowrap">
            <div className="td-content" />
          </td>
          <td className="col-limit-period nowrap">
            <div className="td-content" />
          </td>
        </>
      ) : (
        <>
          <td className="col-credit-limit nowrap">
            <div className="td-content">
              <FNumber value={R.pathOr(0, ['credit', 'amount'], link)} />
            </div>
          </td>
          <td className="col-credit-remaining nowrap">
            <div className="td-content">
              <FNumber value={R.pathOr(0, ['credit', 'remaining'], link)} />
            </div>
          </td>
          <td className="col-limit-period nowrap">
            <div className="td-content">
              {R.pathOr(0, ['credit', 'period'], link)}
            </div>
          </td>
        </>
      )}

      <td className="col-payment-term nowrap">
        <div className="td-content">
          {R.pathOr('n/a', ['paymentterm', 'desc'], link)}
        </div>
      </td>
      <td className="col-reference">
        <div className="td-content">{R.pathOr('', ['reference'], link)}</div>
      </td>
      {editable && (
        <td className="col-editme">
          <div className="td-content">
            <Button htmlType="button" type="buttonlink" onClick={toggle}>
              Edit
            </Button>
          </div>
        </td>
      )}
    </tr>
  );
};

const DetailRow = ({ record, setRefresh }) => {
  const editable = useStoreState(canEditCustomer(record));
  return (
    <table className="edit-buyers-table">
      <DetailRowHeader />
      <tbody>
        {record.links.map(link => (
          <LinkRow
            key={link.id}
            link={link}
            setRefresh={setRefresh}
            editable={editable}
          />
        ))}
      </tbody>
    </table>
  );
};

const Action = ({ record }) => {
  const editable = useStoreState(canEditCustomer(record));
  return (
    <div className="td-content">
      {editable ? (
        <Link
          to={`/customers/${record.id}/edit`}
          className={classnames('nui-button', 'nui-button-icon')}
          title="Edit customer"
        >
          <span className="icon-pencil" />
        </Link>
      ) : (
        <Link
          to={`/customers/${record.id}`}
          className={classnames('nui-button', 'nui-button-icon', 'nui-reverse')}
          title="View customer"
        >
          <span className="icon-eye" />
        </Link>
      )}
    </div>
  );
};

export default () => {
  const solution = useStoreState(state => state.auth.solution);
  return (
    <>
      <Helmet>
        <title>Customers</title>
      </Helmet>
      <Header />
      <FiltersProvider store={`customer-filters_${solution?.id}`}>
        <Body />
      </FiltersProvider>
    </>
  );
};

const Body = () => {
  useScrollToTopOnMount();
  useBodyClassReverse();

  const { filters } = useFilters();

  const [refresh, setRefresh] = useToggle();
  const [customers, setCustomers] = useState(null);

  const solution = useStoreState(state => state.auth.solution);

  const [displayedCustomers, setDisplayedCustomers] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const [sortDirection, setSortDirection] = useState('up');
  const [sortColumn, setSortColumn] = useState('buyer');

  const [q, setQ] = useState('');

  const setSort = column => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'down' ? 'up' : 'down');
    } else {
      setSortColumn(column);
      setSortDirection('down');
    }
  };

  useEffect(() => {
    let sortedData = [];

    if (customers === null) return;

    if (sortColumn === 'shortcode')
      sortedData = R.sortWith([R.ascend(R.path(['shortcode']))])(customers);

    if (sortColumn === 'country')
      sortedData = R.sortWith([
        R.ascend(R.path(['country', 'name'])),
        R.ascend(R.path(['shortcode'])),
      ])(customers);

    if (sortColumn === 'port')
      sortedData = R.sortWith([
        R.ascend(R.path(['port', 'name'])),
        R.ascend(R.path(['shortcode'])),
      ])(customers);

    if (sortColumn === 'buyer')
      sortedData = R.sortWith([
        R.ascend(R.path(['name'])),
        R.ascend(R.path(['shortcode'])),
      ])(customers);

    if (sortDirection === 'down') sortedData = R.reverse(sortedData);

    if (q !== '') {
      const sq = q.toLowerCase();
      sortedData = sortedData.filter(
        item =>
          R.pathOr('', ['name'], item).toLowerCase().includes(sq) ||
          R.pathOr('', ['shortcode'], item).toLowerCase().includes(sq) ||
          R.pathOr('', ['country', 'name'], item).toLowerCase().includes(sq) ||
          R.pathOr('', ['port', 'name'], item).toLowerCase().includes(sq)
      );
    }

    sortedData = filterCustomers(sortedData, filters);
    setDisplayedCustomers(sortedData);
  }, [sortDirection, sortColumn, customers, q, filters]);

  useEffect(() => {
    const fetch = async () => {
      const params = { solution: solution.id };
      const data = await api.getData2('/customers', params);
      const customers = data.customers || [];

      setCustomers(customers);
    };
    if (solution) fetch();
  }, [solution, refresh]);

  let columns = [
    {
      title: (
        <div>
          <a
            href="#buyer"
            className={classnames('icon-sort', 'sortem', {
              [sortDirection]: sortColumn === 'buyer',
            })}
            onClick={() => setSort('buyer')}
          >
            Customer
          </a>
        </div>
      ),
      key: 'name',
      className: 'col-buyer-name shaded-right',
      render: (text, record, index) => (
        <div className="td-content customer-name">
          <span className="tiny-label">Customer</span>
          <Link to={`/customers/${record.id}`}>
            <Tooltip
              title={
                <span className="icon-tooltip">
                  <span className="icon-info-circled" />
                  View customer details
                </span>
              }
            >
              {R.pathOr('', ['name'], record)}
            </Tooltip>
          </Link>
        </div>
      ),
    },
    {
      title: (
        <div>
          <a
            href="#shortcode"
            className={classnames('icon-sort', 'sortem', {
              [sortDirection]: sortColumn === 'shortcode',
            })}
            onClick={() => setSort('shortcode')}
          >
            Shortcode
          </a>
        </div>
      ),
      key: 'buyer.shortcode',
      className: 'col-buyer-shortcode',
      render: (text, record, index) => (
        <div className="td-content">
          <span className="tiny-label">Shortcode</span>
          {R.pathOr('', ['shortcode'], record)}
        </div>
      ),
    },
    {
      title: (
        <div>
          <a
            href="#country"
            className={classnames('icon-sort', 'sortem', {
              [sortDirection]: sortColumn === 'country',
            })}
            onClick={() => setSort('country')}
          >
            Country
          </a>
        </div>
      ),
      key: 'buyer.country',
      className: 'col-buyer-country',
      render: (text, record, index) => (
        <div className="td-content">
          <span className="tiny-label">Country</span>
          {R.pathOr('', ['country', 'name'], record)}
        </div>
      ),
    },
  ];

  if (solution.freight_types.includes('port')) {
    columns.push({
      title: (
        <div>
          <a
            href="#port"
            className={classnames('icon-sort', 'sortem', {
              [sortDirection]: sortColumn === 'port',
            })}
            onClick={() => setSort('port')}
          >
            Default port
          </a>
        </div>
      ),
      key: 'buyer.port',
      className: 'col-buyer-port',
      render: (text, record, index) => {
        const port = R.pathOr('', ['port', 'name'], record);
        return (
          <div className="td-content">
            <span className="tiny-label">Default port</span>
            {port && (
              <>
                {port} ({R.pathOr('', ['port', 'country', 'name'], record)})
              </>
            )}
          </div>
        );
      },
    });
  }

  columns.push(
    {
      title: 'Division',
      key: 'seller.name',
      className: 'col-seller',
      render: (text, record, index) => {
        const solutionOwners = record.links.map(item => {
          const solutionOwnerType = invertDivisionType(record.divisiontype);
          return `${item[solutionOwnerType].name} (${item[solutionOwnerType].shortcode})`;
        });
        return (
          <div className="td-content">
            <span className="tiny-label">Division</span>
            {solutionOwners.join(', ')}
          </div>
        );
      },
    },
    {
      title: '',
      key: 'edit button',
      className: 'col-edit edit-only',
      render: record => <Action record={record} />,
    }
  );

  return (
    <>
      <div className="nui-row search-customer">
        <div className="search-fieldset">
          <div className="nui-fieldset">
            <div className="input-holder search-holder">
              <Input
                type="search"
                value={q}
                onChange={e => setQ(e.target.value)}
                placeholder="Search customers"
              />
            </div>
          </div>
        </div>
      </div>

      <CustomerFilters data={customers} clearAction={() => setQ('')} />

      <div className="full-page-content">
        {customers === null ? (
          <Loading size="large" />
        ) : (
          <div className="generic-table light">
            <hr className="m-0" />
            <Table
              className="generic-table expand-table customers2-table th-sticky"
              columns={columns}
              data={displayedCustomers}
              expandedRowRender={record => (
                <DetailRow
                  id={record.id}
                  record={record}
                  setRefresh={setRefresh}
                />
              )}
              expandIcon={({ expanded, onExpand, record }) => (
                <RowToggle
                  open={expanded}
                  onClick={e => {
                    onExpand(record, e);
                  }}
                ></RowToggle>
              )}
              expandedRowKeys={expandedRowKeys}
              rowKey="id"
              onExpand={(_, record) => {
                if (expandedRowKeys.includes(record.id)) {
                  setExpandedRowKeys(state =>
                    R.filter(x => x !== record.id, state)
                  );
                } else {
                  setExpandedRowKeys(state => R.append(record.id, state));
                }
              }}
              emptyText={() => (
                <div className="nui-row full-page-messaging align-center pt-100 p-100 mt--1">
                  <div className="icon-inbox bordered align-center">
                    There are currently no customers to display
                  </div>
                </div>
              )}
            />
            <hr className="m-0" />
          </div>
        )}
      </div>
    </>
  );
};
