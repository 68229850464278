import { Order, Tender } from '~/models/tenders';
import { useFilters } from './context';
import moment from 'moment';

const filterOrders = (orders: Order[]) => {
  const { filters, favFilterOn, localFavourites } = useFilters();

  // override favourite status from context so updates appear real time
  localFavourites &&
    Object.keys(localFavourites).forEach((key: string) => {
      const order = orders.find((x: Order) => x.id === key);
      if (order) {
        order.favourite = localFavourites[key];
      }
    });

  if (favFilterOn) {
    orders = orders.filter((x: Order) => x.favourite === true);
  }

  if (filters?.deliveryFrom?.items.length) {
    orders = orders.filter((x: Order) =>
      x.location?.name
        ? filters.deliveryFrom.items.includes(x.location.name)
        : false
    );
  }
  if (filters?.product?.items.length) {
    orders = orders.filter((x: Order) =>
      x.product?.name ? filters.product.items.includes(x.product.name) : false
    );
  }
  if (filters?.bids?.items.length) {
    if (filters.bids.items[0] === 'mine') {
      orders = orders.filter((x: Order) =>
        x.counters.get().find(x => x.isowner === true)
      );
    } else if (filters.bids.items[0] === 'no') {
      orders = orders.filter((x: Order) => x.counters.length === 0);
    }
  }
  if (filters?.etd?.items.length === 2) {
    const etdFrom = filters?.etd?.items[0]
      ? moment(filters?.etd?.items[0])?.startOf('day')
      : undefined;
    const etdTo = filters?.etd?.items[1]
      ? moment(filters?.etd?.items[1])?.startOf('day')
      : undefined;

    if (etdFrom && etdTo) {
      orders = orders.filter(
        x => moment(x.etd.from) <= etdTo && moment(x.etd.to) >= etdFrom
      );
    }
  }
  if (filters?.productionMonth?.items.length) {
    orders = orders.filter((x: Order) =>
      x.productionmonth?.val
        ? filters.productionMonth.items.includes(x.productionmonth.val)
        : false
    );
  }
  return orders;
};
export default filterOrders;

export const getDeliveryFromOptions = (items: Order[]) =>
  [...new Set(items?.map(item => item.location?.name))]
    .map(x => ({ value: x ?? '', label: x ?? '' }))
    .filter(x => x.value !== '');

export const getProductOptions = (items: Order[]) =>
  [...new Set(items?.map(item => item.product?.name))]
    .map(x => ({ value: x ?? '', label: x ?? '' }))
    .filter(x => x.value !== '');

export const getBidOptions = (tender: Tender) => {
  const counterType = tender?.ordername.counter;
  if (counterType === 'bid') {
    return [
      // { value: '', label: 'All' },
      {
        value: 'mine',
        label: 'My bids',
        translation: {
          id: `tender-details-filters-my-bids`,
          description: 'Tender details filter by my bids',
        },
      },
      {
        value: 'no',
        label: 'No bids',
        translation: {
          id: 'tender-details-filters-no-bids',
          description: 'Tender details filter by no bids',
        },
      },
    ];
  } else {
    return [
      {
        value: 'mine',
        label: 'My offers',
        translation: {
          id: `tender-details-filters-my-offers`,
          description: 'Tender details filter by my offers',
        },
      },
      {
        value: 'no',
        label: 'No offers',
        translation: {
          id: 'tender-details-filters-no-offers',
          description: 'Tender details filter by no offers',
        },
      },
    ];
  }
};

export const getProductionMonthOptions = (items: Order[]) =>
  items
    ?.map(item => item.productionmonth)
    .filter(
      (productionmonth, index, self) =>
        productionmonth?.val &&
        index === self.findIndex(pm => pm?.val === productionmonth?.val)
    )
    .sort((a, b) => a.val.localeCompare(b.val))
    .map(x => ({ value: x?.val ?? '', label: x?.display ?? '' }));
