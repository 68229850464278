import type { Tender, Order } from '~/models/tenders';
import React, { useState, useRef, useEffect } from 'react';
import * as R from 'ramda';
import { routeUrl, withPreload, withRouteX } from '~/router';
import { LabelledInfo, StackItem, ListedData } from 'Components/nui/Wizard';
import * as Data from 'Components/Data';
import { capitalize } from '~/utils';
import classnames from 'classnames';
import { Alert, Button, Loading } from 'Components/nui';
import { toast } from 'react-toastify';
import Access from 'Components/Access';
import { useMountedState } from '~/hooks';
import { useHistory } from 'react-router-dom';
import * as service from '~/services/tenders';
import { Radio } from 'antd';
import { useStoreState } from '~/store';
import { FormattedMessage } from 'react-intl';

type TenderProp = Record<'tender', Tender>;
type OrdersProp = Record<'orders', Order[]>;
type TenderVisibleProp = Record<'tenderVisible', boolean | undefined>;

const Review = withPreload({
  route: 'tender-edit-review',
  preload: service.observe.orders(),
})(({ data: { tender, orders } }) => {
  const solutionColumns = useStoreState(state => state.auth.solutionColumns);
  const [tenderVisible, setTenderVisible] = useState(
    solutionColumns?.tendervisible &&
      (!R.isNil(tender.tendervisible)
        ? tender.tendervisible
        : solutionColumns.tendervisible?.value ?? true)
  );

  return (
    <div className="tender-edit-step review">
      <FormInfo tender={tender} />

      <TenderDetails tender={tender} />
      <hr />

      <OrderDetails tender={tender} orders={orders} />

      {!R.isNil(tenderVisible) && (
        <>
          <hr />
          <ShowLanding
            tenderVisible={tenderVisible}
            setTenderVisible={setTenderVisible}
            tender={tender}
          />
        </>
      )}

      <div className="sticky-btm">
        <Access to="publish" model={tender}>
          <Publish tender={tender} tenderVisible={tenderVisible} />
        </Access>

        <Access to="notify" model={tender}>
          <Notify tender={tender} />
        </Access>
      </div>
    </div>
  );
});
export default withRouteX({ name: 'tender-edit-review' })(Review);

const FormInfo = ({ tender }: TenderProp) => (
  <Access to={['publish', 'notify']} model={tender}>
    <Alert type="info" hasicon className="mb-20">
      <Access to="publish" model={tender}>
        <p>
          <FormattedMessage
            id="tender-create-review-summary-text-publish"
            description="Text for review section for tender summary on tenders create/edit pages"
            defaultMessage="Once you publish this tender, participants will be notified and they will be able to view the tender. You will not be able to change the start, duration, or method of the tender."
          />
        </p>
      </Access>
      <Access to="notify" model={tender}>
        <p>
          <FormattedMessage
            id="tender-create-review-summary-text-notify"
            description="Text for review section for tender summary on tenders create/edit pages"
            defaultMessage="Notifying participants will create a notification for all participants about the tender. You can notify participants at any time."
          />
        </p>
        {tender.notified && (
          <p>
            <FormattedMessage
              id="tender-create-review-summary-text-last-notified"
              description="Text for review section for tender summary on tenders create/edit pages"
              defaultMessage="Participants were last notified on <strong>{notifyTime}</strong>"
              values={{
                notifyTime: tender.notified.format('LL HH:mm:ss'),
                strong: chunks => <strong>{chunks}</strong>,
              }}
            />
          </p>
        )}
      </Access>
    </Alert>
  </Access>
);

const infoFmt = 'LL HH:mm Z';
type ITenderDetails = TenderProp;
const TenderDetails = ({ tender }: ITenderDetails) => (
  <LabelledInfo
    data={[
      [
        <FormattedMessage
          id="tender-create-review-summary-field-tender-name"
          description="Field for tender name on review summary on tenders create/edit pages"
          defaultMessage="Tender name"
        />,
        tender.name,
      ],
      [
        <FormattedMessage
          id="tender-create-review-summary-field-method"
          description="Field for Method on review summary on tenders create/edit pages"
          defaultMessage="Method"
        />,
        <Data.TenderMethod info={tender} />,
      ],
      [
        <FormattedMessage
          id="tender-create-review-summary-field-status"
          description="Field for Status on review summary on tenders create/edit pages"
          defaultMessage="Status"
        />,
        <TenderStatus value={tender.status} />,
      ],
      [
        <FormattedMessage
          id="tender-create-review-summary-field-start"
          description="Field for Start on review summary on tenders create/edit pages"
          defaultMessage="Start"
        />,
        <Data.Datetime value={tender.start} fmt={infoFmt} />,
      ],
      [
        <FormattedMessage
          id="tender-create-review-summary-field-finish"
          description="Field for Finish on review summary on tenders create/edit pages"
          defaultMessage="Finish"
        />,
        <Data.Datetime value={tender.finish} fmt={infoFmt} />,
      ],
      [
        <FormattedMessage
          id="tender-create-review-summary-field-duration"
          description="Field for Duration on review summary on tenders create/edit pages"
          defaultMessage="Duration"
        />,
        <Data.Duration value={tender.duration} />,
      ],
      [
        <FormattedMessage
          id="tender-create-review-summary-field-orders"
          description="Field for Bids/Offers on review summary on tenders create/edit pages"
          defaultMessage="{orderType, select, offer {Offers} bid {Bids} other{{orderType}}}"
          values={{ orderType: tender.ordername.order }}
        />,
        tender.orders.total.toFixed(0),
      ],
      [
        <FormattedMessage
          id="tender-create-review-summary-field-participants"
          description="Field for Participants on review summary on tenders create/edit pages"
          defaultMessage="Participants"
        />,
        tender.participants!.included.toFixed(0) +
          '/' +
          tender.participants!.total.toFixed(0),
      ],
    ]}
  />
);

const OrderDetails = ({ tender, orders }: TenderProp & OrdersProp) =>
  orders.length ? (
    <ListedData
      title={
        <FormattedMessage
          id="tender-create-review-summary-field-orders"
          description="Field for Bids/Offers on review summary on tenders create/edit pages"
          defaultMessage="{orderType, select, offer {Offers} bid {Bids} other{{orderType}}}"
          values={{ orderType: tender.ordername.order }}
        />
      }
      className="summary-orders-list"
    >
      {orders.map(order => (
        <StackItem
          key={order.id}
          title={order.product.name}
          data={
            <>
              <Data.IndexUnit value={order.startprice} />{' '}
              {order.reserve.price.val && (
                <>
                  <FormattedMessage
                    id="tender-create-review-summary-field-reserve-price"
                    description="Field for Reserve price on review summary on tenders create/edit pages"
                    defaultMessage="Reserve price:"
                  />{' '}
                  <Data.Price value={order.reserve.price} />
                  {' | '}
                </>
              )}
              <Data.Price
                onZero={
                  <FormattedMessage
                    id="generic-any"
                    description="Text Any"
                    defaultMessage="Any"
                  />
                }
                value={order.startprice}
              />{' '}
              / <Data.Volume value={order.volume} /> {order.volume.unit}
              {order.attributes ? (
                <Data.Attributes value={order.attributes} />
              ) : (
                <br />
              )}
              <strong>
                <FormattedMessage
                  id="tender-details-summary-field-etd"
                  description="Field for `ETD` in summary in tender details page"
                  defaultMessage="ETD"
                />
              </strong>{' '}
              <Data.ETD value={order.etd} />
              {order.productionmonth && (
                <>
                  <br />
                  <Data.ProductionMonth value={order.productionmonth.display} />
                </>
              )}
            </>
          }
        />
      ))}
    </ListedData>
  ) : null;

const TenderStatus = ({ value }: Record<'value', string>) => (
  <span className={classnames('status', value)}>
    <FormattedMessage
      id="tender-create-summary-status"
      description="Text for Status field on summary for tenders create/edit order page"
      defaultMessage="{value, select, active{active} finished{finished} published{published} cancelled{cancelled} planning{planning} archived{archived} other{{value}}}"
      values={{ value: value }}
    />
  </span>
);

const ShowLanding = ({ tenderVisible, setTenderVisible, tender }: any) => {
  const [loading, setLoading] = useState(false);
  const visibilityEditable =
    tender.status === 'published' || tender.status === 'planning';

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (tender.status === 'published') {
      setLoading(true);
      const editVisibility = async () => {
        const result = await service.actions.editVisibility(
          tender.id,
          tenderVisible
        );
        if (result?.success) {
          toast.success(
            <>
              Show this tender on the landing page has been updated to{' '}
              <strong>{tenderVisible === true ? 'Yes' : 'No'}</strong>. This
              tender will
              <strong>{tenderVisible === false && ' not'}</strong> be visible on
              the landing page.
            </>
          );
        } else if (result?.validate) {
          toast.error(<p>{capitalize(result.validate)}</p>);
        } else if (result?.errors) {
          toast.error(
            <>
              <p>Error updating tender visibility on landing page </p>
              <ul>
                {result.errors.map(e => (
                  <li>{capitalize(e)}</li>
                ))}
              </ul>
            </>
          );
        }
        setLoading(false);
      };
      editVisibility();
    }
  }, [tenderVisible]);
  return (
    <div>
      <h3>
        <FormattedMessage
          id="tender-details-summary-landing-page"
          description="Heading for landing page field in summary in tender details page"
          defaultMessage="Show this tender on the Landing Page"
        />
      </h3>
      <p>
        <FormattedMessage
          id="tender-details-summary-landing-page-text"
          description="Text for landing page in summary in tender details page"
          defaultMessage="Show this tender in the list of upcoming tenders on the Nui Landing Page to engage customers. All Nui users will be able to see the tender name, start and end date. Users that do not have access to your tender will not be able to click through to see the tender."
        />
      </p>

      <div className="show-landing-page-radio-button-field">
        <label>
          <strong>
            <FormattedMessage
              id="tender-details-summary-landing-page"
              description="Label for landing page in summary in tender details page"
              defaultMessage="Show this tender on the Landing Page"
            />
          </strong>
        </label>
        <Radio.Group
          className="show-landing-page-radio-button"
          value={tenderVisible}
          onChange={e => {
            const value = e.target.value;
            setTenderVisible(value);
          }}
        >
          <Radio
            className="show-on-landing-input-yes"
            name="show-on-landing-yes"
            value={true}
            disabled={loading || !visibilityEditable}
          >
            <span>
              <FormattedMessage
                id="orderform-field-generic-yes-label"
                description="Label for 'Yes' radio button on tender counter form"
                defaultMessage="Yes"
              />
            </span>
          </Radio>

          <Radio
            className="show-on-landing-input-no"
            name="show-on-landing-no"
            value={false}
            disabled={loading || !visibilityEditable}
          >
            <span className="viewing-range-radio-label">
              <FormattedMessage
                id="orderform-field-generic-no-label"
                description="Label for 'No' radio button on tender counter form"
                defaultMessage="No"
              />
            </span>
          </Radio>
        </Radio.Group>
        {loading && (
          <Loading size="small" className="tender-visibility-loading" />
        )}
      </div>
    </div>
  );
};

const Publish = ({ tender, tenderVisible }: TenderProp & TenderVisibleProp) => {
  const [loading, setLoading] = useMountedState(false);
  const history = useHistory();

  return (
    <Button
      type="primary"
      className="mb-10"
      loading={loading}
      disabled={loading}
      onClick={async () => {
        setLoading(true);

        const result = await service.actions.publish(tender.id, tenderVisible);
        if (result?.success) {
          toast.success(
            <>
              Tender <strong>{tender.name}</strong> has been published
              successfully.
            </>
          );
          history.push(routeUrl('tender-view', { tenderId: tender.id }));
        } else if (result?.validate) {
          toast.error(<p>{capitalize(result.validate)}</p>);
          setLoading(false);
        } else if (result?.errors) {
          toast.error(
            <>
              <p>This tender could not be published</p>
              <ul>
                {result.errors.map(e => (
                  <li>{capitalize(e)}</li>
                ))}
              </ul>
            </>
          );
          setLoading(false);
        }
      }}
    >
      <FormattedMessage
        id="tender-create-review-summary-button-publish-tender"
        description="Button for Publish tender for review summary on tenders create/edit pages"
        defaultMessage="Publish tender"
      />
    </Button>
  );
};

const Notify = ({ tender }: TenderProp) => {
  const [loading, setLoading] = useMountedState(false);

  return (
    <Button
      type="primary"
      loading={loading}
      disabled={loading}
      onClick={async () => {
        setLoading(true);

        const result = await service.actions.notify(tender.id);
        if (result?.success) toast.success('Participants will be notified');
        else if (result?.errors)
          toast.error('An error occurred. Participants were not notified.');

        setLoading(false);
      }}
    >
      <FormattedMessage
        id="tender-create-review-summary-button-notify-participants"
        description="Button for notify participants for review summary on tenders create/edit pages"
        defaultMessage="Notify participants"
      />
    </Button>
  );
};
