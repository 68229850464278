import React, { useRef, useState, useEffect } from 'react';
import { Loading, Alert, Form, Button } from 'Components/nui';
import { api } from '~/api';
import { toast } from 'react-toastify';
import { FormWrapper } from 'Components/form';
import { mergeSchema } from 'Components/form/utils';
import { promisify } from 'es6-promisify';
import { useSharedTrade } from '..';
import { useMountedState } from 'react-use';
import { FormattedMessage } from 'react-intl';
import { useStoreState } from '~/store';

export default ({ type }) => (
  <div className="info-form-area">
    <InfoForm type={type} />
  </div>
);

const InfoForm = ({ type }) => {
  const [trade] = useSharedTrade();
  const schema = useRef({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    setLoading(true);

    (async () => {
      try {
        const response = await api.request({
          method: 'get',
          url: `/trades/${trade.id}/info/schema`,
        });
        if (mounted) {
          schema.current = response.data.fields;
        }
      } catch (err) {
        toast('An error occurred while loading this form.', {
          type: 'error',
        });
        schema.current = 'error';
      }
      if (mounted) setLoading(false);
    })();
    return () => void (mounted = false);
  }, []);

  if (loading) return <Loading size="small" />;
  if (schema.current === 'error')
    return (
      <Alert type="error" hasicon className="align-center">
        An error occurred.
      </Alert>
    );

  return <WrappedForm schema={schema.current} type={type} />;
};

function useFormWrapper({ form, schema, type }) {
  const solution = useStoreState(state => state.auth.solution);
  let fields = [
    {
      name: 'type_id',
      label: 'Type',
      render: () => null,
      serializeField: () => {
        if (!schema.type_id) return null;
        return { type_id: schema.type_id.choices.find(i => i[1] === type)[0] };
      },
    },
    {
      name: 'value',
      label:
        type === 'PO Number' ? (
          <FormattedMessage
            id="viewtrade-tradeactions-field-ponumberform-label"
            description="label for purchase roder number Form: Input"
            defaultMessage="Purchase order number"
          />
        ) : type === 'SO Number' ? (
          'Sales order number'
        ) : (
          type
        ),

      type: (type === 'PO Number' || type === 'SO Number') && 'Input',
    },
  ];

  if (type === 'Comment') {
    fields.push({
      name: 'isprivate',
      label: (
        <h5>
          <FormattedMessage
            id="viewtrade-tradeactions-field-attachmentform-helptext-visibility"
            description="helptext for Attachment Form: who can see Trade Action in View trade"
            defaultMessage="Who can see this content"
          />
        </h5>
      ),
      initialValue: true,
      type: 'RadioSelect',
      choices: [
        {
          key: 'private',
          label: (
            <FormattedMessage
              id="viewtrade-tradeactions-field-attachmentform-helptext-visibility-private"
              description="helptext for Attachment Form: only you will see Trade Action in View trade"
              defaultMessage="Private - only you will see this content"
            />
          ),
          value: true,
        },
        {
          key: 'public',
          label: (
            <FormattedMessage
              id="viewtrade-tradeactions-field-attachmentform-helptext-visibility-public"
              description="helptext for Attachment Form: other party will see Trade Action in View trade"
              defaultMessage="Public - the other party will also have access to this content"
            />
          ),
          value: false,
        },
      ],
    });
  } else {
    fields.push({
      name: 'isprivate',
      render: () => null,
      serializeField: () => {
        if (!schema.isprivate) return null;
        return { isprivate: false };
      },
    });
  }
  return new FormWrapper(form, {}, mergeSchema(schema, fields));
}

const BaseForm = ({ form, schema, type }) => {
  const formWrapper = useFormWrapper({
    form,
    schema,
    type,
  });
  const [trade, setTrade] = useSharedTrade();
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);
  const [loading, setLoading] = useState(false);
  const mounted = useMountedState();
  async function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);

    try {
      await validateFieldsAndScroll();
    } catch (err) {
      setLoading(false);
      return;
    }

    try {
      const data = formWrapper.serialize();
      const response = await api.postData(`/trades/${trade.id}/info`, data);
      form.resetFields();
      toast.success(type + ' has been added to trade');
      if (mounted()) {
        if (response.info) {
          setTrade(trade => ({
            ...trade,
            info: [...(trade?.info || []), response.info],
          })); // Add to the shared context
        }
        setLoading(false);
      }
    } catch (err) {
      toast.error('An error occurred while submitting this form');
    }
  }

  return (
    <div className="nui-form">
      <Form onSubmit={handleSubmit} className="post-trade-form">
        {formWrapper.render()}
        <Button
          type="primary"
          disabled={loading}
          loading={loading}
          htmlType="submit"
        >
          <FormattedMessage
            id="viewtrade-tradeactions-field-generic-button-submit"
            description="button for generic form: Submit Trade Action in View trade"
            defaultMessage="Submit"
          />
        </Button>
      </Form>
    </div>
  );
};
const WrappedForm = Form.create()(BaseForm);
