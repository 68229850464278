import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { withPreload, usePathTools } from '~/router';
import * as service from '~/services/tenders';
import { capitalize } from '~/utils';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useStoreActions, useStoreState } from '~/store';
import * as api from '~/fetch';
import { toast } from 'react-toastify';
import { TenderCountdown, TenderInfo, TenderStatus } from './View/components';
import RankingTotals from './View/RankingTotals';
import Breadcrumb from './Breadcrumb';

const ViewHeading = () => {
  type tenderIdParams = {
    tenderId: string;
  };
  const [tenderSolutionId, setTenderSolutionId] = useState('');
  const { tenderId } = useParams<tenderIdParams>();
  const solutionId = useStoreState(state => state.auth.solutionId);
  const changeSolution = useStoreActions(action => action.auth.changeSolution);

  useEffect(() => {
    const fetchTender = async (tenderId: string) => {
      try {
        const res = await api.tenders.tender(tenderId);
        if (res.ok) {
          const data = await res.json();
          setTenderSolutionId(data!.tender.solution.id);
        }
      } catch (error) {
        toast.error('Can not fetch the tender information, please refresh');
      }
    };

    if (tenderId) {
      fetchTender(tenderId);
    }
  }, [tenderId]);

  useEffect(() => {
    if (solutionId && tenderSolutionId && solutionId !== tenderSolutionId) {
      changeSolution(tenderSolutionId);
    }
  }, [solutionId, tenderSolutionId, changeSolution]);

  return <ViewHeadingWithPreload />;
};

const ViewHeadingWithPreload = withPreload({
  route: 'tender-view',
  preload: service.observe.tender(),
})(({ data: tender }) => {
  return (
    <div className="tender-sticky-header">
      <div className="flex">
        <div className="tender-header-wrap">
          <h1 className="m-0">
            <span
              className={classnames(
                `type-${tender.type}`,
                'inline',
                'align-middle'
              )}
            >
              <FormattedMessage
                id="tender-generic-badge-sale-type"
                description="Label for generic buy/sell badge"
                defaultMessage="{tenderType, select, buy {BUY} sell {SELL} other {{tenderType}}}"
                values={{ tenderType: tender.type }}
              />
            </span>{' '}
            <FormattedMessage
              id="tender-view-page-heading"
              description="Heading for view tender page"
              defaultMessage="Tender"
            />{' '}
            <span className="smaller">{tender.name}</span>
            <span className="tender-header-status">
              <TenderStatus tender={tender} />
            </span>
          </h1>
          <div className="tender-header-details">
            <TenderCountdown tender={tender} />
            <RankingTotals tender={tender} />
          </div>
        </div>
        <Breadcrumb />
      </div>
      {/* {view === 'detail' && <TenderInfo tender={tender} view={'details'} />} */}
      <TenderInfo tender={tender} view={'detail'} />
    </div>
  );
});

export const EditHeading = withPreload({
  route: 'tender-new',
  preload: service.observe.tender({ requireId: false }),
})(({ data: tender }) => {
  const { matchParams } = usePathTools();
  const { mode = 'new' } = matchParams('tender-new');
  return (
    <h1>
      <FormattedMessage
        id="tender-edit-page-heading"
        description="Heading for edit tender page"
        defaultMessage="{mode, select, New {New} Edit {Edit} other {{mode}}} tender"
        values={{ mode: capitalize(mode) }}
      />{' '}
      {tender && <span className="smaller">{tender.name}</span>}
    </h1>
  );
});

export default ViewHeading;
