import type { Moment } from 'moment-timezone';
import type { Order } from '~/models/tenders';
import React, { useContext, useEffect, useState } from 'react';
import Rolodex from 'Components/Rolodex';
import NavList from 'Components/nui/NavList';
import { Tooltip } from 'Components/nui';
import {
  OrderTicket,
  CounterDetails,
  OrderDetails,
  OwnCounter,
  OrderActions,
  EditCounterButton,
  OrderIcons,
  CounterRanking,
  CounterActions,
  IsAutobid,
  MyVariant,
  ResTag,
  Relist,
} from './components';
import { capitalize, inArrayIf } from '~/utils';
import Access, { can } from 'Components/Access';
import CounterForm from './CounterForm';
import { useHistory } from 'react-router-dom';
import * as Data from 'Components/Data';
import CounterTrade from './CounterTrade';
import { FieldVisibility, ToggleVisibility } from 'Components/FieldVisibility';
import { useStoreState } from '~/store';
import { NoData } from 'Components/InfoMessage';
import {
  routeUrl,
  matchUrl,
  RoutingTable,
  usePathTools,
  withPreload,
  withRouteX,
} from '~/router';
import * as service from '~/services/tenders';
import * as R from 'ramda';
import { withFilters } from 'Components/filters/context';
import filterOrders from 'Components/filters/filterTenders';
import { FormattedMessage } from 'react-intl';
import { QA } from 'Components/QA';
import { useStoreActions } from 'easy-peasy';
import { ApiContext } from '~/context';

const sortByString = (a: string | undefined, b?: string | undefined) =>
  a && b ? a.localeCompare(b) : +!a - +!b;

const sortByDate = (a?: Moment, b?: Moment) => (a && b ? a.diff(b) : +!a - +!b);

function sortByCreated(a: Order, b: Order) {
  return sortByDate(a.created, b.created);
}

function sortByProduct(a: Order, b: Order) {
  const diff = sortByString(a.product.name, b.product.name);

  if (diff === 0) return sortByCreated(a, b);
  return diff;
}

function sortByProductionMonth(a: Order, b: Order) {
  const aa = a.productionmonth?.val || '';
  const bb = b.productionmonth?.val || '';
  const diff = sortByString(aa, bb);

  if (diff === 0) return sortByCreated(a, b);
  return diff;
}

function sortByAttr(attr: string) {
  return function (a: Order, b: Order) {
    const aa = a.indexedAttributes?.[attr]?.value[0];
    const bb = a.indexedAttributes?.[attr]?.value[0];
    const diff = sortByString(aa?.name, bb?.name);

    if (diff === 0) return sortByProduct(a, b);
    return diff;
  };
}

function sortByExportable(a: Order, b: Order) {
  const sorted = [null, undefined, false, true];
  const diff = sorted.indexOf(b.exportable) - sorted.indexOf(a.exportable);
  if (diff === 0) return sortByProduct(a, b);
  return diff;
}

function sortByEtd(a: Order, b: Order) {
  const diff = sortByDate(a.etd.from, b.etd.from);
  if (diff === 0) return sortByCreated(a, b);
  return diff;
}

function sortByDeliveryFrom(a: Order, b: Order) {
  const diff = sortByString(a.location?.name, b.location?.name);
  if (diff === 0) return sortByCreated(a, b);
  return diff;
}

function sortByBids(a: Order, b: Order) {
  if (a.counters.length > b.counters.length) {
    return -1;
  }
  if (a.counters.length < b.counters.length) {
    return 1;
  }
  return sortByCreated(a, b);
}

function sortOrders(sortOn: string) {
  if (sortOn === 'created') return sortByCreated;
  if (sortOn === 'etd') return sortByEtd;
  if (sortOn === 'productionmonth') return sortByProductionMonth;
  if (sortOn === 'product') return sortByProduct;
  if (sortOn === 'exportable') return sortByExportable;
  if (sortOn === 'deliveryfrom') return sortByDeliveryFrom;
  if (sortOn === 'bids') return sortByBids;
  return sortByAttr(sortOn);
}

type PageName =
  | 'tender-order-details'
  | 'tender-counter'
  | 'tender-counter-orders';

const Orders = withPreload({
  route: 'tender-orders',
  preload: service.observe.orders(),
})(({ data }) => {
  const { tender, orders } = data!;
  const orderIds = orders.map(o => o.id);
  const exportableOrders = orders.filter(o => o.exportable);
  const solution = useStoreState(state => state.auth.solution);
  const [attributes, solutionId, isTenderReserve] = useStoreState(state => [
    state.auth.solution.attributes || [],
    state.auth.solution.id,
    state.auth.solution.settings.tenderreserveprice === true,
  ]);
  const [sortby, setSortby] = useState<string>('created');

  const history = useHistory();
  const { matchParams } = usePathTools();
  const tenderId = tender.id;
  const { orderId = '' } = matchParams('tender-order-view');
  const params = { tenderId, orderId };

  const redirect = (route: PageName, order: string) =>
    void history.push(routeUrl(route, { ...params, orderId: order }));

  const onClick = (order: Order) => () => {
    if (tender.willStart()) redirect('tender-order-details', order.id);
    else if (tender.isRunning()) {
      if (tender.participating) {
        if (order.counters.mine) {
          if (tender.method === 'blind') redirect('tender-counter', order.id);
          else redirect('tender-counter-orders', order.id);
        } else {
          redirect('tender-order-details', order.id);
        }
      } else {
        if (order.counters.length) redirect('tender-counter-orders', order.id);
        else redirect('tender-order-details', order.id);
      }
    } else {
      if (order.counters.length) redirect('tender-counter-orders', order.id);
      else redirect('tender-order-details', order.id);
    }
  };

  let sorted = orders;
  sorted = filterOrders(sorted);
  sorted.sort(sortOrders(sortby));

  const first = sorted[0];

  const tenderHasReserveOrder = R.any(
    R.o(R.not, R.isNil),
    R.map(R.path(['reserve', 'reserve_met']), sorted)
  );

  if (!data!.orders.length)
    return (
      <>
        <hr />
        <NoData className="mt-10 mb--11 p-100">
          <FormattedMessage
            id="tender-create-steps-no-tender-orders"
            description="Text for no tender orders on tender create page"
            defaultMessage="This tender has no {orderType, select, offer {offers} bid {bids} other{{orderType}}}"
            values={{ orderType: tender.ordername.order }}
          />
        </NoData>
      </>
    );

  const hasProductionmonth = R.any(o => !!o.productionmonth, orders);

  const sortOptions: (readonly [string, React.ReactNode])[] = [
    ['created', 'Created'],
    ['product', 'Product'],
    ['etd', 'ETD'],
    ...attributes
      .filter(x => x.name === 'group')
      .map(x => [x.name, x.desc] as const),
    // check data for the location field (named warehouse in BE) - only exist if turned on in the admin
    ...inArrayIf(first?.location, ['deliveryfrom', 'Delivery From'] as const),
    ...inArrayIf(hasProductionmonth, [
      'productionmonth',
      'Production month',
    ] as const),
    ['bids', 'Most Bids'],
    // ...inArrayIf(
    //   exportableOrders.length > 0 && !!solution?.exportprice?.flatfee,
    //   ['exportable', 'Exportable'] as const
    // ),
  ];

  return (
    <FieldVisibility
      store={'tender-orders-fields-v2.0_' + solutionId}
      initial={{ loading: false, supplier: false, location: false }}
    >
      <Rolodex
        tender={tender}
        active={orderId}
        allOrderIds={orderIds}
        items={orders}
        sortable={{
          value: sortby,
          onChange: setSortby as (x: string) => void,
          options: sortOptions,
        }}
        filter={
          <div className="nowrap">
            <strong className="all-black block mb-5">Display on summary</strong>
            <ToggleVisibility name="loading" title="Loading details" />
            <ToggleVisibility name="etd" title="ETD" />
            <ToggleVisibility
              name="location"
              title={'Delivery ' + tender.delivery}
            />
            <ToggleVisibility name="attachments" title="Attachments" />
            <ToggleVisibility name="comment" title="Comments" />
          </div>
        }
      >
        <Rolodex.Wheel
          heading={
            <>
              <h3>
                <FormattedMessage
                  id="tender-create-steps-order-heading"
                  description="Heading for Bid/Offer on tenders create/edit steps"
                  defaultMessage="{ordername, select, bid{Bids} offer{Offers} other{{ordername}s}}"
                  values={{ ordername: tender.ordertype.order }}
                />
              </h3>
              {isTenderReserve && tenderHasReserveOrder && (
                <div className="rolodex-reserve-header">
                  <div className="rolodex-reserve-items">
                    <div>
                      <span className="res-icon inline-block">RES</span>
                      <span>
                        <FormattedMessage
                          id="tender-order-details-reserve-met"
                          description="Text for reserve met on tender order details"
                          defaultMessage="Reserve met"
                        />
                      </span>
                    </div>
                    <div>
                      <span className="icon-attention inline-block" />
                      <span>
                        <FormattedMessage
                          id="tender-order-details-reserve-not-met"
                          description="Text for reserve price not met on tender order details"
                          defaultMessage="Reserve not met"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </>
          }
          items={orders}
        >
          {sorted.map(o => (
            <Rolodex.Card
              key={o.id}
              id={o.id}
              className="tender-order-ticket"
              onClick={onClick(o)}
              heading={({ open }) => (
                <>
                  <h4>
                    <OrderIcons order={o} tenderId={tender.id} />
                    <Tooltip
                      title={
                        <span className="icon-tooltip">
                          <span className="icon-info-circled" />
                          <p>{o.product.name}</p>
                          <Data.Attributes value={o.attributes} />
                        </span>
                      }
                    >
                      {o.product.name}
                    </Tooltip>
                    <MyVariant order={o} />
                    {!open &&
                      (o.location?.shortdesc ||
                        o.productionmonth?.shortdisplay) && (
                        <>
                          <span className="my-variant">
                            <strong>
                              {o.location?.shortdesc}{' '}
                              {o.location?.shortdesc &&
                                o.productionmonth?.shortdisplay &&
                                '-'}{' '}
                              {o.productionmonth?.shortdisplay}
                            </strong>
                          </span>
                        </>
                      )}
                  </h4>

                  {!tender.participating && !tender.ended() && (
                    <OrderActions tender={tender} order={o} />
                  )}
                  <CounterRanking
                    tender={tender}
                    order={o}
                    detail={
                      open ||
                      !(can('add', o.counters) || can('edit', o.counters.mine))
                    }
                  >
                    {/* if there is rank (user placed a bid), show counter actions */}
                    {!open && o.counters.mine?.rank > 0 && (
                      <CounterActions tender={tender} order={o}>
                        <ResTag reserve={o.reserve} />
                      </CounterActions>
                    )}
                    {open && <ResTag reserve={o.reserve} />}
                  </CounterRanking>
                </>
              )}
            >
              <OrderTicket order={o} tender={tender} />
            </Rolodex.Card>
          ))}
        </Rolodex.Wheel>
        {first?.id ? (
          <Rolodex.Content>
            <RoutingTable
              routes={[OrderView]}
              redirect={routeUrl('tender-order-details', {
                ...params,
                orderId: first.id,
              })}
            />
          </Rolodex.Content>
        ) : null}
      </Rolodex>
    </FieldVisibility>
  );
});
export default withRouteX({ name: 'tender-orders' })(withFilters(Orders));

const DetailSection = withPreload({
  route: 'tender-order-view',
  preload: service.observe.order(),
})(({ data: { tender, order } }) => {
  const solution = useStoreState(state => state.auth.solution);
  const { matchParams } = usePathTools();
  const params = matchParams('tender-order-view');
  const viewMyCounter = !!order.counters.mine || can('add', order.counters);
  const viewCounterHistory =
    tender.started() &&
    !!(tender.participating ? order.counters.mine : order.counters.length);

  const isFreightIndexOrder = !!(solution.freight_types.length && order.index);

  const canRelist = useStoreState(
    state =>
      state.auth.solution.roles.find(r =>
        ['manager', 'trader'].includes(r.role)
      ) && tender.owned
  );

  const setOrderQuestions = useStoreActions(
    action => action.marketdepth.setOrderQuestions
  );

  const questionsCount = useStoreState(state =>
    state.marketdepth.orderQuestionsCount(order.id)
  );

  const orderQuestions = useStoreState(
    state => state.marketdepth.orderQuestions
  );

  useEffect(() => {
    if (!orderQuestions[order.id]) {
      setOrderQuestions({
        id: order.id,
        questions: order.state.questions || undefined,
      });
    }
  }, [order.id]);
  return (
    <>
      <NavList>
        <NavList.Tab
          url={routeUrl('tender-order-details', params)}
          path={matchUrl('tender-order-details')}
        >
          <FormattedMessage
            id="tender-details-tab-order-details"
            description="Label for offer/bid details tab on tenders details page"
            defaultMessage="{orderName, select, offer{Offer} bid{Bid} other{{orderName}}} details"
            values={{ orderName: tender.ordername.order }}
          />
        </NavList.Tab>
        {viewMyCounter && (
          <NavList.Tab
            url={routeUrl('tender-counter', params)}
            path={matchUrl('tender-counter')}
          >
            <FormattedMessage
              id="tender-details-tab-my-counter-order"
              description="Label for place/my bid/offer tab on tenders details page"
              defaultMessage="{mine, select, true{My} other{Place}} {isStarted, select, true {auto} other{}} {counterName, select, offer{offer} bid{bid} other{{counterName}}}"
              values={{
                counterName: tender.ordername.counter,
                mine: !!order.counters.mine,
                isStarted: !tender.started(),
              }}
            />
          </NavList.Tab>
        )}
        {tender.started() && (
          <NavList.Tab
            url={routeUrl('tender-counter-orders', params)}
            path={matchUrl('tender-counter-orders')}
          >
            <FormattedMessage
              id="tender-details-tab-counter-order-new"
              description="Label for bids/offers tab on tenders details page"
              defaultMessage="{counterName, select, offer{Offers} bid{Bids} other{{counterName}s}}"
              values={{
                counterName: tender.ordername.counter,
                hasCounters: !!order.counters.length,
              }}
            />
            {order.counters.length > 0 && (
              <span className="count">{order.counters.length}</span>
            )}
          </NavList.Tab>
        )}
        <NavList.Tab
          url={routeUrl('tender-order-qa', params)}
          path={matchUrl('tender-order-qa')}
        >
          Q&A
          {questionsCount > 0 && (
            <span className="count">{questionsCount}</span>
          )}
        </NavList.Tab>
        {tender.owned && (
          <NavList.Tab
            url={routeUrl('tender-order-history', params)}
            path={matchUrl('tender-order-history')}
          >
            <FormattedMessage
              id="tender-details-tab-my-order-history"
              description="Label for my bid/offer history tab on tenders details page"
              defaultMessage="{orderName, select, offer{Offer} bid{Bid} other{{orderName}}} history"
              values={{
                orderName: tender.ordername.order,
              }}
            />
          </NavList.Tab>
        )}
        {viewCounterHistory && (
          <NavList.Tab
            url={routeUrl('tender-counter-history', params)}
            path={matchUrl('tender-counter-history')}
          >
            <FormattedMessage
              id="tender-details-tab-counter-order-history"
              description="Label for counter bid/offer history tab on tenders details page"
              defaultMessage="{counterName, select, offer{Offer} bid{Bid} other{{counterName}}} history"
              values={{
                counterName: tender.ordername.counter,
              }}
            />
          </NavList.Tab>
        )}
        {order.buyers && !!order.startprice.val && !isFreightIndexOrder && (
          <NavList.Tab
            url={routeUrl('tender-order-buyers', params)}
            path={matchUrl('tender-order-buyers')}
          >
            <FormattedMessage
              id="tender-details-tab-show-prices"
              description="Label for Show prices tab on tenders details page"
              defaultMessage="Show prices"
            />
          </NavList.Tab>
        )}
      </NavList>
      <div className="order-title">
        <h3>
          {order.product.name}
          <MyVariant order={order} />
        </h3>
        {canRelist && tender.ended() && <Relist order={order} />}
      </div>
      {order.attributes && <Data.Attributes value={order.attributes} />}
      <hr className="mt-0 mb-10" />

      <RoutingTable
        routes={[
          DetailsPage,
          OrderHistoryPage,
          OrderCounterPage,
          CounterOrdersPage,
          OrderQAPage,
          CounterHistoryPage,
          OrderBuyersPage,
        ]}
        redirect={routeUrl('tender-order-details', params)}
      />
    </>
  );
});
const OrderView = withRouteX({ name: 'tender-order-view' })(DetailSection);

const Details = withPreload({
  route: 'tender-order-details',
  preload: service.observe.order(),
})(({ data: { tender, order } }) => {
  return (
    <>
      <Data.List className="product-details">
        <Data.Item
          title={
            <FormattedMessage
              id="tender-details-product field"
              description="Label for Product field on tenders order details page"
              defaultMessage="Product"
            />
          }
        >
          <Data.ProductLink product={order.product} />
        </Data.Item>
      </Data.List>
      <OrderDetails order={order} />
      <EditCounterButton tender={tender} order={order} />
    </>
  );
});
const DetailsPage = withRouteX({ name: 'tender-order-details' })(Details);

const OrderHistory = withPreload({
  route: 'tender-order-history',
  preload: service.observe.orderHistory(),
})(({ data: history }) => {
  return (
    <ul>
      {history.map(o => (
        <li key={o.id} className="order-item">
          <OrderDetails order={o} />
        </li>
      ))}
    </ul>
  );
});
const OrderHistoryPage = withRouteX({
  name: 'tender-order-history',
})(OrderHistory);

const CounterOrders = withPreload({
  route: 'tender-counter-orders',
  preload: service.observe.order(),
})(({ data: { tender, order } }) => {
  return (
    <ul className="tender-counter-orders-list">
      {order.counters.ranked.map(counter => (
        <li key={counter.id}>
          <OwnCounter counter={counter} />
          <IsAutobid counter={counter} />
          <CounterDetails counter={counter} />
          <Access to="trade" model={order}>
            {!counter.trade && (
              <CounterTrade tender={tender} order={order} counter={counter} />
            )}
          </Access>
        </li>
      ))}
      {!order.counters.ranked.length && (
        <li>
          <FormattedMessage
            id="tender-details-no-counters"
            description="Text for no counters on tenders order details page"
            defaultMessage="There are currently no {counterType, select, offer{offers} bid{bids} other{{counterType}s}} on this {orderType, select, offer{offer} bid{bid} other{{orderType}}}..."
            values={{
              counterType: tender.ordertype.counter,
              orderType: tender.ordertype.order,
            }}
          />
        </li>
      )}
    </ul>
  );
});
const CounterOrdersPage = withRouteX({
  name: 'tender-counter-orders',
})(CounterOrders);

const CounterHistory = withPreload({
  route: 'tender-counter-history',
  preload: service.observe.counterHistory(),
})(({ data: counters }) => {
  return (
    <ul className="counter-history-content">
      {counters.map(h => (
        <li key={h.id} className="counter-item">
          <IsAutobid counter={h} />
          <CounterDetails counter={h} />
        </li>
      ))}
    </ul>
  );
});
const CounterHistoryPage = withRouteX({
  name: 'tender-counter-history',
})(CounterHistory);

const OrderCounter = withPreload({
  route: 'tender-counter',
  preload: service.observe.order(),
})(({ data: { order } }) => {
  const mine = order.counters.mine;
  const showForm = can('add', order.counters) || can('edit', mine);

  return (
    <>
      {mine && <CounterDetails counter={mine} />}
      {showForm && <CounterForm />}
    </>
  );
});
const OrderCounterPage = withRouteX({
  name: 'tender-counter',
})(OrderCounter);

const OrderBuyers = withPreload({
  route: 'tender-order-buyers',
  preload: service.observe.buyers(),
})(({ data: { order, buyers } }) => {
  return (
    <ul className="order-buyers-list">
      {buyers
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(buyer => (
          <li key={buyer.id}>
            <Data.List>
              <Data.Item>{buyer.name}</Data.Item>
              <Data.Item>
                <Data.PriceTicker
                  title="Price"
                  value={order.startprice.new({ ...buyer.price })}
                />
              </Data.Item>
              {buyer.price.reason && (
                <Data.Item
                  title={
                    <FormattedMessage
                      id="tender-details-buyers-price-reason"
                      description="Label for reason field on tender buyers price tab on tenders details page"
                      defaultMessage="Reason"
                    />
                  }
                >
                  {buyer.price.reason}
                </Data.Item>
              )}
            </Data.List>
          </li>
        ))}
    </ul>
  );
});
const OrderBuyersPage = withRouteX({
  name: 'tender-order-buyers',
})(OrderBuyers);

const OrderQA = withPreload({
  route: 'tender-order-qa',
  preload: service.observe.order(),
})(({ data: { tender, order } }) => {
  const $access = Symbol.for('access');
  const api = useContext(ApiContext);
  const loadTenderOrderQuestions = useStoreActions(
    action => action.marketdepth.loadTenderOrderQuestions
  );

  return (
    <QA
      order={order}
      isClosed={tender.started()}
      isOwner={tender.owned}
      canAsk={() => order[$access].add?.questions}
      canAnswer={question => question.__acl__.add?.answers && !tender.started()}
      loadQuestions={() =>
        loadTenderOrderQuestions({ tenderId: tender.id, orderId: order.id })
      }
      askAction={async data => {
        const result = await api.postData(
          `/tenders/${tender.id}/offers/${order.id}/questions`,
          data
        );
        loadTenderOrderQuestions({ tenderId: tender.id, orderId: order.id });
        return result;
      }}
      answerAction={async (data, question) => {
        await api.postData(
          `/tenders/${tender.id}/offers/${order.id}/questions/${question.id}`,
          data
        );
        loadTenderOrderQuestions({ tenderId: tender.id, orderId: order.id });
      }}
    />
  );
});
const OrderQAPage = withRouteX({ name: 'tender-order-qa' })(OrderQA);
