import React from 'react';
import * as R from 'ramda';
import classNames from 'classnames';

const MonthView = ({ column }) => {
  const momentObj = column.moment;
  const quarters = {
    '01': 'Q1',
    '04': 'Q2',
    '07': 'Q3',
    '10': 'Q4',
  };
  const monthName = momentObj.format('MMMM');
  const [firstChar, rest] = R.splitAt(1, monthName);
  const thClassName = classNames(R.toLower(momentObj.format('MMM')), {
    'empty': column.empty,
    'no-trades': column.empty,
  });

  let width = 40;
  if (!column.empty) {
    width = 130;
  }

  return (
    <th className={thClassName}>
      <div style={{ width }} />
      <div className="year">
        <strong>{momentObj.format('YYYY')} </strong>
        <span>
          {momentObj.format('MM') in quarters
            ? quarters[momentObj.format('MM')]
            : ''}
        </span>
      </div>
      <div className="month">
        {firstChar}
        <span>{rest}</span>
      </div>
    </th>
  );
};

const WeekView = ({ column }) => {
  const { empty, moment } = column;
  const weekNumber = moment.format('ww');
  const range = `${moment.startOf('week').format('DD MMM')} - ${moment
    .endOf('week')
    .format('DD MMM')}`;

  const isFirstWeekOfMonth = mom => {
    return mom.week() === mom.clone().startOf('month').week();
  };

  return (
    <th
      className={classNames({
        'empty': empty,
        'no-trades': empty,
        'showing-month': isFirstWeekOfMonth(moment),
      })}
    >
      <div style={{ width: column.empty ? 40 : 130 }} />
      <div className="year_month">
        {isFirstWeekOfMonth(moment) && (
          <>
            <strong>{moment.clone().year()}</strong>
            <span className="show-month">
              {moment.clone().endOf('week').format('MMMM')}
            </span>
            <span className="show-if-last">
              {moment.clone().endOf('week').format('MMM')}
            </span>
          </>
        )}
      </div>
      <div className="week" title={`Week ${weekNumber}, ${range}`}>
        {!empty && <span>Week</span>} {weekNumber}
      </div>
    </th>
  );
};

const DayView = ({ column }) => {
  const { empty, moment } = column;
  const dayNumber = moment.clone().format('DD');
  const weekNumber = moment.clone().format('ww');

  const isStartOfWeek = mom => {
    return mom.day() === mom.clone().startOf('week').day();
  };

  return (
    <th
      className={classNames({
        'empty': empty,
        'no-trades': empty,
        'showing-week': isStartOfWeek(moment),
      })}
    >
      <div style={{ width: column.empty ? 40 : 130 }} />
      <div className="year_month">
        {isStartOfWeek(moment) && (
          <>
            <strong>{moment.clone().year()}</strong>
            <span>Week {moment.clone().endOf('week').format('ww')}</span>
          </>
        )}
      </div>
      <div className="week">
        {dayNumber} {moment.clone().format('MMM')}
      </div>
    </th>
  );
};

export default ({ column, mode }) => {
  if (mode === 'month') return <MonthView column={column} />;
  if (mode === 'day') return <DayView column={column} />;

  return <WeekView column={column} />;
};
