import Button from 'Components/nui/Button';
import { FormattedMessage } from 'react-intl';
import FilterDropdown from './FilterDropdown';
import { FilterTypes, useFilters } from './context';
import FilterChips from './FilterChips';
import { useStoreState } from 'easy-peasy';
import { deduplicateWithMap, invertDivisionType } from '~/utils';

export const getCustomerDivisions = (customer: any) => {
  const divisionType = invertDivisionType(customer.divisiontype);
  return customer.links.map(link => link[divisionType]);
};

const ProductFilters = ({ data = [], clearAction }) => {
  const solutionSettings = useStoreState(state => state.auth.solutionSettings);

  const divisionOptions = deduplicateWithMap(
    data?.map(product => ({
      value: product.division.id,
      label: product.division.name,
    })),
    'value'
  ).sort((a, b) => a.label.localeCompare(b.label));

  const { setFilters } = useFilters();

  return (
    <div className="filter-row">
      <FilterChips>
        <FilterDropdown
          id="division"
          label={
            <FormattedMessage
              id="customer-filter-field-division"
              description="label for 'Division' in Customer filter"
              defaultMessage="Division"
            />
          }
          type={FilterTypes.CHECKBOXES}
          options={divisionOptions}
        />

        <Button
          type="buttonlink"
          className="filter-reset"
          onClick={() => {
            clearAction && clearAction();
            setFilters({});
          }}
        >
          <FormattedMessage
            id="filter-button-clear-filter"
            description="Button for clear search filters"
            defaultMessage="Clear all filters"
          />
        </Button>
      </FilterChips>
    </div>
  );
};

export default ProductFilters;
