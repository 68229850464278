import type { ICustomerRequest } from '~/store/models';
import React, { useState, useEffect, useContext } from 'react';
import { CustomerRequestController } from '~/store/models';
import { useStoreState } from '~/store';
import { toast } from 'react-toastify';
import * as R from 'ramda';

type TFormStatus = ICustomerRequest['formstatus'];

interface IContext {
  customers: ICustomerRequest[];
  loading: boolean;
  error?: Error;
  remove(customer: ICustomerRequest): Promise<void>;
  total: number;
  limit: number;
  setLimit(i: number): void;
  page: number;
  setPage(i: number): void;
  formstatus: TFormStatus[];
  filter(v: TFormStatus[]): void;
}

const ViewContext = React.createContext<IContext>({
  customers: [],
  loading: true,
  async remove() {},
  total: 0,
  limit: 10,
  page: 1,
  setLimit() {},
  setPage() {},
  formstatus: [],
  filter() {},
});

interface IDefault {
  children?: React.ReactNode;
}
export default ({ children }: IDefault) => {
  const [customers, setCustomers] = useState<ICustomerRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [formstatus, setFormstatus] = useState<TFormStatus[]>([
    'incomplete',
    'rejected',
    'cancelled',
    'pending',
    'processed',
  ]);

  const solution = useStoreState(state => state.auth.solution.id);

  const update = async () => {
    setLoading(true);
    setCustomers([]);

    const filters = {
      page,
      limit,
      formstatus: R.includes(formstatus.length, [5, 0])
        ? undefined
        : formstatus,
    };

    try {
      const response = await CustomerRequestController.getOverview({
        solution,
        filters,
      });
      setCustomers(response.data?.customers || []);
      setTotal(response.data?.total || 0);
    } catch (err) {
      setError(err);
      toast('An error occurred while loading the page', { type: 'error' });
    }

    setLoading(false);
  };

  const remove = async (customer: ICustomerRequest) => {
    try {
      await CustomerRequestController.deleteRequest(customer);
      setCustomers(
        R.map(c =>
          c.id === customer.id ? { ...c, formstatus: 'cancelled' } : c
        )
      );
    } catch (err) {
      setError(err);
      toast('An error occurred while loading the page', { type: 'error' });
    }
  };

  useEffect(() => {
    update();
  }, [page, limit, formstatus.length]);

  return (
    <ViewContext.Provider
      value={{
        customers,
        loading,
        error,
        remove,
        total,
        limit,
        setLimit,
        page,
        setPage,
        formstatus,
        filter(value) {
          setFormstatus(value);
        },
      }}
    >
      {children}
    </ViewContext.Provider>
  );
};

export function useTable() {
  return useContext(ViewContext);
}
