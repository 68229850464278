import { Loading, Popover, Table } from 'Components/nui';
import React, { useEffect, useState } from 'react';
import Button from 'Components/nui/Button';
import { useToggle } from 'react-use';
import classNames from 'classnames';
import FNumber from 'Components/FNumber';
import * as R from 'ramda';
import { useFilterIndexData } from 'Pages/Dashboard';
import moment from 'moment-timezone';
import { Radio } from 'antd';
import { api } from '~/api';

export default () => {
  const [filter] = useFilterIndexData();
  const [count, setCount] = useState(1);
  const [data, setData] = useState([]);
  const userTz = moment.tz.guess();
  const [loading, toggle] = useToggle(true);
  const [priceDiff, setPriceDiff] = useState('Week');
  const { productsFilter, currencyFilter, uomFilter } = filter || {};

  useEffect(() => {
    if (!filter.productsFilter?.length || !filter.uomFilter) {
      setData(state => ({
        ...state,
        data: [],
      }));
      return;
    }
    let mounted = true;
    toggle(true);

    (async () => {
      try {
        const rawData = await api.analytics(
          `indexes/compare`,
          [filter.uomFilter, filter.currencyFilter],
          {
            source: filter.productsFilter,
            period: `${count}${priceDiff === 'Week' ? 'W' : 'M'}`,
            tz: userTz,
          }
        );
        if (mounted) {
          const newData = R.pathOr({}, ['data', 'data'], rawData);
          const start = R.pathOr({}, ['data', 'start'], rawData);
          const end = R.pathOr({}, ['data', 'end'], rawData);

          let thelist = [];
          newData.forEach(record => {
            thelist.push({
              avgPrice: record.avg,
              priceDiff: record.avg_diff,
              category: record.category,
              endPrice: record.start_dt === record.end_dt ? '' : record.end,
              endDate:
                record.start_dt === record.end_dt
                  ? ''
                  : moment(record.end_dt).format('DD MMM YYYY'),
              key: record.key,
              label: `${record.category} - ${record.name}`,
              startPrice: record.start,
              startDate: moment(record.start_dt).format('DD MMM YYYY'),
            });
          });

          setData({
            start: start,
            end: end,
            data: thelist,
          });
          toggle(false);
        }
      } catch (error) {
        // pass
      }
    })();

    return () => {
      mounted = false;
    };
  }, [
    count,
    priceDiff,
    productsFilter,
    currencyFilter,
    uomFilter,
    setData,
    toggle,
  ]);

  const clickPrev = () => {
    setCount(R.add(1));
  };

  const clickNext = () => {
    setCount(R.add(-1));
  };

  const columns = [
    {
      title: <span>Index</span>,
      key: 'index',
      className: 'col-header-product',
      render: (text, record) => record.label,
    },
    {
      title: (
        <>
          <div>
            <span className="span-start">Start</span>
            <Popover
              overlay={
                <span>
                  This is the first reported price for the index within the
                  selected time period
                </span>
              }
            >
              <span className="popover-link">?</span>
            </Popover>
          </div>
          <span className="subtext">
            {filter.currencyFilter}/{filter.uomFilter}
          </span>
        </>
      ),
      key: 'start-price',
      className: 'col-header-start-price',
      render: (text, record) =>
        !!record.startPrice && (
          <Popover overlay={<span>Price on {record.startDate} </span>}>
            <span>
              <span className="start-price">
                <FNumber value={record.startPrice} decimalCount={2} />
              </span>
            </span>
          </Popover>
        ),
    },
    {
      title: (
        <div>
          <div>
            <span className="span-end">End</span>
            <Popover
              overlay={
                <span>
                  This is the last reported price for the index within the
                  selected time period
                </span>
              }
            >
              <span className="popover-link">?</span>
            </Popover>
          </div>
          <span className="subtext">
            {filter.currencyFilter}/{filter.uomFilter}
          </span>
        </div>
      ),
      key: 'end-price',
      className: 'col-header-end-price',
      render: (text, record) =>
        !!record.endPrice && (
          <Popover overlay={<span>Price on {record.endDate} </span>}>
            <span className="price-badge-parent">
              <span className="end-price">
                <FNumber value={record.endPrice} decimalCount={2} />
              </span>
            </span>
          </Popover>
        ),
    },
    {
      title: (
        <div>
          <div>
            <span className="span-avg">Avg</span>

            <Popover
              overlay={
                <span>
                  This is the average price for the selected time period
                </span>
              }
            >
              <span className="popover-link">?</span>
            </Popover>
          </div>
          <span className="subtext">
            {filter.currencyFilter}/{filter.uomFilter}
          </span>
        </div>
      ),
      key: 'avg-price',
      className: 'col-header-avg-price',
      render: (text, record) => (
        <span>
          {record.avgPrice ? (
            <FNumber value={record.avgPrice} decimalCount={2} />
          ) : (
            'NA'
          )}
        </span>
      ),
    },
    {
      title: (
        <div>
          <div>
            <span className="span-wow">
              {priceDiff === 'Week' ? 'WOW' : 'MOM'}%
            </span>
            <Popover
              overlay={
                <span>
                  {`This is the average ${
                    priceDiff === 'Week' ? 'week on week' : 'month on month'
                  } percentage change for the selected indices`}
                </span>
              }
            >
              <span className="popover-link">?</span>
            </Popover>
          </div>

          <span className="subtext">Avg% change</span>
        </div>
      ),
      key: 'price-diff',
      className: 'col-header-price-diff',
      render: (text, record) => (
        <div
          className={classNames('price-diff', {
            'positive': record.priceDiff > 0,
            'negative': record.priceDiff < 0,
            'no-change': !record.priceDiff,
          })}
        >
          {record.priceDiff === 0
            ? '0.00%'
            : record.priceDiff > 0
            ? `+${record.priceDiff}%`
            : record.priceDiff < 0
            ? `${record.priceDiff}%`
            : 'NA'}

          <div
            className={classNames({
              arrow: !!record.priceDiff,
              positive: record.priceDiff > 0,
              negative: record.priceDiff < 0,
            })}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="left-hand-panel">
      <div className="viewing">
        <div>
          <span className="viewing-text">Viewing: </span>
          {`${moment(data.start).format('ddd Do MMM YYYY')} -
            ${moment(data.end).format('ddd Do MMM YYYY')}`}
        </div>
        <div className="actions">
          <Button
            onClick={clickPrev}
            type="reverse"
            size="small"
            className="bold mr-10 prev"
            disabled={count >= 12}
          >
            Previous
          </Button>
          <Radio.Group
            className="viewing-range-group"
            value={priceDiff}
            onChange={e => {
              setPriceDiff(e.target.value);
              setCount(1);
            }}
          >
            <Radio
              className="viewing-range-radio"
              name="viewing-range-type"
              value="Week"
            >
              <span className="viewing-range-radio-label">Week</span>
            </Radio>

            <Radio
              className="viewing-range-radio"
              name="viewing-range-type"
              value="Month"
            >
              <span className="viewing-range-radio-label">Month</span>
            </Radio>
          </Radio.Group>
          <Button
            onClick={clickNext}
            type="reverse"
            size="small"
            disabled={count <= 1}
            className="bold mr-10 next"
          >
            Next
          </Button>
        </div>
      </div>
      {loading ? (
        <Loading className="mb-10 mt-10" size="medium" />
      ) : (
        <Table
          className="generic-table left"
          columns={columns}
          data={data.data}
          rowKey={R.prop('key')}
          emptyText={() => (
            <div className="nui-row full-page-messaging align-center pb-100 mt-50 b-0">
              <div className="icon-inbox bordered align-center">
                No data to display
              </div>
            </div>
          )}
        />
      )}
    </div>
  );
};
