import * as R from 'ramda';
import * as Fields from './fields';
// XXX This is causing misfunction of babel plugins

// Thin wrapper around form to deal with normalization (through Field), rendering, data collecting & tranform.
// Not support complex structure such as nesting.
// TODO
// - Form to accept field instance as well
// - Field can be hidden if there is only one choice

export class FormWrapper {
  constructor(form, initialData, fields) {
    this.form = form;
    this.initialData = { ...initialData };
    this.fields = fields.map(field => this.getField(field));
  }

  getField(spec) {
    if (spec instanceof Fields.BaseField) return spec;
    if (spec.render) return spec;
    // TODO initialData will be overrode by the one from spec, is it correct? Any better logic?
    const initialData = this.initialData[spec.name];
    const Field = Fields[`${spec.type}Field`];
    return new Field({
      ...spec,
      initialValue: R.isNil(initialData) ? spec.initialValue : initialData,
    });
  }

  hasField(name) {
    return R.find(R.pathEq(['spec', 'name'], name), this.fields);
  }

  renderField(name) {
    return this.hasField(name).render(this);
  }

  render() {
    return this.fields.map(field => field.render(this));
  }

  serialize() {
    const values = this.form.getFieldsValue();
    return R.mergeAll(
      this.fields
        .filter(field => field.serializeField)
        .map(field => field.serializeField(values))
    );
  }

  // This method call error field's name based on the field's label, if there is no field's label, then call it 'Field'
  setErrors(errors, values = this.form.getFieldsValue()) {
    if (!R.is(Object, errors)) return;
    this.form.setFields(
      R.mergeAll(
        R.toPairs(errors).map(([key, es]) => ({
          [key]: {
            value: values[key],
            errors:
              es.map != null &&
              es.map(
                e =>
                  new Error(
                    e.replace(/\{(\w+)\}/g, (m, name) =>
                      R.compose(
                        R.pathOr('Field', ['spec', 'label']),
                        R.find(R.pathEq(['spec', 'name'], name))
                      )(this.fields)
                    )
                  )
              ),
          },
        }))
      )
    );
  }
}
