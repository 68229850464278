import React, { useContext, useState, useEffect } from 'react';
import classnames from 'classnames';
import * as R from 'ramda';
import Button from './nui/Button';
import { FormattedMessage } from 'react-intl';
import { Order, Tender } from '~/models/tenders';
import TenderFilters from './filters/TenderFilters';

interface IRoloState {
  active: string;
  isOpen(id: string): boolean;
  toggle(id: string): void;
  toggleAll(): void;
  isAllOpen(): boolean;
}
const RoloContext = React.createContext<IRoloState | undefined>(undefined);

export function useRolodex() {
  const context = useContext(RoloContext);
  if (!context) throw new Error('RoloContext is undefined');

  return context;
}

interface ICard {
  id: string;
  heading(props: { open: boolean }): React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  className?: string;
  children?: React.ReactNode;
}
export const Card = ({ id, heading, onClick, className, children }: ICard) => {
  const rolodex = useRolodex();
  const active = rolodex.active === id;

  function toggle() {
    rolodex.toggle(id);
  }

  const open = rolodex.isOpen(id);
  const classname = classnames('rolodex-card', className, {
    active,
    min: !open,
  });

  return (
    <li
      className={classname}
      onClick={e => {
        document.body.classList.add('detail-area-open');
        if (!open) {
          toggle();
        }
        onClick && onClick(e);
      }}
    >
      <div className="rolodex-card-heading">
        <Button
          type="expand"
          expanded={open}
          onClick={e => {
            e.stopPropagation();
            toggle();
          }}
        ></Button>
        {heading({ open })}
      </div>
      {open && <div className="rolodex-card-content">{children}</div>}
    </li>
  );
};

export interface ISortable {
  options: (readonly [string, React.ReactNode])[];
  value: string;
  onChange(value: string): void;
}

interface IWheel {
  heading?: React.ReactNode;
  className?: string;
  sortable?: ISortable;
  filter?: React.ReactNode;
  children: React.ReactElement<ICard>[];
  items: Order[];
}
export const Wheel = ({ className, children }: IWheel) => {
  const rolodex = useRolodex();
  return (
    <div className={classnames('rolodex-column rolodex-wheel', className)}>
      {children.length > 0 ? (
        <>
          <ul className="rolodex-wheel-items">{children}</ul>
        </>
      ) : (
        <div className="rolodex-sort rolodex-empty">
          <FormattedMessage
            id="tender-details-no-offers"
            description="Message for user when there are no offers on tender details page"
            defaultMessage="There are no offers to display"
          />
        </div>
      )}
    </div>
  );
};

interface IContent {
  className?: string;
  children?: React.ReactNode;
}
export const Content = ({ className, children }: IContent) => {
  return (
    <div className={classnames('rolodex-column rolodex-content', className)}>
      <div className="content">
        <button
          className="close-detail-area"
          onClick={() =>
            void document.body.classList.remove('detail-area-open')
          }
        >
          <span className="icon-cancel-1 " />
        </button>
        {children}
      </div>
    </div>
  );
};

interface IRolodex {
  tender: Tender;
  active: string;
  allOrderIds: string[];
  className?: string;
  items: Order[];
  sortable?: ISortable;
  filter?: React.ReactNode;
  children: [
    React.ReactElement<IWheel, typeof Wheel>,
    React.ReactElement<IContent, typeof Content> | null
  ];
}
const Rolodex = ({
  tender,
  active,
  allOrderIds,
  className,
  items,
  sortable,
  filter,
  children,
}: IRolodex) => {
  const [open, setOpen] = useState<string[]>(
    allOrderIds?.length > 3 ? [] : allOrderIds
  );

  useEffect(
    () => () => void document.body.classList.remove('detail-area-open'),
    []
  );

  function isOpen(id: string) {
    return open.includes(id);
  }

  function isAllOpen() {
    return open.length === allOrderIds.length;
  }

  function toggle(id: string) {
    setOpen(state =>
      state.includes(id) ? R.reject(R.equals(id), state) : [...state, id]
    );
  }

  function toggleAll() {
    if (open.length < allOrderIds.length) setOpen(allOrderIds);
    else {
      setOpen([]);
    }
  }

  const state = { active, isOpen, toggle, toggleAll, isAllOpen };
  return (
    <RoloContext.Provider value={state}>
      <TenderFilters
        items={items}
        sortable={sortable}
        filter={filter}
        tender={tender}
      />
      <div className={classnames('rolodex', className)}>{children}</div>
    </RoloContext.Provider>
  );
};

Rolodex.Card = Card;
Rolodex.Wheel = Wheel;
Rolodex.Content = Content;
export default Rolodex;
