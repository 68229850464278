import React, { useState } from 'react';
import { UsingSchema, useSchema, useCustomerRequest } from './EditContext';
import { FormWrapper } from 'Components/form';
import { mergeSchema } from 'Components/form/utils';
import { promisify } from 'es6-promisify';
import { Button, Alert, Form } from 'Components/nui';
import { toast } from 'react-toastify';
import * as R from 'ramda';
import { NextStep } from 'Components/nui/Wizard';
import { Route, useRouteMatch, useHistory } from 'react-router-dom';

interface IStep {
  title: string;
  desc?: string;
}
const Step = ({ title, desc }: IStep) => (
  <div className="form-step">
    <h3 className="numbered outside">{title}</h3>
    {desc && <p className="description">{desc}</p>}
  </div>
);

interface KeyVal {
  [id: string]: string;
}
interface PortsChoices {
  [id: string]: [string, string][];
}
interface PortsData {
  choices: PortsChoices;
}
type PortsPair = [string, [string, string][]];
interface PortsSelect {
  key: string;
  label: string;
  value: {
    key: string;
    label: string;
    value: string;
  }[];
}

export default () => {
  return (
    <div className="content-locations-details">
      <UsingSchema schema="locations">
        <LocationForm />
      </UsingSchema>
    </div>
  );
};

function useFormWrapper({ form, schema, customer, location }: any) {
  const countries: KeyVal = R.fromPairs(schema.country.choices);

  const fields = [
    { render: () => <Step key="step-1" title="Location details" /> },
    {
      name: 'description',
      label: 'Add a name or description for this location',
      type: 'Input',
      props: { placeholder: 'E.g. Main warehouse' },
    },
    {
      name: 'isdefault',
      label: 'Default location',
      required: true,
    },
    { render: () => <Step key="step-2" title="Address details" /> },
    {
      render: () =>
        customer.address ? (
          <Alert key="copy-phys" hasicon className="mb-20">
            <Button
              key="copy-address"
              type="simple"
              size="small"
              htmlType="button"
              className="copy-address inline p-0"
              onClick={() => {
                const {
                  phyaddr1,
                  phyaddr2,
                  phycity,
                  phystate,
                  phyzip,
                  phycountry,
                } = customer.address;
                form.setFieldsValue({
                  addr1: phyaddr1,
                  addr2: phyaddr2,
                  city: phycity,
                  state: phystate,
                  zip: phyzip,
                  country: phycountry?.id,
                });
              }}
            >
              Copy address details
            </Button>
            <span className="pl-5">from the physical address.</span>
          </Alert>
        ) : null,
    },
    {
      name: 'addr1',
      label: 'Street',
      type: 'Input',
    },
    {
      name: 'addr2',
      label: 'Suburb',
      type: 'Input',
    },
    {
      name: 'city',
      label: 'City',
      type: 'Input',
    },
    {
      name: 'state',
      label: 'State',
      type: 'Input',
    },
    {
      name: 'zip',
      label: 'ZIP code',
      type: 'Input',
    },
    {
      name: 'country',
      label: 'Country',
      type: 'Select',
      choices: R.pipe(
        R.propOr([], 'choices'),
        R.map(([value, label]) => ({ value, label, key: value }))
      ),
    },
    {
      name: 'port',
      label: 'Port',
      choices: R.pipe<
        PortsData,
        PortsChoices,
        PortsPair[],
        PortsPair[],
        PortsPair[],
        PortsSelect[]
      >(
        R.propOr({}, 'choices'),
        R.toPairs,
        R.filter(([code]: PortsPair) =>
          R.equals(code, R.propOr(code, 'country', form.getFieldsValue()))
        ),
        R.sort(([first], [second]) =>
          R.propOr<string, KeyVal, string>('', first, countries).localeCompare(
            R.propOr('', second, countries)
          )
        ),
        R.map(([country, ports]) => ({
          key: country,
          label: countries[country],
          value: ports.map(([value, label]) => ({ value, label, key: value })),
        }))
      ),
    },
  ];

  const initialData = {
    ...location,
    country: location.country?.id,
    port: location.port?.id,
  };

  return new FormWrapper(form, initialData, mergeSchema(schema, fields));
}

const BaseForm = ({ form, schema }: any) => {
  const { customer, actions } = useCustomerRequest();
  const { params } = useRouteMatch<{ locationId: string }>();
  const { locationId } = params;
  const location: any =
    R.find(R.propEq('id', locationId), customer.locations || []) || {};
  const formWrapper = useFormWrapper({ form, schema, customer, location });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);
  const [loading, setLoading] = useState(false);
  const [last, setLast] = useState<[string, string]>();
  const history = useHistory();

  async function handleSubmit(e: any) {
    e.preventDefault();

    setLoading(true);
    setLast(undefined);

    try {
      await validateFieldsAndScroll();
    } catch (err) {
      setLoading(false);
      return;
    }

    try {
      const data = { id: location.id, ...formWrapper.serialize() };
      await actions.editLocation(data);

      const { description } = data;
      const action = location.id ? 'updated' : 'added';
      setLast([description, action]);

      form.resetFields();
      setLoading(false);
      toast(`${description} has been ${action} successfully`, {
        type: 'success',
      });
      history.push(`/customer-requests/${customer.id}/locations`);
    } catch (err) {
      if (err?.response && err.response?.status === 400) {
        const validation = err.response?.data?.errors || [];
        if (validation.length) {
          formWrapper.setErrors(validation[0]?.description || []);
        } else {
          // wtf
          toast('Form did not validate.', { type: 'error' });
          console.error('form did not validate', err);
        }
      } else {
        toast(
          'There was an error while submitting your form. Please try again later.',
          { type: 'error' }
        );
      }
      setLoading(false);
    }

    window.scrollTo(0, 0);
  }

  return (
    <div className="nui-form">
      <Form onSubmit={handleSubmit}>
        <div className="form-steps-container">
          {last && (
            <Alert hasicon type="success">
              <strong>{last[0]}</strong> has been {last[1]} successfully. You
              can add another, or <NextStep>continue</NextStep>.
            </Alert>
          )}
          {formWrapper.render()}
          <div className="sticky-btm button-set mt-20 trio trio-for-small">
            <Button
              type="primary"
              disabled={loading}
              loading={loading}
              htmlType="submit"
            >
              Save
            </Button>
            <NextStep />
          </div>
        </div>
      </Form>
    </div>
  );
};

type IWrappedForm = (props: any) => React.ReactElement;
const WrappedForm: IWrappedForm = Form.create()(BaseForm) as any;

const LocationForm = () => {
  const schema = useSchema('locations');
  return (
    <Route path="/customer-requests/:id/locations/:locationId?">
      <WrappedForm schema={schema} />
    </Route>
  );
};
