import React from 'react';
import { Form, Button } from 'Components/nui';
import { FormWrapper } from 'Components/form';
import { mergeSchema } from 'Components/form/utils';
import { ShowResult } from 'Components/Result';
import { promisify } from 'es6-promisify';
import * as R from 'ramda';
import { submitError, submitSuccess } from 'Components/form/utils';
import { useStoreActions } from 'easy-peasy';
import { api } from '~/api';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

function getFormWrapper({ form }) {
  const fields = [
    {
      name: 'reason',
      label: (
        <FormattedMessage
          id="marketrow-decline-label"
          description="Label for Decline in Marketrow"
          defaultMessage="Reason for declining"
        />
      ),
    },
  ];
  const schema = { reason: { required: true, type: 'String' } };

  return new FormWrapper(form, {}, mergeSchema(schema, fields));
}

function NForm({ form, order, submitState }) {
  const formWrapper = getFormWrapper({
    form,
    order,
  });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);

  async function handleSubmit(e) {
    e.preventDefault();
    // Client-side validation
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }
    // Server-side validation
    submitState.setLoading(true);
    const data = formWrapper.serialize();
    try {
      const result = await api.getData(
        { type: 'orders', id: `${order.id}/decline`, getResult: x => x },
        null,
        { method: 'post', data }
      );

      submitState.setResult(result);
      toast.info('The order was declined');
    } catch (err) {
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        err
      );
      if (errors) {
        formWrapper.setErrors(errors);
      } else {
        submitState.setResult(err);
      }
    }
    submitState.setLoading(false);
  }

  return (
    <Form onSubmit={handleSubmit}>
      {formWrapper.render()}
      <Form.Item>
        <Button
          type="secondary"
          size="small"
          htmlType="submit"
          loading={submitState.loading}
        >
          <FormattedMessage
            id="marketrow-decline-button"
            description="Button for `Decline` on Decline in Marketrow"
            defaultMessage="Decline"
          />
        </Button>
      </Form.Item>
    </Form>
  );
}

const WrappedForm = Form.create()(NForm);

export default ({ order }) => (
  <ShowResult renderSuccess={submitSuccess} renderError={submitError}>
    {submitState => (
      <div className="wrapper">
        <div className="nui-form order-form mt-10">
          <WrappedForm
            key={`form-decline-${order.id}`}
            order={order}
            submitState={submitState}
          />
        </div>
      </div>
    )}
  </ShowResult>
);
