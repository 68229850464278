import React, { useEffect, useState } from 'react';
import { Alert } from 'Components/nui';
import { FileLink } from 'Components/Data';
import { useStoreState } from 'easy-peasy';
import moment from 'moment-timezone';
import InfoForm from './InfoForm';
import { useSharedTrade } from '..';
import * as R from 'ramda';
import { FormattedMessage } from 'react-intl';

const createHistory = ({ attachments, info, freightquote }) => {
  /**
   * @description
   * Creates a history array of info and attachments combined ordered by creation time.
   * The array is then grouped into boxes that will be later used by components
   * The resulting array is then to be inserted into appropriate component
   * @param attachments: Array
   * @param info: Array or undefined
   */
  let history = [];

  // Add attachments. The trade.attachments is always an array, empty if there are no attachments
  if (attachments && attachments.length) {
    history = attachments
      .filter(a => a)
      .map(f => ({
        ...f,
        type: { id: 100, name: 'File' },
      }));
  }
  // Add info. The trade.info will be undefined if there is no info
  if (info && InfoForm.length) history = [...history, ...info];
  // Add freight quote
  if (freightquote?.quote) {
    /** freight dict is very different to tradeinfo so we have to create a division placeholder in order to pass the grouping logic below
    We don't want them to be grouped, so we assign them different division.shortcode 
    */
    // the initial
    history.push({
      ...freightquote.quote,
      type: { id: 10, name: 'provided' },
      division: { shortcode: 'seller' },
      isprivate: false,
      value: freightquote.quote.sellcomment,
    });
    // create another history record if the quote was accepted
    if (freightquote.quote.accepted) {
      history.push({
        ...freightquote.quote,
        type: { id: 10, name: 'accepted' },
        division: { shortcode: 'buyer' },
        isprivate: false,
        created: freightquote.quote.acceptedon,
        value: freightquote.quote.buycomment,
      });
    }
    // create another history record if the quote was declined
    if (freightquote.quote.declined) {
      history.push({
        ...freightquote.quote,
        type: { id: 10, name: 'declined' },
        division: { shortcode: 'buyer' },
        isprivate: false,
        created: freightquote.quote.declinedon,
        value: freightquote.quote.buycomment,
      });
    }
  }

  // Sort by creation time
  history = history.sort((a, b) => {
    if (moment(a.created) < moment(b.created)) return 1;
    if (moment(a.created) > moment(b.created)) return -1;
    return 0;
  });
  /**
   * Grouping logic:
   * Box = [1st obj in history], then
   * for each obj in history: if obj{type, division, private} eq box[-1]{type, division, private}, then box add the obj
   *                          else: boxes += box, box = [obj]
   */
  if (history.length) {
    let box = [history[0]];
    let boxes = [];
    history.slice(1).forEach(o => {
      let t = box[box.length - 1];
      if (
        o.type.id === t.type.id &&
        o.division.shortcode === t.division.shortcode &&
        o.isprivate === t.isprivate &&
        moment(t.created).diff(moment(o.created), 'minutes') <= 1
      ) {
        box.push(o);
      } else {
        boxes.push(box);
        box = [o];
      }
    });
    boxes.push(box); //Final push
    return boxes;
  } else return [];
};

const HistoryObjects = ({ entries, trade }) => {
  /**
   * @description
   * Receives an Array of history objects that should be grouped into one box by type, owner and view mode
   * PONumber and FreightQuote always have one object max in them
   */
  switch (entries[0].type.id) {
    case 0:
      return <PONumber poNumber={entries[0]} />;
    case 1:
      return <Comments comments={entries} trade={trade} />;
    case 2:
      return <FreightRequest quote={entries[0]} />;
    case 4:
      return <SONumber soNumber={entries[0]} />;
    case 10:
      return <FreightQuote quote={entries[0]} />;
    case 100:
      return <FilesHistory files={entries} />;

    default:
      return null;
  }
};

const FilesHistory = ({ files }) => {
  const myDivisions = useStoreState(state => state.auth.solutionDivisions).map(
    i => i.shortcode
  );
  const mine = myDivisions.includes(files[0].division.shortcode);
  return (
    <li key={files[0].created}>
      <h4>
        <FormattedMessage
          id="viewtrade-actionhistory-helptext-files"
          description="helptext for files Action history in View trade"
          defaultMessage="Files added"
        />{' '}
        <span className="date">
          {moment(files[0].created).format('DD MMM YYYY HH:mm')}
        </span>
      </h4>{' '}
      {!mine && (
        <p className="p-0">
          {files[0].division.name}{' '}
          <FormattedMessage
            id="viewtrade-actionhistory-helptext-files-hasadded"
            description="helptext for files: has added Action history in View trade"
            defaultMessage="has added {files, plural, one {1 file} other {# files}}"
            values={{
              files: files.length,
            }}
          />
        </p>
      )}
      <ul>
        {files.map(f => (
          <li key={f.id}>
            <FileLink file={f} />
          </li>
        ))}
      </ul>
      {mine && (
        <p className="smaller p-0 mt-10 mb-10">
          <strong className="all-black">
            <FormattedMessage
              id="viewtrade-actionhistory-helptext-files-viewingmode"
              description="helptext for files: Viewing mode Action history in View trade"
              defaultMessage="Viewing mode"
            />{' '}
          </strong>
          {files[0].isprivate ? (
            <FormattedMessage
              id="viewtrade-actionhistory-helptext-files-viewingmode-private"
              description="helptext for files: Viewing mode Private Action history in View trade"
              defaultMessage="Private"
            />
          ) : (
            <FormattedMessage
              id="viewtrade-actionhistory-helptext-files-viewingmode-public"
              description="helptext for files: Viewing mode Public Action history in View trade"
              defaultMessage="Public"
            />
          )}
        </p>
      )}
    </li>
  );
};

const PONumber = ({ poNumber }) => {
  const myDivisions = useStoreState(state => state.auth.solutionDivisions).map(
    i => i.shortcode
  );
  const mine = myDivisions.includes(poNumber.division.shortcode);
  return (
    <li key={poNumber.id}>
      <h4>
        <FormattedMessage
          id="viewtrade-actionhistory-helptext-ponumber-provided"
          description="helptext for Purchase order number provided Action history in View trade"
          defaultMessage="Purchase order number provided"
        />
        <span className="date">
          {moment(poNumber.created).format('DD MMM YYYY HH:mm')}
        </span>
      </h4>
      <div className="comment-box submission">
        <blockquote>
          {mine ? (
            <FormattedMessage
              id="viewtrade-actionhistory-helptext-ponumber-viewingmode-ourpurchase"
              description="helptext for purchase order number: Our purchase Action history in View trade"
              defaultMessage="Our purchase"
            />
          ) : (
            <FormattedMessage
              id="viewtrade-actionhistory-helptext-ponumber-viewingmode-purchase"
              description="helptext for ponumber: Purchase Action history in View trade"
              defaultMessage="Purchase"
            />
          )}{' '}
          <FormattedMessage
            id="viewtrade-actionhistory-helptext-ponumber-is"
            description="helptext for ponumber: order number is Action history in View trade"
            defaultMessage="order number is"
          />{' '}
          <strong>{poNumber.value}</strong>
        </blockquote>
      </div>
    </li>
  );
};

const SONumber = ({ soNumber }) => {
  const myDivisions = useStoreState(state => state.auth.solutionDivisions).map(
    i => i.shortcode
  );
  const mine = myDivisions.includes(soNumber.division.shortcode);
  return (
    <li key={soNumber.id}>
      <h4>
        Sales order number provided
        <span className="date">
          {moment(soNumber.created).format('DD MMM YYYY HH:mm')}
        </span>
      </h4>
      <div className="comment-box submission">
        <blockquote>
          {mine ? 'Our sales' : 'Sales'} order number is{' '}
          <strong>{soNumber.value}</strong>
        </blockquote>
      </div>
    </li>
  );
};

const FreightRequest = ({ quote }) => {
  const myDivisions = useStoreState(state => state.auth.solutionDivisions).map(
    i => i.shortcode
  );
  const [trade] = useSharedTrade();
  const mine = myDivisions.includes(quote.division.shortcode);
  return (
    <li key={quote.id}>
      <h4>
        {trade.discountavailable ? (
          <FormattedMessage
            id="viewtrade-actionhistory-helptext-freightrequest-discount"
            description="helptext for Freight Request: Discount Action history in View trade"
            defaultMessage="Discount requested <span>{time}</span>"
            values={{
              time: moment(quote.created).format('DD MMM YYYY HH:mm'),
              span: chunks => <span className="date">{chunks}</span>,
            }}
          />
        ) : (
          <FormattedMessage
            id="viewtrade-actionhistory-helptext-freightrequest-quote"
            description="helptext for Freight Request: Freight quote Action history in View trade"
            defaultMessage="Freight quote requested <span>{time}</span>"
            values={{
              time: moment(quote.created).format('DD MMM YYYY HH:mm'),
              span: chunks => <span className="date">{chunks}</span>,
            }}
          />
        )}
      </h4>
      {quote.value && (
        <div className={`comment-box ${mine ? 'yours' : 'theirs'}`}>
          <blockquote>{quote.value}</blockquote>
        </div>
      )}
    </li>
  );
};

const FreightQuote = ({ quote }) => {
  const [trade] = useSharedTrade();
  const isSeller = trade.offer.is_owner;
  let mine =
    (isSeller && quote.type.name === 'provided') ||
    (!isSeller && quote.type.name !== 'provided');
  return (
    <li key={quote.id}>
      <h4>
        {trade.discountavailable ? (
          <FormattedMessage
            id="viewtrade-actionhistory-helptext-freightdiscount"
            description="helptext for Freight Request: Discount Action history in View trade"
            defaultMessage="Discount {name} <span>{time}</span>"
            values={{
              time: moment(quote.created).format('DD MMM YYYY HH:mm'),
              span: chunks => <span className="date">{chunks}</span>,
              name: quote.type.name,
            }}
          />
        ) : (
          <FormattedMessage
            id="viewtrade-actionhistory-helptext-freightquote"
            description="helptext for Freight Request: Freight quote Action history in View trade"
            defaultMessage="Freight quote {name} <span>{time}</span>"
            values={{
              time: moment(quote.created).format('DD MMM YYYY HH:mm'),
              span: chunks => <span className="date">{chunks}</span>,
              name: quote.type.name,
            }}
          />
        )}
      </h4>
      <div className="comment-box submission">
        <blockquote>
          <div>
            {trade.discountavailable ? (
              <FormattedMessage
                id="viewtrade-actionhistory-helptext-freightquote-discount"
                description="helptext for Freight Quote: discount Action history in View trade"
                defaultMessage="Total discount is"
              />
            ) : (
              <FormattedMessage
                id="viewtrade-actionhistory-helptext-freightquote-cost"
                description="helptext for Freight Quote: freight cost Action history in View trade"
                defaultMessage="Total freight cost is"
              />
            )}{' '}
            <strong className="all-black nowrap">
              {quote.rate.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}{' '}
              {trade.price.currency}
            </strong>
          </div>
          {quote.value}
        </blockquote>
      </div>
    </li>
  );
};

const Comments = ({ comments, trade }) => {
  const myDivisions = useStoreState(state => state.auth.solutionDivisions).map(
    i => i.shortcode
  );
  const mine = myDivisions.includes(comments[0].division.shortcode);
  return (
    <li key={comments[0].created}>
      <h4>
        <FormattedMessage
          id="viewtrade-actionhistory-helptext-comments-added"
          description="helptext for comments: Comments added Action history in View trade"
          defaultMessage="Comments added"
        />{' '}
        <span className="date">
          {moment(comments[0].created).format('DD MMM YYYY HH:mm')}
        </span>
      </h4>
      <ul>
        {comments.map(c => (
          <li key={c.id}>
            <div className={`comment-box ${mine ? 'yours' : 'theirs'}`}>
              {mine ? (
                c.isprivate ? (
                  <FormattedMessage
                    id="viewtrade-actionhistory-helptext-comments-mymessageprivate"
                    description="helptext for comments: My message Action history in View trade"
                    defaultMessage="<strong>My message</strong> (private) <blockquote>{comment}</blockquote>"
                    values={{
                      comment: c.value,
                      strong: chunks => <strong>{chunks}</strong>,
                      blockquote: chunks => <blockquote>{chunks}</blockquote>,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="viewtrade-actionhistory-helptext-comments-mymessage"
                    description="helptext for comments: My message Action history in View trade"
                    defaultMessage="<strong>My message</strong> to {name} <blockquote>{comment}</blockquote>"
                    values={{
                      name: trade.otherparty.name,
                      comment: c.value,
                      strong: chunks => <strong>{chunks}</strong>,
                      blockquote: chunks => <blockquote>{chunks}</blockquote>,
                    }}
                  />
                )
              ) : (
                <FormattedMessage
                  id="viewtrade-actionhistory-helptext-comments-says"
                  description="helptext for comments: says Action history in View trade"
                  defaultMessage="<strong>{name}</strong> says <blockquote>{comment}</blockquote>"
                  values={{
                    name: c.division.name,
                    comment: c.value,
                    strong: chunks => <strong>{chunks}</strong>,
                    blockquote: chunks => <blockquote>{chunks}</blockquote>,
                  }}
                />
              )}
            </div>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default () => {
  const [trade] = useSharedTrade();
  const [history, setHistory] = useState([]);
  const isSeller = R.path(['offer', 'is_owner'], trade);
  useEffect(() => void setHistory(createHistory(trade)), [trade]);
  return (
    <div className="content">
      <h3>
        <FormattedMessage
          id="viewtrade-actionhistory-header"
          description="Header for Action history in View trade"
          defaultMessage="Action history"
        />
      </h3>
      {history.length ? (
        <ul className="action-history">
          {history.map((entries, index) => (
            <HistoryObjects entries={entries} key={index} trade={trade} />
          ))}
        </ul>
      ) : (
        <Alert hasicon>
          <p className="smaller">
            {isSeller ? (
              <FormattedMessage
                id="viewtrade-actionhistory-helptext-seller"
                description="helptext for Action history in View trade for seller"
                defaultMessage="A history of all post trade actions will appear here. Please contact the buyer and provide all required information in order to complete the trade."
              />
            ) : (
              <FormattedMessage
                id="viewtrade-actionhistory-helptext-buyer"
                description="helptext for Action history in View trade for buyer"
                defaultMessage="A history of all post trade actions will appear here. Please contact the seller and provide all required information in order to complete the trade."
              />
            )}
          </p>
        </Alert>
      )}
    </div>
  );
};
