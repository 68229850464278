import * as R from 'ramda';

export type TreeNodeKey = string;

type NodeFunction<C, R> = (context: C) => R;

export type MakeTreeNode<T, C> = NodeFunction<C, T | T[] | null>;

export type KeyedMakeTreeNode<T, C, K extends TreeNodeKey> = NodeFunction<
  C,
  T | T[] | null
> & { readonly key: K };

export type TreeNodeMaker<T, C> =
  | MakeTreeNode<T, C>
  | {
      // Make a node.
      make: MakeTreeNode<T, C>;
      // Same parameters as make(); return value indicates if node should be included.
      predicate?: NodeFunction<C, boolean>;
    };

type Entry<
  T,
  C,
  K extends D[number]['key'] & TreeNodeKey,
  D extends Readonly<Array<KeyedMakeTreeNode<T, C, TreeNodeKey>>>
> = K | [K, readonly K[]];

type NodeFactoryConfig<T, K extends TreeNodeKey, C> = Readonly<{
  transform: (context: C, node: T, key: K, children?: T[]) => T[];
}>;

// Wraps a Make always returning an array.
const arrayOnly =
  <T, C>(make: MakeTreeNode<T, C>) =>
  (context: C): T[] => {
    const node = make(context);
    if (node !== null) {
      if (Array.isArray(node)) return node;
      return [node];
    }
    return [];
  };

// Wraps a Make with a transform callback.
const transformed =
  <T, C, K extends TreeNodeKey>(
    key: K,
    make: MakeTreeNode<T, C>,
    transform: NodeFactoryConfig<T, K, C>['transform']
  ) =>
  (context: C, children: T[]): T[] =>
    arrayOnly(make)(context).flatMap(node =>
      transform(context, node, key, children)
    );

export function treeFactory<T, C>() {
  function defineNode<K extends TreeNodeKey>(
    key: K,
    maker: TreeNodeMaker<T, C>
  ) {
    function wrap(): MakeTreeNode<T, C> {
      // Simple maker function.
      if (typeof maker === 'function') return maker;
      const { predicate } = maker;
      const make = maker.make;
      // Maker with predicate.
      if (predicate)
        return context => {
          if (predicate(context) ? true : false) return make(context);
          return [];
        };
      // Without predicate.
      return make;
    }
    return Object.assign(wrap(), { key });
  }

  function mergeNodes<
    D extends Readonly<Array<KeyedMakeTreeNode<T, C, K>>>,
    K extends TreeNodeKey
  >(...nodes: D) {
    return nodes.reduce((acc, node) => {
      acc[node.key] = node;
      return acc;
    }, {} as Partial<Record<K, MakeTreeNode<T, C>>>) as Readonly<
      Record<K, MakeTreeNode<T, C>>
    >;
  }

  // Define and create TreeFactory.
  function defineFactory<
    D extends Readonly<Array<KeyedMakeTreeNode<T, C, TreeNodeKey>>>,
    K extends D[number]['key'] & TreeNodeKey
  >(def: D, { transform }: NodeFactoryConfig<T, K, C>) {
    const keyedMake = mergeNodes(...def);
    const make = (key: K) =>
      transform
        ? transformed(key, keyedMake[key], transform)
        : arrayOnly(keyedMake[key]);
    return {
      get keys() {
        return R.keys(keyedMake);
      },
      make(context: C, tree: Entry<T, C, K, D>[]): T[] {
        const mapper = (entry: Entry<T, C, K, D>): T[] => {
          function run(): T[] {
            if (Array.isArray(entry)) {
              const [key, childKeys] = entry;
              return make(key)(context, childKeys.flatMap(mapper));
            }
            return make(entry)(context, []);
          }
          return run();
        };
        return tree.flatMap(mapper);
      },
    } as const;
  }
  return {
    // Define single node.
    defineNode,
    // Create factory based on defined nodes.
    defineFactory,
  } as const;
}
