import React, { useEffect, useState } from 'react';
import { LoadingState } from 'Components/Layout';
import { ShowResult } from 'Components/Result';
import { useStoreState } from 'easy-peasy';
import { promisify } from 'es6-promisify';
import * as R from 'ramda';
import { useAsync } from 'react-use';
import { useFormWrapper } from './utils';
import { api } from '~/api';
import { Button, Form } from 'Components/nui';
import LocationAlerts from './LocationAlerts';
import { submitError, submitSuccess } from 'Components/form/utils';
import { FormattedMessage } from 'react-intl';

function NForm({ form, order, product, schema, submitState, setCurCur }) {
  const solution = useStoreState(state => state.auth.solution);
  const [price, setPrice] = useState(order.price.val);

  const props = {
    form,
    order,
    product,
    schema,
    solution,
    price,
    setPrice,
  };

  const formWrapper = useFormWrapper(props);
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);

  async function handleSubmit(e) {
    e.preventDefault();
    // Client-side validation
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }
    submitState.setLoading(true);
    const data = {
      ...formWrapper.serialize(),
      'fok-order-id': order.id,
    };
    try {
      const result = await api.getData(
        { type: 'orders', getResult: x => x },
        null,
        { method: 'post', data }
      );

      submitState.setResult(result);
    } catch (err) {
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        err
      );
      if (errors) {
        formWrapper.setErrors(errors);
      } else {
        submitState.setResult(err);
      }
    }
    submitState.setLoading(false);
  }

  const currency = formWrapper.form.getFieldValue('currency');

  useEffect(() => {
    if (!currency) return;
    setCurCur(currency);
  }, [currency]);

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <LocationAlerts
          form={form}
          order={order}
          price={price}
          setPrice={setPrice}
          schema={schema}
        />
        {formWrapper.render()}
        <Form.Item>
          <Button
            className={order.type === 'bid' ? 'offer' : 'bid'}
            htmlType="submit"
            loading={submitState.loading}
          >
            {order.type === 'bid' ? (
              <FormattedMessage
                id="marketrow-fok-button-sell"
                description="button for fok 'Sell' in Marketrow"
                defaultMessage="Sell"
              />
            ) : (
              <FormattedMessage
                id="marketrow-fok-button-buy"
                description="button for fok 'Buy' in Marketrow"
                defaultMessage="Buy"
              />
            )}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

const WrappedForm = Form.create()(NForm);

export default ({ order, product, setCurCur }) => {
  const state = useAsync(
    () =>
      api.getData(
        { type: 'orders', id: 'neworder' },
        { 'fok-order-id': order.id }
      ),
    [order.id]
  );

  return (
    <ShowResult renderSuccess={submitSuccess} renderError={submitError}>
      {submitState => (
        <div className="wrapper">
          <div className="nui-form order-form">
            <LoadingState state={product ? state : null}>
              {schema => (
                <WrappedForm
                  key={`fok-form-${order.id}`}
                  order={order}
                  schema={schema}
                  product={product}
                  submitState={submitState}
                  setCurCur={setCurCur}
                />
              )}
            </LoadingState>
          </div>
        </div>
      )}
    </ShowResult>
  );
};
