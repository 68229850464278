import React, { useState, useRef } from 'react';
import { UsingSchema, useSchema, useCustomerRequest } from './EditContext';
import { FormWrapper } from 'Components/form';
import { mergeSchema } from 'Components/form/utils';
import { promisify } from 'es6-promisify';
import { Button, Alert, Form } from 'Components/nui';
import { toast } from 'react-toastify';
import * as R from 'ramda';
import { useStoreState } from '~/store';
import { NextStep } from 'Components/nui/Wizard';
import { Route, useRouteMatch, useHistory } from 'react-router-dom';
import { inArrayIf } from '~/utils';

export default () => {
  return (
    <div className="content-links-details">
      <UsingSchema schema="links">
        <LocationForm />
      </UsingSchema>
    </div>
  );
};

function useFormWrapper({ form, schema, customer }: any) {
  const solution = useStoreState(state => state.auth.solution);
  const { params } = useRouteMatch<{ linkId?: string }>();

  const { linkId = '' } = params;
  const link: any = R.find(R.propEq('id', linkId), customer.links || []) || {};

  const last = useRef('');

  if (linkId !== last.current) {
    last.current = linkId;
    form.setFieldsValue({ nocreditlimit: !!link.nocreditlimit });
  }
  const nocreditlimit = form.getFieldValue('nocreditlimit');

  const getChoices = R.pipe(
    R.propOr([], 'choices'),
    R.map(([value, label]) => ({ value, label, key: value }))
  );

  const fields = [
    {
      name: 'division',
      label: link.id ? 'Link with' : 'Apply settings for these divisions',
      type: link.id ? 'Select' : 'MultipleSelect',
      choices: getChoices,
      serializeField: (data: string | string[]) => ({
        division: Array.isArray(data) ? data : [data],
      }),
    },
    {
      name: 'paymentterm',
      label: 'Payment term',
      choices: getChoices,
    },
    {
      name: 'nocreditlimit',
      label: (
        <>
          Credit limit{' '}
          <span className="inline-block unit">({solution.currency})</span>
        </>
      ),
      required: true,
      choices: [
        { label: 'Yes', value: false, key: 'yes' },
        { label: 'No', value: true, key: 'no' },
      ],
      type: 'RadioSelect',
    },
    ...inArrayIf(!nocreditlimit, {
      label: null,
      name: 'creditamount',
      required: !nocreditlimit,
      initialValue: 100000,
    }),
    ...inArrayIf(!nocreditlimit, {
      label: 'Limit period',
      name: 'creditperiod',
      choices: getChoices,
      required: !nocreditlimit,
    }),
    {
      name: 'customerpremium',
      label: (
        <>
          Customer premium
          <span className="block unit">{solution.currency}</span>
        </>
      ),
    },
    {
      name: 'financepremium',
      label: (
        <>
          Finance premium
          <span className="block unit">{solution.currency}</span>
        </>
      ),
    },
  ];

  const initialData = {
    ...link,
    nocreditlimit: link.nocreditlimit || false,
    division: link.division?.id,
    paymentterm: link.paymentterm?.id,
    creditperiod: link.creditperiod?.id,
  };

  return new FormWrapper(form, initialData, mergeSchema(schema, fields));
}

const BaseForm = ({ form, schema }: any) => {
  const { customer, actions } = useCustomerRequest();
  const formWrapper = useFormWrapper({ form, schema, customer });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);
  const [loading, setLoading] = useState(false);
  const [last, setLast] = useState(false);
  const history = useHistory();

  async function handleSubmit(e: any) {
    e.preventDefault();

    setLoading(true);
    setLast(false);

    try {
      await validateFieldsAndScroll();
    } catch (err) {
      setLoading(false);
      return;
    }

    try {
      const { division, ...rest } = formWrapper.serialize();
      const existing = R.reject(
        (c: any) => R.includes(c.division.id, division as any[]),
        customer.links || []
      ).map(({ division, paymentterm, ...c }) => ({
        ...c,
        division: division?.id,
        paymentterm: paymentterm.id,
      }));

      const data = [
        ...existing,
        ...division.map((division: string) => ({ division, ...rest })),
      ];

      await actions.edit('links', data);
      form.resetFields();
      setLast(true);
      setLoading(false);
      history.push(`/customer-requests/${customer.id}/links`);
      toast('Divisions linked successfully', { type: 'success' });
    } catch (err) {
      if (err?.response && err.response?.status === 400) {
        const validation = err.response?.data?.errors || [];
        if (validation.length) {
          formWrapper.setErrors(validation[0]?.description || []);
        } else {
          // wtf
          toast('Form did not validate.', { type: 'error' });
          console.error('form did not validate', err);
        }
      } else {
        toast(
          'There was an error while submitting your form. Please try again later.',
          { type: 'error' }
        );
        console.error(err);
      }
      setLoading(false);
    }

    window.scrollTo(0, 0);
  }

  return (
    <div className="nui-form">
      <Form onSubmit={handleSubmit}>
        <div className="form-steps-container">
          {last && (
            <Alert hasicon type="success">
              Links updated successfully. You can add more, or{' '}
              <NextStep>continue</NextStep>.
            </Alert>
          )}
          {formWrapper.render()}
          <div className="sticky-btm button-set mt-20 trio trio-for-small">
            <Button
              type="primary"
              disabled={loading}
              loading={loading}
              htmlType="submit"
            >
              Save
            </Button>
            <NextStep />
          </div>
        </div>
      </Form>
    </div>
  );
};

type IWrappedForm = (props: any) => React.ReactElement;
const WrappedForm: IWrappedForm = Form.create()(BaseForm) as any;

const LocationForm = () => {
  const schema = useSchema('links');
  return (
    <Route path="/customer-requests/:id/links/:linkId?">
      <WrappedForm schema={schema} />
    </Route>
  );
};
