import { ModelApi } from '../ModelApi';
import { useModel, guardRequest } from '../useModel';
import { useStoreActions, useStoreState } from '~/store';
import { ParticipantData } from '../modelTypes';
import * as R from 'ramda';
import Participant from './Participant';
import { useEffect } from 'react';
import { WithId, IUseFilters } from '../types';
import { AxiosRequestConfig } from 'axios';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { allowRolesIf } from 'Components/Authorised';
import { stringFilter, arrayFilter, deserializeString } from '../utils';

export { Participant };

interface IParticipantApi {
  solution: WithId;
}

export class ParticipantApi extends ModelApi {
  solution: WithId;

  constructor({ solution }: IParticipantApi) {
    const url = '/participants';
    super({ url });

    this.solution = solution;
  }

  async get(config: AxiosRequestConfig = {}) {
    return super.get(
      R.mergeDeepLeft(config, { params: { solution: this.solution.id } })
    );
  }
}

interface Config {
  tag?: string;
  filterConfig?: Partial<IUseFilters<ParticipantData>>;
}

export function useParticipants({ tag = '', filterConfig }: Config = {}) {
  const solution = useStoreState(state => state.auth.solution);
  const solutionSettings = useStoreState(state => state.auth.solutionSettings);
  const getResult = R.pathOr<ParticipantData[]>([], ['data', 'participants']);
  const api = new ParticipantApi({ solution });

  const actions = useStoreActions(state => state.models.participants);
  const state = useStoreState(state => state.models.participants);

  const dismissParticipants = useStoreActions(
    state => state.auth.dismissParticipants
  );

  const hasParticipants = () =>
    R.path(['matchinglevel'], solutionSettings) === 'commodity';

  const participants = useModel<ParticipantData>({
    defaultTag: tag,
    getResult,
    actions,
    state,
    api,
    filterConfig,
  });

  return {
    ...participants,

    fetch() {
      return participants.fetch().map(p => new Participant(p));
    },

    loading(model?: WithId) {
      if (model) return state.isLoading(model);
      return participants.loading(tag);
    },

    async toggleAcceptance(model: Participant) {
      actions.setLoading({ model, value: true });
      const data = state.getModel(model);
      const docsapproved = !data.us.docsapproved;
      const mark = moment().utc();

      await guardRequest(async () => {
        const response = await api.put({
          model,
          data: {
            solution: solution.id,
            accept: docsapproved,
          },
        });

        if (response.status === 200) {
          const rdata = response.data;
          actions.add(rdata);
          actions.setLastModified({ model, value: mark });
          toast.success(
            `Successfully ${rdata.us.docsapproved ? 'added' : 'removed'} ${
              model.name
            } as a participant.`
          );
        }
      });

      actions.setLoading({ model, value: false });
    },

    async changePaymentTerm(value: string, model: Participant) {
      actions.setLoading({ model, value: true });
      const data = state.getModel(model);
      const mark = moment().utc();

      await guardRequest(async () => {
        const response = await api.put({
          model,
          data: {
            solution: solution.id,
            accept: data.us.docsapproved,
            paymentterm: value,
          },
        });

        if (response.status === 200) {
          const rdata = response.data;
          actions.add(rdata);
          actions.setLastModified({ model, value: mark });
          toast.success(
            `Successfully set ${R.path(
              ['selldata', 'paymentterm', 'desc'],
              rdata
            )} as a payment term for ${model.name}.`
          );
        }
      });

      actions.setLoading({ model, value: false });
    },

    async sendTradeRequest(model: Participant) {
      actions.setLoading({ model, value: true });
      const mark = moment().utc();

      await guardRequest(async () => {
        const response = await api.patch({
          model,
          data: {
            solution: solution.id,
            action: 'request',
          },
        });

        if (response.status === 200) {
          const rdata = response.data;
          actions.add(rdata);
          actions.setLastModified({ model, value: mark });
          toast.success(`Successfully sent trade request to ${model.name}.`);
        }
      });

      actions.setLoading({ model, value: false });
    },

    async dismiss() {
      actions.tags.setLoading({ tag, value: true });

      await guardRequest(async () => {
        const response = await api.patch({
          data: {
            solution: solution.id,
            action: 'dismiss',
          },
        });

        switch (response.status) {
          case 204:
            toast.success('Participants dismissed successfully');
            dismissParticipants();
            actions.tags.setLoading({ tag, value: false });
            break;
          default:
            break;
        }
      });
    },

    _acl: {
      view: allowRolesIf('guest', 'trader', 'manager')(hasParticipants),
      add: allowRolesIf('trader', 'manager')(hasParticipants),
      edit: allowRolesIf('trader', 'manager')(hasParticipants),
      delete: allowRolesIf('trader', 'manager')(hasParticipants),
    },
  };
}

export function useParticipantsTable() {
  const solution = useStoreState(state => state.auth.solution);
  const participants = useParticipants({
    tag: `tender-participants-${solution.id}`,
    filterConfig: {
      initial: {
        name: '',
        companyno: '',
        theiraccept: [0, 1],
        ouraccept: [0, 1],
        activatedon: undefined,
        dismissed: undefined,
        viewonly: undefined,
      },
      serialize: filters => {
        const name = stringFilter(filters.name);
        const companyno = stringFilter(filters.companyno);
        const theiraccept = arrayFilter('theiraccept', [0, 1], filters);
        const ouraccept = arrayFilter('ouraccept', [0, 1], filters);

        return { ...filters, name, companyno, theiraccept, ouraccept };
      },
      deserialize: filters => {
        const included = Object.keys(filters);

        const name = R.includes('name', included)
          ? deserializeString(filters.name[0])
          : '';

        const theiraccept = R.includes('theiraccept', included)
          ? [parseInt(filters.theiraccept[0])]
          : [0, 1];

        const ouraccept = R.includes('ouraccept', included)
          ? [parseInt(filters.ouraccept[0])]
          : [0, 1];

        return { ...filters, name, theiraccept, ouraccept };
      },
    },
  });

  useEffect(() => {
    participants.update();
  }, participants.shouldUpdate());

  return participants;
}
