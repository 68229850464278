import React from 'react';
import classnames from 'classnames';
import * as R from 'ramda';

const sizes = {
  default: '',
  small: 'nui-small',
};

export default React.forwardRef((props, ref) => {
  const {
    type,
    value,
    id,
    size,
    disabled,
    placeholder,
    className,
    onChange,
    onEnter,
  } = props;

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      onEnter && onEnter();
    }
  };

  return (
    <input
      className={classnames(R.pathOr(sizes.default, [size], sizes), className)}
      ref={ref}
      type={type || 'text'}
      placeholder={placeholder}
      value={value}
      id={id}
      disabled={disabled}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
});
