export const icons = [
  'arrow_drop_down',
  'check',
  'co2',
  'dashboard',
  'finance',
  'progress_activity',
  'settings',
  'unfold_less',
  'unfold_more',
  'visibility',
  'visibility_off',
] as const;

const Icon = ({
  name,
  className,
}: {
  name: typeof icons[number];
  className?: string;
}) => (
  <span
    className={`material-symbols-rounded${className ? ` ${className}` : ''}`}
  >
    {name}
  </span>
);

export default Icon;
