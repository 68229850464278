import type { WrappedFormUtils } from 'antd/lib/form/Form';
import moment from 'moment-timezone';
import { getStatus } from '~/store/bannerModel/utils';
import { IBanner } from '~/store/types';

type BannerFormData = Readonly<{
  action_text: string;
  action_url: string;
  style: string;
  subject: string;
  content: string;
  start: string;
  end: string;
}>;

const MINUTE_STEP = 5;

const fromDt = moment().minute(
  Math.ceil(moment().minute() / MINUTE_STEP) * MINUTE_STEP
);

export default function createFields<
  T extends Readonly<
    Array<{
      [key: string]: any;
      name: keyof BannerFormData;
    }>
  >
>(form: WrappedFormUtils<T>, initialData: IBanner | null) {
  const status = initialData ? getStatus(initialData) : null;
  const data =
    status === 'expired' ? { ...initialData, start: undefined } : initialData;
  return [
    {
      type: 'Input',
      name: 'subject',
      label: 'Heading',
    },
    {
      type: 'RichText',
      name: 'content',
      label: 'Message',
    },
    {
      type: 'Input',
      name: 'action_url',
      label: 'Link URL',
    },
    {
      type: 'Input',
      name: 'action_text',
      label: 'Link text',
    },
    {
      name: 'start',
      label: 'Start date',
      fromDate: fromDt,
      initialValue: data ? data.start : fromDt.clone(),
      props: {
        showTime: {
          format: 'h:mm a',
          minuteStep: MINUTE_STEP,
          hideDisabledOptions: true,
          defaultValue: fromDt.clone(),
        },
      },
      type: 'DateTime',
    },
    {
      name: 'end',
      label: 'End date',
      fromDate: () =>
        form
          .getFieldValue('start')
          ?.clone()
          .minute(form.getFieldValue('start').clone().minute() + 15) ||
        fromDt.clone().minute(fromDt.clone().minute() + 15),
      initialValue: fromDt.clone().minute(fromDt.clone().minute() + 15),
      props: {
        getCalendarContainer: null,
        showTime: {
          format: 'h:mm a',
          minuteStep: MINUTE_STEP,
          hideDisabledOptions: true,
          defaultValue: fromDt.clone().minute(fromDt.clone().minute() + 15),
        },
      },
      type: 'DateTime',
    },
  ];
}
