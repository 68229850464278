import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Table, Button, Modal, Loading } from 'Components/nui';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import * as R from 'ramda';
import { useScrollToTopOnMount, useBodyClassReverse } from '~/hooks.js';
import { api } from '~/api';
import { toast } from 'react-toastify';
import Header from './Header';
import Helmet from 'Components/Helmet';
import { Product } from '~/models';
import ProductFilters from 'Components/filters/ProductFilters';
import { FiltersProvider, useFilters } from 'Components/filters/context';
import filterProducts from 'Components/filters/filterProducts';

export default () => {
  const solution = useStoreState(state => state.auth.solution);
  return (
    <>
      <Helmet>
        <title>Products</title>
      </Helmet>
      <Header />
      <FiltersProvider store={`product-filters_${solution?.id}`}>
        <Body />
      </FiltersProvider>
    </>
  );
};

const Body = () => {
  useScrollToTopOnMount();
  useBodyClassReverse();

  const solution = useStoreState(state => state.auth.solution);
  const solutionSettings = useStoreState(state => state.auth.solutionSettings);
  const fetchProducts = useStoreActions(action => action.auth.fetchProducts);
  const [products, setProducts] = useState(null);

  const fetch = async () => {
    const data = await api.getData2('/products', { solution: solution.id });

    setProducts(
      R.sortBy(R.compose(R.toLower, R.prop('name')))(
        R.pathOr([], ['products'], data).map(p => new Product(p))
      )
    );
  };

  useEffect(() => {
    fetch();
  }, []);

  const canManageDivisions = useStoreState(
    state => state.auth.canManageDivisions
  );
  const canManageProductsDivisions = useStoreState(
    state => state.auth.canManageProductsDivisions
  );
  const canEdit =
    canManageProductsDivisions.length > 0 && canManageDivisions.length > 0;

  const [productToDelete, setProductToDelete] = useState();

  const deleteModal = product => {
    setProductToDelete(product);
  };

  const closeDeleteModal = () => setProductToDelete(null);

  const deleteProduct = async (id, name) => {
    closeDeleteModal();
    toast(`Pending product '${name}' deletion`, {
      type: 'info',
    });
    try {
      await api.deleteData(`/products/${id}`);
      fetch();
      toast(`Product ${name} is deleted`, {
        type: 'success',
      });
      fetchProducts();
    } catch (err) {
      toast('Something went wrong. Please try again later', {
        type: 'error',
      });
    }
  };

  const columns = [
    {
      title: 'Name',
      key: 'name',
      className: 'col-product shaded-right',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record, index) => (
        <div className="td-content">
          <Link to={`/products/${record.id}`}>{record.name}</Link>
        </div>
      ),
    },
    {
      title: 'Unit',
      key: 'unit',
      className: 'col-unit',
      render: (text, record, index) => (
        <div className="td-content nowrap">{record._desc}</div>
      ),
    },
    {
      title: 'Volume unit',
      key: 'volumeunit',
      className: 'col-volunit',
      render: (text, record, index) => (
        <div className="td-content">
          {R.path(['loadingunit', 'desc'], record)}
        </div>
      ),
    },
    {
      title: 'Created at',
      key: 'createdat',
      className: 'col-createdat',
      sorter: (a, b) => moment(a.created).unix() - moment(b.created).unix(),
      render: (text, record, index) => (
        <div className="td-content nowrap">
          {moment(record.created).format('DD MMMM YYYY')}
        </div>
      ),
    },
    {
      title: 'Company',
      key: 'company',
      className: 'column-nowrap',
      sorter: (a, b) => a.owner.name.localeCompare(b.owner.name),
      render: (text, record, index) => (
        <div className="td-content">{record.owner && record.owner.name}</div>
      ),
    },
  ];

  if (canEdit) {
    columns.push({
      title: '',
      key: 'edit',
      className: 'col-edit',
      render: (text, record, index) => {
        return record.owner &&
          R.find(R.propEq('id', record.owner.id), canManageDivisions) ? (
          <div className="td-content">
            <Link
              to={`/products/${record.id}/edit`}
              className="nui-button nui-button-icon"
              title="Edit product"
            >
              <span className="icon-pencil"></span>
            </Link>
          </div>
        ) : null;
      },
    });
    columns.push({
      title: '',
      key: 'delete',
      className: 'col-delete',
      render: (text, record, index) => {
        return record.owner &&
          R.find(R.propEq('id', record.owner.id), canManageDivisions) ? (
          <div className="td-content">
            <Button
              title="Delete product"
              type="reverse"
              icon="trash"
              onClick={() => deleteModal(record)}
            />
          </div>
        ) : null;
      },
    });
  }

  let filteredData = products;
  const { filters } = useFilters();
  filteredData = filterProducts(products, filters);

  return (
    <>
      {productToDelete && (
        <Modal size="small" close={closeDeleteModal}>
          <h2>Warning</h2>
          <p>
            Are you sure you want to delete{' '}
            <strong className="all-black">{productToDelete.name}</strong>? This
            can&apos;t be undone.
          </p>
          <hr />
          <div className="button-set">
            <Button
              type="primary"
              onClick={() =>
                deleteProduct(productToDelete.id, productToDelete.name)
              }
            >
              Yes, delete it
            </Button>
            <Button type="simple" onClick={closeDeleteModal}>
              Cancel
            </Button>
          </div>
        </Modal>
      )}

      {solutionSettings.matchinglevel === 'product' && (
        <ProductFilters data={products} />
      )}

      {products ? (
        <div className="full-page-content">
          <div className="generic-table light">
            <Table
              className={classNames('table-wrapper', 'th-sticky')}
              columns={products.length ? columns : undefined}
              pagination={{
                size: 'small',
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                pageSize: 10,
              }}
              data={filteredData}
              emptyText={
                <div className="nui-row full-page-messaging align-center mt-20 p-100">
                  <div className="icon-inbox bordered align-center">
                    There are currently no products to display
                  </div>
                </div>
              }
              rowKey={R.prop('id')}
            />
          </div>
        </div>
      ) : (
        <div className="nui-row full-page-messaging align-center mt-20 pt-100 p-100">
          {products === null ? (
            <Loading size="medium" />
          ) : (
            <div className="icon-inbox bordered align-center">
              There are currently no products to display
            </div>
          )}
        </div>
      )}
    </>
  );
};
