import React from 'react';
import * as R from 'ramda';
import { FormattedMessage } from 'react-intl';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const PaymentTermColumn = ColumnTree.defineNode('payment-term', {
  predicate({ solutionColumns }) {
    return 'paymentterm' in solutionColumns && solutionColumns.paymentterm
      ? true
      : false;
  },
  make() {
    return {
      title: (
        <FormattedMessage
          id="marketplace-table-heading-paymentterm"
          description="Payment term, repeated for cell headings"
          defaultMessage="Payment term"
        />
      ),
      children: [
        {
          title: '',
          key: 'paymentterm',
          className: 'col-paymentterm',
          render: (text, record, index) => (
            <div className="td-content">
              <span className="tiny-label">
                <FormattedMessage
                  id="marketplace-table-heading-paymentterm"
                  description="Payment term, repeated for cell headings"
                  defaultMessage="Payment term"
                />
              </span>
              {R.pathOr('', ['paymentterm', 'desc'], record)}
            </div>
          ),
        },
      ],
    };
  },
});
