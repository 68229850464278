import Base from '../Model';
import { TradeData } from '../modelTypes';
import { Trade as OldTrade } from '~/models';
import moment from 'moment-timezone';
import * as R from 'ramda';
import { Warehouse } from '../warehouses';
import { getDecimalCount } from '~/utils';

export default class Trade extends Base<TradeData> {
  old() {
    return new OldTrade(this.state);
  }

  get pid() {
    return this.state.pid;
  }
  get status() {
    return this.state.status;
  }
  get incoterms() {
    return this.state.incoterms;
  }
  get paymentterm() {
    return this.state.paymentterm;
  }
  get age() {
    return this.state.age;
  }
  get exchange() {
    return this.state.exchange;
  }
  get created() {
    return moment(this.state.created);
  }
  get paid() {
    return this.state.paid;
  }
  get isPrivate() {
    return this.state.isprivate;
  }

  get etd() {
    const fmt = 'DD MMM YYYY';
    return {
      from: moment(this.state.etd.from),
      to: moment(this.state.etd.to),
      format() {
        return `${this.from.format(fmt)} - ${this.to.format(fmt)}`;
      },
    };
  }
  get quarteretd() {
    return this.state.quarteretd;
  }

  get product() {
    return this.state.product;
  }
  get loading() {
    return this.state.loading;
  }
  get totaldeliveries() {
    return this.state.totaldeliveries;
  }
  get deliveryfrequency() {
    return this.state.deliveryfrequency;
  }
  get volume() {
    return this.state.volume;
  }
  get price() {
    return this.state.price;
  }
  get _price() {
    return this.state._price;
  }
  get commission() {
    return this.state.commission;
  }
  get value() {
    return this.state.value;
  }
  get size() {
    return this.state.size;
  }
  get group() {
    return this.state.group;
  }
  get index() {
    return this.state.index;
  }
  get indexdate() {
    return this.state.indexdate;
  }

  get productionmonth() {
    return this.state.productionmonth;
  }

  get otherParty() {
    return this.state.otherparty;
  }

  get buyer() {
    return this.state.buyer;
  }

  get seller() {
    return this.state.seller;
  }

  get type() {
    return this.state.type;
  }

  get partners() {
    return this.state.partners;
  }

  get offer() {
    return this.state.offer;
  }
  get bid() {
    return this.state.bid;
  }

  get fromaddr() {
    return R.pathOr([], ['state', 'fromaddr'], this).map(w => new Warehouse(w));
  }
  get toaddr() {
    return R.pathOr([], ['state', 'toaddr'], this).map(w => new Warehouse(w));
  }

  get route() {
    return {
      source: this.fromaddr[0],
      destination: this.toaddr[0],
    };
  }

  get attributes() {
    return this.state.attributes;
  }

  get exportdocs() {
    return this.state.exportdocs;
  }

  get exportable() {
    return this.state.exportable;
  }

  get exportcountry() {
    return this.state.exportcountry;
  }

  priceDecimalCount() {
    if (!this.price || !this.price.step) return 0;
    return getDecimalCount(this.price.step);
  }

  volumeDecimalCount() {
    if (!this.loading || !this.loading.qty) return 0;
    return getDecimalCount(this.loading.qty);
  }
}
