import React, { useContext, useState } from 'react';
import { Alert, Switch } from 'Components/nui';
import { useParams, Link } from 'react-router-dom';
import { ApiContext } from '~/context';
import { useAsync } from 'react-use';
import { LoadingState } from 'Components/Layout';
import { useStoreState } from 'easy-peasy';
import * as R from 'ramda';
import { api } from '~/api';
import classNames from 'classnames';
import { useSharedProduct } from '..';
import Helmet from 'Components/Helmet';
import { toast } from 'react-toastify';

const List = ({ ids }) => {
  const { id } = useParams();
  const [product, _] = useSharedProduct();
  const divisions = useStoreState(state => state.auth.solutionDivisions);

  const [selected, setSelected] = useState(ids);

  const division = R.find(
    R.propEq('id', R.path(['division', 'id'], product)),
    divisions
  );
  const warehouses = R.sortBy(R.compose(R.toLower, R.prop('description')))(
    R.propOr([], 'warehouses', division)
  );

  const undetermined =
    selected.length < warehouses.length && selected.length !== 0;

  const save = async data => {
    toast('Location preferences are saved', {
      type: 'success',
    });
    try {
      await api.getData({ type: 'products', id: `${id}/warehouses` }, null, {
        method: 'put',
        data: {
          warehouses: data.map(el => ({ id: el })),
        },
      });
    } catch (err) {
      // const errors = R.path(
      //   ['response', 'data', 'errors', 0, 'description'],
      //   err
      // );
    }
  };

  const onChange = (w_id, checked) => {
    const newData = checked
      ? R.append(w_id, selected)
      : R.without([w_id], selected);

    setSelected(newData);
    save(newData);
  };

  const onChangeAll = checked => {
    const newData = checked ? warehouses.map(item => item.id) : [];

    setSelected(newData);
    save(newData);
  };

  return (
    <ul className="item-list">
      <li>
        <label className="master-switch">
          <Switch
            className={classNames({ 'nui-switch-undetermined': undetermined })}
            checked={selected.length > 0}
            onChange={checked => onChangeAll(checked)}
          />
          <strong className="all-black">All locations</strong>
        </label>
      </li>
      {warehouses.map(w => (
        <li key={w.id}>
          <label>
            <Switch
              checked={selected.includes(w.id)}
              onChange={checked => onChange(w.id, checked)}
            />
            <dl className="list stacked no-ws">
              <dt>{w.description}</dt>
              <dd>
                {w.street && `${w.street}, `} {w.city && `${w.city}, `}
                {w.state}
              </dd>
              <dd>{w.country.name}</dd>
            </dl>
          </label>
        </li>
      ))}
    </ul>
  );
};

export default () => {
  const { id } = useParams();
  const api = useContext(ApiContext);
  const [product, _] = useSharedProduct();

  const state = useAsync(() => {
    return api.getData({
      type: 'products',
      id: `${id}/warehouses`,
      getResult: x => x.warehouses.map(i => i.id),
    });
  }, [api, id]);

  if (!product) return null;

  return (
    <>
      <Helmet>
        <title>Edit product {product.name} - Locations</title>
      </Helmet>
      <Alert hasicon className="mb-20">
        Select the locations where this product can be shipped from. You can
        create additional locations in the add location wizard. Existing
        products will be automatically assigned to any newly created locations.
      </Alert>
      <div className="switch-list">
        <LoadingState state={state}>{ids => <List ids={ids} />}</LoadingState>
      </div>
      <div className="sticky-btm">
        <Link
          className="button primary mb-10"
          to={`/products/${product.id}/edit/customers`}
        >
          Next step
        </Link>
      </div>
    </>
  );
};
