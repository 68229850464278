import type * as Models from '~/models/tenders';
import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { matchMany, routeUrl, withPreload } from '~/router';
import { capitalize, getColumnFieldLabel, SolutionColumns } from '~/utils';
import classnames from 'classnames';
import * as Data from 'Components/Data';
import Quickbid from './Quickbid';
import Access, { can } from 'Components/Access';
import DisplayPopout from 'Components/DisplayPopout';
import { FieldVisible } from 'Components/FieldVisibility';
import ActionList from 'Components/ActionList';
import CancelTender from './Cancel';
import { Tooltip, Button, Modal, Alert } from 'Components/nui';
import { useMountedState } from '~/hooks';
import * as service from '~/services/tenders';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { useStoreState } from '~/store';
import * as R from 'ramda';
import Documents from 'Components/Lists/Documents';
import { FormattedMessage } from 'react-intl';
import { ToggleFavourite } from 'Components/filters/Favourites';

type TenderProp = Record<'tender', Models.Tender>;
type OrderProp = Record<'order', Models.Order>;
type CounterProp = Record<'counter', Models.Counter>;
type ChildrenProp = Partial<Record<'children', React.ReactNode>>;
type ClassProp = Partial<Record<'className', string>>;

interface Props {
  children?: React.ReactNode;
  className?: string;
}

interface TenderId {
  tender: { id: string };
}

const stopPropagation = (e: React.MouseEvent) => {
  e.stopPropagation();
};

export const TenderOwned = ({ tender }: TenderProp) =>
  tender.owned ? (
    <Tooltip
      title={
        <span className="icon-tooltip">
          <span className="icon-info-circled" />
          <FormattedMessage
            id="tender-tooltip-my-tender"
            description="Tootltip for own tender"
            defaultMessage="This is your own tender"
          />
        </span>
      }
    >
      <span className="own-tender" />
    </Tooltip>
  ) : null;

export const OwnCounter = ({ counter }: CounterProp) =>
  counter.isowner ? (
    <span className="own-counter">
      <FormattedMessage
        id="tender-tooltip-my-counter"
        description="Tootltip for own tender counter offer/bid"
        defaultMessage="My {orderType, select, offer {offer} bid {bid} other {{orderType}}}"
        values={{ orderType: counter.ordertype }}
      />
    </span>
  ) : counter.companybid ? (
    <span className="company-counter">
      <FormattedMessage
        id="tender-tooltip-company-counter"
        description="Tootltip for  tender counter offer/bid"
        defaultMessage="Company {orderType, select, offer {offer} bid {bid} other {{orderType}}}"
        values={{ orderType: counter.ordertype }}
      />
    </span>
  ) : null;

export const IsAutobid = ({ counter }: CounterProp) =>
  counter.isAuto ? (
    <span className="my-autobid">
      <FormattedMessage
        id="tender-tooltip-auto-counter"
        description="Tootltip for auto counter offer/bid"
        defaultMessage="Auto {orderType, select, offer {offer} bid {bid} other {{orderType}}}"
        values={{ orderType: counter.ordertype }}
      />
    </span>
  ) : null;

export const TenderStatus = ({ tender }: TenderProp) => (
  <span className={classnames('tender-status-badge', tender.status)}>
    <FormattedMessage
      id="tender-status"
      description="Label for tender status"
      defaultMessage="{status, select, active {active} finished {finished} published {published} cancelled {cancelled} planning {planning} archived {archived} other {{status}}}"
      values={{ status: tender.status }}
    />
  </span>
);

type ITenderLink = TenderId & Props;
export const TenderLink = ({ tender, children, ...props }: ITenderLink) => (
  <Link to={routeUrl('tender-view', { tenderId: tender.id })} {...props}>
    {children}
  </Link>
);

const infoFmt = 'LL HH:mm';
const infoFmtShort = 'll HH:mm';

export const TenderInfo = ({
  tender,
  view,
}: TenderProp & { view?: string }) => {
  const showActivity = tender.owned && tender.isRunning();
  return (
    <>
      <div
        className={`tender2-details-list ${view === 'detail' ? 'detail' : ''}`}
      >
        <Data.List>
          {tender?.owner?.name && (
            <Data.Item
              title={
                <FormattedMessage
                  id="tender-info-field-owner"
                  description="Label for `Owner` field on tender info"
                  defaultMessage="Owner"
                />
              }
            >
              {tender.owner.name}
            </Data.Item>
          )}

          {view === 'detail' && (
            <>
              <Data.Item
                title={
                  <FormattedMessage
                    id="tender-info-field-date"
                    description="Label for `Date` field on tender info"
                    defaultMessage="Date"
                  />
                }
                className="item-date"
              >
                <Data.Datetime value={tender.start} fmt={infoFmtShort} /> -{' '}
                <Data.Datetime
                  value={tender.finish}
                  fmt={
                    moment(tender.finish).diff(tender.start, 'days') < 1
                      ? 'HH:mm'
                      : infoFmtShort
                  }
                />{' '}
                {!!tender.extramins && ` (+${tender.extramins} mins)`} (
                <Data.Duration value={tender.duration} />)
              </Data.Item>
              <Data.Item
                title={
                  <FormattedMessage
                    id="tender-info-field-method"
                    description="Label for `Method` field on tender info"
                    defaultMessage="Method"
                  />
                }
              >
                <Data.TenderMethod info={tender} />
              </Data.Item>
            </>
          )}
          {view !== 'detail' && (
            <>
              <Data.Item
                title={
                  <FormattedMessage
                    id="tender-info-field-start"
                    description="Label for `Start` field on tender info"
                    defaultMessage="Start"
                  />
                }
                className="item-start"
              >
                <Data.Datetime value={tender.start} fmt={infoFmt} />
              </Data.Item>
              <Data.Item
                title={
                  <FormattedMessage
                    id="tender-info-field-finish"
                    description="Label for `Finish` field on tender info"
                    defaultMessage="Finish"
                  />
                }
                className="item-finish"
              >
                <Data.Datetime value={tender.finish} fmt={infoFmt} />
                {!!tender.extramins && ` (+${tender.extramins} mins)`}
              </Data.Item>
              <Data.Item
                title={
                  <FormattedMessage
                    id="tender-info-field-method"
                    description="Label for `Method` field on tender info"
                    defaultMessage="Method"
                  />
                }
              >
                <Data.TenderMethod info={tender} />
              </Data.Item>
              <Data.Item
                title={
                  <FormattedMessage
                    id="tender-info-field-duration"
                    description="Label for `Duration` field on tender info"
                    defaultMessage="Duration"
                  />
                }
                className="item-duration"
              >
                <Data.Duration value={tender.duration} />
              </Data.Item>
            </>
          )}

          <Data.Item
            title={
              <FormattedMessage
                id="tender-info-field-orders"
                description="Label for `bids/offers` field on tender info"
                defaultMessage="{type, select, Bids {Bids} Offers {Offers} other {{type}}}"
                values={{ type: capitalize(tender.ordername.order) + 's' }}
              />
            }
          >
            {tender.orders.total}
          </Data.Item>
          {!!tender.participants && (
            <Data.Item
              title={
                <FormattedMessage
                  id="tender-info-field-participants"
                  description="Label for `Participants` field on tender info"
                  defaultMessage="Participants"
                />
              }
              className="item-participants"
            >
              {tender.participants.included.toFixed(0)}
            </Data.Item>
          )}
          {!!tender.files?.length && (
            <Data.Item
              title={
                <FormattedMessage
                  id="tender-info-field-documents"
                  description="Label for `Documents` field on tender info"
                  defaultMessage="Documents"
                />
              }
            >
              <Data.Files files={tender.files} />
            </Data.Item>
          )}
        </Data.List>

        {view === 'detail' && (
          <div className="tender2-action-box">
            <Switch>
              <Route
                exact
                path={matchMany(
                  'tender-overview',
                  'tender-history',
                  'tender-trades'
                )}
              >
                <Link
                  to={routeUrl('tender-view', { tenderId: tender.id })}
                  className="nui-button nui-primary nui-small pb-7 pt-7 inline-block"
                >
                  <FormattedMessage
                    id="tender-list-button-view"
                    description="Button to view tender on tender list"
                    defaultMessage="View <span>tender</span>"
                    values={{
                      span: chunks => (
                        <span className="show-for-small">{chunks}</span>
                      ),
                    }}
                  />
                </Link>
              </Route>
              <Route>
                <div className="tender-activity">
                  {showActivity && <TenderActivity />}
                </div>
              </Route>
            </Switch>
            <TenderActions tender={tender} />
          </div>
        )}
      </div>
    </>
  );
};

const actions = [
  'edit',
  'orders_add',
  'participants_edit',
  'publish',
  'notify',
  'finalise',
  'delete',
  'analyse',
];
export const TenderActions = ({ tender }: TenderProp) => {
  const solutionColumns = useStoreState(state => state.auth.solutionColumns);
  const visibilityEditable =
    tender.status === 'published' || tender.status === 'planning';
  return (
    <Access to={actions} model={tender}>
      <ActionList
        className="tender-actions button nui-button nui-secondary has-dd"
        title={
          <FormattedMessage
            id="tender-buttton-actions"
            description="Button for tender owner actions"
            defaultMessage="Actions"
          />
        }
      >
        <Access to="edit" model={tender}>
          <ActionList.Item>
            <Link
              to={routeUrl('tender-edit-details', {
                tenderId: tender.id,
                mode: 'edit',
              })}
            >
              <FormattedMessage
                id="tender-buttton-actions-edit"
                description="Button for `edit` in tender owner actions dropdown"
                defaultMessage="Edit tender"
              />
            </Link>
          </ActionList.Item>
        </Access>
        <Access to="orders_add" model={tender}>
          <ActionList.Item>
            <Link
              to={routeUrl('tender-edit-orders', {
                tenderId: tender.id,
                mode: 'edit',
              })}
            >
              <FormattedMessage
                id="tender-buttton-actions-add-order"
                description="Button for `add offer/bid` in tender owner actions dropdown"
                defaultMessage="Add {orderType, select, offer {offer} bid {bid} other {{orderType}}}(s)"
                values={{ orderType: tender.ordername.order }}
              />
            </Link>
          </ActionList.Item>
        </Access>
        <Access to="participants_edit" model={tender}>
          <ActionList.Item>
            <Link
              to={routeUrl('tender-edit-participants', {
                tenderId: tender.id,
                mode: 'edit',
              })}
            >
              <FormattedMessage
                id="tender-buttton-actions-edit-participants"
                description="Button for `edit participants` in tender owner actions dropdown"
                defaultMessage="Edit participants"
              />
            </Link>
          </ActionList.Item>
        </Access>
        {!R.isNil(solutionColumns?.tendervisible) && visibilityEditable && (
          <Access to={['publish', 'notify']} model={tender}>
            <ActionList.Item>
              <Link
                to={routeUrl('tender-edit-review', {
                  tenderId: tender.id,
                  mode: 'edit',
                })}
              >
                <FormattedMessage
                  id="tender-buttton-actions-edit-visibility"
                  description="Button for `edit visibility` in tender owner actions dropdown"
                  defaultMessage="Edit visibility"
                />
              </Link>
            </ActionList.Item>
          </Access>
        )}

        <Access to={['publish', 'notify']} model={tender}>
          <ActionList.Item>
            <Link
              to={routeUrl('tender-edit-review', {
                tenderId: tender.id,
                mode: 'edit',
              })}
            >
              {tender.status === 'planning' ? (
                <FormattedMessage
                  id="tender-buttton-actions-publish"
                  description="Button for `Publish tender` in tender owner actions dropdown"
                  defaultMessage="Publish tender"
                />
              ) : (
                <FormattedMessage
                  id="tender-buttton-actions-edit-notify"
                  description="Button for `Notify participants` in tender owner actions dropdown"
                  defaultMessage="Notify participants"
                />
              )}
            </Link>
          </ActionList.Item>
        </Access>
        <Access to="finalise" model={tender}>
          <ActionList.Item className="tender-action-finalise">
            <Link to={routeUrl('tender-trades', { tenderId: tender.id })}>
              <FormattedMessage
                id="tender-buttton-actions-finalise"
                description="Button for `finalise tender` in tender owner actions dropdown"
                defaultMessage="Finalise tender"
              />
            </Link>
          </ActionList.Item>
        </Access>
        <Access to="delete" model={tender}>
          <ActionList.Item>
            <CancelTender tender={tender} />
          </ActionList.Item>
        </Access>
        <Access to="analyse" model={tender}>
          <ActionList.Item className="tender-action-analyse">
            <Link to={routeUrl('tender-report', { tenderId: tender.id })}>
              <FormattedMessage
                id="tender-buttton-actions-statistics"
                description="Button for `View statistics` in tender owner actions dropdown"
                defaultMessage="View statistics"
              />
            </Link>
          </ActionList.Item>
        </Access>
      </ActionList>
    </Access>
  );
};

export const TenderActivity = withPreload({
  route: 'tender-view',
  preload: service.activity.observe(),
  size: 'tiny',
})(({ data: users }) => {
  const num = users?.map(x => x).filter(u => u.status === 'active').length;

  return (
    <DisplayPopout
      title={
        <span>
          <span className="icon-group">
            {!!num && <span className="badge">{num}</span>}
          </span>
          <span className="title">
            <FormattedMessage
              id="tender-details-activity-label"
              description="Label for `Activity` in tender details page (tender owner only)"
              defaultMessage="Activity"
            />
            <span className="arrow"></span>
          </span>
        </span>
      }
    >
      <p className="p-0">
        <strong className="all-black">
          <FormattedMessage
            id="tender-details-activity-modal-header"
            description="Header for activity modal in tender details page (tender owner only)"
            defaultMessage="Participants' activity"
          />
        </strong>
      </p>
      <hr className="mt-5 mb-10" />
      {users && (
        <ul
          className={classnames('divisions', {
            'gt-20': users.length > 20,
            'gt-40': users.length > 40,
            'gt-60': users.length > 60,
            'gt-80': users.length > 80,
            'gt-100': users.length > 100,
          })}
        >
          {users
            .map(x => x)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(u => (
              <li
                key={u.id}
                title={
                  u.status === 'last-seen'
                    ? `Seen ${u.awayFor} ago`
                    : capitalize(u.status)
                }
              >
                {u.name}
                <span className={classnames('status', u.status)}>
                  {u.status === 'last-seen' && u.awayFor}
                </span>
              </li>
            ))}
        </ul>
      )}
    </DisplayPopout>
  );
});

type IOrderTicket = TenderProp & OrderProp;
export const OrderTicket = ({ tender, order }: IOrderTicket) => {
  const best = order.counters.best;
  const solutionColumns = useStoreState(state => state.auth.solutionColumns);

  return (
    <div className="tender-order-ticket">
      <div className="ticket-column ticket-order-details">
        <Data.List className="tender2-summary-fields">
          {order.productionmonth && (
            <Data.Item
              title={
                <FormattedMessage
                  id="tender-details-field-production-month"
                  description="Field for `Production month` in tender details page"
                  defaultMessage="Production month"
                />
              }
              className="item-productionmonth"
            >
              {order.productionmonth.display}
            </Data.Item>
          )}
          {order.futures?.length && (
            <Data.Item
              title={
                <FormattedMessage
                  id="tender-details-summary-field-futures"
                  description="Field for `Futures` in tender details page"
                  defaultMessage="Futures"
                />
              }
            >
              <Link onClick={e => void e.stopPropagation()} to="/futures">
                <FormattedMessage
                  id="tender-details-summary-field-futures-link"
                  description="Link for `Futures` in tender details page"
                  defaultMessage="View futures pricing"
                />
              </Link>
            </Data.Item>
          )}
        </Data.List>
        <Data.Attributes value={order.attributes} />
        <Data.List>
          <Data.Item>
            {tender.participating && best ? (
              <Data.PriceTicker
                title={
                  <FormattedMessage
                    id="tender-details-summary-field-best-price"
                    description="Field for `Best price` in summary in tender details page"
                    defaultMessage="Best price"
                  />
                }
                value={best.price}
              />
            ) : (
              <Data.PriceTicker
                title={
                  <FormattedMessage
                    id="tender-details-summary-field-start-price"
                    description="Field for `Start price` in summary in tender details page"
                    defaultMessage="Start price"
                  />
                }
                value={order.startprice}
                onZero={
                  <FormattedMessage
                    id="generic-any"
                    description="Text Any"
                    defaultMessage="Any"
                  />
                }
              />
            )}
          </Data.Item>
          {order.startprice.index && (
            <Data.Item>
              <Data.TickerAlt
                title={
                  <FormattedMessage
                    id="tender-details-summary-field-price-index"
                    description="Field for `Price index` in summary in tender details page"
                    defaultMessage="Price index"
                  />
                }
              >
                <span className="unit">
                  <Data.IndexSummary
                    index={order.startprice.index}
                    date={moment(order.startprice.indexdate)}
                  />
                </span>
              </Data.TickerAlt>
            </Data.Item>
          )}
          <Data.Item>
            <Data.VolumeTicker
              title={
                <FormattedMessage
                  id="tender-details-summary-field-total-volume"
                  description="Field for `Total volume` in summary in tender details page"
                  defaultMessage="Total volume"
                />
              }
              value={order.volume}
            />
          </Data.Item>
          {order.stripvolume && (
            <Data.Item>
              <Data.VolumeTicker
                title={
                  <FormattedMessage
                    id="tender-details-summary-field-delivery-volume"
                    description="Field for `Delivery volume` in summary in tender details page"
                    defaultMessage="Delivery volume"
                  />
                }
                value={order.stripvolume}
              />
            </Data.Item>
          )}
        </Data.List>
        <Data.List className="tender2-summary-fields">
          <FieldVisible name="loading">
            <Data.Item
              title={
                <FormattedMessage
                  id="tender-details-summary-field-loading-details"
                  description="Field for `Loading details` in summary in tender details page"
                  defaultMessage="Loading details"
                />
              }
            >
              <Data.LoadingDetail value={order.loading} />
            </Data.Item>
          </FieldVisible>
          <FieldVisible name="etd">
            <Data.Item
              title={
                <FormattedMessage
                  id="tender-details-summary-field-etd"
                  description="Field for `ETD` in summary in tender details page"
                  defaultMessage="ETD"
                />
              }
              className="item-etd"
            >
              <Data.ETD value={order.etd} />
            </Data.Item>
          </FieldVisible>
          {order.location && (
            <FieldVisible name="location">
              <Data.Item
                title={
                  <FormattedMessage
                    id="tender-details-summary-delivery"
                    description="Field for `delivery` in summary in tender details page"
                    defaultMessage="Delivery {delivery, select, from {from} to {to} other {{delivery}}}"
                    values={{ delivery: order.delivery }}
                  />
                }
              >
                <Data.ShortLocation value={order.location} />
              </Data.Item>
            </FieldVisible>
          )}
          {!!order.files?.length && (
            <FieldVisible name="attachments">
              <Data.Item
                title={
                  <FormattedMessage
                    id="tender-details-summary-field-attachments"
                    description="Field for `Attachments` in summary in tender details page"
                    defaultMessage="Attachments"
                  />
                }
                className="item-attachments"
              >
                <Data.Files files={order.files} />
              </Data.Item>
            </FieldVisible>
          )}
          {!R.isNil(order.exportable) && (
            <FieldVisible name="exportable">
              <Data.Item
                title={
                  getColumnFieldLabel(
                    order.ordertype,
                    'exportable'
                  )(solutionColumns as SolutionColumns) ?? (
                    <FormattedMessage
                      id="tender-details-summary-field-exportable"
                      description="Field for `Exportable` in summary in tender details page"
                      defaultMessage="Exportable"
                    />
                  )
                }
                className="exportable-snippet"
              >
                <Data.Bool value={!!order.exportable} />
              </Data.Item>
            </FieldVisible>
          )}
          {order.comment && (
            <FieldVisible name="comment">
              <Data.Item
                title={
                  <FormattedMessage
                    id="tender-details-summary-field-comments"
                    description="Field for `Comments` in summary in tender details page"
                    defaultMessage="Comments"
                  />
                }
                className="comment-snippet"
              >
                <Data.Note value={order.comment} />
              </Data.Item>
            </FieldVisible>
          )}
        </Data.List>
      </div>
      <div className="ticket-column ticket-counter-details">
        <div className="counter-details">
          {tender.participating ? (
            <CounterSummary tender={tender} order={order} />
          ) : best ? (
            <>
              <Data.PriceTicker
                title={
                  <FormattedMessage
                    id="tender-details-summary-field-best-price"
                    description="Field for `Best price` in summary in tender details page"
                    defaultMessage="Best price"
                  />
                }
                value={best.price}
              />
              <Data.VolumeTicker value={best.stripvolume ?? best.volume} />
              {!order.startprice.index && (
                <Data.PriceTicker
                  title={
                    <FormattedMessage
                      id="tender-details-summary-field-total-value"
                      description="Field for `Total value` in summary in tender details page"
                      defaultMessage="Total value"
                    />
                  }
                  className="total"
                  value={best.askingValue}
                />
              )}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

type ICounterSummary = TenderProp & OrderProp;
const CounterSummary = ({ tender, order }: ICounterSummary) => {
  const mine = order.counters.mine;

  return (
    <>
      {!!mine && (
        <>
          <Data.PriceTicker value={mine.price} />
          <Data.VolumeTicker value={mine.stripvolume ?? mine.volume} />
          {!order.startprice.index && (
            <Data.PriceTicker
              title={
                <FormattedMessage
                  id="tender-details-summary-field-total-value"
                  description="Field for `Total value` in summary in tender details page"
                  defaultMessage="Total value"
                />
              }
              className="total"
              value={mine.askingValue}
            />
          )}
          {mine.hasAuto && (
            <Data.PriceTicker
              title={
                <FormattedMessage
                  id="tender-details-summary-field-auto-counter"
                  description="Field for `auto bid/offer` in summary in tender details page"
                  defaultMessage="Auto {counterType, select, offer {offer} bid {bid} other{{counterType}}}"
                  values={{ counterType: tender.ordername.counter }}
                />
              }
              className={classnames('total', {
                index: !!order.startprice.index,
              })}
              value={mine.autobid}
            />
          )}
        </>
      )}
      <CounterActions tender={tender} order={order} />
    </>
  );
};

type ICounterRanking = TenderProp &
  OrderProp & { detail: boolean; children?: React.ReactNode };
export const CounterRanking = ({
  tender,
  order,
  detail,
  children,
}: ICounterRanking) => {
  const mine = order.counters.mine;

  const plural = order.counters.length !== 1 ? 's' : '';

  const blind = mine?.rank === 0;
  const leading = mine?.rank === 1;
  const losing = mine?.rank > 1;
  const none = !order.counters.length;
  const autobid = mine && !tender.started();

  const text = mine ? (
    autobid ? (
      <FormattedMessage
        id="tender-details-summary-counter-heading-autobid"
        description="Heading for counter summary in tender details page"
        defaultMessage="Auto {counterType, select, offer {offer} bid {bid} other{{counterType}}} placed"
        values={{ counterType: tender.ordername.counter }}
      />
    ) : blind ? (
      <FormattedMessage
        id="tender-details-summary-counter-heading-blind"
        description="Heading for counter summary in tender details page"
        defaultMessage="Blind tender"
      />
    ) : (
      <FormattedMessage
        id="tender-details-summary-counter-heading-active"
        description="Heading for counter summary in tender details page"
        defaultMessage="Ranking"
      />
    )
  ) : none ? (
    <FormattedMessage
      id="tender-details-summary-counter-heading-none"
      description="Heading for counter summary in tender details page"
      defaultMessage="No {counterType, select, offer {offers} bid {bids} other{{counterType}}}"
      values={{ counterType: tender.ordername.counter }}
    />
  ) : (
    <>
      <FormattedMessage
        id="tender-details-summary-counter-heading-count"
        description="Heading for counter summary in tender details page"
        defaultMessage="{count} {counterType, select, offer {{count, plural, one{offer} other{offers}}} bid {{count, plural, one{bid} other{bids}}} other{}}"
        values={{
          counterType: tender.ordername.counter,
          count: order.counters.length,
        }}
      />
    </>
  );

  return (
    <div
      className={classnames('rank', {
        'num-counters': !mine,
        blind,
        leading,
        losing,
        none,
        autobid,
      })}
    >
      {/* detail view, or if not owner, and there is no rank (user haven't placed a bid), show bid count */}
      {(detail || (!tender.owned && !order.counters.mine?.rank)) && text}
      {children}
      {tender.started() && !!mine && <Data.Rank value={mine.rank} />}
    </div>
  );
};

type ICounterActions = TenderProp & OrderProp & { children: React.ReactNode };
export const CounterActions = ({
  tender,
  order,
  children,
}: ICounterActions) => {
  const mine = order.counters.mine;
  const editUrl = routeUrl('tender-counter', {
    tenderId: tender.id,
    orderId: order.id,
  });

  if (can('edit', mine) || can('add', order.counters)) {
    if (mine?.rank > 1)
      return (
        <Quickbid tender={tender} order={order}>
          {children}
        </Quickbid>
      );

    return (
      <Link
        className="add-counter-bid nui-button nui-reverse nui-beveled"
        to={editUrl}
        onClick={e => {
          stopPropagation(e);
          document.body.classList.add('detail-area-open');
        }}
      >
        <FormattedMessage
          id="tender-details-summary-button-actions"
          description="Buttons for summary in tender details page"
          defaultMessage="{mine, select, true {Edit } other{Place }}{started, select, true {auto} other{}}{counterType, select, offer {offer} bid {bid} other{{counterType}}}"
          values={{
            counterType: tender.ordertype.counter,
            mine: !!mine,
            started: !tender.started(),
          }}
        />
        {children}
      </Link>
    );
  }

  return <></>;
};

export const OrderDetails = ({ order, className }: OrderProp & ClassProp) => {
  const products = useStoreState(state => state.auth.products);
  const product = products.find(p => p.id === order.product.id);
  const productColumns = product?.columns;

  const solutionColumns = useStoreState(state => state.auth.solutionColumns);

  return (
    <div className={classnames('order-details-item', className)}>
      <Data.List className="tender-order-detail-2">
        <Data.Item
          className="order-created"
          title={
            <FormattedMessage
              id="tender-details-order-details-field-created"
              description="Field for `Created` in order details tab in tender details page"
              defaultMessage="Created"
            />
          }
        >
          <Data.Datetime value={order.created} />
        </Data.Item>
        <Data.Item className="order-price">
          <Data.PriceTicker
            title={
              <FormattedMessage
                id="tender-details-summary-field-start-price"
                description="Field for `Start price` in summary in tender details page"
                defaultMessage="Start price"
              />
            }
            value={order.startprice}
            onZero={
              <FormattedMessage
                id="generic-any"
                description="Text Any"
                defaultMessage="Any"
              />
            }
          />
        </Data.Item>
        {order.reserve.price.val && (
          <Data.Item className="reserve-price">
            <Data.PriceTicker
              value={order.reserve.price}
              title={
                <FormattedMessage
                  id="tender-details-order-details-field-reserve-price"
                  description="Field for `Reserve price` in order details tab in tender details page"
                  defaultMessage="Reserve price"
                />
              }
            />
          </Data.Item>
        )}
        <Data.Item className="order-volume">
          <Data.VolumeTicker
            title={
              <FormattedMessage
                id="tender-details-summary-field-total-volume"
                description="Field for `Total volume` in summary in tender details page"
                defaultMessage="Total volume"
              />
            }
            value={order.volume}
          />
        </Data.Item>
        {order.stripvolume && (
          <Data.Item className="order-volume">
            <Data.VolumeTicker
              title={
                <FormattedMessage
                  id="tender-details-summary-field-delivery-volume"
                  description="Field for `Delivery volume` in summary in tender details page"
                  defaultMessage="Delivery volume"
                />
              }
              value={order.stripvolume}
            />
          </Data.Item>
        )}
      </Data.List>

      <ResAlert reserve={order.reserve} />

      <Data.List className="tender-order-detail-3">
        {order.startprice.index && (
          <Data.Item
            title={
              <FormattedMessage
                id="tender-details-summary-field-price-index"
                description="Field for `Price index` in summary in tender details page"
                defaultMessage="Price index"
              />
            }
          >
            <Data.IndexSummary
              index={order.startprice.index}
              date={moment(order.startprice.indexdate)}
              className="font-inherit"
            />
          </Data.Item>
        )}
        {order.productionmonth && (
          <Data.Item
            title={
              <FormattedMessage
                id="tender-details-field-production-month"
                description="Field for `Production month` in tender details page"
                defaultMessage="Production month"
              />
            }
            className="item-productionmonth"
          >
            {order.productionmonth.display}
          </Data.Item>
        )}
        {!!order.files?.length && (
          <Data.Item
            className="attachments"
            title={
              <FormattedMessage
                id="tender-details-summary-field-attachments"
                description="Field for `Attachments` in summary in tender details page"
                defaultMessage="Attachments"
              />
            }
          >
            <Data.Files files={order.files} className="icon-file-pdf" />
          </Data.Item>
        )}
        <Data.Item
          className="etd"
          title={
            <FormattedMessage
              id="tender-details-summary-field-etd"
              description="Field for `ETD` in summary in tender details page"
              defaultMessage="ETD"
            />
          }
        >
          <Data.ETD value={order.etd} />
        </Data.Item>
        <Data.Item
          className="loading-details"
          title={
            <FormattedMessage
              id="tender-details-summary-field-loading-details"
              description="Field for `Loading details` in summary in tender details page"
              defaultMessage="Loading details"
            />
          }
        >
          <Data.LoadingDetail value={order.loading} />
        </Data.Item>
        {!!order.variants.length && (
          <Data.Item
            className="variants"
            title={
              <FormattedMessage
                id="tender-details-order-details-field-product-variants"
                description="Field for `Product variants` in order details tab in tender details page"
                defaultMessage="Product variants"
              />
            }
          >
            <Data.ProductVariants variants={order.variants} />
          </Data.Item>
        )}
        {order.exportable != null && (
          <Data.Item
            className="exportable"
            title={
              solutionColumns?.exportable?.label || (
                <FormattedMessage
                  id="tender-details-summary-field-exportable"
                  description="Field for `Exportable` in summary in tender details page"
                  defaultMessage="Exportable"
                />
              )
            }
          >
            <Data.Bool value={order.exportable} />
          </Data.Item>
        )}
        {order.freightavailable != null && (
          <Data.Item
            className="freightavailable"
            title={
              <FormattedMessage
                id="tender-details-order-details-field-additionalfreight-label"
                description="Field for `Additional freight` in order details tab in tender details page"
                defaultMessage="Additional freight"
              />
            }
          >
            <Data.Bool value={order.freightavailable} />
          </Data.Item>
        )}
        {!!order.origins?.length && (
          <Data.Item
            className="origins"
            title={
              <FormattedMessage
                id="tender-details-order-details-field-origin"
                description="Field for `Origin` in order details tab in tender details page"
                defaultMessage="Origin"
              />
            }
          >
            {order.origins.map(x => x.name).join(', ')}
          </Data.Item>
        )}
        {!!order.places?.length && (
          <Data.Item
            className="places"
            title={
              <FormattedMessage
                id="tender-details-order-details-field-location"
                description="Field for `Location` in order details tab in tender details page"
                defaultMessage="Location"
              />
            }
          >
            {order.places.map(x => x.name).join(', ')}
          </Data.Item>
        )}
        {!!order.incoterms?.length && (
          <Data.Item
            className="incoterms"
            title={
              <FormattedMessage
                id="tender-details-order-details-field-incoterm"
                description="Field for `Incoterm` in order details tab in tender details page"
                defaultMessage="Incoterm"
              />
            }
          >
            {order.incoterms.map(x => x.desc).join(', ')}
          </Data.Item>
        )}
        {!!order.docs &&
          Object.entries(order.docs).map(([doc, value]) => (
            <Data.Item
              key={`doc-${doc}`}
              title={
                getColumnFieldLabel(
                  order.ordertype,
                  doc
                )(productColumns ?? solutionColumns) ||
                (doc === 'gfsi' ? 'GFSI' : capitalize(doc)) + ' docs'
              }
            >
              <Data.Bool value={value} />
            </Data.Item>
          ))}
        {order.location && (
          <Data.Item
            className="delivery"
            title={
              <FormattedMessage
                id="tender-details-summary-delivery"
                description="Field for `delivery` in summary in tender details page"
                defaultMessage="Delivery {delivery, select, from {from} to {to} other {{delivery}}}"
                values={{ delivery: order.delivery }}
              />
            }
          >
            <Data.Location value={order.location} className="hide-label" />
          </Data.Item>
        )}
        {order.comment && (
          <Data.Item
            className="comments stacked"
            title={
              <FormattedMessage
                id="tender-details-summary-field-comments"
                description="Field for `Comments` in summary in tender details page"
                defaultMessage="Comments"
              />
            }
          >
            <Data.Note value={order.comment} />
          </Data.Item>
        )}
      </Data.List>
    </div>
  );
};

type ICounterDetails = CounterProp & { className?: string };
export const CounterDetails = ({ counter, className }: ICounterDetails) => {
  const solutionColumns = useStoreState(state => state.auth.solutionColumns);
  return (
    <Data.List className={classnames('counter-details-item', className)}>
      {!!counter.rank && (
        <Data.Item
          title={
            <FormattedMessage
              id="tender-details-summary-counter-heading-active"
              description="Heading for counter summary in tender details page"
              defaultMessage="Ranking"
            />
          }
          className="rank"
        >
          <Data.Rank value={counter.rank} />
        </Data.Item>
      )}
      {counter.updated ? (
        <Data.Item
          title={
            <FormattedMessage
              id="tender-details-counter-details-field-updated"
              description="Field for `Updated` in counter details in tender details page"
              defaultMessage="Updated"
            />
          }
          className="order-created"
        >
          <Data.Datetime value={counter.updated} />
        </Data.Item>
      ) : (
        !!counter.created && (
          <Data.Item
            title={
              <FormattedMessage
                id="tender-details-order-details-field-created"
                description="Field for `Created` in counter details tab in tender details page"
                defaultMessage="Created"
              />
            }
            className="order-created"
          >
            <Data.Datetime value={counter.created} />
          </Data.Item>
        )
      )}
      <Data.Item className="order-price">
        <Data.PriceTicker value={counter.price} />
      </Data.Item>
      {counter.displayedPrice && (
        <Data.Item className="order-price">
          <Data.PriceTicker
            title={
              <FormattedMessage
                id="tender-details-counter-details-field-displayed-price"
                description="Field for `Displayed price` in counter details tab in tender details page"
                defaultMessage="Displayed price"
              />
            }
            value={counter.displayedPrice}
          />
        </Data.Item>
      )}
      <Data.Item className="order-volume">
        <Data.VolumeTicker value={counter.volume} />
      </Data.Item>
      {counter.stripvolume && (
        <Data.Item className="order-volume">
          <Data.VolumeTicker value={counter.stripvolume} />
        </Data.Item>
      )}
      {counter.hasAuto && (
        <Data.Item
          className="order-value"
          title={
            <FormattedMessage
              id="tender-details-counter-details-field-autobid"
              description="Field for `Auto bid` in counter details tab in tender details page"
              defaultMessage="Auto bid"
            />
          }
        >
          <Data.PriceTicker title="" value={counter.autobid} />
        </Data.Item>
      )}
      {!counter.executedValue.index && (
        <Data.Item
          className="order-value"
          title={
            <FormattedMessage
              id="tender-details-counter-details-field-order-value"
              description="Field for `Bid/Offer value` in counter details tab in tender details page"
              defaultMessage="{orderType, select, bid{Bid} offer{Offer} other{{orderType}}} value"
              values={{ orderType: counter.ordertype }}
            />
          }
        >
          <Data.PriceTicker title="" value={counter.askingValue} />
        </Data.Item>
      )}
      {counter.price.index && (
        <Data.Item
          title={
            <FormattedMessage
              id="tender-details-summary-field-price-index"
              description="Field for `Price index` in summary in tender details page"
              defaultMessage="Price index"
            />
          }
        >
          <Data.IndexSummary
            index={counter.price.index}
            date={moment(counter.price.indexdate)}
            className="font-inherit"
          />
        </Data.Item>
      )}
      {counter.division && (
        <Data.Item
          title={
            <FormattedMessage
              id="tender-details-counter-details-field-division"
              description="Field for `Division` in counter details tab in tender details page"
              defaultMessage="Division"
            />
          }
          className="division"
        >
          {counter.division.name}
        </Data.Item>
      )}
      {counter.creator && (
        <Data.Item
          title={
            <FormattedMessage
              id="tender-details-counter-details-field-creator"
              description="Field for `Creator` in counter details tab in tender details page"
              defaultMessage="Creator"
            />
          }
          className="creator"
        >
          {counter.creator}
        </Data.Item>
      )}
      {counter.owner && counter.owner != counter.creator && (
        <Data.Item title="Owner" className="owner">
          {counter.owner}
        </Data.Item>
      )}
      {counter.variant && (
        <Data.Item
          title={
            <FormattedMessage
              id="tender-details-counter-details-field-variant"
              description="Field for `Variant` in counter details tab in tender details page"
              defaultMessage="Variant"
            />
          }
          className="variant"
        >
          <Data.ProductVariants
            variants={[
              {
                ...counter.variant,
                loading: counter.order.variants?.find(
                  x => x.id === counter.variant?.id
                )?.loading?.desc,
              },
            ]}
          />
        </Data.Item>
      )}
      {counter.fromaddr && (
        <Data.Item
          title={
            <FormattedMessage
              id="tender-details-counter-details-field-delivery-from"
              description="Field for `Delivery from` in counter details tab in tender details page"
              defaultMessage="Delivery from"
            />
          }
          className="delivery-from"
        >
          <Data.Location value={counter.fromaddr} />
        </Data.Item>
      )}
      {counter.toaddr && (
        <Data.Item
          title={
            <FormattedMessage
              id="tender-details-counter-details-field-delivery-to"
              description="Field for `Delivery to` in counter details tab in tender details page"
              defaultMessage="Delivery to"
            />
          }
          className="delivery-to"
        >
          <Data.Location value={counter.toaddr} />
        </Data.Item>
      )}
      {counter.incoterm && (
        <Data.Item
          title={
            <FormattedMessage
              id="tender-details-order-details-field-incoterm"
              description="Field for `Incoterm` in order details tab in tender details page"
              defaultMessage="Incoterm"
            />
          }
          className="incoterm"
        >
          {counter.incoterm}
        </Data.Item>
      )}
      {!R.isNil(counter.exportable) && (
        <Data.Item
          title={R.pathOr(
            <FormattedMessage
              id="tender-details-summary-field-exportable"
              description="Field for `Exportable` in summary in tender details page"
              defaultMessage="Exportable"
            />,
            [
              'exportable',
              counter.ordertype === 'bid' ? 'buy' : 'sell',
              'label',
            ],
            solutionColumns
          )}
          className="exportable"
        >
          <Data.Bool value={counter.exportable} />
        </Data.Item>
      )}
      {counter.exportdocs !== undefined && (
        <Data.Item
          title={R.pathOr(
            <FormattedMessage
              id="tender-details-order-details-field-export-docs"
              description="Field for `Export docs` in order details tab in tender details page"
              defaultMessage="Export docs"
            />,
            [
              'exportdocs',
              counter.ordertype === 'bid' ? 'buy' : 'sell',
              'label',
            ],
            solutionColumns
          )}
          className="exportdocs"
        >
          <Data.Required
            value={counter.exportdocs}
            notRequired={counter.ordertype === 'bid'}
          />
        </Data.Item>
      )}
      {counter.heattreatedpallets !== undefined && (
        <Data.Item
          title={
            <FormattedMessage
              id="tender-details-order-details-field-heat-treated-pallets"
              description="Field for `Heat-treated pallets required` in order details tab in tender details page"
              defaultMessage="Heat-treated pallets required"
            />
          }
          className="heattreatedpallets"
        >
          <Data.Required
            value={counter.heattreatedpallets}
            notRequired={counter.ordertype === 'bid'}
          />
        </Data.Item>
      )}
      {counter.exportcountry && (
        <Data.Item
          title={
            <FormattedMessage
              id="tender-details-order-details-field-destination"
              description="Field for `Destination` in order details tab in tender details page"
              defaultMessage="Destination"
            />
          }
          className="exportcountry"
        >
          {counter.exportcountry.name}
        </Data.Item>
      )}
      {counter.callofftime && (
        <Data.Item
          title={
            <FormattedMessage
              id="tender-details-order-details-field-call-off-time"
              description="Field for `Call off time` in order details tab in tender details page"
              defaultMessage="Call off time"
            />
          }
          className="callofftime"
        >
          {counter.callofftime}
        </Data.Item>
      )}
      {counter.comment && (
        <Data.Item
          title={
            <FormattedMessage
              id="tender-details-summary-field-comments"
              description="Field for `Comments` in summary in tender details page"
              defaultMessage="Comments"
            />
          }
          className="comments stacked"
        >
          <Data.Note value={counter.comment} />
        </Data.Item>
      )}
      {counter.trade && (
        <>
          <Data.Item
            title={
              <FormattedMessage
                id="tender-details-summary-field-trade"
                description="Field for `Trade` in summary in tender details page"
                defaultMessage="Trade"
              />
            }
            className="trade"
          >
            <Link to={`/trades/${counter.trade.id}`}>{counter.trade.pid}</Link>
          </Data.Item>
          <Data.Item
            title={
              <FormattedMessage
                id="tender-details-summary-field-trade-volume"
                description="Field for `Trade volume` in summary in tender details page"
                defaultMessage="Trade volume"
              />
            }
            className="trade-volume"
          >
            <Data.VolumeTicker title="" value={counter.executed} />
          </Data.Item>
          {!counter.executedValue.index && (
            <Data.Item
              title={
                <FormattedMessage
                  id="tender-details-summary-field-trade-value"
                  description="Field for `Trade value` in summary in tender details page"
                  defaultMessage="Trade value"
                />
              }
              className="trade-value"
            >
              <Data.PriceTicker title="" value={counter.executedValue} />
            </Data.Item>
          )}
        </>
      )}
      {counter.files && counter.files.length > 0 && (
        <Data.Item
          title={
            <FormattedMessage
              id="tender-details-summary-field-attachments"
              description="Field for `Attachments` in summary in tender details page"
              defaultMessage="Attachments"
            />
          }
        >
          <Data.Files files={counter.files} />
        </Data.Item>
      )}
    </Data.List>
  );
};

export const OrderActions = ({ tender, order }: OrderProp & TenderProp) => (
  <DisplayPopout className="order-actions">
    <ul>
      <Access to="edit" model={order}>
        <li>
          <Link
            onClick={stopPropagation}
            to={routeUrl('tender-edit-orders', {
              tenderId: tender.id,
              orderId: order.id,
              mode: 'edit',
            })}
            className="icon-pencil"
            title={'Edit ' + order.ordertype}
          >
            <FormattedMessage
              id="tender-details-order-button-edit"
              description="Button for `Edit offer/bid` in order details in tender details page"
              defaultMessage="Edit {orderType, select, offer {offer} bid {bid} other {{orderType}}}"
              values={{ orderType: order.ordertype }}
            />
          </Link>
        </li>
        <li>
          <Link
            onClick={stopPropagation}
            to={routeUrl('tender-duplicate-order', {
              tenderId: tender.id,
              orderId: order.id,
            })}
            className="icon-clone"
            title={'Duplicate ' + order.ordertype}
          >
            <FormattedMessage
              id="tender-details-order-button-duplicate"
              description="Button for `Duplicate offer/bid` in order details in tender details page"
              defaultMessage="Duplicate {orderType, select, offer {offer} bid {bid} other {{orderType}}}"
              values={{ orderType: order.ordertype }}
            />
          </Link>
        </li>
      </Access>
      <Access to="delete" model={order}>
        <li>
          <DeleteOrder tender={tender} order={order} className="icon-trash">
            <FormattedMessage
              id="tender-details-order-button-delete"
              description="Button for `Delete offer/bid` in order details in tender details page"
              defaultMessage="Delete {orderType, select, offer {offer} bid {bid} other {{orderType}}}"
              values={{ orderType: order.ordertype }}
            />
          </DeleteOrder>
        </li>
      </Access>
    </ul>
  </DisplayPopout>
);

type IDeleteOrder = TenderProp & OrderProp & ClassProp & ChildrenProp;
export const DeleteOrder = ({
  tender,
  order,
  className,
  children,
}: IDeleteOrder) => {
  const [visible, setVisible] = useMountedState(false);
  const [loading, setLoading] = useMountedState(false);

  const toggle = (v: boolean) => () => void setVisible(v);

  const onDelete = async () => {
    setLoading(true);

    const result = await service.actions.deleteOrder(tender.id, order.id);
    if (result?.success) {
      toast.success(
        <>
          {capitalize(order.ordertype)} for {order.product.name} removed
          successfully
        </>
      );
    } else {
      toast.error(
        <>
          An error occurred while removing {order.ordertype} for{' '}
          {order.product.name}
        </>
      );
    }

    setVisible(false);
    setLoading(false);
  };

  return (
    <>
      <Button
        type="buttonlink"
        className={classnames('cancel-order', className)}
        onClick={toggle(true)}
        loading={loading}
        disabled={loading}
      >
        {children}
      </Button>
      {visible && (
        <Modal close={toggle(false)} size="small">
          <h1>
            <FormattedMessage
              id="tender-details-delete-modal-heading"
              description="Heading for delete modal in tender details page"
              defaultMessage="Confirm"
            />
          </h1>
          <p>
            <FormattedMessage
              id="tender-details-delete-modal-text"
              description="Text for delete modal in tender details page"
              defaultMessage="Are you sure you want to delete this {orderType, select, offer {offer} bid {bid} other {{orderType}}} for <strong>{product}</strong>?"
              values={{
                strong: chunks => <strong>{chunks}</strong>,
                orderType: order.ordertype,
                product: order.product.name,
              }}
            />
          </p>
          <hr />
          <div className="button-set">
            <Button
              type="warning"
              onClick={onDelete}
              loading={loading}
              disabled={loading}
            >
              <FormattedMessage
                id="tender-details-delete-modal-button-delete"
                description="Button for delete in delete modal in tender details page"
                defaultMessage="Delete"
              />
            </Button>
            <Button type="simple" onClick={toggle(false)}>
              <FormattedMessage
                id="tender-details-delete-modal-button-close"
                description="Button for Close in delete modal in tender details page"
                defaultMessage="Close"
              />
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export const TenderCountdown = ({ tender }: TenderProp) => {
  const target = tender.target;
  const remaining = tender.isRunning()
    ? target!.diff(moment(), 'seconds')
    : 100;

  return (
    <span
      className={classnames('tender-timer', {
        'finish': tender.isRunning(),
        'start': tender.willStart(),
        's60-sec': remaining < 60,
        's30-sec': remaining < 30,
        's10-sec': remaining < 10,
        'no-value': !target,
        'icon-clock': !!target,
      })}
    >
      {target ? (
        <>
          <strong className="all-black">
            {tender.willStart() ? (
              <FormattedMessage
                id="tender-countdown-text-starting"
                description="Text for tender countdown"
                defaultMessage="Starts in"
              />
            ) : (
              <FormattedMessage
                id="tender-countdown-text-ending"
                description="Text for tender countdown"
                defaultMessage="Ends in"
              />
            )}
          </strong>{' '}
          <span className="nowrap" data-testid="tender-timer">
            <Data.Countdown target={target} />
          </span>
        </>
      ) : tender.status === 'planning' ? (
        <FormattedMessage
          id="tender-countdown-text-planning"
          description="Text for tender countdown"
          defaultMessage="Tender is not yet published"
        />
      ) : ['cancelled', 'stopped'].includes(tender.status) ? (
        <FormattedMessage
          id="tender-countdown-text-cancelled"
          description="Text for tender countdown"
          defaultMessage="Tender was cancelled"
        />
      ) : tender.finalised ? (
        <>
          <FormattedMessage
            id="tender-countdown-text-finalised"
            description="Text for tender countdown"
            defaultMessage="Finalised on"
          />{' '}
          <span data-testid="tender-timer">
            <Data.Datetime value={tender.finalised} />
          </span>
        </>
      ) : tender.ended() ? (
        <FormattedMessage
          id="tender-countdown-text-finished"
          description="Text for tender countdown"
          defaultMessage="Tender has finished"
        />
      ) : (
        <FormattedMessage
          id="tender-countdown-text-loading"
          description="Text for tender countdown"
          defaultMessage="Checking..."
        />
      )}
    </span>
  );
};

type IEditCounterButton = TenderProp & OrderProp;
export const EditCounterButton = ({ tender, order }: IEditCounterButton) => (
  <>
    {tender?.participating &&
      (order.counters.mine ? (
        <Access to="edit" model={order.counters.mine}>
          <Link
            to={routeUrl('tender-counter', {
              tenderId: tender.id,
              orderId: order.id,
            })}
            className="edit-counter nui-button nui-secondary mt-20"
          >
            <FormattedMessage
              id="tender-details-counter-form-button-edit"
              description="Button label for edit counter in tender details page"
              defaultMessage="Edit {orderType, select, offer{offer} bid{bid} other{{orderType}}}"
              values={{ orderType: tender.ordertype.counter }}
            />
          </Link>
        </Access>
      ) : (
        <Access to="add" model={order.counters}>
          <Link
            to={routeUrl('tender-counter', {
              tenderId: tender.id,
              orderId: order.id,
            })}
            className="edit-counter nui-button nui-secondary mt-20"
          >
            <FormattedMessage
              id="tender-details-counter-form-button-place"
              description="Button label for place counter in tender details page"
              defaultMessage="Place {tenderStarted, select, false{auto} other{}} {orderType, select, offer{offer} bid{bid} other{{orderType}}}"
              values={{
                orderType: tender.ordertype.counter,
                tenderStarted: tender.started(),
              }}
            />
          </Link>
        </Access>
      ))}
  </>
);

export const OrderIcons = ({
  order,
  tenderId,
}: OrderProp & { tenderId: string }) => {
  const solution = useStoreState(state => state.auth.solution);

  return (
    <div className="order-icons has-icons">
      {!!order.files?.length && (
        <Data.Icon
          icon="icon-attach-circled"
          className="is-bundle"
          tooltip={
            <FormattedMessage
              id="tender-details-icon-attachment"
              description="Icon tooltip for attatchment in tender details page"
              defaultMessage="This {orderType, select, offer{offer} bid{bid} other{{orderType}}} has {count} attachment(s)"
              values={{ orderType: order.ordertype, count: order.files.length }}
            />
          }
        />
      )}
      {order.exportable && (
        <Data.Icon
          icon="icon-ship"
          className="has-ship"
          tooltip={
            <>
              <strong className="all-black block">
                <FormattedMessage
                  id="tender-details-summary-field-exportable"
                  description="Field for `Exportable` in summary in tender details page"
                  defaultMessage="Exportable"
                />
              </strong>
              <span className="block">
                <FormattedMessage
                  id="tender-details-icon-exportable"
                  description="Icon tooltip for exportable in tender details page"
                  defaultMessage="This product is exportable."
                />
              </span>
              {!!solution?.exportprice?.price && (
                <span>
                  <FormattedMessage
                    id="tender-details-icon-exportable-price"
                    description="Icon tooltip for exportable in tender details page"
                    defaultMessage="Extra costs and terms may apply."
                  />
                </span>
              )}
            </>
          }
        />
      )}
      {order.comment && (
        <Data.Icon
          icon="icon-comment"
          className="has-comment"
          tooltip={order.comment}
        />
      )}
      {order.questionsCount > 0 && (
        <Tooltip
          title={
            <span className="icon-tooltip">
              <span className="icon-info-circled" />{' '}
              {
                <FormattedMessage
                  id="marketplace-legend-qa-text"
                  description="text for qa icon in marketplace legend in Marketplace"
                  defaultMessage="Includes questions from users"
                />
              }
            </span>
          }
        >
          <span className="mp-help-icon has-qa">Q&amp;A</span>
        </Tooltip>
      )}

      <ToggleFavourite
        tenderId={tenderId}
        offerId={order.id}
        active={order.favourite ?? false}
        hasSeparator={
          !!order.files?.length ||
          !!order.exportable ||
          !!order.comment ||
          order.questionsCount > 0
        }
      />
    </div>
  );
};

export const MyVariant = ({ order }: OrderProp) =>
  order.counters.mine?.variant && !order.counters.mine.variant.isBase ? (
    <span className="my-variant">{order.counters.mine.variant.name}</span>
  ) : null;

interface Reserve {
  reserve: {
    reserve_met: boolean | undefined;
  };
}

export const ResTag = ({ reserve }: Reserve) => (
  <div className="res-tag">
    {R.isNil(reserve.reserve_met) ? (
      <></>
    ) : (
      <span
        className={classnames(
          'inline-block',
          reserve.reserve_met ? 'res-icon' : 'icon-attention'
        )}
      >
        {reserve.reserve_met && 'RES'}
      </span>
    )}
  </div>
);

export const ResAlert = ({ reserve }: Reserve) => {
  return R.isNil(reserve.reserve_met) ? (
    <></>
  ) : reserve.reserve_met ? (
    <Alert className="mb-20" type="success" hasicon>
      <FormattedMessage
        id="tender-details-icon-reserve-price"
        description="Icon tooltip for reserve-price in tender details page"
        defaultMessage="Reserve price met"
      />
    </Alert>
  ) : (
    <Alert className="mb-20" type="warning" hasicon>
      <FormattedMessage
        id="tender-details-icon-reserve-price-not-met"
        description="Icon tooltip for reserve-price  in tender details page"
        defaultMessage="Reserve price not met"
      />
    </Alert>
  );
};

interface IRelist {
  order: { id: string; product: { id: string }; ordertype: 'bid' | 'offer' };
}
export const Relist = ({ order }: IRelist) => (
  <Tooltip
    title={
      <span className="icon-tooltip">
        <span className="icon-info-circled" />
        <FormattedMessage
          id="tender-details-icon-relist"
          description="Icon tooltip for reserve-price in tender details page"
          defaultMessage="Copy {orderType, select, offer {offer} bid {bid} other {{orderType}}} details and relist on Marketplace"
          values={{ orderType: order.ordertype }}
        />
      </span>
    }
  >
    <Link
      to={`/orders/${order.ordertype}?product=${order.product.id}&tender-order=${order.id}`}
      className="button primary"
    >
      <FormattedMessage
        id="tender-details-button-relist"
        description="Icon tooltip for reserve-price in tender details page"
        defaultMessage="Relist"
      />
    </Link>
  </Tooltip>
);
