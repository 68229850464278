import React from 'react';
import * as R from 'ramda';
import { Counter } from 'models.market';
import { Tooltip } from 'Components/nui';
import { pluralize } from '~/utils';

export const CounterSummaryTooltip = ({
  counters,
  children,
}: Record<'counters', Counter[]> & Record<'children', React.ReactNode>) => {
  const isActive = R.either(
    R.propEq('status', 'Working'),
    R.propEq('status', 'Oversubscribed')
  );
  const activeCounters = R.filter(isActive, counters);
  const declinedCounters = R.filter(R.propEq('status', 'Declined'), counters);
  const tradedCounters = R.filter(R.propEq('status', 'Traded'), counters);
  const cancelledCounters = R.filter(R.propEq('status', 'Cancelled'), counters);
  return (
    <Tooltip
      title={
        <span className="icon-tooltip">
          <span className="icon-info-circled" />
          This order has{' '}
          <ul className="counters-summary-list">
            {activeCounters.length ? (
              <li key="activeCounter">
                {activeCounters.length} active{' '}
                {pluralize(activeCounters.length, 'counter')}
              </li>
            ) : null}
            {declinedCounters.length ? (
              <li key="declinedCounter">
                {declinedCounters.length} declined{' '}
                {pluralize(declinedCounters.length, 'counter')}
              </li>
            ) : null}
            {tradedCounters.length ? (
              <li key="tradedCounter">
                {tradedCounters.length} traded{' '}
                {pluralize(tradedCounters.length, 'counter')}
              </li>
            ) : null}
            {cancelledCounters.length ? (
              <li key="cancelledCounter">
                {cancelledCounters.length} withdrawn{' '}
                {pluralize(cancelledCounters.length, 'counter')}
              </li>
            ) : null}
          </ul>
        </span>
      }
    >
      {children}
    </Tooltip>
  );
};
