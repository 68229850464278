import React from 'react';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Tooltip } from 'Components/nui';
import { specConst } from '~/utils';
import Icon from 'Components/Icon';

export const SpecButtons = ({ product }) => {
  const icons = R.uniq(
    product.specifications.map(item => R.path(['compliance', 'id'], item))
  ).filter(x => x);

  if (icons.length === 0) return null;

  return (
    <ul className="legend-list">
      {icons.map(id => (
        <li key={id}>
          <Tooltip
            title={
              <span className="icon-tooltip">
                <span className="icon-info-circled" />
                {specConst[id]?.tooltipText}
              </span>
            }
          >
            <Link
              to={`/products/${product.id}/specifications#${specConst[id]?.anchorText}`}
              className={classNames('legend', specConst[id]?.className)}
              onClick={e => e.stopPropagation()}
            >
              {id === 10005 ? <Icon name="co2" /> : specConst[id]?.symbol}
            </Link>
          </Tooltip>
        </li>
      ))}
    </ul>
  );
};
