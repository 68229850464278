const FilterChips = ({
  leading,
  trailing,
  children,
}: {
  leading?: React.ReactNode;
  children: React.ReactNode;
  trailing?: React.ReactNode;
}) => {
  return (
    <div className="filter-chips">
      <div className="flex-1 w-100">
        <div className="filter-chips-inner-left">
          {leading}

          <div className="filter-chips-chips">
            <div className="filter-chips-scroller">
              <div className="filter-chips-chips-wrap">{children}</div>
            </div>
          </div>
        </div>

        <div className="filter-chips-inner-right">{trailing}</div>
      </div>
    </div>
  );
};

export default FilterChips;
