import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { useStoreState } from 'easy-peasy';
import * as R from 'ramda';
import { useToggle, useClickAway, useMeasure } from 'react-use';
import { Authorised } from 'Components/Authorised';
import {
  usePartners,
  useCustomers,
  useParticipants,
  useWarehouses,
  useConsolidation,
  useDeliveries,
  CustomerRequestController,
} from '~/store/models';
import { Tooltip } from 'Components/nui';
import { getEnv } from '~/utils';
import { TendersLink } from './Tenders';
import { useLocale } from '~/lang';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { Popover } from 'antd';
import Icon from 'Components/Icon';

const MobileMenuLink = ({ exact, to, title, iconClass }) => (
  <li>
    <NavLink
      exact={exact}
      to={to}
      activeClassName="selected"
      className="box"
      title={title}
    >
      <span className={iconClass}>{title}</span>
    </NavLink>
  </li>
);

const MobileMenu = () => {
  const { marketplace, tender } = useStoreState(
    state => state.auth.solutionSettings
  );
  return (
    <div className="mbar mobile-nav">
      <ul>
        {marketplace && (
          <MobileMenuLink
            exact
            to="/"
            title="Marketplace"
            iconClass="icon-bank"
          />
        )}
        {tender && (
          <MobileMenuLink
            to="/tenders"
            title="Tenders"
            iconClass="icon-hammer"
          />
        )}
        <MobileMenuLink
          to="/orders"
          title="Orders"
          iconClass="icon-doc-text-inv"
        />
        <MobileMenuLink to="/trades" title="Trades" iconClass="icon-exchange" />
      </ul>
    </div>
  );
};

const SecondaryMenu = ({ smallMainNav, canCreateLiquidity }) => {
  const participants = useParticipants();
  const customers = useCustomers();
  const warehouses = useWarehouses();
  const partners = usePartners();
  const consolidation = useConsolidation();
  const deliveries = useDeliveries();
  const isSolutionOwner = useStoreState(state => state.auth.isSolutionOwner);
  const isdao = useStoreState(state => state.auth.isdao);
  const solution = useStoreState(state => state.auth.solution);
  const routeTypes = ['port', 'zone'];
  const hasRoutes =
    isSolutionOwner &&
    R.any(R.includes(R.__, routeTypes), [
      ...solution.freight_types,
      solution.freight,
    ]);

  const ref = useRef(null);
  const [open, toggle] = useToggle(false);
  useClickAway(ref, () => {
    toggle(false);
  });

  const SecondaryMenuLink = ({ to, title, tooltip }) => (
    <li>
      <NavLink
        to={to}
        activeClassName="selected"
        title={tooltip ?? title}
        onClick={toggle}
      >
        {title}
      </NavLink>
    </li>
  );

  return (
    <div ref={ref} className="secondary-holder">
      <Tooltip
        title={
          <span className="icon-tooltip">
            <span className="icon-info-circled"></span>
            <FormattedMessage
              id="navbar-tab-view-pages"
              description="View pages ellipsis option in navbar"
              defaultMessage="View pages"
            />
          </span>
        }
      >
        <button className="icon-ellipsis show-more" onClick={toggle} />
      </Tooltip>
      {open && (
        <nav className={classNames('secondary', 'open')}>
          <ul>
            {smallMainNav && (
              <>
                {canCreateLiquidity && (
                  <SecondaryMenuLink
                    to="/lp-overview"
                    tooltip="LP Overview"
                    title="LP Overview"
                  />
                )}
                <SecondaryMenuLink
                  to="/orders"
                  tooltip="Orders"
                  title={
                    <FormattedMessage
                      id="navbar-tab-orders"
                      description="Orders option in navbar"
                      defaultMessage="Orders"
                    />
                  }
                />
                <SecondaryMenuLink
                  to="/trades"
                  tooltip="Trades"
                  title={
                    <FormattedMessage
                      id="navbar-tab-trades"
                      description="Trades option in navbar"
                      defaultMessage="Trades"
                    />
                  }
                />
                <Authorised to="view" model={participants}>
                  <SecondaryMenuLink
                    to="/participants"
                    tooltip="Participants"
                    title={
                      <FormattedMessage
                        id="navbar-tab-participants"
                        description="Participants option in navbar"
                        defaultMessage="Participants"
                      />
                    }
                  />
                </Authorised>
                <Authorised to="view" model={consolidation}>
                  <SecondaryMenuLink
                    to="/consolidation"
                    tooltip="Consolidation"
                    title={
                      <FormattedMessage
                        id="navbar-tab-consolidation"
                        description="Consolidation option in navbar"
                        defaultMessage="Consolidation"
                      />
                    }
                  />
                </Authorised>
                <Authorised to="view" model={deliveries}>
                  <SecondaryMenuLink
                    to="/deliveries"
                    tooltip="Deliveries"
                    title={
                      <FormattedMessage
                        id="navbar-tab-deliveries"
                        description="Deliveries option in navbar"
                        defaultMessage="Deliveries"
                      />
                    }
                  />
                </Authorised>
              </>
            )}
            {hasRoutes && <SecondaryMenuLink to="/routes" title="Routes" />}
            <Authorised to="view" model={partners}>
              <SecondaryMenuLink
                to="/partners"
                tooltip="Partners"
                title={
                  <FormattedMessage
                    id="navbar-tab-partners"
                    description="Partners option in navbar"
                    defaultMessage="Partners"
                  />
                }
              />
            </Authorised>

            <SecondaryMenuLink
              to="/products"
              tooltip="Products"
              title={
                <FormattedMessage
                  id="navbar-tab-products"
                  description="Products option in navbar"
                  defaultMessage="Products"
                />
              }
            />
            <Authorised to="view" model={warehouses}>
              <SecondaryMenuLink
                to="/locations"
                tooltip="Locations"
                title={
                  <FormattedMessage
                    id="navbar-tab-locations"
                    description="Locations option in navbar"
                    defaultMessage="Locations"
                  />
                }
              />
            </Authorised>
            <Authorised to="view" model={customers}>
              <SecondaryMenuLink
                to="/customers"
                tooltip="Customers"
                title={
                  <FormattedMessage
                    id="navbar-tab-customers"
                    description="Customers option in navbar"
                    defaultMessage="Customers"
                  />
                }
              />
            </Authorised>
            <Authorised to="view" model={CustomerRequestController}>
              <SecondaryMenuLink
                to="/customer-requests"
                tooltip="Customer requests"
                title={
                  <FormattedMessage
                    id="navbar-tab-customer-request"
                    description="Customer request option in navbar"
                    defaultMessage="Customer requests"
                  />
                }
              />
            </Authorised>
            {(isSolutionOwner || isdao) && (
              <SecondaryMenuLink
                to="/reports"
                tooltip="Reports"
                title={
                  <FormattedMessage
                    id="navbar-tab-reports"
                    description="Reports option in navbar"
                    defaultMessage="Reports"
                  />
                }
              />
            )}
            <SecondaryMenuLink
              to="/users"
              tooltip="Users"
              title={
                <FormattedMessage
                  id="navbar-tab-users"
                  description="Users option in navbar"
                  defaultMessage="Users"
                />
              }
            />
          </ul>
        </nav>
      )}
    </div>
  );
};

const DashboardButton = () => {
  const solution = useStoreState(state => state.auth.solution);

  const dashboards = R.propOr([], 'dashboards', solution);

  if (dashboards.length === 0) return null;

  return (
    <Tooltip
      title={
        <span className="icon-tooltip">
          <span className="icon-info-circled" />
          <FormattedMessage
            id="navbar-tab-dashboard"
            description="Dashboard option in navbar"
            defaultMessage="Dashboards are configurable to aid your trade decision making process."
          />
        </span>
      }
    >
      <NavLink to="/dashboard">
        <Icon name="finance" />
      </NavLink>
    </Tooltip>
  );
};

const TradeButtons = () => {
  const solutionDivisions = useStoreState(
    state => state.auth.solutionDivisions
  );
  const restrictedAccess = solutionDivisions.some(s => s?.restrictedaccess);
  const tradeActions = useStoreState(state => state.auth.userTradeActions);
  const marketplace = useStoreState(
    state => state.auth.solutionSettings.marketplace
  );

  if (!marketplace || restrictedAccess) return <></>;

  return (
    <div className="bset button-set">
      {R.includes('buy', tradeActions) && (
        <Tooltip
          title={
            <span className="icon-tooltip">
              <span className="icon-info-circled"></span>
              <FormattedMessage
                id="navbar-button-placebid-tooltip"
                description="Tooltip for 'Place bid' button in Navbar"
                defaultMessage="Placing a bid means you are buying"
              />
            </span>
          }
        >
          <Link to="/orders/bid" role="button" className="button bid">
            <FormattedMessage
              id="navbar-button-placebid"
              description="Place bid button"
              defaultMessage="Place bid"
            />
          </Link>
        </Tooltip>
      )}
      {R.includes('sell', tradeActions) && (
        <Tooltip
          title={
            <span className="icon-tooltip">
              <span className="icon-info-circled" />
              <FormattedMessage
                id="navbar-button-placeoffer-tooltip"
                description="Tooltip for 'Place offer' button in Navbar"
                defaultMessage="Placing an offer means you are selling"
              />
            </span>
          }
        >
          <Link to="/orders/offer" role="button" className="button offer">
            <FormattedMessage
              id="navbar-button-placeoffer"
              description="Place offer button"
              defaultMessage="Place offer"
            />
          </Link>
        </Tooltip>
      )}
    </div>
  );
};

const Lang = () => {
  const ref = useRef();
  const [open, toggle] = useToggle(false);
  const locale = useLocale();

  useClickAway(ref, () => void toggle(false));

  const style = {
    backgroundColor: 'white',
  };

  const onClick = value => async () => {
    const { success } = await locale.change(value);

    if (success) window.location.reload();
    else {
      toast.error('Could not update language.');
    }
  };

  const isSelected = id => id === locale.current;

  return (
    <Popover
      content={
        <div className="lang-dropdown">
          <h4>
            <FormattedMessage
              id="navbar-language-picker-heading"
              description="Heading 'Change language' for Language picker"
              defaultMessage="Change language"
            />
          </h4>
          <ul>
            {locale.options.map(([id, name]) => (
              <li key={id}>
                <button
                  className="link-button"
                  disabled={isSelected(id)}
                  onClick={onClick(id)}
                >
                  {name}
                </button>
                {isSelected(id) && <Icon name="check" />}
              </li>
            ))}
          </ul>
        </div>
      }
      trigger="click"
      placement="bottomRight"
    >
      <button className="link-button lang-button">
        {locale.current.toUpperCase()}
        <Icon name="arrow_drop_down" />
      </button>
    </Popover>
  );
};

const HelpSection = forwardRef((_, ref) => (
  <div className="help-section config-links has-lang-switcher" ref={ref}>
    <div className="help-section-inner">
      <Tooltip
        title={
          <span className="icon-tooltip">
            <span className="icon-info-circled" />
            <FormattedMessage
              id="navbar-tab-portal"
              description="Portal option in navbar"
              defaultMessage="The Nui Portal, gives you a unique view of physical trade across our marketplaces, and oversight of upcoming tenders across all Nui platforms."
            />
          </span>
        }
      >
        <a
          href={
            import.meta.env.VITE_APP_PORTAL_URL ||
            'https://portal.nuimarkets.com'
          }
          target="_blank"
          rel="noreferrer"
          className="help-section-portal"
        >
          <Icon name="dashboard" />
        </a>
      </Tooltip>
      <DashboardButton />
      <div className="nav-separator"></div>
      <Tooltip
        title={
          <span className="icon-tooltip">
            <span className="icon-info-circled" />
            <FormattedMessage
              id="navbar-tab-settings"
              description="Settings option in navbar"
              defaultMessage="Settings"
            />
          </span>
        }
      >
        <Link to="/account/config/notifications" title="Settings">
          <Icon name="settings" />
        </Link>
      </Tooltip>
      <Lang />
    </div>
  </div>
));

const MainNav = ({ smallMainNav, canCreateLiquidity }) => {
  const domain = getEnv(import.meta.env.VITE_APP_OLD_DOMAIN);
  const user = useStoreState(state => state.auth.userModel);
  const isadmin = R.equals(
    'ea5befd8-958c-466e-96b5-a8513a0c416e',
    R.path(['company', 'id'], user)
  );

  const { marketplace, tender } = useStoreState(
    state => state.auth.solutionSettings
  );
  const totalOffers = useStoreState(state => state.market.totalOffers);
  const totalBids = useStoreState(state => state.market.totalBids);
  const participants = useParticipants();
  const consolidation = useConsolidation();
  const deliveries = useDeliveries();

  return (
    <nav className="main">
      <ul>
        {marketplace && (
          <li className="marketplace-link">
            <NavLink
              to="/"
              activeClassName="selected"
              className="icon-bank"
              title="Marketplace"
              isActive={(match, location) =>
                ['/', '/overview'].includes(location.pathname)
              }
            >
              <FormattedMessage
                id="navbar-tab-marketplace"
                description="Top level/navbar marketplace link"
                defaultMessage="Marketplace"
              />
            </NavLink>
            {(totalOffers > 0 || totalBids > 0) && (
              <span className="activity-status">
                {!!totalOffers && (
                  <>
                    <FormattedMessage
                      id="navbar-tab-marketplace-sub-offers"
                      description="Total {count} of offers"
                      defaultMessage="{count, plural, =1 {1 offer} other {# offers}}"
                      values={{ count: totalOffers }}
                    />
                    {!!totalBids && ' | '}
                  </>
                )}
                {!!totalBids && (
                  <FormattedMessage
                    id="navbar-tab-marketplace-sub-bids"
                    description="Total {count} of bids"
                    defaultMessage="{count, plural, =1 {1 bid} other {# bids}}"
                    values={{ count: totalBids }}
                  />
                )}
              </span>
            )}
          </li>
        )}

        {tender && <TendersLink />}

        {!smallMainNav && (
          <>
            {canCreateLiquidity && (
              <li className="navsmall">
                <NavLink
                  to="/lp-overview"
                  activeClassName="selected"
                  title="LP Overview"
                >
                  LP Overview
                </NavLink>
              </li>
            )}
            <li className="navsmall">
              <NavLink to="/orders" activeClassName="selected" title="Orders">
                <FormattedMessage
                  id="navbar-tab-orders"
                  description="Orders option in navbar"
                  defaultMessage="Orders"
                />
              </NavLink>
            </li>
            <li className="navsmall">
              <NavLink to="/trades" activeClassName="selected" title="Trades">
                <FormattedMessage
                  id="navbar-tab-trades"
                  description="Trades option in navbar"
                  defaultMessage="Trades"
                />
              </NavLink>
            </li>

            <Authorised to="view" model={participants}>
              <li className="navsmall">
                <NavLink
                  to="/participants"
                  activeClassName="selected"
                  title="Participants"
                >
                  <FormattedMessage
                    id="navbar-tab-participants"
                    description="Participants option in navbar"
                    defaultMessage="Participants"
                  />
                </NavLink>
              </li>
            </Authorised>

            <Authorised to="view" model={consolidation}>
              <li className="navsmall">
                <NavLink
                  to="/consolidation"
                  title="Consolidation"
                  activeClassName="selected"
                >
                  <FormattedMessage
                    id="navbar-tab-consolidation"
                    description="Consolidation option in navbar"
                    defaultMessage="Consolidation"
                  />
                </NavLink>
              </li>
            </Authorised>
            <Authorised to="view" model={deliveries}>
              <li className="navsmall">
                <NavLink
                  to="/deliveries"
                  title="Deliveries"
                  activeClassName="selected"
                >
                  <FormattedMessage
                    id="navbar-tab-deliveries"
                    description="Deliveries option in navbar"
                    defaultMessage="Deliveries"
                  />
                </NavLink>
              </li>
            </Authorised>
            {isadmin && (
              <li className="navsmall">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${domain}/signin`}
                  title="Admin"
                  className="admin"
                >
                  Admin
                </a>
              </li>
            )}
          </>
        )}
      </ul>
    </nav>
  );
};

const MainMenu = forwardRef(({ width }, ref) => {
  const navRef = useRef(null);
  const helpSectionRef = useRef(null);

  const [fullNavWidth, setFullNavWidth] = useState(null);
  const [smallMainNav, setSmallMainNav] = useState(false);

  useEffect(() => {
    // set full nav width - only if its greater in case browser loaded mobile first
    if (width > 0 && navRef.current?.clientWidth > fullNavWidth) {
      setFullNavWidth(navRef.current?.clientWidth);
    }
  }, [navRef.current, width]);

  useEffect(() => {
    const nav = navRef.current;
    const helpSection = helpSectionRef.current;

    // collapse to small nav if there's not enough space
    if (
      width - nav?.offsetLeft - fullNavWidth - 120 <
      helpSection?.clientWidth
    ) {
      setSmallMainNav(true);
    } else {
      setSmallMainNav(false);
    }
  }, [width, fullNavWidth]);

  const solutionDivisions = useStoreState(
    state => state.auth.solutionDivisions
  );

  const canCreateLiquidity = solutionDivisions.some(s => s.createliquidity);

  return (
    <div role="navigation" className="nbar navbar" ref={ref}>
      <TradeButtons />
      {width > 768 && (
        <div className="navigation" ref={navRef}>
          <MainNav
            smallMainNav={smallMainNav}
            canCreateLiquidity={canCreateLiquidity}
          />
          <SecondaryMenu
            smallMainNav={smallMainNav}
            canCreateLiquidity={canCreateLiquidity}
          />
        </div>
      )}
      <HelpSection ref={helpSectionRef} />
    </div>
  );
});

export default () => {
  const [containerRef, { width }] = useMeasure();
  return (
    <div className="sticky-nav" ref={containerRef}>
      {width <= 768 && <MobileMenu />}
      <MainMenu ref={containerRef} width={width} />
    </div>
  );
};
