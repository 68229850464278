import React, { useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Button, Input } from 'Components/nui';
import { useToggle } from 'react-use';
import * as R from 'ramda';
import { FormattedMessage } from 'react-intl';
import MarketFilters from 'Components/filters/MarketFilters';
import { Icon, Popover, Menu, Dropdown } from 'antd';
import { useFilters } from 'Components/filters/context';

const legacyToNew = data => {
  return {
    division: {
      items: data.fDivision ?? [],
    },
    product: {
      items: data.fProducts ?? [],
    },
    group: {
      items: data.fTags ?? [],
    },
    type: {
      items: data.fType ?? [],
    },
    etd: {
      items: data.fEtd,
    },
    etdOption: {
      items: data.fEtdOption,
    },
    updated: {
      items: data.fUpdated,
    },
    updatedOption: {
      items: data.fUpdatedOption,
    },
  };
};

const newToLegacy = data => {
  return {
    fDivision: data.division?.items,
    fProducts: data.product?.items,
    fTags: data.group?.items,
    fType: data.type?.items,
    fEtd: data.etd?.items,
    fEtdOption: data.etdOption?.items,
    fUpdated: data.updated?.items,
    fUpdatedOption: data.updatedOption?.items,
  };
};

export const PresetsList = () => {
  const solution = useStoreState(state => state.auth.solution);
  const prefs = useStoreState(state => state.auth.userPrefs);
  const presets = R.sortBy(
    R.prop('name'),
    R.pathOr([], ['marketplace', 'presets'], prefs)
  ).filter(item => solution && item.solution === solution.id);

  const deletePreset = useStoreActions(action => action.market.deletePreset);

  const { setFilters } = useFilters();

  const applyPreset = data => {
    setFilters(legacyToNew(data));
  };

  return (
    <>
      {presets.length > 0 && (
        <Dropdown
          placement="bottomRight"
          overlay={
            <Menu className="filter-preset-dropdown">
              {presets.map(item => (
                <Menu.Item key={item.id}>
                  <Button
                    type="buttonlink"
                    onClick={() => applyPreset(item.data)}
                  >
                    <span title={item.name}>{item.name}</span>
                  </Button>
                  <Button
                    type="buttonlink"
                    onClick={() => deletePreset(item.id)}
                    className="delete-button"
                  >
                    <span className="icon-trash" title="Delete preset"></span>
                  </Button>
                </Menu.Item>
              ))}
            </Menu>
          }
          trigger={['click']}
        >
          <Button type="buttonlink">
            <FormattedMessage
              id="marketplace-filter-presets-heading"
              description="Heading for 'Filter presets' in Marketplace filter"
              defaultMessage="Filter presets"
            />{' '}
            <Icon type="caret-down" />
          </Button>
        </Dropdown>
      )}
    </>
  );
};

export const PresetForm = () => {
  const savePreset = useStoreActions(action => action.market.savePreset);
  const solution = useStoreState(state => state.auth.solution);
  const { filters } = useFilters();

  const [on, toggle] = useToggle(false);
  const [name, setName] = useState('');

  const save = () => {
    setName('');
    toggle(false);
    savePreset({
      name,
      solution: solution.id,
      data: newToLegacy(filters),
    });
  };

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      visible={on}
      onVisibleChange={toggle}
      content={
        <div className="ant-input ant-input-wrap">
          <Input
            placeholder="Name for preset"
            className="nui-"
            value={name}
            onChange={e => setName(e.target.value)}
            ref={input => input && input.focus()}
            onEnter={() => name !== '' && save()}
          />
          <Button
            type="primary"
            onClick={save}
            disabled={name === ''}
            size="small"
          >
            <FormattedMessage
              id="marketplace-filter-presets-field-save-button"
              description="Button for 'Save presets' in Marketplace filter"
              defaultMessage="Save"
            />
          </Button>
        </div>
      }
    >
      <Button type="buttonlink" onClick={() => toggle(true)}>
        <FormattedMessage
          id="marketplace-filter-presets-field-save-label"
          description="label for 'Save presets' in Marketplace filter"
          defaultMessage="Save as a preset"
        />{' '}
        <Icon type="caret-down" />
      </Button>
    </Popover>
  );
};

export default () => {
  return <MarketFilters />;
};
