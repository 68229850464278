import type { TableProps } from 'rc-table/lib/Table';
import type { ExpandableTableProps } from 'rc-table/lib/ExpandableTable';
import type { ColumnType } from 'rc-table/lib/interface';
import React from 'react';
import Table from 'rc-table';
import classnames from 'classnames';
import RowToggle from 'Components/nui/RowToggle';
import Loading from './Loading';

const Cell = ({ divProps, children, ...props }: any) => (
  <td {...props}>
    <div className="td-content" {...divProps}>
      {children}
    </div>
  </td>
);
const Head = ({ divProps, children, ...props }: any) => (
  <th {...props}>
    <div className="th-content" {...divProps}>
      {children}
    </div>
  </th>
);

const components = {
  header: { cell: Head },
  body: { cell: Cell },
};

type DefaultValueType = Record<string, any>;
type Hax = 'columnManager' | 'store' | 'getRowKey' | 'columns';
type BaseProps<T> = Omit<TableProps<T>, Hax>;
interface ExpandableBaseProps<T> extends Omit<ExpandableTableProps<T>, Hax> {
  //
}
interface Props<T> extends BaseProps<T> {
  prefixCls?: undefined;
  expandable?: Partial<ExpandableBaseProps<T>>;
  columns?: any[];
}

export default function BaseTable<T>({ prefixCls, ...props }: Props<T>) {
  return (
    <Table
      className=""
      prefixCls="nui-table"
      expandRowByClick={true}
      expandIconAsCell={false}
      {...(props as any)}
    />
  );
}

export interface NuiColumnType<T extends DefaultValueType>
  extends ColumnType<T> {
  name?: string;
}
interface NuiProps<T extends DefaultValueType> extends Props<T> {
  columns?: NuiColumnType<T>[];
}
function NuiTable<T extends DefaultValueType>({
  className,
  columns = [],
  expandable = undefined,
  ...props
}: NuiProps<T>) {
  const classes = {
    'expand-table': !!expandable,
  };

  const expandProps: ExpandableBaseProps<T> = {
    expandIcon: ({ expanded, onExpand, record }) => (
      <RowToggle
        open={expanded}
        onClick={e => {
          onExpand(record, e);
        }}
      ></RowToggle>
    ),
    ...((expandable || {}) as ExpandableBaseProps<T>),
  };

  return (
    <div
      className={classnames(
        className,
        'nui-table nui-table2 table light',
        classes
      )}
    >
      <BaseTable
        components={components}
        columns={columns.map(({ name, className, ...item }) => ({
          key: name,
          className: classnames(`column-${name}`, className),
          ...item,
        }))}
        {...((expandable && expandProps) as any)}
        {...props}
      />
    </div>
  );
}

export function SimpleTable<T>({ className, ...props }: NuiProps<T>) {
  return (
    <NuiTable className={classnames(className, 'simple-table')} {...props} />
  );
}

export function GenericTable<T>({ className, ...props }: NuiProps<T>) {
  return props.loading ? (
    <Loading />
  ) : (
    <NuiTable className={classnames(className, 'generic-table')} {...props} />
  );
}
