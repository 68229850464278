import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import * as R from 'ramda';
import { areas } from '../references';
import Details from './Details';
import Loading from './Loading';
import Warehouses from './Warehouses';
import Customers from './Customers';
import Specifications from './Specifications';
import Attributes from './Attributes';

export default () => {
  const { id, area } = useParams();

  if (id && !area) return <Redirect to={`/products/${id}/edit/details`} />;

  return (
    <>
      <div className="container">
        <div className="title-block show-for-medium">
          <h2>
            {R.pathOr('Product details', [area, 'title'], areas)}
            <span>
              Step <strong>{R.pathOr('1', [area, 'step'], areas)}</strong> /{' '}
              <strong>6</strong>
            </span>
          </h2>
        </div>

        <div>
          {(area === 'details' || id === undefined) && <Details />}
          {area === 'specifications' && <Specifications />}
          {area === 'loading-details' && <Loading />}
          {area === 'attributes' && <Attributes />}
          {area === 'locations' && <Warehouses />}
          {area === 'customers' && <Customers />}
        </div>
      </div>
    </>
  );
};
