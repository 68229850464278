import * as React from 'react';
import Helmet from 'Components/Helmet';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import Table from './Table';
import { Link } from 'react-router-dom';
import { useStoreState } from '~/store';
import { Alert } from 'Components/nui';
import { FiltersProvider } from 'Components/filters/context';

export default () => {
  const auth = useStoreState(state => state.auth);
  const solution = useStoreState(state => state.auth.solution);
  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>
      <div className="full-page-header">
        <h1 className="inline mt-10 mr-20 mb-0">Users</h1>

        <div className="filter-export product-export-button">
          {auth.hasRoleInAnySolution('manager') && (
            <Link to="/users/add" className="button secondary">
              Add user
            </Link>
          )}
        </div>

        <ul className="nui-bc right pt-20">
          <li>
            <BreadcrumbRoot active="users" />
          </li>
          <li>Users</li>
        </ul>
        {!auth.hasRoleInAnySolution('manager') && (
          <Alert hasicon type="info" className="mt-20">
            Please contact your manager or support to add or modify users and
            their roles
          </Alert>
        )}
      </div>

      <div className="full-page-content">
        <div className="users-table">
          <div className="generic-table light mt-0">
            <div className="nui-table table-wrapper th-sticky">
              <FiltersProvider store={`user-filters_${solution?.id}`}>
                <Table />
              </FiltersProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
