import React from 'react';
import RichText from '~/components/nui/RichText';

const RichTextWidget = ({ onChange, value, initialValue }) => {
  return (
    <RichText
      key={initialValue} // Restart component when initialValue changes.
      onChange={onChange}
    />
  );
};

export default RichTextWidget;
