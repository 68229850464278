import React, { useEffect, useRef } from 'react';
import { Table, Loading } from 'Components/nui';
import { useStoreState, useStoreActions } from '~/store';
import { useInterval } from 'react-use';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import useTable from './hooks/useTable';
import useSortedData from './hooks/useSortedData';
import MarketRow from 'Pages/MarketRow';
import { FormattedMessage } from 'react-intl';
import RowToggle from 'Components/nui/RowToggle';
import filterOrders from 'Components/filters/filterMarket';

const UPDATE_INTERVAL = 30000;

const Body = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const solution = useStoreState(state => state.auth.solution);
  const productById = useStoreState(state => state.auth.productById);

  const setData = useStoreActions(actions => actions.market.setData);
  const update = useStoreActions(actions => actions.market.update);

  useEffect(() => {
    setData({
      updatedDate: null,
    });
    update();
  }, [solution]);

  useInterval(() => {
    update();
  }, UPDATE_INTERVAL);

  let data = useSortedData();
  data = filterOrders(data);

  const { columns, expandedRowKeys, setExpandedRowKeys } = useTable(
    [
      ['product', ['product-brand', 'product-name']],
      ['legend', ['legend-price', 'legend-volume', 'legend-type']],
      ['ref-price', ['ref-price-content']],
      ['state', ['state-tradeable']],
      'origins',
      'locations',
      'incoterms',
      ['etd', ['etd-from', 'etd-to']],
      ['loading', ['loading-desc']],
      'splittable',
      'payment-term',
      'published',
    ],
    data
  );

  const scrolled = useRef(false);
  useEffect(() => {
    if (!scrolled.current && data && data.length > 0) {
      const urlParams = new URLSearchParams(window.location.search);
      const orderParamId = urlParams.get('order');

      if (orderParamId) {
        requestAnimationFrame(() => {
          const element = document.querySelector(
            `tr[data-row-key="${orderParamId}"]`
          );
          if (element) {
            scrolled.current = true;
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
      }
    }
  }, [data?.length]);

  if (data === null)
    return (
      <div className="nui-row full-page-messaging align-center mt-10 pt-100 p-100">
        <Loading size="huge" />
      </div>
    );

  if (data.length === 0)
    return (
      <div className="nui-row full-page-messaging align-center mt-10 pt-100 p-100">
        <div className="icon-inbox bordered align-center">
          <FormattedMessage
            id="marketplace-empty-text"
            description="Text for when marketplace is empty"
            defaultMessage="There are currently no offers or bids. Place an offer or bid to start trading."
          />
        </div>
      </div>
    );

  return (
    <div className="full-page-content">
      <Table
        className="md-table generic-table expand-table"
        columns={columns}
        data={data}
        expandIconAsCell={false}
        expandIcon={({ expanded, onExpand, record }) => (
          <RowToggle
            open={expanded}
            onClick={e => {
              onExpand(record, e);
            }}
          ></RowToggle>
        )}
        rowKey={record => record.pid}
        rowClassName={(record, _index) => {
          let name = expandedRowKeys.includes(record.id) ? 'selected' : '';
          const product = productById(record.product.id);
          if (!product) {
            name = name + ' viewonly';
          }
          return name;
        }}
        expandedRowRender={record => (
          <MarketRow id={record.id} updated={record.updated} />
        )}
        expandedRowKeys={expandedRowKeys}
        onExpand={(_, record) => {
          if (expandedRowKeys.includes(record.pid)) {
            setExpandedRowKeys([0]);
            history.push(pathname);
          } else {
            setExpandedRowKeys([record.pid]);
            history.push(
              `${pathname}?${qs.stringify({
                order: record.pid,
              })}`
            );
          }
        }}
        onRow={record => {
          const isExpanded = expandedRowKeys.includes(record.pid);
          return {
            'title': isExpanded
              ? 'Click row to hide details'
              : 'Click row to view details',
            'data-id': record.id,
          };
        }}
      />
    </div>
  );
};

export default Body;
