import React from 'react';
import { useSharedCustomer } from '.';
import * as R from 'ramda';
import { Alert } from 'Components/nui';
import Tabs, { TabPane } from 'Components/nui/Tabs';
import Products from './Products';
import Warehouses from './Warehouses';
import Links from './Links';
import Freight from './Freight';
import {
  WarehousesList,
  CustomerDetails,
  CustomerAddresses,
} from 'Components/Lists';
import { useStoreState } from 'easy-peasy';
import Service from './Service';

export default () => {
  const [customer] = useSharedCustomer();
  const solution = useStoreState(state => state.auth.solution);
  const solutionSettings = useStoreState(state => state.auth.solutionSettings);

  if (!customer) return null;

  return (
    <>
      <div className="full-page-header">
        <div className="detail-lists single-double-single">
          <div className="list-box">
            <CustomerDetails customer={customer} />
          </div>
          <div className="list-box double">
            <CustomerAddresses customer={customer} />
          </div>

          <div className="list-box">
            {R.pathSatisfies(
              item => item && item.length > 0,
              ['warehouses'],
              customer
            ) ? (
              <WarehousesList warehouses={customer.warehouses} />
            ) : (
              <div>
                <h2>Locations</h2>
                <ul className="pt-10">
                  <li className="stacked">
                    <Alert hasicon className="align-center">
                      No locations to display.
                    </Alert>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="full-page-header">
        <hr className="mt--12 show-for-medium" />
        <h2 className="light m-0">Linked with</h2>
      </div>
      <div className="full-page-content">
        <Links customer={customer} />
      </div>

      <div className="full-page-content tab-content">
        <Tabs animated={false} defaultActiveKey="products">
          <TabPane tab="Products" key="products">
            <Products />
          </TabPane>
          <TabPane tab="Locations" key="locations">
            <Warehouses />
          </TabPane>
          {solution.freight === 'incoterm' && (
            <TabPane tab="Freight" key="freight">
              <Freight />
            </TabPane>
          )}
          {(solutionSettings.flatpremiums ||
            solutionSettings.ratedpremiums) && (
            <TabPane tab="Service fees" key="service">
              <Service />
            </TabPane>
          )}
        </Tabs>
      </div>
    </>
  );
};
