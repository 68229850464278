import qs from 'qs';
import * as R from 'ramda';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { parseSearch } from '~/utils';

export default function useExpandedRows(orders) {
  const history = useHistory();
  const { pathname } = useLocation();

  const onlyId = orders && orders.length === 1 ? orders[0].id : null;

  const { order } = parseSearch(R.path(['location', 'search'], history));

  const [expandedRowKeys, setExpandedRowKeys] = useState<(string | number)[]>(
    []
  );

  useEffect(() => {
    setExpandedRowKeys(order ? [order] : []);
  }, [order]);

  useEffect(() => {
    if (onlyId) setExpandedRowKeys([onlyId]);
  }, [onlyId]);

  const expandRow = useCallback(
    (tab?: string, recordId?: string) => (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setExpandedRowKeys(recordId ? [recordId] : []);
      history.push(
        `${pathname}?${qs.stringify({
          order: recordId,
          tab,
        })}`
      );
    },
    []
  );

  return {
    expandedRowKeys,
    setExpandedRowKeys,
    expandRow,
  } as const;
}
