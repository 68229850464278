import React, { useEffect } from 'react';
import { useToggle, useWindowScroll } from 'react-use';

const stickThreshold = 142;

export default () => {
  const { y } = useWindowScroll();
  const [stick, setStick] = useToggle();

  useEffect(() => {
    if (!stick && y >= stickThreshold) {
      setStick(true);
      document.body.classList.add('stick');
    }

    if (stick && y < stickThreshold) {
      setStick(false);
      document.body.classList.remove('stick');
    }
  }, [y]);

  return <></>;
};
