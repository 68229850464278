import React from 'react';
import * as R from 'ramda';
import { ColumnTree } from '../hooks/useTable/defineColumns';
import { FormattedMessage } from 'react-intl';
import { useStoreState } from '~/store';

export const IncotermColumn = ColumnTree.defineNode(
  'incoterms',
  ({ solutionColumns }) => {
    const solution = useStoreState(state => state.auth.solution);
    const solutionIncoterms = solution.incoterms;

    return {
      title: R.propOr(
        <FormattedMessage
          id="marketplace-table-heading-incoterm"
          description="Table heading, incoterm"
          defaultMessage="Incoterm"
        />,
        'label',
        solutionColumns.incoterms
      ),
      children: [
        {
          title: '',
          key: 'incoterm',
          className: 'col-incoterm',
          render: (text, record, index) => {
            const recordIncoterms = record.incoterms;
            const incotermsDesc =
              recordIncoterms?.length > 0
                ? recordIncoterms
                    .map(ri => {
                      const matchedSolutionIncoterm = solutionIncoterms?.find(
                        si => si.id === ri
                      );
                      return matchedSolutionIncoterm?.desc || '';
                    })
                    .join(', ')
                : undefined;

            return (
              <div className="td-content">
                <span className="tiny-label">
                  {R.propOr(
                    <FormattedMessage
                      id="marketplace-table-heading-incoterm"
                      description="Incoterm, repeat table heading"
                      defaultMessage="Incoterm"
                    />,
                    'label',
                    solutionColumns.incoterms
                  )}
                </span>
                <abbr title={incotermsDesc}>
                  {record.incoterms && record.incoterms.join(', ')}
                </abbr>
              </div>
            );
          },
        },
      ],
    };
  }
);
