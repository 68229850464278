import { Order } from '~/store/models/orders';
import { getCustomerDivisions } from './CustomerFilters';

const filterCustomers = (data: any, filters: any) => {
  if (!data) return null;

  let filteredData = [...data];

  if (filters?.country?.items?.length > 0) {
    filteredData = filteredData?.filter((x: Order) =>
      filters.country.items.includes(x.country.id)
    );
  }

  if (filters?.division?.items?.length > 0) {
    filteredData = filteredData.filter(item => {
      const customerDivisionIds = getCustomerDivisions(item).map(
        (element: any) => element.id
      );
      return customerDivisionIds.some(id =>
        filters.division.items.includes(id)
      );
    });
  }

  return filteredData;
};
export default filterCustomers;
