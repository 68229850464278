import type { Action, Computed } from 'easy-peasy';
import { action, computed } from 'easy-peasy';
import * as R from 'ramda';
import { WithItem } from './utils';

export interface IListModel<
  T,
  K extends string,
  G extends Readonly<Record<K, T[]>> = Readonly<Record<K, T[]>>
> {
  data: T[];
  append: Action<IListModel<T, K, G>, T>;
  prepend: Action<IListModel<T, K, G>, T>;
  remove: Action<IListModel<T, K, G>, T>;
  update: Action<IListModel<T, K, G>, Partial<T>>;
  replace: Action<IListModel<T, K, G>, T>;
  set: Action<IListModel<T, K, G>, T[]>;
  byId: Computed<IListModel<T, K, G>, (id: string) => T | null>;
  groups: Computed<IListModel<T, K, G>, Partial<G>>;
}

export default function listModel<
  T,
  K extends string,
  G extends Record<K, T[]> = Record<K, T[]>
>(
  initialData: T[],
  getKey: (item: T) => string,
  grouping?: {
    data: G;
    getGroup: (item: T) => keyof G & string;
  }
): IListModel<T, K, G> {
  const withItem = WithItem<T>(getKey);
  return {
    data: initialData,
    append: action((state, payload) => {
      state.data.push(payload);
    }),
    prepend: action((state, payload) => {
      state.data.unshift(payload);
    }),
    remove: action(
      withItem((state, _item, index) => {
        state.data.splice(index, 1);
      })
    ),
    update: action(
      withItem((state, item, index) => {
        state.data[index] = { ...state.data[index], ...item };
      })
    ),
    replace: action(
      withItem((state, item, index) => {
        state.data[index] = item;
      })
    ),
    set: action((state, payload) => {
      state.data = payload;
    }),
    byId: computed(
      state => id => state.data.find(o => getKey(o) === id) || null
    ),
    groups: computed(state => {
      if (!grouping) return {};
      return R.groupBy(grouping.getGroup, state.data) as any; // TODO: Fix return type.
    }),
  };
}
