const filterUsers = (data: any, filters: any) => {
  if (!data) return null;

  let filteredData = [...data];

  if (filters?.company?.items?.length > 0) {
    filteredData = filteredData?.filter((x: any) =>
      filters.company.items.includes(x.company.id)
    );
  }

  return filteredData;
};
export default filterUsers;
