import React from 'react';
import Body from './Body';
import Header from './Header';
import Filter from './Filter';
import Helmet from 'Components/Helmet';
import { useStoreState } from '~/store';
import { Redirect } from 'react-router-dom';
import { FiltersProvider } from 'Components/filters/context';

export default () => {
  const marketplace = useStoreState(
    state => state.auth.solutionSettings.marketplace
  );
  const solution = useStoreState(state => state.auth.solution);

  if (!marketplace) return <Redirect to="/tenders" />;

  return (
    <div className="market">
      <Helmet>
        <title>Marketplace</title>
      </Helmet>

      <Header />
      <FiltersProvider store={`market-filters_${solution?.id}`}>
        <Filter />
        <Body />
      </FiltersProvider>
    </div>
  );
};
