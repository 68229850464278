import { action, thunk } from 'easy-peasy';
import * as R from 'ramda';
import { Order, Trade, Product } from '~/models';
import {} from '~/models';
import moment from 'moment-timezone';

export default {
  orders: [],
  trades: [],
  updatedDate: null,
  mode: 'month',
  volumeMode: false,
  tableData: {},
  columns: [],
  schemas: {},
  mip: [], // mobile invisible products

  setData: action((state, payload) => {
    const keys = Object.keys(payload);
    keys.forEach(key => (state[key] = payload[key]));
  }),

  setSchema: action((state, payload) => {
    state.schemas = { ...state.schemas, ...payload };
  }),

  toggleMip: action((state, payload) => {
    state.mip = state.mip.includes(payload)
      ? R.without([payload], state.mip)
      : R.append(payload, state.mip);
  }),

  toggleMipAll: action((state, ids) => {
    state.mip = state.mip.length > 0 ? [] : ids;
  }),

  updateSchema: thunk(async (actions, payload, { injections: { api } }) => {
    try {
      const data = await api.getData(
        { type: 'orders', id: 'neworder' },
        { product: payload }
      );
      actions.setSchema({ [payload]: data });
    } catch (e) {
      // Empty
    }
  }),

  calculateTableData: thunk(async (actions, _, { getState, getStoreState }) => {
    const { solutionProducts } = getStoreState().auth;
    const store = getState();

    const productById = id => {
      const product = R.find(R.propEq('id', id), solutionProducts);
      return product && new Product(product);
    };

    const mode = store.mode;
    let data = {};
    const minMoment = moment().startOf(mode);
    let maxMoment = moment().startOf(mode);
    let totals = {};
    let flags = {};
    store.orders.forEach(order => {
      const product = productById(R.path(['product', 'id'], order));
      if (!product) return;
      const productId = product.p_id;

      let fromMoment = moment(order.etd.from).startOf(mode);
      const toMoment = moment(order.etd.to).startOf(mode);
      const type = order.type;
      const newPrice = order.price.val;

      if (maxMoment < toMoment) {
        maxMoment = toMoment;
      }
      const total = R.pathOr(0, [productId, 'total', type], data);
      data = R.mergeDeepRight(data, {
        [productId]: { total: { [type]: total + 1 } },
      });

      while (fromMoment <= toMoment) {
        const key = fromMoment.format('X');
        flags[key] = 1;
        const price = R.pathOr(
          null,
          [productId, key, type, 'price', 'val'],
          data
        );
        totals[key] = R.pathOr(0, [key], totals) + 1;

        const cnt = R.pathOr(0, [productId, key, `cnt_${type}`], data);
        data = R.mergeDeepRight(data, {
          [productId]: { [key]: { [`cnt_${type}`]: cnt + 1 } },
        });
        if (newPrice === 0 && !order.index) {
          // reset
          data = R.mergeDeepRight(data, {
            [productId]: { [key]: { [`open_${type}`]: null } },
          });
          // save
          data = R.mergeDeepRight(data, {
            [productId]: { [key]: { [`open_${type}`]: order } },
          });
        } else if (
          price === null ||
          (newPrice > price && type === 'bid') ||
          (newPrice < price && type === 'offer')
        ) {
          // reset
          data = R.mergeDeepRight(data, {
            [productId]: { [key]: { [type]: null } },
          });
          // save
          data = R.mergeDeepRight(data, {
            [productId]: { [key]: { [type]: order } },
          });
        }

        fromMoment = fromMoment.clone().add(1, mode);
      }
    });

    store.trades.forEach(trade => {
      const product = productById(R.path(['product', 'id'], trade));
      if (!product) return;
      const productId = product.p_id;

      let fromMoment = moment(trade.etd.from).startOf(mode);
      const toMoment = moment(trade.etd.to).startOf(mode);
      const newCreated = moment(trade.created);

      if (maxMoment < toMoment) {
        maxMoment = toMoment;
      }

      while (fromMoment <= toMoment) {
        const key = fromMoment.format('X');
        flags[key] = 1;

        const trd = R.pathOr(0, [productId, key, `trade`], data);
        if (!trd || newCreated > moment(trd.created)) {
          // reset
          data = R.mergeDeepRight(data, {
            [productId]: { [key]: { trade: null } },
          });
          // save
          data = R.mergeDeepRight(data, {
            [productId]: { [key]: { trade } },
          });
        }

        fromMoment = fromMoment.clone().add(1, mode);
      }
    });

    let header = [];
    let curMoment = minMoment.clone();
    let globalMax = minMoment.clone().add(2, 'year');
    if (mode === 'week') {
      globalMax = minMoment.clone().add(12, 'week');
    }
    if (mode === 'day') {
      globalMax = minMoment.clone().add(30, 'day');
    }
    let max = maxMoment.clone().add(12, mode);
    while (curMoment <= max && curMoment <= globalMax) {
      const key = curMoment.format('X');
      header.push({
        key,
        moment: curMoment.clone(),
        empty: !(key in flags),
      });
      curMoment = curMoment.clone().add(1, mode);
    }

    actions.setData({ tableData: data, columns: header });
  }),

  updateData: thunk(
    async (actions, _, { injections: { api }, getState, getStoreState }) => {
      const storeState = getStoreState();
      const { solution } = storeState.auth;
      const { warehouse } = storeState.market;
      const store = getState();
      const { updatedDate } = store;
      const newDate = new Date();

      let params = { solution: solution.id };
      if (warehouse) {
        params['warehouse'] = warehouse.id;
      }

      // Orders
      let orders = [];
      let [status, response] = await api.getDataIfModifiedSince(
        '/market-orders',
        params,
        updatedDate
      );

      if (status === 200) {
        const changed = response.orders.map(o => new Order(o));
        let ids = [];
        changed.forEach(o => {
          o.status === 'Working' && orders.push(o);
          ids.push(o.id);
        });
        if (updatedDate) {
          store.orders.forEach(order => {
            if (!ids.includes(order.id)) {
              orders.push(order);
            }
          });
        }

        actions.setData({
          orders,
        });
      }

      // Trades
      let trades = [];
      [status, response] = await api.getDataIfModifiedSince(
        '/market-trades',
        { solution: solution.id },
        updatedDate
      );
      if (status === 200) {
        let changed = response.trades.map(t => new Trade(t));
        if (updatedDate) {
          store.trades.forEach(t => {
            const id = R.findIndex(R.propEq('id', t.id))(changed);
            if (id === -1) {
              trades.push(t);
            } else {
              trades.push(changed[id]);
            }
          });
        }
        changed.forEach(t => {
          trades.push(t);
        });
        actions.setData({
          trades,
        });
      }

      actions.setData({
        updatedDate: newDate,
      });

      actions.calculateTableData();
    }
  ),
};
