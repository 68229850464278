import type { ColumnProps } from 'Components/NuiTable';
import React, { useState } from 'react';
import Table from 'Components/NuiTable';
import { Trade } from '~/store/models';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import FNumber from 'Components/FNumber';
import moment from 'moment-timezone';
import { inArrayIf, capitalize } from '~/utils';
import { useStoreState } from '~/store';
import LoadingDetails from 'Components/Lists/LoadingDetails';
import Locations from 'Components/Lists/Locations';
import Contact from 'Components/Lists/Contact';
import { Tooltip, Loading } from 'Components/nui';
import { unitsOfMeasure } from '~/utils/const';
import classnames from 'classnames';
import { IndexUnit, PriceIndex } from 'Components/nui/PriceIndex';
import ExtraDetails from 'Components/Lists/ExtraDetails';

interface ICell {
  children: React.ReactNode;
}
const Cell = ({ children, ...props }: ICell) => (
  <td {...props}>
    <div className="td-content">{children}</div>
  </td>
);

interface ITradeDiv {
  trade: Trade;
}
function TradeDiv({ trade }: ITradeDiv) {
  return (
    <div className="list-boxes">
      {trade.loading && (
        <div className="list-box">
          <LoadingDetails loading={trade.loading} unit={trade.volume.unit} />
        </div>
      )}
      {trade.type && (
        <div className="list-box">
          <Contact
            order={
              trade.offer && trade.offer.is_owner ? trade.bid : trade.offer
            }
          />
        </div>
      )}
      {(trade?.old()?.fromaddr?.length || trade?.old()?.toaddr?.length) && (
        <div className="list-box">
          <Locations
            fromaddr={trade.old().fromaddr}
            toaddr={trade.old().toaddr}
          />
        </div>
      )}
      <div className="list-box">
        <ExtraDetails record={trade} bid={trade.bid} offer={trade.offer} />
      </div>
    </div>
  );
}

interface Trades {
  fetch: () => Trade[];
  loading: () => boolean;
}
interface Pagination {
  total: () => number;
  pages: () => number;
  page: () => number;
  limit: () => number;
  changePage: (c: { value: number }) => void;
  changeLimit: (c: { value: number }) => void;
}

interface ITradesTable {
  trades: Trades;
  pagination: Pagination;
}
export default ({ trades, pagination }: ITradesTable) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const solution = useStoreState(state => state.auth.solution);
  const attributes = R.pathOr([], ['attributes'], solution);

  const productById: any = useStoreState(R.path(['auth', 'productById']));
  const { shippingroutes = [] } = useStoreState(state => state.auth.solution);

  const { shipments, matchinglevel } = useStoreState(
    state => state.auth.solutionSettings
  );
  const showCommission = matchinglevel === 'commodity';

  const getRoute = (trade: Trade) =>
    R.find(
      route =>
        R.pathEq(['source', 'id'], trade.route.source?.port?.id || '', route) &&
        R.pathEq(
          ['destination', 'id'],
          trade.route.destination?.port?.id || '',
          route
        ),
      shippingroutes
    );

  const columns: ColumnProps<Trade>[] = [
    {
      title: 'ID',
      className: 'col-id expand-text',
      key: 'id',
      render: (text, record) => (
        <Link to={`/trades/${record.id}`}>
          <Tooltip
            title={
              <span className="icon-tooltip">
                <span className="icon-info-circled"></span>View trade details
              </span>
            }
          >
            {record.pid}
          </Tooltip>
        </Link>
      ),
    },
    {
      title: 'Type',
      className: 'col-type',
      key: 'type',
      render: (text, record) => {
        if (record.type === 'purchase')
          return <span className="type-bid">Purchase</span>;
        if (record.type === 'sale')
          return <span className="type-offer">Sale</span>;
        return <span />;
      },
    },
    {
      title: 'Product',
      className: 'col-product-small',
      key: 'product',
      render: (text, record) => (
        <Link
          to={`/products/${record.product.id}/quick`}
          title="View product details"
        >
          <Tooltip
            title={
              <span className="icon-tooltip">
                <span className="icon-info-circled"></span>View product details
                in new window
              </span>
            }
          >
            {record.product.name}
            <span className="nowrap">
              &#65279;<span className="icon-link-ext-alt"></span>
            </span>
          </Tooltip>
        </Link>
      ),
    },
    {
      title: 'Seller',
      className: 'col-seller',
      key: 'seller',
      render: (text, record) => record.seller?.name || 'n/a',
    },
    {
      title: 'Buyer',
      className: 'col-buyer',
      key: 'buyer',
      render: (text, record) => record.buyer?.name || 'n/a',
    },
  ];

  if (attributes.length)
    columns.push({
      title: 'Attributes',
      key: 'attributes',
      className: `col-attributes`,
      render: (_, record) => {
        if (record?.attributes) {
          return Object.keys(record.attributes)
            .sort()
            .map(k => (
              <div className="attribute-box" key={k}>
                <span className="tiny-label">{capitalize(k)}</span>
                {R.pathOr('', ['attributes', k, 0, 'name'], record)}
              </div>
            ));
        }
        return [];
      },
    });

  columns.push(
    {
      title: 'Status',
      key: 'status',
      className: 'col-status',
      render: (text, record) => (
        <span className={`status-${record.status?.toLowerCase()}`}>
          {record.status}
        </span>
      ),
    },
    {
      title: 'Created at',
      key: 'createdat',
      className: 'col-createdat',
      render: (text, record) =>
        moment(record.created).format('DD MMM YYYY, HH:mm'),
    },
    ...inArrayIf(showCommission, {
      title: 'Commission',
      key: 'price.commission',
      className: 'col-commission align-right nowrap',
      render: (text: string, record: Trade) => (
        <>
          <FNumber
            value={record.commission?.commission || 0}
            decimalCount={record.priceDecimalCount()}
          />
          <span className="unit inline ml-5">
            {record.commission?.currency}
          </span>
        </>
      ),
    }),
    {
      title: 'Price',
      children: [
        {
          title: 'Value',
          key: 'price.value',
          className: 'col-price-value align-right',
          render: (text, record) => <PriceIndex order={record} />,
        },
        {
          title: 'Unit',
          key: 'priceunit',
          className: 'col-price-unit',
          render: (text, record) => (
            <IndexUnit order={record} priceunit={record.price.unit} />
          ),
        },
      ],
    },
    {
      title: 'Volume',
      children: [
        {
          title: 'Value',
          key: 'volume.value',
          className: 'align-right',
          render: (text, record) => (
            <FNumber
              value={record.volume.val}
              decimalCount={record.volumeDecimalCount()}
            />
          ),
        },
        {
          title: 'Unit',
          key: 'volume.unit',
          render: (text, record) => record.volume.unit,
        },
      ],
    },
    {
      title: 'Estimated time of departure',
      children: [
        {
          title: 'From',
          key: 'etd.from',
          className: 'col-etd',
          render: (text, record) =>
            record.quarteretd || moment(record.etd.from).format('DD MMM YYYY'),
        },
        {
          title: 'To',
          key: 'etd.to',
          className: 'col-etd col-etd-to',
          render: (text, record) => {
            if (record.quarteretd) return null;

            if (record.deliveryfrequency) {
              const u: string = R.pathOr('', ['volume', 'unit'], record);
              const product = productById(record.product.id);
              const unit: string = product
                ? R.pathOr('', ['loadingunit', 'desc'], product)
                : R.pathOr(u, [u], unitsOfMeasure);

              return (
                <div className="td-content">
                  <span className="tiny-label">Strip order</span>
                  <div className="delivery-volume nowrap">
                    <strong className="all-black">
                      {record.totaldeliveries}
                    </strong>
                    <span className="times-rotate">+</span>
                    <strong className="all-black">
                      {record.volume.delivery_total}
                    </strong>
                    <span className="unit">{unit}</span>
                  </div>
                  <div>
                    <span
                      className={classnames(
                        'icon-clock',
                        'periodic-delivery',
                        record.deliveryfrequency
                      )}
                    >
                      {record.deliveryfrequency}
                    </span>
                  </div>
                </div>
              );
            }

            return moment(record.etd.to).format('DD MMM YYYY');
          },
        },
      ],
    },
    ...inArrayIf(shipments, {
      title: 'Estimated time of arrival',
      key: 'eta',
      className: 'col-eta',
      children: [
        {
          title: 'Date',
          key: 'eta.date',
          className: 'col-eta-date',
          render: (text: string, record: Trade) => {
            const route = getRoute(record);
            return route
              ? record.etd.to.add(route.duration, 'd').format('DD MMM YYYY')
              : null;
          },
        },
        {
          title: 'Duration',
          key: 'eta.duration',
          className: 'col-etd-duration',
          render: (text: string, record: Trade) => {
            const route = getRoute(record);
            return route ? `ETD + ${route.duration} days` : null;
          },
        },
      ],
    })
  );

  const components = {
    body: {
      cell: Cell,
    },
  };

  return (
    <Table
      className="generic-table expand-table th-rows light"
      expandedRowKeys={expandedRowKeys}
      columns={columns}
      dataSource={trades.fetch()}
      loading={{
        indicator: <Loading className="mb-10 mt-10" size="medium" />,
        spinning: !trades.fetch().length && trades.loading(),
      }}
      rowKey={R.prop('id')}
      components={components}
      expandIconAsCell={false}
      expandRowByClick={true}
      expandedRowRender={record => <TradeDiv trade={record} />}
      pagination={{
        size: 'small',
        showSizeChanger: true,
        showTotal: (total, range) =>
          `${range[0]} - ${range[1]} of ${total} items`,
        pageSize: pagination.limit(),
        total: pagination.total(),
        current: pagination.page(),
      }}
      onChange={page => {
        pagination.changePage({ value: R.propOr(0, 'current', page) });
        pagination.changeLimit({ value: R.propOr(0, 'pageSize', page) });
      }}
      onExpand={(_, record) => {
        if (expandedRowKeys.includes(record.id)) {
          setExpandedRowKeys(state => R.filter(x => x !== record.id, state));
        } else {
          setExpandedRowKeys(state => R.append(record.id, state));
        }
      }}
      onRow={record => {
        const isExpanded = expandedRowKeys.includes(record.id);
        return {
          title: isExpanded
            ? 'Click row to hide details'
            : 'Click row to view details',
        };
      }}
    />
  );
};
