import { Icon, Select } from 'antd';
import { ISortable } from './Rolodex';

const { Option } = Select;

const Sort = ({ options, value, onChange }: ISortable) => (
  <Select
    defaultValue={options.find(x => x[0] === value)?.[0]}
    style={{ width: 160 }}
    onChange={value => onChange(value)}
    suffixIcon={<Icon type="caret-down" />}
  >
    {options.map(([id, title]) => (
      <Option key={id} value={id}>
        {title}
      </Option>
    ))}
  </Select>
);

export default Sort;
