import React from 'react';
import { useScrollToTopOnMount } from '~/hooks';
import Helmet from 'Components/Helmet';
import { Link, useRouteMatch } from 'react-router-dom';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import { reportGroups, reportTypes } from '..';
import { useStoreState } from 'easy-peasy';

export default () => {
  useScrollToTopOnMount();
  const { url } = useRouteMatch();

  const isSolutionOwner = useStoreState(state => state.auth.isSolutionOwner);
  const solution = useStoreState(state => state.auth.solution);
  const isdao = useStoreState(state => state.auth.isdao);
  const divisions = useStoreState(state => state.auth.solutionDivisions);

  let roles = [];

  if (isSolutionOwner) {
    roles.push('owner');
  }

  const anonsellview = !!solution.roles.find(
    x => x.division?.anonsellview === true
  );
  if (isSolutionOwner && anonsellview) {
    roles.push('anonsellview');
  }

  if (isdao) {
    roles = ['dao'];
  }

  const hasIntersect = (arr1, arr2) => {
    const set1 = new Set(arr1);
    return arr2.some(item => set1.has(item));
  };

  const columns = {};
  let isEmpty = true;
  for (let i = 0; i < reportGroups.length; i++) {
    const items = Object.values(reportTypes)
      .filter(x => x.group === reportGroups[i])
      .filter(x => hasIntersect(x.acl, roles))
      .filter(
        x => divisions.find(y => y.divisiontype === x.divtype) || !x.divtype
      );

    columns[reportGroups[i]] = items;

    if (items.length > 0) {
      isEmpty = false;
    }
  }

  return (
    <>
      <Helmet>
        <title>Reports</title>
      </Helmet>

      <div className="full-page-header">
        <h1 className="inline mt-10 mr-20 mb-0">Reports</h1>
        <ul className="nui-bc right pt-20">
          <li>
            <BreadcrumbRoot active="reports" />
          </li>
          <li>Reports</li>
        </ul>

        <hr />

        {isEmpty ? (
          <p>No reports available</p>
        ) : (
          <div className="report-lists">
            {Object.keys(columns).map(
              name =>
                columns[name].length > 0 && (
                  <ul key={name}>
                    <li>
                      <h3>{name}</h3>
                    </li>
                    {columns[name].map(item => (
                      <li key={item.path}>
                        <Link to={`${url}/${item.path}`} title={item.title}>
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )
            )}
          </div>
        )}
      </div>
    </>
  );
};
