import * as Models from '~/models/tenders';
import * as api from '~/fetch';
import { loadable } from '~/observable';

type TenderId = Partial<Record<'tenderId', string>>;
type Loader<T> =
  | { loading: true; errors: string[]; data?: undefined }
  | {
      loading: false;
      errors: string[];
      data: T;
    };

export function tenderReport() {
  return loadable<Loader<Models.TenderReport>, TenderId>(
    async function loadReport(onChange, { tenderId = '' } = {}) {
      onChange({ loading: true as const, errors: [], data: undefined });

      try {
        let shouldRerun = false;
        let timeoutId: NodeJS.Timeout | null = null;

        const fetchData = async () => {
          const response = await api.tenders.reports(tenderId);
          await api.utils.processResponse(response, {
            async 200(response) {
              if (timeoutId) {
                clearTimeout(timeoutId);
              }
              const data = await response.json();
              onChange({
                loading: false as const,
                errors: [],
                data: new Models.TenderReport(data),
              });
            },
            async 202() {
              shouldRerun = true;
              onChange({ loading: true as const, errors: [], data: undefined });
            },
            async default() {
              onChange({
                loading: true as const,
                errors: ['There was an error'],
                data: undefined,
              });
            },
          });
        };

        await fetchData();

        // If the response was 202, rerun the function in 3 seconds
        if (shouldRerun) {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          timeoutId = setTimeout(() => {
            loadReport(onChange, { tenderId });
          }, 3000);
        }
      } catch (error) {
        console.error('tender-analytics-report: Unknown error', error);
        onChange({
          loading: true as const,
          errors: ['There was an error'],
          data: undefined,
        });
      }
    }
  );
}

export function counterHistory() {
  return loadable<Loader<Models.TenderCharts>, TenderId>(
    async (onChange, { tenderId = '' } = {}) => {
      onChange({ loading: true as const, errors: [], data: undefined });

      try {
        const response = await api.tenders.reportCounterHistory(tenderId);
        await api.utils.processResponse(response, {
          async 200(response) {
            const data = await response.json();
            onChange({
              loading: false as const,
              errors: [],
              data: new Models.TenderCharts({ history: data, frequency: [] }),
            });
          },
          async default() {
            onChange({
              loading: true as const,
              errors: ['There was an error'],
              data: undefined,
            });
          },
        });
      } catch (error) {
        console.error('tender-analytics-report: Unknown error', error);
        onChange({
          loading: true as const,
          errors: ['There was an error'],
          data: undefined,
        });
      }
    }
  );
}
