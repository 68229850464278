import React, { useState, useEffect } from 'react';
import { Form, Button, Loading, Modal } from 'Components/nui';
import { FormWrapper } from 'Components/form';
import { mergeSchema } from 'Components/form/utils';
import { api } from '~/api';
import { toast } from 'react-toastify';
import { useStoreState } from '~/store';
import * as R from 'ramda';
import { promisify } from 'es6-promisify';
import { useDeliveries } from '~/store/models';

function useFormWrapper({ form, schema, delivery = {} }) {
  const fields = [
    // { name: 'vessel', label: 'Vessel', type: 'Input', required: true },
    { name: 'reference', label: 'Reference', type: 'Input' },
    { name: 'etd', label: 'Estimated time of departure' },
    { name: 'eta', label: 'Estimated time of arrival' },
    {
      name: 'buyer',
      label: 'Buyer',
      choices: buyer =>
        R.propOr([], 'choices', buyer).map(([value, label]) => ({
          value,
          label,
          key: value,
        })),
    },
    {
      name: 'consignee',
      label: 'Consignee',
      choices: consignee =>
        R.propOr([], 'choices', consignee).map(([value, label]) => ({
          value,
          label,
          key: value,
        })),
    },
    { name: 'comment', label: 'Comment' },
  ];

  return new FormWrapper(form, delivery, mergeSchema(schema, fields));
}

const BaseForm = ({
  form,
  schema,
  delivery,
  close,
  deliveries,
  otherparty,
  division,
  trades,
  reset = () => undefined,
}) => {
  const formWrapper = useFormWrapper({ form, schema, delivery });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      await validateFieldsAndScroll();
    } catch (error) {
      return;
    }

    setLoading(true);

    const data = formWrapper.serialize();

    try {
      const result = await deliveries.addDelivery({
        division,
        ...delivery,
        ...data,
      });
      await deliveries.addTradesToDelivery(result.shipment, trades);
      await reset();
      const plural = trades.length === 1 ? 'trade' : 'trades';
      toast(`Successfully consolidated ${trades.length} ${plural}`);
      close();
    } catch (error) {
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        error
      );
      console.error(error);
      try {
        formWrapper.setErrors(errors);
      } catch (e) {
        toast('Your form could not be submitted. Please try again later.', {
          type: 'error',
        });
      }
      setLoading(false);
    }
  };

  return (
    <div className="nui-form">
      <Form onSubmit={handleSubmit}>
        {formWrapper.render()}
        <div>
          <Button type="primary" htmlType="submit" disabled={loading}>
            Save
          </Button>
          <Button type="reverse" disabled={loading} onClick={close}>
            Close
          </Button>
        </div>
      </Form>
    </div>
  );
};

const DeliveryForm = Form.create()(BaseForm);

const AddDelivery = ({
  trades,
  close,
  division,
  delivery = undefined,
  reset = () => undefined,
}) => {
  const [loading, setLoading] = useState(true);
  const [schema, setSchema] = useState({});

  const solution = useStoreState(state => state.auth.solution.id);
  const deliveries = useDeliveries();

  const getSchema = async () => {
    setLoading(true);
    try {
      const { otherparty } = delivery;
      const other = otherparty ? `&otherparty=${otherparty}` : '';
      const result = await api.request({
        url: `/shipments/schema?solution=${solution}${other}`,
        method: 'get',
      });
      setSchema(result.data.fields);
    } catch (error) {
      toast('Something went wrong. Please try again later.', { type: 'error' });
    }
    setLoading(false);
  };

  useEffect(() => {
    getSchema();
  }, []);

  return loading ? (
    <Loading size="medium" />
  ) : schema ? (
    <div className="delivery-form">
      <DeliveryForm
        schema={schema}
        division={division}
        delivery={delivery}
        close={close}
        deliveries={deliveries}
        reset={reset}
        trades={trades}
      />
    </div>
  ) : null;
};

export default ({ trades, visible, close, otherparty, division, reset }) => {
  return (
    visible && (
      <Modal className="nui-modal small" close={close}>
        <h1>Add delivery</h1>
        <AddDelivery
          trades={trades}
          delivery={{ otherparty }}
          close={close}
          reset={reset}
          division={division}
        />
      </Modal>
    )
  );
};
