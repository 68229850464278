import React from 'react';
import classnames from 'classnames';
import * as R from 'ramda';

const types = {
  default: 'nui-alert-info',
  info: 'nui-alert-info',
  success: 'nui-alert-success',
  warning: 'nui-alert-warning',
  error: 'nui-alert-error',
  help: 'nui-alert-help',
};

const sizes = {
  default: '',
  small: 'nui-alert-small',
  large: 'nui-alert-large',
};

export default props => {
  const { children, size, type, className, hasicon } = props;

  return (
    <div
      className={classnames(
        'nui-alert',
        R.pathOr(types.default, [type], types),
        R.pathOr(sizes.default, [size], sizes),
        className,
        {
          'has-icon': hasicon,
        }
      )}
    >
      {children}
    </div>
  );
};
