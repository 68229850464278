import React, { useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import * as R from 'ramda';
import Helmet from 'Components/Helmet';
import Body from 'Pages/Market/Body';
import DivisionWarehouse from 'Pages/Market/DivisionWarehouse';
import { FiltersProvider } from 'Components/filters/context';
import { useFilters } from 'Components/filters/context';

export default () => {
  return (
    <FiltersProvider>
      <MarketSlice />
    </FiltersProvider>
  );
};

const MarketSlice = () => {
  const { productId } = useParams();
  const product = useStoreState(state => state.auth.productById(productId));
  const p_id = R.prop('p_id', product);

  const { setFilters } = useFilters();

  useEffect(() => {
    p_id && setFilters({ product: { items: [p_id] } });
  }, [p_id]);

  const title = p_id
    ? `Market depth - ${R.prop('name', product)}`
    : 'Marketplace';
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <h2 className="mb-20">
        {title}
        {product && R.hasPath(['owner', 'name'], product) && (
          <span className="nowrap"> - {product.owner.name}</span>
        )}
      </h2>

      <DivisionWarehouse />

      <div className="market sb-175 ml--20 mr--20">
        <Body />
      </div>
    </>
  );
};
