import React, { useEffect, useState } from 'react';
import { Form, Button, Table, Alert } from 'Components/nui';
import { FormWrapper } from 'Components/form';
import { mergeSchema, normalizeChoices } from 'Components/form/utils';
import { LoadingState } from 'Components/Layout';
import { ShowResult } from 'Components/Result';
import { useStoreState } from 'easy-peasy';
import { promisify } from 'es6-promisify';
import * as R from 'ramda';
import { useAsync } from 'react-use';
import { api } from '~/api';
import { submitError, submitSuccess } from 'Components/form/utils';
import { inArrayIf } from '~/utils';

const CustomerTable = ({ prices }) => {
  const columns = [
    {
      title: 'Customer name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Price',
      key: 'price',
      align: 'right',
      render: (text, record, index) => {
        return record.price.val > 0 ? (
          record.price.val
        ) : (
          <span className="tag">n/a</span>
        );
      },
    },
    {
      title: 'Currency/Unit',
      key: 'currency',
      render: (text, record, index) => {
        return record.price.unit;
      },
    },
    {
      title: 'N/A reason',
      key: 'reason',
      render: (text, record, index) => {
        return record.price.reason;
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      className="generic-table"
      columns={columns}
      data={prices}
    />
  );
};

const PortsTable = ({ prices, zones }) => {
  const columns = [
    {
      title: 'Country',
      key: 'country',
      render: (text, record, index) => R.path(['country', 'name'], record),
    },
    {
      title: 'Port',
      key: 'port',
      render: (text, record, index) => R.path(['port', 'name'], record),
    },
    ...inArrayIf(zones, {
      title: 'Zone',
      key: 'zone',
      render: (_, record) => R.path(['zone', 'name'], record),
    }),
    {
      title: 'Price',
      key: 'price',
      align: 'right',
      render: (text, record, index) => {
        return record.price.val > 0 ? (
          record.price.val
        ) : (
          <span className="tag">n/a</span>
        );
      },
    },
    {
      title: 'Currency/Unit',
      key: 'currency',
      render: (text, record, index) => {
        return record.price.unit;
      },
    },
    {
      title: 'N/A reason',
      key: 'reason',
      render: (text, record, index) => {
        return record.price.reason;
      },
    },
    {
      title: 'Total customers',
      key: 'total',
      render: (text, record, index) => {
        return record.count;
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      className="generic-table"
      columns={columns}
      data={prices}
    />
  );
};

function getFormWrapper({ form, product, schema, initialData }) {
  const getChoicesByDivision =
    ({ choices }) =>
    () => {
      const divisionId = form.getFieldValue('division');
      return normalizeChoices(
        Array.isArray(choices) ? choices : R.propOr([], divisionId, choices)
      );
    };

  let fields = [
    {
      name: 'division',
      label: 'Division',
      choices: normalizeChoices,
    },
    {
      name: 'loading',
      label: 'Loading details',
      choices: R.sortBy(R.prop('label'))(
        R.map(
          x => ({
            key: x.id,
            value: x.id,
            label: x.desc,
          }),
          R.propOr([], 'loading', product)
        )
      ),
      props: {
        placeholder: 'Select loading details',
      },
    },
    {
      name: 'price',
      label: (
        <>
          Price{' '}
          <span className="normal">
            {product.getDesc(form.getFieldValue('loading'))}
          </span>
        </>
      ),
      step: product.step,
      min: product.step,
    },
    {
      name: 'fromaddr',
      label: 'Delivery from',
      choices: getChoicesByDivision,
      props: {
        placeholder: 'Select a location',
      },
    },
  ];

  return new FormWrapper(form, initialData, mergeSchema(schema, fields));
}

function NForm({ form, product, schema, submitState, initialData, auto }) {
  const solution = useStoreState(state => state.auth.solution);
  const zones = R.includes('zone', solution.freight_types);

  const [prices, setPrices] = useState();
  const [format, setFormat] = useState();
  // Already bound
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);

  useEffect(() => {
    if (auto) handleSubmit();
  }, [auto]);

  if (!product) return null;

  const formWrapper = getFormWrapper({
    form,
    product,
    schema,
    initialData,
  });

  async function handleSubmit(e) {
    e && e.preventDefault();
    // Client-side validation
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }
    // Server-side validation
    submitState.setLoading(true);
    const params = formWrapper.serialize();
    try {
      setPrices(null);
      const result = await api.getData2(
        `/products/${product.id}/displayed-prices`,
        params
      );
      if (result.format === 'port') {
        setFormat('port');
        setPrices(
          R.sortWith([
            R.ascend(R.path(['country', 'name'])),
            R.ascend(R.path(['port', 'name'])),
          ])(result.prices)
        );
      } else if (result.format === 'customer') {
        setFormat('customer');
        setPrices(R.sortBy(R.path(['name']))(result.prices));
      }
    } catch (err) {
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        err
      );
      if (errors) {
        // TODO scrollIntoView
        formWrapper.setErrors(errors);
      } else {
        // report the unexcepted error
        submitState.setResult(err);
      }
    }
    submitState.setLoading(false);
  }

  return (
    <div>
      <div className="customer-prices-form">
        <div className="nui-row">
          <h2 className="light">Customer prices</h2>
          <Form className="mt-20">
            {formWrapper.render()}
            <Form.Item className="valign-b">
              <Button
                htmlType="button"
                onClick={handleSubmit}
                loading={submitState.loading}
              >
                Show
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>

      {prices && (
        <div className="full-page-content">
          <div className="table-wrapper customer-table">
            {prices.length > 0 ? (
              <>
                {format === 'port' && (
                  <PortsTable prices={prices} zones={zones} />
                )}
                {format === 'customer' && <CustomerTable prices={prices} />}
              </>
            ) : (
              <Alert hasicon>No data to display</Alert>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

const WrappedForm = Form.create()(NForm);

export default ({ productId, init, auto }) => {
  const productById = useStoreState(state => state.auth.productById);
  const product = productId ? productById(productId) : null;

  const schemaState = useAsync(() => {
    if (!productId) return Promise.resolve();
    return api.getData({
      type: 'products',
      id: `${productId}/displayed-prices/schema`,
    });
  }, [productId]);

  return (
    <ShowResult renderSuccess={submitSuccess} renderError={submitError}>
      {submitState => (
        <div className="wrapper">
          <div className="nui-form">
            <LoadingState state={schemaState}>
              {schema => (
                <WrappedForm
                  key="customer-prices"
                  schema={schema}
                  product={product}
                  initialData={init}
                  submitState={submitState}
                  auto={auto}
                />
              )}
            </LoadingState>
          </div>
        </div>
      )}
    </ShowResult>
  );
};
