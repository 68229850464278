import { capitalize, getDecimalCount, subcurrencies } from '~/utils';
import * as R from 'ramda';
import moment from 'moment-timezone';

/**
 * These models are meant to be used in select() and will be changed overtime when store updates,
 *  thus they should not store any other values other than input obj. Put the values into store.
 * Also, to avoid possible overriding issues of functions and getters,
 *  follow the convention that adds a '_' prefix to properties of subclasses.
 * Plus, we should not put here functionalities similiar to divisionsSelector, because no cache support.
 *  although we could memorize methods here, because they are invalidated when model stales.
 *  For method memorize, ref https://github.com/medikoo/memoizee/blob/master/lib/methods.js
 */

// TODO some sort of ACL to help with rendering the app

export const exists = x => !!R.prop('id', x);

class Base {
  constructor(...obj) {
    Object.assign(this, ...obj);
  }
}

export class OT extends Base {
  get priceDecimalCount() {
    if (!this.price || !this.price.step) return 0;
    return getDecimalCount(this.price.step);
  }

  get volumeDecimalCount() {
    if (!this.loading || !this.loading.qty) return 0;
    return getDecimalCount(this.loading.qty);
  }

  get etddesc() {
    if ('quarteretd' in this) return this.quarteretd;
    if ('etd' in this)
      return `${moment(this.etd.from).format('DD MMMM YYYY')} - ${moment(
        this.etd.to
      ).format('DD MMMM YYYY')}`;
    return null;
  }
}

export class Order extends OT {
  get currencies() {
    let r = [this.price];
    if (this.price.currencies) {
      r = r.concat(this.price.currencies);
    }
    return r;
  }

  get hascurrencies() {
    return this.currencies.length > 1;
  }

  getPriceObjByCur(cur) {
    const notfound = {
      currency: 'n/a',
      unit: 'n/a',
      val: 0,
    };
    if (!cur) return notfound;
    return R.find(R.propEq('currency', cur), this.currencies) || notfound;
  }
}
export class Trade extends OT {}

export class User extends Base {
  get _shortname() {
    if (this.firstname && this.firstname.length <= 7) {
      return capitalize(this.firstname);
    }
    return [this.firstname, this.lastname]
      .map(x => x.charAt(0).toUpperCase())
      .join(' ');
  }

  get _fullname() {
    return `${this.firstname} ${this.lastname}`;
  }
}

export class Route extends Base {
  get name() {
    const source = R.join(
      ' - ',
      R.filter(R.complement(R.isNil), [
        R.path(['source', 'name'], this),
        R.path(['destination', 'country', 'name'], this),
      ])
    );

    const dest = R.path(['destination', 'name'], this);

    return R.join(', ', [source, dest]);
  }
}

export class Product extends Base {
  get _desc() {
    let currencyCode = R.path(['currency', 'code'], this);
    const currencyFactor = R.path(['currency', 'factor'], this);
    if (currencyFactor !== 1) {
      currencyCode = R.path([currencyCode], subcurrencies);
    }
    const factor = R.path(['unit', 'factor'], this);
    const desc = R.path(['unit', 'desc'], this);
    const extra = factor && factor < 1 ? `${factor * 100}%` : '';
    return `${currencyCode} / ${desc} ${extra}`;
  }

  getDesc(loadingId, currency) {
    let currencyCode = R.path(['currency', 'code'], this);
    if (currency) currencyCode = currency;
    const currencyFactor = R.path(['currency', 'factor'], this);
    if (currencyFactor !== 1) {
      currencyCode = R.path([currencyCode], subcurrencies);
    }

    const factor = R.path(['unit', 'factor'], this);
    let desc = R.path(['unit', 'desc'], this);
    if (loadingId && R.path(['unit', 'name'], this) === 'packaging') {
      const loading = R.find(R.propEq('id', loadingId), this.loading);
      if (loading) desc = R.path(['packaging'], loading);
    }

    const extra = factor && factor < 1 ? `${factor * 100}%` : '';
    return [currencyCode, '/', desc, ...(extra ? [extra] : [])].join(' ');
  }

  get _volumeUnit() {
    const factor = R.path(['unit', 'factor'], this);
    const desc = R.path(['loadingunit', 'desc'], this);
    const extra = factor && factor < 1 ? ` (${factor * 100}%)` : '';
    return `${desc}${extra}`;
  }

  _getVolume(entVolume) {
    if (!entVolume) return 0;

    const factor = R.path(['unit', 'factor'], this);
    return factor * entVolume || 0;

    // the code below is just rubbish

    // const rel = R.path(['unit', 'rel'], this);
    // const loadingunitrel = R.path(['loadingunit', 'rel'], this);

    // return factor * (entVolume / (rel / loadingunitrel)) || 0;
  }

  _getValue(price, entVolume, loadingId) {
    if (!price || !entVolume) return 0;

    const unitrel = R.pathOr(1, ['unit', 'rel'], this);
    const loadingunitrel = R.pathOr(1, ['loadingunit', 'rel'], this);

    const volume = this._getVolume(entVolume);

    if (loadingId && R.path(['unit', 'name'], this) === 'packaging') {
      const loading = R.find(R.propEq('id', loadingId), this.loading);
      if (loading)
        return (
          (price * volume * R.pathOr(1, ['packing_load'], loading)) /
          R.pathOr(1, ['qty'], loading) /
          this.currency.factor
        );
    }

    return (
      (price * volume * loadingunitrel) / unitrel / this.currency.factor || 0
    );
  }

  get _isVolumeUnitDifferent() {
    if (R.path(['unit', 'name'], this) === 'packaging') return false;
    return (
      R.path(['loadingunit', 'name'], this) !== R.path(['unit', 'name'], this)
    );
  }
}
