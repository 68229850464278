import React, { useRef } from 'react';
import classnames from 'classnames';
import { useToggle, useClickAway } from 'react-use';
import { ToggleFavouriteFilter } from './filters/Favourites';

interface IPopout {
  children?: React.ReactNode;
  title?: React.ReactNode;
  className?: string;
  btn?: any;
  showFav?: boolean;
}
export default ({ title, className, btn = {}, children, showFav }: IPopout) => {
  const [open, toggle] = useToggle(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useClickAway(ref, event => {
    if (!document.getElementById('modal-root')?.contains(event.target as Node))
      toggle(false);
  });

  const btnProps = {
    className: 'icon-ellipsis',
    ...btn,
  };

  return (
    <div ref={ref} className={classnames('display-popout', className)}>
      {showFav && <ToggleFavouriteFilter />}
      <div className="boxed">
        <button
          {...btnProps}
          onClick={e => {
            e.stopPropagation();
            toggle();
          }}
        >
          {title && <span className="display-popout-label">{title}</span>}
        </button>
        {open && (
          <div className="relative">
            <div className="tiny-panel">{children}</div>
          </div>
        )}
      </div>
    </div>
  );
};
