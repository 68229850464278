import type { Product } from '~/models/models';

import React from 'react';
import { Alert } from 'Components/nui';
import * as Data from 'Components/Data';
import * as R from 'ramda';
import * as moment from 'moment-timezone';
import FNumber from 'Components/FNumber';
import { getDecimalCount } from '~/utils';
import { FormattedMessage } from 'react-intl';

interface ILoading {
  id: string;
  qty: number;
}

interface IProduct extends Product {
  loading?: ILoading[];
  step: number;
  currency?: { code: string };
  loadingunit: { desc: string };
  index?: {
    step: number;
    type: 'Modifier' | 'Multiplier';
    name: string;
    category: string;
    period: 'Day' | 'Week' | 'Month';
    offset: 'Prior to' | 'Of' | 'After';
  };
}

const getLoading = (product: IProduct, loading?: string) =>
  R.find(R.propEq('id', loading), product?.loading || []);

interface IFields {
  price?: number;
  startprice?: number;
  indexedprice?: 'true' | 'false';
  indexdate?: moment.Moment;
  volume?: number;
  totaldeliveries?: number;
  deliveryfrequency?: string;
  frometd?: moment.Moment;
  loading?: string;
}

interface IFormTotals {
  product: IProduct;
  isTender: boolean;
  fields: IFields;
}
export const FormTotals = (props: IFormTotals) => {
  const { product, isTender, fields = {} } = props;
  const {
    volume = 0,
    totaldeliveries = 1,
    deliveryfrequency,
    frometd,
    price,
    startprice,
  } = fields;
  const indexedprice = fields.indexedprice === 'true';
  const loading = getLoading(product, fields.loading);
  const date = fields.indexdate?.clone();

  const pricestep =
    product.index && indexedprice
      ? getDecimalCount(product.index.step)
      : getDecimalCount(product.step);

  const index = product.index;
  const pricetotal = price ?? startprice;
  const operator =
    index && indexedprice ? (
      index.type === 'Multiplier' ? (
        <>&times;</>
      ) : R.isNil(pricetotal) ? (
        <>&plusmn;</>
      ) : pricetotal >= 0 ? (
        <>&#43;</>
      ) : (
        <>&minus;</>
      )
    ) : (
      <></>
    );

  const totalvolume = volume * totaldeliveries;
  const totalvalue =
    indexedprice || !loading?.id
      ? 0
      : product._getValue(price, totalvolume, loading.id);

  return (
    <Alert hasicon type="info" className="order-pdv-summary">
      <h4>
        <FormattedMessage
          id="orderform-alert-summary-title"
          description="Title for Summary alert on new order form"
          defaultMessage="Summary"
        />
      </h4>
      <Data.List>
        {indexedprice && index && (
          <Data.Item
            title={
              <FormattedMessage
                id="orderform-alert-summary-index-label"
                description="Label for index label on Summary alert on new order form"
                defaultMessage="Index"
              />
            }
          >
            <Data.IndexSummary index={index} date={date} />
          </Data.Item>
        )}
        {isTender ? (
          <Data.Item
            title={
              <FormattedMessage
                id="orderform-alert-summary-start-price-label"
                description="Label for Start price label on Summary alert on new order form"
                defaultMessage="Start price"
              />
            }
          >
            {operator}
            {R.isNil(startprice) ? (
              <>Any</>
            ) : (
              <FNumber value={Math.abs(startprice)} decimalCount={pricestep} />
            )}{' '}
            <PriceUnit
              product={product}
              indexedprice={indexedprice}
              loading={loading}
            />
          </Data.Item>
        ) : (
          !R.isNil(price) && (
            <Data.Item
              title={
                <FormattedMessage
                  id="orderform-alert-summary-price-label"
                  description="Label for Price label on Summary alert on new order form"
                  defaultMessage="Price"
                />
              }
            >
              {operator}
              <FNumber value={Math.abs(price)} decimalCount={pricestep} />{' '}
              <PriceUnit
                product={product}
                indexedprice={indexedprice}
                loading={loading}
              />
            </Data.Item>
          )
        )}
        {totaldeliveries > 1 && volume > 0 && !!loading && (
          <>
            <Data.Item
              title={
                <FormattedMessage
                  id="orderform-alert-summary-deliveries-label"
                  description="Label for Deliveries label on Summary alert on new order form"
                  defaultMessage="Deliveries"
                />
              }
            >
              {totaldeliveries}&times;
              <FNumber
                value={product._getVolume(volume)}
                decimalCount={getDecimalCount(loading.qty)}
              />{' '}
              {product._volumeUnit}
              {deliveryfrequency && <> ({deliveryfrequency})</>}
            </Data.Item>
            {frometd && (
              <Data.Item
                title={
                  <FormattedMessage
                    id="orderform-alert-summary-deliveries-from-label"
                    description="Label for Deliveries from label on Summary alert on new order form"
                    defaultMessage="Deliveries from"
                  />
                }
              >
                {frometd.format('LL')}
              </Data.Item>
            )}
          </>
        )}
        {totalvolume > 0 && !!loading && (
          <Data.Item
            title={
              <FormattedMessage
                id="orderform-alert-summary-total-volume-label"
                description="Label for Total volume label on Summary alert on new order form"
                defaultMessage="Total volume"
              />
            }
          >
            <FNumber
              value={totalvolume}
              decimalCount={getDecimalCount(loading.qty)}
            />{' '}
            {product._volumeUnit}
          </Data.Item>
        )}
        {totalvalue > 0 && (
          <Data.Item
            title={
              <FormattedMessage
                id="orderform-alert-summary-total-value-label"
                description="Label for Total value label on Summary alert on new order form"
                defaultMessage="Total value"
              />
            }
          >
            <FNumber value={totalvalue} decimalCount={2} />{' '}
            {product.currency?.code}
          </Data.Item>
        )}
      </Data.List>
    </Alert>
  );
};

interface IPriceUnit {
  product: IProduct;
  loading?: ILoading;
  indexedprice: boolean;
}
const PriceUnit = ({ product, indexedprice, loading }: IPriceUnit) =>
  loading && !indexedprice ? (
    <span className="unit">{product.getDesc(loading.id, undefined)}</span>
  ) : (
    <></>
  );
