import React, { useEffect, useState } from 'react';
import { api } from '~/api';
import { mime } from '~/utils';
import * as R from 'ramda';
import Slider from 'react-slick';
import { useToggle } from 'react-use';
import { Carousel } from 'Components/nui';
import { FormattedMessage } from 'react-intl';

const sortById = R.sortBy(R.prop('id'));

export default ({ attachments, title = 'Documents' }) => {
  if (!attachments) return null;

  const [on, toggle] = useToggle(false);

  const [images, _] = useState(
    attachments.filter(file => file?.mimetype?.startsWith('image'))
  );

  const [initialSlide, setInitialSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: images.length > 4,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    useTransform: true,
    variableWidth: true,
    cssEase: 'cubic-bezier(0.770, 0.000, 0.175, 1.000)',
    prevArrow: (
      <button type="button">
        <span className="icon-angle-left"></span>
      </button>
    ),
    nextArrow: (
      <button type="button">
        <span className="icon-angle-right"></span>
      </button>
    ),
  };

  const [srcs, setSrcs] = useState([]);

  const sortedSrcs = sortById(srcs);
  const sortedAttachments = sortById(attachments);

  useEffect(() => {
    const fetch = async file => {
      const src = await api.getFileHref(file.href, file.mimetype);
      setSrcs(old => [
        ...old,
        {
          id: file.id,
          name: file.filename,
          src,
        },
      ]);
    };
    images.forEach(file => {
      fetch(file);
    });
  }, [images]);

  return (
    <>
      <h2>{title}</h2>

      <p className="light-gray pt-10 pb-10">
        <FormattedMessage
          id="orderdetails-documents-field-attachmentform-modal-helptext-seeattached"
          description="helptext for 'see attached documents' in Documents"
          defaultMessage="See attached document(s) for more information."
        />
      </p>

      {/* file class names: 
        icon-file-pdf (PDF)
        icon-file-excel (XLS, XLSX)
        icon-file-image (IMG)
        icon-file-word (DOC, DOCX)
        icon-doc-text (CSV)
      */}

      <ul className="documents-list pt-0">
        {sortedAttachments.map(file => (
          <li key={file.href}>
            <span
              className={R.pathOr(
                mime.default.className,
                [file.mimetype, 'className'],
                mime
              )}
              title={R.pathOr(mime.default.hint, [file.mimetype, 'hint'], mime)}
            />
            {file.mime}
            <a
              href={`#${file.filename}`}
              onClick={() =>
                api.getFile(file.href, file.filename, file.mimetype)
              }
            >
              <span title={file.filename}>{file.filename}</span>
            </a>
          </li>
        ))}
      </ul>

      {sortedSrcs.length > 0 && (
        <>
          <ul className="slides-container m-0 p-0 mb-40">
            <li>
              <Slider {...settings}>
                {sortedSrcs.map(item => (
                  <img
                    key={item.id}
                    src={item.src}
                    alt={item.name}
                    onClick={() => {
                      toggle();
                      setInitialSlide(sortedSrcs.indexOf(item));
                    }}
                  />
                ))}
              </Slider>
            </li>
          </ul>

          {on && (
            <Carousel
              images={sortedSrcs}
              initialSlide={initialSlide}
              close={() => toggle(false)}
            />
          )}
        </>
      )}
    </>
  );
};
