import React, { useRef } from 'react';
import { useClickAway, useToggle } from 'react-use';
import classNames from 'classnames';
import { Button } from 'Components/nui';
import { useStoreState } from 'easy-peasy';
import * as R from 'ramda';
import { FormattedMessage } from 'react-intl';

const DropdownPanel = ({ toggle }) => {
  const solutionColumns = useStoreState(state => state.auth.solutionColumns);
  const solutionSettings = useStoreState(state => state.auth.solutionSettings);

  const canCounter =
    R.pathOr(true, ['counterbids'], solutionSettings) ||
    R.pathOr(true, ['counteroffers'], solutionSettings);

  const canPrivate = !!solutionColumns.counterparty;

  return (
    <div className="panel mpkey-panel">
      <h2>
        <em className="icon-help inline-block">&#65279;</em>
        <FormattedMessage
          id="marketplace-legend-heading"
          description="Heading for marketplace legend in Marketplace"
          defaultMessage="What's what"
        />
      </h2>
      <p>
        <FormattedMessage
          id="marketplace-legend-helptext"
          description="Helptext for marketplace legend in Marketplace"
          defaultMessage="A visual guide to help you navigate around"
        />
      </p>

      <ul>
        <li>
          <span className="mp-help-icon has-qa">Q&amp;A</span>
          <FormattedMessage
            id="marketplace-legend-qa-text"
            description="text for qa icon in marketplace legend in Marketplace"
            defaultMessage="Includes questions from users"
          />
        </li>
        <li>
          <em className="mp-help-icon icon-comment has-comment"></em>
          <FormattedMessage
            id="marketplace-legend-comment-text"
            description="text for comment icon in marketplace legend in Marketplace"
            defaultMessage="Includes additional comments"
          />
        </li>
        {R.path(['attachments', 'type'], solutionColumns) === 'file' && (
          <li>
            <em className="mp-help-icon icon-attach-circled is-bundle"></em>
            <FormattedMessage
              id="marketplace-legend-attachments-text"
              description="text for attatchements icon in marketplace legend in Marketplace"
              defaultMessage="This order has attachments"
            />
          </li>
        )}
        {canCounter && (
          <>
            <li>
              <span className="mp-help-icon has-counters">2</span>
              <FormattedMessage
                id="marketplace-legend-counter-text"
                description="text for counter orders icon in marketplace legend in Marketplace"
                defaultMessage="There are counter orders"
              />
            </li>
            <li>
              <span className="mp-help-icon has-counters">
                <span className="my-counter"></span>2
              </span>
              <FormattedMessage
                id="marketplace-legend-my-counter-text"
                description="text for my-counter icon in marketplace legend in Marketplace"
                defaultMessage="You placed your counter order"
              />
            </li>
          </>
        )}
        <li>
          <span className="mp-help-icon my-offer-bid">
            <span></span>
          </span>
          <FormattedMessage
            id="marketplace-legend-my-order-text"
            description="text for my-order icon in marketplace legend in Marketplace"
            defaultMessage="Indicates your own order"
          />
        </li>
        <li>
          <span className="mp-help-icon broker-offer-bid">
            <span></span>
          </span>
          <FormattedMessage
            id="marketplace-legend-broker-order-text"
            description="text for broker-order icon in marketplace legend in Marketplace"
            defaultMessage="Indicates a broker order"
          />
        </li>
        {canPrivate && (
          <>
            <li>
              <span className="mp-help-icon private">p</span>
              <FormattedMessage
                id="marketplace-legend-private-order-text"
                description="text for private-order icon in marketplace legend in Marketplace"
                defaultMessage="Private offer/bid"
              />
            </li>
            <li>
              <span className="mp-help-icon private">pg</span>
              <FormattedMessage
                id="marketplace-legend-private-group-order-text"
                description="text for private-group-order icon in marketplace legend in Marketplace"
                defaultMessage="Private group offer/bid"
              />
            </li>
            {/* <li className="mt-15">
              <span className="mp-help-icon best-counter">10</span>
              Counter offer/bid placed
            </li>
            <li>
              <span className="mp-help-icon best-counter my">10</span>
              Your leading counter offer/bid
            </li> */}
          </>
        )}
      </ul>
      <Button
        htmlType="button"
        onClick={toggle}
        className="nui-button nui-small nui-secondary"
      >
        <FormattedMessage
          id="marketplace-legend-close-button"
          description="Button for closing marketplace legend in Marketplace"
          defaultMessage="Close"
        />
      </Button>
    </div>
  );
};

export default () => {
  const ref = useRef(null);
  const [opened, toggle] = useToggle(false);

  useClickAway(ref, () => {
    toggle(false);
  });

  return (
    <>
      <Button
        type="primary"
        onClick={toggle}
        className="icon-help show-mpkey-panel"
      >
        &#65279;
      </Button>
      <div
        ref={ref}
        className={classNames('dd', {
          opened,
        })}
      >
        {opened && <DropdownPanel toggle={toggle} />}
      </div>
    </>
  );
};
