import React, { useEffect } from 'react';
import { Form, Button, Loading } from 'Components/nui';
import { FormWrapper } from 'Components/form';
import { mergeSchema } from 'Components/form/utils';
import { ShowResult } from 'Components/Result';
import { promisify } from 'es6-promisify';
import * as R from 'ramda';
import classNames from 'classnames';
import { submitError } from 'Components/form/utils';
import moment from 'moment-timezone';
import { ClassicResult } from 'Components/Result';
import { useStoreState } from 'easy-peasy';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import QAVisibilityInfo from 'Components/QAVisibilityInfo';

function getQFormWrapper({ form }) {
  const fields = [
    {
      name: 'question',
      label: (
        <FormattedMessage
          id="marketrow-qa-field-label"
          description="Label for `Ask your question` field on qa in Marketrow"
          defaultMessage="Ask your question"
        />
      ),
    },
  ];
  const initialData = {};
  const schema = { question: { required: true, type: 'String' } };

  return new FormWrapper(form, initialData, mergeSchema(schema, fields));
}

function getAFormWrapper({ form }) {
  const fields = [
    {
      name: 'answer',
      label: (
        <FormattedMessage
          id="marketrow-qa-field-answer-question-text"
          description="Text for 'Answer this question' answer form in Marketrow"
          defaultMessage="Answer this question"
        />
      ),
    },
  ];
  const initialData = {};
  const schema = { answer: { required: true, type: 'String' } };

  return new FormWrapper(form, initialData, mergeSchema(schema, fields));
}

function QForm({ form, submitState, action }) {
  // TO BE COMFIRMED
  const qaprivate = useStoreState(
    state => state.auth.solutionSettings.qaprivate
  );

  const formWrapper = getQFormWrapper({ form });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);

  async function handleSubmit(e) {
    e.preventDefault();
    // Client-side validation
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }
    // Server-side validation
    submitState.setLoading(true);
    const data = {
      ...formWrapper.serialize(),
    };
    try {
      const result = await action(data);
      submitState.setResult(result);
      toast('Your question has been sent', {
        type: 'success',
      });
    } catch (err) {
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        err
      );
      if (errors) {
        formWrapper.setErrors(errors);
      } else {
        // report the unexcepted error
        submitState.setResult(err);
      }
    }
    submitState.setLoading(false);
  }

  return (
    <Form onSubmit={handleSubmit}>
      {formWrapper.render()}
      <QAVisibilityInfo qaprivate={qaprivate} />
      <Form.Item>
        <Button
          className="nui-secondary"
          htmlType="submit"
          loading={submitState.loading}
          disabled={submitState.loading}
        >
          <FormattedMessage
            id="marketrow-qa-button-ask"
            description="button for qa 'Ask' in Marketrow"
            defaultMessage="Ask"
          />
        </Button>
      </Form.Item>
    </Form>
  );
}

function AForm({ form, question, submitState, action }) {
  const qaprivate = useStoreState(
    state => state.auth.solutionSettings.qaprivate
  );

  const formWrapper = getAFormWrapper({ form });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);

  async function handleSubmit(e) {
    e.preventDefault();
    // Client-side validation
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }
    // Server-side validation
    submitState.setLoading(true);
    const data = {
      ...formWrapper.serialize(),
    };
    try {
      action(data, question);
      submitState.setResult({ result: 'Your answer has been published' });
      toast('Your answer has been published', {
        type: 'success',
      });
      submitState.setResult();
    } catch (err) {
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        err
      );
      if (errors) {
        formWrapper.setErrors(errors);
      } else {
        // report the unexcepted error
        submitState.setResult(err);
      }
    }
    submitState.setLoading(false);
  }

  return (
    <Form onSubmit={handleSubmit}>
      {formWrapper.render()}
      <QAVisibilityInfo qaprivate={qaprivate} />
      <Form.Item>
        <Button
          className={classNames('button', 'secondary')}
          htmlType="submit"
          loading={submitState.loading}
          disabled={submitState.loading}
        >
          <FormattedMessage
            id="marketrow-qa-button-answer"
            description="button for qa 'Answer' in Marketrow"
            defaultMessage="Answer"
          />
        </Button>
      </Form.Item>
    </Form>
  );
}

const WrappedQForm = Form.create()(QForm);
const WrappedAForm = Form.create()(AForm);

const submitQSuccess = (result, submitState) => (
  <ClassicResult title="">
    <p>
      <FormattedMessage
        id="marketrow-qa-helptext-question-submitted"
        description="helptext for `question submitted` on qa in Marketrow"
        defaultMessage="Thanks! Your question has been sent successfully!"
      />
    </p>
    <hr className="mt-15 mb-15" />
    <Button
      className="nui-reverse mb-5"
      onClick={() => {
        // Clear result page
        submitState.setResult();
      }}
    >
      <FormattedMessage
        id="marketrow-qa-button-anotherquestion"
        description="button for qa 'Another question?' in Marketrow"
        defaultMessage="Another question?"
      />
    </Button>
  </ClassicResult>
);

export function QA({
  askAction,
  answerAction,
  isClosed,
  isOwner,
  canAsk,
  canAnswer,
  order,
  loadQuestions,
}) {
  const orderQuestions = useStoreState(
    state => state.marketdepth.orderQuestions
  );

  const dateFormat = 'll HH:mm';

  useEffect(() => {
    loadQuestions();
  }, [order.id]);

  const questions = R.propOr([], order.id, orderQuestions);

  const canAskThis = canAsk();

  return (
    <>
      {questions === null ? (
        <Loading size="small" />
      ) : (
        questions
          ?.sort((a, b) => moment(a.created).diff(moment(b.created)))
          .map(question => (
            <dl className="q-and-a" key={question.id}>
              <dt className="question">
                <span className="date">
                  {moment(question.created).format(dateFormat)} -{' '}
                </span>
                <span className="type">Q</span> {question.question}
                {question.creator && (
                  <div className="author">
                    <FormattedMessage
                      id="marketrow-qa-helptext-generic-from"
                      description="helptext for `from` on qa in Marketrow"
                      defaultMessage="{creator} {division, select, null {} other {from {division_name}}}"
                      values={{
                        division: question.division,
                        division_name: question.division.name,
                        creator: question.creator.fullname,
                      }}
                    />
                  </div>
                )}
              </dt>
              {question.answers.length > 0 ? (
                question.answers.map(answer => (
                  <dd key={answer.id} className="answer">
                    <p>
                      <span className="date">
                        {moment(answer.created).format(dateFormat)} -{' '}
                      </span>
                      <span className="type">A</span> {answer.answer}
                      {answer.creator && (
                        <span className="author">
                          {answer.creator.fullname}
                        </span>
                      )}
                    </p>
                  </dd>
                ))
              ) : (
                <dd className="answer">
                  <span className="type">A</span>
                  <FormattedMessage
                    id="marketrow-qa-helptext-noanswer"
                    description="helptext for `No answer yet` on qa in Marketrow"
                    defaultMessage="No answer yet"
                  />
                </dd>
              )}
              {canAnswer(question) && (
                <dd className="answer">
                  <ShowResult
                    key={question.id}
                    renderError={submitError}
                    renderSuccess={(_, submitState) => (
                      <div className="qa-wrapper">
                        <div className="nui-form order-form">
                          <WrappedAForm
                            key={`form-a-${question.id}`}
                            question={question}
                            submitState={submitState}
                            action={answerAction}
                          />
                        </div>
                      </div>
                    )}
                  >
                    {submitState => (
                      <div className="qa-wrapper">
                        <div className="nui-form order-form">
                          <WrappedAForm
                            key={`form-a-${question.id}`}
                            question={question}
                            submitState={submitState}
                            action={answerAction}
                          />
                        </div>
                      </div>
                    )}
                  </ShowResult>
                </dd>
              )}
            </dl>
          ))
      )}

      {isClosed ? (
        <strong>
          <FormattedMessage
            id="qa-helptext-closed"
            description="helptext for `Q&A now closed` on qa"
            defaultMessage="Q&A is now closed"
          />
        </strong>
      ) : (
        <>
          {isOwner && questions?.length === 0 ? (
            <FormattedMessage
              id="marketrow-qa-helptext-noquestion"
              description="helptext for `No questions so far...` on qa in Marketrow"
              defaultMessage="No questions so far..."
            />
          ) : canAskThis ? (
            <ShowResult
              renderSuccess={submitQSuccess}
              renderError={submitError}
            >
              {submitState => (
                <div className="qa-wrapper">
                  <div className="nui-form order-form">
                    <WrappedQForm
                      key={`form-q-${order.id}`}
                      submitState={submitState}
                      action={askAction}
                    />
                  </div>
                </div>
              )}
            </ShowResult>
          ) : null}
        </>
      )}
    </>
  );
}
