import React from 'react';
import * as R from 'ramda';
import FNumber from 'Components/FNumber';
import { getDecimalCount } from '~/utils';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { unitsOfMeasure } from '~/utils';
import { useStoreState } from '~/store';
import Tooltip from '../nui/Tooltip';
import { FormattedMessage } from 'react-intl';
import { PriceIndex, AltIndexUnit } from 'Components/nui/PriceIndex';
import { Alert } from 'Components/nui';

export default ({ trade, myorder }) => {
  const solution = useStoreState(state => state.auth.solution);
  const hasFlatFee = solution?.exportprice?.flatfee;

  const showCommission = useStoreState(
    state =>
      R.path(['auth', 'solutionSettings', 'matchinglevel'], state) ===
      'commodity'
  );
  const volumeUnit = R.pathOr(
    trade.volume.unit,
    [trade.volume.unit],
    unitsOfMeasure
  );
  let data = [];

  if ('pid' in trade)
    data.push([
      'id',
      <FormattedMessage
        id="tradedetails-field-id-label"
        description="Label for 'ID' field on Trade details"
        defaultMessage="ID"
      />,
      <Link to={`/trades/${trade.id}`}>{trade.pid}</Link>,
    ]);

  if ('product' in trade)
    data.push([
      'product',
      <FormattedMessage
        id="tradedetails-field-product-label"
        description="Label for 'Product' field on Trade details"
        defaultMessage="Product"
      />,
      <Tooltip
        title={
          <span className="icon-tooltip">
            <span className="icon-info-circled" />
            <FormattedMessage
              id="tradedetails-field-product-helptext"
              description="Helptext for 'Product' field on Trade details"
              defaultMessage="View product details in new window"
            />
          </span>
        }
      >
        <Link to={`/products/${trade.product.id}/quick`}>
          {trade.product.name}
          <span className="nowrap valign-m">
            &#65279;
            <span className="icon-link-ext-alt" />
          </span>
        </Link>
      </Tooltip>,
    ]);

  if ('displayed' in trade.price)
    data.push([
      'price',
      <FormattedMessage
        id="tradedetails-field-agreedprice-label"
        description="Label for 'Agreed Price' field on Trade details"
        defaultMessage="Agreed Price"
      />,
      <>
        <FNumber
          value={trade.price.displayed.val}
          decimalCount={trade.priceDecimalCount}
        />{' '}
        {trade.price.unit.replace(
          trade.price.currency,
          trade.price.displayed.currency
        )}
      </>,
    ]);

  if (
    myorder &&
    R.hasPath(
      ['_price', R.path(['_price', 'currency'], myorder), 'tax'],
      myorder
    )
  ) {
    data.push([
      'tax',
      <FormattedMessage
        id="tradedetails-field-tax-label"
        description="Label for 'Tax' field on Trade details"
        defaultMessage="Tax"
      />,
      <>
        {R.pathOr('n/a', ['_price', myorder._price.currency, 'tax'], myorder)}{' '}
        <span>
          {R.pathOr(
            'n/a',
            ['_price', myorder._price.currency, 'unit'],
            myorder
          )}
        </span>
      </>,
    ]);
  }

  if ('exchange' in trade) data.push(['exchange', 'Exchange', trade.exchange]);

  if ('freight' in trade.price) {
    data.push([
      'freight',
      <FormattedMessage
        id="tradedetails-field-freight-label"
        description="Label for 'Freight' field on Trade details"
        defaultMessage="Freight"
      />,
      <>
        <FNumber
          value={trade.price.freight}
          decimalCount={getDecimalCount(trade.price.freight)}
        />{' '}
        {trade.price.unit}
      </>,
    ]);
  }

  if ('exportdocs' in trade.price)
    data.push([
      'exportdocs',
      <FormattedMessage
        id="tradedetails-field-exportdocsfee-label"
        description="Label for 'Export docs fee' field on Trade details"
        defaultMessage="Export docs fee"
      />,
      <>
        <FNumber
          value={trade.price.exportdocs}
          decimalCount={
            hasFlatFee
              ? getDecimalCount(trade.price.exportdocs)
              : getDecimalCount(trade.price.val)
          }
        />{' '}
        {hasFlatFee ? trade.price.currency : trade.price.unit}
      </>,
    ]);

  if ('premium' in trade.price) {
    data.push([
      'premium',
      <FormattedMessage
        id="tradedetails-field-premium-label"
        description="Label for 'Premium' field on Trade details"
        defaultMessage="Premium"
      />,
      <>
        <FNumber
          value={trade.price.premium}
          decimalCount={trade.priceDecimalCount}
        />{' '}
        {trade.price.unit}
      </>,
    ]);
  }

  if ('commission' in trade) {
    data.push([
      'commission',
      <FormattedMessage
        id="tradedetails-field-commission-label"
        description="Label for 'Commission' field on Trade details"
        defaultMessage="Commission"
      />,
      <>
        <FNumber
          value={trade.commission.commission}
          decimalCount={trade.priceDecimalCount}
        />{' '}
        {trade.commission.currency}
      </>,
      undefined,
      { 'data-testid': 'commission-row' },
    ]);
  }

  data.push([
    'value',
    <FormattedMessage
      id="tradedetails-field-value-label"
      description="Label for 'Value' field on Trade details"
      defaultMessage="Value"
    />,
    trade.value.val ? (
      <>
        <FNumber value={trade.value.val} decimalCount={2} />{' '}
        {trade.value.currency}
      </>
    ) : (
      <span>TBD (To be determined)</span>
    ),
  ]);

  if (R.path(['freightquote', 'quote', 'accepted'], trade)) {
    data.push([
      'discount',
      trade.discountavailable ? (
        <FormattedMessage
          id="tradedetails-field-discount-label"
          description="Label for 'Discount' field on Trade details"
          defaultMessage="Discount"
        />
      ) : (
        <FormattedMessage
          id="tradedetails-field-freight-label"
          description="Label for 'Freight' field on Trade details"
          defaultMessage="Freight"
        />
      ),
      <>
        <FNumber
          value={R.pathOr(0, ['freightquote', 'quote', 'rate'], trade)}
          decimalCount={trade.priceDecimalCount}
        />{' '}
        {trade.value.currency}
      </>,
    ]);
  }

  if ('exportdocs' in trade.price && 'total' in trade.value) {
    data.push([
      'exportdocs',
      <FormattedMessage
        id="tradedetails-field-valueexportdocs-label"
        description="Label for 'Total value with export docs' field on Trade details"
        defaultMessage="Total value with export docs"
      />,
      trade.value.val ? (
        <>
          <FNumber
            value={trade.value.total}
            decimalCount={trade.priceDecimalCount}
          />{' '}
          {trade.value.currency}
        </>
      ) : (
        <span>TBD (To be determined)</span>
      ),
    ]);
  }

  if ('shipped' in trade.value) {
    data.push([
      'totalvalue',
      <FormattedMessage
        id="tradedetails-field-totalvalue-label"
        description="Label for 'Total value {value}' field on Trade details"
        defaultMessage="Total value {value}"
        values={{ value: trade.incoterms && trade.incoterms.join(', ') }}
      />,
      <>
        <FNumber
          value={trade.value.shipped}
          decimalCount={trade.priceDecimalCount}
        />{' '}
        {trade.value.currency}
      </>,
    ]);
  }

  if ('displayed' in trade.value) {
    data.push([
      'displayed',
      <FormattedMessage
        id="tradedetails-field-agreedtotalvalue-label"
        description="Label for 'Agreed Total Value' field on Trade details"
        defaultMessage="Agreed Total Value"
      />,
      <>
        <FNumber
          value={trade.value.displayed.val}
          decimalCount={trade.priceDecimalCount}
        />{' '}
        {trade.value.displayed.currency}
      </>,
    ]);
  }

  if ('incoterm' in trade)
    data.push([
      'incoterm',
      <FormattedMessage
        id="tradedetails-field-incoterm-label"
        description="Label for 'Incoterm' field on Trade details"
        defaultMessage="Incoterm"
      />,
      trade.incoterm,
    ]);

  data.push([
    'createdat',
    <FormattedMessage
      id="tradedetails-field-createdat-label"
      description="Label for 'Created at' field on Trade details"
      defaultMessage="Created at"
    />,
    moment(trade.created).format('DD MMMM YYYY HH:mm'),
  ]);

  if ('etd' in trade)
    data.push([
      'etd',
      <FormattedMessage
        id="tradedetails-field-etd-label"
        description="Label for 'Estimated time of departure' field on Trade details"
        defaultMessage="Estimated time of departure"
      />,
      trade.deliveryfrequency
        ? moment(trade.etd.from).format('DD MMMM YYYY')
        : trade.etddesc,
      'stacked',
    ]);

  if ('eta' in trade)
    data.push([
      'eta',
      <FormattedMessage
        id="tradedetails-field-eta-label"
        description="Label for 'Estimated time of arrival' field on Trade details"
        defaultMessage="Estimated time of arrival"
      />,
      `${moment(trade.eta.eta).format('DD MMMM YYYY')} (ETD + ${
        trade.eta.days
      } days)`,
    ]);

  if ('deliveryfrequency' in trade)
    data.push([
      'deliveries',
      'Deliveries',
      <>
        {trade.totaldeliveries} {trade.deliveryfrequency} deliveries of{' '}
        <FNumber
          value={trade.volume.delivery_total}
          decimalCount={trade.volumeDecimalCount}
        />{' '}
        {volumeUnit}
      </>,
    ]);

  return (
    <>
      <>
        <hr className="m-0 mb-10 show-for-small-only" />
        <div className="trade-values">
          <div className="unit-detail price">
            <h4>
              <FormattedMessage
                id="tradedetails-field-price-label"
                description="Label for 'Price' field on Trade details"
                defaultMessage="Price"
              />
            </h4>
            <PriceIndex order={trade} />{' '}
            <AltIndexUnit order={trade} priceunit={trade.price.unit} />
          </div>

          <div className="unit-detail volume">
            <h4>
              <FormattedMessage
                id="tradedetails-field-volume-label"
                description="Label for 'Volume' field on Trade details"
                defaultMessage="Volume"
              />
            </h4>
            <FNumber
              value={trade.volume.val}
              decimalCount={trade.volumeDecimalCount}
            />{' '}
            <span className="unit">{volumeUnit}</span>
            {trade.quarteretd && (
              <div className="qrts">
                <FNumber
                  value={trade.volume.val / 3}
                  decimalCount={trade.volumeDecimalCount}
                />{' '}
                <FormattedMessage
                  id="tradedetails-field-price-monthly"
                  description="'monthly' label for 'Price' field on Trade details"
                  defaultMessage="monthly"
                />
              </div>
            )}
            {trade.totaldeliveries && (
              <>
                <span className="qrts inline-block border mt-7 ml--5 nowrap">
                  {trade.totaldeliveries} &times;{' '}
                  <FNumber
                    value={trade.volume.delivery_total}
                    decimalCount={trade.volumeDecimalCount}
                  />{' '}
                  {trade.deliveryfrequency}
                </span>
              </>
            )}
          </div>

          <div className="unit-detail value">
            <h4>
              <FormattedMessage
                id="tradedetails-field-value-label"
                description="Label for 'Value' field on Trade details"
                defaultMessage="Value"
              />
            </h4>
            {trade.value.val ? (
              <>
                <FNumber value={trade.value.val} decimalCount={2} />{' '}
                <span className="unit">{trade.value.currency}</span>
              </>
            ) : (
              <Tooltip
                placement="bottom"
                title={
                  <span className="icon-tooltip">
                    <span className="icon-info-circled" />
                    <strong className="all-black block">
                      To be determined
                    </strong>

                    <span>Index-based pricing date has not been reached.</span>
                  </span>
                }
              >
                <span className="tbd all-black">TBD</span>{' '}
                <span className="show-help inline-block" />
              </Tooltip>
            )}
          </div>

          {showCommission && trade.commission && (
            <div className="unit-detail value">
              <h4>
                <FormattedMessage
                  id="tradedetails-field-commission-label"
                  description="Label for 'commission' field on Trade details"
                  defaultMessage="Commission"
                />
              </h4>
              <FNumber
                value={trade.commission.commission}
                decimalCount={trade.priceDecimalCount}
              />{' '}
              <span className="unit">{trade.commission.currency}</span>
            </div>
          )}
        </div>
      </>
      {R.hasPath(['broker', 'name'], trade) && (
        <Alert className="mb-20">
          <strong>
            <FormattedMessage
              id="tradedetails-field-managedbybroker-label"
              description="Label for 'Managed by Broker' field on Trade Details"
              defaultMessage="Managed by Broker"
            />
          </strong>
          <br />
          {trade.broker.name}
        </Alert>
      )}
      <h3>
        {' '}
        <FormattedMessage
          id="tradedetails-header"
          description="Header for Trade details"
          defaultMessage="Trade details"
        />
      </h3>

      {trade.type && (
        <ul className="trade-details-list type-list">
          <li className="mb-20">
            <strong className="all-black">
              <FormattedMessage
                id="tradedetails-field-type-label"
                description="Label for 'Type' field on Trade details"
                defaultMessage="Type"
              />
            </strong>
            {trade.type === 'sale' && (
              <span className="type-offer inline mt-2">
                <FormattedMessage
                  id="tradedetails-field-type-sale"
                  description="Label for 'Sale' field on Trade details"
                  defaultMessage="Sale"
                />
              </span>
            )}
            {trade.type === 'purchase' && (
              <span className="type-bid inline mt-2">
                <FormattedMessage
                  id="tradedetails-field-type-purchase"
                  description="Label for 'Purchase' field on Trade details"
                  defaultMessage="Purchase"
                />
              </span>
            )}
          </li>
        </ul>
      )}

      <ul className="trade-details-list">
        {data.map(([key, message, value, className, attr]) => (
          <li key={key} className={className} {...attr}>
            <strong>{message}</strong>
            <span className={`item-${key}`}>{value}</span>
          </li>
        ))}
      </ul>
    </>
  );
};
