import React, { useState, useEffect, useRef } from 'react';
import Helmet from 'Components/Helmet';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import { Route } from 'react-router-dom';
import LiquidityForm from './LiquidityForm';
import { fetchSchema, editOrder, cancelOrders } from './fetch';
import { toast } from 'react-toastify';
import { Loading, Tooltip } from 'Components/nui';
import * as R from 'ramda';
import { Button, Modal } from 'Components/nui';
import { promisify } from 'es6-promisify';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import { useToggle } from 'react-use';

const LiquidityPage = () => {
  const [on, toggle] = useToggle(false);

  const [schemas, setSchemas] = useState<any>(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [userErrors, setUserErrors] = useState<{ [key: string]: any } | null>(
    null
  );
  const errorMsg = 'An error occurred. Please try again or refresh the page';
  const [orderScheduled, setOrderScheduled] = useState(false);

  const formRefs = useRef<any[]>([]);

  const handleSubmitAll = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    try {
      const formsKVPairs = R.toPairs(formRefs.current);
      const validationPromises = formsKVPairs.map(form => {
        return promisify(form[1].validateFieldsAndScroll)();
      });

      try {
        await Promise.all(validationPromises);
      } catch (error) {
        return;
      }
      const formDataArray = formsKVPairs.map(([formKey, formValue]) => {
        const fieldsValue = formValue.getFieldsValue();
        return {
          [`${formKey}_bid_price`]: fieldsValue.bid_price,
          [`${formKey}_offer_price`]: fieldsValue.offer_price,
          [`${formKey}_counteronly`]: fieldsValue.counteronly === 'true',
        };
      });

      const formsData = formDataArray.reduce((r, c) => Object.assign(r, c), {});

      setSubmitLoading(true);

      const postData = await editOrder(formsData);

      setSubmitLoading(false);

      if (postData) {
        setUserErrors(null);
        toast.success('Liquidity provider orders updated successfully.');
        setOrderScheduled(true);
      }
    } catch (error: any) {
      const errorResponse = error.response;
      if (errorResponse?.status === 400) {
        const errorDesc = errorResponse.data.errors[0].description;
        const errors = errorDesc?.product_orders;
        if (R.isNil(errors)) {
          toast.error(errorMsg);
        } else {
          setUserErrors(errors);
        }
      } else {
        toast.error(errorMsg);
      }
      setSubmitLoading(false);
    }
  };

  const handleCancelAction = async () => {
    setSubmitLoading(true);
    try {
      await cancelOrders();
    } catch (error) {
      console.log(error);
    }
    setOrderScheduled(false);
    toggle();
    setSubmitLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setPageLoading(true);
      try {
        const result = await fetchSchema();
        if (result) {
          const ordersFields = result.fields.product_orders.fields;
          const schemas = R.toPairs(ordersFields).map(([k, v]: any) => [
            k,
            v.fields,
          ]);
          setSchemas(schemas);
          setOrderScheduled(
            schemas?.some(
              (s: any) =>
                (s[1].info?.bid?.status &&
                  s[1].info.bid.status !== 'Cancelled') ||
                (s[1].info?.offer?.status &&
                  s[1].info.offer.status !== 'Cancelled')
            ) || false
          );
        } else {
          toast.error(errorMsg);
        }
      } catch (error) {
        toast.error(errorMsg);
      }

      setPageLoading(false);
    };

    fetchData();
  }, [orderScheduled]);

  return (
    <Route path="/lp-overview">
      <>
        <Helmet>
          <title>LP Overview</title>
        </Helmet>
        <div className="lp-overview">
          <div className="full-page-header">
            <h1 className="inline mt-10 mr-20 mb-0">LP Overview</h1>
            <div className="liquidity-status">
              {pageLoading ? null : orderScheduled ? (
                <div className="liquidity-scheduled-info">
                  {schemas.some(
                    (s: any) =>
                      s[1].info?.bid?.status === 'Pending' ||
                      s[1].info?.offer?.status === 'Pending'
                  ) ? (
                    <span className="liquidity-status-scheduled">
                      Scheduled
                    </span>
                  ) : (
                    <span className="liquidity-status-active">Active</span>
                  )}
                  <span className="liquidity-time-info">
                    {schemas?.[0]?.[1]?.info?.bid && (
                      <>
                        {schemas[0][1].info.bid.scheduledtime &&
                          `From ${moment(
                            schemas[0][1].info.bid.scheduledtime
                          ).format('LLL')} `}
                        {schemas[0][1].info.bid.expiry &&
                          `To ${moment(schemas[0][1].info.bid.expiry).format(
                            'LLL'
                          )}`}
                      </>
                    )}
                  </span>
                </div>
              ) : (
                <Tooltip
                  title={
                    <span className="icon-tooltip">
                      <span className="icon-info-circled"></span>
                      No order scheduled yet, fill the form and click the
                      Schedule button to schedule your orders
                    </span>
                  }
                >
                  <span className="status-traded">To schedule</span>
                </Tooltip>
              )}
            </div>

            <ul className="nui-bc right pt-20">
              <li>
                <BreadcrumbRoot active="lp-overview" />
              </li>
              <li>LP Overview</li>
            </ul>
          </div>

          <div className="full-page-content liquidity-page">
            {pageLoading ? (
              <Loading size="large" />
            ) : (
              schemas?.length && (
                <div className="liquidity-content">
                  {schemas.map((s: any[]) => {
                    const [k, v] = s;
                    return (
                      <div
                        className="liquidity-individual-form-container"
                        key={k}
                      >
                        <LiquidityForm
                          key={k}
                          schema={v}
                          ref={el =>
                            Object.assign(formRefs.current, { [k]: el })
                          }
                          errors={userErrors?.[k]}
                          orderScheduled={orderScheduled}
                        />
                      </div>
                    );
                  })}
                  <div className="button-set liquidity-submit-button">
                    <Button
                      htmlType="submit"
                      type="primary"
                      disabled={submitLoading}
                      loading={submitLoading}
                      onClick={handleSubmitAll}
                      className="mr-0"
                    >
                      {orderScheduled ? (
                        <FormattedMessage
                          id="lp-form-button-update-label"
                          description="Label for `Update` button on LP form"
                          defaultMessage="Update"
                        />
                      ) : (
                        <FormattedMessage
                          id="lp-form-button-schedule-label"
                          description="Label for `Schedule` button on LP form"
                          defaultMessage="Schedule"
                        />
                      )}
                    </Button>

                    {orderScheduled && (
                      <>
                        <Button
                          htmlType="button"
                          type="simple"
                          onClick={toggle}
                        >
                          <FormattedMessage
                            id="lp-form-button-cancel-label"
                            description="Label for `Cancel` button on LP form"
                            defaultMessage="Cancel orders"
                          />
                        </Button>
                        {on && (
                          <Modal close={toggle} size="small">
                            <h2>
                              <FormattedMessage
                                id="lp-form-cancel-modal-title"
                                description="Title for `Are you sure` on LP form cancel modal"
                                defaultMessage="Are you sure?"
                              />
                            </h2>
                            <hr />
                            <Button
                              onClick={handleCancelAction}
                              type="primary"
                              disabled={submitLoading}
                            >
                              <FormattedMessage
                                id="lp-form-cancel-modal-confirm-text"
                                description="Text for `Yes, proceed` on LP form cancel modal"
                                defaultMessage="Yes, proceed"
                              />
                            </Button>

                            <Button onClick={toggle} type="simple">
                              <FormattedMessage
                                id="lp-form-cancel-modal-cancel-text"
                                description="Text for `Cancel` on LP form cancel modal"
                                defaultMessage="Cancel"
                              />
                            </Button>
                          </Modal>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </>
    </Route>
  );
};

export default LiquidityPage;
