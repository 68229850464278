import type {
  IToken,
  IRequestConfig,
  IPolledConfig,
  IGetResponse,
  IAnyResponse,
  IPollResponse,
  IRemoveResponse,
  IResponseModify,
} from 'fetch';
import { authService } from '~/services/auth-service';
import { api } from '~/api';
import qs from 'qs';

type ContentType = Record<'Content-Type', string> | {};

const TOKEN_KEY = '__t';
const BASE_URL = import.meta.env.VITE_APP_API_BASE;

const RFC2822 = 'ddd, DD MMM YYYY HH:mm:ss [GMT]';

export class TokenError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'token-error';
  }
}

async function token(count = 1) {
  if (count === 10) throw new TokenError('Could not get token');

  const tk = localStorage.getItem(TOKEN_KEY);
  if (tk) return JSON.parse(tk) as IToken;

  return new Promise<IToken>(
    resolve =>
      void setTimeout(() => {
        const t = localStorage.getItem(TOKEN_KEY);
        if (t) resolve(JSON.parse(t) as IToken);
        resolve(token(count + 1));
      }, 100)
  );
}

async function refresh() {
  try {
    const token = await api.refresh();
    authService.saveToken(token);
    api.updateToken(token);
    return true;
  } catch {
    //
  }
  return false;
}

export async function request({
  url,
  params,
  data,
  formdata,
  headers = {},
  ...props
}: IRequestConfig): Promise<IAnyResponse> {
  const tk = await token();
  const p = qs.stringify(params, { indices: false });
  const u = BASE_URL + url + (p.length ? '?' + p : '');

  const Authorization = `${tk.token_type} ${tk.access_token}`;
  const contentType: ContentType = formdata
    ? {}
    : { 'Content-Type': 'application/json' };

  const response = await fetch(u, {
    headers: {
      Authorization,
      ...contentType,
      ...headers,
    },
    credentials: 'include',
    body: formdata || JSON.stringify(data),
    ...props,
  });

  if (response.status === 401) {
    const result = await refresh();
    if (!result) throw new Error('OAuth refresh error');
    return await request({
      url,
      params,
      data,
      formdata,
      headers,
      ...props,
    });
  }

  return response as IAnyResponse;
}

export async function get<D>(config: IRequestConfig): Promise<IGetResponse<D>> {
  return (await request(config)) as IGetResponse<D>;
}

export async function poll<D>({
  since,
  headers = {},
  ...config
}: IPolledConfig): Promise<IPollResponse<D>> {
  const ims = since.clone().utc().format(RFC2822);
  return (await request({
    ...config,
    headers: { 'If-Modified-Since': ims, ...headers },
  })) as IPollResponse<D>;
}

export async function modify<D>(
  config: IRequestConfig
): Promise<IResponseModify<D>> {
  return (await get<D>(config)) as IResponseModify<D>;
}

export async function remove<D>({
  method = 'delete',
  ...config
}: IRequestConfig): Promise<IRemoveResponse<D>> {
  return (await request({ method, ...config })) as IRemoveResponse<D>;
}
