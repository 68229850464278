import React, { useRef } from 'react';
import { useToggle, useClickAway } from 'react-use';
import classnames from 'classnames';
import * as R from 'ramda';
import Button from '../Button';
import Checkbox from '../Checkbox';

interface IDropCheck<T> {
  name: string;
  options: [T, string][];
  enabledKeys?: T[];
  value: T[] | undefined;
  onChange: (name: string, values: T[] | undefined) => void;
  title?: string;
  selectAll?: string;
  itemType?: string;
}
export default function DropCheck<T>({
  name,
  options,
  enabledKeys,
  value = [],
  onChange,
  title = '',
  itemType = 'options',
}: IDropCheck<T>) {
  const [visible, toggleVisible] = useToggle(false);
  const allChecked = value?.length === options.length;
  const noneChecked = value?.length === 0;
  const allOptionDisabled = enabledKeys && enabledKeys.length === 0;

  const isChecked = (option: T) => R.includes(option, value);

  const ref = useRef(null);
  useClickAway(ref, () => toggleVisible(false));

  const enabledOptions = enabledKeys
    ? options.filter(item => enabledKeys.includes(item[0]))
    : options;

  const allEnabledChecked = value.length === enabledOptions.length;

  options = R.sort((a, b) => {
    if (a[1] === 'Others') return 1;
    if (b[1] === 'Others') return -1;
    return a[1].localeCompare(b[1]);
  }, options);

  return (
    <div ref={ref} className="dropcheck">
      <Button htmlType="button" className="showoptions" onClick={toggleVisible}>
        <span className="total">
          {allChecked
            ? `All ${itemType} selected`
            : value.length === 0
            ? `No ${itemType} selected`
            : value.length === 1
            ? R.pathOr('', [1], R.find(R.propEq(0, value[0]), options))
            : `${value.length} out of ${options.length} ${itemType} selected`}
        </span>
        <span className="arrow" />
      </Button>
      <div className={classnames('options', { visible })}>
        <div className="check-field option-all mt-5">
          <Checkbox
            name={name}
            checked={allChecked}
            disabled={allOptionDisabled}
            onChange={() => {
              onChange(
                name,
                allEnabledChecked ? [] : enabledOptions.map(([value]) => value)
              );
            }}
            indeterminate={!allChecked && !noneChecked}
          >
            {title}
          </Checkbox>
        </div>
        {visible &&
          options.map(([option, title]) => {
            const disabled = enabledKeys
              ? !enabledKeys.includes(option)
              : false;
            return (
              <div key={title} className="option check-field">
                <Checkbox
                  name={option}
                  checked={isChecked(option)}
                  disabled={disabled}
                  onChange={() => {
                    onChange(
                      name,
                      isChecked(option)
                        ? R.reject(R.equals(option), value)
                        : [...value, option]
                    );
                  }}
                >
                  {title}
                </Checkbox>
              </div>
            );
          })}
      </div>
    </div>
  );
}
