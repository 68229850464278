import type { Tender, Order } from '~/models/tenders';
import type { FormComponentProps } from 'antd/lib/form';
import React, { useEffect, useState } from 'react';
import { withRouteX, withPreload, routeUrl } from '~/router';
import {
  normalizeChoices,
  mergeSchema,
  MAX_SAFE_INTEGER,
  MIN_SAFE_INTEGER,
} from 'Components/form/utils';
import * as Data from 'Components/Data';
import { FormWrapper } from 'Components/form';
import { Button, Form, Alert, Tooltip } from 'Components/nui';
import { promisify } from 'es6-promisify';
import { toast } from 'react-toastify';
import {
  capitalize,
  inArrayIf,
  getDecimalCount,
  getColumnFieldLabel,
  invertOrderType,
  SolutionColumns,
} from '~/utils';
import { Value } from '~/models/utils';
import * as R from 'ramda';
import * as service from '~/services/tenders';
import Access from 'Components/Access';
import { useHistory } from 'react-router-dom';
import { ResAlert } from './components';
import { Eta } from './Eta';
import { useStoreState } from '~/store';
import FNumber from 'Components/FNumber';
import FilesField from 'Components/form/FilesField';
import { FormattedMessage } from 'react-intl';

interface IConfig extends FormComponentProps {
  schema: any;
  tender: Tender;
  order: Order;
}
function useFormWrapper({ form, schema, tender, order }: IConfig) {
  const solutionColumns = useStoreState(state => state.auth.solutionColumns);
  const solution = useStoreState(state => state.auth.solution);

  const mine = order.counters.mine;
  const [files, setFiles] = useState([]);

  const priceLabel = order.startprice.index?.type.toLowerCase() || 'price';

  useEffect(() => {
    const field: number = form.getFieldValue('price');
    const price = mine?.price.val;
    if (price) {
      if (order.ordertype === 'offer' && price > field)
        form.setFields({ price: { value: price } });
      else if (order.ordertype === 'bid' && field > price)
        form.setFields({ price: { value: price } });
    }
  }, [mine?.price.val]);

  const exportIntent =
    form.getFieldValue('exportable') ?? !!schema?.exportable?.value;

  const exportdocsRequired =
    form.getFieldValue('exportdocs') ?? !!schema?.exportdocs?.value;

  const heatTreatedPalletsRequired =
    form.getFieldValue('heattreatedpallets') ??
    !!schema?.heattreatedpallets?.value;

  const variantId = form.getFieldValue('variant') || mine?.variant?.id;
  const variant = schema.variant?.choices.find((v: any) => v.id === variantId);
  const offset: number | undefined = variant?.minprice;
  const variantPrice: number = variant?.current;
  const variantLoading = order.variants.find(
    (v: any) => v.id === variantId
  )?.loading;

  const autobid = tender.willStart()
    ? true
    : form.getFieldValue('autobid') ?? mine?.isAuto;

  const getChoicesByDivision =
    ({ choices }: any) =>
    () => {
      const divisionId = form.getFieldValue('division');
      return normalizeChoices(
        Array.isArray(choices) ? choices : R.propOr([], divisionId, choices)
      );
    };

  const showVariant = !!variantPrice;
  const showNextprice = order.showNextprice || offset;

  const currentPrice =
    variantPrice ??
    mine?.price.val ??
    order.nextprice.val ??
    order.startprice.val;
  const indexType = order.startprice.index?.type;

  const [floor, ceil] =
    indexType === 'Multiplier'
      ? [order.startprice.step, MAX_SAFE_INTEGER] // I know, it's redundant
      : indexType === 'Modifier'
      ? [MIN_SAFE_INTEGER, MAX_SAFE_INTEGER]
      : [order.startprice.step, MAX_SAFE_INTEGER];

  const variantprices =
    schema.variant?.choices.map((v: any) => v.current || v.minprice) || [];
  const minvariant = Math.min(...variantprices) ?? floor;

  const [minprice, maxprice] =
    order.ordertype === 'offer'
      ? [Math.min(minvariant, currentPrice ?? floor), ceil]
      : [Math.min(minvariant, floor), currentPrice ?? ceil];

  const orderVol = order.stripvolume?.delivered?.val ?? order.volume.val;
  const initialVol = schema.volume?.val ?? orderVol;

  const fields = [
    {
      name: 'division',
      label: (
        <FormattedMessage
          id="tender-counter-form-division-label"
          description="Label for Division in tender counter form"
          defaultMessage="Division"
        />
      ),
      choices: normalizeChoices,
    },
    ...inArrayIf(schema.variant?.choices, {
      render: () => (
        <React.Fragment key="product-variant-hint">
          <h3 className="fs-1 m-0">
            <FormattedMessage
              id="tender-counter-form-product-variants-heading"
              description="Heading for `Product variants` in tender counter form"
              defaultMessage="Product variants"
            />
          </h3>
          <p className="light-gray smaller">
            <FormattedMessage
              id="tender-counter-form-product-variants-text"
              description="Text for `Product variants` in tender counter form"
              defaultMessage="Product variants are product sub-categories. Select the relevant product variant you want to bid on and note the price. The price of product variants will differ from price of the base product. Note: Your minimum bid amount cannot be lower than the price."
            />
          </p>
        </React.Fragment>
      ),
    }),
    {
      name: 'variant',
      label: (
        <FormattedMessage
          id="tender-counter-form-variant-label"
          description="Label for variant in tender counter form"
          defaultMessage="Select a product variant"
        />
      ),
      choices: (variant: any) =>
        variant?.choices?.map(({ id, name }: any) => ({
          value: id,
          label: name,
          key: id,
        })),
    },
    ...inArrayIf(!!variantLoading, {
      render: () => (
        <div>
          <strong>
            <FormattedMessage
              id="tender-details-summary-field-loading-details"
              description="Field for `Loading details` in summary in tender details page"
              defaultMessage="Loading details"
            />
          </strong>
          <div>{variantLoading}</div>
          <hr className="mt-6" />
        </div>
      ),
    }),
    {
      render() {
        return (
          (showVariant || showNextprice) && (
            <React.Fragment key="price-hint">
              <Alert type="info" hasicon className="bid-price-info">
                {showVariant && (
                  <div className="variant-equivalent">
                    <FormattedMessage
                      id="tender-counter-form-price-hint-variant"
                      description="Text for price-hint product variant section in tender counter form"
                      defaultMessage="Your equivalent current {counterType, select, offer {offer} bid {bid} other {{counterType}}} price for this variant is"
                      values={{ counterType: tender.ordertype.counter }}
                    />
                    <Data.PriceTicker
                      value={order.nextprice.new({ val: variantPrice })}
                      title=""
                    />
                  </div>
                )}
                {showNextprice && (
                  <div key="min-bid" className="min-bid">
                    <div className="clm ttl">
                      <strong className="all-black">
                        <FormattedMessage
                          id="tender-counter-form-price-hint-next-price"
                          description="Text for price-hint next price section in tender counter form"
                          defaultMessage="{counterType, select, bid {Minimum} other {Maximum}} next {counterType, select, offer {offer} bid {bid} other {{counterType}}}"
                          values={{ counterType: tender.ordertype.counter }}
                        />
                      </strong>
                    </div>
                    <div className="clm price">
                      <Data.PriceTicker
                        title=""
                        value={order.nextprice.new({
                          val: offset ?? order.nextprice.val,
                        })}
                      />
                    </div>
                  </div>
                )}
              </Alert>
              <hr />
              {mine?.rank === 1 && (
                <Alert className="mb-20" type="success" hasicon>
                  <FormattedMessage
                    id="tender-counter-form-price-hint-leading"
                    description="Text for price-hint leading section in tender counter form"
                    defaultMessage="You are leading"
                  />
                </Alert>
              )}
              <ResAlert reserve={order.reserve} />
            </React.Fragment>
          )
        );
      },
    },

    autobid
      ? {
          label: tender.started() ? (
            <FormattedMessage
              id="tender-counter-form-autobid-label"
              description="label for autobid in tender counter form"
              defaultMessage="{counterType, select, bid {Maximum} other {Minimum}} auto-{counterType, select, offer {offer} bid {bid} other {{counterType}}}"
              values={{ counterType: tender.ordername.counter }}
            />
          ) : (
            <Tooltip
              placement="bottom"
              title={
                <span className="icon-tooltip">
                  <span className="icon-info-circled" />
                  <p className="light-gray smaller">
                    <FormattedMessage
                      id="tender-counter-form-autobid-tooltip"
                      description="Tooltip for autobid in tender counter form"
                      defaultMessage="Auto {counterType, select, offer {offer} bid {bid} other {{counterType}}} keeps you in the lead by automatically placing a {orderType, select, offer {higher} other {lower}} {counterType, select, offer {offer} bid {bid} other {{counterType}}} whenever you're out-ranked. You choose the {orderType, select, offer {maximum} other {minimum}} amount you are willing to {orderType, select, offer {pay} other {sell for}} - and auto {counterType, select, offer {offer} bid {bid} other {{counterType}}} does the rest."
                      values={{
                        counterType: tender.ordername.counter,
                        orderType: tender.ordertype.order,
                      }}
                    />
                  </p>
                </span>
              }
            >
              <span className="tbd all-black">
                <FormattedMessage
                  id="tender-counter-form-published-autobid-label"
                  description="label for autobid in publised tender counter form"
                  defaultMessage="{orderType, select, bid {Maximum} other {Minimum}} auto-{orderType, select, offer {offer} bid {bid} other {{orderType}}}"
                  values={{ orderType: tender.ordername.counter }}
                />
              </span>
              <span className="show-help inline-block" />
            </Tooltip>
          ),
          name: 'autobid_price',
          step: order.startprice.step,
          min: minprice,
          max: maxprice,
          initialValue:
            schema.autobid_price?.value ??
            mine?.autobid?.val ??
            order.nextprice.val,
        }
      : {
          name: 'price',
          label: !tender.started() ? (
            <FormattedMessage
              id="tender-counter-form-published-price-label"
              description="label for price in tender counter form"
              defaultMessage="Starting {priceLabel, select, modifier{modifier} multiplier{multiplier} price{price} other{{priceLabel}}}"
              values={{ priceLabel: priceLabel }}
            />
          ) : (
            <FormattedMessage
              id="tender-counter-form-price-label"
              description="label for price in tender counter form"
              defaultMessage="{priceLabel, select, modifier{Modifier} multiplier{Multiplier} price{Price} other{{priceLabel}}}"
              values={{ priceLabel: priceLabel }}
            />
          ),
          min: minprice,
          max: maxprice,
          step: order.startprice.step,
          initialValue: order.startprice.val,
        },

    tender.started() && {
      name: 'autobid',
      label: (
        <Tooltip
          placement="bottom"
          title={
            <span className="icon-tooltip">
              <span className="icon-info-circled" />
              <p className="light-gray smaller">
                {schema?.autobid?.disabled ? (
                  <FormattedMessage
                    id="tender-counter-form-published-autobid-tooltip"
                    description="Tooltip for published autobid in tender counter form"
                    defaultMessage="Auto {counterType, select, offer {offer} bid {bid} other {{counterType}}} is only available if the {orderType, select, offer {offer} bid {bid} other {{orderType}}} has a start price or if another {counterType, select, offer {offer} bid {bid} other {{counterType}}} has been placed."
                    values={{
                      counterType: tender.ordername.counter,
                      orderType: tender.ordername.order,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="tender-counter-form-autobid-tooltip"
                    description="Tooltip for autobid in tender counter form"
                    defaultMessage="Auto {counterType, select, offer {offer} bid {bid} other {{counterType}}} keeps you in the lead by automatically placing a {orderType, select, offer {higher} other {lower}} {counterType, select, offer {offer} bid {bid} other {{counterType}}} whenever you're out-ranked. You choose the {orderType, select, offer {maximum} other {minimum}} amount you are willing to {orderType, select, offer {pay} other {sell for}} - and auto {counterType, select, offer {offer} bid {bid} other {{counterType}}} does the rest."
                    values={{
                      counterType: tender.ordername.counter,
                      orderType: tender.ordertype.order,
                    }}
                  />
                )}
              </p>
            </span>
          }
        >
          <div
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <span className="tbd all-black">
              <FormattedMessage
                id="tender-counter-form-auto-price-label"
                description="label for autobid price in tender counter form"
                defaultMessage="Auto-{orderType, select, offer {offer} bid {bid} other {{orderType}}}"
                values={{ orderType: tender.ordername.counter }}
              />
            </span>{' '}
            <span className="show-help inline-block" />
          </div>
        </Tooltip>
      ),
      type: 'Switch',
      props: {
        disabled: schema?.autobid?.disabled,
      },
      initialValue: schema?.autobid?.value ?? false,
    },
    {
      name: 'volume',
      label: order.stripvolume ? (
        <FormattedMessage
          id="tender-counter-form-strip-volume-label"
          description="label for strip-order volume in tender counter form"
          defaultMessage="Volume per delivery"
        />
      ) : (
        <FormattedMessage
          id="tender-counter-form-volume-label"
          description="label for volume in tender counter form"
          defaultMessage="Volume"
        />
      ),
      max: orderVol,
      min: order.loading.qty,
      step: order.loading.qty,
      initialValue: initialVol,
      required: true,
      props: { disabled: schema.volume.disabled },
    },

    {
      name: 'exportable',
      label: getColumnFieldLabel(
        invertOrderType(order.ordertype),
        'exportable'
      )(solutionColumns as SolutionColumns) ?? (
        <FormattedMessage
          id="tender-counter-form-exportable-label"
          description="label for Exportable in tender counter form"
          defaultMessage="Exportable"
        />
      ),
      choices: [
        {
          key: 'yes',
          value: true,
          label: (
            <FormattedMessage
              id="orderform-field-generic-yes-label"
              description="Label for 'Yes' radio button on tender counter form"
              defaultMessage="Yes"
            />
          ),
        },
        {
          key: 'no',
          value: false,
          label: (
            <FormattedMessage
              id="orderform-field-generic-no-label"
              description="Label for 'No' radio button on tender counter form"
              defaultMessage="No"
            />
          ),
        },
      ],
      type: 'RadioSelect',
      initialValue: schema?.exportable?.value,
    },

    ...inArrayIf(
      !('exportable' in schema) ||
        (exportIntent && order.ordertype === 'offer'),
      {
        name: 'exportdocs',
        label: getColumnFieldLabel(
          invertOrderType(order.ordertype),
          'exportdocs'
        )(solutionColumns as SolutionColumns) ?? (
          <FormattedMessage
            id="tender-counter-form-export-docs-label"
            description="Label for 'Export docs' radio button on tender counter form"
            defaultMessage="Export docs"
          />
        ),
        choices: [
          {
            key: 'yes',
            value: true,
            label: (
              <FormattedMessage
                id="orderform-field-generic-yes-label"
                description="Label for 'Yes' radio button on tender counter form"
                defaultMessage="Yes"
              />
            ),
          },
          {
            key: 'no',
            value: false,
            label: (
              <FormattedMessage
                id="orderform-field-generic-not-required-label"
                description="Label for 'Not Required' radio button on new order form"
                defaultMessage="Not required"
              />
            ),
          },
        ],
        type: 'RadioSelect',
        required: true,
        initialValue: schema?.exportdocs?.value,
      }
    ),

    {
      render: () =>
        exportIntent &&
        solution?.exportprice?.flatfee &&
        exportdocsRequired && (
          <Alert
            hasicon
            type="info"
            className="alert-fee"
            key="alert-exportfee"
          >
            <FormattedMessage
              id="tender-counter-form-export-intent-text"
              description="Text for export intent field on tender counter form"
              defaultMessage="Please note a <strong>{price} {currency}</strong> flat fee will be added to this order."
              values={{
                strong: chunks => (
                  <strong style={{ width: 'auto' }}>{chunks}</strong>
                ),
                price: solution.exportprice.price,
                currency: solution.currency,
              }}
            />
            {solution.exportprice.file && (
              <a
                href={solution.exportprice.file.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage
                  id="tender-counter-form-export-intent-link"
                  description="Link for export intent field on tender counter form"
                  defaultMessage="View more information here"
                />
              </a>
            )}
          </Alert>
        ),
    },

    ...inArrayIf(exportIntent, {
      name: 'heattreatedpallets',
      label: (
        <FormattedMessage
          id="tender-counter-form-heat-treated-pallets-label"
          description="Label for Heat-treated pallets field on tender counter form"
          defaultMessage="Heat-treated pallets required"
        />
      ),
      choices: [
        {
          key: 'yes',
          value: true,
          label: (
            <FormattedMessage
              id="orderform-field-generic-yes-label"
              description="Label for 'Yes' radio button on tender counter form"
              defaultMessage="Yes"
            />
          ),
        },
        {
          key: 'no',
          value: false,
          label: (
            <FormattedMessage
              id="orderform-field-generic-not-required-label"
              description="Label for 'Not Required' radio button on new order form"
              defaultMessage="Not required"
            />
          ),
        },
      ],
      type: 'RadioSelect',
      required: true,
      initialValue: schema?.heattreatedpallets?.value,
    }),

    {
      render: () =>
        exportIntent &&
        heatTreatedPalletsRequired &&
        form.getFieldValue('volume') > 0 &&
        solution?.exportprice?.heattreatedfee && (
          <Alert
            hasicon
            type="info"
            className="pallets-request"
            key="pallets-request"
          >
            <h3>
              <FormattedMessage
                id="tender-counter-form-heat-treated-pallets-heading"
                description="Heading for Heat-treated pallets section on tender counter form"
                defaultMessage="Heat-treated pallets summary"
              />
            </h3>
            <blockquote>
              <FNumber
                value={parseFloat(solution.exportprice.heattreatedfee)}
                decimalCount={getDecimalCount(
                  parseFloat(solution.exportprice.heattreatedfee)
                )}
                unit={solution.currency}
              />{' '}
              &times;{' '}
              <FNumber
                value={form.getFieldValue('volume')}
                decimalCount={getDecimalCount(form.getFieldValue('volume'))}
              />{' '}
              ={' '}
              <FNumber
                value={
                  parseFloat(solution.exportprice.heattreatedfee) *
                  form.getFieldValue('volume')
                }
                decimalCount={2}
                unit={solution.currency}
              />
            </blockquote>
          </Alert>
        ),
    },

    ...inArrayIf(exportIntent, {
      name: 'exportcountry',
      label: (
        <FormattedMessage
          id="tender-counter-form-destination-label"
          description="Label for Destination field on tender counter form"
          defaultMessage="Destination"
        />
      ),
      choices: schema?.exportcountry?.choices?.map(
        ([value, label]: [string, string]) => ({
          value,
          label,
          key: value,
        })
      ),
      initialValue: schema?.exportcountry?.value,
    }),

    {
      render: () =>
        order.startprice.index || autobid ? (
          <React.Fragment key="value-empty" />
        ) : (
          <div key="value" className="total-counter inline-block">
            <div className="total-counter-value inline">
              <strong className="all-black inline mr-74">
                <FormattedMessage
                  id="tender-counter-form-value-label"
                  description="Label for counter value field on tender counter form"
                  defaultMessage="{counterType, select, offer {Offer} bid {Bid} other {{counterType}}} value"
                  values={{ counterType: tender.ordertype.counter }}
                />
              </strong>
              <span>
                <Data.PriceTicker
                  className="price-ticker inline"
                  title=""
                  value={
                    new Value({
                      product: order.product.id,
                      loading: order.loading,
                      price: order.startprice.new({
                        val: form.getFieldValue('price') || 0,
                      }),
                      volume: order.volume.new({
                        val:
                          form.getFieldValue('volume') *
                            (order.stripvolume?.delivered?.deliveries || 1) ||
                          0,
                      }),
                    })
                  }
                />
              </span>
            </div>
          </div>
        ),
    },

    {
      name: 'buyer',
      label: (
        <FormattedMessage
          id="tender-counter-form-buyer-label"
          description="Label for Buyer field on tender counter form"
          defaultMessage="Buyer"
        />
      ),
      choices: (buyer: any) =>
        buyer.choices.map(([value, label]: [string, string]) => ({
          value,
          label,
          key: value,
        })),
    },
    {
      name: 'consignee',
      label: (
        <FormattedMessage
          id="tender-counter-form-consignee-label"
          description="Label for Consignee field on tender counter form"
          defaultMessage="Consignee"
        />
      ),
      choices: (consignee: any) =>
        consignee.choices.map(([value, label]: [string, string]) => ({
          value,
          label,
          key: value,
        })),
    },
    {
      name: 'warehouse',
      label: (
        <FormattedMessage
          id="tender-counter-form-warehouse-label"
          description="Label for Warehouse field on tender counter form"
          defaultMessage="Warehouse"
        />
      ),
      props: {
        disabled: schema.division && !form.getFieldValue('division'),
        placeholder:
          schema.division && !form.getFieldValue('division') ? (
            <FormattedMessage
              id="tender-counter-form-helptext-division"
              description="Helptext for a field on tender counter form when division field has no value"
              defaultMessage="Please select a division"
            />
          ) : (
            <FormattedMessage
              id="tender-counter-form-helptext-location"
              description="Helptext for a field on tender counter form when location field has no value"
              defaultMessage="Please select a location"
            />
          ),
      },
      choices: getChoicesByDivision,
    },
    {
      name: 'toaddr',
      label: (
        <FormattedMessage
          id="tender-counter-form-delivery-to-label"
          description="Label for Delivery to field on tender counter form"
          defaultMessage="Delivery to"
        />
      ),
      props: {
        disabled: schema.division && !form.getFieldValue('division'),
        placeholder:
          schema.division && !form.getFieldValue('division') ? (
            <FormattedMessage
              id="tender-counter-form-helptext-division"
              description="Helptext for a field on tender counter form when division field has no value"
              defaultMessage="Please select a division"
            />
          ) : (
            <FormattedMessage
              id="tender-counter-form-helptext-location"
              description="Helptext for a field on tender counter form when location field has no value"
              defaultMessage="Please select a location"
            />
          ),
      },
      choices: getChoicesByDivision,
    },
    ...inArrayIf(['port', 'zone'].includes(schema?.toaddr?.freight), {
      render: () => <Eta order={order} form={form} schema={schema} />,
    }),
    {
      name: 'fromaddr',
      label: (
        <FormattedMessage
          id="tender-counter-form-delivery-from-label"
          description="Label for Delivery from field on tender counter form"
          defaultMessage="Delivery from"
        />
      ),
      props: {
        disabled: schema.division && !form.getFieldValue('division'),
        placeholder:
          schema.division && !form.getFieldValue('division') ? (
            <FormattedMessage
              id="tender-counter-form-helptext-division"
              description="Helptext for a field on tender counter form when division field has no value"
              defaultMessage="Please select a division"
            />
          ) : (
            <FormattedMessage
              id="tender-counter-form-helptext-location"
              description="Helptext for a field on tender counter form when location field has no value"
              defaultMessage="Please select a location"
            />
          ),
      },
      choices: getChoicesByDivision,
    },
    {
      name: 'incoterms',
      label: (
        <FormattedMessage
          id="tender-counter-form-incoterm-label"
          description="Label for Incoterm field on tender counter form"
          defaultMessage="Incoterm"
        />
      ),
      choices: getChoicesByDivision,
    },
    {
      name: 'callofftime',
      label: (
        <FormattedMessage
          id="tender-counter-form-call-off-times-label"
          description="Label for Call off times field on tender counter form"
          defaultMessage="Call off times"
        />
      ),
      type: 'Input',
    },
    !mine && {
      name: 'acceptdocs',
      label: (
        <FormattedMessage
          id="tender-counter-form-accept-docs-label"
          description="Label for accept docs field on tender counter form"
          defaultMessage="I accept the {tenderFilesLength, select, true{<TenderFiles></TenderFiles>} other{}} {orderFilesLength, select, true{{orderFilesLength, select, true{and the} other{}}} other{}} {orderFilesLength, select, true{<OrderFiles></OrderFiles>} other{}}"
          values={{
            tenderFilesLength: !!tender.files?.length,
            TenderFiles: () => (
              <Data.Files
                files={tender.files!}
                title={
                  <FormattedMessage
                    id="tender-counter-form-link-tender-documents-label"
                    description="Label for tender documents link on tender counter form"
                    defaultMessage="tender documents"
                  />
                }
              />
            ),
            orderFilesLength: !!order.files?.length,
            OrderFiles: () => (
              <Data.Files
                files={order.files!}
                title={
                  <FormattedMessage
                    id="tender-counter-form-link-order-documents-label"
                    description="Label for bid/offer documents link on tender counter form"
                    defaultMessage="{orderType, select, offer {offers} bid {bids} other{{orderType}}} documents"
                    values={{ orderType: order.ordertype }}
                  />
                }
              />
            ),
          }}
        />
      ),
    },
    {
      name: 'comment',
      label: (
        <FormattedMessage
          id="tender-counter-form-comment-label"
          description="Label for Comment field on tender counter form"
          defaultMessage="Comment"
        />
      ),
      type: 'String',
    },
    ...inArrayIf(tender.method === 'blind', {
      name: 'files',
      label: (
        <FormattedMessage
          id="tender-counter-form-files-label"
          description="Label for files field on tender counter form"
          defaultMessage="Attachments"
        />
      ),
      render: () => {
        return (
          <FilesField
            key="form-files"
            accept={R.pathOr([], ['files', 'accepted'], schema).join(', ')}
            files={files}
            setFiles={setFiles}
          />
        );
      },
      serializeField: () => {
        return files.length ? { files } : undefined;
      },
    }),

    {
      name: 'attachments',
      label: (
        <FormattedMessage
          id="tender-counter-form-attachments-label"
          description="Label for attachments field on tender counter form"
          defaultMessage="Existing attachments"
        />
      ),
      choices: normalizeChoices,
      props: {
        placeholder: (
          <FormattedMessage
            id="tender-counter-form-attachments-placeholder"
            description="Placeholder for attachments field on tender counter form"
            defaultMessage="Select attachments to keep"
          />
        ),
      },
      initialValue: R.pathOr([], ['attachments', 'value'], schema),
      initialData: R.pathOr([], ['attachments', 'value'], schema),
    },
  ];

  return new FormWrapper(form, mine?.formdata, mergeSchema(schema, fields));
}

const BaseForm = ({ form, schema, tender, order }: IConfig) => {
  const [loading, setLoading] = useState(false);
  const formWrapper = useFormWrapper({ form, schema, tender, order });
  const mine = order.counters.mine;
  const autobid = tender.willStart()
    ? true
    : form.getFieldValue('autobid') ?? mine?.isAuto;
  const action = mine?.id ? 'update' : 'place';
  const history = useHistory();

  let mounted = true;
  useEffect(() => {
    return () => {
      mounted = false;
    };
  }, []);

  async function handleSubmit(e: any) {
    e.preventDefault();
    setLoading(true);

    try {
      await promisify(form.validateFieldsAndScroll)();
    } catch (err) {
      if (mounted) {
        setLoading(false);
        return;
      }
    }

    const { files, ...fields } = formWrapper.serialize();
    const formdata = new FormData();

    for (const [name, value] of Object.entries(fields)) {
      if (value !== undefined) {
        if (Array.isArray(value))
          for (const v of value) formdata.append(name, v);
        else {
          formdata.append(name, value as string);
        }
      }
    }

    if (files)
      for (const f of files) {
        formdata.append('files[]', f);
      }

    if (mine?.id) {
      formdata.append('id', mine.id);
    }
    const result = await service.actions.counter(
      tender.id,
      order.id,
      formdata,
      mine?.id
    );

    if (result) {
      if (result.success) {
        toast.success(
          `${capitalize(tender.ordertype.counter)} ${action}d successfully`
        );
        history.push(
          routeUrl('tender-counter-orders', {
            tenderId: tender.id,
            orderId: order.id,
          })
        );
      } else {
        if (result?.errors?.length) {
          for (const e of result.errors) toast.error(e);
        }
        if (result?.validate) {
          formWrapper.setErrors(result.validate);
        }
      }
    }

    if (mounted) {
      setLoading(false);
    }
  }

  const onDelete = async () => {
    setLoading(true);

    const result = await service.actions.deleteCounter(
      tender.id,
      order.id,
      mine.id
    );

    if (result) {
      if (result.success) {
        toast.success(
          <>
            Your {!tender.started() && 'auto'} {tender.ordername.counter} has
            been deleted.
          </>
        );
      } else {
        if (result.errors?.length) {
          toast.error(
            <>
              Something went wrong while deleting this{' '}
              {!tender.started() && 'auto'} {tender.ordername.counter}
            </>
          );
        }
      }
    }

    if (mounted) {
      setLoading(false);
    }
  };

  return (
    <Form className="nui-form order-form" onSubmit={handleSubmit}>
      {formWrapper.render()}
      <div className="button-set pt-10">
        <Button
          key="submit"
          htmlType="submit"
          type={tender.ordertype.counter}
          disabled={loading}
          loading={loading}
        >
          <FormattedMessage
            id="tender-counter-form-button-submit"
            description="Label for submit button on tender counter form"
            defaultMessage="{action, select, update{Update} place{Place} other{{action}}} {autobid, select, true{auto} other{}} {counterType, select, offer {offer} bid {bid} other {{counterType}}}"
            values={{
              action: action,
              autobid: !!autobid,
              counterType: tender.ordertype.counter,
            }}
          />
        </Button>
        <Access to="delete" model={mine}>
          <Button
            key="cancel"
            htmlType="button"
            type="reverse"
            disabled={loading}
            loading={loading}
            onClick={onDelete}
          >
            <FormattedMessage
              id="tender-counter-form-button-delete"
              description="Label for delete button on tender counter form"
              defaultMessage="Delete {started, select, false{auto} other{}} {counterType, select, offer {offer} bid {bid} other {{counterType}}}"
              values={{
                action: action,
                started: tender.started(),
                counterType: tender.ordername.counter,
              }}
            />
          </Button>
        </Access>
      </div>
    </Form>
  );
};

const Wrapped = Form.create<IConfig>()(BaseForm);

type ICounterForm = { tender: Tender; order: Order };
const CounterForm = withPreload({
  route: 'tender-counter',
  preload: service.schemas.counter(),
})<ICounterForm>(({ data: { schema } = {}, order, tender }) => {
  return <Wrapped schema={schema} tender={tender} order={order} />;
});

const Page = withPreload({
  route: 'tender-counter',
  preload: service.observe.order(),
})(({ data: { tender, order } }) => {
  return <CounterForm tender={tender} order={order} />;
});

export default withRouteX({ name: 'tender-counter' })(Page);
