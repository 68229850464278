import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { SetState } from '~/hooks';
import { useLocalStorage } from 'react-use';

type ChildrenProp = Partial<Record<'children', React.ReactNode>>;

type ILocalFavsData = Record<string, boolean>;

export enum FilterTypes {
  CHECKBOXES = 'checkboxes',
  RADIO = 'radio',
  DATE = 'date',
}

export type IFilters = {
  [key: string]: {
    type: FilterTypes;
    items: (string | undefined)[];
  };
};

export type IFilterOption = {
  value: string | undefined;
  label: string;
  translation?: any;
  children?: IFilterOption[];
};

interface IContext {
  filters: IFilters;
  setFilters: SetState<any | undefined>;
  favFilterOn?: boolean;
  setFavFilterOn?: SetState<boolean | undefined>;
  localFavourites?: ILocalFavsData;
  setlocalFavourites: Dispatch<SetStateAction<ILocalFavsData>>;
}

export const FiltersContext = React.createContext<IContext | undefined>(
  undefined
);

type IFiltersProvider = ChildrenProp & {
  store: string;
};
export const FiltersProvider = ({ store, children }: IFiltersProvider) => {
  const [favFilterOn, setFavFilterOn] = useLocalStorage<boolean>(
    `${store}_favouritesFilterOn`,
    false
  );

  const localStorage = useLocalStorage<any>(`${store}_filters`, {});
  const localState = useState({});
  const [filters, setFilters] = store ? localStorage : localState;
  const [localFavourites, setlocalFavourites] = useState<ILocalFavsData>({});

  return (
    <FiltersContext.Provider
      value={{
        filters,
        setFilters,
        favFilterOn,
        setFavFilterOn,
        localFavourites,
        setlocalFavourites,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const withFilters = (Component: React.ComponentType) => {
  return (props: any) => (
    <FiltersProvider store={`tender-orders-filters_${props.params?.tenderId}`}>
      <Component {...props} />
    </FiltersProvider>
  );
};

export function useFilters() {
  const context = useContext(FiltersContext);
  if (!context) throw new Error('Filters context is undefined');
  return context;
}
