import React from 'react';
import classnames from 'classnames';
import { Tooltip } from 'Components/nui';
import { FormattedMessage } from 'react-intl';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const LegendTypeColumn = ColumnTree.defineNode(
  'legend-type',
  ({ productById }) => ({
    title: (
      <span>
        <FormattedMessage
          id="marketplace-table-subheading-ordertype"
          description="Table sub-heading, order type e.g. ([My] offer|bid)"
          defaultMessage="Type"
        />
      </span>
    ),
    key: 'type',
    className: 'col-product-type-alt',
    render: (text, record, index) => (
      <div className="td-content">
        <div className="col-product-type">
          <>
            <span
              className={classnames(`type-${record.type}`, {
                'my': record.is_owner && !record.broker?.id,
                'private': record.isprivate,
                'counteronly': record.counteronly,
                'disabled': productById(record.product.id) === null,
                'private-group':
                  (record.counterparties && record.counterparties.length > 1) ||
                  record.ismultiprivate,
              })}
            >
              {
                <FormattedMessage
                  id="marketplace-table-cell-ordertype"
                  description="Cell text for 'orderType' column in marketplace table"
                  defaultMessage="{is_owner, select, true {My } other {}}{counteronly, select, true {SFC } other {}} {type, select, bid {bid} offer {offer} other {{type}}}"
                  values={{
                    is_owner: record.is_owner,
                    counteronly: record.counteronly,
                    type: record.type,
                  }}
                />
              }
              {record.broker?.id && (
                <span className="broker">
                  <Tooltip
                    className="icon-help-circled"
                    placement="top"
                    title={
                      <span className="icon-tooltip">
                        <span className="icon-info-circled" />
                        <FormattedMessage
                          id="marketplace-table-cell-ordertype-broker-tooltip"
                          description="Broker Tooltip text for 'orderType' cell column in marketplace table"
                          defaultMessage="Broker order"
                        />
                      </span>
                    }
                  />
                </span>
              )}
              {record.isprivate && (
                <Tooltip
                  title={
                    <span className="icon-tooltip">
                      <span className="icon-info-circled" />
                      <FormattedMessage
                        id="marketplace-table-cell-ordertype-private-tooltip"
                        description="Private Tooltip text for 'orderType' cell column in marketplace table"
                        defaultMessage="{is_owner, select, true {My } other {}}Private order"
                        values={{
                          is_owner: record.is_owner,
                        }}
                      />
                    </span>
                  }
                >
                  <span className="private-icon">
                    {record.isprivate &&
                    ((record.counterparties &&
                      record.counterparties.length > 1) ||
                      record.ismultiprivate)
                      ? 'pg'
                      : 'p'}
                  </span>
                </Tooltip>
              )}
            </span>
          </>
        </div>
      </div>
    ),
  })
);
