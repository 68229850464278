import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const LoadingColumn = ColumnTree.defineNode('loading', _context => ({
  title: (
    <FormattedMessage
      id="marketplace-table-heading-loadingconfig"
      description="Loading configuration, repeated for cell headings"
      defaultMessage="Table heading"
    />
  ),
}));
