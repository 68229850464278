import type * as Struct from 'struct';
import type { Tender } from '~/models/tenders';
import type { FormComponentProps } from 'antd/lib/form';
import React, { useState } from 'react';
import { routeUrl, withPreload, withRouteX } from '~/router';
import { FormWrapper } from 'Components/form';
import { mergeSchema } from 'Components/form/utils';
import moment from 'moment-timezone';
import { Button, Form } from 'Components/nui';
import { useMountedState } from '~/hooks';
import { promisify } from 'es6-promisify';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import FilesField from 'Components/form/FilesField';
import * as services from '~/services';
import { useStoreState } from '~/store';
import { FormattedMessage } from 'react-intl';
import { inArrayIf } from '~/utils';

type TenderProp = Record<'tender', Tender>;

interface IDetails {
  mode?: string;
}
const Details = withPreload({
  route: 'tender-edit-details',
  preload: services.tenders.schemas.tenderDetails(),
})<IDetails>(({ data: { tender, schema }, mode = 'edit' }) => {
  return (
    <div className="tender-edit-step details">
      <DetailsForm tender={tender} schema={schema} mode={mode} />
    </div>
  );
});

export default withRouteX({
  name: 'tender-edit-details',
  exact: true,
})(({ params: { mode } }) => {
  return <Details mode={mode} />;
});

type IUseFormWrapper = FormComponentProps &
  Partial<TenderProp> &
  Record<'schema', Struct.Schema>;
type FormProps = IUseFormWrapper & Record<'mode', string>;
function useFormWrapper({ form, schema, tender }: IUseFormWrapper) {
  const timeInterval = 5;
  const fromDt = moment()
    .startOf('hour')
    .minute(Math.ceil(moment().minute() / timeInterval) * timeInterval);

  const [files, setFiles] = useState([]);
  const reservePriceEnabled = useStoreState(
    state => state.auth.solutionSettings.tenderreserveprice
  );

  const fields = [
    {
      name: 'name',
      label: (
        <FormattedMessage
          id="tender-create-details-page-tender-name-field"
          description="Label for Tender name field on tenders create/edit details page"
          defaultMessage="Tender name"
        />
      ),
      type: 'Input',
    },
    {
      name: 'issell',
      label: (
        <FormattedMessage
          id="tender-create-details-page-tender-type-field"
          description="Label for Tender type field on tenders create/edit details page"
          defaultMessage="Tender type"
        />
      ),
      choices: [
        {
          key: 'sell',
          value: 'true',
          label: (
            <FormattedMessage
              id="tender-create-details-page-tender-type-option-sell"
              description="Label for Sell radio option on tenders create/edit details page"
              defaultMessage="Sell"
            />
          ),
        },
        {
          key: 'buy',
          value: 'false',
          label: (
            <FormattedMessage
              id="tender-create-details-page-tender-type-option-buy"
              description="Label for Buy radio option on tenders create/edit details page"
              defaultMessage="Buy"
            />
          ),
        },
      ],
      type: 'RadioSelect',
    },
    {
      render: () => (
        <div key="method-help" className="pt-10">
          <h3>
            <FormattedMessage
              id="tender-create-details-page-tender-method-heading"
              description="Heading for Tender method on tenders create/edit details page"
              defaultMessage="Tender method"
            />
          </h3>
          <p>
            <FormattedMessage
              id="tender-create-details-page-tender-method-subheading"
              description="Subheading for Tender method on tenders create/edit details page"
              defaultMessage="Select a tender method below."
            />
          </p>
          <p>
            <FormattedMessage
              id="tender-create-details-page-tender-method-text1"
              description="Text for first paragraph Tender method on tenders create/edit details page"
              defaultMessage="An <em>open tender</em> is one in which participants will be able to see their bid in relation to bids placed by other participants. A <em>binding tender</em> means you are obligated to trade the top ranked bid on all offers that were bid on."
              values={{ em: chunks => <em>{chunks}</em> }}
            />
          </p>
          <p>
            <FormattedMessage
              id="tender-create-details-page-tender-method-text2"
              description="Text for second paragraph of Tender method on tenders create/edit details page"
              defaultMessage="A <em>blind tender</em> is one in which participants will only be able to see their own bids."
              values={{ em: chunks => <em>{chunks}</em> }}
            />
          </p>
          {reservePriceEnabled && (
            <p>
              <strong>
                <FormattedMessage
                  id="tender-create-details-page-tender-method-text-reserved-price"
                  description="Text for Tender method when reserved price is enabled on tenders create/edit details page"
                  defaultMessage="If a reserve price has been set and the tender does not meet the reserve price, the tender owner is not obligated to trade."
                />
              </strong>
            </p>
          )}
        </div>
      ),
    },
    {
      name: 'method',
      label: (
        <FormattedMessage
          id="tender-create-details-page-method-field"
          description="Label for Method field on tenders create/edit details page"
          defaultMessage="Method"
        />
      ),
      choices: [
        {
          value: 'open',
          key: 'open',
          label: (
            <FormattedMessage
              id="tender-create-details-page-method-field-open"
              description="Label for Open (binding) option on tenders create/edit details page"
              defaultMessage="Open (binding)"
            />
          ),
        },
        {
          value: 'blind',
          key: 'blind',
          label: (
            <FormattedMessage
              id="tender-create-details-page-method-field-blind"
              description="Label for Blind option on tenders create/edit details page"
              defaultMessage="Blind"
            />
          ),
        },
      ],
      type: 'RadioSelect',
    },
    {
      render: () => <hr key="sep-0" className="mt-0" />,
    },
    {
      name: 'start',
      label: (
        <FormattedMessage
          id="tender-create-details-page-start-field"
          description="Label for Start field on tenders create/edit details page"
          defaultMessage="Start"
        />
      ),
      fromDate: fromDt.clone(),
      props: {
        getCalendarContainer: null,
        showTime: {
          format: 'h:mm a',
          minuteStep: timeInterval,
          hideDisabledOptions: true,
          defaultValue: fromDt.clone(),
        },
      },
      type: 'DateTime',
    },
    {
      name: 'finish',
      label: (
        <FormattedMessage
          id="tender-create-details-page-finish-field"
          description="Label for Finish field on tenders create/edit details page"
          defaultMessage="Finish"
        />
      ),
      fromDate: form.getFieldValue('start') || fromDt.clone(),
      props: {
        getCalendarContainer: null,
        showTime: {
          format: 'h:mm a',
          minuteStep: timeInterval,
          hideDisabledOptions: true,
          defaultValue: fromDt.clone(),
        },
      },
      type: 'DateTime',
    },
    {
      name: 'files',
      render() {
        if (!schema.files) return null;
        return (
          <FilesField
            key="form-files"
            label={
              <FormattedMessage
                id="documents-form-label"
                description="Label for Documents input form"
                defaultMessage="Documents"
              />
            }
            accept={schema.files.accepted.join(', ')}
            files={files}
            setFiles={setFiles}
          />
        );
      },
      serializeField() {
        if (!schema.files) return null;
        return files.length ? { files } : undefined;
      },
    },
    {
      name: 'attachments',
      label: (
        <FormattedMessage
          id="tender-create-details-page-existing-documents-form-label"
          description="Label for Existing attachments input form on tenders create/edit details page"
          defaultMessage="Existing attachments"
        />
      ),
      choices:
        tender?.files?.map(f => ({
          key: f.id,
          value: f.id,
          label: f.filename,
        })) || [],
      props: {
        placeholder: 'Select attachments to keep',
      },
      type: 'MultipleSelect',
    },
  ];

  const attachments = !!tender?.files?.length &&
    !!schema.files && { attachments: { type: 'MultipleSelect' } };

  return new FormWrapper(
    form,
    tender?.formdata,
    mergeSchema({ ...attachments, ...schema }, fields)
  );
}

const BaseForm = ({ form, schema, tender, mode }: FormProps) => {
  const [loading, setLoading] = useMountedState(false);
  const history = useHistory();

  const formWrapper = useFormWrapper({ form, schema, tender });

  async function handleSubmit() {
    setLoading(true);

    try {
      await promisify(form.validateFieldsAndScroll)();
    } catch (err) {
      setLoading(false);
      return;
    }

    const { files, ...fields } = formWrapper.serialize();
    const formdata = new FormData();
    for (const [name, value] of Object.entries(fields)) {
      if (value !== undefined) {
        if (Array.isArray(value))
          for (const v of value) formdata.append(name, v);
        else formdata.append(name, value as string);
      }
    }
    if (files) for (const f of files) formdata.append('files[]', f);
    if (tender?.id) formdata.append('id', tender.id);
    const result = await services.tenders.actions.editTender(formdata);

    if (result) {
      if (result.success) {
        const name = fields.name;
        const action = fields.id ? 'updated' : 'created';
        toast.success(`Tender "${name}" ${action} successfully`);
        const url = routeUrl('tender-edit-orders', {
          tenderId: result.data.id,
          mode,
        });
        history.push(url);
      } else {
        if (result.errors) for (const e of result.errors) toast.error(e);
        if (result.validate) formWrapper.setErrors(result.validate);
      }
    }

    setLoading(false);
  }

  return (
    <Form
      className="nui-form tender-form"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      {formWrapper.render()}
      <div className="sticky-btm button-set">
        <Button
          htmlType="submit"
          type="primary"
          disabled={loading}
          loading={loading}
        >
          <FormattedMessage
            id="tender-create-details-page-save-button"
            description="Button Save on tenders create/edit details page"
            defaultMessage="Save"
          />
        </Button>
      </div>
    </Form>
  );
};

const DetailsForm = Form.create<FormProps>()(BaseForm);
