import { useState, useEffect } from 'react';
import { api } from '~/api';
import * as R from 'ramda';
import { useStoreState } from '~/store';

async function getWarehouses(divisionId) {
  return api.getData(
    { type: 'divisions', id: `${divisionId}/warehouses` },
    { limit: 100, active: true }
  );
}

async function getSolutionWarehouses(divisionIds) {
  const warehouses = [];
  for (let id of divisionIds) warehouses.push(await getWarehouses(id));
  return R.flatten(warehouses);
}

async function patchDefault(warehouse) {
  return api.getData(
    {
      type: 'divisions',
      id: `${warehouse.division.id}/warehouses/${warehouse.id}`,
    },
    null,
    { method: 'patch', data: { default: true } }
  );
}

async function deleteWarehouse(warehouse) {
  return api.getData(
    {
      type: 'divisions',
      id: `${warehouse.division.id}/warehouses/${warehouse.id}`,
    },
    null,
    { method: 'delete' }
  );
}

async function addWarehouse(divisionId, data) {
  return api.getData(
    { type: 'divisions', id: `${divisionId}/warehouses` },
    null,
    { method: 'post', data }
  );
}

async function getSchema(divisionId) {
  return api.getData({
    type: 'divisions',
    id: `${divisionId}/warehouses/schema`,
    getResult: x => x || {},
  });
}

export function useWarehouses() {
  const [warehouses, setWarehouses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [shouldUpdate, setUpdate] = useState(true);
  const [schemas, setSchemas] = useState({});

  const roles = useStoreState(R.pathOr([], ['auth', 'solution', 'roles']));
  const divisionIds = roles.map(R.path(['division', 'id']));

  const addSchema = (divisionId, schema) => {
    setSchemas({ ...schemas, [divisionId]: schema });
  };

  useEffect(() => {
    if (!loading && shouldUpdate) {
      update();
    }
  }, [loading, setLoading]);

  function all() {
    return [warehouses, loading];
  }

  async function update() {
    setLoading(true);
    setUpdate(false);

    try {
      const result = await getSolutionWarehouses(divisionIds);
      setWarehouses(result);
      setLoading(false);
      return result;
    } catch (e) {
      setError(e);
      setLoading(false);
      console.log(error, e);
      return e;
    }
  }

  async function setDefault(warehouse) {
    const result = await patchDefault(warehouse);
    await update();
    return result;
  }

  async function remove(warehouse) {
    const result = await deleteWarehouse(warehouse);
    await update();
    return result;
  }

  async function add(divisionId, data) {
    const result = await addWarehouse(divisionId, data);
    await update();
    return result;
  }

  async function schema(divisionId) {
    if (!divisionId) return {};
    if (schemas[divisionId]) return schemas[divisionId];
    try {
      const s = await getSchema(divisionId);
      addSchema(s);
      return s;
    } catch (e) {
      return {};
    }
  }

  return { all, update, setDefault, remove, schema, add };
}
