import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { loadSentry } from './sentry';

import './scss/fontello.scss';
import './scss/sitebase.scss';

// Required for Vite hot reload - I cannot explain why it is needed!
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const R = () => {};

if (import.meta.env.VITE_APP_SENTRY_DSN) {
  loadSentry();
}

ReactDOM.render(<App />, document.getElementById('root'));
