import type { Tender, Order, Counter } from '~/models/tenders';
import type { Price } from '~/models/utils';
import React from 'react';
import * as Data from 'Components/Data';
import { routeUrl } from '~/router';
import { Button, Modal, Alert } from 'Components/nui';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import * as service from '~/services/tenders';
import { useMountedState } from '~/hooks';
import { FormattedMessage } from 'react-intl';

type TenderProp = Record<'tender', Tender>;
type OrderProp = Record<'order', Order>;
type CounterProp = Record<'counter', Counter>;

type IUseQuickbid = TenderProp & OrderProp;
function useQuickbid({ tender, order }: IUseQuickbid) {
  const mine = order.counters.mine;

  const [isOpen, setIsOpen] = useMountedState(false);
  const [loading, setLoading] = useMountedState(false);
  const [error, setError] = useMountedState(false);
  const [nextprice, setNextprice] = useMountedState(order.counters.autoprice);

  const toggleModal = (value?: boolean) =>
    void setIsOpen(state => value ?? !state);

  const onOpen = (e?: React.MouseEvent) => {
    e?.stopPropagation();

    toggleModal(true);
    setNextprice(order.counters.autoprice);
  };

  const onClose = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    toggleModal(false);
  };

  return {
    isOpen,
    loading,
    error,
    nextprice,

    onOpen,
    onClose,

    async update(e?: React.MouseEvent) {
      e?.preventDefault();

      setLoading(true);
      const result = await service.actions.quickbid(
        tender.id,
        order.id,
        mine.id,
        nextprice.val!
      );

      if (result) {
        if (result.success) {
          toast.success(
            <>
              Your {mine.ordertype} for <strong>{order.product.name}</strong>{' '}
              has been updated successfully
            </>
          );
          setError(false);
          onClose();
        } else if (result.validate) {
          toast.error(
            <>
              Your {mine.ordertype} for <strong>{order.product.name}</strong>{' '}
              could not be updated because the price has changed.
            </>,
            { delay: undefined, onClick: onOpen }
          );
          setError(true);
          const next = await service.observe.queryOrder(tender.id, order.id);
          setNextprice(next.order.counters.autoprice);
        }
      }

      setLoading(false);
    },
  };
}

type IQuickbid = TenderProp & OrderProp & { children?: React.ReactNode };
const Quickbid = ({ tender, order, children }: IQuickbid) => {
  const autoprice = order.counters.autoprice;
  const mine = order.counters.mine;

  const { isOpen, loading, error, nextprice, onOpen, onClose, update } =
    useQuickbid({ tender, order });

  return (
    <>
      <Button
        type="reverse"
        className="nui-beveled"
        onClick={onOpen}
        loading={loading}
        disabled={loading}
      >
        {error && <span className="icon-attention error" />}

        <FormattedMessage
          id="tender-details-quickbid-button"
          description="Label for quickbid button on tenders details page"
          defaultMessage="Quick {orderType, select, offer {offer} bid {bid} other {{orderType}}}"
          values={{ orderType: mine.ordertype }}
        />
        <span className="place-bid-price">
          <Data.Number value={autoprice.val!} step={mine.price.step} />
        </span>
        {children}
      </Button>
      {isOpen && (
        <Modal size="small" close={onClose}>
          <h2>
            {' '}
            <FormattedMessage
              id="tender-details-quickbid-modal-heading"
              description="Heading for quickbid modal"
              defaultMessage="Confirm {orderType, select, offer {offer} bid {bid} other {{orderType}}}"
              values={{ orderType: mine.ordertype }}
            />
          </h2>
          <p className="mb-10">
            <span className="inline-block mr-15">
              <strong className="all-black pr-5">
                <FormattedMessage
                  id="tender-details-quickbid-modal-product"
                  description="Label for product field on quickbid modal"
                  defaultMessage="Product"
                />
              </strong>{' '}
              {order.product.name}
            </span>
            {!!mine.variant && (
              <span className="inline-block">
                <strong className="all-black pr-5">
                  <FormattedMessage
                    id="tender-details-quickbid-modal-variant"
                    description="Label for Variant field on quickbid modal"
                    defaultMessage="Variant"
                  />
                </strong>{' '}
                {mine.variant.name}
              </span>
            )}
          </p>
          {error && (
            <Alert className="mb-10" hasicon type="error">
              <FormattedMessage
                id="x"
                description="Error for Variant field on quickbid modal"
                defaultMessage="Your {orderType, select, offer {offer} bid {bid} other {{orderType}}} could not be updated because the price has changed.<strong>Please confirm the new price below.</strong>"
                values={{
                  orderType: mine.ordertype,
                  strong: chunks => <strong className="error">{chunks}</strong>,
                }}
              />
            </Alert>
          )}
          <hr className="mt-10 mb-5" />
          <CounterDetails counter={mine} price={nextprice} outbid={error} />
          <hr className="mt-10" />
          <div className="button-set">
            <Button
              type="primary"
              loading={loading}
              disabled={loading}
              onClick={update}
            >
              <FormattedMessage
                id="tender-details-quickbid-modal-button-confirm"
                description="Button Confirm on quickbid modal"
                defaultMessage="Confirm"
              />
            </Button>
            <Link
              onClick={onClose}
              to={routeUrl('tender-counter', {
                tenderId: tender.id,
                orderId: order.id,
              })}
              className={classnames('nui-button nui-secondary', {
                loading,
                disabled: loading,
              })}
            >
              <FormattedMessage
                id="tender-details-quickbid-modal-button-edit-details"
                description="Button Edit details on quickbid modal"
                defaultMessage="Edit details"
              />
            </Link>
            <Button type="buttonlink" className="ml-20" onClick={onClose}>
              <FormattedMessage
                id="tender-details-quickbid-modal-button-close"
                description="Button Close on quickbid modal"
                defaultMessage="Close"
              />
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

type ICounterDetails = CounterProp & { price: Price; outbid?: boolean };
const CounterDetails = ({ counter, price, outbid }: ICounterDetails) => (
  <Data.List>
    <Data.Item className={classnames('order-price', { outbid })}>
      <Data.PriceTicker
        title={
          <FormattedMessage
            id="tender-details-quickbid-modal-new-price"
            description="Label for New price field on quickbid modal"
            defaultMessage="New price"
          />
        }
        value={price}
      />
    </Data.Item>
    <Data.Item className="order-volume">
      <Data.VolumeTicker value={counter.volume} />
    </Data.Item>
    {counter.fromaddr && (
      <Data.Item
        title={
          <FormattedMessage
            id="tender-details-quickbid-modal-delivery-from"
            description="Label for Delivery from field on quickbid modal"
            defaultMessage="Delivery from"
          />
        }
      >
        <Data.Location value={counter.fromaddr} />
      </Data.Item>
    )}
    {counter.toaddr && (
      <Data.Item
        title={
          <FormattedMessage
            id="tender-details-quickbid-modal-delivery-to"
            description="Label for Delivery to field on quickbid modal"
            defaultMessage="Delivery to"
          />
        }
      >
        <Data.Location value={counter.toaddr} />
      </Data.Item>
    )}
    {counter.comment && (
      <Data.Item
        title={
          <FormattedMessage
            id="tender-details-quickbid-modal-comments"
            description="Label for Comments field on quickbid modal"
            defaultMessage="Comments"
          />
        }
        className="stacked"
      >
        <Data.Note value={counter.comment} />
      </Data.Item>
    )}
  </Data.List>
);

export default Quickbid;
