import type * as SentryType from '@sentry/react';

let sentryInstance: typeof SentryType | undefined;
let sentryReplayInstance:
  | ReturnType<typeof SentryType.replayIntegration>
  | undefined;

export const loadSentry = async () => {
  const Sentry = await import('@sentry/react');

  if (!sentryInstance) {
    sentryInstance = Sentry;
  }

  // Initialize the replay instance only once
  if (!sentryReplayInstance) {
    sentryReplayInstance = Sentry.replayIntegration({
      // disable all default masking
      // https://docs.sentry.io/platforms/javascript/session-replay/privacy/
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
      networkDetailAllowUrls: [
        /https:\/\/.*\/api\/.*/,
        /https:\/\/analytics\..*/,
      ],
    });
  }

  Sentry.init({
    environment:
      import.meta.env.VITE_APP_ENV ?? window.location.hostname.split('.')[1],
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    release:
      import.meta.env.VITE_APP_ENV === 'prod'
        ? import.meta.env.VITE_APP_GIT_TAG ||
          import.meta.env.VITE_APP_GIT_BRANCH
        : 'develop',
    integrations: [
      // Tracing (performance monitoring - disabled for now)
      // https://docs.sentry.io/platforms/javascript/guides/react/tracing/
      // Sentry.browserTracingIntegration(),
      // @ts-ignore
      sentryReplayInstance,
    ],
    tracesSampleRate:
      parseFloat(import.meta.env.VITE_APP_SENTRY_TRACES_SAMPLE_RATE) || 0,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

    // Session Replay
    // https://docs.sentry.io/platforms/javascript/guides/nextjs/session-replay/#sampling
    // disable session replay by default
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate:
      parseFloat(
        import.meta.env.VITE_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE
      ) || 0,
    normalizeDepth: 12,
    beforeSend(event, hint) {
      // ignore playwright
      if (event.user?.email?.includes('mailslurp.com')) {
        return null;
      }
      const error = hint.originalException;
      // ignore OAuth refresh errors
      if (error instanceof Error && error.message === 'OAuth refresh error') {
        return null;
      }
      return event;
    },
  });
};

export const getSentryInstance = () => sentryInstance;

export const startReplay = (enabled: boolean) => {
  const sentry = getSentryInstance();
  if (sentry && import.meta.env.VITE_APP_SENTRY_DSN && enabled) {
    const replay = sentry.getReplay();
    replay?.flush();
  }
};

export const stopReplay = (enabled: boolean) => {
  const sentry = getSentryInstance();
  if (sentry && import.meta.env.VITE_APP_SENTRY_DSN && enabled) {
    const replay = sentry.getReplay();
    replay?.stop();
  }
};
