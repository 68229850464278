import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import * as R from 'ramda';
import { areas } from '../references';
import Details from './Details';
import Freight from './Freight';

export default () => {
  const { id, area } = useParams();

  if (id && !area) return <Redirect to={`/routes/${id}/edit/details`} />;

  return (
    <>
      <div className="container">
        <div className="title-block show-for-medium">
          <h2>
            {R.pathOr('Route details', [area, 'title'], areas)}
            <span>
              Step <strong>{R.pathOr('1', [area, 'step'], areas)}</strong> /{' '}
              <strong>2</strong>
            </span>
          </h2>
        </div>

        <div>
          {(area === 'details' || id === undefined) && <Details />}
          {area === 'freight' && <Freight />}
        </div>
      </div>
    </>
  );
};
