import type * as Struct from 'struct';
import type { Tender, Order } from '~/models/tenders';
import type { FormComponentProps } from 'antd/lib/form';
import type { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
import { useFormWrapper } from 'Pages/Orders/New';
import { Button, Form } from 'Components/nui';
import { Product } from '~/models';
import { useMountedState } from '~/hooks';
import { promisify } from 'es6-promisify';
import { toast } from 'react-toastify';
import * as R from 'ramda';
import { capitalize } from '~/utils';
import * as services from '~/services/tenders';
import { useStoreState } from '~/store';
import { FormattedMessage } from 'react-intl';

type TenderProp = Record<'tender', Tender>;
type OrderProp = Record<'order', Order>;
type OnSuccess = Record<'onSuccess', (form: WrappedFormUtils) => void>;

type IOrderForm = FormComponentProps &
  TenderProp &
  Partial<OrderProp> &
  OnSuccess &
  Record<'product', any> &
  Record<'schema', Struct.Schema>;
const BaseForm = ({
  form,
  tender,
  order,
  product,
  schema,
  onSuccess,
}: IOrderForm) => {
  const [loading, setLoading] = useMountedState(false);
  const solution = useStoreState(state => state.auth.solution);
  const formWrapper = useFormWrapper({
    form,
    product: new Product(product),
    initialData: { ...(order?.formdata || {}) },
    schema: {
      ...schema,
      ...(!!order?.files?.length && {
        attachments: {
          required: false,
          type: 'MultipleSelect',
          value: order.files.map(R.prop('id')),
          choices: order.files.map(f => [f.id, f.filename]),
        },
      }),
    },
    hideTotals: true,
    orderType: tender.ordertype.order,
    solution,
  });

  async function handleSubmit() {
    setLoading(true);

    try {
      await promisify(form.validateFieldsAndScroll)();
    } catch (err) {
      setLoading(false);
      return;
    }

    const { files, ...fields } = formWrapper.serialize();
    const formdata = new FormData();
    formdata.append('product', product.id);
    for (const [name, value] of Object.entries(fields)) {
      if (value !== undefined) {
        if (Array.isArray(value))
          for (const v of value) formdata.append(name, v);
        else formdata.append(name, value as string);
      }
    }
    if (files) for (const f of files) formdata.append('files[]', f);

    const result = await services.actions.editOrder(
      formdata,
      tender.id,
      order?.id
    );

    if (result?.success) {
      const action = order?.id ? 'updated' : 'placed';
      const otype = tender.ordertype.order;
      toast.success(
        `${capitalize(otype)} for ${product.name} ${action} successfully.`
      );
      onSuccess(form);
    } else if (result?.errors) {
      for (const e of result.errors) toast.error(e);
    } else if (result?.validate) {
      const {
        frometd = [],
        toetd = [],
        attributes = {},
        variants,
        ...validate
      } = result.validate;

      const etd = [...frometd, ...toetd];
      const etdv = etd.length ? { etd } : {};

      const attrErr = Object.fromEntries(
        Object.entries(attributes).map(([name, errors]) => [
          `attributes_${name}`,
          errors,
        ])
      );

      formWrapper.setErrors({ frometd, ...etdv, ...validate, ...attrErr });

      // HACK DOM to display errors due to unable to set errors to nested forms in Ant Design v3
      if (variants) {
        Object.keys(variants).forEach(id => {
          const variant = variants[id][0];
          const domId = id.replace('variants-', '');

          // reset errors
          const variantItemDom = document.querySelector(
            `.variants-item[data-id="${domId}"]`
          );
          variantItemDom?.querySelectorAll('.has-error').forEach(node => {
            node.classList.remove('has-error');
          });
          variantItemDom
            ?.querySelectorAll('.ant-form-explain')
            .forEach(node => {
              node.remove();
            });

          if (variant.product) {
            const fieldDom = variantItemDom?.querySelector(
              `.product .ant-form-item-control`
            );

            const errorNode = document.createElement('div');
            errorNode.classList.add('ant-form-explain');
            errorNode.textContent = variant.product;

            fieldDom?.classList.add('has-error');
            fieldDom?.appendChild(errorNode);
          }
          if (variant.loading) {
            const fieldDom = variantItemDom?.querySelector(
              `.loading .ant-form-item-control`
            );

            const errorNode = document.createElement('div');
            errorNode.classList.add('ant-form-explain');
            errorNode.textContent = variant.loading;

            fieldDom?.classList.add('has-error');
            fieldDom?.appendChild(errorNode);
          }
        });
      }
    }

    setLoading(false);
  }

  return (
    <Form
      className="nui-form tender-form"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      {formWrapper.render()}
      <div className="sticky-btm button-set">
        <Button
          htmlType="submit"
          type="primary"
          disabled={loading}
          loading={loading}
        >
          <FormattedMessage
            id="tender-create-order-save-button"
            description="Button for Save on tenders create/edit order page"
            defaultMessage="Save"
          />
        </Button>
      </div>
    </Form>
  );
};

const OrderForm = Form.create<IOrderForm>()(BaseForm);
export default OrderForm;
