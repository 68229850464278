import React from 'react';
import { useScrollToTopOnMount } from '~/hooks.js';
import TradesTable from './Table';
import { useTradesTable } from '~/store/models';
import Header from './Header';
import Helmet from 'Components/Helmet';
import { Loading } from 'Components/nui';
import { FiltersProvider } from 'Components/filters/context';
import { useStoreState } from '~/store';
import TradeFilters from 'Components/filters/TradeFilters';

export default () => {
  useScrollToTopOnMount();

  const trades = useTradesTable();
  const { pagination, ...table } = trades;

  const solution = useStoreState(state => state.auth.solution);

  return (
    <>
      <Helmet>
        <title>Trades</title>
      </Helmet>
      <Header trades={trades} />
      <FiltersProvider store={`trade-filters_${solution?.id}`}>
        <div className="full-page-content">
          <TradeFilters trades={trades} />
          {trades.loading() ? (
            <Loading />
          ) : table.noContent() ? (
            <div className="nui-row full-page-messaging align-center p-100 pb-110">
              <div className="icon-inbox bordered align-center">
                There are currently no trades to display
              </div>
            </div>
          ) : (
            <TradesTable trades={table} pagination={pagination} />
          )}
        </div>
      </FiltersProvider>
    </>
  );
};
