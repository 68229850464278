import React, { useState } from 'react';
import { Form, Button, Alert } from 'Components/nui';
import { FormWrapper } from 'Components/form';
import {
  mergeSchema,
  normalizeChoices,
  submitError,
} from 'Components/form/utils';
import { LoadingState } from 'Components/Layout';
import { ShowResult } from 'Components/Result';
import { promisify } from 'es6-promisify';
import * as R from 'ramda';
import { useAsync } from 'react-use';
import { api } from '~/api';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Helmet from 'Components/Helmet';
import { useStoreState } from 'easy-peasy';
import { useSharedRoute } from '..';
import { parseSearch } from '~/utils';

const PForm = ({ form, route, schema, submitState }) => {
  const solution = useStoreState(state => state.auth.solution);
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const initialData = route
    ? {
        source: route.source.id,
        destination: route.destination.id,
        duration: route.duration,
      }
    : parseSearch(location.search);

  const [saved, setSaved] = useState(false);

  const getFormWrapper = () => {
    let fields = [
      {
        name: 'source',
        label: 'Source',
        choices: normalizeChoices,
        initialValue: R.prop('source', initialData),
        props: {
          disabled: true,
        },
      },
      {
        name: 'destination',
        label: 'Destination',
        choices: normalizeChoices,
        initialValue: R.prop('destination', initialData),
        props: {
          disabled: true,
        },
      },
      {
        name: 'duration',
        label: 'Duration',
        initialValue: R.prop('duration', initialData),
      },
    ];

    return new FormWrapper(form, {}, mergeSchema(schema, fields));
  };

  const formWrapper = getFormWrapper();
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }
    submitState.setLoading(true);
    let data = formWrapper.serialize();

    data['solution'] = solution.id;

    try {
      setSaved(false);
      const result = await api.getData(
        { type: 'routes', id: id || '', getResult: x => x },
        null,
        { method: id ? 'put' : 'post', data }
      );
      submitState.setResult();
      window.scrollTo(0, 0);
      setSaved(true);
      submitState.setLoading(false);
      if (!id) history.push(`/routes/${result.id}/edit/freight`);
    } catch (err) {
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        err
      );
      if (errors) {
        formWrapper.setErrors(errors);
      } else {
        submitState.setResult(err);
      }
      submitState.setLoading(false);
    }
  }

  return (
    <>
      {saved && (
        <Alert hasicon type="success" className="mb-20">
          <h3>Success</h3>
          Route details have been saved
        </Alert>
      )}

      <Form onSubmit={handleSubmit}>
        {formWrapper.render()}
        <div className="button-set mt-20 mb-20">
          <Button
            htmlType="submit"
            type="primary"
            loading={submitState.loading}
          >
            {id ? 'Update' : 'Save and continue'}
          </Button>
        </div>
      </Form>
    </>
  );
};

const WrappedForm = Form.create()(PForm);

export default () => {
  const { id } = useParams();
  const [route] = useSharedRoute();
  const history = useHistory();
  const solution = useStoreState(state => state.auth.solution);

  const state = useAsync(() => {
    return api.getData(
      { type: 'routes', id: id ? `${id}/schema` : 'schema' },
      { solution: solution.id }
    );
  }, [solution]);

  const next = () => {
    history.push(`/routes/${id}/edit/freight`);
  };

  if (route && !R.hasPath(['value'], state)) {
    return (
      <>
        <Helmet>
          <title>Edit route {route.name} - Details</title>
        </Helmet>
        <dl className="summary-list">
          <dt>Source</dt>
          <dd>
            {R.path(['source', 'name'], route)},{' '}
            {R.path(['source', 'country', 'name'], route)}
          </dd>

          <dt>Destination</dt>
          <dd>
            {R.path(['destination', 'name'], route)},{' '}
            {R.path(['destination', 'country', 'name'], route)}
          </dd>

          <dt>Duration</dt>
          <dd>{route.duration}</dd>
        </dl>
        <hr />
        <Button type="primary" className="button primary mb-10" onClick={next}>
          Next step
        </Button>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>New route</title>
      </Helmet>
      <ShowResult renderError={submitError}>
        {submitState => (
          <div className="wrapper">
            <div className="nui-form order-form">
              <LoadingState state={state}>
                {schema => (
                  <WrappedForm
                    key="route-details"
                    route={route}
                    schema={schema}
                    submitState={submitState}
                  />
                )}
              </LoadingState>
            </div>
          </div>
        )}
      </ShowResult>
    </>
  );
};
