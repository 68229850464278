import { api } from '~/api';

export async function fetchSchema() {
  return await api.getData2('liquidity-schema');
}

type FormsData = { [x: string]: any };
export async function editOrder(formdata: FormsData) {
  return await api.postData('liquidity-orders', formdata);
}

export async function cancelOrders() {
  return await api.deleteData('liquidity-orders');
}
