import { action, computed, thunk, thunkOn } from 'easy-peasy';
import * as R from 'ramda';
import { api } from '~/api';

export default {
  prefs: null,
  solutionId: '',
  productsPrefs: {},

  // comp: computed(
  //   [state => state.prefs, (_, storeState) => storeState.auth.solutions],
  //   (prefs, solutions) => {
  //     if (!prefs) return {};
  //     const np = {};
  //     const solIds = solutions.map(s => s.id);
  //     const solLength = solutions.length;

  //     R.keys(prefs.prefs).forEach(key => {
  //       if (prefs.prefs[key] !== false)
  //         np[key] = { value: 1, solutions: R.clone(solIds) };
  //     });

  //     R.keys(prefs.solutions).forEach(sId => {
  //       R.keys(prefs.solutions[sId].prefs).forEach(key => {
  //         if (!(key in np)) np[key] = { value: 0.5, solutions: [] };

  //         if (prefs.solutions[sId].prefs[key] !== false)
  //           np[key].solutions.push(sId);
  //       });
  //     });

  //     R.keys(np).forEach(key => {
  //       if (np[key].solutions.length === solLength) np[key].value = 1;
  //     });

  //     return np;
  //   }
  // ),

  solutionKeys: computed(state =>
    R.pathOr([], ['solutions', state.solutionId, 'keys'], state.prefs)
  ),

  setSolutionId: action((state, id) => {
    state.solutionId = id;
  }),

  update: action((state, data) => {
    state.prefs = R.merge(state.prefs, data);
  }),

  updateProductsPrefs: action((state, data) => {
    state.productsPrefs = { ...state.productsPrefs, ...data };
  }),

  patch: thunk(async (actions, data) => {
    const result = await api.getData(
      {
        type: 'user',
        id: 'notification-prefs',
        getResult: x => x,
      },
      null,
      {
        method: 'patch',
        data,
      }
    );
    actions.update(result);
  }),

  patchProductPrefs: thunk(async (actions, data) => {
    const result = await api.getData(
      {
        type: 'user',
        id: 'notification-products',
        getResult: x => x.products,
      },
      null,
      {
        method: 'put',
        data,
      }
    );
    actions.updateProductsPrefs({ [data.solution]: result });
  }),

  loadProducts: thunk(async (actions, data, { getState }) => {
    const state = getState();
    if (!state.solutionId || state.solutionId in state.productsPrefs) return;
    const result = await api.getData(
      {
        type: 'user',
        id: 'notification-products',
        getResult: x => x.products,
      },
      { ...data }
    );
    actions.updateProductsPrefs({ [state.solutionId]: result });
  }),

  onSetSolutionId: thunkOn(
    actions => actions.setSolutionId,
    async (actions, target) => {
      await actions.loadProducts({ solution: target.payload });
    }
  ),
};
