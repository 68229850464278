import * as R from 'ramda';
import {
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
  useCallback,
} from 'react';
import {
  useWindowSize,
  useMount,
  useMountedState as useIsMounted,
  useLocalStorage,
} from 'react-use';
import { getEnv } from './utils';
import { useStoreDispatch, useStoreState } from 'easy-peasy';
import throttle from 'lodash.throttle';
import debounce from 'lodash.debounce';

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useBeforeUnload() {
  function listener(e) {
    // Ref https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#Example
    e.preventDefault();
    e.returnValue = '';
  }
  useEffect(() => {
    if (!getEnv(import.meta.env.VITE_APP_APPLY_BEFOREUNLOAD)) return;
    window.addEventListener('beforeunload', listener);
    return () => window.removeEventListener('beforeunload', listener);
  });
}

export function useSiteTitle(title) {
  // useTitle(title ? `${title} - Nui Markets` : 'Nui Markets');
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function useDrawerWidth() {
  const { width } = useWindowSize();
  return Math.min(width, 600);
}

// export function useCallAsync(fn) {
//   const [run, setRun] = useState(false);
//   const state = useAsync(async () => {
//     if (!run) return Promise.resolve({ loading: false, value: undefined });
//     return fn();
//   }, [run]);
//   // When the handler is called, trigger the async
//   return {
//     state,
//     call: () => {
//       setRun(true);
//     },
//   };
// }

// export function useAsyncPersist(callback, watch) {
//   const [data, setData] = useState(undefined);

//   const result = useAsync(callback, watch);

//   // After API call
//   useEffect(() => {
//     if (!result.loading && !result.error && result.value) {
//       setData(result.value);
//     }
//   }, [result]);

//   return {
//     loading: result.loading,
//     error: result.error,
//     value: data,
//   };
// }

export function useAsyncCall(callback, wait) {
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(!wait);
  const [value, setValue] = useState();
  const [error, setError] = useState();

  const caller = async () => {
    try {
      const result = await callback();
      setValue(result);
    } catch (e) {
      setError(e);
    }
    setUpdate();
    setLoading(false);
  };

  useEffect(() => {
    if (loading || !update) return;
    setLoading(true);
    caller();
  }, [update]);

  const softUpdate = () => {
    setUpdate(true);
  };

  const hardUpdate = () => {
    if (loading) return;
    setValue(null);
    setUpdate(true);
  };

  return [{ loading, error, value }, softUpdate, hardUpdate];
}

// export function useAsyncPing(apiCallback, interval) {
//   const [data, update] = useAsyncCall(apiCallback);
//   useInterval(update, interval);
//   return data;
// }

// function useOnce(action) {
//   const [hasRun, setHasRun] = useState(false);
//   useEffect(() => {
//     setHasRun(false);
//   }, [action]);
//   const run = (...params) => {
//     if (!hasRun) {
//       setHasRun(true);
//       return action(...params);
//     }
//   };
//   return run;
// }

// export function useCountdown(target, after, accuracy = 500) {
//   const getDiff = tgt => tgt && moment.duration(tgt.diff(moment()));
//   const [diff, setDiff] = useState(getDiff(target));
//   const doAfter = useOnce(after);

//   useInterval(() => {
//     if (diff) {
//       setDiff(getDiff(target));
//       if (diff.asMilliseconds() <= 0) doAfter();
//     }
//   }, accuracy);

//   return diff;
// }

// Set global timezone to tz, update when args changes. Restore to prev timezone when exit.
// Note that setDefault is not executed in the first round of render, may not set already the correct timezone.
export function useTimezone(tz, args = []) {
  // const [prevTz] = useState(R.path(['defaultZone', 'name'], moment));
  // useEffect(() => {
  //   moment.tz.setDefault(tz);
  //   return () => void moment.tz.setDefault(prevTz);
  // }, [tz, ...args]);
}

// Call fn when window scroll
export function useWindowScrollFn(fn) {
  const frame = useRef(0);
  const [state, setState] = useState();
  useMount(() => {
    const handler = () => {
      cancelAnimationFrame(frame.current);
      frame.current = requestAnimationFrame(() => {
        setState(fn());
      });
    };

    window.addEventListener('scroll', handler, {
      capture: false,
      passive: true,
    });

    return () => {
      cancelAnimationFrame(frame.current);
      window.removeEventListener('scroll', handler);
    };
  });
  return state;
}

export function useIsMobile() {
  const { width } = useWindowSize();
  return width < 576;
}

export function useSchema(productId) {
  const dispatch = useStoreDispatch();
  const schemas = useStoreState(state => state.marketplace.schemas);
  const schema = R.prop(productId, schemas);
  useEffect(() => {
    if (schema) return;
    dispatch.marketplace.updateSchema(productId);
  }, [schema]);

  return schema;
}

export function useThrottle(fn, wait, config) {
  const ref = useRef(throttle(fn, wait, config));
  return ref.current;
}

export function useDebounce(fn, wait, config) {
  const ref = useRef(debounce(fn, wait, config));
  return ref.current;
}

export const useScrollToTopOnMount = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
};

export const useBodyClassReverse = () => {
  useEffect(() => {
    document.body.classList.add('reverse');
    return () => document.body.classList.remove('reverse');
  }, []);
};

export const useLockBodyScroll = () => {
  useLayoutEffect(() => {
    const originalTop = window.scrollY;
    document.body.style.position = 'fixed';
    document.body.style.top = `-${originalTop}px`;
    requestAnimationFrame(() => (document.body.style.overflow = ''));

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      requestAnimationFrame(() =>
        window.scrollTo(0, parseInt(scrollY || '0') * -1)
      );
    };
  }, []);
};

export function useMountedState(initial) {
  const [state, setState] = useState(initial);
  const isMounted = useIsMounted();

  const update = next => {
    if (isMounted()) setState(next);
  };

  return [state, update];
}

export function useLocalJsonStorage(id, initial = {}) {
  const [state, setState, remove] = useLocalStorage(id, '{}', {
    raw: false,
    serializer: JSON.stringify,
    deserializer: JSON.parse,
  });

  return [R.mergeRight(initial, state), setState, remove];
}

export function useDebounced(fn, wait, { unmount = false }, deps) {
  const timerRef = useRef(null);
  const argsRef = useRef(null);
  const debounced = useCallback(
    (...args) => {
      argsRef.current = args;
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        fn(...argsRef.current);
      }, wait);
    },
    [fn, wait, ...deps]
  );
  const instant = useCallback(
    (...args) => {
      argsRef.current = args;
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
      }
      fn(...argsRef.current);
    },
    [fn, wait, ...deps]
  );
  const cancel = useCallback(() => {
    argsRef.current = null;
    if (timerRef.current !== null) {
      clearTimeout(timerRef.current);
    }
  }, [fn, wait, ...deps]);
  useEffect(
    () => () => {
      if (unmount && timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
        fn(...argsRef.current);
      }
    },
    []
  );
  return [debounced, instant, cancel];
}
