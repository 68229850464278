import type { SocketEvent, ServerEvents } from 'endpoints.base';
import qs from 'qs';
import moment from 'moment-timezone';
import { TokenError } from './api';

type EventType<T extends SocketEvent> = T extends SocketEvent<infer E, any>
  ? E
  : never;

type EventData<E extends string, T extends SocketEvent> = T extends SocketEvent<
  E,
  infer D
>
  ? D
  : never;

type EventHandler<E extends string, T extends SocketEvent> = (
  event: EventData<E, T>
) => void | Promise<void>;

interface SocketOpen extends Event {}
interface SocketClose extends CloseEvent {}
interface SocketError extends Event {}

type OpenHandler = (event: SocketOpen) => void;
type CloseHandler = (event: SocketClose) => void;
type ErrorHandler = (event: SocketError) => void;

type SkEvent = keyof WebSocketEventMap;
type SkMessage<K extends SkEvent = SkEvent> = WebSocketEventMap[K];
type SkHandler<K extends SkEvent = SkEvent> = (e: SkMessage<K>) => void;

const TOKEN_KEY = '__t';
const BASE_URL = import.meta.env.VITE_APP_UPDATES_URL;
const PING_INTERVAL = 12e3; // 12s
const RETRY_DELAY = 6e3; // 1s

export enum Code {
  ok = 1000,
  try_again = 1013,
  finish = 4000,
  ping_lost = 1000,
}

class SocketTarget<T extends SocketEvent> extends EventTarget {
  addSocket(socket: WebSocket) {
    socket.addEventListener(
      'open',
      event => void this.dispatcher('open', event)
    );
    socket.addEventListener(
      'message',
      event => void this.dispatcher('message', event)
    );
    socket.addEventListener(
      'close',
      event => void this.dispatcher('close', event)
    );
    socket.addEventListener(
      'error',
      event => void this.dispatcher('error', event)
    );
  }

  private dispatcher(name: string, detail: SkMessage) {
    const ev = new CustomEvent(name, { detail });
    this.dispatchEvent(ev);
  }

  private addSocketEventListener<K extends SkEvent>(
    event: K,
    handler: SkHandler<K>
  ) {
    const master = (event: CustomEvent) => handler(event.detail);
    this.addEventListener(event, master as EventListener);
    return () => void this.removeEventListener(event, master as EventListener);
  }

  addOpenEventListender(handler: OpenHandler) {
    return this.addSocketEventListener('open', handler);
  }

  addMessageEventListener<E extends EventType<T | ServerEvents>>(
    event: E,
    handler: EventHandler<E, ServerEvents>
  ) {
    return this.addSocketEventListener('message', message => {
      const data = JSON.parse(message.data);
      if (data.event === event) handler(data);
    });
  }

  addCloseEventListender(handler: CloseHandler) {
    return this.addSocketEventListener('close', handler);
  }

  addErrorEventListener(handler: ErrorHandler) {
    return this.addSocketEventListener('error', handler);
  }
}

interface Config {
  url: string;
  params?: Record<string, string>;
}
export default class Socket<T extends SocketEvent> {
  private events: SocketTarget<T>;
  private socket!: WebSocket;
  private ponged = true;
  private stats?: { lag: number; delta: number };
  private interval!: NodeJS.Timeout;
  private retry!: NodeJS.Timeout;

  get token() {
    const tk = localStorage.getItem(TOKEN_KEY);
    if (tk) return JSON.parse(tk)?.access_token;
    throw new TokenError('Socket could not retrieve token');
  }

  get ready() {
    return this.socket.readyState === WebSocket.OPEN;
  }

  constructor(config: Config) {
    this.events = new SocketTarget();
    this.connect(config);
  }

  private reconnect(config: Config) {
    clearTimeout(this.interval);
    delete this.interval;
    delete this.stats;
    this.ponged = true;
    clearTimeout(this.retry);
    this.retry = setTimeout(() => void this.connect(config), RETRY_DELAY);
  }

  private connect(config: Config) {
    try {
      const { url, params } = config;
      const token = this.token;
      const options = { indices: false, encode: false };
      const pamStr = qs.stringify({ token, ...params }, options);
      this.socket = new WebSocket([BASE_URL, url, '?', pamStr].join(''));
    } catch (error) {
      if (error instanceof TokenError) {
        console.error('Platform signed out', error.message);
      } else {
        this.reconnect(config);
        return;
      }
    }
    this.events.addSocket(this.socket);

    this.onsubscribed(() => {
      this.ping();
    });

    this.onpong(event => {
      this.ponged = true;
      if (event.server) {
        const { client, server } = event;
        const now = moment.utc().valueOf();
        const lag = (now - client) / 2;
        const delta = now - server + lag;
        this.stats = { lag, delta };
      }
    });

    this.onclose(event => {
      if (event.code === Code.finish) return;
      this.reconnect(config);
    });

    this.onfinish(() => {
      clearTimeout(this.interval);
      delete this.stats;
      this.ponged = true;
    });
  }

  private ping() {
    if (this.interval) return;

    this.interval = setInterval(() => {
      if (!this.ready) return;

      if (this.ponged) {
        this.ponged = false;
        const client = moment.utc().valueOf();
        this.send({ event: 'ping', client, ...this.stats });
      } else {
        this.ponged = true;
        clearInterval(this.interval);
        delete this.interval;
        this.close(Code.ping_lost);
      }
    }, PING_INTERVAL);
  }

  send(data: any) {
    if (this.ready) {
      this.socket.send(JSON.stringify(data));
      return true;
    }

    return false;
  }

  close(code: number = Code.ok) {
    this.socket.close(code);
  }

  onmessage<E extends EventType<T>>(event: E, handler: EventHandler<E, T>) {
    return this.events.addMessageEventListener(event, handler);
  }

  onclose(handler: CloseHandler) {
    return this.events.addCloseEventListender(handler);
  }

  oncode(code: Code, handler: CloseHandler) {
    return this.events.addCloseEventListender(event => {
      if (event.code === code) handler(event);
    });
  }

  onstart(handler: () => void) {
    return this.onsubscribed(handler);
  }

  onfinish(handler: CloseHandler) {
    return this.oncode(Code.ok, handler);
  }

  onfail(handler: ErrorHandler) {
    return this.events.addErrorEventListener(handler);
  }

  private onsubscribed(handler: EventHandler<'subscribed', ServerEvents>) {
    return this.events.addMessageEventListener('subscribed', handler);
  }

  private onpong(handler: EventHandler<'pong', ServerEvents>) {
    return this.events.addMessageEventListener('pong', handler);
  }

  finish() {
    this.close(Code.finish);
  }
}
