import React, { Fragment, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import {
  ColorSquare,
  NoDataAvailable,
  TooltipWrapper,
  useFilterTIData,
  useSharedTIData,
} from 'Pages/Dashboard';
import * as R from 'ramda';
import moment from 'moment-timezone';
import { Tooltip } from 'Components/nui';
import { useDeepCompareEffect, useToggle } from 'react-use';

const Chart = ({ type, data, step }) => {
  const [filter] = useFilterTIData();
  const [keys, setKeys] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [loading, toggle] = useToggle(true);

  useDeepCompareEffect(() => {
    if (R.isEmpty(data)) {
      setChartData([]);
      return;
    }

    let theDict = {};
    let theKeys = [];

    data.forEach(product => {
      const productData = R.propOr([], 'data', product);
      const label = product.label;

      theKeys.push([product.label, product.color]);
      productData.forEach(item => {
        if (!(item.dt in theDict)) {
          theDict[item.dt] = {};
        }
        theDict[item.dt][label] = R.propOr(0, 'value', item);
        theDict[item.dt][`${label}Color`] = product.color;
      });
    });

    let theList = [];
    const parse = (value, key) => {
      let dt = moment(key * 1);
      let date = dt.format('DD MMM');
      if (step === '1h') date = dt.format('HH:mm');
      if (step === '6h') date = dt.format('DD MMM, HH:mm');
      if (step === '1M') date = dt.format('MMM');

      theList.push({
        ...value,
        date,
      });
    };
    R.forEachObjIndexed(parse, theDict);

    setChartData(theList);
    setKeys(theKeys);
    toggle(false);
  }, [data, setChartData, setKeys]);

  return R.isEmpty(chartData) ? (
    !loading && (
      <div className="chart-container trade-volumes">
        <h3 className="mb-20">
          {type === 'Market' && 'Marketplace trade volumes'}
          {type === 'Tender' && 'Tender trade volumes'}
          <Tooltip
            className="icon-help-circled"
            placement="bottom"
            title={
              <span className="icon-tooltip">
                <span className="icon-info-circled" />
                {type === 'Market' &&
                  'Shows volume traded by product on the marketplace over the selected timeframe.'}
                {type === 'Tender' &&
                  'Shows volume traded by product in tenders over the selected timeframe.'}
              </span>
            }
          />
        </h3>
        <NoDataAvailable />
      </div>
    )
  ) : (
    <div className="chart-container trade-volumes">
      <h3 className="mb-20">
        {type === 'Market' && 'Marketplace trade volumes'}
        {type === 'Tender' && 'Tender trade volumes'}
        <Tooltip
          className="icon-help-circled"
          placement="bottom"
          title={
            <span className="icon-tooltip">
              <span className="icon-info-circled" />
              {type === 'Market' &&
                'Shows volume traded by product on the marketplace over the selected timeframe.'}
              {type === 'Tender' &&
                'Shows volume traded by product in tenders over the selected timeframe.'}
            </span>
          }
        />
      </h3>

      {
        <section>
          <div className="chart">
            <ResponsiveBar
              data={chartData}
              keys={keys.map(item => item[0])}
              groupMode="stacked"
              indexBy="date"
              margin={{ top: 0, right: 0, bottom: 50, left: 60 }}
              padding={0.5}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={({ id, data }) => String(data[`${id}Color`])}
              borderColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
              }}
              enableLabel={false}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -30,
                // legend: 'date',
                legendPosition: 'middle',
                legendOffset: 32,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: filter.uom,
                legendPosition: 'middle',
                legendOffset: -60,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
              }}
              tooltip={({ id, value, color, x, y }) => (
                <TooltipWrapper point={{ x, y }}>
                  <span className="inline-block valign-t mt-2 wp-10">
                    <ColorSquare color={color} />
                  </span>{' '}
                  <span className="inline-block valign-t wp-85">
                    <strong className="all-black">{id}</strong>
                    <br />
                    {value.toLocaleString()} {filter.uom}
                  </span>
                </TooltipWrapper>
              )}
              // layers={['grid', AlternateBarBackground(keys), 'axes', 'bars']}
            />
          </div>

          <ul className="chart-legend">
            {keys.map(item => (
              <li key={item[0]}>
                <span className="color-block mr-2 mt-2">
                  {' '}
                  <ColorSquare color={item[1]} />
                </span>{' '}
                <span className="name mr-15">{item[0]}</span>
              </li>
            ))}
          </ul>
        </section>
      }
    </div>
  );
};

export default () => {
  const [data] = useSharedTIData();
  const rawData = R.propOr([], 'quantity-by-time', data);
  const step = R.pathOr('1d', ['period', 'interval'], data);
  const apiError = data === 'Error';

  return apiError ? (
    <div className="trade-volume-containers">
      {rawData.map(item => (
        <Chart key={item.key} type={item.key} data={data} step={step} />
      ))}
    </div>
  ) : (
    <div className="flex-desktop gap-20">
      {rawData.map((item, index) => (
        <Fragment key={item.key}>
          {index > 0 && <hr className="mt-30 mb-30 hide-for-xlarge" />}
          <Chart key={item.key} type={item.key} data={item.data} step={step} />
        </Fragment>
      ))}
    </div>
  );
};
