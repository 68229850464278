import React from 'react';
import { Route, Link, Switch } from 'react-router-dom';
import { useStoreState } from '~/store';
import NavList from 'Components/nui/NavList';
import { matchUrl, routeUrl } from '~/router';
import { FormattedMessage } from 'react-intl';

export default () => {
  const edit = useStoreState(
    state =>
      state.auth.solution.roles
        .map(r => r.division.edittenders)
        .includes(true) &&
      (state.auth.solution.tenderlogic === 'purchase'
        ? state.auth.solution.roles.map(r => r.role).includes('manager')
        : state.auth.solution.roles.map(r => r.role).includes('manager') ||
          state.auth.solution.roles.map(r => r.role).includes('trader'))
  );

  return (
    <Switch>
      <Route path={matchUrl('tender-new')} />
      <Route path={matchUrl('tender-view')} />
      <Route>
        {edit && (
          <Link
            className="nui-button nui-primary new-tender"
            title="Create a new tender"
            to={routeUrl('tender-new')}
          >
            <FormattedMessage
              id="tender-list-button-new-tender"
              description="Label for `new tender` button on tender list page"
              defaultMessage="New tender"
            />
          </Link>
        )}
        <NavList>
          <NavList.Tab
            exact
            url={routeUrl('tender-overview')}
            path={matchUrl('tender-overview')}
          >
            <FormattedMessage
              id="tender-list-tab-overview"
              description="Label for `Overview` tab on tender list page"
              defaultMessage="Overview"
            />
          </NavList.Tab>
          <NavList.Tab
            url={routeUrl('tender-history')}
            path={matchUrl('tender-history')}
          >
            <FormattedMessage
              id="tender-list-tab-history"
              description="Label for `History` tab on tender list page"
              defaultMessage="History"
            />
          </NavList.Tab>
        </NavList>
      </Route>
    </Switch>
  );
};
