import React from 'react';
import classnames from 'classnames';
import FNumber from 'Components/FNumber';
import { Tooltip } from 'Components/nui';
import * as R from 'ramda';
import { FormattedMessage } from 'react-intl';
import { getDecimalCount } from '~/utils';
import { ColumnTree } from '../hooks/useTable/defineColumns';
import { IndexUnit, PriceIndex } from 'Components/nui/PriceIndex';
import moment from 'moment-timezone';
import { IndexSummary } from 'Components/Data';

export const LegendPriceColumn = ColumnTree.defineNode(
  'legend-price',
  ({
    solutionColumns,
    sortDirection,
    sortColumn,
    setSort,
    solutionDivisionIds,
    expandRow,
  }) => ({
    title:
      R.path(['reference_price', 'type'], solutionColumns) === 'display' ? (
        <span>
          <FormattedMessage
            id="marketplace-table-subheading-price"
            description="Table sub-heading, conditional, repeated for both"
            defaultMessage="Price"
          />
        </span>
      ) : (
        <a
          href="#price"
          className={classnames('icon-sort', 'sortem', {
            [sortDirection]: sortColumn === 'price',
          })}
          onClick={() => setSort('price')}
        >
          <FormattedMessage
            id="marketplace-table-subheading-price"
            description="Table sub-heading, conditional, repeated for both"
            defaultMessage="Price"
          />
        </a>
      ),
    key: 'price',
    className: 'col-product-price-alt',
    render: (text, record, index) => {
      let best: number | null = null;
      let my = false;
      if (record.counters && record.counters.length > 0) {
        R.forEach<any /* TODO: Fix type */>(x => {
          if (
            x.status.toLowerCase() === 'working' &&
            (best === null ||
              (record.type === 'offer' && x.price.val > best) ||
              (record.type === 'bid' && x.price.val < best))
          ) {
            best = x.price.val;
            my =
              (x.division && solutionDivisionIds.includes(x.division.id)) ||
              false;
          }
        }, record.counters);
      }

      if (record._price.status === 'open') {
        return (
          <div className="td-content">
            <span className="openordertype">Open {record.type}</span>
          </div>
        );
      }
      if (record._price.status === 'undetermined') {
        return (
          <div className="td-content">
            <span className="openordertype">Not available</span>
          </div>
        );
      }

      if (record._price.status === 'determined') {
        const _price = R.prop('_price', record);
        const step = record.index
          ? record.index.step
          : R.propOr(0.01, 'step', _price);
        const _decimalCount = getDecimalCount(step);
        return (
          <div className="td-content">
            <div className="flex-tags">
              <span className="product-price inline mr-5">
                <PriceIndex order={record} />
              </span>

              {best !== null && (
                <Tooltip
                  title={
                    <span className="icon-tooltip">
                      <span className="icon-info-circled" />
                      Best counter
                    </span>
                  }
                >
                  <a
                    className={classnames('best-counter', { my })}
                    href="#counters"
                    onClick={expandRow('counters', record.id)}
                  >
                    <FNumber value={best} decimalCount={_decimalCount} />
                  </a>
                </Tooltip>
              )}
              {record.index && (
                <Tooltip
                  title={
                    <span className="icon-tooltip">
                      <span className="icon-info-circled" />
                      Price is based on{' '}
                      <IndexSummary
                        index={record.index}
                        date={moment(record.indexdate)}
                        className="inline-with-surrounding font-inherit"
                      />
                    </span>
                  }
                >
                  <span className="tag-index">Index</span>
                </Tooltip>
              )}
            </div>
            <span className="product-unit">
              <IndexUnit order={record} />
            </span>
          </div>
        );
      }
      return null;
    },
  })
);
