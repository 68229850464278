import React, { useContext, useState, useEffect } from 'react';
import { Upload, Button, Loading } from 'Components/nui';
import { useParams, Link } from 'react-router-dom';
import { ApiContext } from '~/context';
import { useUpdate } from 'react-use';
import * as R from 'ramda';
import { getEnv } from '~/utils';
import Helmet from 'Components/Helmet';
import { useSharedProduct } from '..';
import { toast } from 'react-toastify';

const unify = item => ({
  ...item,
  uid: item.id,
  name: item.filename,
  size: item.content_length,
  status: 'done',
});

const Section = ({ id, name, files, compliance }) => {
  const api = useContext(ApiContext);
  const [fileList, setFileList] = useState(files);
  const update = useUpdate();
  const params = useParams();

  const baseUrl = getEnv(import.meta.env.VITE_APP_API_BASE);

  const props = {
    multiple: true,
    type: 'drag',
    name: 'attachment',
    accept: 'application/pdf',
    beforeUpload(file) {
      // console.log('beforeUpload', file.name);
    },
    onStart: file => {
      file.status = 'uploading';
      setFileList(R.append(file, fileList));
    },
    onSuccess(file) {
      // console.log('onSuccess', file);
      replaceFile(file);
    },
    onProgress(step, file) {
      update();
      file.percent = Math.round(step.percent);
      replaceFile(file);
    },
    onError(err) {
      // console.log('onError', err);
    },
    customRequest({ file, filename, onError, onProgress, onSuccess }) {
      const formData = new FormData();
      formData.append(filename, file);
      formData.append('compliance_id', compliance * 1);

      api.ax
        .post(`${baseUrl}/products/${params.id}/specifications`, formData, {
          onUploadProgress: ({ total, loaded }) => {
            onProgress(
              { percent: Math.round((loaded / total) * 100).toFixed(2) },
              file
            );
          },
        })
        .then(({ data: response }) => {
          onSuccess({
            ...unify(response.specification),
            uid: file.uid,
          });
          toast('Specification(s) added successfully', {
            type: 'success',
          });
        })
        .catch(onError);

      return {
        abort() {
          console.log('upload progress is aborted.');
        },
      };
    },
  };

  const withoutFile = s_id => R.filter(n => n.id !== s_id, fileList);

  const replaceFile = file =>
    setFileList(prevState => {
      const index = R.findIndex(R.propEq('uid', file.uid), prevState);
      const newList = R.filter(n => n.uid !== file.uid, prevState);
      return R.insert(index, file, newList);
    });
  const deleteFile = async s_id => {
    setFileList(withoutFile(s_id));
    await api.getData(
      {
        type: 'products',
        id: `${params.id}/specifications/${s_id}`,
      },
      null,
      {
        method: 'delete',
      }
    );
    toast('The pdf-file is removed', {
      type: 'success',
    });
  };

  return (
    <>
      <label htmlFor={id}>{name}</label>
      <Upload id={id} {...props} />

      <ul className="file-list">
        {fileList.map(item => (
          <li key={item.uid}>
            <span className="icon-file-pdf" title="File type PDF"></span>{' '}
            <Button
              type="buttonlink"
              onClick={() => api.getPdf(item.href, item.filename)}
            >
              <span title={item.name}>{item.name}</span>
            </Button>
            <Button
              icon="trash"
              type="reverse"
              onClick={() => deleteFile(item.id)}
            />
            {item.status === 'uploading' && (
              <div
                className="progress"
                style={{
                  width: `${item.percent}%`,
                }}
              />
            )}
          </li>
        ))}
      </ul>
    </>
  );
};

export default () => {
  const api = useContext(ApiContext);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [specs, setSpecs] = useState([]);
  const [product, _] = useSharedProduct();

  useEffect(() => {
    const fetch = async () => {
      const response = await api.getData({
        type: 'products',
        id: `${id}/specifications`,
        getResult: x => x.specifications,
      });

      setSpecs(response.map(item => unify(item)));
      setLoading(false);
    };

    fetch();
  }, [api, id, setLoading, setSpecs]);

  const complianceFilter = c_id =>
    R.filter(n => n.compliance.id === c_id, specs);

  if (!product) return null;
  if (loading) return <Loading size="medium" />;

  return (
    <>
      <Helmet>
        <title>Edit product {product.name} - Loading details</title>
      </Helmet>
      <p>
        Upload your product specifications, certifications and other related
        documents here. These can only be in pdf format. If your documents are
        in any other file format, you&apos;ll need to convert them first.
      </p>

      <h3>Product specifications</h3>
      <div className="fieldset specs file-section mb-40">
        <Section
          id="specifications-cert"
          name="Product specifications"
          compliance={10000}
          files={complianceFilter(10000)}
        />
      </div>

      <h4>Certifications / Accreditations</h4>

      <p>Select which certifications you wish to add (PDF only).</p>
      <div className="fieldset file-section mb-40">
        <Section
          id="halal-certifications"
          name="Halal certifications"
          compliance={10001}
          files={complianceFilter(10001)}
        />
      </div>
      <div className="fieldset file-section mb-40">
        <Section
          id="kosher-certifications"
          name="Kosher certifications"
          compliance={10002}
          files={complianceFilter(10002)}
        />
      </div>
      <div className="fieldset file-section mb-40">
        <Section
          id="organic-certifications"
          name="Organic certifications"
          compliance={10003}
          files={complianceFilter(10003)}
        />
      </div>
      <div className="fieldset file-section mb-40">
        <Section
          id="other-certifications"
          name="Other certifications"
          compliance={10004}
          files={complianceFilter(10004)}
        />
      </div>
      <div className="fieldset file-section mb-40">
        <Section
          id="low-carbon-certifications"
          name="Low Carbon certifications"
          compliance={10005}
          files={complianceFilter(10005)}
        />
      </div>
      <div className="sticky-btm">
        <Link
          className="button primary mb-10"
          to={`/products/${product.id}/edit/loading-details`}
        >
          Next step
        </Link>
      </div>
    </>
  );
};
