import type { Tender } from '~/models/tenders';
import React from 'react';
import { useMountedState } from '~/hooks';
import { Button, Modal } from 'Components/nui';
import * as service from '~/services/tenders';
import { toast } from 'react-toastify';
import { usePathTools, routeUrl } from '~/router';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

type TenderProp = Record<'tender', Tender>;

export default ({ tender }: TenderProp) => {
  const [visible, setVisible] = useMountedState(false);
  const [loading, setLoading] = useMountedState(false);
  const history = useHistory();

  const toggle = (value: boolean) => () => {
    setVisible(value);
  };

  const { matchParams } = usePathTools();
  const { tenderId = '' } = matchParams('tender-view');

  return (
    <>
      <Button onClick={toggle(true)}>
        <FormattedMessage
          id="tender-buttton-actions-cancel"
          description="Button for `Cancel tender` in tender owner actions dropdown"
          defaultMessage="Cancel tender"
        />
      </Button>
      {visible && (
        <Modal close={toggle(false)} size="small">
          <h2>
            <FormattedMessage
              id="tender-modal-cancel-heading"
              description="Heading for cancel tender modal"
              defaultMessage="Confirm"
            />
          </h2>
          <p className="pb-10">
            <FormattedMessage
              id="tender-modal-tender-name"
              description="Heading for cancel tender modal"
              defaultMessage="Tender name: <strong >{name}</strong>"
              values={{
                name: tender.name,
                strong: chunks => (
                  <strong className="all-black">{chunks}</strong>
                ),
              }}
            />
          </p>
          <p>
            <FormattedMessage
              id="tender-modal-cancel-text"
              description="Text for cancel tender modal"
              defaultMessage="Are you sure you want to cancel this tender?"
            />
          </p>
          <hr />
          <div className="button-set">
            <Button
              loading={loading}
              disabled={loading}
              onClick={async () => {
                setLoading(true);

                if (tenderId) history.push(routeUrl('tender-overview'));

                const result = await service.actions.cancelTender(tender.id);
                if (result?.success)
                  toast.success(
                    <>
                      Tender <strong className="nowrap">{tender.name}</strong>{' '}
                      cancelled successfully
                    </>
                  );
                else if (result?.errors)
                  toast.error(<>There was an error cancelling this tender</>);

                setLoading(false);
                setVisible(false);
              }}
            >
              <FormattedMessage
                id="tender-modal-cancel-button-confirm"
                description="Button for `Confirm` in cancel tender modal"
                defaultMessage="Confirm"
              />
            </Button>
            <Button type="simple" onClick={toggle(false)}>
              <FormattedMessage
                id="tender-modal-cancel-button-close"
                description="Button for `Close` in cancel tender modal"
                defaultMessage="Close"
              />
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};
