import { Popover, Checkbox, Icon, Radio, DatePicker } from 'antd';
import { FilterTypes, IFilterOption, useFilters } from './context';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { useState, Fragment } from 'react';

function findByValue(arr, value) {
  for (let obj of arr) {
    if (obj.value === value) {
      return obj;
    }
    if (obj.children && Array.isArray(obj.children)) {
      return findByValue(obj.children, value);
    }
  }
  return null;
}

const FilterDropdown = ({
  id,
  label,
  menuLabel,
  type,
  options,
  showSelectedCount = true,
  subFieldId,
  subFieldType,
  onChange,
  subFieldOnChange,
  onClear,
  canSelectAll = false,
}: {
  id: string;
  label: React.ReactNode;
  menuLabel?: React.ReactNode;
  type: FilterTypes;
  options?: IFilterOption[];
  showSelectedCount?: boolean;
  subFieldId?: string;
  subFieldType?: FilterTypes;
  onChange?: (value: any) => void;
  subFieldOnChange?: (value: any) => void;
  onClear?: () => void;
  canSelectAll?: boolean;
}) => {
  const [visible, setVisible] = useState(false);
  const { filters, setFilters } = useFilters();
  const values = filters[id]?.items ?? [];
  const subValues = subFieldId ? filters[subFieldId]?.items ?? [] : [];

  const clearDropdownFilter = (id: string) => {
    const newFilters = { ...filters };
    if (newFilters[id]) {
      newFilters[id].items = [];
    }
    if (subFieldId && newFilters[subFieldId]) {
      newFilters[subFieldId].items = [];
    }
    setFilters(newFilters);
    onClear && onClear();
  };

  const handleCheckboxChange = (id: string, option: any) => {
    let newFilters = { ...filters };
    if (!newFilters[id]) {
      newFilters[id] = {
        type: FilterTypes.CHECKBOXES,
        items: [],
      };
    }
    if (newFilters[id].items.includes(option.value)) {
      newFilters[id].items = newFilters[id].items.filter(
        x => x != option.value
      );
      // uncheck all children
      option.children?.forEach(child => {
        newFilters[id].items = newFilters[id].items.filter(
          x => x != child.value
        );
      });
    } else {
      if (
        option.children &&
        option.children.every(child =>
          newFilters[id].items.includes(child.value)
        )
      ) {
        newFilters[id].items = newFilters[id].items.filter(
          x => x != option.value
        );
        option.children.forEach(child => {
          newFilters[id].items = newFilters[id].items.filter(
            x => x != child.value
          );
        });
      } else {
        if (!option.children) {
          newFilters[id].items.push(option.value);
        }
        // check all children
        option.children?.forEach(child => {
          newFilters[id].items.push(child.value);
          newFilters[id].items = [...new Set(newFilters[id].items)];
        });
      }
    }
    setFilters(newFilters);
    onChange && onChange(newFilters);
  };

  const handleDateRangeChange = (id: string, values: any, ret: boolean) => {
    const newFilters = { ...filters };
    if (!newFilters[id]) {
      newFilters[id] = { type: FilterTypes.DATE, items: [] };
    }
    newFilters[id].items = values.map(x => x.toDate());
    if (ret) {
      return newFilters;
    }
    setFilters(newFilters);
  };

  const handleRadioChange = (id: string, option: any, ret: boolean) => {
    const newFilters = { ...filters };
    if (!newFilters[id]) {
      newFilters[id] = {
        type: FilterTypes.RADIO,
        items: [],
      };
    }
    if (option.value === '') {
      newFilters[id].items = [];
      return newFilters;
    }
    newFilters[id].items = [option.value];
    if (ret) {
      return newFilters;
    }
    setFilters(newFilters);
    onChange && onChange(newFilters);
  };

  const SubField = ({ subFieldId, option }) => {
    return (
      <div className="sub-field">
        {subFieldType === FilterTypes.DATE && (
          <DatePicker.RangePicker
            value={
              Array.isArray(subValues) && subValues.length === 2
                ? subValues.map(x => moment(x))
                : undefined
            }
            format="DD MMM YYYY"
            onChange={value => {
              const dateRangeState = handleDateRangeChange(
                subFieldId,
                value,
                true
              );
              const radioChangeState = handleRadioChange(id, option, true);
              const newFilters = { ...dateRangeState, ...radioChangeState };
              setFilters(newFilters);
              subFieldOnChange && subFieldOnChange(newFilters);
            }}
          />
        )}
      </div>
    );
  };

  const panel = (
    <>
      {<h4 className="filter-dropdown-title">{menuLabel ?? label}</h4>}
      {type === FilterTypes.RADIO && (
        <Radio.Group value={values[0] ?? ''}>
          <ul className="filter-dropdown-list">
            {options?.map((option, k) => (
              <li key={k}>
                <Radio
                  value={option.value}
                  onChange={() => handleRadioChange(id, option)}
                >
                  {option.translation ? (
                    <FormattedMessage
                      id={option.translation.id}
                      description={option.translation.description}
                      defaultMessage={option.label}
                    />
                  ) : (
                    option.label
                  )}
                </Radio>
                {option.hasSubField && subFieldId && (
                  <SubField subFieldId={subFieldId} option={option} />
                )}
              </li>
            ))}
          </ul>
        </Radio.Group>
      )}

      {type === FilterTypes.CHECKBOXES && (
        <ul className="filter-dropdown-list">
          {options?.map((option, k) => {
            const isGroup = option.children && option.children.length > 0;

            const allChilrenChecked =
              isGroup &&
              option.children?.every(child => values.includes(child.value));

            const someChilrenChecked =
              isGroup &&
              !allChilrenChecked &&
              option.children?.some(child => values.includes(child.value));

            return (
              <Fragment key={k}>
                <li key={k} className={isGroup ? 'is-group' : ''}>
                  <Checkbox
                    indeterminate={someChilrenChecked}
                    name={option.value}
                    checked={
                      isGroup
                        ? allChilrenChecked
                        : values.includes(option.value)
                    }
                    onChange={() => handleCheckboxChange(id, option)}
                  >
                    {option.label}
                  </Checkbox>
                </li>
                {option.children?.map((option, k) => (
                  <li key={k}>
                    <Checkbox
                      name={option.value}
                      checked={values.includes(option.value)}
                      onChange={() => handleCheckboxChange(id, option)}
                    >
                      {option.label}
                    </Checkbox>
                  </li>
                ))}
              </Fragment>
            );
          })}
        </ul>
      )}

      {type == FilterTypes.DATE && (
        <div className="mt-6">
          <DatePicker.RangePicker
            value={
              Array.isArray(values) && values.length === 2
                ? values.map(x => moment(x))
                : undefined
            }
            format="DD MMM YYYY"
            onChange={value => handleDateRangeChange(id, value)}
          />
        </div>
      )}

      <div className="mt-10 align-right">
        {canSelectAll && (
          <>
            {options?.length === values.length ? (
              <button
                className="button simple filter-dropdown-clear-button mr-5"
                title="Deselect all"
                onClick={() => {
                  setFilters({
                    ...filters,
                    [id]: {
                      type: FilterTypes.CHECKBOXES,
                      items: [],
                    },
                  });
                }}
              >
                <FormattedMessage
                  id="filters-deselect-all"
                  description="Deselect all button in filter panels"
                  defaultMessage="Deselect all"
                />
              </button>
            ) : (
              <button
                className="button simple filter-dropdown-clear-button mr-5"
                title="Select all"
                onClick={() => {
                  setFilters({
                    ...filters,
                    [id]: {
                      type: FilterTypes.CHECKBOXES,
                      items: options?.map(option => option.value),
                    },
                  });
                }}
              >
                <FormattedMessage
                  id="filters-select-all"
                  description="Select all button in filter panels"
                  defaultMessage="Select all"
                />
              </button>
            )}
          </>
        )}
        <button
          className="button simple filter-dropdown-clear-button"
          title="Clear"
          onClick={() => {
            setVisible(false);
            clearDropdownFilter(id);
          }}
        >
          <FormattedMessage
            id="filters-clear"
            description="Clear button in filter panels"
            defaultMessage="Clear"
          />
        </button>
      </div>
    </>
  );

  const invalid = type === FilterTypes.DATE && values.length !== 2;

  const getActiveLabel = (type, values) => {
    let activeLabel;
    if (type === FilterTypes.DATE && values.length === 2) {
      activeLabel = `${moment(values?.[0]).format('D MMM YYYY')} - ${moment(
        values?.[1]
      ).format('D MMM YYYY')}`;
    } else if (values.length === 1 && Array.isArray(options)) {
      const activeOption = findByValue(options, values[0]);

      if (activeOption?.hasSubField) {
        return getActiveLabel(subFieldType, subValues);
      } else {
        activeLabel = activeOption?.label ?? label;
      }
    } else {
      activeLabel = (
        <>
          {label}{' '}
          {values.length > 0 && showSelectedCount && <>({values.length})</>}
        </>
      );
    }
    return activeLabel;
  };

  return (
    <Popover
      content={panel}
      trigger="click"
      placement="bottomLeft"
      visible={visible}
      onVisibleChange={setVisible}
    >
      <button
        className={`filter-dropdown-chip ${
          !invalid && values.length > 0 ? 'active' : ''
        }`}
        onClick={() => setVisible(!visible)}
      >
        {getActiveLabel(type, values)} <Icon type="caret-down" />
      </button>
    </Popover>
  );
};

export default FilterDropdown;
