import type { Auth } from 'Components/Authorised';
import { allowRolesIf } from 'Components/Authorised';
import { api } from '~/api';
import * as R from 'ramda';
import qs from 'qs';

type TModelType = 'company' | 'address' | 'links' | 'locations' | 'users';
type TFormStatus =
  | 'incomplete'
  | 'pending'
  | 'rejected'
  | 'processed'
  | 'cancelled';

interface IDivisionRef {
  id: string;
  href: string;
  shortcode: string;
  name: string;
}

export interface ICustomerRequest {
  id: string;
  formstatus: TFormStatus;
  comment: string;
  feedback: string;
  division?: IDivisionRef;
  company: any;
  address: any;
  links: any[];
  locations: any[];
  users: any[];
}

const allowCustomerRequest = (auth: Auth) => auth.isSolutionOwner;

interface IGetOverview {
  solution: string;
  filters?: {
    page?: number;
    limit?: number;
    formstatus?: TFormStatus[];
  };
}

interface IGetRequest {
  id: string;
}

interface IGetSchema {
  name: TModelType;
  customer: { id?: string };
  solution: string;
}

interface IEdit {
  customer: { id?: string };
  model: TModelType;
  data: any;
  solution: string;
}

interface IRemove {
  customer: { id?: string };
  model: 'links' | 'locations' | 'users';
  id: string;
}

interface ISubmit {
  customer: { id?: string };
  comment?: string;
}

export class CustomerRequestController {
  static async getOverview({ solution, filters }: IGetOverview) {
    return await api.request({
      method: 'get',
      url: 'customer-request',
      params: { solution, sort: '-created', ...filters },
      paramsSerializer: (p: any) => qs.stringify(p, { indices: false }),
    });
  }

  static async getRequest({ id }: IGetRequest) {
    return await api.request({ method: 'get', url: `/customer-request/${id}` });
  }

  static async deleteRequest({ id }: IGetRequest) {
    return await api.request({
      method: 'delete',
      url: `/customer-request/${id}`,
    });
  }

  static async getSchema({ name, customer, solution }: IGetSchema) {
    const url = customer.id
      ? `/customer-request/${customer.id}/${name}/schema`
      : '/customer-request/schema';
    return await api.request({ method: 'get', url, params: { solution } });
  }

  static async edit({ customer, model, data, solution }: IEdit) {
    if (customer.id) {
      const method =
        data.id || !R.includes(model, ['links', 'locations', 'users'])
          ? 'put'
          : 'post';

      const base = `/customer-request/${customer.id}/${model}`;
      const url = data.id ? base + `/${data.id}` : base;

      return await api.request({ method, url, data });
    } else {
      return await api.request({
        method: 'post',
        url: `/customer-request`,
        data: {
          ...data,
          solution,
        },
      });
    }
  }

  static async remove({ customer, model, id }: IRemove) {
    return await api.request({
      method: 'delete',
      url: `/customer-request/${customer.id}/${model}/${id}`,
    });
  }

  static async submit({ customer, comment }: ISubmit) {
    return await api.request({
      method: 'patch',
      url: `/customer-request/${customer.id}`,
      data: { submit: true, comment },
    });
  }

  static get _acl() {
    return {
      view: allowRolesIf('manager')(allowCustomerRequest),
      add: allowRolesIf('manager')(allowCustomerRequest),
      edit: allowRolesIf('manager')(allowCustomerRequest),
      delete: allowRolesIf('manager')(allowCustomerRequest),
    };
  }
}
