import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from 'antd';
import * as R from 'ramda';
import Button from '~/components/nui/Button';
import { useStoreState, useStoreActions } from '~/store';
import createFields from './createFields';
import { FormWrapper } from 'Components/form';
import { mergeSchema } from 'Components/form/utils';
import type { WrappedFormUtils } from 'antd/lib/form/Form';
import { getStatus } from '~/store/bannerModel/utils';

interface INewUpdate {
  form: WrappedFormUtils;
}

const EditBannerForm = ({ form }: INewUpdate) => {
  const newSchema = useStoreState(state => state.banners.schema);
  const editSchema = useStoreState(state => state.banners.editSchema);
  const item = useStoreState(state => state.banners.selectedItem);
  const solutionId = useStoreState(R.pathOr(null, ['auth', 'solution', 'id']));
  const update = useStoreActions(actions => actions.banners.apiUpdate);
  const add = useStoreActions(actions => actions.banners.apiAdd);
  const mode = useStoreActions(actions => actions.banners.mode);

  const status = item ? getStatus(item) : null;

  const schema = useMemo(
    () => mergeSchema(item ? editSchema : newSchema, createFields(form, item)),
    [newSchema, editSchema, item, mode]
  );

  const formWrapper = useMemo(
    () => new FormWrapper(form, item ? { ...item } : undefined, schema),
    [item, schema]
  );

  useEffect(() => {
    if (!form) return;
    if (status === 'expired' && !form.isFieldTouched('start'))
      form.setFieldsValue({ start: undefined });
  }, [item, schema, formWrapper]);

  if (solutionId === null) return null;

  return (
    <Form>
      {formWrapper.render()}
      <hr />
      <div className="button-set">
        <Button
          type="primary"
          onClick={async () => {
            let data = formWrapper.serialize();
            if (!data) return;
            if (item) {
              const result = await update({
                ...data,
                style: 'info',
                start: status !== 'live' ? data.start : undefined,
                id: item.id,
              });
              formWrapper.setErrors(
                result.type === 'error' ? result.errors : null
              );
            } else {
              const result = await add({ ...data, solution: solutionId });
              formWrapper.setErrors(
                result.type === 'error' ? result.errors : null
              );
            }
          }}
        >
          {item ? (
            <FormattedMessage
              id="banners-edit"
              defaultMessage="Update"
              description="Update banner label"
            />
          ) : (
            <FormattedMessage
              id="banners-create"
              defaultMessage="Create"
              description="Create banner label"
            />
          )}
        </Button>
        <Button
          type="simple"
          onClick={() => {
            mode.pop();
          }}
        >
          <FormattedMessage
            id="banners-edit-cancel"
            defaultMessage="Cancel"
            description="Cancel edit / create banner label"
          />
        </Button>
      </div>
    </Form>
  );
};
const WrappedForm = Form.create()(EditBannerForm);

export default () => {
  return <WrappedForm />;
};
