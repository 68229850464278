import React, { useState, useEffect } from 'react';

import { Alert, Button, Loading } from 'Components/nui';
import { api } from '~/api';
import * as R from 'ramda';
import moment from 'moment-timezone';
import { authService } from '~/services/auth-service';
import { Link } from 'react-router-dom';

const isPhone = (type, description) => {
  return description?.includes(type);
};

const sortByDate = (a, b) => {
  return moment(b?.last_seen).diff(moment(a?.last_seen));
};

export default () => {
  const [loading, setLoading] = useState(true);
  const [browsers, setBrowsers] = useState([]);
  const [devices, setDevices] = useState([]);
  const code = authService.loadTrusted();

  const trusted = R.find(R.propEq('code', code))(browsers);

  const fetchBrowsers = async () => {
    const data = await api.getData2('/user/trusted-browsers');
    setBrowsers(R.pathOr([], ['trusted-browsers'], data));
  };

  const fetchDevices = async () => {
    const data = await api.getData2('/user/devices');
    setDevices(R.pathOr([], ['devices'], data));
  };

  useEffect(() => {
    setLoading(true);
    fetchBrowsers();
    fetchDevices();
    Promise.all([fetchBrowsers, fetchDevices]).then(
      () => void setLoading(false)
    );
  }, []);

  const deleteBrowsers = async id => {
    setBrowsers(state => R.filter(x => x.code !== id, state));
    if (trusted && trusted.code === id) authService.clearTrusted();
    await api.deleteData(`/user/trusted-browsers/${id}`);
  };

  const deleteDevices = async id => {
    await api.deleteData(`/user/devices/${id}`);
    setDevices(R.reject(R.propEq('id', id)));
  };

  const addBrowser = async () => {
    setLoading(true);
    const response = await api.postData('/user/trusted-browsers', {});
    authService.saveTrusted(
      R.pathOr('', ['trusted-browser', 'code'], response)
    );
    await fetchBrowsers();
    setLoading(false);
  };

  if (loading)
    return (
      <div className="panel-loading">
        <Loading size="medium" />
      </div>
    );

  return (
    <div className="wrapper">
      <h2>Security</h2>
      <h3 className="numbered outside nowrap">Trusted browsers</h3>
      <p className="justify">
        Trusting a browser allows you to auto sign in from links sent to you in
        emails. You&apos;ll also need to select &lsquo;Auto sign in from
        emails&rsquo; within <Link to="/account/profile">Profile settings</Link>
        .
      </p>
      <ul className="browser-list">
        {browsers.map(item => (
          <li key={item.code}>
            <Button
              onClick={() => deleteBrowsers(item.code)}
              icon="trash"
              title="Remove as trusted browser"
            />
            <div>
              <div className="since">
                <span className="mini-label">Created on</span>
                {moment(item.created).format('lll')}
              </div>
              <div className="expires">
                <span className="mini-label">Expires on</span>
                {moment(item.expires).format('lll')}
              </div>
            </div>
            <div className="user-agent">
              <span className="mini-label">User agent</span>
              {item.useragent}
            </div>

            {trusted && trusted.code === item.code && (
              <div className="current">Current browser</div>
            )}
          </li>
        ))}
      </ul>

      {browsers.length === 0 && (
        <Alert hasicon className="mb-20">
          No trusted browsers found
        </Alert>
      )}

      {!trusted && <Button onClick={addBrowser}>Add current browser</Button>}

      <h3 className="mt-20 numbered outside nowrap">Trusted devices</h3>
      <p className="">
        You have signed into the mobile app using these devices and may still be
        receiving push notifications on these devices if still logged in. Please
        remove any device(s) not in use.
      </p>
      {devices.length === 0 ? (
        <Alert hasicon className="mb-20">
          No trusted devices found
        </Alert>
      ) : (
        <ul className="devices-list">
          {devices
            .filter(device => device.client === 'internal' && device?.device)
            .sort(sortByDate)
            .map(device => (
              <li key={device.id}>
                <div className="image-container">
                  {isPhone('iPhone', device.device) ? (
                    <span className="icon-apple" title="iPhone"></span>
                  ) : isPhone('Android', device.device) ? (
                    <span className="icon-android" title="Android"></span>
                  ) : (
                    <span className="icon-mobile" title="Mobile phone"></span>
                  )}
                </div>
                <div className="since">
                  <span className="mini-label">Device name</span>
                  {device.device.split(',')[1]?.substring(1)
                    ? device.device.split(',')[1].substring(1)
                    : 'Unknown'}
                </div>
                {device?.last_seen && (
                  <div className="since">
                    <span className="mini-label">Last seen</span>
                    {moment(device?.last_seen).format('lll')}
                  </div>
                )}

                <Button
                  onClick={() => deleteDevices(device.id)}
                  icon="trash"
                  className=" "
                  title="Remove this device"
                />
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};
