import React from 'react';
import { useAsync } from 'react-use';
import { LoadingState } from 'Components/Layout';
import { useParams } from 'react-router-dom';
import { Trade } from '~/models';
import { api } from '~/api';
import Helmet from 'Components/Helmet';
import LoadingDetails from 'Components/Lists/LoadingDetails';
import ExtraDetails from 'Components/Lists/ExtraDetails';
import * as R from 'ramda';
import TradeDetails from 'Components/Lists/TradeDetails';

export default ({ tradeData }) => {
  const { id } = useParams();

  const raw = tradeData
    ? { loading: false, value: tradeData }
    : useAsync(async () => {
        const data = await api.getData({
          type: 'trades',
          id,
          getResult: x => new Trade(x.trade),
        });

        return data;
      }, [id]);

  return (
    <div className="last-trade">
      <Helmet>
        <title>Last trade</title>
      </Helmet>

      <h2 className="light">
        {raw.loading ? 'Loading trade...' : 'Last trade'}
      </h2>

      <LoadingState state={raw}>
        {trade => (
          <>
            <div className="detail-lists">
              <div className="list-box has-unit-box">
                <TradeDetails trade={trade} />
              </div>
              <div className="list-box loading-extra">
                <div className="list-box">
                  <LoadingDetails
                    loading={trade.loading}
                    unit={R.path(['volume', 'unit'], trade)}
                  />
                </div>
                <div className="list-box">
                  <ExtraDetails record={trade} />
                </div>
              </div>
            </div>
          </>
        )}
      </LoadingState>
    </div>
  );
};
