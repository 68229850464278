import React from 'react';
import Breadcrumb from 'Components/Breadcrumb';
import { Route, Switch } from 'react-router-dom';
import { matchUrl, routeUrl, usePathTools, withPreload } from '~/router';
import * as service from '~/services/tenders';
import { FormattedMessage } from 'react-intl';

const paths = {
  history: 'tender-history',
  analytics: 'tender-analytics',
  overview: 'tender-overview',
} as const;

export default () => {
  const { pathActive } = usePathTools();
  const active =
    (['history', 'analytics'] as const).find(p => pathActive(paths[p])) ||
    'overview';

  return (
    <Breadcrumb.Items>
      <Switch>
        <Route path={matchUrl('tender-view')}>
          <ViewTender />
        </Route>
        <Route path={matchUrl('tender-new')}>
          <EditTender />
        </Route>
        <Route>
          <Root active={active} />
        </Route>
      </Switch>
    </Breadcrumb.Items>
  );
};

type IRoot = Record<'active', keyof typeof paths>;
const Root = ({ active }: IRoot) => {
  const { pathActive } = usePathTools();

  return (
    <Breadcrumb.Root
      className="relative"
      title={
        <FormattedMessage
          id="tender-breadcrumbs-root"
          description="Label for root breadcrumbs on tenders page"
          defaultMessage="Tender {active, select, overview {overview} history {history} other {{active}}}"
          values={{ active: active }}
        />
      }
      url={routeUrl(paths[active])}
    >
      <Breadcrumb.Crumb
        title={
          <FormattedMessage
            id="tender-list-tab-overview"
            description="Label for `Overview` tab on tender list page"
            defaultMessage="Overview"
          />
        }
        url={routeUrl('tender-overview')}
        active={pathActive('tender-overview', true)}
      />
      <Breadcrumb.Crumb
        title={
          <FormattedMessage
            id="tender-list-tab-history"
            description="Label for `History` tab on tender list page"
            defaultMessage="History"
          />
        }
        url={routeUrl('tender-history')}
        active={pathActive('tender-history', true)}
      />
    </Breadcrumb.Root>
  );
};

const ViewTender = withPreload({
  route: 'tender-view',
  preload: service.observe.tender({ requireId: false }),
})(({ data: tender }) => {
  const { pathActive } = usePathTools();

  return (
    <>
      <Root active={tender?.status === 'archived' ? 'history' : 'overview'} />
      {tender && (
        <Breadcrumb.Crumb
          title={tender.name}
          url={routeUrl('tender-view', { tenderId: tender.id })}
          active={pathActive('tender-view')}
        />
      )}
    </>
  );
});

const EditTender = withPreload({
  route: 'tender-new',
  preload: service.observe.tender({ requireId: false }),
})(({ data: tender }) => {
  const { matchParams } = usePathTools();
  const params = matchParams('tender-new');

  return (
    <>
      <Root active="overview" />
      {tender ? (
        <>
          <Breadcrumb.Crumb
            title={tender.name}
            url={routeUrl('tender-view', { tenderId: tender.id })}
            active={false}
          />
          <Breadcrumb.Crumb
            title={
              <FormattedMessage
                id="tender-breadcrumbs-edit"
                description="Label for `edit` breadcrumbs on tenders page"
                defaultMessage="Edit"
              />
            }
            url={routeUrl('tender-edit-details', params)}
            active={true}
          />
        </>
      ) : (
        <Breadcrumb.Crumb
          title={
            <FormattedMessage
              id="tender-breadcrumbs-new"
              description="Label for `edit` breadcrumbs on tenders page"
              defaultMessage="New"
            />
          }
          url={routeUrl('tender-new')}
          active={true}
        />
      )}
    </>
  );
});
