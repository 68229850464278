import React, { useEffect } from 'react';

import type { FormComponentProps } from 'antd/lib/form';
import type * as Struct from 'struct';
import { Form } from 'Components/nui';
import { useStoreState } from '~/store';
import * as R from 'ramda';
import { Product } from '~/models';
import { FormWrapper } from 'Components/form';
import {
  mergeSchema,
  normalizeChoices,
  MAX_SAFE_INTEGER,
} from 'Components/form/utils';
import moment from 'moment-timezone';
import * as Data from 'Components/Data';
import { Link } from 'react-router-dom';
import { Tooltip } from 'Components/nui';
import { capitalize } from '~/utils';
import { InputNumberWidget } from 'Components/form/widgets';

type ILiquidityForm = FormComponentProps &
  Record<'schema', Struct.Schema> &
  Record<'errors', { string: string[] }[] | undefined> &
  Record<'orderScheduled', boolean>;

function InvalidOrderLink({ order }: { order: string }) {
  return (
    <Tooltip
      title={
        <span className="icon-tooltip">
          <span className="icon-info-circled"></span>
          This {order} is no longer available
        </span>
      }
    >
      <a className="liquidity-invalid-order">View {capitalize(order)}</a>
    </Tooltip>
  );
}

function findAttribute(ap: any) {
  const [name, valueChoices] = ap;
  const attributesInfo = valueChoices.choices.find(
    (c: any) => c[0] === valueChoices.value
  );
  return [name, attributesInfo[1]];
}

function useFormWrapper({ form, schema, orderScheduled }: ILiquidityForm) {
  const products = useStoreState(state => state.auth.products);
  const loadingLabel = schema.loading.choices[0][1];
  const productChoices = normalizeChoices(schema.product.choices);
  const quartersChoices = normalizeChoices(schema.quarters?.choices);
  const quartersValue = quartersChoices?.[0]?.value;
  const hasQuarters = quartersValue !== 'range';
  const productInfo = productChoices[0];
  const info = schema.info;

  const product = new Product(
    R.find(R.propEq('id', productInfo.value), products)
  );

  const attributesPairs = R.toPairs(schema.attributes?.fields);

  const fields = [
    {
      render: () => (
        <div className="liquidity-product-info ant-form-item" key={product.id}>
          <div className="product-name-heading">
            <span>
              <Data.ProductLink product={product} />
            </span>
          </div>
          {attributesPairs?.length > 0 && (
            <Tooltip
              title={
                <span className="icon-tooltip">
                  <span className="icon-info-circled" />
                  {attributesPairs
                    .map(ap => {
                      const [name, value] = findAttribute(ap);
                      return `${name}: ${value}`;
                    })
                    .join(', ')}
                </span>
              }
            >
              <span className="liquidity-attributes-value">
                {attributesPairs
                  .map(ap => {
                    const [name, value] = findAttribute(ap);
                    return value;
                  })
                  .join(', ')}
              </span>
            </Tooltip>
          )}
          {info?.attachments?.length && (
            <Data.Files
              files={info.attachments}
              title="product spec"
              className="icon-file-pdf"
            />
          )}
          {orderScheduled && (
            <div
              className="liquidity-link-container"
              key={`${info?.bid?.id}-${info?.offer?.id}`}
            >
              {info?.bid ? (
                <Link
                  className="liquidity-bid-details-link"
                  to={`/orders/${info.bid.id}`}
                >
                  View Bid
                </Link>
              ) : (
                <InvalidOrderLink order="bid" />
              )}

              {info?.offer ? (
                <Link
                  className="liquidity-offer-details-link"
                  to={`/orders/${info.offer.id}`}
                >
                  View Offer
                </Link>
              ) : (
                <InvalidOrderLink order="offer" />
              )}
            </div>
          )}
        </div>
      ),
    },

    hasQuarters
      ? {
          render: () => (
            <div key={quartersValue} className="ant-form-item quarters">
              <span className="liquidity-field-label">ETD</span>
              <span className="block">{quartersValue}</span>
            </div>
          ),
        }
      : {
          render: () => (
            <React.Fragment key={schema.from_etd.value}>
              <div className="ant-form-item etd">
                <span className="liquidity-field-label">ETD from</span>
                <span className="block">
                  {moment(schema.from_etd.value).format('LL')}
                </span>
              </div>
              <div className="ant-form-item etd">
                <span className="liquidity-field-label">ETD to</span>
                <span className="block">
                  {moment(schema.to_etd.value).format('LL')}
                </span>
              </div>
            </React.Fragment>
          ),
        },

    {
      render: () => (
        <div className="ant-form-item loading-info-text" key={loadingLabel}>
          <span className="liquidity-field-label">Loading details</span>
          <span>{loadingLabel}</span>
        </div>
      ),
    },

    {
      props: {
        disabled: schema.counteronly.disabled,
        required: schema.counteronly.required,
      },
      name: 'counteronly',
      label: 'Order type',
      initialValue: !!schema.counteronly.value,
      choices: [
        { label: 'Directly tradeable (Firm)', value: 'false', key: 'no' },
        {
          label: 'Subject to final confirmation (SFC)',
          value: 'true',
          key: 'yes',
        },
      ],
    },

    {
      name: 'bid_price',
      props: {
        disabled: schema.bid_price.disabled,
        required: schema.bid_price.required,
      },
      step: product.step,
      min: R.has('openprice', schema) ? 0 : product.step,
      initialValue: schema.bid_price?.value,
    },

    {
      name: 'offer_price',
      props: {
        disabled: schema.offer_price.disabled,
        required: schema.offer_price.required,
      },
      step: product.step,
      min: R.has('openprice', schema) ? 0 : product.step,
      initialValue: schema.offer_price?.value,
    },

    {
      render: () => (
        <Form.Item
          label={`Volume (${R.path(['loadingunit', 'desc'], product)})`}
          required={true}
          className="volume-field"
          key="volume"
        >
          <InputNumberWidget
            min={0}
            max={MAX_SAFE_INTEGER}
            step={1}
            props={{
              value: schema.volume.value || 0,
              disabled: schema.volume.disabled,
              required: schema.volume.required,
            }}
          />
          {hasQuarters && (
            <span className="extra-info-under-field">
              (3 x {schema.volume.value / 3} monthly)
            </span>
          )}
        </Form.Item>
      ),
    },
  ];

  const mergedSchema = mergeSchema(schema, fields);

  return new FormWrapper(form, {}, mergedSchema);
}

const BaseForm = ({ form, schema, errors, orderScheduled }: ILiquidityForm) => {
  const formWrapper = useFormWrapper({
    form,
    schema,
    errors,
    orderScheduled,
  });

  useEffect(() => {
    if (errors?.length) {
      formWrapper.setErrors(errors[0]);
    }
  }, [errors]);

  return (
    <Form layout="inline" className="liquidity-form">
      {formWrapper.render()}
    </Form>
  );
};

const LiquidityForm = Form.create<ILiquidityForm>()(BaseForm);

export default LiquidityForm;
