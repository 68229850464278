import { ICustomerRequest } from '~/store/models';
import React, { useState } from 'react';
import { GenericTable } from 'Components/nui/Table';
import { useTable } from './ViewContext';
import { Link } from 'react-router-dom';
import * as R from 'ramda';
import classnames from 'classnames';
import { Alert, Button, Loading } from 'Components/nui';
import Pagination from 'Components/nui/Pagination';
import { FormStatus } from '../Components';

interface IAction {
  customer: ICustomerRequest;
}

const Delete = ({ customer }: IAction) => {
  const { remove } = useTable();
  const [loading, setLoading] = useState(false);

  if (['cancelled', 'processed'].includes(customer.formstatus)) return null;
  return (
    <Button
      disabled={loading}
      icon="trash"
      onClick={async () => {
        setLoading(true);
        await remove(customer);
      }}
    />
  );
};

const Edit = ({ customer }: IAction) => {
  const redirect = ['pending', 'processed'].includes(customer.formstatus)
    ? 'review'
    : 'company';
  const editable = ['incomplete', 'pending', 'rejected'].includes(
    customer.formstatus
  );

  if (customer.formstatus === 'cancelled') return null;
  return (
    <Link
      to={`/customer-requests/${customer.id}/${redirect}`}
      className={classnames('nui-button nui-button-icon', {
        'nui-reverse': !editable,
      })}
      title={editable ? 'Edit request' : 'View request'}
    >
      <span
        className={classnames({
          'icon-pencil': editable,
          'icon-eye': !editable,
        })}
      />
    </Link>
  );
};

export default () => {
  const { loading, error, customers, ...pagination } = useTable();

  const column = (
    name: string,
    title: string,
    render: (c: ICustomerRequest) => React.ReactNode,
    className: string = ''
  ) => ({
    key: `customer-requests-table-${name}`,
    className: classnames(`col-${name}`, className),
    title,
    render,
  });

  const columns = [
    column('company-name', 'Company', customer =>
      customer.division?.id ? (
        <Link to={`/customers/${customer.division?.id}`}>
          {customer.company?.name}
        </Link>
      ) : (
        customer.company?.name
      )
    ),
    column('companyno', 'Company no', R.path(['company', 'companyno'])),
    column('comment', 'Your comments', R.prop('comment')),
    column('feedback', 'Nui feedback', R.prop('feedback')),
    column('status', 'Status', customer => <FormStatus customer={customer} />),
    column('edit', '', customer => <Edit customer={customer} />),
    column('delete', '', customer => <Delete customer={customer} />),
  ];

  return (
    <div className="generic-table light">
      <hr className="m-0" />
      <GenericTable
        className="customer-request-table nui-table table-wrapper th-sticky"
        columns={columns}
        data={customers}
        rowKey="id"
        emptyText={
          error ? (
            <Alert type="error" hasicon className="m-20 align-center">
              An error occurred while loading this data
            </Alert>
          ) : loading ? (
            <Loading size="huge" className="mt-40" />
          ) : (
            <div className="nui-row full-page-messaging align-center mt--46 p-100">
              <div className="icon-inbox bordered align-center">
                There are currently no customer requests to display
              </div>
            </div>
          )
        }
      />
      {!loading && <Pagination pagination={pagination} loading={loading} />}
    </div>
  );
};
