import { Tender, Order } from '~/models/tenders';
import * as api from '~/fetch';
import * as services from './internal';

type Response<T = void> = Promise<
  | (T extends void
      ? { success: true; data?: undefined }
      : { success: true; data: T })
  | { success: false; errors?: string[]; validate?: any; data?: undefined }
  | undefined
>;
export async function counter(
  tender: string,
  order: string,
  formdata: FormData,
  id?: string
): Response {
  try {
    const response = await api.tenders.counter(tender, order, formdata, id);
    return await api.utils.processResponse(response, {
      async 200(response) {
        const { bid } = await response.json();
        services.events.counters({
          tender,
          data: {
            bids: [{ ...bid, offer: { id: order, href: '' } }],
            offers: [],
          },
        });
        services.events.placeCounter({ tender });
        return { success: true as const };
      },
      async 400(response) {
        const data = await response.json();
        return {
          success: false as const,
          validate: data.errors[0].description,
        };
      },
      async 500() {
        return { success: false as const, errors: ['Server error'] };
      },
      async default() {
        return { success: false as const, errors: ['Unknown error'] };
      },
    });
  } catch (error) {
    return { success: false, errors: ['Application error'] };
  }
}

export async function deleteCounter(
  tender: string,
  order: string,
  counter: string
): Response {
  try {
    const response = await api.tenders.deleteCounter(tender, order, counter);
    return await api.utils.processResponse(response, {
      async 204() {
        services.events.deleteCounter({ tender, order, counter });
        return { success: true as const };
      },
      async default() {
        return { success: false as const, errors: ['Unknown error'] };
      },
    });
  } catch (error) {
    return { success: false as const, errors: ['Application error'] };
  }
}

export async function quickbid(
  tender: string,
  order: string,
  counter: string,
  price: number
): Response {
  try {
    const response = await api.tenders.quickbid(tender, order, counter, price);
    return await api.utils.processResponse(response, {
      async 200(response) {
        const { bid } = await response.json();
        services.events.counters({
          tender,
          data: {
            bids: [{ ...bid, offer: { id: order, href: '' } }],
            offers: [],
          },
        });
        return { success: true };
      },
      async 400(response) {
        const data = await response.json();
        return { success: false, validate: data.errors[0].description };
      },
      async 500() {
        return { success: false, errors: ['Server error'] };
      },
    });
  } catch (error) {
    return { success: false, errors: ['Application error'] };
  }
}

export async function editTender(data: FormData): Response<Tender> {
  try {
    const { solution } = services.storage.getOverview();
    data.append('solution', solution!);
    const response = await api.tenders.editTender(data);
    return await api.utils.processResponse(response, {
      async 200(response) {
        const data = await response.json();
        services.events.tender({ data });
        const tender = new Tender({
          ...data.tender,
          orders: { total: data.tender.offers.length },
        });
        return { success: true as const, data: tender };
      },
      async 400(response) {
        const data = await response.json();
        return {
          success: false as const,
          validate: data.errors[0].description,
        };
      },
      async 500() {
        return { success: false as const, errors: ['Server error'] };
      },
      async default() {
        return { success: false as const, errors: ['Unknown error'] };
      },
    });
  } catch (error) {
    return { success: false as const, errors: ['Application error'] };
  }
}

export async function editOrder(
  data: FormData,
  tenderId: string,
  orderId?: string
): Response<Order> {
  try {
    const response = await api.tenders.editOrder(data, tenderId, orderId);
    return await api.utils.processResponse(response, {
      async 200(response) {
        const data = await response.json();
        services.events.order({ data });
        const { bids, ...offer } = data.offer;
        return {
          success: true as const,
          data: new Order(
            offer,
            bids.map(bid => ({
              ...bid,
              offer: { id: offer.id, pid: offer.pid, href: offer.href },
            }))
          ),
        };
      },
      async 400(response) {
        const data = await response.json();
        return {
          success: false as const,
          validate: data.errors[0].description,
        };
      },
      async 500() {
        return { success: false as const, errors: ['Server error'] };
      },
      async default() {
        return { success: false as const, errors: ['Unknown error'] };
      },
    });
  } catch (error) {
    return { success: false as const, errors: ['Application error'] };
  }
}

export async function setParticipant(
  tender: string,
  participant: string,
  value: boolean
): Response<void> {
  try {
    const response = await api.tenders.setParticipant(
      tender,
      participant,
      value
    );
    return await api.utils.processResponse(response, {
      async 200(response) {
        const data = await response.json();
        const value = { participants: [data.participant] };
        services.storage.onParticipants(tender, value);
        return { success: true as const };
      },
      async 400(response) {
        const data = await response.json();
        return {
          success: false as const,
          validate: data.errors[0].description,
        };
      },
      async 500() {
        return { success: false as const, errors: ['Server error'] };
      },
      async default() {
        return { success: false as const, errors: ['Unknown error'] };
      },
    });
  } catch (error) {
    return { success: false as const, errors: ['Application error'] };
  }
}

export async function setParticipantGroup(
  tender: string,
  participants: string[]
): Response<void> {
  try {
    const response = await api.tenders.setParticipantGroup(
      tender,
      participants
    );
    return await api.utils.processResponse(response, {
      async 200(response) {
        const data = await response.json();
        services.storage.onParticipants(tender, data);
        return { success: true as const };
      },
      async 400(response) {
        const data = await response.json();
        return {
          success: false as const,
          validate: data.errors[0].description,
        };
      },
      async 500() {
        return { success: false as const, errors: ['Server error'] };
      },
      async default() {
        return { success: false as const, errors: ['Unknown error'] };
      },
    });
  } catch (error) {
    return { success: false as const, errors: ['Application error'] };
  }
}

export async function publish(
  tender: string,
  tendervisible?: boolean
): Response<void> {
  try {
    const response = await api.tenders.publish(tender, tendervisible);
    return await api.utils.processResponse(response, {
      async 200(response) {
        const data = await response.json();
        services.events.tender({ data });
        return { success: true as const };
      },
      async 400(response) {
        const data = await response.json();
        return {
          success: false as const,
          validate: data.errors[0].description,
        };
      },
      async 500() {
        return { success: false as const, errors: ['Server error'] };
      },
      async default() {
        return { success: false as const, errors: ['Unknown error'] };
      },
    });
  } catch (error) {
    return { success: false as const, errors: ['Application error'] };
  }
}

export async function editVisibility(
  tender: string,
  tendervisible: boolean
): Response<void> {
  try {
    const response = await api.tenders.editVisibility(tender, tendervisible);
    return await api.utils.processResponse(response, {
      async 200(response) {
        const data = await response.json();
        services.events.tender({ data });
        return { success: true as const };
      },
      async 400(response) {
        const data = await response.json();
        return {
          success: false as const,
          validate: data.errors[0].description,
        };
      },
      async 500() {
        return { success: false as const, errors: ['Server error'] };
      },
      async default() {
        return { success: false as const, errors: ['Unknown error'] };
      },
    });
  } catch (error) {
    return { success: false as const, errors: ['Application error'] };
  }
}

export async function notify(tender: string): Response<void> {
  try {
    const response = await api.tenders.notify(tender);
    return await api.utils.processResponse(response, {
      async 200(response) {
        const data = await response.json();
        services.events.tender({ data });
        return { success: true as const };
      },
      async 400(response) {
        const data = await response.json();
        return {
          success: false as const,
          validate: data.errors[0].description,
        };
      },
      async 500() {
        return { success: false as const, errors: ['Server error'] };
      },
      async default() {
        return { success: false as const, errors: ['Unknown error'] };
      },
    });
  } catch (error) {
    return { success: false as const, errors: ['Application error'] };
  }
}

export async function trade(
  tender: string,
  order: string,
  counter: string,
  data: { volume: number }
): Response<null> {
  try {
    const response = await api.tenders.trade(tender, order, counter, data);
    return await api.utils.processResponse(response, {
      async 200(response) {
        const data = await response.json();
        services.events.counters({
          tender,
          data: {
            bids: [
              {
                ...data.bid,
                offer: {
                  id: order,
                  href: `/tenders/${tender}/offers/${order}`,
                },
              },
            ],
            offers: [],
          },
        });
        return { success: true as const, data: null };
      },
      async 400(response) {
        const data = await response.json();
        return {
          success: false as const,
          validate: data.errors[0].description,
        };
      },
      async 500() {
        return { success: false as const, errors: ['Server error'] };
      },
      async default() {
        return { success: false as const, errors: ['Unknown error'] };
      },
    });
  } catch (error) {
    return { success: false as const, errors: ['Application error'] };
  }
}

export async function finalise(tender: string): Response<null> {
  try {
    const response = await api.tenders.finalise(tender);
    return await api.utils.processResponse(response, {
      async 200(response) {
        const data = await response.json();
        services.events.tender({ data });
        return { success: true as const, data: null };
      },
      async 500() {
        return { success: false as const, errors: ['Server error'] };
      },
      async default() {
        return { success: false as const, errors: ['Unknown error'] };
      },
    });
  } catch (error) {
    return { success: false as const, errors: ['Application error'] };
  }
}

export async function deleteOrder(
  tender: string,
  order: string
): Response<void> {
  try {
    const response = await api.tenders.deleteOrder(tender, order);
    return await api.utils.processResponse(response, {
      async 204() {
        services.events.deleteOrder({ tender, order });
        return { success: true as const };
      },
      async 500() {
        return { success: false as const, errors: ['Server error'] };
      },
      async default() {
        return { success: false as const, errors: ['Unknown error'] };
      },
    });
  } catch (error) {
    return { success: false as const, errors: ['Application error'] };
  }
}

export async function cancelTender(tender: string): Response<null> {
  try {
    const response = await api.tenders.cancelTender(tender);
    return await api.utils.processResponse(response, {
      async 204() {
        services.events.cancelTender({ tender });
        return { success: true as const, data: null };
      },
      async 500() {
        return { success: false as const, errors: ['Server error'] };
      },
      async default() {
        return { success: false as const, errors: ['Unknown error'] };
      },
    });
  } catch (error) {
    return { success: false as const, errors: ['Application error'] };
  }
}
