import { useState } from 'react';
import { api } from '~/api';
import { toast } from 'react-toastify';
import { Trade } from '~/store/models';
import type {
  TradeData,
  DeliveryData,
  DeliveryAttachment,
} from '~/store/models/modelTypes';
import type { Role } from '~/store/types';
import type { AxiosResponse } from 'axios';
import * as R from 'ramda';
import { useStoreState } from '~/store';
import Delivery, { Attachment, XFile } from './Delivery';
import { allowRolesIf } from 'Components/Authorised';
import type { AuthorisationModel } from '~/store/AuthorisationModel';
import type { State } from 'easy-peasy';

type Auth = State<AuthorisationModel>;

type OnProgress = (props: { percent: number }) => any;

interface TradesResponse {
  trades: TradeData[];
  pages?: number;
  total?: number;
}

interface DeliveriesResponse {
  shipments: DeliveryData[];
  pages?: number;
  total?: number;
}

type Consolidated = Record<string, string[]>;

export function useConsolidation() {
  const [trades, setTrades] = useState<Trade[]>([]);
  const [loading, setLoading] = useState(false);
  const [consolidated, setConsolidated] = useState<Consolidated>({});

  return {
    async loadTrades(division: string) {
      setLoading(true);

      const limit = 50;
      let page = 1;
      let result: AxiosResponse<TradesResponse>;
      let results: TradeData[] = [];

      do {
        result = await api.request({
          url: `/trades?division=${division}&shipment=null&limit=${limit}&page=${page}`,
          method: 'get',
        });
        results = results.concat(result?.data?.trades || []);
      } while (++page <= (result?.data?.pages || 0));

      setTrades(results.map(r => new Trade(r)));
      setLoading(false);
    },

    async load(division: string) {
      await this.loadTrades(division);
    },

    addTrade(trade: Trade) {
      if (loading) return;
      const customer = trade.otherParty.id;
      const trades = R.pathOr<string[]>([], [customer], consolidated);
      setConsolidated({
        ...consolidated,
        [customer]: R.uniq([...trades, trade.id]),
      });
    },

    removeTrade(trade: Trade) {
      if (loading) return;
      const customer = trade.otherParty.id;
      const trades = R.pathOr<string[]>([], [customer], consolidated);
      setConsolidated({
        ...consolidated,
        [customer]: R.reject(R.equals(trade.id), trades),
      });
    },

    addAllTrades(id: string) {
      if (loading) return;
      const trades = this.byDivision()[id];
      if (trades) {
        setConsolidated({
          ...consolidated,
          [id]: trades.map(R.prop('id')),
        });
      }
    },

    removeAllTrades(id: string) {
      if (loading) return;
      setConsolidated({ ...consolidated, [id]: [] });
    },

    isConsolidated(trade: Trade) {
      const trades = consolidated[trade.otherParty.id];
      if (trades) return !!R.find(R.equals(trade.id), trades);
      return false;
    },

    getConsolidated(id: string) {
      const con = R.filter(
        t => R.includes(t.id, R.propOr([], id, consolidated)),
        trades
      );
      return con;
    },

    getTrade(id: string) {
      return R.find(R.propEq('id', id), trades);
    },

    byDivision() {
      return R.groupBy(R.pathOr('', ['otherParty', 'id']), trades);
    },

    get customers() {
      return R.indexBy(
        R.prop('id'),
        R.uniqBy(R.prop('id'), R.map(R.prop('otherParty'), trades))
      );
    },

    get trades() {
      return trades;
    },

    get loading() {
      return loading;
    },

    get _acl() {
      const shippingSoln = R.pathOr(false, [
        'solution',
        'settings',
        'shipments',
      ]);
      const seller = (auth: Auth) => {
        for (const r of auth.roles as unknown as Role[]) {
          if (r.division.seller) return true;
        }
        return false;
      };
      const hasAccess = (auth: Auth) => shippingSoln(auth) && seller(auth);

      return {
        view: allowRolesIf('guest', 'trader', 'manager')(hasAccess),
        add: allowRolesIf('trader', 'manager')(hasAccess),
        edit: allowRolesIf('trader', 'manager')(hasAccess),
        delete: allowRolesIf('trader', 'manager')(hasAccess),
      };
    },
  };
}

interface Init {
  limit?: number;
  page?: number;
}
export function useDeliveries(initial: Init = {}) {
  const { limit: iLimit = 10, page: iPage = 1 } = initial;

  const [loading, setLoading] = useState(false);
  const [deliveries, setDeliveries] = useState<Delivery[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(iLimit);
  const [pages, setPages] = useState(iPage);
  const [total, setTotal] = useState(1);
  const solution = useStoreState(state => state.auth.solution.id);

  return {
    async load() {
      setLoading(true);
      try {
        const result: AxiosResponse<DeliveriesResponse> = await api.request({
          url: `/shipments?limit=${limit}&page=${page}&solution=${solution}&sort=-created`,
          method: 'get',
        });

        if (result.data.pages) setPages(result.data.pages);
        if (result.data.total) setTotal(result.data.total);

        setDeliveries(result.data?.shipments?.map(d => new Delivery(d)) || []);
      } catch (error) {
        console.error({ error });
        toast('An error occured. Please try again later.', { type: 'error' });
      }
      setLoading(false);
    },

    async addDelivery(data: any) {
      setLoading(true);
      const result = await api.request({
        url: '/shipments',
        method: 'post',
        data,
      });
      setLoading(false);
      return result.data;
    },

    async updateDelivery(delivery: Delivery, data: Partial<Delivery>) {
      setLoading(true);
      await api.request({
        url: `/shipments/${delivery.id}`,
        method: 'put',
        data: { ...delivery.form(), ...data },
      });
      setLoading(false);
    },

    async addTradesToDelivery(delivery: Delivery, trades: Trade[]) {
      setLoading(true);
      const data = {
        trades: [...delivery.trades, ...trades.map(R.pick(['id']))],
      };
      const result = await api.request({
        url: `/shipments/${delivery.id}/trades`,
        method: 'put',
        data,
      });
      setLoading(false);
      return result.data;
    },

    async unconsolidateAll(delivery: Delivery) {
      setLoading(true);
      await api.request({
        url: `/shipments/${delivery.id}/trades`,
        method: 'post',
        data: { trades: [] },
      });
      setLoading(false);
    },

    async removeTrade(delivery: Delivery, trade: TradeData) {
      setLoading(true);
      await api.request({
        url: `/shipments/${delivery.id}/trades/${trade.id}`,
        method: 'delete',
      });
      await this.load();
      setLoading(false);
    },

    async notify(delivery: Delivery) {
      setLoading(true);
      await api.request({
        url: `/shipments/${delivery.id}`,
        method: 'patch',
        data: { finalised: true },
      });
      setLoading(false);
    },

    byCustomer(customer: string) {
      return R.filter(R.pathEq(['buyer', 'id'], customer), deliveries);
    },

    deliveries,
    loading,

    total,
    page,
    limit,
    setPage,
    setLimit,
    pages,

    nextPage() {
      setPage(page + 1);
    },
    prevPage() {
      setPage(page - 1);
    },
    firstPage() {
      setPage(1);
    },
    lastPage() {},

    get _acl() {
      const shippingSoln = R.pathOr(false, [
        'solution',
        'settings',
        'shipments',
      ]);
      const seller = (auth: Auth) => {
        for (const r of auth.roles as unknown as Role[]) {
          if (r.division.seller) return true;
        }
        return false;
      };
      const canView = (auth: Auth) => shippingSoln(auth);
      const canEdit = (auth: Auth) => shippingSoln(auth) && seller(auth);

      return {
        view: allowRolesIf('guest', 'trader', 'manager')(canView),
        add: allowRolesIf('trader', 'manager')(canEdit),
        edit: allowRolesIf('trader', 'manager')(canEdit),
        delete: allowRolesIf('trader', 'manager')(canEdit),
      };
    },
  };
}

export function useDeliveryAttachments(delivery: Delivery) {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<Record<string, Attachment>>(
    R.indexBy(R.prop('id'), delivery.attachments)
  );

  const addFile = (file: XFile | DeliveryAttachment, progress = 0) => {
    const id = (file as XFile).uid || (file as DeliveryAttachment).id;
    setFiles(state => ({
      ...state,
      [id]: new Attachment(file, delivery.seller, progress),
    }));
  };

  const removeFile = (id: string) => {
    setFiles(state =>
      R.indexBy(
        R.prop('id'),
        R.reject(R.propEq('id', id), Object.values(state))
      )
    );
  };

  return {
    get files() {
      return Object.values(files).sort((a, b) =>
        b.created.subtract(a.created.unix(), 's').unix()
      );
    },

    get loading() {
      return loading;
    },

    async addAttachment(file: Omit<XFile, 'progress'>) {
      setLoading(true);
      const data = new FormData();
      data.append('attachment', file);

      addFile(file);

      try {
        const response = await api.request({
          url: `shipments/${delivery.id}/attachments`,
          method: 'post',
          data,
          onUploadProgress: ({ total, loaded }) => {
            const progress = (loaded / total) * 100;
            addFile(file, progress);
          },
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/formdata',
          },
        });

        const { attachment } = response.data;

        removeFile(file.uid);
        addFile(attachment);
      } catch (err) {
        removeFile(file.uid);
        throw err;
      }
      setLoading(false);
    },

    async removeAttachment(file: Attachment) {
      await api.request({
        url: `shipments/${delivery.id}/attachments/${file.id}`,
        method: 'delete',
      });

      removeFile(file.id);
    },
  };
}
