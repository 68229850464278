import React, { useRef } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useHistory } from 'react-router-dom';
import { Button } from 'Components/nui';
import { useClickAway, useToggle } from 'react-use';
import classNames from 'classnames';
import Helmet from 'Components/Helmet';
import Icon from 'Components/Icon';

export default () => {
  const solution = useStoreState(state => state.auth.solution);
  const solutions = useStoreState(state => state.auth.solutions);
  const changeSolution = useStoreActions(action => action.auth.changeSolution);
  const history = useHistory();
  const solutionsLoading = useStoreState(
    state => state.auth.user.solutionsLoading
  );

  const ref = useRef(null);
  const [open, toggle] = useToggle(false);
  useClickAway(ref, () => {
    toggle(false);
  });

  const change = id => {
    toggle(false);
    changeSolution(id);
    history.push('/');
  };

  return (
    <div ref={ref} className="solution-picker">
      <Helmet>
        {solutions.map(s => (
          <link key={s.id} rel="prefetch" as="image" href={s.icon || s.logo} />
        ))}
      </Helmet>
      {solutionsLoading ? (
        <Icon name="progress_activity" className="solution-loader spin" />
      ) : solutions.length > 1 ? (
        <Button
          htmlType="button"
          type="buttonlink"
          title="Change solution"
          onClick={toggle}
        >
          <span className="solution">
            Change <span className="show-for-large">solution</span>
          </span>
        </Button>
      ) : null}
      {open && (
        <nav>
          <ul>
            {solutions
              .sort((a, b) => {
                const isMarketplaceA =
                  a.settings?.matchinglevel === 'commodity';
                const isMarketplaceB =
                  b.settings?.matchinglevel === 'commodity';
                if (isMarketplaceA && !isMarketplaceB) {
                  return -1;
                } else if (!isMarketplaceA && isMarketplaceB) {
                  return 1;
                } else {
                  return a.name.localeCompare(b.name);
                }
              })
              .map(s => (
                <li
                  key={s.id}
                  className={classNames({ selected: s.id === solution.id })}
                >
                  <Button htmlType="button" onClick={() => change(s.id)}>
                    <figure>
                      <img src={s.icon || s.logo} alt={s.desc} />
                    </figure>
                    <span className="solution-name">{s.name}</span>
                    {s.id === solution.id && <span className="icon-ok"></span>}
                  </Button>
                </li>
              ))}
          </ul>
        </nav>
      )}
    </div>
  );
};
