import React from 'react';
import TdCell from './TdCell';
import EmptyTdCell from './EmptyTdCell';
import NewOrderBtn from './NewOrderBtn';
import * as R from 'ramda';
import moment from 'moment-timezone';

const Cell = ({ column, mode, symbol, data, tradeActions }) => {
  const startOfMonth = column.moment.startOf(mode);
  const startMoment = startOfMonth.isBefore(moment()) ? moment() : startOfMonth;

  const etd = [
    startMoment.format('YYYY-MM-DD'),
    column.moment.endOf(mode).format('YYYY-MM-DD'),
  ];

  let BidBtn = null;
  if ('buy' in tradeActions) {
    BidBtn = (
      <NewOrderBtn
        type="bid"
        params={{
          product: tradeActions.buy,
          etd,
        }}
      />
    );
  }

  let OfferBtn = null;
  if ('sell' in tradeActions) {
    OfferBtn = (
      <NewOrderBtn
        type="offer"
        params={{
          product: tradeActions.sell,
          etd,
        }}
      />
    );
  }

  if (!data)
    return (
      <EmptyTdCell narrow={column.empty} BidBtn={BidBtn} OfferBtn={OfferBtn} />
    );

  return (
    <TdCell data={data} symbol={symbol} BidBtn={BidBtn} OfferBtn={OfferBtn} />
  );
};

export default ({ symbol, columns, mode, data, tradeActions }) => {
  return columns.map(column => (
    <Cell
      key={column.key}
      column={column}
      symbol={symbol}
      mode={mode}
      data={R.path([column.key], data)}
      tradeActions={tradeActions}
    />
  ));
};
