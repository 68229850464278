import { FormattedMessage } from 'react-intl';
import Icon from './Icon';

const QAVisibilityInfo = ({ qaprivate }: { qaprivate: boolean }) => {
  return (
    <div className="qa-visibility-explain-container">
      <Icon
        name={qaprivate ? 'visibility_off' : 'visibility'}
        className="icon-inline icon-md"
      />
      {qaprivate ? (
        <FormattedMessage
          id="marketrow-qa-field-tooltip-private"
          description="Tooltip for private qa icon on qa on maketplace"
          defaultMessage="Private"
        />
      ) : (
        <FormattedMessage
          id="marketrow-qa-field-tooltip-public"
          description="Tooltip for public qa icon on qa on maketplace"
          defaultMessage="Public once answered"
        />
      )}
    </div>
  );
};

export default QAVisibilityInfo;
