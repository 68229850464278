import type { ICustomerRequest } from '~/store/models/customer-request';
import type { AxiosError } from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CustomerRequestController } from '~/store/models';
import { Loading } from 'Components/nui';
import { useStoreState } from '~/store';
import * as R from 'ramda';

type TSchemaName = 'company' | 'address' | 'links' | 'locations' | 'users';
type TRemoveable = 'links' | 'locations' | 'users';

function isAxios(error: Error | AxiosError): error is AxiosError {
  if ((error as AxiosError).response) return true;
  return false;
}

interface IReqError {
  status: number;
  message: string;
}

type IEditModel<T> = (data: T) => Promise<any>;
type IRemoveModel = (id: string) => Promise<any>;

interface IActions {
  edit(model: TSchemaName, data: any): Promise<Partial<ICustomerRequest>>;
  editLocation(data: any): Promise<any>;
  editUser(data: any): Promise<any>;
  remove(model: TRemoveable, id: string): Promise<void>;
  submit(comment?: string): Promise<void>;
}

interface IEditContext {
  customer: Partial<ICustomerRequest>;
  loading: boolean;
  error?: IReqError;
  actions: IActions;
  validation: {
    formComplete: boolean;
    hasCompany: boolean;
    hasAddress: boolean;
    hasLinks: boolean;
    hasLocations: boolean;
    hasUsers: boolean;
    hasManagers: boolean;
  };
}

const Context = React.createContext<IEditContext>({
  customer: {},
  loading: false,
  error: undefined,
  actions: {
    async edit() {
      return {};
    },
    async editLocation() {
      return {};
    },
    async editUser() {
      return {};
    },
    async remove() {},
    async submit() {},
  },
  validation: {
    formComplete: false,
    hasCompany: false,
    hasAddress: false,
    hasLinks: false,
    hasLocations: false,
    hasUsers: false,
    hasManagers: false,
  },
});

interface IDefault {
  children: React.ReactNode;
}
export default ({ children }: IDefault) => {
  const [customer, setCustomer] = useState<Partial<ICustomerRequest>>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<IReqError>();

  const solution = useStoreState(state => state.auth.solution.id);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id !== 'add') {
      if (customer.id !== id) {
        (async () => {
          setLoading(true);
          try {
            const response = await CustomerRequestController.getRequest({ id });
            setCustomer(response.data.customer);
          } catch (err) {
            if (isAxios(err)) {
              const { response } = err || {};
              setError({ status: response?.status || 0, message: '' });
              console.error({ response });
            } else {
              setError({ status: 0, message: '' });
              console.error('Unhandled error while fetching customer request.');
            }
          }
          setLoading(false);
        })();
      }
    } else {
      setCustomer({});
    }
  }, [id]);

  const actions: IActions = {
    async edit(model, data) {
      const response = await CustomerRequestController.edit({
        model,
        data,
        customer,
        solution,
      });
      if (response.data.customer) {
        setCustomer(response.data.customer);
        return response.data.customer;
      } else {
        const updated = { ...customer, ...response.data };
        setCustomer(updated);
        return updated;
      }
    },

    async editLocation(data) {
      if (!data.id) return await this.edit('locations', data);

      const response = await CustomerRequestController.edit({
        model: 'locations',
        data,
        customer,
        solution,
      });
      if (response.data.location) {
        const updated = {
          ...customer,
          locations: [
            ...R.reject(
              R.propEq('id', response.data.location.id),
              customer.locations || []
            ),
            response.data.location,
          ],
        };
        setCustomer(updated);
        return updated;
      }
    },

    async editUser(data) {
      if (!data.id) return await this.edit('users', data);

      const response = await CustomerRequestController.edit({
        model: 'users',
        data,
        customer,
        solution,
      });
      if (response.data.user) {
        const updated = {
          ...customer,
          users: [
            ...R.reject(
              R.propEq('id', response.data.user.id),
              customer.users || []
            ),
            response.data.user,
          ],
        };
        setCustomer(updated);
        return updated;
      }
    },

    async remove(model, id) {
      await CustomerRequestController.remove({
        customer,
        model,
        id,
      });
      setCustomer(state => ({
        ...state,
        [model]: R.reject(R.propEq('id', id), R.prop(model, state) as any[]),
      }));
    },

    async submit(comment) {
      const response = await CustomerRequestController.submit({
        customer,
        comment,
      });
      setCustomer(response.data.customer);
    },
  };

  const hasCompany = !!Object.keys(customer?.company || {}).length;
  const hasAddress = !!Object.keys(customer?.address || {}).length;
  const hasLinks = !!customer.links?.length;
  const hasLocations = !!customer.locations?.length;
  const hasUsers = !!customer.users?.length;
  const hasManagers = !!R.filter(
    R.propEq('role', 'manager'),
    customer.users || []
  ).length;

  const formComplete =
    hasCompany &&
    hasAddress &&
    hasLinks &&
    hasLocations &&
    hasUsers &&
    hasManagers;

  const validation = {
    formComplete,
    hasCompany,
    hasAddress,
    hasLinks,
    hasLocations,
    hasUsers,
    hasManagers,
  };

  const value = { customer, loading, error, actions, validation };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useCustomerRequest() {
  return useContext(Context);
}

interface ISchema {
  value?: any[];
  loading: boolean;
  error?: IReqError;
}
interface ISchemaContext {
  company: ISchema;
  address: ISchema;
  links: ISchema;
  locations: ISchema;
  users: ISchema;
}

const fake = { loading: false };
const SchemaContext = React.createContext<ISchemaContext>({
  company: fake,
  address: fake,
  locations: fake,
  links: fake,
  users: fake,
});

function useSchemaProvider(name: TSchemaName) {
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const { customer } = useCustomerRequest();

  const solution = useStoreState(state => state.auth.solution.id);

  const { action } = useParams<{ action?: TSchemaName }>();

  useEffect(() => {
    if (value) return;

    const editing = action === name && customer.id;
    const creating = action === undefined && name === 'company';
    if (editing || creating) {
      (async () => {
        setLoading(true);

        try {
          const { data } = await CustomerRequestController.getSchema({
            name,
            solution,
            customer,
          });
          if (data.fields) {
            setValue(data.fields);
          } else {
            setValue(undefined);
          }
        } catch (error) {
          setError(error);
        }

        setLoading(false);
      })();
    }
  }, [action, customer.id]);

  return { value, loading, error };
}

export const FormContext = ({ children }: IDefault) => {
  const company = useSchemaProvider('company');
  const address = useSchemaProvider('address');
  const links = useSchemaProvider('links');
  const locations = useSchemaProvider('locations');
  const users = useSchemaProvider('users');

  const schemas = { company, address, links, locations, users };

  return (
    <SchemaContext.Provider value={schemas}>{children}</SchemaContext.Provider>
  );
};

const UsingSchemaContext = React.createContext<undefined | any[]>(undefined);

interface IUsingSchema {
  schema: TSchemaName;
  children?: React.ReactNode;
}
export const UsingSchema = ({ schema, children }: IUsingSchema) => {
  const context = useContext(SchemaContext);
  const current = context[schema];

  if (current.loading) {
    return <Loading />;
  } else if (current.error) {
    return <h3>Something went wrong</h3>;
  }
  return (
    <UsingSchemaContext.Provider value={current.value}>
      {children}
    </UsingSchemaContext.Provider>
  );
};

export function useSchema(name: TSchemaName) {
  const context = useContext(UsingSchemaContext);
  return context;
}
