import Helmet from 'Components/Helmet';
import Header from './Header';
import Filter from './Filter';
import React, { useState, useRef, useEffect } from 'react';
import {
  NavLink,
  Route,
  Switch,
  useRouteMatch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { useScrollToTopOnMount } from '~/hooks';
import { createStateContext } from 'react-use';
import CustomerEngagement from './CustomerEngagement';
import TradeInformation from './TradeInformation';
import TradeIndex from './GlobalCommodityPricing';
import FilterTradeInformation from './TradeInformation/Filter';
import FilterCustomerEngagement from './CustomerEngagement/Filter';
import FilterTradeIndex from './GlobalCommodityPricing/Filter';
import * as R from 'ramda';
import { useStoreState } from '~/store';
import { Alert } from 'Components/nui';
import { startReplay, stopReplay } from '~/sentry';

export const [useSharedFilter, SharedFilterProvider] = createStateContext();

export const [useSharedTIData, SharedTIDataProvider] = createStateContext({});
export const [useFilterTIData, FilterTIDataProvider] = createStateContext({});

export const [useSharedCEData, SharedCEDataProvider] = createStateContext({});
export const [useFilterCEData, FilterCEDataProvider] = createStateContext({});

export const [useFilterIndexData, FilterIndexDataProvider] = createStateContext(
  {}
);

export default () => {
  useScrollToTopOnMount();
  const { url } = useRouteMatch();

  const solution = useStoreState(state => state.auth.solution);

  const dashboards = R.propOr([], 'dashboards', solution);

  const { pathname } = useLocation();

  useEffect(() => {
    startReplay(
      import.meta.env.VITE_APP_SENTRY_REPLAYS_DASHBOARD_SESSION_ENABLED
    );
    return () => {
      stopReplay(
        import.meta.env.VITE_APP_SENTRY_REPLAYS_DASHBOARD_SESSION_ENABLED
      );
    };
  }, []);

  if (dashboards.length === 0) return <Redirect to="/" />;

  return (
    <SharedFilterProvider>
      <SharedCEDataProvider>
        <FilterCEDataProvider>
          <SharedTIDataProvider>
            <FilterTIDataProvider>
              <FilterIndexDataProvider>
                <Helmet>
                  <title>Dashboard</title>
                </Helmet>
                <div className="dashboard2">
                  <Header />
                  <div className="full-page-header dashboard-header">
                    <div className="dashboard-tabs nui-tabs-nav">
                      <nav id="dashboard-nav">
                        {dashboards.includes('indexes') ? (
                          <>
                            <NavLink
                              exact
                              to={url}
                              title="Global commodity pricing"
                            >
                              Global commodity pricing
                            </NavLink>
                            {dashboards.includes('trade') && (
                              <NavLink
                                to={`${url}/trade-information`}
                                title="Trade information"
                              >
                                Trade information
                              </NavLink>
                            )}
                          </>
                        ) : (
                          dashboards.includes('trade') && (
                            <NavLink exact to={url} title="Trade information">
                              Trade information
                            </NavLink>
                          )
                        )}
                        {dashboards.includes('user') && (
                          <NavLink
                            to={`${url}/customer-engagement`}
                            title="Customer engagement"
                          >
                            Customer engagement
                          </NavLink>
                        )}
                      </nav>
                    </div>
                    <div className="dashboard-filter">
                      {dashboards.includes('indexes') ? (
                        pathname !== '/dashboard' && <Filter />
                      ) : (
                        <Filter />
                      )}
                      <hr className="m-0 mt-10 show-for-small-only" />
                    </div>
                  </div>

                  <div className="full-page-header mt--1 pt-0">
                    <div className="filter-section dashboard-filter opened">
                      <div className="filter-block">
                        <Switch>
                          <Route path={`${url}/customer-engagement`}>
                            <FilterCustomerEngagement />
                          </Route>
                          <Route path={`${url}/trade-information`}>
                            <FilterTradeInformation />
                          </Route>
                          <Route>
                            {dashboards.includes('indexes') ? (
                              <FilterTradeIndex />
                            ) : (
                              <FilterTradeInformation />
                            )}
                          </Route>
                        </Switch>
                      </div>
                    </div>

                    <Switch>
                      <Route path={`${url}/customer-engagement`}>
                        <CustomerEngagement />
                      </Route>
                      <Route path={`${url}/trade-information`}>
                        <TradeInformation />
                      </Route>
                      <Route>
                        {dashboards.includes('indexes') ? (
                          <TradeIndex />
                        ) : (
                          <TradeInformation />
                        )}
                      </Route>
                    </Switch>
                  </div>
                </div>
              </FilterIndexDataProvider>
            </FilterTIDataProvider>
          </SharedTIDataProvider>
        </FilterCEDataProvider>
      </SharedCEDataProvider>
    </SharedFilterProvider>
  );
};

export const ColorSquare = ({ color }) => (
  <span
    style={{
      background: color,
      display: 'inline-block',
      width: 12,
      height: 12,
    }}
  />
);

export const TooltipWrapper = ({ children, point }) => {
  const ref = useRef();
  const [anchorRight, setAnchorRight] = useState(false);

  React.useEffect(() => {
    const chartContainer = ref.current?.parentNode?.parentNode;
    if (chartContainer?.offsetWidth - point?.x < 250) {
      setAnchorRight(true);
    } else {
      setAnchorRight(false);
    }
  }, [point?.x]);

  return (
    <div
      ref={ref}
      className="nui-tooltip"
      style={{
        position: 'absolute',
        right: anchorRight ? 0 : 'auto',
      }}
    >
      {children}
    </div>
  );
};

export const AlternateBarBackground =
  keys =>
  ({ bars, innerHeight }) => {
    const rects = bars
      .slice(0, Math.floor(bars.length / keys.length))
      .filter((_, index) => index % 2 === 0);

    return (
      <g>
        {rects.map(rect => (
          <rect
            key={rect.key}
            x={rect.x - (rect.width * keys.length) / 2}
            y={0}
            width={rect.width * keys.length * 2}
            height={innerHeight}
            style={{
              fill: 'rgba(0,0,0,.03)',
            }}
          />
        ))}
      </g>
    );
  };

// still wrong, but okk
export const AlternateBackground = ({ series, slices, ...props }) => {
  const rects = slices.filter((_, index) => index % 2 === 1);

  const width = R.pathOr(0, [0, 'width'], rects);

  return (
    <g>
      {slices.map((rect, index) => (
        <rect
          key={rect.id}
          x={rect.x0}
          y={0}
          width={rect.width}
          height={rect.height}
          style={{
            fill: index % 2 === 1 ? 'rgba(0,0,0,.05)' : 'transparent',
          }}
        />
      ))}
    </g>
  );
};

export const NoDataAvailable = () => (
  <Alert hasicon type="info" className="align-center mt-20">
    No data available for the selected date range.
  </Alert>
);

export const ApiError = () => (
  <Alert hasicon type="error" className="align-center mt-20">
    There was an error loading this data
  </Alert>
);

// user activity
// volumn traded by trade type
export const majorColors = ['#0d8abc', '#df700d', '#bfbfbf', '#fcb441'];

// global commodity pricing
// volumne traded by trade parties
// unique visitors
export const colors = [
  '#418CF0',
  '#FCB441',
  '#E0400A',
  '#056492',
  '#BFBFBF',
  '#1A3B69',
  '#129CDD',
  '#CA6B4B',
  '#005CDB',
  '#F1B9A8',
  '#7893BE',
  '#0D8FB0',
  '#7ABFDA',
  '#7fa5c2',
  '#e01c19',
  '#266d00',
  '#fd9600',
  '#938dc3',
  '#8dd900',
  '#d1c200',
  '#3ae0bc',
  '#3aa0e0',
  '#9a9a9a',
  '#799bef',
  '#b78fec',
  '#ff008b',
  '#ec80d7',
  '#ff7680',
  '#b130cb',
  '#e406ad',
  '#e04a60',
  '#5b6e5d',
  '#dde02b',
  '#f26303',
];

// company activity
export const pairedThemeColors = [
  'rgb(166, 206, 227)',
  'rgb(31, 120, 180)',
  'rgb(178, 223, 138)',
  'rgb(51, 160, 44)',
  'rgb(251, 154, 153)',
  'rgb(227, 26, 28)',
  'rgb(253, 191, 111)',
  'rgb(255, 127, 0)',
  'rgb(202, 178, 214)',
  'rgb(106, 61, 154)',
  'rgb(255, 255, 153)',
  'rgb(177, 89, 40)',
];
