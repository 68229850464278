import { Loading, Select } from 'Components/nui';
import React, { useState, useEffect } from 'react';
import { api } from '~/api';
import { useStoreState } from 'easy-peasy';
import { useFilterIndexData } from '..';
import * as R from 'ramda';
import { useToggle } from 'react-use';
import DropCheck from 'Components/nui/form/DropCheck';

const uom = [
  { key: 'pound', label: 'Pound' },
  { key: 'mt', label: 'Tonne' },
  { key: 'kg', label: 'Kilogram' },
];

const currency = [
  { key: 'EUR', label: 'EUR' },
  { key: 'GBP', label: 'GBP' },
  { key: 'USD', label: 'USD' },
];

const indexes = [
  'GDT',
  'CME',
  'EEX',
  'EEX WECI',
  'NDPSR',
  'CLAL',
  'EDF ZUIVELNL',
];

export default () => {
  const [filter, setFilter] = useFilterIndexData();
  const userCurrency = useStoreState(state => state.auth.solution.currency);
  const solution = useStoreState(state => state.auth.solution.id);
  const userUom = userCurrency === 'USD' ? 'pound' : 'mt';

  const [products, setProducts] = useState([]);
  const [loading, toggle] = useToggle(true);
  const [apiError, setApiError] = useState(false);

  useEffect(() => {
    let mounted = true;
    toggle(true);

    (async () => {
      try {
        const data = await api.analytics('indexes/sources', [], {
          category: indexes,
        });
        if (mounted) {
          const newData = R.pathOr([], ['data'], data);
          setProducts(newData);

          setFilter(state => ({
            ...state,
            productsFilter: newData?.slice(0, 3).map(R.prop('key')),
            currencyFilter: userCurrency,
            uomFilter: userUom,
            chartPeriodFilter: '3M',
          }));
        }
        toggle(false);
      } catch (error) {
        setApiError(true);
      }
    })();

    return () => {
      mounted = false;
    };
  }, [solution, setApiError, setProducts, setFilter, toggle]);

  return (
    !apiError &&
    (loading && !filter?.productsFilter ? (
      <Loading size="small" />
    ) : (
      <div>
        <div className="nui-fieldset product-filter">
          <label htmlFor="product">Index</label>
          <div className="select-holder">
            <DropCheck
              name="indexes"
              id="indexs"
              itemType="indices"
              title="All"
              options={products.map(item => [
                item.key,
                `${item.category} - ${item.name}`,
              ])}
              value={filter.productsFilter}
              onChange={(_, values) => {
                void setFilter(state => ({
                  ...state,
                  productsFilter: values,
                }));
              }}
            />
          </div>
        </div>

        <div className="nui-fieldset currency-filter">
          <label htmlFor="currency">Currency</label>
          <div className="select-holder">
            <Select
              id="currency"
              value={filter.currencyFilter}
              onChange={value =>
                void setFilter(state => ({ ...state, currencyFilter: value }))
              }
            >
              {currency.map(item => (
                <option value={item.key} key={item.key}>
                  {item.label}
                </option>
              ))}
            </Select>
          </div>
        </div>

        <div className="nui-fieldset unit-filter">
          <label htmlFor="unit">Unit</label>
          <div className="select-holder">
            <Select
              id="unit"
              value={filter.uomFilter}
              onChange={value =>
                void setFilter(state => ({ ...state, uomFilter: value }))
              }
            >
              {uom.map(item => (
                <option value={item.key} key={item.key}>
                  {item.label}
                </option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    ))
  );
};
