import type * as Models from '~/models/tenders';
import React, { useEffect } from 'react';
import { routeUrl, withPreload, withRouteX } from '~/router';
import { Switch, Loading } from 'Components/nui';
import * as Data from 'Components/Data';
import { toast } from 'react-toastify';
import { useMountedState } from '~/hooks';
import { Link } from 'react-router-dom';
import * as service from '~/services/tenders';
import { FormattedMessage } from 'react-intl';

type TenderProp = Record<'tender', Models.Tender>;
type ParticipantProp = Record<'participant', Models.Participant>;

interface IParticipants {
  mode: string;
}
const Participants = withPreload({
  route: 'tender-edit-participants',
  preload: service.observe.participants(),
})<IParticipants>(({ data: { tender, participants }, mode = 'edit' }) => {
  const [loading, setLoading] = useMountedState(false);
  const active = participants.filter(p => p.approved).length;
  const inactive = participants.length - active;
  const params = { tenderId: tender.id, mode };

  const [pageLoading, setPageLoading] = useMountedState(true);

  useEffect(() => {
    service.observe.refreshParticipants(tender.id).then(() => {
      setPageLoading(false);
    });
  }, []);

  if (!participants?.length || pageLoading) return <Loading />;

  return (
    <div className="tender-edit-step participants">
      <div className="toggle-all">
        <Switch
          checked={!!active}
          indeterminate={(!!(active && inactive)).toString()}
          loading={loading}
          disabled={loading}
          onChange={async () => {
            setLoading(true);
            const verb = active ? 'disabled' : 'added';
            const result = await service.actions.setParticipantGroup(
              tender.id,
              active ? [] : participants.map(p => p.id)
            );
            setLoading(false);
            if (result?.success)
              toast.success(`All participants ${verb} successfully`);
            else toast.error('Could not update all at this time.');
          }}
        />
        <strong className="all-black">
          <FormattedMessage
            id="tender-create-order-participants-switch-all-label"
            description="Heading for All participants switch on tenders create/edit participants page"
            defaultMessage="All participants"
          />
        </strong>
      </div>
      <ul className="participant-list">
        {participants
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(participant => (
            <Entry
              key={participant.id}
              tender={tender}
              participant={participant}
              disabled={loading}
            />
          ))}
      </ul>
      <div className="sticky-btm button-set">
        <Link
          to={routeUrl('tender-edit-review', params)}
          className="button primary"
        >
          <FormattedMessage
            id="tender-create-order-participants-button-continue"
            description="Button for Continue on tenders create/edit participants page"
            defaultMessage="Continue"
          />
        </Link>
      </div>
    </div>
  );
});
export default withRouteX({
  name: 'tender-edit-participants',
})(({ params: { mode = '' } }) => <Participants mode={mode} />);

type IEntry = TenderProp & ParticipantProp & Record<'disabled', boolean>;
const Entry = ({ tender, participant, disabled }: IEntry) => {
  const [loading, setLoading] = useMountedState(false);
  const value = participant.approved;

  const onChange = async () => {
    setLoading(true);
    const [verb, particle] = value ? ['remov', 'from'] : ['add', 'to'];
    const result = await service.actions.setParticipant(
      tender.id,
      participant.id,
      !value
    );
    setLoading(false);

    const success = (
      <>
        <strong>{participant.name}</strong> has been {verb}ed {particle} this
        tender
      </>
    );
    const error = (
      <>
        An error occurred while {verb}ing <strong>{participant.name}</strong>{' '}
        {particle} this tender
      </>
    );

    if (result?.success) toast.success(success);
    else toast.error(error);
  };

  return (
    <li className="participant-entry">
      <Data.List>
        <Data.Item className="participant-data toggle">
          <Switch
            loading={loading}
            disabled={loading || participant.disabled}
            checked={value}
            onChange={onChange}
          />
        </Data.Item>
        <Data.Item className="participant-data name">
          {participant.name}
        </Data.Item>
        {participant.disabled && (
          <Data.Item
            className="participant-data reason"
            title={
              <FormattedMessage
                id="tender-create-order-participants-title-reason"
                description="Title for Reason field on tenders create/edit participants page"
                defaultMessage="Reason"
              />
            }
          >
            <Data.Reason reason={participant.reason} />
          </Data.Item>
        )}
        {participant.address?.country && (
          <Data.Item className="participant-data country">
            <Data.CountryCode country={participant.address?.country} />
          </Data.Item>
        )}
        {participant.companyno && (
          <Data.Item
            className="participant-data vat"
            title={
              <FormattedMessage
                id="tender-create-order-participants-title-company-number"
                description="Title for Company number field on tenders create/edit participants page"
                defaultMessage="Company no"
              />
            }
          >
            {participant.companyno}
          </Data.Item>
        )}
        {participant.website && (
          <Data.Item
            className="participant-data web"
            title={
              <FormattedMessage
                id="tender-create-order-participants-title-website"
                description="Title for Website field on tenders create/edit participants page"
                defaultMessage="Website"
              />
            }
          >
            <Data.Website value={participant.website} />
          </Data.Item>
        )}
      </Data.List>
    </li>
  );
};
