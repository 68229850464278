import React, { useRef, useState, useEffect } from 'react';
import { Loading, Alert, Form, Button, Checkbox } from 'Components/nui';
import { api } from '~/api';
import { toast } from 'react-toastify';
import { FormWrapper } from 'Components/form';
import { mergeSchema } from 'Components/form/utils';
import { promisify } from 'es6-promisify';
import { useSharedTrade } from '../..';

export default () => {
  const [trade] = useSharedTrade();
  const schema = useRef({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    setLoading(true);

    (async () => {
      try {
        const response = await api.request({
          method: 'get',
          url: `/trades/${trade.id}/freightquote/schema`,
        });
        if (mounted) {
          schema.current = response.data.fields;
          setLoading(false);
        }
      } catch (err) {
        toast.error('An error occurred while loading this form.');
        schema.current = 'error';
      }
    })();

    return () => {
      mounted = false;
    };
  }, [trade]);

  if (loading) {
    return <Loading size="small" />;
  }

  if (schema.current === 'error') {
    return (
      <Alert type="error" hasicon className="align-center">
        An error occurred.
      </Alert>
    );
  }

  return (
    <div className="info-form-area">
      <WrappedForm schema={schema.current} />
    </div>
  );
};

function useFormWrapper({ form, schema }) {
  const fields = [
    {
      name: 'comment',
      label: 'Add a note to the seller',
      type: null,
    },
  ];

  return new FormWrapper(form, {}, mergeSchema(schema, fields));
}

const BaseForm = ({ form, schema }) => {
  const formWrapper = useFormWrapper({
    form,
    schema,
  });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);
  const [trade, setTrade] = useSharedTrade();
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      setLoading(false);
      return;
    }

    try {
      let data = formWrapper.serialize();
      const response = await api.postData(
        `/trades/${trade.id}/freightquote`,
        data
      );
      form.resetFields();
      toast.success('Your request has been sent to the seller');

      setTrade(trade => ({
        ...trade,
        info: [...(trade?.info || []), response.request].filter(x => x),
        freightquote: { request: response.request, status: 'requested' },
      }));
    } catch (err) {
      setLoading(false);
      console.error(err);
      toast.error('An error occurred while submitting this form');
    }
  }

  return (
    <div className="nui-form">
      <Checkbox
        checked={showForm}
        onChange={e => setShowForm(e.target.checked)}
      >
        Request a quote for the total freight cost
      </Checkbox>

      {showForm && (
        <Form onSubmit={handleSubmit}>
          {formWrapper.render()}
          <Button
            type="primary"
            disabled={loading}
            loading={loading}
            htmlType="submit"
          >
            Request
          </Button>
        </Form>
      )}
    </div>
  );
};

const WrappedForm = Form.create()(BaseForm);
