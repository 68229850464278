import * as React from 'react';
import Helmet from 'Components/Helmet';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import * as Wizard from 'Components/nui/Wizard';
import { Link } from 'react-router-dom';
import EditContext, { useCustomerRequest, FormContext } from './EditContext';
import { Loading } from 'Components/nui';
import Error404 from 'Pages/Error404';
import * as R from 'ramda';
import Company from './Company';
import Address from './Address';
import Links from './Links';
import Locations from './Locations';
import Users from './Users';
import Summary from './Summary';
import Review from './Review';

export default () => {
  return (
    <EditContext>
      <Helmet>
        <title>Customer requests</title>
      </Helmet>
      <div className="full-page-header">
        <Heading className="inline mt-10 mr-20 mb-0" />
        <Breadcrumb />
        <hr className="sm10-md20 mb-0" />
        <Edit />
      </div>
    </EditContext>
  );
};

const Heading = (props: any) => {
  const { customer } = useCustomerRequest();

  if (customer.id) return <h1 {...props}>Edit customer request</h1>;
  return <h1 {...props}>New customer request</h1>;
};

const Breadcrumb = () => {
  const { customer } = useCustomerRequest();

  return (
    <ul className="nui-bc right pt-20">
      <li>
        <BreadcrumbRoot active="customer-requests" />
      </li>
      <li>
        <Link to="/customer-requests">Customer requests</Link>
      </li>
      <li>{customer.id ? 'Edit request' : 'Add'}</li>
    </ul>
  );
};

const validator = (exp: boolean, message: string) => (exp ? message : false);

const Edit = () => {
  const { customer, error, loading, validation } = useCustomerRequest();

  const getUrl = (path: string) => `/customer-requests/${customer?.id}/${path}`;

  const validate = (...validators: (false | string)[]) =>
    R.reject(R.equals<string | false>(false), validators) as string[];

  const editable = R.includes(customer.formstatus, ['incomplete', 'rejected']);

  const {
    formComplete,
    hasCompany,
    hasAddress,
    hasLinks,
    hasLocations,
    hasUsers,
    hasManagers,
  } = validation;

  const steps = [
    {
      id: 'company',
      url: customer.id ? getUrl('company') : '/customer-requests/add',
      title: 'Company details',
      desc: 'Set up basic company information',
      render: () => <Company />,
      completed: hasCompany,
      disabled: !editable,
    },
    {
      id: 'address',
      url: getUrl('address'),
      title: 'Address details',
      desc: "Set up the company's addresses",
      disabled: !editable || !customer.id,
      render: () => <Address />,
      errors: validate(
        validator(
          (hasLinks || hasLocations || hasUsers) && !hasAddress,
          'Address details not provided.'
        )
      ),
      completed: hasAddress,
    },
    {
      id: 'links',
      url: getUrl('links'),
      title: 'Link divisions',
      desc: 'Link this company with your division(s), and set any trading details, such as credit limit and payment terms',
      disabled: !editable || !customer.id,
      render: () => <Links />,
      completed: hasLinks,
      errors: validate(
        validator(
          (hasLocations || hasUsers) && !hasLinks,
          'No divisions have been linked.'
        )
      ),
    },
    {
      id: 'locations',
      url: getUrl('locations'),
      title: 'Locations',
      desc: 'Add locations for this company',
      disabled: !editable || !customer.id,
      render: () => <Locations />,
      completed: hasLocations,
      errors: validate(
        validator(hasUsers && !hasLocations, 'No locations have been added.')
      ),
    },
    {
      id: 'users',
      url: getUrl('users'),
      title: 'People',
      desc: 'Add manager(s), traders and viewers',
      disabled: !editable || !customer.id,
      render: () => <Users />,
      completed: hasUsers && hasManagers,
      errors: validate(
        validator(hasUsers && !hasManagers, 'At least one manager is required.')
      ),
    },
    {
      id: 'review',
      url: getUrl('review'),
      title: editable ? 'Review and submit' : 'Summary',
      desc: 'Review customer details and submit for processing',
      disabled: !formComplete,
      render: () => <Review />,
      numbered: false,
      summary: false,
    },
  ];

  return error ? (
    <Error />
  ) : (
    <FormContext>
      <Wizard.Wizard steps={steps}>
        <Wizard.Content>
          {loading ? <Loading size="large" /> : <Wizard.RouteSteps heading />}
        </Wizard.Content>
        <Wizard.Summary title="Summary">
          <Summary />
        </Wizard.Summary>
      </Wizard.Wizard>
    </FormContext>
  );
};

const Error = () => {
  const context = useCustomerRequest();

  if (context.error) {
    switch (context.error.status) {
      case 404: {
        return <Error404 />;
      }
      default: {
        return <strong>An error occurred</strong>;
      }
    }
  }
  return <React.Fragment />;
};
