import React, { useState, useEffect } from 'react';
import { useStoreDispatch, useStoreState } from 'easy-peasy';
import { api } from '~/api';
import moment from 'moment-timezone';
import { useBodyClassReverse } from '~/hooks';
import { Checkbox, Button } from 'Components/nui';

export default () => {
  const dispatch = useStoreDispatch();
  const solution = useStoreState(state => state.auth.solution);
  const policesToAccept = solution.policies.filter(p => p.accepted === false);

  const policyNames = policesToAccept.map(p => p.name);

  const termsNeeded = policyNames.includes('terms');
  const privacyNeeded = policyNames.includes('privacy');
  const nuiTermsNeeded = policyNames.includes('nui-terms');

  const [termsToAccept, setTermsToAccept] = useState(true);
  const [privacyToAccept, setPrivacyToAccept] = useState(true);
  const [nuiTermsToAccept, setNuiTermsToAccept] = useState(true);

  useEffect(() => {
    setTermsToAccept(termsNeeded);
    setPrivacyToAccept(privacyNeeded);
    setNuiTermsToAccept(nuiTermsNeeded);
  }, [solution]);

  const [loading, setLoading] = useState(false);

  async function accept() {
    setLoading(true);
    await api.acceptPolicy(solution.id);
    dispatch.auth.checkAuth();
  }

  useBodyClassReverse();

  return (
    <section className="main-content privy-content" role="main">
      <div>
        <div className="privy">
          <h1>Terms and privacy statement</h1>
        </div>
      </div>

      {(termsNeeded || privacyNeeded) && (
        <div>
          <div className="privy">
            <p>
              Please read and accept the{' '}
              {privacyNeeded && (
                <strong className="all-black">privacy statement</strong>
              )}{' '}
              {privacyNeeded && termsNeeded && 'and '}
              {termsNeeded && (
                <strong className="all-black">terms of use</strong>
              )}{' '}
              prior to proceeding to the platform.
            </p>
            {privacyNeeded && (
              <Checkbox
                checked={!privacyToAccept}
                onChange={e => setPrivacyToAccept(!e.target.checked)}
                title=""
                disabled={loading}
              >
                I have read and understand the{' '}
                <a
                  href={policesToAccept.find(p => p.name === 'privacy').url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  privacy statement
                </a>
                {policesToAccept.find(p => p.name === 'privacy').updated && (
                  <em>
                    {' '}
                    (updated on{' '}
                    {moment(
                      policesToAccept.find(p => p.name === 'privacy').updated
                    ).format('ll')}
                    )
                  </em>
                )}
              </Checkbox>
            )}
            {termsNeeded && (
              <div>
                <Checkbox
                  checked={!termsToAccept}
                  onChange={e => setTermsToAccept(!e.target.checked)}
                  title=""
                  disabled={loading}
                >
                  I have read and understand the{' '}
                  <a
                    href={policesToAccept.find(p => p.name === 'terms').url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms of use
                  </a>
                  {policesToAccept.find(p => p.name === 'terms').updated && (
                    <em>
                      {' '}
                      (updated on{' '}
                      {moment(
                        policesToAccept.find(p => p.name === 'terms').updated
                      ).format('ll')}
                      )
                    </em>
                  )}
                </Checkbox>
              </div>
            )}
          </div>
        </div>
      )}

      {nuiTermsNeeded && (
        <div className="mt-20">
          <div className="privy">
            <p>
              Please read and accept the{' '}
              <strong className="all-black">Nui terms of use</strong> prior to
              proceeding to the platform.
            </p>
            <div>
              <Checkbox
                checked={!nuiTermsToAccept}
                onChange={e => setNuiTermsToAccept(!e.target.checked)}
                title=""
                disabled={loading}
              >
                I have read and understand the{' '}
                <a
                  href={policesToAccept.find(p => p.name === 'nui-terms').url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Nui terms of use
                </a>
                {policesToAccept.find(p => p.name === 'nui-terms').updated && (
                  <em>
                    {' '}
                    (updated on{' '}
                    {moment(
                      policesToAccept.find(p => p.name === 'nui-terms').updated
                    ).format('ll')}
                    )
                  </em>
                )}
              </Checkbox>
            </div>
          </div>
        </div>
      )}

      <div>
        <div className="privy">
          <hr />
          <div className="button-set pt-20">
            <Button
              type="primary"
              disabled={nuiTermsToAccept || termsToAccept || privacyToAccept}
              onClick={accept}
              loading={loading}
            >
              Accept
            </Button>
            <Button type="simple" onClick={() => dispatch.auth.signOut()}>
              Sign Out
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
