import type * as Responses from 'endpoints.tenders';
import type * as Storage from 'services.tenders.storage';
import moment from 'moment-timezone';
import * as R from 'ramda';
import { createStore, action, computed } from 'easy-peasy';
import { createObservable } from '~/observable';
import * as serviceImport from './internal';
import * as events from './events';

// workaround for tests not handling imports properly
const service = serviceImport;
if (process.env.NODE_ENV === 'test') {
  // @ts-ignore
  service.events = events;
}

const isDefined = <X>(x: X | undefined): x is X => !!x;
const isInit = <X>(x: Storage.InitMeta<X>): x is Storage.Meta<X> => !!x.updated;

const store = createStore<Storage.Store>(
  {
    data: {},
    overview: { updated: null, errors: [], loading: false },
    history: { total: 0, pages: [], loading: [] },
    participants: {},

    createTender: action((state, id) => {
      if (!state.data[id]) {
        state.data[id] = { tender: { updated: undefined, errors: [] } };
      }
    }),

    reset: action(state => {
      state.data = {};
      state.overview = { updated: null, errors: [], loading: false };
      state.history = { total: 0, pages: [], loading: [] };
      state.participants = {};
    }),

    update: action(
      (state, { id, polled: since, tender, orders, counters, info }) => {
        const initial = state.data[id];
        const updated = moment.utc().toISOString();
        const polled = since?.toISOString();

        if (initial) {
          state.overview.updated = updated;

          if (tender) {
            const prev = initial.tender;

            if (prev.updated) {
              if (
                moment(tender.updated).isSameOrAfter(moment(prev.data.updated))
              ) {
                state.data[id]!.tender = {
                  updated,
                  errors: prev.errors,
                  polled: polled || prev.polled,
                  data: tender,
                };
              }
            } else {
              state.data[id] = {
                tender: { updated, polled, data: tender, errors: [] },
                orders: { updated: undefined, errors: [] },
                counters: { updated: undefined, errors: [] },
              };
            }
          }

          if (orders) {
            const prev = initial.orders;

            if (prev?.updated) {
              state.data[id]!.orders!.updated = updated;
              if (polled) state.data[id]!.orders!.polled = polled;

              for (const order of Object.values(orders)) {
                const current = prev.data[order.id];
                if (current) {
                  if (
                    moment(order.updated).isSameOrAfter(moment(current.updated))
                  ) {
                    state.data[id]!.orders!.data![order.id] = order;
                  }
                } else {
                  state.data[id]!.orders!.data![order.id] = order;
                }
              }
            } else {
              state.data[id]!.orders = {
                updated,
                polled,
                errors: [],
                data: orders,
              };
            }
          }

          if (counters) {
            const prev = initial.counters;

            if (prev?.updated) {
              state.data[id]!.counters!.updated = updated;
              if (polled) state.data[id]!.counters!.polled = polled;

              for (const counter of Object.values(counters)) {
                const current = prev.data[counter.id];
                if (counter.status === 'cancelled') {
                  delete state.data[id]!.counters!.data![counter.id];
                } else if (current) {
                  const a = moment(counter.updated || counter.priceupdated);
                  const b = moment(current.updated || current.priceupdated);
                  if (a.isSameOrAfter(b)) {
                    state.data[id]!.counters!.data![counter.id] = counter;
                  }
                } else {
                  state.data[id]!.counters!.data![counter.id] = counter;
                }
              }
            } else {
              state.data[id]!.counters = {
                updated,
                polled,
                errors: [],
                data: R.reject(c => c.status === 'cancelled', counters),
              };
            }
          }

          if (info) {
            const current = state.data[id]?.orders?.data;
            if (current) {
              for (const entry of info) {
                const order = current[entry.id];
                if (
                  order.updated &&
                  entry.updated &&
                  moment(entry.updated).isSameOrAfter(moment(order.updated))
                ) {
                  state.data[id]!.orders!.data![entry.id].updated =
                    entry.updated;

                  if (!R.isNil(entry.nextprice)) {
                    state.data[id]!.orders!.data![
                      entry.id
                    ].startprice.nextprice = entry.nextprice;
                  }

                  if (!R.isNil(entry.quickprice)) {
                    state.data[id]!.orders!.data![
                      entry.id
                    ].startprice.quickprice = entry.quickprice;
                  }

                  if (!R.isNil(entry.reserve_met)) {
                    state.data[id]!.orders!.data![entry.id].reserve_met =
                      entry.reserve_met;
                  }
                }
              }
            }
          }
        } else {
          if (tender) {
            state.data[id] = {
              tender: { updated, errors: [], data: tender },
              orders: orders
                ? { updated, errors: [], data: orders }
                : { updated: undefined, errors: [] },
              counters: counters
                ? { updated, errors: [], data: counters }
                : { updated: undefined, errors: [] },
            };
          } else {
            console.warn('store.update: failed - tender does not exist');
          }
        }
      }
    ),

    updateOverview: action(
      (state, { solution, polled, errors, tenders, loading }) => {
        const updated = moment.utc().toISOString();

        state.overview.updated = updated;
        state.overview.loading = loading ?? state.overview.loading;
        if (solution) state.overview.solution = solution;
        if (polled) state.overview.polled = polled.toISOString();
        if (errors) state.overview.errors = errors;

        if (tenders) {
          for (const tender of tenders) {
            const current = state.data[tender.id]?.tender;

            if (current?.updated) {
              const a = moment(tender.updated);
              const b = moment(current.data.updated);
              if (a.isSameOrAfter(b)) {
                state.data[tender.id]!.tender.updated = updated;
                state.data[tender.id]!.tender.data = tender;
              }
            } else {
              state.data[tender.id] = {
                tender: { updated, errors: [], data: tender },
                orders: { updated: undefined, errors: [] },
                counters: { updated: undefined, errors: [] },
              };
            }
          }
        }
      }
    ),

    updateHistory: action((state, { total, addPages, setLoading }) => {
      if (total) state.history.total = total;
      if (addPages) {
        for (const page of addPages) {
          if (!state.history.pages.includes(page)) {
            state.history.pages.push(page);
            state.history.pages.sort();
          }
        }
      }
      if (setLoading) {
        let add: number[] = [];
        let remove: number[] = [];
        for (const [page, value] of setLoading) {
          if (value && !state.history.loading.includes(page)) add.push(page);
          else remove.push(page);
        }
        state.history.loading = [...state.history.loading, ...add].filter(
          x => !remove.includes(x)
        );
      }
    }),

    initParticipants: action((state, tender) => {
      if (!state.participants[tender]) {
        state.participants[tender] = { updated: undefined, errors: [] };
      }
    }),

    updateParticipants: action((state, { tender, participants }) => {
      const updated = moment.utc().toISOString();
      const { errors = [], data } = state.participants[tender] || {};
      state.participants[tender] = {
        updated,
        errors,
        data: { ...data, ...participants },
      };
      if (state.data[tender]?.tender.data) {
        const excluded = Object.values(
          state.participants[tender]!.data!
        ).filter(x => !x.isapproved).length;
        state.data[tender]!.tender.data!.participants!.excluded = excluded;
      }
    }),

    onTenderExtend: action((state, { id, extramins }) => {
      if (state.data[id]?.tender.data) {
        state.data[id]!.tender.data!.extramins = extramins;
      }
    }),

    deleteOrder: action((state, { tender, order }) => {
      const prev = state.data[tender]?.orders?.data;
      if (prev && prev[order]) {
        state.data[tender]!.orders!.data![order].volume.val = 0;
      }
    }),

    deleteCounter: action((state, { tender, order, counter }) => {
      const prev = state.data[tender];
      if (prev) {
        if (prev.counters?.data && prev.counters.data[counter]) {
          delete state.data[tender]!.counters!.data![counter];
        }
        if (prev.orders?.data && prev.orders.data[order]) {
          state.data[tender]!.orders!.data![order].__acl__.add.bids = true;
        }
      }
    }),

    cancelTender: action((state, { tender }) => {
      const prev = state.data[tender]?.tender.data;
      if (prev) {
        state.data[tender]!.tender.data!.status = 'cancelled';
        state.data[tender]!.tender.data!.__acl__ = {
          edit: false,
          delete: false,
          publish: false,
          notify: false,
          finalise: false,
          add: {
            offers: false,
            participants: false,
          },
        };
      }
    }),

    getOverview: computed(
      state => () =>
        Object.values(state.data)
          .filter(isDefined)
          .map(d => d.tender)
          .filter(isInit)
          .map(t => t.data)
          .filter(t => !['archived', 'stopped', 'cancelled'].includes(t.status))
          .filter(t => t.solution.id === state.overview.solution)
    ),

    getHistory: computed(
      state => () =>
        Object.values(state.data)
          .filter(isDefined)
          .map(d => d.tender)
          .filter(isInit)
          .map(t => t.data)
          .filter(t => t.finalised)
    ),

    getParticipants: computed(state => id => {
      const value = state.participants[id];
      if (value) {
        if (value.updated) {
          return { ...value, data: Object.values(value.data) };
        }
        return { updated: undefined, errors: [] };
      }
      return undefined;
    }),

    getTender: computed(
      state => id =>
        state.data[id]?.tender || { updated: undefined, errors: [] }
    ),

    getOrders: computed(state => id => {
      const value = state.data[id]?.orders;
      if (value?.updated) {
        const data = Object.values(value.data).filter(o => o.volume.val > 0);
        return { ...value, data };
      }
      return { updated: undefined, errors: [] };
    }),

    getCounters: computed(state => id => {
      const value = state.data[id];
      if (value?.tender.updated && value.counters?.updated) {
        const tender = value.tender.data;
        if (tender.method === 'blind' || !tender.started) {
          return {
            ...value.counters,
            data: Object.values(value.counters.data),
          };
        }

        const counters = Object.values(
          R.groupBy(c => c.offer.id, Object.values(value.counters.data))
        ).flatMap(counters => {
          const ranked = counters.sort((a, b) => {
            const direction = +(tender.type === 'sell') * 2 - 1;
            const [x, y] = [a.price.val ?? 0, b.price.val ?? 0];
            const diff = direction * (y - x);
            if (diff === 0) return moment(a.priceupdated).diff(b.priceupdated);
            return diff;
          });

          return ranked.map((counter, index) => ({
            ...counter,
            rank: index + 1,
          }));
        });

        return { ...value.counters, data: counters };
      }

      return { updated: undefined, errors: [] };
    }),

    getCountersPolled: computed(state => id => {
      const polled = state.data[id]?.counters?.polled;
      if (polled) return moment(polled);
      return undefined;
    }),
  },
  { name: 'tender-store' }
);

function state() {
  return store.getState();
}

function actions() {
  return store.getActions();
}

export const observe = createObservable(store);

export function getHistory() {
  return state().history;
}

export function getOverview() {
  return state().overview;
}

export function getTender(id: string) {
  return state().getTender(id);
}

export function getOrders(id: string) {
  return state().getOrders(id);
}

export function getCountersPolled(id: string) {
  return state().getCountersPolled(id);
}

export function getParticipants(id: string) {
  return state().getParticipants(id);
}

service.events.onSolution(({ solution }) => {
  actions().reset();
  actions().updateOverview({ solution, loading: true });
});

service.events.onSignout(() => {
  actions().reset();
});

service.events.onOverview(({ data, polled }) => {
  const tenders = data.tenders.map(({ offers, ...tender }) => ({
    ...tender,
    orders: { total: offers.length },
  }));

  actions().updateOverview({ loading: false, tenders, polled });
});

service.events.onTender(({ data, polled }) => {
  const { offers, ...tender } = data.tender;

  const orders = Object.fromEntries(
    offers.map(({ bids, ...order }) => [
      order.id,
      { ...order, questionsCount: undefined },
    ])
  );
  const counters = Object.fromEntries(
    offers.flatMap(({ bids, ...offer }) =>
      bids.map(b => [
        b.id,
        { ...b, offer: { id: offer.id, href: offer.href, pid: offer.pid } },
      ])
    )
  );

  actions().update({
    id: tender.id,
    tender: { ...tender, orders: { total: offers.length } },
    orders,
    counters,
    polled,
  });
});

service.events.onOrder(({ data }) => {
  const { bids, ...order } = data.offer;
  const counters = Object.fromEntries(
    bids.map(b => [
      b.id,
      { ...b, offer: { id: order.id, href: order.href, pid: order.pid } },
    ])
  );
  actions().update({
    id: order.tender.id,
    counters,
    orders: { [order.id]: order },
  });
});

service.events.onCounters(({ tender, data, polled }) => {
  const counters = Object.fromEntries(data.bids.map(b => [b.id, b]));
  const info = data.offers || [];
  actions().update({ id: tender, counters, info, polled });
});

service.events.onExtended(({ tender, extramins }) => {
  actions().onTenderExtend({ id: tender, extramins });
});

service.events.onDeleteOrder(({ tender, order }) => {
  actions().deleteOrder({ tender, order });
});

service.events.onDeleteCounter(({ tender, order, counter }) => {
  actions().deleteCounter({ tender, order, counter });
});

service.events.onCancelTender(({ tender }) => {
  actions().cancelTender({ tender });
});

export function initParticipants(tender: string) {
  actions().initParticipants(tender);
}

export function onParticipants(
  tender: string,
  response: Responses.ParticipantsGet
) {
  const participants = Object.fromEntries(
    response.participants.map(p => [p.id, p])
  );
  actions().updateParticipants({ tender, participants });
}

type IHistoryPag = {
  total?: number;
  addPages?: number[];
  setLoading?: [number, boolean][];
};
export function onHistoryPagination(config: IHistoryPag) {
  actions().updateHistory(config);
}

export function onSingleTenderLoad(data) {
  service.events.tender({ data });
}
