import React, { useEffect, useRef } from 'react';
import PageWrapper from 'Pages/PageWrapper';
import SignIn from 'Pages/SignIn';
import * as R from 'ramda';
import { matchPath, Route, Switch } from 'react-router-dom';
import { compile } from 'path-to-regexp';
import { PrivateRoute } from './PrivateRoute';

/**
 * TODO
 * - Transition blocking when leaving from dirty form
 * - Authorization: admin, manager, trader, viewer
 * - Flag based routing, dynamic routing, feature toggle
 */

export const UUID =
  '[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}';

export const ORDERPID = '[BO]-[A-Z0-9]{8}-\\d+';

export const PRIVATE_ROUTES = {
  home: '/',
  overview: '/overview',
  marketplace: '/marketplace',
  marketdepth: `/marketplace/:productId(${UUID})`,

  dashboard: '/dashboard',
  // dashboard2: '/dashboard2',

  products: '/products',
  product: `/products/:id(${UUID})/quick`,
  productSpecifications: `/products/:id(${UUID})/specifications`,

  tenders: '/tenders',

  orders: '/orders',
  orderNew: '/orders/:orderType(bid|offer)',
  orderEdit: `/orders/:orderId(${ORDERPID})/edit`,
  liquidityProvider: '/lp-overview',
  trades: '/trades',

  account: '/account',

  config: '/config',

  routes: '/routes',

  participants: '/participants',
  locations: '/locations',
  customers: '/customers',
  consignees: '/partners',
  customerRequest: '/customer-requests',
  reports: '/reports',

  consolidation: '/consolidation',
  deliveries: '/deliveries',
  users: '/users',

  futures: '/futures',
  duplicate: '/duplicate', // duplicate (tender) orders
};

export const ROUTES = {
  signIn: '/signin',
  loginEmail: '/login/email',
  ...PRIVATE_ROUTES,
};

export function routeUrl(route, params = {}) {
  return compile(route)(params);
}

export function useBaseUrl(base, baseParams = {}) {
  return [
    // route
    function (route, params) {
      return routeUrl([base, route].join('/'), { ...baseParams, ...params });
    },
    // match
    function (route, params) {
      return `${base}/${route}`;
    },
  ];
}

export function checkIsModal(pathname) {
  return R.any(
    path => matchPath(pathname, { path }),
    [
      '/preview',
      ROUTES.orderNew,
      ROUTES.orderEdit,
      ROUTES.marketplace,
      ROUTES.marketdepth,
      ROUTES.product,
      ROUTES.productSpecifications,
      ROUTES.account,
      ROUTES.futures,
      ROUTES.duplicate,
    ]
  );
}

export function checkIsTradeModal(pathname) {
  const matchingResult = matchPath(pathname, '/preview/trade/:id');
  return matchingResult;
}

export function usePreviousNormalLocation(value) {
  const ref = useRef();
  useEffect(() => {
    if (!checkIsModal(value.pathname)) {
      ref.current = value;
    }
  });
  return ref.current;
}

export function Routes({ user }) {
  return (
    <Switch>
      {/* TODO check email login link */}
      <Route path={[ROUTES.signIn, ROUTES.loginEmail]} component={SignIn} />
      <PrivateRoute
        path={R.values(PRIVATE_ROUTES)}
        component={PageWrapper}
        user={user}
      />
    </Switch>
  );
}
