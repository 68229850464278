import { Loading } from 'Components/nui';
import React, { useEffect } from 'react';
import { ApiError, useSharedFilter } from '..';
import CompaniesLastSeen from './CompaniesLastSeen';
import MostActiveCompanies from './MostActiveCompanies';
import UniqueUserCompanyActivity from './UniqueUserCompanyActivity';
import UserActivity from './UserActivity';
import ProductsActivity from './ProductsActivity';
import { useSharedCEData } from 'Pages/Dashboard';
import { useToggle } from 'react-use';
import * as R from 'ramda';

export default () => {
  const [data] = useSharedCEData();
  const [loading, toggle] = useToggle(true);
  const [filter] = useSharedFilter();

  useEffect(() => {
    if (data) {
      toggle(false);
    }
  }, [data]);

  return data === 'Error' ? (
    <ApiError />
  ) : R.isEmpty(filter) && loading ? (
    <Loading size="large" />
  ) : (
    <>
      <div className="flex-desktop gap-20">
        <UserActivity />
        <hr className="mt-30 mb-30 hide-for-xlarge" />
        <MostActiveCompanies />
      </div>
      <hr className="mt-30 mb-30" />
      <div className="flex-desktop gap-20">
        <UniqueUserCompanyActivity />
        <hr className="mt-30 mb-30 hide-for-xlarge" />
        <CompaniesLastSeen />
      </div>
    </>
  );
};
