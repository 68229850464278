import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Alert } from 'Components/nui';

export function useResultState() {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState();
  const [redirect, setRedirect] = useState('');

  const state = {
    loading,
    setLoading,
    result,
    setResult,
    redirect,
    setRedirect,
  };
  return state;
}

export function ShowResult({
  renderError = () => (
    <Alert hasicon type="error">
      <h4>Submit failed</h4>
      Something went wrong and process failed. Please try again later.
    </Alert>
  ),
  renderSuccess = () => <></>,
  handleModalClose,
  silentSuccess = false,
  children,
}) {
  // TODO review logic
  /**
   * - Removes display of the form on submit failure
   * - No success feedback on redirect
   *  - Consider a few seconds pause, showing message before redirect
   */

  const state = useResultState();
  if (state.result && !(state.result instanceof Error)) {
    if (state.redirect && !state.loading)
      return <Redirect to={state.redirect} />;

    if (!silentSuccess) {
      return renderSuccess(state.result, state, handleModalClose);
    }
  }
  return (
    <>
      {state.result && renderError(state.result, state, handleModalClose)}
      <div style={{ display: state.result ? 'none' : 'block' }}>
        {children(state)}
      </div>
    </>
  );
}

export function ClassicResult({ success = true, title, children }) {
  return (
    <Alert hasicon type={success ? 'success' : 'error'}>
      <h4>{title}</h4>
      {children}
    </Alert>
  );
}
