import type { Tender, Order, Counter } from '~/models/tenders';
import type { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import { FormWrapper } from 'Components/form';
import { Button, Form } from 'Components/nui';
import { useMountedState } from '~/hooks';
import { promisify } from 'es6-promisify';
import { toast } from 'react-toastify';
import * as services from '~/services/tenders';
import { FormattedMessage } from 'react-intl';

type TenderProp = Record<'tender', Tender>;
type OrderProp = Record<'order', Order>;
type CounterProp = Record<'counter', Counter>;

type IUseFormWrapper = FormComponentProps & CounterProp;

function useFormWrapper({ form, counter }: IUseFormWrapper) {
  const deliveries = counter.stripvolume;
  const initialData = {
    volume: deliveries?.delivered?.val || counter.volume.val,
  };

  const fields = [
    {
      name: 'volume',
      label: (
        <FormattedMessage
          id="tender-trade-volume"
          description="Volume field for tender trade"
          defaultMessage="Volume {deliveries, select, true {per delivery} other {}}"
          values={{ deliveries: !!deliveries }}
        />
      ),
      type: 'Decimal',
      required: true,
      step: counter.volume.step,
    },
  ];

  return new FormWrapper(form, initialData, fields);
}

type IBaseTradeForm = FormComponentProps & ICounterTrade;
const BaseTradeForm = ({ form, tender, order, counter }: IBaseTradeForm) => {
  const [loading, setLoading] = useMountedState(false);
  const formWrapper = useFormWrapper({ form, counter });

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    try {
      await promisify(form.validateFieldsAndScroll)();
    } catch (error) {
      setLoading(false);
      return;
    }

    const { volume } = formWrapper.serialize();
    const result = await services.actions.trade(
      tender.id,
      order.id,
      counter.id,
      { volume }
    );

    if (result?.success) {
      toast.success(`Trade for ${order.product.name} completed successfully`);
    } else if (result?.errors) {
      for (const e of result.errors) toast.error(e);
    } else if (result?.validate) {
      formWrapper.setErrors(result.validate);
      if (result.validate.price)
        toast.error(
          <>
            Could not complete this trade because this participant&apos;s credit
            is too low
          </>
        );
    }

    setLoading(false);
  };

  return (
    <Form className="nui-form order-form" onSubmit={onSubmit}>
      {formWrapper.render()}
      <div>
        <Button
          loading={loading}
          disabled={loading}
          htmlType="submit"
          type="primary"
        >
          <FormattedMessage
            id="tender-trade-button"
            description="Trade button for tender trade"
            defaultMessage="Trade"
          />
        </Button>
      </div>
    </Form>
  );
};
const TradeForm = Form.create<IBaseTradeForm>()(BaseTradeForm);

type ICounterTrade = TenderProp & OrderProp & CounterProp;
const CounterTrade = ({ tender, order, counter }: ICounterTrade) => {
  return <TradeForm tender={tender} order={order} counter={counter} />;
};
export default CounterTrade;
