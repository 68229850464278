import { Form, Popover } from 'Components/nui';
import classnames from 'classnames';
import * as R from 'ramda';
import React from 'react';
import { getCallableName } from '../utils';
import { FormattedMessage } from 'react-intl';

// XXX This is causing misfunction of babel plugins. Do not do this.
// import type { WrappedFormUtils } from 'antd/es/form';

/**
 * When being array, choice.value is used to represent grouped choices.
 * XXX Besides string|number|array here, using object as choice.value is not supported by rc-select,
 *  ref https://github.com/react-component/select/blob/master/src/util.ts#L12
 * thus we can not simply use for example product or loading as the value, need to lookup the object manually.
 */

/**
 * TODO
 * preprocess and postprocess
 * value getter from initialData
 * better spec interface
 */
export default class BaseField {
  // widget: ElementType;

  constructor(spec) {
    this.spec = spec;
  }

  getSpec() {
    // props of spec can be function and are evaluated when necessary
    return R.mergeAll(
      R.toPairs(this.spec).map(([k, v]) => ({
        [k]:
          !R.includes(k, ['widget', 'serializeField']) &&
          typeof v === 'function'
            ? v()
            : v,
      }))
    );
  }

  getType() {
    return getCallableName(this).slice(0, -'Field'.length);
  }

  // Used in getFieldDecorator, to normalize the value
  // normalize(cur: any): void | T {
  normalize(cur) {
    return cur;
  }

  // TODO
  // When getInitialData is provided, use it to get value from initialData or fixed one, before normalize the value
  // When serializeField is provided, use it w/ normalized values of all fields and serialized value of the field
  serializeField(values) {
    const { name, serializeField } = this.getSpec();
    const raw = values[name];
    const value = this.serialize(raw);
    if (serializeField) {
      return serializeField(value, raw, values);
    }
    return { [name]: value };
  }

  serialize(value) {
    return value;
  }

  // Use either pass-in widget or the default one defined in this Field to render
  renderUnbound(spec = this.getSpec()) {
    // return React.createElement(spec.widget || this.widget, spec);
    return (spec.widget || this.widget)(spec);
  }

  // getFieldDecorator + renderUnbound()
  renderBound(form, spec = this.getSpec()) {
    const normalize = this.normalize.bind(this);
    const { name, initialValue, required } = spec;
    // TODO mergeDeepWith to override options
    return form.getFieldDecorator(name, {
      // Manually invoke normalize() at beginning
      initialValue: normalize(initialValue),
      normalize,
      rules: [
        {
          required,
          message: (
            <FormattedMessage
              id="generic-form-error-text"
              description="text for 'field required' error on generic form"
              defaultMessage="This field is required"
            />
          ),
        },
      ],
      // TODO more customization
    })(this.renderUnbound(spec));
  }

  // <Form.Item> + renderBound()
  render({ form }) {
    const spec = this.getSpec();
    const { label, name, help, type, formItemProps } = spec;
    const itemName = `form-item-name-${name}`;
    return (
      <Form.Item
        label={
          <>
            {label}{' '}
            {help && (
              <Popover overlay={<span>{help}</span>}>
                <span className="popover-link">?</span>
              </Popover>
            )}
          </>
        }
        key={itemName}
        {...formItemProps}
        className={classnames([
          `form-item-type-${type || this.getType()}`,
          itemName,
          R.prop('className', formItemProps),
        ])}
      >
        {this.renderBound(form, spec)}
      </Form.Item>
    );
  }
}
