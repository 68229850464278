import React from 'react';
import moment from 'moment-timezone';
import appstore from 'Assets/img/appstore.svg';
import googleplay from 'Assets/img/googleplay.svg';
import logo from 'Assets/img/mono-logo-black.png';

export default () => (
  <footer className="footer">
    <div className="nui-row">
      <div className="xs24 md16 lg14">
        <p className="copy">
          <span className="companyname">
            &copy; 2014 - {moment().year()} Nui Markets NZ Ltd
          </span>{' '}
          <a
            href="https://www.nuimarkets.com"
            title="Company"
            target="_blank"
            rel="noopener noreferrer"
          >
            Company
          </a>
          {' | '}
          <a
            href="https://www.nuimarkets.com/privacy/"
            title="Privacy policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy policy
          </a>
          {' | '}
          <a
            href="https://www.nuimarkets.com/terms/"
            title="Legal terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            Legal terms
          </a>
          {' | '}
          <a
            href="https://www.nuimarkets.com/contact/"
            title="Contact us"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact us
          </a>
        </p>
      </div>

      <div className="xs24 md8 lg10">
        <p className="applinks">
          Trading app available on{' '}
          <span className="nowrap">
            <a
              href="https://play.google.com/store/apps/details?id=com.nuimarkets.app"
              title="Get it on Google Play"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Play
            </a>
            {' and '}
            <a
              href="https://apps.apple.com/us/app/nui-markets/id1530875871"
              title="Available on the iPhone App Store"
              target="_blank"
              rel="noopener noreferrer"
            >
              App Store
            </a>
          </span>
        </p>
      </div>
    </div>

    <div className="nui-row">
      <hr className="mt-10 mb-10" />
    </div>

    <div className="nui-row">
      <div className="xs24 md12 md12push">
        <p className="applinks">
          <a
            href="https://play.google.com/store/apps/details?id=com.nuimarkets.app"
            className="imglink"
            title="Get it on Google Play"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={googleplay} alt="Get it on Google Play" />
          </a>
          <a
            href="https://apps.apple.com/us/app/nui-markets/id1530875871"
            className="imglink"
            title="Available on the iPhone App Store"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={appstore} alt="Available on the iPhone App Store" />
          </a>
        </p>
      </div>
      <div className="xs24 md12 md12pull">
        <div className="logo">
          <img src={logo} alt="Nui" />
          <p className="mt-5">
            <strong className="all-black m-0">
              Powered by Nui Markets{' '}
              {import.meta.env.VITE_APP_TAG ? (
                <span className="block mt-2">
                  Version {import.meta.env.VITE_APP_TAG}
                </span>
              ) : (
                ''
              )}
              {import.meta.env.VITE_APP_COMMIT ? (
                <span className="block mt-2">
                  Version {import.meta.env.VITE_APP_COMMIT}
                  {import.meta.env.VITE_APP_COMMIT_DT
                    ? ` (${moment
                        .duration(
                          moment(
                            import.meta.env.VITE_APP_COMMIT_DT * 1000
                          ).diff(moment())
                        )
                        .humanize(true)})`
                    : ''}
                </span>
              ) : (
                ''
              )}
              {import.meta.env.VITE_APP_DOMAIN_LABEL ? (
                <span className="block mt-2">
                  Endpoint {import.meta.env.VITE_APP_DOMAIN_LABEL}
                </span>
              ) : (
                ''
              )}
            </strong>{' '}
          </p>
        </div>
      </div>
    </div>
  </footer>
);
