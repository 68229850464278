import type * as Models from '~/models/tenders';
import React from 'react';
import { useStoreState } from '~/store';
import { Popover } from 'Components/nui';
import { routesIncluded, getAddrInfo, getAddrChoice } from '~/utils';
import * as R from 'ramda';
import { FormattedMessage } from 'react-intl';

type EtaErrorProps = {
  error: string;
  className?: string;
};
const EtaError = ({ error, className }: EtaErrorProps) => (
  <div key="new-eta" className="mb-10">
    <abbr title={'Estimated Time of Arrival'}>
      <FormattedMessage
        id="tender-eta-heading"
        description="Heading for ETA field for tenders"
        defaultMessage="ETA"
      />
    </abbr>{' '}
    <Popover
      overlay={
        <span>
          <FormattedMessage
            id="tender-eta-tooltip"
            description="Tooltip for ETA field for tenders"
            defaultMessage="Estimated Time of Arrival"
          />
        </span>
      }
    >
      <span className="popover-link">?</span>
    </Popover>
    <p>
      <strong className={className}>{error}</strong>
    </p>
    <hr />
  </div>
);

type IEta = Record<'order', Models.Order> &
  Record<'form', any> &
  Record<'schema', any>;
export const Eta = ({ order, form, schema }: IEta) => {
  const solution = useStoreState(state => state.auth.solution);
  const toaddr = !R.isNil(form.getFieldValue('toaddr'))
    ? form.getFieldValue('toaddr')
    : schema?.toaddr?.value;
  const division = !R.isNil(form.getFieldValue('division'))
    ? form.getFieldValue('division')
    : schema?.division?.value;
  const toaddrInfo =
    schema?.toaddr?.choices &&
    getAddrInfo(toaddr)(getAddrChoice(division)(schema.toaddr.choices));
  if (!solution.shippingroutes || solution.tenderlogic === 'purchase')
    return <></>;

  if (!toaddrInfo)
    return (
      <EtaError
        error={'Please select delivery location'}
        className="select-location-warning"
      />
    );

  const matchingRoutes = solution.shippingroutes.filter(
    route =>
      order?.location?.[route.type]?.id &&
      routesIncluded(
        [order.location[route.type].id],
        [Object.values(toaddrInfo[2])[0]]
      )(route)
  );

  if (!matchingRoutes.length)
    return (
      <EtaError
        error={`No shipping routes found`}
        className="select-location-warning"
      />
    );

  const sortedRoutes = matchingRoutes.sort(
    (route1, route2) => route1.duration - route2.duration
  );

  return (
    <div key="new-eta" className="mb-10 eta">
      <abbr title={'Estimated Time of Arrival'}>
        {matchingRoutes.length > 1 ? (
          <FormattedMessage
            id="tender-eta-text-multiple"
            description="Text for mutiple Estimated Time of Arrivals"
            defaultMessage="ETAs"
          />
        ) : (
          <FormattedMessage
            id="tender-eta-heading"
            description="Heading for Estimated Time of Arrival field for tenders"
            defaultMessage="ETA"
          />
        )}
      </abbr>{' '}
      <Popover
        overlay={
          <span>
            <FormattedMessage
              id="tender-eta-tooltip"
              description="Tooltip for ETA field for tenders"
              defaultMessage="Estimated Time of Arrival"
            />
          </span>
        }
      >
        <span className="popover-link">?</span>
      </Popover>
      {sortedRoutes.map(route => (
        <div key={route.id}>
          <strong className={'eta-from'}>
            <FormattedMessage
              id="tender-eta-text-route"
              description="Text for route in eta field"
              defaultMessage="From {sourceName} To {destinationName}"
              values={{
                sourceName: route.source.name,
                destinationName: route.destination.name,
              }}
            />
          </strong>
          {' - '}
          <FormattedMessage
            id="tender-eta-text-duration"
            description="Text for estimated time of departure duration in eta field"
            defaultMessage="{date} (ETD + {days} {days, plural, one {day} other {days}})"
            values={{
              date: order.etd.to
                .clone()
                .add(route.duration, 'days')
                .format('DD MMM YYYY'),
              days: route.duration,
            }}
          />
        </div>
      ))}
      <hr />
    </div>
  );
};
