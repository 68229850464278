import React, { useState, useEffect } from 'react';
import { Form, Button, Checkbox, Alert } from 'Components/nui';
import { FormWrapper } from 'Components/form';
import {
  mergeSchema,
  normalizeChoices,
  submitError,
} from 'Components/form/utils';
import { LoadingState } from 'Components/Layout';
import { ShowResult } from 'Components/Result';
import { promisify } from 'es6-promisify';
import * as R from 'ramda';
import { useAsync } from 'react-use';
import { api } from '~/api';
import { useParams, useHistory } from 'react-router-dom';
import Helmet from 'Components/Helmet';
import { SelectWidget } from 'Components/form/widgets';
import { useStoreState } from 'easy-peasy';
import { capitalize } from '~/utils';
import { useSharedProduct } from '..';

const FileField = ({ name = '', value, onChange, accept, initialValue }) => {
  const reset = () => {
    onChange(initialValue);
  };

  const clearValue = () => {
    onChange();
  };

  const filename = value?.name || value?.filename;
  const href = value && (value?.href || URL.createObjectURL(value));

  return (
    <div className="logo-file-input-holder nui-fieldset">
      <input
        type="file"
        accept={accept}
        id={name}
        className="logo-file-input"
        onChange={e => {
          const file = e.target.files[0];
          e.target.value = '';
          onChange(file);
        }}
      />
      {value ? (
        <>
          <span className="value-name">
            <span className="icon-ok" /> {filename}
          </span>
          <img className="value-preview" src={href} alt={filename} />
          <Button
            className="value-remove"
            type="buttonlink"
            onClick={clearValue}
          >
            (Remove)
          </Button>
        </>
      ) : (
        initialValue && (
          <>
            <span className="removed-value">
              <span className="icon-block" /> {initialValue.filename} removed
            </span>
            <Button className="removed-reset" type="buttonlink" onClick={reset}>
              (Undo)
            </Button>
          </>
        )
      )}
    </div>
  );
};

const PForm = ({ form, product, schema, submitState }) => {
  const history = useHistory();
  const { id } = useParams();
  const solution = useStoreState(state => state.auth.solution);
  const divisions = useStoreState(state => state.auth.solutionDivisions);
  const [_, setProduct] = useSharedProduct();

  const [logo, setLogo] = useState();
  useEffect(() => {
    if (product?.logo?.id) setLogo(product.logo);
  }, [product?.logo?.id]);

  const [saved, setSaved] = useState(false);
  const [extra, setExtra] = useState(() => {
    return !(id === undefined || schema.unitfactor.value === 1);
  });

  const getFormWrapper = () => {
    const getChoices = choices => {
      if (choices.length && !Array.isArray(choices[0][1])) {
        return normalizeChoices(choices);
      }
      return R.map(([name, subchoices]) => ({
        key: name,
        label: name,
        value: subchoices.map(x => ({ key: x[0], value: x[0], label: x[1] })),
      }))(choices);
    };

    let division = divisions[0];
    const divisionId = product
      ? product.division.id
      : form.getFieldValue('division');

    if (divisionId) {
      division = R.find(R.propEq('id', divisionId), divisions);
    }

    const cur = R.pathOr('Cur', ['currency', 'code'], division);
    const subcur = capitalize(
      R.pathOr('Subcur', ['currency', 'subunit', 'desc'], division)
    );
    const subcurrencyChoices = [
      { key: 'false', value: 'false', label: cur },
      { key: 'true', value: 'true', label: subcur },
    ];

    let fields = [
      {
        name: 'division',
        label: 'Division',
        choices: normalizeChoices,
      },
      {
        name: 'name',
        label: 'Product name',
        type: 'Input',
      },
      {
        name: 'shortname',
        label: 'Short name',
        type: 'Input',
      },
      {
        name: 'description',
        label: 'Description',
      },
      {
        name: 'reference',
        label: 'Internal product reference',
        type: 'Input',
      },
      // {
      //   render: () => (
      //     <div key="age-title">
      //       <h3>Age</h3>
      //       <p>
      //         Set a date range for the age of the product at time of delivery.
      //       </p>
      //     </div>
      //   ),
      // },
      {
        name: 'agelogic',
        label: 'Age logic',
      },
      {
        render: () => (
          <div key="availability-title">
            <h3 className="m-0">Availability</h3>
          </div>
        ),
      },
      {
        name: 'marketplace',
        label: 'Marketplace',
      },
      {
        name: 'tender',
        label: 'Tenders',
      },
      { render: () => <hr className="mb-10 mt-0" /> },
      {
        name: 'logo',
        label: 'Product logo',
        render() {
          if (!R.prop('logo', schema)) return null;
          return (
            <Form.Item
              name="logo"
              label="Product logo"
              className="upload-media"
            >
              <FileField
                value={logo}
                onChange={setLogo}
                accept={R.pathOr([], ['logo', 'accepted'], schema).join(', ')}
                initialValue={product?.logo}
              />
            </Form.Item>
          );
        },
        serializeField() {
          if (!R.prop('logo', schema) || logo?.id) return;
          if (logo) return { logo };
          if (product?.logo?.id) return { logo: 'delete' };
        },
      },
      { render: () => <hr className="mt-0" /> },
      {
        render: () => (
          <div key="units-title">
            <h3>Units</h3>
            <p>
              The <em>base sell unit</em> is the unit in which the product will
              be sold. The <em>loading unit</em> is the unit in which the
              product will be loaded.
            </p>
          </div>
        ),
      },
      {
        name: 'subcurrency',
        label: 'Sold in',
        widget: SelectWidget,
        choices: subcurrencyChoices,
      },
      {
        name: 'baseunit',
        label: 'Base sell unit',
        choices: getChoices(R.pathOr([], ['baseunit', 'choices'], schema)),
      },
      {
        name: 'pricestep',
        label: 'Price increment',
        initialValue: 5,
        step: 0.0001,
      },
      {
        name: 'price',
        label: 'Min sell price',
        step: 0.0001,
      },
      {
        name: 'loadingunit',
        label: 'Loading unit',
        choices: getChoices(R.pathOr([], ['loadingunit', 'choices'], schema)),
      },
      {
        name: 'tags',
        label: 'Tags',
        choices: getChoices(R.pathOr([], ['tags', 'choices'], schema)),
      },
      {
        render: () => (
          <div key="sellunit-title">
            <hr />
            <div className="sell-unit-block">
              <Checkbox
                checked={extra}
                onClick={() => setExtra(state => !state)}
              >
                &nbsp;
              </Checkbox>
              <h3>Sell unit details</h3>
              <p>
                This is used when the name itself indicates the amount of fat in
                the product. The fat content for these products is used in price
                calculation.
              </p>
            </div>
          </div>
        ),
      },
    ];

    if (extra) {
      fields.push({
        name: 'unit',
        label: 'Sell unit name',
        type: 'Input',
        required: id ? R.path(['unitfactor', 'value'], schema) !== 1 : true,
      });
      fields.push({
        name: 'unitfactor',
        label: 'Ratio to whole product',
        initialValue: 1,
        step: 0.01,
        required: true,
      });
    }

    fields = fields.map(item => {
      if (!item.name || !schema[item.name]) return item;
      const s = schema[item.name];
      return {
        ...item,
        initialValue: s.value !== undefined ? s.value : item.initialValue,
        props: {
          ...item.props,
          disabled: !!s.disabled,
        },
      };
    });

    return new FormWrapper(form, {}, mergeSchema(schema, fields));
  };

  const formWrapper = getFormWrapper();
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }
    submitState.setLoading(true);
    const serialized = formWrapper.serialize();

    const fields = serialized;
    const data = new FormData();

    for (const [name, value] of Object.entries(fields)) {
      if (value !== undefined) {
        if (Array.isArray(value)) for (const v of value) data.append(name, v);
        else data.append(name, value);
      }
    }

    if (!extra) {
      data.append('unit', '');
      data.append('unitfactor', 1);
    }
    data.append('solution', solution.id);

    try {
      setSaved(false);
      const result = await api.getData(
        { type: 'products', id: id || '', getResult: x => x },
        null,
        { method: id ? 'put' : 'post', data }
      );
      setProduct(result.product);
      submitState.setResult();
      window.scrollTo(0, 0);
      setSaved(true);
      submitState.setLoading(false);
      if (!id)
        history.push(`/products/${result.product.id}/edit/specifications`);
    } catch (err) {
      console.error(err);
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        err
      );
      if (errors) {
        formWrapper.setErrors(errors);
      } else {
        submitState.setResult(err);
      }
      submitState.setLoading(false);
    }
  }

  return (
    <>
      {saved && (
        <Alert hasicon type="success" className="mb-20">
          <h3>Success</h3>
          Product details have been saved.
        </Alert>
      )}

      <Form onSubmit={handleSubmit}>
        {formWrapper.render()}
        <div className="button-set sticky-btm">
          <Button
            htmlType="submit"
            type="primary"
            loading={submitState.loading}
          >
            {id ? 'Update' : 'Save and continue'}
          </Button>
        </div>
      </Form>
    </>
  );
};

const WrappedForm = Form.create()(PForm);

export default () => {
  const { id } = useParams();
  const [product, _] = useSharedProduct();
  const solution = useStoreState(state => state.auth.solution);

  const state = useAsync(() => {
    return api.getData(
      { type: 'products', id: id ? `${id}/schema` : 'schema' },
      { solution: solution.id }
    );
  }, [solution]);

  return (
    <>
      <Helmet>
        <title>
          {product ? `Edit product ${product.name} - Details` : 'New product'}
        </title>
      </Helmet>
      <ShowResult renderError={submitError}>
        {submitState => (
          <div className="wrapper">
            <div className="nui-form order-form">
              <LoadingState state={state}>
                {schema => (
                  <WrappedForm
                    key="product-details"
                    product={product}
                    schema={schema}
                    submitState={submitState}
                  />
                )}
              </LoadingState>
            </div>
          </div>
        )}
      </ShowResult>
    </>
  );
};
