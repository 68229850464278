import { Order } from '~/store/models/orders';
import { useFilters } from './context';
import moment from 'moment';
import { useStoreState } from 'easy-peasy';

const filterOrders = (orders: any) => {
  const { filters } = useFilters();
  const { tagProducts } = useStoreState(state => state.market);

  if (!orders) return null;

  let filteredOrders = [...orders];

  if (filters?.product?.items?.length > 0) {
    filteredOrders = filteredOrders?.filter((x: Order) =>
      x.product?.id ? filters.product.items.includes(x.product.id) : false
    );
  }

  if (filters?.type?.items?.length > 0) {
    filteredOrders = filteredOrders?.filter((order: Order) => {
      for (const item of filters?.type?.items ?? []) {
        switch (item) {
          case 'Offer_SFC':
            if (order.type === 'offer' && order.counteronly === true) {
              return true;
            }
            continue;
          case 'Offer_Tradeable':
            if (order.type === 'offer' && order.counteronly !== true) {
              return true;
            }
            continue;
          case 'Bid_SFC':
            if (order.type !== 'offer' && order.counteronly === true) {
              return true;
            }
            continue;
          case 'Bid_Tradeable':
            if (order.type !== 'offer' && order.counteronly !== true) {
              return true;
            }
            continue;
        }
      }
    });
  }

  if (filters?.group?.items?.length > 0) {
    filteredOrders = filteredOrders?.filter((order: Order) => {
      for (const item of filters?.group?.items ?? []) {
        if (item && tagProducts[item].includes(order.product.id)) {
          return true;
        }
      }
    });
  }

  if (filters?.etdOption?.items?.length > 0) {
    const etdValue = filters?.etdOption?.items?.[0];

    if (etdValue === 'thisWeek') {
      filteredOrders = filteredOrders?.filter(
        (x: any) =>
          moment(x.etd.from) >= moment().startOf('week') &&
          moment(x.etd.from) <= moment().endOf('week')
      );
    } else if (etdValue === 'thisMonth') {
      filteredOrders = filteredOrders?.filter(
        (x: any) =>
          moment(x.etd.from) >= moment().startOf('month') &&
          moment(x.etd.from) <= moment().endOf('month')
      );
    } else if (etdValue === 'nextMonth') {
      filteredOrders = filteredOrders?.filter(
        (x: any) =>
          moment(x.etd.from) >= moment().startOf('month').add(1, 'month') &&
          moment(x.etd.from) <= moment().endOf('month').add(1, 'month')
      );
    } else if (etdValue === 'afterNextMonth') {
      filteredOrders = filteredOrders?.filter(
        (x: any) =>
          moment(x.etd.from) >= moment().startOf('month').add(2, 'month')
      );
    } else if (etdValue === 'custom' && filters?.etd?.items?.length) {
      const etdFrom = filters?.etd?.items[0]
        ? moment(filters?.etd?.items[0])?.startOf('day')
        : undefined;
      const etdTo = filters?.etd?.items[1]
        ? moment(filters?.etd?.items[1])?.startOf('day')
        : undefined;
      if (etdFrom && etdTo) {
        filteredOrders = filteredOrders?.filter(
          (x: any) => moment(x.etd.from) <= etdTo && moment(x.etd.to) >= etdFrom
        );
      }
    }
  }

  if (filters?.updatedOption?.items?.length > 0) {
    const updatedValue = filters?.updatedOption?.items?.[0];

    if (updatedValue === 'today') {
      filteredOrders = filteredOrders?.filter(
        (x: any) => moment(x.updated) >= moment().startOf('day')
      );
    } else if (updatedValue === 'last24h') {
      filteredOrders = filteredOrders?.filter(
        (x: any) =>
          moment(x.updated) >= moment().subtract(1, 'days').startOf('day')
      );
    } else if (updatedValue === 'last7d') {
      filteredOrders = filteredOrders?.filter(
        (x: any) =>
          moment(x.updated) >= moment().subtract(7, 'days').startOf('day')
      );
    } else if (updatedValue === 'lastMonth') {
      filteredOrders = filteredOrders?.filter(
        (x: any) =>
          moment(x.updated) >= moment().subtract(30, 'days').startOf('day')
      );
    } else if (updatedValue === 'custom' && filters?.updated?.items?.length) {
      const updatedFrom = filters?.updated?.items[0]
        ? moment(filters?.updated?.items[0])?.startOf('day')
        : undefined;
      const updatedTo = filters?.updated?.items[1]
        ? moment(filters?.updated?.items[1])?.startOf('day')
        : undefined;
      if (updatedFrom && updatedTo) {
        filteredOrders = filteredOrders?.filter(
          (x: any) =>
            moment(x.updated) <= updatedTo && moment(x.updated) >= updatedFrom
        );
      }
    }
  }

  if (filters?.state?.items?.length > 0) {
    filteredOrders = filteredOrders?.filter(
      (order: Order & { state: string }) => {
        for (const item of filters?.state?.items ?? []) {
          switch (item) {
            case 'tradeable':
              if (order.tradeable) {
                return true;
              }
              continue;
            case 'untradeable':
              if (!order.tradeable && !order.is_owner) {
                return true;
              }
              continue;
            case 'working':
              if (!order.tradeable && order.is_owner) {
                return true;
              }
              continue;
            case 'viewOnly':
              if (order.state === 'viewonly') {
                return true;
              }
              continue;
            case 'sfcTradeable':
              if (order.state === 'sfc-tradeable') {
                return true;
              }
              continue;
          }
        }
      }
    );
  }

  if (filters?.origin?.items.length) {
    filteredOrders = filteredOrders.filter((x: Order) =>
      x.origins?.find(origin => filters?.origin?.items.includes(origin.id))
    );
  }

  return filteredOrders;
};
export default filterOrders;
