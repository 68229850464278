import Button from 'Components/nui/Button';
import { FormattedMessage } from 'react-intl';
import FilterDropdown from './FilterDropdown';
import { FilterTypes, useFilters } from './context';
import FilterChips from './FilterChips';
import { useAllUsers } from '../../pages/Users/Context';
import type { Company } from '../../pages/Users/View/Table';
import * as R from 'ramda';

const UserFilters = ({ clearAction }: { clearAction?: () => void }) => {
  const { setFilters } = useFilters();

  const [users] = useAllUsers();
  const companies =
    users?.length &&
    R.uniq(R.map(R.prop('company'), users)).map((company: Company) => ({
      value: company?.id,
      label: company?.name,
    }));

  return users?.length > 0 && companies?.length > 1 ? (
    <div className="filter-row">
      <FilterChips>
        <FilterDropdown
          id="company"
          label={
            <FormattedMessage
              id="user-filter-field-company"
              description="label for 'Company' in User filter"
              defaultMessage="Company"
            />
          }
          type={FilterTypes.CHECKBOXES}
          options={companies}
        />

        <Button
          type="buttonlink"
          className="filter-reset"
          onClick={() => {
            clearAction && clearAction();
            setFilters({});
          }}
        >
          <FormattedMessage
            id="filter-button-clear-filter"
            description="Button for clear search filters"
            defaultMessage="Clear all filters"
          />
        </Button>
      </FilterChips>
    </div>
  ) : null;
};

export default UserFilters;
