const filterProducts = (data: any, filters: any) => {
  if (!data) return null;

  let filteredData = [...data];

  if (filters?.division?.items?.length > 0) {
    filteredData = filteredData?.filter((x: any) =>
      filters.division.items.includes(x.division.id)
    );
  }

  return filteredData;
};
export default filterProducts;
