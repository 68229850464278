import { OrderData } from '../modelTypes';
import Order from './Order';
import { ModelApi } from '../ModelApi';
import * as R from 'ramda';
import { useStoreActions, useStoreState } from '~/store';
import { useModel } from '../useModel';
import { OrderStatus } from '~/const';
import { useEffect } from 'react';
import moment, { Moment } from 'moment-timezone';
import { Filters } from '../types';
import { arrayFilter } from '../utils';

export { Order };

export class OrderApi extends ModelApi {
  constructor() {
    const url = '/orders';
    super({ url });
  }
}

interface Config {
  tag?: string;
  filterConfig?: {
    serialize: (f: Filters) => Filters;
    deserialize: (f: { [name: string]: string[] }) => Filters;
    initial: Filters;
  };
}
export function useOrders({ tag = '', filterConfig }: Config = {}) {
  const getResult = R.pathOr<OrderData[]>([], ['data', 'orders']);
  const api = new OrderApi();

  const actions = useStoreActions(state => state.models.orders);
  const state = useStoreState(state => state.models.orders);

  const orders = useModel({
    defaultTag: tag,
    getResult,
    actions,
    state,
    api,
    filterConfig,
  });

  return {
    ...orders,

    fetch() {
      return orders.fetch().map(o => new Order(o));
    },
  };
}

export function useOTable() {
  const solution = useStoreState(state => state.auth.solution);
  const products = useStoreState(
    R.pathOr<any[]>([], ['auth', 'commodityFilteredProducts'])
  );

  const orders = useOrders({
    tag: `otable-${solution.id}`,
    filterConfig: {
      initial: {
        product: products.map(R.prop('id')),
        status: OrderStatus,
        issell: [0, 1],
        created: [],
        etd: [],
      },
      serialize: filters => {
        const product = arrayFilter('product', products, filters);
        const status = arrayFilter('status', OrderStatus, filters);
        const issell = arrayFilter('issell', [0, 1], filters);

        const fmt = 'YYYY-MM-DD';
        const isDt = (dt: any[]): dt is [Moment, Moment] =>
          dt.length === 2 && moment.isMoment(dt[0]) && moment.isMoment(dt[1]);

        let etd;
        if (filters.etd && isDt(filters.etd)) {
          etd = filters.etd.map(d => d.clone().startOf('day').format(fmt));
        }

        let created;
        if (filters.created && isDt(filters.created)) {
          const [start, end] = filters.created.map((d, i) =>
            d.clone().add(i, 'days').startOf('day').format(fmt)
          );
          created = `gte:${start},lte:${end}`;
        }

        return { ...filters, product, status, issell, etd, created };
      },
      deserialize: filters => {
        const included = Object.keys(filters);

        const issell = R.includes('issell', included)
          ? [parseInt(filters.issell[0])]
          : [0, 1];

        const created = R.includes('created', included)
          ? R.pipe(
              R.split(','),
              R.zip(['gte:', 'lte:']),
              R.map(([op, d]) => moment(R.replace(op, '', d)))
            )(filters.created[0])
          : [];

        const etd = R.includes('etd', included)
          ? filters.etd.map(d => moment(d))
          : [];

        return { ...filters, issell, created, etd };
      },
    },
  });

  // Initial setup
  useEffect(() => {
    orders.pagination.changeLimit({ value: 10 });
    orders.pagination.changePage({ value: 1 });
  }, []);

  // Run updates
  useEffect(() => {
    orders.update({
      config: {
        params: { sort: '-created', solution: solution.id },
      },
    });
  }, orders.shouldUpdate());

  return {
    ...orders,
    async update() {
      await orders.update({
        config: { params: { sort: '-created', solution: solution.id } },
      });
    },
  };
}
