import React, { useContext } from 'react';
import YesNo from 'Components/YesNo';
import { Alert, Button, Tooltip } from 'Components/nui';
import { useSharedProduct } from '.';
import { ApiContext } from '~/context';
import { specConst, capitalize } from '~/utils';
import classnames from 'classnames';
import * as R from 'ramda';

export default () => {
  const [product] = useSharedProduct();
  const api = useContext(ApiContext);

  return (
    <>
      <h3 className="mb-17">Summary</h3>

      {product ? (
        <>
          <dl className="summary-list">
            <dt>ID</dt>
            <dd>{product.pid}</dd>

            <dt>Name</dt>
            <dd>{product.name}</dd>

            <dt>Short name</dt>
            <dd>{product.shortname || 'n/a'}</dd>

            <dt>Reference</dt>
            <dd>{product.reference || 'n/a'}</dd>

            <dt>Availability</dt>
            {product.marketplace && <dd>Marketplace</dd>}
            {product.tender && <dd>Tenders</dd>}
            {!product.tender && !product.marketplace && <dd>n/a</dd>}

            <dt>Price unit</dt>
            <dd>{product._desc}</dd>

            <dt>Loading unit</dt>
            <dd>{product.loadingunit.desc}</dd>

            <dt>Price increment</dt>
            <dd>
              {product.step} {product._desc}
            </dd>
            {product?.logo?.id && (
              <>
                <dt>Product logo</dt>
                <dd>
                  <Tooltip img={product.logo} />
                </dd>
              </>
            )}
            {product?.description && (
              <>
                <dt>Description</dt>
                <dd>{product.description}</dd>
              </>
            )}
          </dl>

          {/* Specifications */}
          {product.specifications && product.specifications.length > 0 && (
            <dl className="summary-list stacked">
              <dt>Specifications</dt>
              {product.specifications.map(item => (
                <dd className="spec" title={item.filename} key={item.id}>
                  <em
                    className={classnames(
                      'legend',
                      R.pathOr('', [item.compliance.id, 'className'], specConst)
                    )}
                    title={R.pathOr(
                      '',
                      [item.compliance.id, 'name'],
                      specConst
                    )}
                  >
                    {R.pathOr('?', [item.compliance.id, 'symbol'], specConst)}
                  </em>{' '}
                  <Button
                    type="buttonlink"
                    onClick={() => api.getPdf(item.href, item.filename)}
                  >
                    <span title={item.filename}>{item.filename}</span>
                  </Button>
                </dd>
              ))}
            </dl>
          )}

          {/* Loading details */}
          {product.loading && product.loading.length > 0 && (
            <dl className="summary-list stacked numbered">
              <dt>Loading options</dt>
              {product.loading.map(item => (
                <dd key={item.id}>{item.desc}</dd>
              ))}
            </dl>
          )}

          {/* product attributes */}
          {product.attributes && (
            <dl className="summary-list stacked ">
              <dt>Product attributes</dt>
              {Object.keys(product.attributes).map(k => {
                return (
                  <dd key={k.id}>
                    <dl className="summary-list stacked numbered">
                      <dt>{capitalize(k)}</dt>{' '}
                      {R.prop(k, product.attributes).map(item => (
                        <dd key={item.id}>{item.name}</dd>
                      ))}
                    </dl>
                  </dd>
                );
              })}
            </dl>
          )}

          {/* Warehouses */}
          {product.warehouses && product.warehouses.length > 0 && (
            <dl className="summary-list stacked numbered">
              <dt>Locations</dt>
              {product.warehouses.map(item => (
                <dd key={item.id}>{item.desc}</dd>
              ))}
            </dl>
          )}

          {/* select customers */}
          {product?.customers?.length > 0 && (
            <dl className="summary-list stacked numbered">
              <dt>Customers</dt>
              {product.customers.map(item => (
                <dd key={item.id}>{item.name}</dd>
              ))}
            </dl>
          )}
        </>
      ) : (
        <Alert hasicon>
          <p className="smaller">
            A progress summary of the product you are adding will appear here.
            You can save and exit at anytime, and come back to complete the
            process.
          </p>
        </Alert>
      )}
    </>
  );
};
