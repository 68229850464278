import { WarehouseData as WhData } from '../modelTypes';
import Warehouse from './Warehouse';
import { useStoreState } from '~/store';
import { allowRolesIf } from 'Components/Authorised';

export { Warehouse };
export type WarehouseData = WhData;

export function useWarehouses() {
  const solutionSettings = useStoreState(state => state.auth.solutionSettings);
  const { managementaccess, matchinglevel } = solutionSettings;
  const hasWarehouses = () => managementaccess && matchinglevel === 'product';

  return {
    _acl: {
      view: allowRolesIf('guest', 'trader', 'manager')(hasWarehouses),
      add: allowRolesIf('manager')(hasWarehouses),
      edit: allowRolesIf('manager')(hasWarehouses),
      delete: allowRolesIf('manager')(hasWarehouses),
    },
  };
}
