import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import * as React from 'react';
import Helmet from 'Components/Helmet';
import { Link, useRouteMatch } from 'react-router-dom';
import { useScrollToTopOnMount } from '~/hooks';
import { usePartners } from '~/store/models';
import Edit from './Edit';
import Table from './Table';
import * as R from 'ramda';

interface Props {
  children?: React.ReactNode;
}

interface IHeader extends Props {
  edit: boolean;
}
const Header = ({ edit, children }: IHeader) => (
  <>
    <Helmet>
      <title>Partners</title>
    </Helmet>
    <div className="full-page-header">
      <h1 className="inline mt-10 mr-20 mb-0">
        {edit ? 'Add partner' : 'Partners'}
      </h1>

      {children}

      <ul className="nui-bc right pt-20">
        <li>
          <BreadcrumbRoot active="partners" />
        </li>
        {edit ? (
          <>
            <li>
              <Link to="/partners">Partners</Link>
            </li>
            <li>Add</li>
          </>
        ) : (
          <li>Partners</li>
        )}
      </ul>

      {!edit && (
        <>
          <hr className="mb-10 mt-10" />
          <div className="nav-add-item">
            <Link to="/partners/add" className="button secondary">
              Add partner
            </Link>
          </div>
        </>
      )}
    </div>
  </>
);

export default () => {
  useScrollToTopOnMount();

  const { params } =
    useRouteMatch<{ action: string }>('/partners/:action') || {};

  const partners = usePartners();

  const edit = R.contains(params?.action, ['add', 'edit']);

  return (
    <>
      <Header edit={edit} />

      {(edit && <Edit />) || (
        <div className="full-page-content">
          <Table partners={partners} />
        </div>
      )}
    </>
  );
};
