import * as R from 'ramda';
import { Route, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { getEnv } from '~/utils';
import { api } from '~/api';
import { useStoreActions } from 'easy-peasy';
import LoadingSite from 'Components/LoadingSite';

export function PrivateRoute({ component: Component, user, ...rest }) {
  const checkAuth = useStoreActions(actions => actions.auth.checkAuth);
  const history = useHistory();
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('token');
  const clientId = getEnv(import.meta.env.VITE_APP_WEB_CLIENT_ID);
  const urlSolution = urlParams.get('solution_id');

  async function tokenAuth() {
    try {
      const response = await api.authenticate(token, clientId);
      const { token_type, user_id, access_token, expires_in } = response;
      checkAuth({
        token: {
          user_id,
          access_token,
          token_type,
          expires_in,
        },
        target: R.pathOr({}, ['target'], response),
        redirect: false,
      });
      history.replace({
        pathname: location.pathname,
        search: null,
      });
    } catch (error) {
      history.replace('/signin');
    }
  }

  useEffect(() => {
    if (!user) {
      if (token) {
        tokenAuth();
      } else {
        checkAuth({ redirect: false, urlSolution });
      }
    }
  }, [user]);

  return (
    <Route
      {...rest}
      render={props => (user ? <Component {...props} /> : <LoadingSite />)}
    />
  );
}
