import type * as Market from 'models.market';
import type { FormComponentProps } from 'antd/lib/form';

import React, { useState } from 'react';
import { Button, Form, Alert, Loading } from 'Components/nui';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormWrapper } from 'Components/form';
import {
  MAX_SAFE_INTEGER,
  mergeSchema,
  normalizeChoices,
  strBoolIsTruthy,
} from 'Components/form/utils';
import * as R from 'ramda';
import FilesField from 'Components/form/FilesField';
import moment from 'moment-timezone';
import LocationAlerts from '../FokCounter/LocationAlerts';
import { useSchema, useClients, update } from './actions';
import { useStoreActions, useStoreState } from '~/store';
import { promisify } from 'es6-promisify';
import { toast } from 'react-toastify';
import { unitsOfMeasure } from '~/utils/const';
import FNumber from 'Components/FNumber';
import { Product } from '~/models';
import {
  getDecimalCount,
  inArrayIf,
  getColumnFieldLabel,
  invertOrderType,
  SolutionColumns,
} from '~/utils';

interface IConfig {
  counter?: Market.Counter;
  order: Market.Order;
  children: (onEdit: () => void) => React.ReactElement;
}
export default ({ order, counter, children }: IConfig) => {
  const [editing, setEditing] = useState(false);

  if (!editing) return children(() => void setEditing(true));

  return (
    <div className="nui-form order-form counter-form">
      <CounterForm
        order={order}
        counter={counter}
        onExit={() => void setEditing(false)}
      />
    </div>
  );
};

interface ICounterForm {
  order: Market.Order;
  counter?: Market.Counter;
  onExit: () => void;
}
const CounterForm = ({ order, counter, onExit }: ICounterForm) => {
  const state = useSchema(order, counter);

  if (state.loading) return <Loading />;
  if (state.error)
    return (
      <Alert hasicon type="error">
        {state.error}
      </Alert>
    );

  return (
    <WrappedForm
      schema={state.schema!}
      order={order}
      counter={counter}
      onExit={onExit}
    />
  );
};

interface IFormProps extends FormComponentProps {
  schema: Record<string, any>;
  order: Market.Order;
  counter?: Market.Counter;
  onExit: () => void;
}
function useFormWrapper({ form, schema, order, counter }: IFormProps) {
  const solution = useStoreState(state => state.auth.solution);
  const solutionColumns = useStoreState(state => state.auth.solutionColumns);
  const exportIntent = strBoolIsTruthy(form, schema, 'exportable');

  const exportdocsRequired = strBoolIsTruthy(form, schema, 'exportdocs');

  const heatTreatedPalletsRequired = strBoolIsTruthy(
    form,
    schema,
    'heattreatedpallets'
  );

  const totaldeliveries: number = R.propOr(1, 'totaldeliveries', order);

  const volume = form.getFieldValue('volume') ?? 0;

  const totalVolume = volume * totaldeliveries;

  const { formatMessage } = useIntl();
  const [files, setFiles] = useState([]);

  const product = useStoreState(
    state =>
      new Product(state.auth.products.find(p => p.id === order.product.id))
  );

  const divisions = useStoreState(state => state.auth.solutionDivisionIds);
  const initOwner = R.includes(counter?.division?.id, divisions)
    ? ''
    : counter?.division?.id;

  const { realparty = initOwner } = form.getFieldsValue();

  const clients = useClients(realparty);

  const getChoicesByDivision =
    ({ choices }: Record<'choices', [string, string][]>) =>
    () => {
      const divisionId = form.getFieldValue('division');
      return normalizeChoices(
        Array.isArray(choices) ? choices : R.propOr([], divisionId, choices)
      );
    };

  const timeInterval = 15; // minutes
  const fromDt = moment()
    .startOf('hour')
    .minute(Math.ceil(moment().minute() / timeInterval) * timeInterval);

  const index = order.index;
  const anonymousOrder = useStoreState(
    state => state.auth.solutionColumns?.anonymous?.value
  );

  const volumeUnit = R.propOr(
    order.volume.unit,
    order.volume.unit,
    unitsOfMeasure
  );

  const fields: any[] = [
    ...(index
      ? [
          index.type === 'Multiplier'
            ? {
                name: 'price',
                label: <>Multiplier</>,
                step: index.step,
                initialValue: 1,
                min: index.step,
                max: MAX_SAFE_INTEGER,
              }
            : {
                name: 'price',
                label: <>Modifier</>,
                step: index.step,
                initialValue: 0,
                min: -9999999,
              },
          {
            render: () => {
              const { price } = form.getFieldsValue(['price']);

              const date = moment(order.indexdate).clone().format('LL');

              if (index.type === 'Multiplier')
                return (
                  <div key="value-mult" className="p-0 pb-10 totals">
                    <div className="inline-block">
                      <span className="label w-auto pr-10 inline-block nowrap">
                        <strong className="all-black">Price:</strong>{' '}
                        {index.category} {date} &times;{' '}
                        <FNumber
                          value={price}
                          decimalCount={getDecimalCount(index.step)}
                        />{' '}
                      </span>
                    </div>
                  </div>
                );

              const operator = price >= 0 ? <>&#43;</> : <>&minus;</>;

              return (
                <div key="value-mod" className="p-0 pb-10 totals">
                  <div className="inline-block">
                    <span className="label w-auto pr-10 inline-block nowrap">
                      <strong className="all-black">Price:</strong>{' '}
                      {index.category} {date} {operator}{' '}
                      <FNumber
                        value={Math.abs(price)}
                        decimalCount={getDecimalCount(index.step)}
                      />{' '}
                      {order.price.unit}
                    </span>
                  </div>
                </div>
              );
            },
          },
          {
            render: () => <hr key="no-more-warnings-2" className="mt-5" />,
          },
        ]
      : [
          {
            name: 'price',
            label: (
              <>
                <FormattedMessage
                  id="marketrow-tradeform-field-price-label"
                  description="Label for `Price` field on tradeform in Marketrow"
                  defaultMessage="Price"
                />{' '}
                <span className="unit block mt--4">
                  {R.path(['price', 'unit'], order)}
                </span>
              </>
            ),
            step: order.price.step,
            min: R.has('openprice', schema) ? 0 : order.price.step,
          },
        ]),
    {
      name: 'volume',
      label: (
        <>
          Volume {!!order.totaldeliveries && 'per delivery'}
          <span className="unit block mt--4">
            {R.propOr(order.volume.unit, order.volume.unit, unitsOfMeasure)}
          </span>
        </>
      ),
      step: order.loading.qty,
      min: () =>
        order.splittable ? order.loading.qty : order.volume.available,
      max: order.volume.delivery ?? order.volume.available,
      props: () => ({ disabled: !order.splittable }),
    },
    {
      render: () =>
        !index && (
          <div key="value" className="p-0 pb-10 totals">
            <div className="inline-block">
              <span className="label w-auto pr-10 block nowrap">
                <strong className="all-black">
                  <FormattedMessage
                    id="orderform-field-totalvalue-label"
                    description="Label for `Total value` field on new order form"
                    defaultMessage="Total value:"
                  />
                </strong>
                <FNumber
                  value={product._getValue(
                    form.getFieldValue('price') || 0,
                    (form.getFieldValue('volume') || 0) *
                      (order.totaldeliveries || 1),
                    order.loading.id
                  )}
                  decimalCount={2}
                />{' '}
                {order.price.currency}
              </span>
              {order.totaldeliveries && (
                <span className="label w-auto pr-10 block nowrap">
                  <strong className="all-black">
                    <FormattedMessage
                      id="orderform-field-totalvolume-label"
                      description="Label for `Total volume` field on new order form"
                      defaultMessage="Total volume:"
                    />
                  </strong>
                  <FNumber
                    value={form.getFieldValue('volume') * order.totaldeliveries}
                    decimalCount={getDecimalCount(order.price.step)}
                  />{' '}
                  {volumeUnit}
                </span>
              )}
            </div>
          </div>
        ),
    },
    {
      name: 'anonymous',
      label: 'Hide my details on counter',
      choices: [
        {
          label: 'Yes',
          value: 'true',
          key: 'yes',
        },
        { label: 'No', value: 'false', key: 'no' },
      ],
      initialValue: anonymousOrder,
      type: 'Boolean',
      required: true,
    },
    {
      name: 'expiry',
      label: 'Expires at',
      fromDate: fromDt.clone(),
      props: {
        getCalendarContainer: null,
        showTime: {
          format: 'h:mm a',
          minuteStep: timeInterval,
          hideDisabledOptions: true,
        },
      },
      initialData: R.pathOr(
        fromDt.clone().add(2, 'hours'),
        ['expiry', 'value'],
        schema
      ),
      initialValue: R.pathOr(
        fromDt.clone().add(2, 'hours'),
        ['expiry', 'value'],
        schema
      ),
    },
    {
      name: 'division',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-division-label"
          description="Label for `Division` field on tradeform in Marketrow"
          defaultMessage="Division"
        />
      ),
      choices: normalizeChoices,
      props: {
        disabled: !!schema.division?.disabled,
      },
    },
    {
      name: 'realparty',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-realparty-label"
          description="Label for `Company (participant)` field on tradeform in Marketrow"
          defaultMessage="Company (participant)"
        />
      ),
      props: {
        disabled: clients.loading,
      },
      choices: normalizeChoices,
    },
  ];

  if (!clients.error) {
    fields.push({
      name: 'realpartyuser',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-user-label"
          description="Label for `User` field on tradeform in Marketrow"
          defaultMessage="User"
        />
      ),
      props: {
        disabled: clients.loading || !clients.choices.length,
      },
      choices: () => normalizeChoices(clients.choices),
    });
  } else {
    fields.push({
      render: () => (
        <Alert key="clients-error" hasicon type="error">
          There was an error while fetching this data.
        </Alert>
      ),
    });
  }

  fields.push(
    {
      name: 'files',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-attachments-label"
          description="Label for `Attachments` field on tradeform in Marketrow"
          defaultMessage="Attachments"
        />
      ),
      render: () => {
        if (!R.prop('files', schema)) return null;
        return (
          <FilesField
            key="form-files"
            accept={R.pathOr([], ['files', 'accepted'], schema).join(', ')}
            files={files}
            setFiles={setFiles}
          />
        );
      },
      serializeField: () => {
        if (!R.prop('files', schema)) return null;
        return files.length ? { files } : undefined;
      },
    },
    {
      name: 'attachments',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-existingattachments-label"
          description="Label for `Existing attachments` field on tradeform in Marketrow"
          defaultMessage="Existing attachments"
        />
      ),
      choices: normalizeChoices,
      props: {
        placeholder: (
          <FormattedMessage
            id="marketrow-tradeform-field-existingattachments-placholder"
            description="Placeholder for `Existing attachments` field on tradeform in Marketrow"
            defaultMessage="Select attachments to keep"
          />
        ),
      },
      initialValue: R.pathOr([], ['attachments', 'value'], schema),
      initialData: R.pathOr([], ['attachments', 'value'], schema),
    },
    {
      name: 'fromaddr',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-deliveryfrom-label"
          description="Label for `Delivery from` field on tradeform in Marketrow"
          defaultMessage="Delivery from"
        />
      ),
      choices: getChoicesByDivision,
      props: {
        placeholder: (
          <FormattedMessage
            id="marketrow-tradeform-field-deliveryfrom-placholder"
            description="Placeholder for `Delivery from` field on tradeform in Marketrow"
            defaultMessage="Select location"
          />
        ),
      },
    },
    {
      name: 'toaddr',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-deliveryto-label"
          description="Label for `Delivery to` field on tradeform in Marketrow"
          defaultMessage="Delivery to"
        />
      ),
      choices: getChoicesByDivision,
      props: {
        placeholder: (
          <FormattedMessage
            id="marketrow-tradeform-field-deliveryfrom-placholder"
            description="Placeholder for `Delivery from` field on tradeform in Marketrow"
            defaultMessage="Select location"
          />
        ),
      },
    },
    {
      name: 'warehouse',
      label:
        order.type === 'bid' ? (
          <FormattedMessage
            id="marketrow-tradeform-field-deliveryfrom-label"
            description="Label for `Delivery from` field on tradeform in Marketrow"
            defaultMessage="Delivery from"
          />
        ) : (
          <FormattedMessage
            id="marketrow-tradeform-field-deliveryto-label"
            description="Label for `Delivery to` field on tradeform in Marketrow"
            defaultMessage="Delivery to"
          />
        ),
      choices: getChoicesByDivision,
      props: {
        placeholder: (
          <FormattedMessage
            id="marketrow-tradeform-field-deliveryfrom-placholder"
            description="Placeholder for `Delivery from` field on tradeform in Marketrow"
            defaultMessage="Select location"
          />
        ),
      },
    },
    {
      name: 'buyer',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-buyer-label"
          description="Label for `Buyer` field on tradeform in Marketrow"
          defaultMessage="Buyer"
        />
      ),
      choices: normalizeChoices,
    },
    {
      name: 'consignee',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-consignee-label"
          description="Label for `Consignee` field on tradeform in Marketrow"
          defaultMessage="Consignee"
        />
      ),
      choices: normalizeChoices,
    },
    {
      name: 'exportable',
      label: (
        <>
          {getColumnFieldLabel(
            invertOrderType(order.type),
            'exportable'
          )(solutionColumns as SolutionColumns) ?? 'Exportable'}
        </>
      ),
      initialValue: schema?.exportable?.value,
      choices: [
        {
          label: 'Yes',
          value: 'true',
          key: 'yes',
        },
        { label: 'No', value: 'false', key: 'no' },
      ],
      required: true,
    },

    ...inArrayIf(
      !('exportable' in schema) || (exportIntent && order.type === 'offer'),
      {
        name: 'exportdocs',
        label: (
          <>
            {getColumnFieldLabel(
              invertOrderType(order.type),
              'exportdocs'
            )(solutionColumns as SolutionColumns) ?? (
              <FormattedMessage
                id="marketrow-tradeform-field-exportdocs-label"
                description="Label for `Export docs` field on tradeform in Marketrow"
                defaultMessage="Export docs"
              />
            )}
            <span className="normal warning">
              (
              <FormattedMessage
                id="marketrow-tradeform-field-exportdocs-warning"
                description="warning for `Export docs` field on tradeform in Marketrow"
                defaultMessage="Fee applies"
              />
              )
            </span>
          </>
        ),
        initialValue: schema?.exportdocs?.value,
        required: true,
        choices: [
          {
            label: 'Yes',
            value: 'true',
            key: 'yes',
          },
          { label: 'Not required', value: 'false', key: 'no' },
        ],
      }
    ),
    {
      render: () =>
        exportIntent &&
        exportdocsRequired &&
        solution?.exportprice?.flatfee && (
          <Alert
            hasicon
            type="info"
            className="alert-fee"
            key="alert-exportfee"
          >
            Please note a{' '}
            <strong>
              {solution.exportprice.price} {solution.currency}
            </strong>{' '}
            flat fee will be added to this order.{' '}
            {solution.exportprice.file && (
              <a
                href={solution.exportprice.file.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                View more information here
              </a>
            )}
          </Alert>
        ),
    },

    ...inArrayIf(exportIntent, {
      name: 'heattreatedpallets',
      label: 'Request Heat-treated pallets',
      choices: [
        {
          label: 'Yes',
          value: 'true',
          key: 'yes',
        },
        { label: 'Not required', value: 'false', key: 'no' },
      ],
      initialValue: schema?.heattreatedpallets?.value,
      required: true,
    }),

    ...inArrayIf(
      exportIntent &&
        heatTreatedPalletsRequired &&
        totalVolume > 0 &&
        !!solution?.exportprice?.heattreatedfee,
      {
        render: () => (
          <Alert
            hasicon
            type="info"
            className="pallets-request"
            key="pallets-request"
          >
            <h3>Heat-treated pallets summary</h3>
            <div>
              <blockquote>
                <FNumber
                  value={parseFloat(solution!.exportprice!.heattreatedfee)}
                  decimalCount={getDecimalCount(
                    parseFloat(solution!.exportprice!.heattreatedfee)
                  )}
                  unit={solution.currency}
                />{' '}
                &times;{' '}
                <FNumber
                  value={totalVolume}
                  decimalCount={getDecimalCount(totalVolume)}
                />{' '}
                ={' '}
                <FNumber
                  value={
                    parseFloat(solution!.exportprice!.heattreatedfee) *
                    totalVolume
                  }
                  decimalCount={2}
                  unit={solution.currency}
                />
              </blockquote>
            </div>
          </Alert>
        ),
      }
    ),
    ...inArrayIf(exportIntent, {
      name: 'exportcountry',
      label: 'Destination',
      choices: normalizeChoices(schema?.exportcountry?.choices),
      initialValue: schema?.exportcountry?.value,
    }),
    {
      name: 'callofftime',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-callofftimes-label"
          description="Label for `Call off times` field on tradeform in Marketrow"
          defaultMessage="Call off times"
        />
      ),
      required: () => {
        if (!order.etd.from || !order.etd.to) return false;
        return moment(order.etd.from)
          .add(28, 'days')
          .isBefore(moment(order.etd.to));
      },
      props: {
        placeholder: formatMessage({
          id: 'marketrow-tradeform-field-callofftimes-placholder',
          description:
            'Placeholder for `Call off times` field on tradeform in Marketrow',
          defaultMessage: 'Ex: 1 load in week 20\n1 load in week 22',
        }),
      },
      initialValue: R.pathOr('', ['callofftime', 'value'], schema),
      initialData: R.pathOr('', ['callofftime', 'value'], schema),
    },
    {
      name: 'reference',
      label: (
        <>
          <FormattedMessage
            id="marketrow-tradeform-field-reference-label"
            description="Label for `Reference` field on tradeform in Marketrow"
            defaultMessage="Reference"
          />{' '}
          <span className="normal">
            <FormattedMessage
              id="marketrow-tradeform-field-reference-helptext"
              description="helptext for `Reference` field on tradeform in Marketrow"
              defaultMessage="This field is for internal use only, and will not be published."
            />
          </span>
        </>
      ),
    },
    {
      name: 'note',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-comment-label"
          description="Label for `Comment` field on tradeform in Marketrow"
          defaultMessage="Comment"
        />
      ),
    }
  );

  const schemaData: object = R.filter(
    R.o(R.not, R.isNil),
    R.map(R.prop('value'), schema)
  );
  const initialData = {
    volume: order.volume.available,
    price: order.price.val,
    ...schemaData,
  };

  const mergedSchema = mergeSchema(schema, fields);

  return new FormWrapper(form, initialData, mergeSchema(schema, fields));
}

const BaseForm = ({ form, order, counter, schema, onExit }: IFormProps) => {
  const [loading, setLoading] = useState(false);
  const [displayedPrice, setDisplayedPrice] = useState<number>();
  const formWrapper = useFormWrapper({ form, order, counter, schema, onExit });
  const mktUpdate = useStoreActions(state => state.market.update);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const validate = promisify(form.validateFieldsAndScroll);

    try {
      await validate();
    } catch (error) {
      setLoading(false);
      return;
    }

    const serialized = formWrapper.serialize();
    const { files, ...fields } = serialized;
    const data = new FormData();
    for (const [name, value] of Object.entries(fields)) {
      if (value !== undefined) {
        if (Array.isArray(value)) for (const v of value) data.append(name, v);
        else data.append(name, value as any);
      }
    }
    if (files) for (const file of files) data.append('files[]', file);

    const result = await update({
      order: order.id,
      counter: counter?.id,
      formdata: data,
    });

    if (result.success) {
      await mktUpdate();
      toast.success(
        <>Your counter was {counter?.id ? 'updated' : 'placed'} successfully.</>
      );
      onExit();
    }

    if (result.error) {
      toast.error(<>There was an unexpected error. Please try again later.</>);
      setLoading(false);
    }

    if (result.validate) {
      formWrapper.setErrors(result.validate);
      setLoading(false);
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <LocationAlerts
          form={form}
          order={order}
          price={displayedPrice}
          setPrice={setDisplayedPrice}
          schema={schema}
        />
        {formWrapper.render()}
        <Button
          key="submit"
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          <FormattedMessage
            id="marketrow-counters-button-counter"
            description="button for counters 'Counter' in Marketrow"
            defaultMessage="Counter"
          />
        </Button>
        <Button key="cancel" type="buttonlink" size="small" onClick={onExit}>
          <FormattedMessage
            id="marketrow-counters-button-cancel"
            description="button for counters 'Cancel' in Marketrow"
            defaultMessage="Cancel"
          />
        </Button>
      </Form>
    </div>
  );
};
const WrappedForm = Form.create<IFormProps>()(BaseForm);
