import React, { useEffect, useState } from 'react';
import * as api from '~/fetch';
import { mergeSchema, normalizeChoices } from 'Components/form/utils';
import { FormWrapper } from 'Components/form';
import { promisify } from 'es6-promisify';
import { Form, Button, Loading } from 'Components/nui';
import { useRoles, useUser } from '../Context';
import { toast } from 'react-toastify';
import { capitalize } from '~/utils';

type IWrappedForm = (props: any) => React.ReactElement;

function useFormWrapper({ form, schema, roleId }: any) {
  const fields = [
    {
      name: 'division',
      label: 'Division',
      type: 'Select',
      choices: normalizeChoices(schema.division.choices),
      props: () => ({
        disabled: !!roleId,
        placeholder: 'Select a division',
      }),
      initialValue: roleId && schema.division.value,
    },
    {
      name: 'role',
      label: 'Role',
      type: 'Select',
      choices: normalizeChoices(schema.role.choices),
      initialValue: roleId && schema.role.value,
      props: () => ({
        placeholder: 'Select a role',
      }),
    },
  ];

  return new FormWrapper(form, {}, mergeSchema(schema, fields));
}

function BaseForm({ form, schema, userId, role, setEditing }: any) {
  const { setRole } = useRoles(userId);
  const { setUser } = useUser(userId);
  const roleId = role?.id;

  const formWrapper = useFormWrapper({ form, schema, roleId });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }

    setLoading(true);

    const formData = {
      ...formWrapper.serialize(),
    };

    try {
      const response = await api.users.setUserRoles(userId, roleId, formData);
      await api.utils.processResponse(response, {
        async 200(response) {
          const data: any = await response.json();
          setRole(roleId, data.role);
          setUser(userId, data.user);
          setEditing(false);
        },
        async default(response) {
          toast.error(
            <>An unknown error occured, please refresh the page or try again</>
          );
        },
      });
    } catch (err) {
      formWrapper.setErrors(err);
      setLoading(false);
    }
  }

  return (
    <div className="nui-form pt-20">
      <span className="user-solution">
        {role?.division?.solution?.name
          ? capitalize(role.division.solution.name)
          : ''}
      </span>
      <Form onSubmit={handleSubmit}>
        {formWrapper.render()}

        <Form.Item>
          <div className="button-set">
            <Button
              type="primary"
              htmlType="submit"
              disabled={loading}
              className="mb-0"
            >
              Save
            </Button>
            <Button
              type="simple"
              onClick={() => setEditing(false)}
              disabled={loading}
              className="mb-0"
            >
              Cancel
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

const UserForm: IWrappedForm = Form.create()(BaseForm) as any;

export default ({ userId, role, setEditing }: any) => {
  const { setRole } = useRoles(userId);
  const { setUser } = useUser(userId);
  const [schema, setSchema] = useState({});
  const [schemaLoading, setSchemaLoading] = useState(false);

  const hasSchema = Object.keys(schema).length;

  useEffect(() => {
    let mounted = true;
    const loadUserEditSchema = async () => {
      try {
        setSchemaLoading(true);
        const response = await api.users.roleSchema(userId, role?.id);
        await api.utils.processResponse(response, {
          async 200(response) {
            //Todo: add types
            const data: any = await response.json();
            if (mounted) {
              setSchema(data.fields);
              setSchemaLoading(false);
            }
          },
          async default(response) {
            toast.error(
              <>
                An unknown error occured, please refresh the page or try again
              </>
            );
          },
        });
      } catch (error) {
        toast.error(
          <>An unknown error occured, please refresh the page or try again</>
        );
      }
    };
    loadUserEditSchema();
    return () => {
      mounted = false;
    };
  }, [userId, role?.id]);

  return hasSchema && !schemaLoading ? (
    <UserForm
      schema={schema}
      userId={userId}
      role={role}
      setEditing={setEditing}
      setRole={setRole}
      setUser={setUser}
    />
  ) : (
    <Loading />
  );
};
