import * as Models from '~/models/tenders';
import React, { useState } from 'react';
import { withRouteX, withPreload, usePreloadable } from '~/router';
import * as service from '~/services/tenders';
import { Loading, Alert, Input, Switch } from 'Components/nui';
import * as Data from 'Components/Data';
import { capitalize } from '~/utils';
import { SimpleTable, GenericTable } from 'Components/nui/Table';
import classnames from 'classnames';
import * as Charts from './Charts';
import { useToggle } from 'react-use';
import { Button } from 'Components/nui';
import * as api from '~/fetch';
import { FormattedMessage } from 'react-intl';

type PReport = Record<'report', Models.TenderReport>;
type PTender = Record<'tender', Models.Tender>;

export default withRouteX({ name: 'tender-report' })(() => <Page />);

const Page = withPreload({
  route: 'tender-report',
  preload: service.observe.tender(),
})(({ data: tender }) => {
  const {
    data: report,
    loading,
    errors,
  } = usePreloadable({
    route: 'tender-report',
    preload: service.analytics.tenderReport(),
  });

  if (errors.length)
    return (
      <div className="tender-report-content error">
        <Alert hasicon type="error">
          <FormattedMessage
            id="tender-stats-error"
            description="Error for tender stats page"
            defaultMessage="There was an error loading this data"
          />
        </Alert>
      </div>
    );

  if (loading)
    return (
      <div className="tender-report-content loading">
        <Loading />
      </div>
    );

  if (report)
    return (
      <div className="tender-report-content">
        <hr />
        <TenderInfo tender={tender} report={report} />
        <div className="flex-desktop gap-20">
          <ChartSection
            title={
              <FormattedMessage
                id="tender-stats-counter-price-chart-heading"
                description="Heading for counter price chart on tender stats page"
                defaultMessage="{orderName, select, bid{Bid} offer{Offer} other{{orderName}}} price"
                values={{ orderName: tender?.ordername.counter }}
              />
            }
            className="counter-price"
          >
            <Charts.CountersPrice tender={tender} charts={report.charts} />
          </ChartSection>
          <hr className="mt-30 mb-30 hide-for-xlarge" />
          <Participants tender={tender} report={report} />
        </div>
        <hr />
        <OrdersDetails tender={tender} report={report} />
      </div>
    );

  return <></>;
});

const TenderInfo = ({ tender, report }: PTender & PReport) => {
  const counterName = capitalize(tender.ordername.counter);
  return (
    <div className="chart-container table-chart-container tender-report-info">
      <h3>
        <FormattedMessage
          id="tender-stats-trade-details-heading"
          description="Heading for Tender trade details table on tender stats page"
          defaultMessage="Tender trade details"
        />
      </h3>
      <Data.List>
        <Data.Item
          title={
            <FormattedMessage
              id="tender-stats-trade-details-extension-counters"
              description="Field offers/bids during extension on Tender trade details table on tender stats page"
              defaultMessage="{counterName, select, Bid{Bids} Offer{Offers} other{{counterName}s}} during extension"
              values={{ counterName: counterName }}
            />
          }
          className="tender-report-info-extensions"
        >
          <Data.Number value={report.info.extensions} />
        </Data.Item>
        <Data.Item
          title={
            <FormattedMessage
              id="tender-stats-trade-details-viewer"
              description="Field Viewers on Tender trade details table on tender stats page"
              defaultMessage="Viewers"
            />
          }
          className="tender-report-info-viewers"
        >
          <Data.Number value={report.info.viewers} />
        </Data.Item>
        <Data.Item
          title={
            <FormattedMessage
              id="tender-stats-trade-details-counters"
              description="Field offers/bids on Tender trade details table on tender stats page"
              defaultMessage="{counterName, select, Bid{Bids} Offer{Offers} other{{counterName}s}}"
              values={{ counterName: counterName }}
            />
          }
          className="tender-report-info-counters"
        >
          <Data.Number value={report.info.counters} />
        </Data.Item>
        <Data.Item
          title={
            <FormattedMessage
              id="tender-stats-trade-details-trades"
              description="Field Trades on Tender trade details table on tender stats page"
              defaultMessage="Trades"
            />
          }
          className="tender-report-info-trades"
        >
          <Data.Number value={report.info.trades} />
        </Data.Item>
        <Data.Item
          title={
            <FormattedMessage
              id="tender-stats-trade-details-total-value"
              description="Field Total value on Tender trade details table on tender stats page"
              defaultMessage="Total value"
            />
          }
          className="tender-report-info-value"
        >
          <Data.PriceTicker title="" value={report.info.value} />
        </Data.Item>
        <Data.Item
          title={
            <FormattedMessage
              id="tender-stats-trade-details-extension-value"
              description="Field Extension value on Tender trade details table on tender stats page"
              defaultMessage="Extension value"
            />
          }
          className="tender-report-info-extvalue"
        >
          <Data.PriceTicker title="" value={report.info.extvalue} />
        </Data.Item>
      </Data.List>
    </div>
  );
};

const Participants = ({ tender, report }: PTender & PReport) => {
  const [viewers, setViewers] = useState(true);
  const [search, setSearch] = useState('');

  const column = ({
    className,
    index,
    ...data
  }: {
    name: string;
    title: React.ReactNode;
    className?: string;
    index?: keyof typeof report.participants[number];
    render?: (p: typeof report.participants[number]) => React.ReactNode;
  }) => ({
    ...data,
    key: `col-${data.name}`,
    dataIndex: index,
    className: classnames(`col-${data.name}`, className),
  });

  let processed = report.participants.slice(0);
  if (!viewers) processed = processed.filter(x => x.viewed);
  if (search.length)
    processed = processed.filter(x =>
      x.name.toLowerCase().includes(search.toLowerCase())
    );

  return (
    <div className="chart-container table-chart-container tender-report-participants">
      <h3>
        <FormattedMessage
          id="tender-stats-participants-heading"
          description="Heading for participants table on tender stats page"
          defaultMessage="Participants"
        />
      </h3>
      <ParticipantFilters
        search={{ state: search, setState: setSearch }}
        viewers={{ state: viewers, setState: setViewers }}
      />
      <ExportButton tenderId={tender.id} viewed={!viewers} />
      <div className="table-wrapper">
        <SimpleTable
          className="table"
          columns={[
            column({
              name: 'company',
              title: (
                <FormattedMessage
                  id="tender-stats-participants-column-company"
                  description="Heading for column in participants table on tender stats page"
                  defaultMessage="Company"
                />
              ),
              render: p => <span className="company-name">{p.name}</span>,
            }),
            column({
              name: 'viewed',
              title: (
                <FormattedMessage
                  id="tender-stats-participants-column-viewed"
                  description="Heading for column in participants table on tender stats page"
                  defaultMessage="Viewed"
                />
              ),
              render: p => <Data.Bool value={p.viewed} />,
            }),
            column({
              name: 'counters',
              title: (
                <FormattedMessage
                  id="tender-stats-participants-column-counters"
                  description="Heading for column in participants table on tender stats page"
                  defaultMessage="{counterName, select, bid{Bids} offer{Offers} other{{counterName}}}"
                  values={{ counterName: tender.ordername.counter }}
                />
              ),
              index: 'counters',
            }),
            column({
              name: 'trades',
              title: (
                <FormattedMessage
                  id="tender-stats-participants-column-trades"
                  description="Heading for column in participants table on tender stats page"
                  defaultMessage="Trades"
                />
              ),
              render: p => p.trades.count,
            }),
            column({
              name: 'value',
              title: (
                <FormattedMessage
                  id="tender-stats-participants-column-trades-value"
                  description="Heading for column in participants table on tender stats page"
                  defaultMessage="Trades' value"
                />
              ),
              render: p => <Data.PriceTicker title="" value={p.trades.value} />,
            }),
          ]}
          data={processed}
          rowKey="id"
        />
      </div>
    </div>
  );
};

const OrdersDetails = ({ tender, report }: PTender & PReport) => {
  const column = ({
    className,
    index,
    ...data
  }: {
    name: string;
    title: React.ReactNode;
    className?: string;
    index?: keyof typeof report.orders[number];
    render?: (o: typeof report.orders[number]) => React.ReactNode;
  }) => ({
    ...data,
    key: data.name,
    dataIndex: index,
    className: classnames(`col-${data.name}`, className),
  });

  return (
    <div className="full-page-content tender-report-orders">
      <h3>
        <FormattedMessage
          id="tender-stats-orders-heading"
          description="Heading Bids/Offers table on tender stats page"
          defaultMessage="{counterName, select, bid{Bids} offer{Offers} other{{counterName}}}"
          values={{ counterName: tender.ordername.order }}
        />
      </h3>
      <GenericTable
        columns={[
          column({
            name: 'product',
            title: (
              <FormattedMessage
                id="tender-stats-orders-column-product"
                description="Column for Bids/Offers table on tender stats page"
                defaultMessage="Product"
              />
            ),
            render: o => o.product.label,
            className: 'shaded-right',
          }),
          column({
            name: 'participants',
            title: (
              <FormattedMessage
                id="tender-stats-orders-column-participants"
                description="Column for Bids/Offers table on tender stats page"
                defaultMessage="Participants"
              />
            ),
            index: 'participants',
          }),
          column({
            name: 'counters',
            title: (
              <FormattedMessage
                id="tender-stats-orders-column-counters"
                description="Column for Bids/Offers table on tender stats page"
                defaultMessage="{counterName, select, bid{Bids} offer{Offers} other{{counterName}}}"
                values={{ counterName: tender.ordername.counter }}
              />
            ),
            index: 'counters',
          }),
          column({
            name: 'extensions',
            title: (
              <FormattedMessage
                id="tender-stats-orders-column-extensions"
                description="Column for Bids/Offers table on tender stats page"
                defaultMessage="{counterName, select, bid{Bid} offer{Offer} other{{counterName}}} extensions"
                values={{ counterName: tender.ordername.counter }}
              />
            ),
            render: o => o.extensions.count,
          }),
          column({
            name: 'ext-amount',
            title: (
              <FormattedMessage
                id="tender-stats-orders-column-extension-value"
                description="Column for Bids/Offers table on tender stats page"
                defaultMessage="Ext. value"
              />
            ),
            render: o => (
              <Data.PriceTicker title="" value={o.extensions.value} />
            ),
          }),
          column({
            name: 'max-price',
            title: (
              <FormattedMessage
                id="tender-stats-orders-column-best-price"
                description="Column for Bids/Offers table on tender stats page"
                defaultMessage="Best price"
              />
            ),
            render: o => <Data.PriceTicker title="" value={o.price} />,
          }),
          column({
            name: 'trades',
            title: (
              <FormattedMessage
                id="tender-stats-orders-column-trades"
                description="Column for Bids/Offers table on tender stats page"
                defaultMessage="Trades"
              />
            ),
            render: o => o.trades.count,
          }),
          column({
            name: 'value',
            title: (
              <FormattedMessage
                id="tender-stats-orders-column-trades-value"
                description="Column for Bids/Offers table on tender stats page"
                defaultMessage="Trades' value"
              />
            ),
            render: o => <Data.PriceTicker title="" value={o.trades.value} />,
          }),
        ]}
        data={report.orders}
        rowKey="id"
      />
    </div>
  );
};

type IChartSection = Record<'className', string> &
  Record<'children' | 'title', React.ReactNode>;
const ChartSection = ({ title, children, className }: IChartSection) => (
  <div className="chart-container tender-bid-price">
    <div className={classnames('tender-chart', className)}>
      <h3>{title}</h3>
      {children}
    </div>
  </div>
);

interface IParticipantFilter {
  search: { state: string; setState(s: string): void };
  viewers: { state: boolean; setState(s: boolean): void };
}

const ExportButton = ({
  tenderId,
  viewed,
}: {
  tenderId: string;
  viewed: boolean;
}) => {
  const [loading, toggle] = useToggle(false);

  const exportToParticipantsCSV = async () => {
    toggle(true);
    try {
      const response = await api.tenders.getParticipantsCSV(tenderId, viewed);
      const csvArrayBuffer = await response.arrayBuffer();
      const csvBlob = new Blob([csvArrayBuffer], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(csvBlob);
      link.setAttribute('download', 'Participants');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error exporting CSV:', error);
    } finally {
      toggle(false);
    }
  };

  return (
    <Button
      htmlType="button"
      size="small"
      type="secondary"
      className="export-button"
      loading={loading}
      onClick={exportToParticipantsCSV}
    >
      <span className="icon-export"></span>
      <FormattedMessage
        id="tender-stats-participants-button-export-to-csv"
        description="Button Export to csv for participants table on tender stats page"
        defaultMessage="<span>Export to</span> CSV"
        values={{
          span: chunks => <span className="show-for-large">{chunks}</span>,
        }}
      />
    </Button>
  );
};

const ParticipantFilters = ({ search, viewers }: IParticipantFilter) => {
  const [internal, setInternal] = useState(search.state);

  return (
    <section className="orders-table-filter-section">
      <div className="search-fieldset w-240">
        <div className="nui-fieldset">
          <label htmlFor="filter-search" className="offset">
            <FormattedMessage
              id="tender-stats-participants-search"
              description="Search for participants table on tender stats page"
              defaultMessage="Search"
            />
          </label>
          <div className="input-holder">
            <Input
              id="filter-search"
              type="search"
              placeholder="Search"
              value={internal}
              onChange={e => {
                const value = e?.target.value || '';
                setInternal(value);
                search.setState(value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="nui-field-holder show-viewers">
        <label htmlFor="filter-viewers">
          <FormattedMessage
            id="tender-stats-participants-button-show-absentees"
            description="Button Show absentees for participants table on tender stats page"
            defaultMessage="Show absentees"
          />{' '}
        </label>
        <Switch
          loading={false}
          id="filter-viewers"
          checked={viewers.state}
          onChange={(checked: boolean) => void viewers.setState(checked)}
        />
      </div>
    </section>
  );
};
