import React from 'react';
import qs from 'qs';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { SpecButtons } from 'Components/SpecButtons';
import { useIsMobile } from '~/hooks';
import { useStoreState } from 'easy-peasy';
import { Tooltip } from 'Components/nui';
import * as R from 'ramda';

export default ({ product, total }) => {
  const isMobile = useIsMobile();
  const volumeMode = useStoreState(state => state.marketplace.volumeMode);
  const productsTradeActions = useStoreState(
    state => state.auth.userProductsTradeActions
  );
  const tradeActions = R.pathOr([], [product.p_id], productsTradeActions);

  return (
    <th>
      <div className={classNames('product-col-width', 'no-ws', 'product')}>
        {!isMobile && (
          <div className="product-info pt-5">
            <div className="product-details">
              <h2>
                <Link
                  to={`/products/${product.id}/quick`}
                  className="product-name"
                >
                  <Tooltip
                    title={
                      <span className="icon-tooltip">
                        <span className="icon-info-circled"></span>View product
                        details in new window
                      </span>
                    }
                  >
                    {product.name}
                    <span className="nowrap">
                      &#65279;<span className="icon-link-ext-alt"></span>
                    </span>
                  </Tooltip>
                </Link>
              </h2>

              {R.hasPath(['owner', 'name'], product) && (
                <p className="division">{product.owner.name}</p>
              )}

              <p className="unit">
                {product._desc}
                {volumeMode && <> | {product._volumeUnit}</>}
              </p>
            </div>

            <div className="actions">
              <SpecButtons product={product} />

              <div className="right">
                <>
                  {'buy' in tradeActions && (
                    <Link
                      to={`/orders/bid?${qs.stringify({
                        product: tradeActions.buy,
                      })}`}
                      className={classNames('pill', 'place-bid')}
                    >
                      Place bid
                    </Link>
                  )}
                  {'sell' in tradeActions && (
                    <Link
                      to={`/orders/offer?${qs.stringify({
                        product: tradeActions.sell,
                      })}`}
                      className={classNames('pill', 'place-offer')}
                    >
                      Place offer
                    </Link>
                  )}
                </>
              </div>
            </div>
          </div>
        )}

        <div className="totals">
          <div className="current">
            <div className="total-bids">
              <span>Bids</span>{' '}
              {'bid' in total && (
                <Link
                  to={`/marketplace/${product.id}`}
                  className="show-totals"
                  title="View Market depth"
                >
                  {total.bid}
                </Link>
              )}
            </div>
            <div className="total-offers">
              <span>Offers</span>{' '}
              {'offer' in total && (
                <Link
                  to={`/marketplace/${product.id}`}
                  className="show-totals"
                  title="View Market depth"
                >
                  {total.offer}
                </Link>
              )}
            </div>
          </div>
          <div className="last">
            <span>Last</span>
          </div>
        </div>
      </div>
    </th>
  );
};
