import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { createStateContext, useToggle } from 'react-use';
import { Link } from 'react-router-dom';
import Helmet from 'Components/Helmet';
import { Button, Textarea, Alert } from 'Components/nui';
import { useSharedProduct } from '..';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
// import { v4 as uuid } from 'uuid';
import { api } from '~/api';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';

const [useSharedAttributes, SharedAttributesProvider] = createStateContext({});

const sortOptions = items =>
  R.sortBy(R.compose(R.toLower, R.prop('name')))(items);

const NewAttribute = ({ category }) => {
  const [product] = useSharedProduct();
  const [attributes, setAttributes] = useSharedAttributes();
  const [loading, setLoading] = useToggle();

  const initialValues = {
    options: '',
  };
  const validationSchema = Yup.object({
    options: Yup.string().required('At least one option is required.'),
  });
  const onSubmit = async (values, { resetForm }) => {
    let lst = [];
    setLoading(true);
    values.options.split(/\r?\n/).forEach(x => {
      if (x.trim().length > 0) lst.push(x.trim());
    });
    try {
      const data = await api.putData(
        `/products/${product.id}/attributes/${category}`,
        lst
      );
      const items = sortOptions(R.pathOr([], [category], data));
      setAttributes(state => ({ ...state, [category]: items }));
      resetForm();
      toast.success('New options were successfully added');
    } catch (error) {
      toast.error(
        'Something went wrong. Please refresh the page and try again.'
      );
    }
    setLoading(false);
  };

  return (
    <div className="wrapper">
      <div className="nui-form order-form inset-form mb-20">
        <h3 className="mt--10">New {category} options</h3>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <div className="nui-fieldset">
              <p className="pb-10">Add each option on a separate line.</p>
              <div className="input-holder mb-5">
                <Field name="options" as={Textarea} rows={4} />
              </div>
              <div className="error">
                <ErrorMessage name="options" />
              </div>
            </div>

            <Button type="secondary" loading={loading} htmlType="submit">
              Add options
            </Button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

const Section = ({ category }) => {
  const [product] = useSharedProduct();
  const [attributes, setAttributes] = useSharedAttributes();
  const options = R.pathOr([], [category], attributes);
  const [loading, setLoading] = useToggle();
  const [delId, setDelId] = useState();

  const delAll = async () => {
    setLoading(true);
    try {
      const data = await api.deleteData(
        `/products/${product.id}/attributes/${category}`
      );
      setAttributes(state => ({ ...state, [category]: [] }));
      toast.success('All options were removed');
    } catch (error) {
      toast.error(
        'Something went wrong. Please refresh the page and try again.'
      );
    }
    setLoading(false);
  };

  const delOption = async id => {
    setDelId(id);
    try {
      const data = await api.deleteData(
        `/products/${product.id}/attributes/${id}`
      );
      let arr = [];
      options.forEach(x => {
        if (x.id !== id) arr.push(x);
      });
      setAttributes(state => ({ ...state, [category]: arr }));
      toast.success('The option was removed');
    } catch (error) {
      toast.error(
        'Something went wrong. Please refresh the page and try again.'
      );
    }
    setDelId('');
  };

  return (
    <>
      <div className="attribute">
        <div className="attribute-name">
          <strong className="all-black">Name</strong>
          <span className="attribute-category">{category}</span>
          <div className="button-holder">
            <Button
              type="reverse"
              loading={loading}
              icon="trash"
              className="delete-all"
              onClick={delAll}
            />
          </div>
        </div>

        <div className="attribute-options">
          {options.length > 0 ? (
            <>
              <strong className="block all-black">Options</strong>
              <ul>
                {options.map(item => (
                  <li key={item.id}>
                    <Button
                      size="small"
                      type="reverse"
                      icon="trash"
                      loading={delId === item.id}
                      onClick={() => delOption(item.id)}
                    />
                    {item.name}{' '}
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <div className="no-options">
              <strong className="block all-black">Options</strong>
              <span className="block light-gray">
                No {category} options specified.
              </span>
            </div>
          )}
        </div>
      </div>

      <NewAttribute category={category} />
    </>
  );
};

const Sections = () => {
  const solution = useStoreState(state => state.auth.solution);
  const [product] = useSharedProduct();
  const [_, setAttributes] = useSharedAttributes();

  const categories = R.pathOr([], ['attributes'], solution);

  useEffect(() => {
    const fetch = async () => {
      const data = await api.getData2(`/products/${product.id}/attributes`);
      setAttributes(R.pathOr({}, ['attributes'], data));
    };

    fetch();
  }, [product]);

  if (categories.length === 0) {
    return (
      <Alert hasicon>No product attributes configured for the solution.</Alert>
    );
  }

  return categories.map(category => (
    <Section category={category.name} key={category.name} />
  ));
};

export default () => {
  const [product] = useSharedProduct();

  if (!product) return null;

  return (
    <>
      <Helmet>
        <title>Edit product {product.name} - Attributes</title>
      </Helmet>

      <SharedAttributesProvider>
        <Sections />
      </SharedAttributesProvider>
      <div className="sticky-btm">
        <Link
          className="button primary mb-10"
          to={`/products/${product.id}/edit/locations`}
        >
          Next step
        </Link>
      </div>
    </>
  );
};
