import React from 'react';
import Upload from 'rc-upload';
import type { IUpload } from 'rc-upload';
import { FormattedMessage } from 'react-intl';

export default (props: Omit<IUpload, 'component' | 'prefixCls'>) => (
  <Upload component="div" prefixCls="input-holder nui-upload" {...props}>
    <span className="icon-upload-cloud">
      <FormattedMessage
        id="files-form-helptext"
        description="Helptext for files input form"
        defaultMessage="Drag your files here, or click to browse..."
      />
    </span>
  </Upload>
);
