import axios from 'axios';
// import axiosRetry, {
//   exponentialDelay,
//   isNetworkOrIdempotentRequestError,
// } from 'axios-retry';
import qs from 'qs';
import { authService } from './services/auth-service';
import { getEnv } from './utils';

const URLS = {
  base: getEnv(import.meta.env.VITE_APP_API_BASE),
  authenticate: '/authenticate',
  signIn: '/signin',
  signOut: '/signout',
  user: '/user',
  presets: '/presets',
  orders: '/orders',
  trades: '/trades',
  tenders: '/tenders',
  divisions: '/divisions',
  products: '/products',
  customers: '/customers',
  marketOrders: '/market-orders',
  marketTrades: '/market-trades',
  marketDepth: '/market-depth',
  fcmToken: '/register',
  oauthRefresh: '/token',
  oauthRevoke: '/user/revoke',
  acceptPolicy: '/user/accept-policy',
  userActivity: '/user/activity',
  userMobile: '/user/mobile',
  userVerifySMS: '/user/verify-sms',
  userConfirmSMS: '/user/confirm-sms',
  participants: '/participants',
  solutions: '/solutions',
  routes: '/routes',
  analytics: getEnv(import.meta.env.VITE_APP_ANALYTICS_URL),
};

// Taken from CreamApp
export class API {
  token = null;
  constructor(urls = URLS) {
    this.urls = urls;
    this.ax = axios.create({
      baseURL: urls.base,
      // TODO Enable this when components are robust
      // timeout: 10000,
      headers: {
        // axios having issue of defaults headers, before we use axios@next
        // 'Content-Type': 'application/json'
      },
      withCredentials: true,
    });
    this.ax.defaults.headers['Content-Type'] = 'application/json';
    this.ax.interceptors.request.use(
      req => {
        // axios has issue of defaults headers, before we use axios@next
        if (this.token) {
          req.headers.Authorization = `${this.token.token_type} ${this.token.access_token}`;
        }
        return req;
      },
      e => Promise.reject(e)
    );
    // axiosRetry(this.ax, {
    //   retries: 1,
    //   retryDelay: exponentialDelay,
    //   retryCondition: this.retryCondition,
    // });
  }

  // retryCondition = async error => {
  //   // retry on 401, except those refresh calling
  //   // (might be unnecessary because it's axios instead of this.ax doing refresh)
  //   if (
  //     error.response &&
  //     error.response.status === 401 &&
  //     error.config &&
  //     !error.config.url.endsWith(this.urls.oauthRefresh)
  //   ) {
  //     try {
  //       const token = await this.refresh();
  //       authService.saveToken(token);
  //       this.updateToken(token);
  //       return true;
  //     } catch (e) {
  //       // firebase
  //       //   .crashlytics()
  //       //   .recordError(1, `Failed to refresh OAuth token, no more retry: ${e}`);
  //       return false;
  //     }
  //   }
  //   return isNetworkOrIdempotentRequestError(error);
  // };

  // After signin, set token here, when signout clear token
  // In between, use the token to refresh, if refresh success, signal to update oauthToken, else reject w/ error;
  // listener to store directly would cause circular importing, thus manually set here
  updateToken = token => {
    this.token = token;
  };

  // user, trades, orders, divisions, products, etc
  getData = (
    { type, id, division, getResult = x => Object.values(x)[0] || [] },
    extraParams,
    extra
  ) => {
    const prefix = this.urls[type];
    const url = id ? `${prefix}/${id}` : prefix;
    const params = division ? { division } : null;
    return (
      this.ax
        .request({
          url,
          params: { ...params, ...extraParams },
          ...extra,
        })
        // XXX When empty, {} is returned instead of such as { orders: [] }
        .then(resp => getResult(resp.data))
    );
  };

  getData2 = (url, params = {}) =>
    this.ax
      .request({
        url,
        params,
        method: 'get',
      })
      .then(resp => resp.data);

  getDataV2Api = (url, params = {}) =>
    this.ax
      .request({
        // baseURL: 'https://dev.cream.global/api/2.0',
        baseURL: this.urls.base.replace('/api/1.0', '/api/2.0'),
        url,
        params,
        method: 'get',
      })
      .then(resp => resp.data);

  deleteData = (url, params = {}) =>
    this.ax
      .request({
        url,
        params,
        method: 'delete',
      })
      .then(resp => resp.data);

  putData = (url, data) =>
    this.ax
      .request({
        url,
        data,
        method: 'put',
      })
      .then(resp => resp.data);

  patchData = (url, data) =>
    this.ax
      .request({
        url,
        data,
        method: 'patch',
      })
      .then(resp => resp.data);

  postData = (url, data) =>
    this.ax
      .request({
        url,
        data,
        method: 'post',
      })
      .then(resp => resp.data);

  getDataIfModifiedSince = (url, params, ifModifiedSinceDate) => {
    let config = {
      url,
      params,
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    };
    if (ifModifiedSinceDate) {
      const date = new Date(ifModifiedSinceDate.getTime() - 120000);
      config.headers = {
        'If-Modified-Since': date.toUTCString(),
      };
      config.validateStatus = status => {
        return status === 200 || status === 304;
      };
    }

    return this.ax
      .request(config)
      .then(resp => [resp.status, resp.data])
      .catch(error => {
        // handle error
      });
  };

  get request() {
    return this.ax.request;
  }

  requestAnalytics({ endpoint, solutionId, args = null }) {
    let prefix = this.urls['analytics'];
    let url = `${prefix}/${endpoint}/${solutionId}`;
    if (args) {
      url += '/' + args.join('/');
    }
    return this.ax.request({ url }).then(resp => {
      return resp;
    });
  }

  analytics(endpoint, args = [], params = {}) {
    let url = [this.urls.analytics, endpoint].concat(args).join('/');
    if (params) {
      url +=
        '?' + qs.stringify(params, { indices: false, useQuerystring: true });
    }
    return this.ax.get(url).then(resp => resp);
  }

  signIn = (email, solution) =>
    this.ax.post(this.urls.signIn, { email, solution });

  signOut = () => this.ax.post(this.urls.signOut);

  authenticate = (token, client) =>
    this.ax
      .request({
        method: 'post',
        url: this.urls.authenticate,
        data: { token, client },
        headers: {
          Trusted: authService.loadTrusted() || '',
        },
      })
      .then(resp => resp.data);

  getMarketDepth = params =>
    this.ax
      .request({ url: this.urls.marketDepth, params })
      .then(resp => resp.data.data);

  sendMobileVerifySMS = number =>
    this.ax
      .request({
        method: 'post',
        url: this.urls.userVerifySMS,
        data: { number },
      })
      .then(resp => resp.data);

  checkMobileVerifyToken = token =>
    this.ax
      .request({
        method: 'post',
        url: this.urls.userConfirmSMS,
        data: { token },
      })
      .then(resp => resp.data);

  fok = data => this.ax.post(this.urls.orders, data);

  revoke = token => this.ax.post(this.urls.oauthRevoke);

  acceptPolicy = solution => this.ax.post(this.urls.acceptPolicy, { solution });
  userActivity = data =>
    this.ax.post(this.urls.userActivity, data, {
      validateStatus: status => status === 200 || status === 403,
    });

  getPdf = (url, filename) =>
    this.ax
      .request({
        url: url.replace('/api/1.0', ''),
        method: 'get',
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
      })
      .then(response => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });

  getFile = (url, filename, mimeType) =>
    this.ax
      .request({
        url: url.replace('/api/1.0', ''),
        method: 'get',
        responseType: 'blob',
        headers: {
          Accept: mimeType,
        },
      })
      .then(response => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });

  getFileHref = (url, mimeType) =>
    this.ax
      .request({
        url: url.replace('/api/1.0', ''),
        method: 'get',
        responseType: 'blob',
        headers: {
          Accept: mimeType,
        },
      })
      .then(response => window.URL.createObjectURL(new Blob([response.data])));

  // TODO
  refresh = () => {
    const client_id = getEnv(import.meta.env.VITE_APP_WEB_CLIENT_ID);
    return axios
      .request({
        url: this.urls.oauthRefresh,
        method: 'POST',
        data: qs.stringify({
          client: client_id,
        }),
        baseURL: this.urls.base,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        withCredentials: true,
      })
      .then(resp => {
        const { token_type, user_id, access_token, expires_in } = resp.data;
        return {
          user_id,
          access_token,
          token_type,
          expires_in,
        };
      });
  };

  // registerFcmToken = address => {
  //   return this.ax.post(this.urls.fcmToken, { address });
  // };
}

// TODO From env
export const api = new API();
