import React from 'react';
import * as Wizard from 'Components/nui/Wizard';
import { capitalize } from '~/utils';
import { can } from 'Components/Access';
import Summary from './Summary';
import Details from './Details';
import Orders from './Orders';
import Participants from './Participants';
import Review from './Review';
import {
  matchUrl,
  routeUrl,
  RoutingTable,
  withPreload,
  withRouteX,
} from '~/router';
import * as services from '~/services';
import { useStoreState } from '~/store';
import { FormattedMessage } from 'react-intl';

interface IConfig {
  mode?: string;
}
const Edit = withPreload({
  route: 'tender-new',
  preload: services.tenders.observe.orders({ requireId: false }),
})<IConfig>(({ mode = 'edit', data: { tender, orders } }) => {
  const tenderlogic = useStoreState(state => state.auth.solution.tenderlogic);
  const purchasetender = useStoreState(
    state => state.auth.solution.settings.purchasetender
  );

  const isBid = purchasetender && tenderlogic === 'purchase';

  const tenderId = tender?.id;
  const ordername = tender?.ordername.order || (isBid ? 'bid' : 'offer');

  const hasOrders = !!tender?.orders.total;
  const editable = can('edit', tender);

  const canAddOrders = can('orders_add', tender);
  const canEditParticipants = can('participants_edit', tender);
  const canPublish = can('publish', tender);
  const canNotify = can('notify', tender);

  const editDetails = editable;
  const editParticipants = canEditParticipants;
  const editOrders = canAddOrders;
  const allowReview = canPublish || canNotify;

  const steps = [
    {
      id: 'details',
      url: routeUrl('tender-edit-details', { mode, tenderId }),
      path: matchUrl('tender-edit-details'),
      exact: true,
      disabled: !editDetails,
      completed: !!tenderId,
      title: (
        <FormattedMessage
          id="tender-create-steps-details-heading"
          description="Heading for Details on tenders create/edit steps"
          defaultMessage="Details"
        />
      ),
      desc: (
        <FormattedMessage
          id="tender-create-steps-details-description"
          description="Description for Details on tenders create/edit steps"
          defaultMessage="Edit basic tender details"
        />
      ),
    },
    {
      id: 'orders',
      url: tenderId && routeUrl('tender-edit-orders', { mode, tenderId }),
      path: matchUrl('tender-edit-orders'),
      disabled: !editOrders,
      completed: hasOrders,
      title: (
        <FormattedMessage
          id="tender-create-steps-order-heading"
          description="Heading for Bid/Offer on tenders create/edit steps"
          defaultMessage="{ordername, select, bid{Bids} offer{Offers} other{{ordername}s}}"
          values={{ ordername: ordername }}
        />
      ),
      desc: (
        <FormattedMessage
          id="tender-create-steps-order-description"
          description="Description for Bid/Offer on tenders create/edit steps"
          defaultMessage="Add {ordername, select, bid{bids} offer{offers} other{{ordername}s}} to this tender"
          values={{ ordername: ordername }}
        />
      ),
    },
    {
      id: 'participants',
      url: tenderId && routeUrl('tender-edit-participants', { mode, tenderId }),
      path: matchUrl('tender-edit-participants'),
      disabled: !editParticipants,
      completed: hasOrders,
      title: (
        <FormattedMessage
          id="tender-create-steps-participants-heading"
          description="Heading for Participants on tenders create/edit steps"
          defaultMessage="Participants"
        />
      ),
      desc: (
        <FormattedMessage
          id="tender-create-steps-participants-description"
          description="Description for Participants on tenders create/edit steps"
          defaultMessage="Decide who may view this tender"
        />
      ),
    },
    {
      id: 'review',
      url: tenderId && routeUrl('tender-edit-review', { mode, tenderId }),
      path: matchUrl('tender-edit-review'),
      disabled: !allowReview,
      title: canPublish ? (
        <FormattedMessage
          id="tender-create-steps-review-heading-publish"
          description="Heading for Participants for publisher on tenders create/edit steps"
          defaultMessage="Review and publish"
        />
      ) : canNotify ? (
        <FormattedMessage
          id="tender-create-steps-review-heading-notify"
          description="Heading for Participants for notifier on tenders create/edit steps"
          defaultMessage="Notify participants"
        />
      ) : (
        <FormattedMessage
          id="tender-create-steps-review-heading-view"
          description="Heading for Participants for viewer on tenders create/edit steps"
          defaultMessage="Review details"
        />
      ),
      desc: canPublish ? (
        <FormattedMessage
          id="tender-create-steps-review-description-publish"
          description="Description for Participants for publisher on tenders create/edit steps"
          defaultMessage="Review tender details and publish when ready"
        />
      ) : canNotify ? (
        <FormattedMessage
          id="tender-create-steps-review-description-notify"
          description="Description for Participants for notifier on tenders create/edit steps"
          defaultMessage="Notify and remind participants about this tender"
        />
      ) : (
        <FormattedMessage
          id="tender-create-steps-review-description-view"
          description="Description for Participants for viewer on tenders create/edit steps"
          defaultMessage="Review details of this tender"
        />
      ),
      numbered: false,
      summary: false,
    },
  ];

  return (
    <Wizard.Wizard steps={steps}>
      <Wizard.Content>
        <Wizard.StepHeading />
        <RoutingTable routes={[Details, Orders, Participants, Review]} />
      </Wizard.Content>
      <Wizard.Summary
        title={
          <FormattedMessage
            id="tender-create-summary-heading"
            description="Heading for Summary on tenders create/edit steps"
            defaultMessage="Summary"
          />
        }
      >
        <Summary tender={tender} orders={orders} />
      </Wizard.Summary>
    </Wizard.Wizard>
  );
});
export default withRouteX({
  name: 'tender-new',
})(({ params: { mode } }) => {
  return <Edit mode={mode} />;
});
