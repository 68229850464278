import React, { useContext, useEffect } from 'react';
import classnames from 'classnames';
import { SetState, useLocalJsonStorage } from '~/hooks';
import * as R from 'ramda';

type ChildrenProp = Partial<Record<'children', React.ReactNode>>;
type NameProp = Record<'name', string>;
type TitleProp = Record<'title', string | React.ReactNode>;

type IVisibilityData = Record<string, boolean>;
interface IContext {
  fields: IVisibilityData;
  setFields: SetState<IVisibilityData>;
}

const FieldsContext = React.createContext<IContext | undefined>(undefined);

type IFieldVisibility = ChildrenProp & {
  initial: IVisibilityData;
  store: string;
};
export const FieldVisibility = ({
  store,
  initial,
  children,
}: IFieldVisibility) => {
  const [fields, setFields] = useLocalJsonStorage<IVisibilityData>(
    store,
    initial
  );

  return (
    <FieldsContext.Provider
      value={{
        fields,
        setFields,
      }}
    >
      {children}
    </FieldsContext.Provider>
  );
};

function useFieldVisibility() {
  const context = useContext(FieldsContext);
  if (!context) throw new Error('FieldVisibility context is undefined');

  return context;
}

type IFieldVisible = NameProp & ChildrenProp;
export const FieldVisible = ({ name, children }: IFieldVisible) => {
  const { fields } = useFieldVisibility();

  if (fields[name] ?? true) return <>{children}</>;
  return null;
};

type IToggleVisibility = NameProp & TitleProp;
export const ToggleVisibility = ({ name, title }: IToggleVisibility) => {
  const { fields, setFields } = useFieldVisibility();
  useEffect(() => {
    if (R.isNil(fields[name])) {
      setFields({ ...fields, [name]: true });
    }
  }, [fields, setFields]);

  return (
    <div className="fieldset check-fieldset pt-2 pb-2">
      <label htmlFor={name} className="ant-checkbox-wrapper" title={name}>
        <span
          className={classnames('ant-checkbox', {
            'ant-checkbox-checked': fields[name],
          })}
        >
          <input
            type="checkbox"
            id={name}
            className="ant-checkbox-input"
            value={name}
            onChange={() =>
              setFields(() => ({ ...fields, [name]: !fields[name] }))
            }
          />
          <span className="ant-checkbox-inner" />
        </span>
        <span>{title}</span>
      </label>
    </div>
  );
};

export const DummyFilter = () => (
  <div className="this-is-just-dummy-data nowrap">
    <strong className="all-black block mb-5">Display on summary</strong>
    <div className="fieldset check-fieldset pt-2 pb-2">
      <label
        htmlFor="show-field-etd"
        className="ant-checkbox-wrapper"
        title="Show ETD"
      >
        <span className="ant-checkbox">
          <input
            type="checkbox"
            id="show-field-etd"
            className="ant-checkbox-input"
            value="show-field-etd"
          />
          <span className="ant-checkbox-inner"></span>
        </span>
        <span>ETD</span>
      </label>
    </div>
  </div>
);
