import React, { useEffect, useState } from 'react';
import { useScrollToTopOnMount } from '~/hooks';
import Header from './Header';
import { api } from '~/api';
import { useStoreState } from 'easy-peasy';
import { Form, Button, Loading } from 'Components/nui';
import { FormWrapper } from 'Components/form';
import {
  mergeSchema,
  submitError,
  normalizeChoicesNS,
} from 'Components/form/utils';
import { ShowResult } from 'Components/Result';
import { promisify } from 'es6-promisify';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';
import { parseSearch } from '~/utils';
import qs from 'qs';
import { reportTypes } from '..';

const PForm = ({
  form,
  schema,
  path,
  initialData,
  solution,
  submitState,
  fileName,
}) => {
  const getFormWrapper = () => {
    let fields = [
      {
        name: 'start',
        label: 'Start',
      },
      {
        name: 'end',
        label: 'End',
      },
      {
        name: 'status',
        label: 'Status',
        choices: normalizeChoicesNS,
      },
    ];

    return new FormWrapper(form, initialData, mergeSchema(schema, fields));
  };

  const formWrapper = getFormWrapper();
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }
    submitState.setLoading(true);
    let formData = formWrapper.serialize();

    try {
      const params = {
        ...formData,
        solution: solution.id,
        sort: 'created',
      };
      const url = `/reports/${path}?${qs.stringify(params, {
        indices: false,
      })}`;
      await api.getFile(url, fileName, 'application/json');
      submitState.setLoading(false);
    } catch (err) {
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        err
      );
      if (errors) {
        formWrapper.setErrors(errors);
      } else {
        submitState.setResult(err);
      }
      submitState.setLoading(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      {formWrapper.render()}
      <div className="mb-20">
        <Button
          htmlType="submit"
          type="primary"
          loading={submitState.loading}
          title="Show report"
        >
          Download CSV report
        </Button>
      </div>
    </Form>
  );
};

const WrappedForm = Form.create()(PForm);

const CsvReport = ({ reportType }) => {
  useScrollToTopOnMount();
  // const { reportType } = useParams();
  const path = R.path([reportType, 'path'], reportTypes);
  const title = R.path([reportType, 'title'], reportTypes);
  const filename = R.path([reportType, 'filename'], reportTypes);

  const solution = useStoreState(state => state.auth.solution);

  const [schema, setSchema] = useState();
  const history = useHistory();

  const initialData = parseSearch(R.path(['location', 'search'], history));

  useEffect(() => {
    const fetch = async () => {
      const params = {
        solution: solution.id,
      };
      const data = await api.getData2(`/reports/${path}/schema`, params);
      setSchema(data.fields);
    };

    fetch();
  }, [path]);

  if (!path) return history.push('/reports');

  return (
    <>
      <Header title={title} />

      <div className="full-page-header">
        <hr />
        <ShowResult renderError={submitError}>
          {submitState => (
            <div className="nui-form report-filter">
              {schema ? (
                <WrappedForm
                  key="report-filter"
                  schema={schema}
                  path={path}
                  initialData={initialData}
                  solution={solution}
                  submitState={submitState}
                  fileName={filename}
                />
              ) : (
                <Loading size="large" />
              )}
            </div>
          )}
        </ShowResult>
      </div>
    </>
  );
};

export default CsvReport;
