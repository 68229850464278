import React from 'react';
import * as R from 'ramda';
import YesNo from 'Components/YesNo';
import { capitalize, nl2p } from '~/utils';
import moment from 'moment-timezone';
import { Alert } from 'Components/nui';
import { useStoreState } from 'easy-peasy';
import { FormattedMessage } from 'react-intl';
import FNumber from 'Components/FNumber';
import { getDecimalCount, getColumnFieldLabel } from '~/utils';
import { Trade } from '~/models';

const tradeToBidType = recordType =>
  R.propOr(recordType, recordType, { purchase: 'bid', sale: 'bid' });

export default ({ record, bid, offer }) => {
  const products = useStoreState(state => state.auth.products);

  const product = products.find(p => p.id === record.product.id);
  const productColumns = product?.columns;

  const bidOrPurchaseType = record.type === 'bid' || record.type === 'purchase';
  const solutionColumns = useStoreState(state => state.auth.solutionColumns);
  const solution = useStoreState(state => state.auth.solution);
  const hasFlatFee = solution?.exportprice?.flatfee;

  const attributes = R.propOr([], ['attributes'], solution);
  let data = [];

  if (record.productionmonth) {
    data.push([
      'Production month',
      record.productionmonth.display,
      'reversed',
      'productionmonth',
    ]);
  }

  attributes.forEach(item => {
    if (R.pathOr(0, ['attributes', item.name, 'length'], record) > 0) {
      data.push([
        capitalize(item.name),
        record.attributes[item.name].map(x => x.name).join(', '),
        'reversed',
        item.name,
      ]);
    }
  });

  if ('anonymous' in record) {
    data.push([
      <FormattedMessage
        id="extradetails-field-anonymous-label"
        description="Label for 'Anonymous' field on Extra details"
        defaultMessage="Anonymous"
      />,
      <YesNo yes={record.anonymous} />,
      'reversed',
      'anonymous',
    ]);
  }

  if (record?.brand && record.brand.length > 0) {
    data.push([
      <FormattedMessage
        id="extradetails-field-brands-label"
        description="Label for 'Brands' field on Extra details"
        defaultMessage="Brands"
      />,
      record.brand.map(x => x.desc).join(', '),
      'stacked',
      'brands',
    ]);
  }

  if (record?.expiry) {
    data.push([
      <FormattedMessage
        id="extradetails-field-validtill-label"
        description="Label for 'Valid till' field on Extra details"
        defaultMessage="Valid till"
      />,
      moment(record.expiry).format('DD MMMM YYYY HH:mm'),
      'reversed',
      'valid-till',
    ]);
  }

  if (!R.isNil(record.exportdocs)) {
    data.push([
      getColumnFieldLabel(
        tradeToBidType(record.type),
        'exportdocs'
      )(solutionColumns) ?? (
        <FormattedMessage
          id="extradetails-field-exportdocs-label"
          description="Label for 'Export docs' field on Extra details"
          defaultMessage="Export docs"
        />
      ),
      <YesNo yes={record.exportdocs} notRequired={bidOrPurchaseType} />,
      'reversed',
      'exportdocs',
    ]);
  }

  if (record?.exportable && record?.exportcountry) {
    data.push([
      'Destination',
      record.exportcountry.name,
      'reversed',
      'exportcountry',
    ]);
  }

  if ('freightavailable' in record) {
    data.push([
      <FormattedMessage
        id="extradetails-field-additionalfreight-label"
        description="Label for 'Additional freight' field on Extra details"
        defaultMessage="Additional freight"
      />,
      <YesNo yes={record.freightavailable} notRequired={bidOrPurchaseType} />,
      'reversed',
      'freightavailable',
    ]);
  }

  if (!R.isNil(record.exportable)) {
    data.push([
      getColumnFieldLabel(
        tradeToBidType(record.type),
        'exportable'
      )(solutionColumns) ?? (
        <FormattedMessage
          id="extradetails-field-exportable-label"
          description="Label for 'Exportable' field on Extra details"
          defaultMessage="Exportable"
        />
      ),
      <YesNo
        yes={record.exportable}
        notRequired={bidOrPurchaseType && !hasFlatFee}
      />,
      'reversed',
      'exportable',
    ]);
  }

  if ('docs' in record) {
    if ('gfsi' in record.docs) {
      data.push([
        getColumnFieldLabel(
          record.type,
          'gfsi'
        )(productColumns ?? solutionColumns) ?? (
          <FormattedMessage
            id="extradetails-field-gfsi-label"
            description="Label for 'GFSI' field on Extra details"
            defaultMessage="GFSI certification"
          />
        ),
        <YesNo
          yes={record.docs.gfsi === 'yes'}
          notRequired={bidOrPurchaseType}
        />,
        'reversed',
        'gfsi',
      ]);
    }
    if ('export' in record.docs) {
      data.push([
        R.pathOr(
          <FormattedMessage
            id="extradetails-field-exportdocuments-label"
            description="Label for 'Export documents' field on Extra details"
            defaultMessage="Export documents"
          />,
          ['export', 'label'],
          solutionColumns
        ),
        <YesNo
          yes={record.docs.export === 'yes'}
          notRequired={bidOrPurchaseType}
        />,
        'reversed',
        'export',
      ]);
    }
    if ('halal' in record.docs) {
      data.push([
        getColumnFieldLabel(
          record.type,
          'halal'
        )(productColumns ?? solutionColumns) ?? (
          <FormattedMessage
            id="extradetails-field-halal-label"
            description="Label for 'Halal documents' field on Extra details"
            defaultMessage="Halal documents"
          />
        ),
        <YesNo
          yes={record.docs.halal === 'yes'}
          notRequired={bidOrPurchaseType}
        />,
        'reversed',
        'halal',
      ]);
    }
    if ('kosher' in record.docs) {
      data.push([
        getColumnFieldLabel(
          record.type,
          'kosher'
        )(productColumns ?? solutionColumns) ?? (
          <FormattedMessage
            id="extradetails-field-kosher-label"
            description="Label for 'Kosher documents' field on Extra details"
            defaultMessage="Kosher documents"
          />
        ),
        <YesNo
          yes={record.docs.kosher === 'yes'}
          notRequired={bidOrPurchaseType}
        />,
        'reversed',
        'kosher',
      ]);
    }
  }

  if ('callofftime' in record) {
    data.push([
      <FormattedMessage
        id="extradetails-field-Callofftimes-label"
        description="Label for 'Call off times' field on Extra details"
        defaultMessage="Call off times"
      />,
      nl2p(record.callofftime) || 'n/a',
      'reversed',
      'callofftime',
    ]);
  }

  if (record?.destinations?.length)
    data.push([
      <FormattedMessage
        id="extradetails-field-destination-label"
        description="Label for 'Destination' field on Extra details"
        defaultMessage="Destination"
      />,
      record.destinations.map(item => item.name).join(', '),
      'reversed',
      'destinations',
    ]);

  if ('origins' in record)
    data.push([
      R.pathOr(
        <FormattedMessage
          id="extradetails-field-origin-label"
          description="Label for 'Origin' field on Extra details"
          defaultMessage="Origin"
        />,
        ['origins', 'label'],
        solutionColumns
      ),
      record.origins.map(item => item.name).join(', '),
      'reversed',
      'origins',
    ]);

  if (record?.locations?.length)
    data.push([
      R.pathOr(
        <FormattedMessage
          id="extradetails-field-location-label"
          description="Label for 'Location' field on Extra details"
          defaultMessage="Location"
        />,
        ['locations', 'label'],
        solutionColumns
      ),
      record.locations.map(item => item.name).join(', '),
      'reversed',
      'locations',
    ]);

  if (record?.incoterms?.length)
    data.push([
      R.pathOr(
        <FormattedMessage
          id="extradetails-field-incoterm-label"
          description="Label for 'Incoterm' field on Extra details"
          defaultMessage="Incoterm"
        />,
        ['incoterms', 'label'],
        solutionColumns
      ),
      record.incoterms.join(', '),
      'reversed',
      'incoterms',
    ]);

  if ('heattreatedpallets' in record) {
    data.push([
      'Heat-treated pallets required',
      <YesNo yes={record.heattreatedpallets} notRequired={bidOrPurchaseType} />,
      'reversed',
      'pallets-required',
    ]);
  }

  if (record?.heattreatedpallets && record instanceof Trade) {
    const tradeVolume = record.volume.val;
    data.push([
      'Heat-treated pallets summary',
      <span>
        <FNumber
          value={parseFloat(solution.exportprice.heattreatedfee)}
          decimalCount={getDecimalCount(
            parseFloat(solution.exportprice.heattreatedfee)
          )}
          unit={solution.currency}
        />{' '}
        &times;{' '}
        <FNumber
          value={tradeVolume}
          decimalCount={getDecimalCount(tradeVolume)}
        />{' '}
        ={' '}
        <FNumber
          value={parseFloat(solution.exportprice.heattreatedfee) * tradeVolume}
          decimalCount={2}
          unit={solution.currency}
        />
      </span>,
      'reversed',
      'pallets-value',
    ]);
  }

  if (record?.age && record.age.desc)
    data.push([
      <FormattedMessage
        id="extradetails-field-age-label"
        description="Label for 'Age' field on Extra details"
        defaultMessage="Age"
      />,
      record.age.desc,
      'reversed',
      'age',
    ]);

  if (record?.productiondate)
    data.push([
      <FormattedMessage
        id="extradetails-field-productiondate-label"
        description="Label for 'Production date' field on Extra details"
        defaultMessage="Production date"
      />,
      moment(record.productiondate).format('DD MMMM YYYY'),
      'reversed',
      'productiondate',
    ]);

  if (record?.declinereason)
    data.push([
      <FormattedMessage
        id="extradetails-field-declinereason-label"
        description="Label for 'Decline reason' field on Extra details"
        defaultMessage="Decline reason"
      />,
      <span className="icon-block">{record.declinereason}</span>,
      'stacked',
      'declinereason',
    ]);

  if (record?.reference)
    data.push([
      <FormattedMessage
        id="extradetails-field-reference-label"
        description="Label for Reference on Extra details"
        defaultMessage="Reference"
      />,
      record.reference,
      'reversed',
      'reference',
    ]);

  const brands = R.pathOr([], ['brand', 'trade'], record);
  if (brands.length > 0) {
    data.push([
      'Brands',
      brands.map(item => item.desc).join(', '),
      'stacked',
      'brands',
    ]);
  }

  if (bid && bid?.note) {
    data.push([
      <FormattedMessage
        id="extradetails-field-buyercomment-label"
        description="Label for 'Buyer comment' field on Extra details"
        defaultMessage="Buyer comment"
      />,
      nl2p(bid.note),
      'stacked',
      'note',
    ]);
  }

  if (offer && offer?.note) {
    data.push([
      <FormattedMessage
        id="extradetails-field-sellercomment-label"
        description="Label for 'Seller comment' field on Extra details"
        defaultMessage="Seller comment"
      />,
      nl2p(offer.note),
      'stacked',
      'note',
    ]);
  }

  if (data.length === 0) return null;

  return (
    <>
      <h2>
        <FormattedMessage
          id="extradetails-header"
          description="Header for Extra details"
          defaultMessage="Extra details"
        />
      </h2>

      <ul className="trade-details-list loading-details">
        {data.length === 0 ? (
          <li>
            <Alert hasicon>
              <div className="align-center">
                <FormattedMessage
                  id="extradetails-header-empty"
                  description="Header for empty Extra details"
                  defaultMessage="No extra details"
                />
              </div>
            </Alert>
          </li>
        ) : (
          data.map(([message, value, className, key]) => (
            <li key={key} className={className}>
              <strong>{message}</strong>
              <span className={`item-${key}`}>{value}</span>
            </li>
          ))
        )}
      </ul>
    </>
  );
};
