import React from 'react';

import { NoData } from 'Components/InfoMessage';
import Footer from 'Pages/Footer';

export default () => {
  const supportLink = 'mailto:support@nuimarkets.com';
  return (
    <main>
      <div className="full-page-header inactive-account-page-header">
        <h1 className="inline mt-20 mr-20 mb-0">Account not active</h1>
      </div>

      <div className="inactive-account-contact-support-button-container">
        <a
          className="nui-button nui-secondary new-request inactive-account-contact-support-button"
          href={supportLink}
        >
          Contact support
        </a>
      </div>

      <div className="inactive-account-container">
        <NoData className="mt--1 mb--1 p-100 b-0">
          Your account is not active, please contact{' '}
          <a href={supportLink}>support@nuimarkets.com</a>
        </NoData>
      </div>

      <Footer />
    </main>
  );
};
