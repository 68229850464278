import { WithId, Filters } from './types';
import * as R from 'ramda';

export function singular(data: Partial<WithId> = {}): data is WithId {
  return R.not(R.isNil(R.prop('id', data)));
}

export function stringFilter(f: string) {
  if (f)
    return (
      '%' +
      f
        .trim()
        .replace(/%/g, '')
        .replace(/ +(?= )/g, '')
        .replace(/ /g, '%') +
      '%'
    );
  return undefined;
}

export function deserializeString(s: string) {
  return s.replaceAll('%', ' ').trim();
}

export function arrayFilter(
  name: string,
  set: readonly any[],
  filters: Filters
) {
  const all = R.propOr<any[], Filters, any[]>([], name, filters);
  const filter = R.includes(all.length || 0, [0, set.length]) ? [] : all;

  return filter;
}
