import type * as Struct from 'struct';
import type { Tender, Order } from '~/models/tenders';
import React, { useState, useMemo, useEffect } from 'react';
import { routeUrl, withRouteX, withPreload } from '~/router';
import * as services from '~/services/tenders';
import OrderForm from './OrderForm';
import { SelectField } from 'Components/form/fields';
import { useStoreState } from '~/store';
import * as R from 'ramda';
import { Alert, Button, Loading, Form } from 'Components/nui';
import { useMountedState } from '~/hooks';
import { Link, useHistory } from 'react-router-dom';
import { capitalize } from '~/utils';
import { can } from 'Components/Access';
import { toast } from 'react-toastify';
import { ProductTagsFilter } from 'Pages/Orders/New';
import { FormattedMessage } from 'react-intl';

enum form_mode {
  edit,
  nav,
}

interface IOrders {
  mode?: string;
}
const Orders = withPreload({
  route: 'tender-edit-orders',
  preload: services.observe.order({ requireId: false }),
})<IOrders>(({ data: { tender, order }, mode = 'edit' }) => {
  const [[product, action], setLast] = useMountedState(['', '']);
  const [step, setStep] = useState(form_mode.edit);

  const history = useHistory();

  const params = { tenderId: tender.id, mode };

  useEffect(() => {
    if (order?.id && step === form_mode.nav) setStep(form_mode.edit);
  }, [order?.id]);

  const next = can('participants_edit', tender)
    ? routeUrl('tender-edit-participants', params)
    : routeUrl('tender-edit-review', params);

  return (
    <div className="tender-edit-step orders">
      {step === form_mode.nav ? (
        <>
          <Alert type="success" hasicon>
            {capitalize(tender.ordertype.order)} for <strong>{product}</strong>{' '}
            {action} successfully.
          </Alert>
          <div className="button-set proceed">
            <Button type="primary" onClick={() => void setStep(form_mode.edit)}>
              <FormattedMessage
                id="tender-create-order-add-another-button"
                description="Button for Add another on tenders create/edit order page"
                defaultMessage="Add another"
              />
            </Button>
            <Link className="button secondary" to={next}>
              <FormattedMessage
                id="tender-create-order-continue-button"
                description="Button for Continue on tenders create/edit order page"
                defaultMessage="Continue"
              />
            </Link>
          </div>
        </>
      ) : (
        <FormView
          tender={tender}
          order={order}
          onSuccess={({ product }) => {
            setLast([product, order ? 'updated' : 'created']);
            setStep(form_mode.nav);
            if (order?.id) history.push(routeUrl('tender-edit-orders', params));
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        />
      )}
    </div>
  );
});
export default withRouteX({
  name: 'tender-edit-orders',
})(({ params: { mode } }) => <Orders mode={mode} />);

type OnSuccess = (p: { product: string }) => void;
interface IFormView {
  onSuccess: OnSuccess;
  tender: Tender;
  order?: Order;
}
const FormView = ({ tender, order, onSuccess }: IFormView) => {
  const [schema, setSchema] = useMountedState<Struct.Schema>();
  const [loading, setLoading] = useMountedState(false);
  const [prodState, setProduct] = useState(order?.product.id);
  const tags = useStoreState(state => state.auth.solutionTags);

  const tagIdArray = tags.map(tag => tag.id);
  const [productGroupChoices, setProductGroupChoices] = useState(tagIdArray);
  const product = order?.product.id || prodState;
  const products = useStoreState(state =>
    state.auth.tradableProducts(tender.ordertype.order)
  );

  useEffect(() => {
    setProductGroupChoices(['ungrouped', ...tags.map(tag => tag.id)]);
  }, [tags, setProductGroupChoices]);

  const productFilteredByGroup =
    tags.length === 0
      ? products
      : R.filter(
          product =>
            product.tags.length > 0
              ? product.tags.some(tag => productGroupChoices.includes(tag.id))
              : productGroupChoices.includes('ungrouped'),
          products
        );

  useEffect(() => {
    (async () => {
      if (product) {
        setLoading(true);

        const result = await services.schemas.order({
          tender: tender.id,
          order: order?.id,
          product,
        });

        setLoading(false);

        if (result?.success) {
          setSchema(result.data);
        } else {
          setLoading(false);
          toast.error('An error occurred. Please try again later.');
        }
      }
    })();
  }, [tender.id, order?.id, product]);

  const choices = useMemo(
    () =>
      R.toPairs(
        R.groupBy(
          R.pathOr('', ['owner', 'name']),
          productFilteredByGroup.filter(x => x.tender)
        )
      ).map(([group, prods]) => ({
        key: group,
        label: (
          <FormattedMessage
            id="tender-create-order-product-field-group-subheading"
            description="Placeholder for Product on tenders create/edit order page"
            defaultMessage="Products of {group}"
            values={{ group: group }}
          />
        ),
        value: prods.map(({ id, name }) => ({
          key: id,
          value: id,
          label: name,
        })),
      })),
    [productFilteredByGroup]
  );

  const productField = new SelectField({
    name: 'product',
    label: 'Product',
    required: true,
    props: {
      placeholder: (
        <FormattedMessage
          id="tender-create-order-product-field-placeholder"
          description="Placeholder for Product on tenders create/edit order page"
          defaultMessage="Please select a product"
        />
      ),
      disabled: loading || !!order,
      value: order?.product.id || product,
      onChange(id: string) {
        setProduct(id);
      },
    },
    choices,
  });

  return (
    <div className="nui-form order-form">
      <Form.Item>
        <ProductTagsFilter
          productGroupChoices={productGroupChoices}
          setProductGroupChoices={setProductGroupChoices}
          tags={tags}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormattedMessage
            id="tender-create-order-product-field"
            description="Label for Product on tenders create/edit order page"
            defaultMessage="Select a product"
          />
        }
        required={true}
      >
        {productField.renderUnbound()}
      </Form.Item>
      {loading ? (
        <Loading size="large" />
      ) : schema ? (
        <OrderForm
          tender={tender}
          order={order}
          product={products.find(p => p.id === product)}
          schema={schema}
          onSuccess={() => {
            const name = products.find(p => p.id === product)?.name || '';
            onSuccess({ product: name });
          }}
        />
      ) : null}
    </div>
  );
};
