import { useStoreState } from '~/store';
import { allowRolesIf } from 'Components/Authorised';
import * as R from 'ramda';

export function useCustomers() {
  const solutionSettings = useStoreState(state => state.auth.solutionSettings);
  const isSolutionOwner = useStoreState(state => state.auth.isSolutionOwner);

  const hasCustomers = () =>
    R.path(['matchinglevel'], solutionSettings) !== 'commodity' &&
    isSolutionOwner;

  return {
    _acl: {
      view: allowRolesIf('guest', 'trader', 'manager')(hasCustomers),
      add: allowRolesIf('manager')(hasCustomers),
      edit: allowRolesIf('manager')(hasCustomers),
      delete: allowRolesIf('manager')(hasCustomers),
    },
  };
}
