import React, { useState, useEffect } from 'react';
import { api } from '~/api';
import { toast } from 'react-toastify';
import { useSharedCustomer } from '..';
import {
  Table,
  Loading,
  Alert,
  Form,
  Button,
  Modal,
  Tooltip,
} from 'Components/nui';
import {
  mergeSchema,
  normalizeChoices,
  submitError,
} from 'Components/form/utils';
import { LoadingState } from 'Components/Layout';
import { ShowResult } from 'Components/Result';
import { FormWrapper } from 'Components/form';
import { promisify } from 'es6-promisify';
import * as R from 'ramda';
import { useAsync } from 'react-use';
import moment from 'moment-timezone';

const submitSuccess = (result, submitState) => (
  <div className="service-fee-alert mb-30">
    <Alert hasicon type="success">
      <p className="align-center">
        Successfully added a new service fee.{' '}
        <Button
          type="nui-button-link"
          size="small"
          className="inline-block nui-button-link mt--2"
          onClick={() => {
            submitState.setResult();
          }}
        >
          Add another
        </Button>
      </p>
    </Alert>
  </div>
);

const getFormWrapper = ({ form, initialData, schema, customer }) => {
  const rateLabel =
    form.getFieldValue('premiumtype') === 'percent'
      ? 'Rate, %'
      : `Rate, ${R.path(['currency', 'code'], customer)}/Tonne`;

  const fields = [
    {
      key: 'name',
      name: 'name',
      label: 'Name',
      type: 'Input',
    },
    {
      key: 'premiumtype',
      name: 'premiumtype',
      label: 'Type',
      choices: normalizeChoices,
    },
    {
      key: 'rate',
      name: 'rate',
      label: rateLabel,
      initialValue: 0,
      step: 0.01,
      min: 0.01,
    },
  ];

  return new FormWrapper(form, initialData, mergeSchema(schema, fields));
};

const PForm = ({ form, schema, submitState, customer, setRates }) => {
  const formWrapper = getFormWrapper({
    form,
    schema,
    customer,
  });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }
    submitState.setLoading(true);
    const data = formWrapper.serialize();

    try {
      const result = await api.getData(
        { type: 'customers', id: `${customer.id}/premiums`, getResult: x => x },
        null,
        { method: 'post', data }
      );
      submitState.setResult(result);
      setRates(state => R.append(result.premium, state));
    } catch (err) {
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        err
      );
      if (errors) {
        formWrapper.setErrors(errors);
      } else {
        submitState.setResult(err);
      }
    }
    submitState.setLoading(false);
  }

  return (
    <Form onSubmit={handleSubmit}>
      {formWrapper.render()}
      <Button
        type="secondary"
        htmlType="submit"
        className="m-0 mt-0"
        loading={submitState.loading}
      >
        Save
      </Button>
    </Form>
  );
};

const WrappedForm = Form.create()(PForm);

export default () => {
  const [customer] = useSharedCustomer();
  const [rates, setRates] = useState(null);
  const [delId, setDelId] = useState();

  const deleteRate = async () => {
    try {
      await api.deleteData(`/customers/${customer.id}/premiums/${delId}`);
    } catch (error) {
      toast.error('Error deleting service fee');
    }
    setDelId(null);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await api.getData2(
          `/customers/${customer.id}/premiums`
        );
        setRates(response.premiums);
      } catch (error) {
        toast.error('Error fetching service fee');
      }
    };
    if (customer) fetch();
  }, [customer, delId]);

  const state = useAsync(() => {
    return api.getData({
      type: 'customers',
      id: `${customer.id}/premiums/schema`,
    });
  }, [customer]);

  const columns = [
    {
      title: 'Name',
      key: 'name',
      // className: 'col-division',
      render: (text, record, index) => (
        <div className="td-content">{record.name}</div>
      ),
    },
    {
      title: 'Type',
      key: 'type',
      render: (text, record, index) => (
        <div className="td-content">
          {R.pathOr('n/a', ['type', 'name'], record)}
        </div>
      ),
    },
    {
      title: 'Rate',
      key: 'rate',
      render: (text, record, index) => (
        <div className="td-content">
          {R.path(['rate', 'val'], record)} {R.path(['rate', 'unit'], record)}
        </div>
      ),
    },
    {
      title: 'Created at',
      key: 'created',
      render: (text, record, index) => (
        <div className="td-content">
          {moment(record.created).format('DD MMMM YYYY HH:mm')}
        </div>
      ),
    },
    {
      title: '',
      key: 'delete',
      render: (text, record, index) => {
        if (delId === record.id) {
          return (
            <div className="td-content">
              <Loading size="small" />
            </div>
          );
        }
        return (
          <div className="td-content">
            <Tooltip
              title={
                <span className="icon-tooltip">
                  <span className="icon-info-circled"></span>
                  Delete this service fee
                </span>
              }
            >
              <Button
                htmlType="button"
                className="nui-reverse pl-5 pr-5"
                onClick={() => setDelId(record.id)}
              >
                <span className="icon-trash"></span>
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  if (rates === null) return <Loading size="medium" />;

  return (
    <>
      {delId && (
        <Modal size="small" close={() => setDelId(null)}>
          <h2>Warning</h2>
          <p>Are you sure you want to delete this service fee?</p>
          <hr />
          <div className="button-set">
            <Button htmlType="button" className="mr-10" onClick={deleteRate}>
              Yes
            </Button>
            <Button
              htmlType="button"
              type="reverse"
              onClick={() => setDelId(null)}
            >
              No
            </Button>
          </div>
        </Modal>
      )}

      <div className="full-page-content">
        <div className="generic-table light">
          <div>
            {rates.length === 0 ? (
              <div className="service-fee-alert mb-45">
                <Alert hasicon type="info" className="align-center">
                  There are currently no service fees set up.
                </Alert>
              </div>
            ) : (
              <Table
                className="table-wrapper mb-20"
                columns={columns}
                data={R.sortWith([R.descend(R.path(['created']))])(rates)}
              />
            )}
          </div>

          <ShowResult renderSuccess={submitSuccess} renderError={submitError}>
            {submitState => (
              <div className="wrapper service-fees">
                <div className="nui-form order-form inset-form mb-20">
                  <div className="nui-row">
                    <h3 className="mt--10">Add a service fee</h3>
                    <LoadingState state={state}>
                      {schema => (
                        <WrappedForm
                          key="customer-service-fee"
                          schema={schema}
                          submitState={submitState}
                          customer={customer}
                          setRates={setRates}
                        />
                      )}
                    </LoadingState>
                  </div>
                </div>
              </div>
            )}
          </ShowResult>
        </div>
      </div>
    </>
  );
};
