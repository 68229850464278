import React, { useState, useEffect } from 'react';
import { api } from '~/api';
import { useStoreState } from 'easy-peasy';
import * as R from 'ramda';
import moment from 'moment-timezone';
import { InputNumber, Select, Form } from 'antd';
import ErrorBoundary from 'Components/ErrorBoundary';
import { useSiteTitle } from '~/hooks';
import { useToggle } from 'react-use';

const { Option } = Select;

const displayDate = date => (date ? new moment(date).format('LLL') : null);

async function getRates(solutionId) {
  return api.getData(
    {
      type: 'solutions',
      id: `${solutionId}/exchangerates`,
      getResult: R.propOr([], 'exchangerates'),
    },
    { limit: 10 }
  );
}

async function updateRate(solutionId, currency, rate) {
  return api.getData(
    {
      type: 'solutions',
      id: `${solutionId}/exchangerates`,
      getResult: x => x,
    },
    null,
    {
      method: 'post',
      data: { currency, rate },
    }
  );
}

async function getHistory(solutionId, currency) {
  return api.getData(
    {
      type: 'solutions',
      id: `${solutionId}/exchangerates/${currency}`,
      getResult: R.identity,
    },
    { limit: 10 }
  );
}

function useExchange() {
  const [rates, setRates] = useState([]);
  const [shouldUpdate, setUpdate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const solution = useStoreState(state => state.auth.solution);

  useEffect(() => {
    if (!loading && shouldUpdate) {
      setLoading(true);
      getRates(solution.id)
        .then(response => {
          setRates(response);
        })
        .catch(e => {
          setError(e);
          console.log(error, e);
        })
        .then(() => {
          setLoading(false);
          setUpdate(false);
        });
    }
  }, [loading, shouldUpdate, rates]);

  function update() {
    setUpdate(true);
  }

  async function post(rate, currency) {
    const result = await updateRate(solution.id, currency, rate);
    update();
    return result;
  }

  return { rates, update, post };
}

function useExchangeHistory(exchange) {
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const solution = useStoreState(R.path(['auth', 'solution']));

  const update = async () => {
    if (!exchange) return;

    setLoading(true);
    try {
      const result = await getHistory(solution.id, exchange);
      setHistory(result);
      setLoading(false);
      return result;
    } catch (error) {
      console.log(error);
      setLoading(false);
      throw error;
    }
  };

  useEffect(() => {
    if (exchange && !loading) {
      update();
    }
  }, [exchange]);

  return { ...history, loading, update };
}

const ViewExchange = ({ exchange }) => {
  const rate = exchange.rate ? exchange.rate.toFixed(4) : 'N/A';

  return (
    <div className="view-exchange">
      <span className="exchange-currency">
        {R.propOr(null, 'pair', exchange)}
      </span>
      <span className="exchange-rate">{rate}</span>
    </div>
  );
};

function SetExchange({ exchange, history }) {
  const [loading, setLoading] = useState(true);
  const [rate, setRate] = useState(0);
  const [currency, setCurrency] = useState('');
  const user = useStoreState(state => state.auth.user);
  const precision = 4;

  const findEx = value =>
    R.find(R.pathEq(['currency', 'code'], value), exchange.rates);

  const updateState = () => {
    const ex = findEx(currency) || R.propOr({}, 0, exchange.rates);
    const code = R.pathOr('', ['currency', 'code'], ex);
    setCurrency(code);
    setRate(R.propOr(0, 'rate', ex));
    history.setCurrency(code);
  };

  useEffect(() => {
    if (!exchange.loading) {
      updateState();
      setLoading(false);
    }
  }, [exchange]);

  const onChangeRate = value => {
    setRate(value);
  };

  const onChangeCurrency = value => {
    setCurrency(value);
    setRate(R.propOr(0, 'rate', findEx(value) || {}));
    history.setCurrency(value);
  };

  const onSubmit = async e => {
    e.stopPropagation();
    setLoading(true);
    try {
      await exchange.post(rate, currency);
      history.update();
    } catch (e) {
      // Just set the rate to an error for now
      setRate('Error');
    }
  };

  return (
    <div className="set-exchange">
      <div className="currency">
        <label>Currency</label>
        <Select
          value={currency}
          onChange={onChangeCurrency}
          defaultValue={currency}
        >
          {exchange.rates.map(rate => (
            <Option
              value={R.path(['currency', 'code'], rate)}
              key={R.prop('pair', rate)}
            >
              {R.prop('pair', rate)}
            </Option>
          ))}
        </Select>
      </div>
      <div className="rate">
        <label>Rate</label>
        <Form.Item className="currency-rate">
          <InputNumber
            min={0}
            step={1 / 10 ** precision}
            precision={precision}
            decimalSeparator={R.prop('numeric_decimal', user)}
            onChange={onChangeRate}
            value={rate}
            disabled={loading}
          />
        </Form.Item>
      </div>
      <div className="submit">
        <button
          className="button primary"
          onClick={onSubmit}
          disabled={loading}
        >
          {loading ? 'Updating...' : 'Update'}
        </button>
      </div>
    </div>
  );
}

const ExchangeHistory = props => {
  const [open, toggle] = useToggle(false);
  const { history = [], pair = '', loading } = props.history;

  if (loading) return 'Loading...';
  if (!history.length) return 'No history available.';

  return (
    <div className="exchange-history">
      {open ? (
        <>
          <button className="hideme" onClick={toggle}>
            <span className="arrow" /> Hide history
          </button>
          <table>
            <thead>
              <tr>
                <th>
                  Rate <span className="cur">({pair})</span>
                </th>
                <th>Created on</th>
              </tr>
            </thead>
            <tbody>
              {history.map(rate => (
                <tr key={R.prop('id', rate)}>
                  <td>{R.prop('rate', rate).toFixed(4)}</td>
                  <td>{displayDate(R.prop('created', rate))}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <button onClick={toggle}>
          <span className="arrow"></span> Show history
        </button>
      )}
    </div>
  );
};

export default () => {
  const can = useStoreState(state => state.auth.canEditExchangeRates);
  const [histCurrency, setHistCurrency] = useState('');
  const exchange = useExchange();
  const history = useExchangeHistory(histCurrency);

  useSiteTitle('Exchange rates');

  if (!can) return null;

  return (
    <div className="exchange-rates mb-80">
      <h3>Exchange rates</h3>

      <div className="exchange-info">
        <div className="current">
          {R.propOr([], ['rates'], exchange).map(e => (
            <ViewExchange exchange={e} key={R.prop('pair', e)} />
          ))}
        </div>
        <ErrorBoundary>
          <SetExchange
            exchange={exchange}
            history={{ ...history, setCurrency: setHistCurrency }}
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <ExchangeHistory history={history} />
        </ErrorBoundary>
      </div>
    </div>
  );
};
