import type Struct from 'struct';
import type { Tender, Order } from '~/models/tenders';
import React, { useMemo, useState } from 'react';
import { routeUrl, withRouteX, withPreload } from '~/router';
import { GenericTable } from 'Components/nui/Table';
import * as Data from 'Components/Data';
import { Link } from 'react-router-dom';
import { capitalize } from '~/utils';
import { useMountedState } from '~/hooks';
import { Button, Modal, Tooltip, Alert } from 'Components/nui';
import { toast } from 'react-toastify';
import * as services from '~/services/tenders';
import { FormattedMessage } from 'react-intl';
import * as R from 'ramda';

type TenderProp = Record<'tender', Tender>;
type OrderProp = Record<'order', Order>;
type AllOrdersProp = Record<'orders', Order[]>;

const Trades = withPreload({
  route: 'tender-trades',
  preload: services.observe.orders(),
})(({ data: { tender, orders } }) => {
  return (
    <div className="full-page-content">
      <div className="tender2-trades-header">
        <hr className="m-0 mb-20" />
        <h2>
          <FormattedMessage
            id="tender-finalise-review-heading"
            description="Heading for review section on tender finalise page"
            defaultMessage="Review trades and finalise"
          />
        </h2>
      </div>
      <div className="tender-trades-summary">
        <TenderSummary tender={tender} orders={orders} />
        {tender.status !== 'archived' && (
          <FinaliseTender tender={tender} orders={orders} />
        )}
      </div>
      <TradesTable tender={tender} orders={orders} />
    </div>
  );
});
export default withRouteX({ name: 'tender-trades' })(Trades);

const TenderSummary = ({ tender, orders }: TenderProp & AllOrdersProp) => {
  const totalVal = useMemo(
    () => orders.reduce((a, b) => b.trades.value.add(a), {} as Struct.Price),
    [orders]
  );

  const indexOrdersWithTBDValue = orders.filter(
    order =>
      order.trades?.value.val === 0 && order?.index && order.trades.length
  );

  return (
    <div className="tender2-summary">
      <h3>
        <FormattedMessage
          id="tender-finalise-tender-summary-heading"
          description="Heading for tender summary section on tender finalise page"
          defaultMessage="Tender summary"
        />
      </h3>
      <Data.List>
        <Data.Item
          title={
            <FormattedMessage
              id="tender-finalise-tender-summary-field-name"
              description="Field name for tender summary section on tender finalise page"
              defaultMessage="Name"
            />
          }
        >
          {tender.name}
        </Data.Item>
        <Data.Item
          title={
            <FormattedMessage
              id="tender-finalise-tender-summary-field-total-tender-value"
              description="Field total tender value for tender summary section on tender finalise page"
              defaultMessage="Total tender value"
            />
          }
        >
          <Data.PriceTicker title="" value={totalVal} />
          {indexOrdersWithTBDValue.length > 0 && (
            <span className="trade-tbd">
              +{' '}
              <span className="tbd-value">
                {indexOrdersWithTBDValue.length}
              </span>{' '}
              <FormattedMessage
                id="tender-finalise-tender-summary-field-trades"
                description="Field Trades for tender summary section on tender finalise page"
                defaultMessage="Trades"
              />{' '}
              <Tooltip
                placement="bottom"
                title={
                  <span className="icon-tooltip">
                    <span className="icon-info-circled" />
                    <strong className="all-black block">
                      <FormattedMessage
                        id="tender-finalise-tender-summary-field-text"
                        description="Text tender summary section on tender finalise page"
                        defaultMessage="To be determined"
                      />
                    </strong>
                    <span>
                      <FormattedMessage
                        id="tender-finalise-tender-summary-field-text-index"
                        description="Text for index tender summary section on tender finalise page"
                        defaultMessage="Index-based pricing date has not been reached."
                      />
                    </span>
                  </span>
                }
              >
                <span className="tbd">
                  <FormattedMessage
                    id="tender-finalise-tender-summary-field-text-tbd"
                    description="Text tbd for summary section on tender finalise page"
                    defaultMessage="TBD"
                  />
                </span>{' '}
                <span className="show-help inline-block" />
              </Tooltip>
            </span>
          )}
        </Data.Item>
      </Data.List>
    </div>
  );
};

const FinaliseTender = ({ tender, orders }: TenderProp & AllOrdersProp) => {
  const [isOpen, setIsOpen] = useMountedState(false);
  const [loading, setLoading] = useMountedState(false);
  const [displayError, setDisplayError] = useState(false);

  const countersLength = orders.reduce(
    (acc, current) => acc + current.counters.length,
    0
  );
  const tradesLength = orders.reduce(
    (acc, current) => acc + current.trades.length,
    0
  );

  const reserveNotMetOrders = orders.filter(
    o => !R.isNil(o.reserve.reserve_met) && o.reserve.reserve_met === false
  );

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const handleFinaliseClick = () => {
    if (
      countersLength > 0 &&
      reserveNotMetOrders.length === 0 &&
      tradesLength === 0
    ) {
      setDisplayError(true);
    } else {
      open();
    }
  };

  async function onFinalise() {
    setLoading(true);
    const response = await services.actions.finalise(tender.id);
    if (response?.success) {
      toast.success(tender.name + ' has been finalised');
    } else if (response?.validate) {
      toast.error(capitalize(response.validate));
    } else if (response?.errors) {
      for (const e of response.errors) toast.error(e);
    }
    setLoading(false);
    close();
  }

  return (
    <div className="finaliseme">
      <Button onClick={handleFinaliseClick}>Finalise tender</Button>
      {displayError && (
        <Alert className="finalise-error-info" type="error" hasicon>
          <FormattedMessage
            id="tender-finalisation-warning-untraded"
            description="Warning message for when tender finalisation still has untraded offers/bids"
            defaultMessage="This tender has {count, plural, =1 {an} other {}} untraded {orderType, select, offer {{count, plural, =1 {offer} other {offers}}} bid {{count, plural, =1 {bid} other {bids}}} other{{orderType}}}, please review and trade them before finalising"
            values={{
              count: countersLength - tradesLength,
              orderType: tender.ordertype.counter,
            }}
          />
        </Alert>
      )}
      {isOpen && (
        <Modal size="small" close={close}>
          <h2>
            <FormattedMessage
              id="tender-finalise-modal-heading"
              description="Heading for finalise modal on tender finalise page"
              defaultMessage="Confirm"
            />
          </h2>
          <p>
            <FormattedMessage
              id="tender-finalise-modal-text"
              description="Text for finalise modal on tender finalise page"
              defaultMessage="Once <strong>{name}</strong> is finalised, participants will be notified and you will not be able to complete any further trades."
              values={{
                strong: chunks => (
                  <strong className="all-black">{chunks}</strong>
                ),
                name: tender.name,
              }}
            />
          </p>
          <hr className="mt-15" />
          <div className="button-set">
            <Button loading={loading} disabled={loading} onClick={onFinalise}>
              <FormattedMessage
                id="tender-finalise-modal-button-confirm"
                description="Button confirm for finalise modal on tender finalise page"
                defaultMessage="Confirm"
              />
            </Button>
            <Button
              loading={loading}
              disabled={loading}
              type="simple"
              onClick={close}
            >
              <FormattedMessage
                id="tender-finalise-modal-button-close"
                description="Button Close for finalise modal on tender finalise page"
                defaultMessage="Close"
              />
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

const ViewOrder = ({ tender, order }: TenderProp & OrderProp) => (
  <Link
    to={routeUrl('tender-counter-orders', {
      tenderId: tender.id,
      orderId: order.id,
    })}
  >
    <FormattedMessage
      id="tender-finalise-table-button-view"
      description="Button View for table on tender finalise page"
      defaultMessage="View {orderType, select, offer {offer} bid {bid} other {{orderType}}}"
      values={{ orderType: order.ordertype }}
    />
  </Link>
);

export const TradesTable = ({ tender, orders }: TenderProp & AllOrdersProp) => {
  return (
    <GenericTable
      data={orders.sort((a, b) => a.created.valueOf() - b.created.valueOf())}
      rowKey={o => o.id}
      columns={[
        {
          name: 'view-order',
          className: 'col-product shaded-right',
          title: (
            <FormattedMessage
              id="tender-finalise-table-column-heading-product"
              description="Heading for Column Product for table on tender finalise page"
              defaultMessage="Product"
            />
          ),
          render: (_, order) => (
            <>
              <Data.ProductLink product={order.product} />
              <span className="small">
                <ViewOrder tender={tender} order={order} />
              </span>
            </>
          ),
        },
        {
          name: 'num-counters',
          title: (
            <FormattedMessage
              id="tender-finalise-table-column-heading-counters"
              description="Heading for Column Offers/Bids for table on tender finalise page"
              defaultMessage="{counterName, select, offer{Offers} bid{Bids} other{{counterName}s}}"
              values={{ counterName: tender.ordername.counter }}
            />
          ),
          render: (_, order) => order.counters.length,
        },
        {
          name: 'num-trades',
          title: (
            <FormattedMessage
              id="tender-finalise-table-column-heading-trades"
              description="Heading for Column Trades for table on tender finalise page"
              defaultMessage="Trades"
            />
          ),
          render: (_, order) =>
            order.counters.length ? (
              order.trades.length
            ) : (
              <FormattedMessage
                id="generic-not-applicable"
                description="Text N/A"
                defaultMessage="N/A"
              />
            ),
        },
        {
          name: 'total-volume',
          title: (
            <FormattedMessage
              id="tender-finalise-table-column-heading-total-volume"
              description="Heading for Column Total volume for table on tender finalise page"
              defaultMessage="Total volume"
            />
          ),
          render: (_, order) =>
            order.trades.length ? (
              <Data.VolumeTicker value={order.trades.volume} title="" />
            ) : (
              <FormattedMessage
                id="tender-finalise-table-total-volume-cell-text"
                description="Text for cell in Total volume column for table on tender finalise page"
                defaultMessage="No trades"
              />
            ),
        },
        {
          name: 'total-value',
          title: (
            <FormattedMessage
              id="tender-finalise-table-column-heading-total-value"
              description="Heading for Column Total value for table on tender finalise page"
              defaultMessage="Total value"
            />
          ),
          render: (_, order) =>
            order.trades.length ? (
              order?.index ? (
                <Tooltip
                  placement="bottom"
                  title={
                    <span className="icon-tooltip">
                      <span className="icon-info-circled" />
                      <strong className="all-black block">
                        <FormattedMessage
                          id="tender-finalise-table-total-volume-cell-tooltip-heading"
                          description="Heading for tooltip in  cell in Total value column for table on tender finalise page"
                          defaultMessage="To be determined"
                        />
                      </strong>
                      <span>
                        <FormattedMessage
                          id="tender-finalise-table-total-volume-cell-tooltip-text"
                          description="Text for tooltip in  cell in Total value column for table on tender finalise page"
                          defaultMessage="Index-based pricing date has not been reached."
                        />
                      </span>
                    </span>
                  }
                >
                  <span className="tbd all-black bold">
                    <FormattedMessage
                      id="tender-finalise-table-total-value-cell-text"
                      description="Text tbd for cell in Total value column for table on tender finalise page"
                      defaultMessage="TBD"
                    />
                  </span>{' '}
                  <span className="show-help inline-block" />
                </Tooltip>
              ) : (
                <Data.PriceTicker value={order.trades.value} title="" />
              )
            ) : (
              <FormattedMessage
                id="generic-not-applicable"
                description="Text N/A"
                defaultMessage="N/A"
              />
            ),
        },
        {
          name: 'wap',
          title: (
            <FormattedMessage
              id="tender-finalise-table-column-heading-weighted-average-price"
              description="Heading for Column Weighted average price for table on tender finalise page"
              defaultMessage="Weighted average price"
            />
          ),
          render: (_, order) =>
            order.trades.length ? (
              <Data.PriceTicker value={order.trades.wap} title="" />
            ) : (
              <FormattedMessage
                id="generic-not-applicable"
                description="Text N/A"
                defaultMessage="N/A"
              />
            ),
        },
      ]}
    />
  );
};
