import React from 'react';
import { useSharedOrder } from '.';
import { Currencies, OrderTrades } from 'Components/Lists';
import Partner from 'Pages/MarketRow/Partner';
import LoadingDetails from 'Components/Lists/LoadingDetails';
import * as R from 'ramda';
import ExtraDetails from 'Components/Lists/ExtraDetails';
import Comment from 'Components/Lists/Comment';
import OrderDetails from 'Components/Lists/OrderDetails';
import Documents from 'Components/Lists/Documents';

export default () => {
  const [order] = useSharedOrder();

  if (!order) return null;

  return (
    <div className="detail-lists">
      <div className="list-box-container">
        <div className="list-box double has-unit-box">
          <OrderDetails order={order} />
        </div>

        {order.attachments && order.attachments.length > 0 && (
          <div className="list-box">
            <Documents attachments={order.attachments} />
          </div>
        )}

        <div className="list-box">
          <OrderTrades order={order} />
        </div>
      </div>
      <hr className="m-0" />
      <div className="list-box-container">
        {order.hascurrencies && (
          <div className="list-box double">
            <Currencies order={order} />
          </div>
        )}
        <div className="list-box">
          <LoadingDetails
            loading={order.loading}
            unit={R.path(['volume', 'unit'], order)}
          />
        </div>
        <div className="list-box">
          <ExtraDetails record={order} />
        </div>
        <div className="list-box-container block">
          {order.note && (
            <div className="list-box">
              <Comment record={order} />
            </div>
          )}

          {order.partners && (
            <>
              {'consignee' in order.partners && (
                <div className="list-box">
                  <Partner partners={order.partners} type="consignee" />
                </div>
              )}
              {'buyer' in order.partners && (
                <div className="list-box">
                  <Partner partners={order.partners} type="buyer" />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
