import Base from '../Model';
import type {
  DeliveryData,
  DeliveryAttachment,
  DivisionRef,
} from '../modelTypes';
import moment from 'moment-timezone';
import { allowRolesIf } from 'Components/Authorised';
import type { AuthorisationModel } from '../../AuthorisationModel';
import type { State } from 'easy-peasy';
import * as R from 'ramda';

type Auth = State<AuthorisationModel>;

export interface XFile extends File {
  uid: string;
}

function isFile(state: DeliveryAttachment | XFile): state is XFile {
  return !!(state as XFile).uid;
}

export class Attachment extends Base<DeliveryAttachment> {
  private _progress: number;

  constructor(
    data: DeliveryAttachment | XFile,
    division?: DivisionRef,
    progress = 0
  ) {
    if (isFile(data)) {
      super({
        id: data.uid,
        filename: data.name,
        href: '',
        created: moment().format(),
        division: division || {
          id: '',
          href: '',
          shortcode: '',
          name: '',
        },
        label: null,
      });
    } else {
      super(data);
    }
    this._progress = progress;
  }

  get filename() {
    return this.state.filename;
  }

  get href() {
    return this.state.href;
  }

  get created() {
    return moment(this.state.created);
  }

  get division() {
    return this.state.division;
  }

  get progress() {
    return this._progress;
  }

  get _acl() {
    const shippingSoln = R.pathOr(false, ['solutionSettings', 'shipments']);
    const canView = (auth: Auth) => shippingSoln(auth);
    const canEdit = (auth: Auth) =>
      shippingSoln(auth) &&
      !!R.find(R.pathEq(['division', 'id'], this.division.id), auth.roles);

    return {
      view: allowRolesIf('guest', 'trader', 'manager')(canView),
      add: allowRolesIf('trader', 'manager')(canEdit),
      edit: allowRolesIf('trader', 'manager')(canEdit),
      delete: allowRolesIf('trader', 'manager')(canEdit),
    };
  }
}

export default class Delivery extends Base<DeliveryData> {
  get vessel() {
    return this.state.vessel;
  }
  get reference() {
    return this.state.reference;
  }
  get comment() {
    return this.state.comment;
  }
  get partners() {
    return this.state.partners;
  }
  get etd() {
    return this.state.etd && moment(this.state.etd);
  }
  get eta() {
    return this.state.eta && moment(this.state.eta);
  }
  get trades() {
    return this.state.trades;
  }
  get buyer() {
    return this.state.buyer;
  }
  get seller() {
    return this.state.seller;
  }
  get docslink() {
    return this.state.docslink;
  }
  get trackinglink() {
    return this.state.trackinglink;
  }

  get attachments() {
    return this.state.attachments.map(a => new Attachment(a));
  }

  get finalised() {
    return this.state.finalised && moment(this.state.finalised);
  }

  get identifier() {
    return (
      this.reference ||
      this.vessel ||
      (this.etd && this.etd.format('DD/MM/YYYY')) ||
      'Unnamed delivery'
    );
  }

  get hasShipped() {
    return moment.utc().isSameOrAfter(this.etd);
  }

  get totalVolume() {
    return R.reduceBy(
      (acc: number, { val }: { val: number }) => R.add(acc, val),
      0,
      R.prop('unit'),
      this.trades.map(R.prop('volume'))
    );
  }

  get totalValue() {
    return R.reduceBy(
      (acc: number, { val }: { val: number }) => R.add(acc, val),
      0,
      R.prop('currency'),
      this.trades.map(R.prop('value'))
    );
  }

  get attribs() {
    const { trackinglink, docslink, finalised } = this;

    const canView = () => true;
    const canEdit = (auth: Auth) => {
      const myDivision = R.find(
        R.pathEq(['division', 'id'], this.seller.id),
        auth.roles
      );
      return !!myDivision;
    };

    return {
      trackinglink,
      docslink,
      finalised,

      _acl: {
        view: allowRolesIf('guest', 'trader', 'manager')(canView),
        add: allowRolesIf('guest', 'trader', 'manager')(canEdit),
        edit: allowRolesIf('guest', 'trader', 'manager')(canEdit),
        delete: allowRolesIf('guest', 'trader', 'manager')(canEdit),
      },
    };
  }

  form() {
    const {
      attachments,
      creator,
      division,
      trades,
      partners,
      buyer,
      seller,
      ...state
    } = this.state;

    return {
      ...state,
      buyer: partners?.buyer?.id,
      consignee: partners?.consignee?.id,
    };
  }

  get _acl() {
    const canView = () => true;
    const canEdit = (auth: Auth) => {
      const myDivision = R.find(
        R.pathEq(['division', 'id'], this.seller.id),
        auth.roles
      );
      if (myDivision) return !this.hasShipped;
      return false;
    };

    return {
      view: allowRolesIf('guest', 'trader', 'manager')(canView),
      add: allowRolesIf('guest', 'trader', 'manager')(canEdit),
      edit: allowRolesIf('guest', 'trader', 'manager')(canEdit),
      delete: allowRolesIf('guest', 'trader', 'manager')(canEdit),
    };
  }
}
