import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const StateTradableColumn = ColumnTree.defineNode(
  'state-tradeable',
  _context => ({
    title: '',
    key: 'tradeable',
    className: 'col-trade-status',
    render: (text, record, index) => (
      <div className="td-content">
        {record.state === 'tradeable' && (
          <span className="status-tradeable">
            <FormattedMessage
              id="marketplace-table-cell-tradeability-tradeable"
              description="Cell value, tradeability, 'tradeable'"
              defaultMessage="Tradeable"
            />
          </span>
        )}
        {record.state === 'untradeable' && (
          <>
            {record.is_owner ? (
              <span className="status-nottradeable">
                <FormattedMessage
                  id="marketplace-table-cell-tradeability-working"
                  description="Cell value, tradeability, 'working'"
                  defaultMessage="Working"
                />
              </span>
            ) : (
              <span className="status-nottradeable">
                <FormattedMessage
                  id="marketplace-table-cell-tradeability-untradeable"
                  description="Cell value, tradeability, 'untradeable'"
                  defaultMessage="Untradeable"
                />
              </span>
            )}
          </>
        )}
        {record.state === 'viewonly' && (
          <span className="status-viewonly">
            <FormattedMessage
              id="marketplace-table-cell-tradeability-viewonly"
              description="Cell value, tradeability, 'view only'"
              defaultMessage="View only"
            />
          </span>
        )}
        {record.state === 'sfc-tradeable' && (
          <span className="status-sfctradeable">SFC Tradeable</span>
        )}
      </div>
    ),
  })
);
