import React, { useState } from 'react';
import { useSharedOrder } from '.';
import * as R from 'ramda';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'Components/nui';
import { useToggle } from 'react-use';
import { api } from '~/api';
import { toast } from 'react-toastify';
import { useStoreState } from '~/store';
import { FormattedMessage } from 'react-intl';

export default () => {
  const [order] = useSharedOrder();
  const [deleting, setDeleting] = useState(false);
  const [on, toggle] = useToggle();
  const auth = useStoreState(R.prop('auth'));

  const deleteOrder = async () => {
    setDeleting(true);
    await api.getData(
      {
        type: 'orders',
        id: order.id,
      },
      null,
      { method: 'delete' }
    );

    toast.success('The order was cancelled');
    toggle(false);
  };

  if (!order) return null;

  return (
    <div className="order-actions mb-5">
      <div className="actions">
        {!auth.hasRole('guest') && order.status === 'Working' && (
          <div className="button-set align-right">
            <Link
              to={`/orders/${order.pid}/edit`}
              className={classNames('button', 'mb-0', 'primary', 'icon-pencil')}
            >
              <FormattedMessage
                id="vieworder-button-edit"
                description="button for 'Edit order' in View order"
                defaultMessage="Edit {type, select, offer {offer} bid {bid} other {{type}}}"
                values={{ type: order.type }}
              />
            </Link>

            <Button type="reverse" className="mb-0" onClick={toggle}>
              <FormattedMessage
                id="vieworder-button-delete"
                description="button for 'Delete' in View order"
                defaultMessage="Delete"
              />
            </Button>

            {on && (
              <Modal size="small" close={toggle}>
                <h2>
                  <FormattedMessage
                    id="vieworder-deletemodal-header"
                    description="Header for delete modal in View Order"
                    defaultMessage="Warning"
                  />
                </h2>
                <p>
                  <FormattedMessage
                    id="vieworder-deletemodal-helptext"
                    description="helptext for delete modal in View trade"
                    defaultMessage="Are you sure you want to delete the order? You wouldn't be able to undo this operation."
                  />
                </p>

                <hr />
                <div className="button-set">
                  <Button
                    type="primary"
                    onClick={deleteOrder}
                    loading={deleting}
                  >
                    <FormattedMessage
                      id="vieworder-deletemodal-button-yes"
                      description="button for delete modal: yes proceed in View trade"
                      defaultMessage="Yes, proceed"
                    />
                  </Button>
                  <Button type="simple" onClick={toggle}>
                    <FormattedMessage
                      id="vieworder-deletemodal-button-no"
                      description="button for delete modal: no proceed in View trade"
                      defaultMessage="No, go back!"
                    />
                  </Button>
                </div>
              </Modal>
            )}
          </div>
        )}
      </div>
      <div className="status">
        <strong className="all-black mr-10">Status</strong>{' '}
        <span
          className={classNames(
            `status-${order.status.toLowerCase()}`,
            'inline'
          )}
        >
          {order.status}
        </span>
      </div>
    </div>
  );
};
