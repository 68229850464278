export const defaultSetting = {
  biddecline: true,
  brandlogos: false,
  calloffperiod: 28,
  commissioninclusive: false,
  counterbids: true,
  counteroffers: true,
  flatpremiums: false,
  limitacceptance: 0,
  managementaccess: true,
  manualexchange: false,
  marketplace: true,
  marketprivate: false,
  matchinglevel: 'product',
  mulfromaddr: false,
  mulprivate: false,
  openpricebids: false,
  openpriceoffers: true,
  orderquestions: true,
  overview: true,
  participants: false,
  pgprivate: false,
  pgtender: false,
  publicnote: false,
  purchasetender: false,
  qaprivate: true,
  quarters: false,
  ratedpremiums: false,
  shipments: false,
  showexpiry: true,
  solutionproducts: false,
  tender: true,
  tenderfiles: false,
  hasPartners: false,
  tenderreserveprice: false,
  salesordernumber: false,
};
