import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Link, useRouteMatch } from 'react-router-dom';
import { Button, Alert } from 'Components/nui';
import classnames from 'classnames';
import HelpButton from './HelpButton';
import DivisionWarehouse from './DivisionWarehouse';
import * as R from 'ramda';
import { FormattedMessage } from 'react-intl';

export default () => {
  const solutionSettings = useStoreState(state => state.auth.solutionSettings);
  const solutionDivisions = useStoreState(
    state => state.auth.solutionDivisions
  );
  const restrictedAccess = solutionDivisions.some(s => s?.restrictedaccess);
  const divisions = useStoreState(state => state.auth.solutionDivisions);
  const setData = useStoreActions(action => action.market.setData);
  const match = useRouteMatch('/overview');

  useEffect(() => {
    setData({
      division: null,
      updatedDate: null,
    });
  }, [divisions, setData]);

  const overviewAllowed = R.pathOr(true, ['overview'], solutionSettings);

  return (
    <>
      <div className={classnames('nui-row', { 'h1-tabs': overviewAllowed })}>
        {overviewAllowed ? (
          <h1>
            <span className={match === null ? 'active' : ''}>
              <Link to="/">
                <FormattedMessage
                  id="marketplace-headertab-marketplace"
                  description="Marketplace tab on the market overview"
                  defaultMessage="Marketplace"
                />
              </Link>
            </span>
            <span className={match !== null ? 'active' : ''}>
              <Link to="/overview">
                <FormattedMessage
                  id="marketplace-headertab-overview"
                  description="Overview tab on the market overview"
                  defaultMessage="Overview"
                />
              </Link>
            </span>
          </h1>
        ) : (
          <h1>
            <FormattedMessage
              id="marketplace-headertab-marketplace"
              description="Marketplace tab on the market overview"
              defaultMessage="Marketplace"
            />
          </h1>
        )}

        {match === null ? (
          <>
            <div className="mpkey">
              <HelpButton />
            </div>
          </>
        ) : (
          <div className="dds">
            <DivisionWarehouse />
          </div>
        )}
      </div>
      {restrictedAccess && (
        <Alert className="marketplace-restricted-access-warning" type="warning">
          <FormattedMessage
            id="marketplace-alert-restricted-access"
            description="Alert for restricted access warning on marketplace"
            defaultMessage="You are viewing a restricted view of the marketplace where only private orders are accessible. Reach out to <strong>support@nuimarkets.com</strong> if you have any questions."
            values={{ strong: chunks => <strong>{chunks}</strong> }}
          />
        </Alert>
      )}
    </>
  );
};
