import { Data, IContainer, WithId, Meta, ITags, TagMeta } from './types';
import moment from 'moment-timezone';
import { action, computed, Computed, Action } from 'easy-peasy';
import * as R from 'ramda';

const getMetaProp = <K extends keyof Meta>(
  prop: K,
  def: Meta[K]
): Computed<ITags> =>
  computed(
    state => (model: WithId) =>
      R.pathOr(def, ['data', model.id, 'meta', prop], state)
  );
const setMetaProp = <K extends keyof Meta>(
  prop: K
): Action<IContainer<any>, { model: WithId; value: Meta[K] }> =>
  action((state, { model, value }) => {
    if (state.exists(model)) state.data[model.id].meta[prop] = value;
  });
const newMeta = () => ({
  lastModified: moment(0),
  loading: false,
  noContent: true,
  errors: [],
});

const getTagMetaProp = <K extends keyof TagMeta>(
  prop: K,
  def: TagMeta[K]
): Computed<ITags> =>
  computed(
    state => (id: string) => R.pathOr(def, ['data', id, 'meta', prop], state)
  );
const setTagMetaProp = <K extends keyof TagMeta>(
  prop: K
): Action<ITags, { tag: string; value: TagMeta[K] }> =>
  action((state, { tag, value }) => {
    if (state.exists(tag)) state.data[tag].meta[prop] = value;
  });
const newTagMeta = (config?: Partial<TagMeta>) => ({
  lastModified: moment(0),
  loading: false,
  noContent: true,
  errors: [],
  total: 0,
  pages: 0,
  page: 1,
  limit: 10,
  ...config,
});

interface Config<D extends Data> {
  overrides?: Partial<IContainer<D>>;
}
export function createContainer<D extends Data>(
  config: Config<D> = { overrides: {} }
): IContainer<D> {
  const { overrides } = config;

  return {
    data: {},

    tags: {
      data: {},

      create: action((state, tag) => {
        if (!state.data[tag]) {
          state.data[tag] = { pages: {}, meta: newTagMeta() };
        }
      }),
      push: action((state, { tag, value }) => {
        const page = state.page(tag);
        if (!state.data[tag])
          state.data[tag] = { pages: {}, meta: newTagMeta() };
        if (!state.data[tag].pages[page]) state.data[tag].pages[page] = [];
        state.data[tag].pages[page] = value;
      }),
      insert: action((state, { tag, value }) => {
        const page = state.page(tag);
        if (!state.data[tag])
          state.data[tag] = { pages: {}, meta: newTagMeta() };
        if (!state.data[tag].pages[page]) state.data[tag].pages[page] = [];
        state.data[tag].pages[page] = R.uniq(
          R.concat(value, state.data[tag].pages[page])
        );
      }),
      remove: action((state, { tag, value }) => {
        const page = state.page(tag);
        if (state.data[tag]) {
          state.data[tag].pages[page] = R.reject(
            item => R.includes(item, value),
            state.data[tag].pages[page]
          );
          if (!state.data[tag].pages[page].length)
            state.data[tag].meta.noContent = true;
        }
      }),
      reset: action((state, tag) => {
        state.data[tag] = { pages: {}, meta: newTagMeta() };
      }),
      fetch: computed(
        state => tag =>
          R.pathOr([], [tag, 'pages', state.page(tag)], state.data)
      ),

      isLoading: getTagMetaProp('loading', false),
      lastModified: getTagMetaProp('lastModified', moment(0)),
      noContent: getTagMetaProp('noContent', true),
      exists: computed(state => tag => R.not(R.isNil(R.prop(tag, state.data)))),
      total: getTagMetaProp('total', 0),
      pages: getTagMetaProp('pages', 0),
      page: getTagMetaProp('page', 1),
      limit: getTagMetaProp('limit', 10),

      setLoading: setTagMetaProp('loading'),
      setLastModified: setTagMetaProp('lastModified'),
      setNoContent: setTagMetaProp('noContent'),
      setTotal: setTagMetaProp('total'),
      setPages: setTagMetaProp('pages'),
      changePage: setTagMetaProp('page'),
      changeLimit: setTagMetaProp('limit'),
    },

    add: action((state, value) => {
      if (!Array.isArray(value)) value = [value];
      for (const data of value) {
        const meta = R.pathOr(newMeta(), [data.id, 'meta'], state.data);
        state.data[data.id] = { data, meta };
      }
    }),
    remove: action((state, { id }) => {
      delete state.data[id];
    }),

    // Getters
    getModel: computed(
      state => model => R.pathOr(model as D, ['data', model.id, 'data'], state)
    ),
    getTag: computed(
      state => tag => state.tags.fetch(tag).map(id => state.getModel({ id }))
    ),
    getAll: computed(
      state => () => Object.values(state.data).map(R.prop('data'))
    ),

    isLoading: getMetaProp('loading', false),
    lastModified: getMetaProp('lastModified', moment(0)),
    noContent: getMetaProp('noContent', true),
    exists: computed(
      state => model => R.not(R.isNil(R.path(['data', model.id], state)))
    ),

    // Setters
    setLoading: setMetaProp('loading'),
    setLastModified: setMetaProp('lastModified'),
    setNoContent: setMetaProp('noContent'),

    ...overrides,
  };
}
