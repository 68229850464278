import type { ColumnProps } from 'Components/NuiTable';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Order } from '~/store/models';
import YesNo from 'Components/YesNo';
import moment from 'moment-timezone';
import Table from 'Components/NuiTable';

import FNumber from 'Components/FNumber';
import * as R from 'ramda';
import { OrderDiv } from '../components';
import { useStoreState } from '~/store';
import { Button, Modal, Tooltip, Loading } from 'Components/nui';
import { api } from '~/api';
import { useToggle } from 'react-use';
import { capitalize, inArrayIf } from '~/utils';
import { unitsOfMeasure } from '~/utils/const';
import classnames from 'classnames';
import { IndexUnit, PriceIndex } from 'Components/nui/PriceIndex';

interface ICell {
  children: React.ReactNode;
}
const Cell = ({ children, ...props }: ICell) => (
  <td {...props}>
    <div className="td-content">{children}</div>
  </td>
);

interface OTable {
  fetch: () => Order[];
  loading: () => boolean;
  update: () => Promise<void>;
}
interface Pagination {
  total: () => number;
  pages: () => number;
  page: () => number;
  limit: () => number;
  changePage: (c: { value: number }) => void;
  changeLimit: (c: { value: number }) => void;
}
interface IOrderTable {
  orders: OTable;
  pagination: Pagination;
}
export default ({ orders, pagination }: IOrderTable) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const solutionSettings = useStoreState(state => state.auth.solutionSettings);
  const isMarketplaceSolution = solutionSettings.matchinglevel === 'commodity';
  const productById: any = useStoreState(R.path(['auth', 'productById']));
  const canTradeDivisions: any = useStoreState(
    R.path(['auth', 'canTradeDivisions'])
  );
  const solution = useStoreState(state => state.auth.solution);
  const attributes = R.pathOr([], ['attributes'], solution);

  const [orderToDelete, setOrderToDelete] = useState<any>();
  const [isDeleting, setIsdeleting] = useToggle(false);

  const showDeleteModal = (order: any) => {
    setOrderToDelete(order);
  };

  const closeDeleteModal = () => {
    setOrderToDelete(null);
  };

  const deleteOrder = async () => {
    setIsdeleting(true);
    if (orderToDelete) {
      await api.deleteData(`/orders/${orderToDelete.id}`);
      await orders.update();
    }
    setIsdeleting(false);
    setOrderToDelete(null);
  };

  const columns: ColumnProps<Order>[] = [
    {
      title: 'ID',
      key: 'id',
      className: 'col-id expand-text',
      render: (text, order) => (
        <Link to={`/orders/${order.pid}`}>
          <Tooltip
            title={
              <span className="icon-tooltip">
                <span className="icon-info-circled"></span>View order details
              </span>
            }
          >
            {order.pid}
          </Tooltip>
        </Link>
      ),
    },
    {
      title: 'Rev.',
      key: 'rev',
      className: 'col-rev',
      dataIndex: 'revision',
    },
    {
      title: 'Type',
      key: 'type',
      className: 'col-type',
      render: (text, order) => (
        <span
          className={classnames(`type-${order.type.toLowerCase()}`, {
            counteronly: order.counteronly,
          })}
        >
          {order.counteronly && 'SFC '}
          {order.type}
        </span>
      ),
    },
    {
      title: 'Private',
      key: 'private',
      className: 'col-private',
      render: (text, order) => <YesNo yes={order.isPrivate} />,
    },
    {
      title: 'Product',
      key: 'product',
      className: 'col-product shaded-right',
      render: (text, order) => {
        return (
          <>
            {order.attachments && order.attachments.length > 0 && (
              <div className="market-icons">
                <Tooltip
                  title={
                    <span className="icon-tooltip">
                      <span className="icon-info-circled"></span>See attached
                      document(s) for more information.
                    </span>
                  }
                >
                  <a className="icon-attach-circled is-bundle" href="#details">
                    {''}
                  </a>
                </Tooltip>
              </div>
            )}
            <Link
              to={`/products/${order.product.id}/quick`}
              title="View product details"
            >
              <Tooltip
                title={
                  <span className="icon-tooltip">
                    <span className="icon-info-circled"></span>View product
                    details in new window
                  </span>
                }
              >
                {order.product.name}
                <span className="nowrap">
                  &#65279;<span className="icon-link-ext-alt"></span>
                </span>
              </Tooltip>
            </Link>
          </>
        );
      },
    },
    {
      title: 'Division',
      key: 'division',
      className: 'col-division',
      render: (text, order) => {
        return R.pathOr('', ['division', 'name'], order);
      },
    },
    ...inArrayIf(isMarketplaceSolution, {
      title: 'Broker',
      key: 'broker',
      className: 'col-broker',
      render: (text: string, order: Order) =>
        R.pathOr('', ['broker', 'name'], order),
    }),
  ];

  if (attributes.length)
    columns.push({
      title: 'Attributes',
      key: 'attributes',
      className: `col-attributes`,
      render: (_, order) => {
        if (order?.attributes) {
          return Object.keys(order.attributes)
            .sort()
            .map(k => (
              <div key={k} className="attribute-box">
                <span className="tiny-label">{capitalize(k)}</span>
                {R.pathOr('', ['attributes', k, 0, 'name'], order)}
              </div>
            ));
        }
        return [];
      },
    });

  columns.push(
    {
      title: 'Status',
      key: 'status',
      className: 'col-status',
      render: (text, order) => (
        <span className={`status-${order.status.toLowerCase()}`}>
          {order.status}
        </span>
      ),
    },
    {
      title: 'Created at',
      key: 'createdat',
      className: 'col-created',
      render: (text, order) =>
        moment(order.created).format('DD MMM YYYY, HH:mm'),
    },
    {
      title: 'Price',
      key: 'price',
      className: 'col-price',
      children: [
        {
          title: 'Value',
          key: 'pricevalue',
          className: 'col-price-value align-right',
          render: (text, order) => (
            <>
              <PriceIndex order={order} />
              {order.hasCurrencies() && (
                <sup title="Has additional currencies">*</sup>
              )}
            </>
          ),
        },
        {
          title: 'Unit',
          key: 'priceunit',
          className: 'col-price-unit',
          render: (text, order) => <IndexUnit order={order} />,
        },
      ],
    },
    {
      title: 'Volume',
      key: 'volume',
      className: 'col-volume',
      children: [
        {
          title: 'Exec.',
          key: 'volumeexecuted',
          className: 'align-right',
          render: (text, order) =>
            order.volume.executed > 0 ? (
              <FNumber
                value={order.volume.executed}
                decimalCount={order.volumeDecimalCount()}
              />
            ) : (
              ''
            ),
        },
        {
          title: 'Pend.',
          key: 'volumepending',
          className: 'col-pending align-right',
          render: (text, order) =>
            order.volume.pending > 0 ? (
              <>
                <FNumber
                  value={order.volume.pending}
                  decimalCount={order.volumeDecimalCount()}
                />
              </>
            ) : (
              ''
            ),
        },
        {
          title: 'Unit',
          key: 'volumeunit',
          className: 'col-pending',
          render: R.pathOr('', ['volume', 'unit']),
        },
      ],
    },
    {
      title: 'Estimated time of departure',
      key: 'etd',
      className: 'col-etd',
      children: [
        {
          title: 'From',
          key: 'etdfrom',
          render: (text, order) =>
            order.quarteretd || moment(order.etd.from).format('DD MMM YYYY'),
        },
        {
          title: 'To',
          key: 'etdto',
          className: 'col-etd-to',
          render: (text, order) => {
            if (order.quarteretd) return null;

            if (order.deliveryfrequency) {
              const u: string = R.pathOr('', ['volume', 'unit'], order);
              const product = productById(order.product.id);
              const unit: string = product
                ? R.pathOr('', ['loadingunit', 'desc'], product)
                : R.pathOr(u, [u], unitsOfMeasure);

              return (
                <div className="td-content">
                  <span className="tiny-label">Strip order</span>
                  <div className="delivery-volume nowrap">
                    <strong className="all-black">
                      {order.totaldeliveries}
                    </strong>
                    <span className="times-rotate">+</span>
                    <strong className="all-black">
                      {order.volume.delivery_total}
                    </strong>
                    <span className="unit">{unit}</span>
                  </div>
                  <div>
                    <span
                      className={classnames(
                        'icon-clock',
                        'periodic-delivery',
                        order.deliveryfrequency
                      )}
                    >
                      {order.deliveryfrequency}
                    </span>
                  </div>
                </div>
              );
            }

            return moment(order.etd.to).format('DD MMM YYYY');
          },
        },
      ],
    },
    {
      title: 'Splittable',
      key: 'splittable',
      className: 'col-splittable',
      render: (text, order) => <YesNo yes={order.splittable} />,
    }
  );

  if (canTradeDivisions.length > 0) {
    columns.push({
      title: '',
      key: 'edit',
      className: 'col-edit',
      render: (text, record, index) => {
        return record.status.toLowerCase() === 'working' &&
          R.find(R.propEq('shortcode', record.division.shortcode))(
            canTradeDivisions
          ) ? (
          <Link
            to={`/orders/${record.id}/edit`}
            className="nui-button nui-button-icon"
            title="Edit order"
          >
            <span className="icon-pencil"></span>
          </Link>
        ) : null;
      },
    });
    columns.push({
      title: '',
      key: 'delete',
      className: 'col-delete',
      render: (text, record, index) => {
        return record.status.toLowerCase() === 'working' &&
          R.find(
            R.propEq('shortcode', record.division.shortcode),
            canTradeDivisions
          ) ? (
          <Button
            title="Delete order"
            type="reverse"
            icon="trash"
            onClick={() => {
              showDeleteModal(record);
            }}
          />
        ) : null;
      },
    });
  }

  const components = {
    body: {
      cell: Cell,
    },
  };

  return (
    <>
      {orderToDelete && (
        <Modal size="small" close={closeDeleteModal}>
          <h2>Warning</h2>
          <p>
            Are you sure you want to delete order{' '}
            <strong className="all-black">{orderToDelete.pid}</strong>? This
            can&apos;t be undone.
          </p>
          <hr />
          <div className="button-set">
            <Button type="primary" onClick={deleteOrder} loading={isDeleting}>
              Yes, delete it
            </Button>
            <Button type="simple" onClick={closeDeleteModal}>
              Cancel
            </Button>
          </div>
        </Modal>
      )}

      <Table
        className="generic-table expand-table th-rows light"
        expandedRowKeys={expandedRowKeys}
        columns={columns}
        dataSource={orders.fetch()}
        loading={{
          indicator: <Loading className="mb-10 mt-10" size="medium" />,
          spinning: !orders.fetch().length && orders.loading(),
          size: 'large',
        }}
        rowKey={R.prop('id')}
        components={components}
        expandIconAsCell={false}
        expandRowByClick={true}
        expandedRowRender={record => <OrderDiv order={record.old()} />}
        onExpand={(expanded, record) => {
          if (expandedRowKeys.includes(record.id)) {
            setExpandedRowKeys(state => R.filter(x => x !== record.id, state));
          } else {
            setExpandedRowKeys(state => R.append(record.id, state));
          }
        }}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]} - ${range[1]} of ${total} items`,
          pageSize: pagination.limit(),
          total: pagination.total(),
          current: pagination.page(),
        }}
        onChange={page => {
          pagination.changePage({ value: R.propOr(0, 'current', page) });
          pagination.changeLimit({ value: R.propOr(0, 'pageSize', page) });
        }}
        onRow={record => {
          const isExpanded = expandedRowKeys.includes(record.id);
          return {
            title: isExpanded
              ? 'Click row to hide details'
              : 'Click row to view details',
          };
        }}
      />
    </>
  );
};
