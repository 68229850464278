import React, { useEffect, useState } from 'react';
import { api } from '~/api';
import { useDeepCompareEffect, useToggle } from 'react-use';
import { useStoreState } from 'easy-peasy';
import * as R from 'ramda';
import { Loading, Select } from 'Components/nui';
import DropCheck from 'Components/nui/form/DropCheck';
import { useFilterTIData, useSharedFilter, useSharedTIData } from '..';
import moment from 'moment-timezone';

export default () => {
  const filterKeys = ['currency', 'uom', 'type', 'product', 'seller', 'buyer'];

  const [range] = useSharedFilter();
  const [filterNames, setFilterNames] = useFilterTIData();
  const [filter, setFilter] = useState({});

  const [data, setData] = useSharedTIData();
  const [options, setOptions] = useState({
    currency: [],
    uom: [],
    product: [],
    type: [],
    seller: [],
    buyer: [],
  });
  const [availableOptions, setAvailableOptions] = useState({});
  const apiError = data === 'Error';

  const solution = useStoreState(state => state.auth.solution);
  const [loading, toggle] = useToggle(true);

  const periodStart = R.hasPath(['period', 'start'], data)
    ? moment(R.path(['period', 'start'], data))
    : undefined;
  const periodEnd = moment(
    R.pathOr(moment().format('YYYY-MM-DD'), ['period', 'end'], data)
  );
  const periodValidity = R.path(['period', 'validity'], data);

  useEffect(() => {
    let mounted = true;
    if (!range) return;

    (async () => {
      try {
        const data = await api.analytics('trade', [
          solution.id,
          range[0].format('YYYY-MM-DD'),
          range[1].format('YYYY-MM-DD'),
        ]);
        if (mounted) {
          const newData = R.pathOr({}, ['data', 'trade'], data);

          let newFilter = {};
          filterKeys.forEach(key => {
            const val = R.path(['filters', key], newData);
            if (val) {
              newFilter[key] = val;
            }
          });
          setFilter(newFilter);

          let newOptions = {};
          filterKeys.forEach(key => {
            newOptions[key] = R.pathOr([], ['fields', key, 'values'], newData);
          });
          setOptions(newOptions);
        }
      } catch (error) {
        // pass
      }
    })();

    return () => {
      mounted = false;
    };
  }, [solution, range, setFilter, setOptions]);

  useEffect(() => {
    const sellerInFilter = s => filter.seller?.includes(s.key);
    const buyerInFilter = b => filter.buyer?.includes(b.key);
    const productInFilter = p => filter.product?.includes(p.key);
    const _new = {
      currency: R.propOr(
        '',
        'label',
        R.find(R.propEq('key', filter.currency), R.prop('currency', options))
      ),
      uom: R.propOr(
        '',
        'label',
        R.find(R.propEq('key', filter.uom), R.prop('uom', options))
      ),
      type: R.propOr(
        '',
        'label',
        R.find(R.propEq('key', filter.type), R.prop('type', options))
      ),
      product: R.filter(productInFilter, R.prop('product', options))
        .map(p => p.label)
        .join(', '),
      sellers: R.filter(sellerInFilter, R.prop('seller', options))
        .map(s => s.label)
        .join(', '),
      buyers: R.filter(buyerInFilter, R.prop('buyer', options))
        .map(b => b.label)
        .join(', '),
    };
    setFilterNames(_new);
  }, [filter, setFilterNames, options]);

  useEffect(() => {
    let mounted = true;
    toggle(true);
    if (!range) return;

    (async () => {
      try {
        const data = await api.analytics(
          'trade',
          [
            solution.id,
            range[0].format('YYYY-MM-DD'),
            range[1].format('YYYY-MM-DD'),
          ],
          { ...filter }
        );
        if (mounted) {
          const newData = R.pathOr({}, ['data', 'trade'], data);
          setData(newData);
          toggle(false);
        }
      } catch (error) {
        setData('Error');
        // pass
      }
    })();

    return () => {
      mounted = false;
    };
  }, [solution, range, setData, toggle, filter]);

  useDeepCompareEffect(() => {
    const newOptions = R.propOr(
      {},
      'options',
      R.clone(
        R.find(R.propEq('key', filter.currency), R.prop('currency', options))
      )
    );

    const newOptionsUom = R.propOr(
      {},
      'options',
      R.clone(R.find(R.propEq('key', filter.uom), R.prop('uom', options)))
    );

    newOptions.product = R.intersection(
      R.propOr([], 'product', newOptions),
      R.propOr([], 'product', newOptionsUom)
    );
    newOptions.type = R.intersection(
      R.propOr([], 'type', newOptions),
      R.propOr([], 'type', newOptionsUom)
    );

    newOptions.buyer = R.intersection(
      R.propOr([], 'buyer', newOptions),
      R.propOr([], 'buyer', newOptionsUom)
    );

    newOptions.seller = R.intersection(
      R.propOr([], 'seller', newOptions),
      R.propOr([], 'seller', newOptionsUom)
    );

    if (filter.type) {
      const newOptionsType = R.propOr(
        {},
        'options',
        R.clone(R.find(R.propEq('key', filter.type), R.prop('type', options)))
      );

      newOptions.product = R.intersection(
        R.propOr([], 'product', newOptions),
        R.propOr([], 'product', newOptionsType)
      );

      newOptions.buyer = R.intersection(
        R.propOr([], 'buyer', newOptions),
        R.propOr([], 'buyer', newOptionsType)
      );

      newOptions.seller = R.intersection(
        R.propOr([], 'seller', newOptions),
        R.propOr([], 'seller', newOptionsType)
      );
    }

    if (filter.product) {
      const productInFilter = p => filter.product.includes(p.key);
      const productsInFilter = R.filter(
        productInFilter,
        R.prop('product', options)
      );
      const jointBuyer = R.uniq(productsInFilter.flatMap(p => p.options.buyer));
      const jointSeller = R.uniq(
        productsInFilter.flatMap(p => p.options.seller)
      );
      const jointType = R.uniq(productsInFilter.flatMap(p => p.options.type));

      const newOptionsProducts = {
        buyer: jointBuyer,
        seller: jointSeller,
        type: jointType,
      };

      newOptions.buyer = R.intersection(
        R.propOr([], 'buyer', newOptions),
        R.propOr([], 'buyer', newOptionsProducts)
      );

      newOptions.type = R.intersection(
        R.propOr([], 'type', newOptions),
        R.propOr([], 'type', newOptionsProducts)
      );

      newOptions.seller = R.intersection(
        R.propOr([], 'seller', newOptions),
        R.propOr([], 'seller', newOptionsProducts)
      );
    }

    setAvailableOptions(newOptions);
  }, [options, filter]);

  const onCurrencyChange = value => {
    setFilter(state => ({ ...state, currency: value }));
  };

  return (
    !apiError &&
    (loading && R.isEmpty(filter) ? (
      <Loading size="small" />
    ) : (
      !R.isEmpty(data) && (
        <>
          {options.currency.length > 1 && (
            <div className="nui-fieldset currency-filter">
              <label htmlFor="currency">Currency</label>
              <div className="select-holder">
                <Select
                  id="currency"
                  value={filter.currency}
                  onChange={onCurrencyChange}
                >
                  {options.currency.map(item => (
                    <option value={item.key} key={item.key}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          )}

          {options.uom.length > 1 && (
            <div className="nui-field-holder nui-fieldset unit-filter">
              <label htmlFor="unit">Unit</label>
              <div className="select-holder">
                <Select
                  id="unit"
                  value={filter.uom}
                  onChange={value =>
                    setFilter(state => ({ ...state, uom: value }))
                  }
                >
                  {options.uom.map(item => (
                    <option
                      value={item.key}
                      key={item.key}
                      disabled={
                        !R.propOr([], 'uom', availableOptions).includes(
                          item.key
                        )
                      }
                    >
                      {item.label}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          )}

          {options.type.length > 0 && (
            <div className="nui-field-holder nui-fieldset tradetype-filter">
              <label>Trade type</label>
              <div className="select-holder">
                <Select
                  value={filter.type}
                  onChange={value =>
                    setFilter(state =>
                      value
                        ? { ...state, type: value }
                        : R.dissoc('type', state)
                    )
                  }
                >
                  <option value="">No options selected</option>
                  {options.type.map(item => (
                    <option
                      value={item.key}
                      key={item.key}
                      disabled={
                        !R.propOr([], 'type', availableOptions).includes(
                          item.key
                        )
                      }
                    >
                      {item.label}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          )}

          {options.product.length > 0 && (
            <div className="nui-field-holder products-filter">
              <label htmlFor="products">Products</label>

              <DropCheck
                name="products"
                title="All"
                enabledKeys={R.propOr([], 'product', availableOptions)}
                options={options.product.map(item => [item.key, item.label])}
                value={filter.product}
                onChange={(_, values) => {
                  setFilter(state =>
                    values
                      ? { ...state, product: values }
                      : R.dissoc('product', state)
                  );
                }}
              />
            </div>
          )}

          {options.buyer.length > 0 && (
            <div key="buyer-filter" className="nui-field-holder buyers-filter">
              <label htmlFor="buyer">Buyer</label>

              <DropCheck
                name="buyer"
                title="All"
                enabledKeys={R.propOr([], 'buyer', availableOptions)}
                options={options.buyer.map(item => [item.key, item.label])}
                value={filter.buyer}
                onChange={(_, values) => {
                  setFilter(state =>
                    values
                      ? { ...state, buyer: values }
                      : R.dissoc('buyer', state)
                  );
                }}
              />
            </div>
          )}

          {options.seller.length > 0 && (
            <div
              key="seller-filter"
              className="nui-field-holder sellers-filter"
            >
              <label htmlFor="seller">Seller</label>

              <DropCheck
                name="seller"
                title="All"
                enabledKeys={R.propOr([], 'seller', availableOptions)}
                options={options.seller.map(item => [item.key, item.label])}
                value={filter.seller}
                onChange={(_, values) => {
                  setFilter(state =>
                    values
                      ? { ...state, seller: values }
                      : R.dissoc('seller', state)
                  );
                }}
              />
            </div>
          )}

          {loading ? (
            <Loading size="small" />
          ) : (
            <p className="filter-message p-0">
              Showing{' '}
              {R.isNil(filter.product) ? (
                'all available products '
              ) : (
                <>
                  {filter.product?.length} product
                  {filter.product?.length > 1 && 's'} out of{' '}
                  {R.propOr([], 'product', availableOptions).length}{' '}
                </>
              )}
              {filter.currency && (
                <>
                  in <strong>{filterNames.currency}</strong>{' '}
                </>
              )}
              {filter.uom && (
                <>
                  in <strong>{filterNames.uom}</strong>{' '}
                </>
              )}
              {filter.type && (
                <>
                  traded on <strong>{filterNames.type}</strong>{' '}
                </>
              )}
              {filter.buyer && (
                <>
                  by Buyers <strong>{filterNames.buyers}</strong>{' '}
                </>
              )}
              {filter.seller && (
                <>
                  by Sellers <strong>{filterNames.sellers}</strong>{' '}
                </>
              )}
              {range && periodStart && (
                <>
                  for{' '}
                  <strong>
                    {periodStart.format('ll')}
                    {periodStart.format('ll') !== periodEnd.format('ll') && (
                      <> &mdash; {periodEnd.format('ll')}</>
                    )}
                  </strong>{' '}
                  {periodValidity && (
                    <span>
                      (Your date range has been limited to{' '}
                      {periodStart.format('ll')} due to a restriction on your
                      account (max period = {periodValidity}))
                    </span>
                  )}
                </>
              )}
            </p>
          )}
        </>
      )
    ))
  );
};
