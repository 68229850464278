import Button from 'Components/nui/Button';
import { FormattedMessage } from 'react-intl';
import FilterDropdown from './FilterDropdown';
import { FilterTypes, useFilters } from './context';
import FilterChips from './FilterChips';
import { useStoreState } from 'easy-peasy';
import { deduplicateWithMap, invertDivisionType } from '~/utils';

export const getCustomerDivisions = (customer: any) => {
  const divisionType = invertDivisionType(customer.divisiontype);
  return customer.links.map(link => link[divisionType]);
};

const getCustomersDivisions = (customers: any[]) => {
  if (!customers?.length) return [];

  const allDivisions = customers.flatMap(customer =>
    getCustomerDivisions(customer)
  );
  const uniqueDivisions = [
    ...new Map(allDivisions.map(div => [div.id, div])).values(),
  ];

  return uniqueDivisions;
};

const CustomerFilters = ({ data, clearAction }) => {
  const allDivisions = getCustomersDivisions(data);
  const solutionSettings = useStoreState(state => state.auth.solutionSettings);

  const countryOptions = deduplicateWithMap(
    data?.map(customer => ({
      value: customer.country.id,
      label: customer.country.name,
    })),
    'value'
  ).sort((a, b) => a.label.localeCompare(b.label));

  const divisionOptions = allDivisions
    .map(customerDivision => ({
      value: customerDivision.id,
      label: customerDivision.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const { setFilters } = useFilters();

  return (
    <div className="filter-row">
      <FilterChips>
        <FilterDropdown
          id="country"
          label={
            <FormattedMessage
              id="customer-filter-field-country"
              description="Label for 'Country' in Customer filter"
              defaultMessage="Country"
            />
          }
          type={FilterTypes.CHECKBOXES}
          options={countryOptions}
          canSelectAll={true}
        />
        {solutionSettings.matchinglevel === 'product' && (
          <FilterDropdown
            id="division"
            label={
              <FormattedMessage
                id="customer-filter-field-division"
                description="label for 'Division' in Customer filter"
                defaultMessage="Division"
              />
            }
            type={FilterTypes.CHECKBOXES}
            options={divisionOptions}
          />
        )}
        <Button
          type="buttonlink"
          className="filter-reset"
          onClick={() => {
            clearAction && clearAction();
            setFilters({});
          }}
        >
          <FormattedMessage
            id="filter-button-clear-filter"
            description="Button for clear search filters"
            defaultMessage="Clear all filters"
          />
        </Button>
      </FilterChips>
    </div>
  );
};

export default CustomerFilters;
