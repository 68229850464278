import React, { useState } from 'react';
import * as R from 'ramda';
import YesNo from 'Components/YesNo';
import { useToggle } from 'react-use';
import { api } from '~/api';
import { Button, Modal } from 'Components/nui';
import { toast } from 'react-toastify';

export default ({ item, id }) => {
  const [on, toggle] = useToggle(false);
  const [isDeleting, setIsDeleting] = useToggle(false);
  const [isDeleted, setIsDeleted] = useToggle(false);

  const deleteIt = async () => {
    try {
      setIsDeleting(true);
      await api.deleteData(`/routes/${id}/freight/${item.id}`);
      // await orders.update();

      setIsDeleted(true);
      toast.success('The freight item was deleted');
    } catch (error) {
      setIsDeleting(false);
      toggle();
      toast.error('Somtheng went wrong. Please try again later.');
    }
  };

  if (isDeleted) {
    return null;
  }

  return (
    <li>
      <label>
        <dl className="list no-ws">
          {'division' in item && (
            <>
              <dt>Division</dt>
              <dd>{item.division.name}</dd>
            </>
          )}

          <dt>Container</dt>
          <dd>{item.container}</dd>

          <dt>Reefer</dt>
          <dd>
            <YesNo yes={item.reefer} />
          </dd>

          <dt>Incoterm</dt>
          <dd>{item.incoterm}</dd>

          <dt>Rate</dt>
          <dd>
            {R.path(['rate', 'val'], item)} {R.path(['rate', 'unit'], item)}
          </dd>
        </dl>
      </label>

      <div className="">
        <Button
          htmlType="button"
          type="reverse"
          className="nui-button-icon"
          onClick={toggle}
        >
          <span className="icon-trash" />
        </Button>
      </div>

      {on && (
        <Modal size="small" close={toggle}>
          <h2>Warning</h2>
          <p>Are you sure you want to delete the freight item?</p>
          <hr />
          <div className="button-set">
            <Button type="primary" onClick={deleteIt} loading={isDeleting}>
              Yes, delete it
            </Button>
            <Button type="simple" onClick={toggle}>
              Cancel
            </Button>
          </div>
        </Modal>
      )}
    </li>
  );
};
