import Button from 'Components/nui/Button';
import { FormattedMessage } from 'react-intl';
import FilterDropdown from './FilterDropdown';
import { FilterTypes, useFilters } from './context';
import FilterChips from './FilterChips';
import { useStoreState } from 'easy-peasy';
import { DivisionWarehouseFilter } from 'Pages/Market/DivisionWarehouse';
import { PresetForm, PresetsList } from 'Pages/Market/Filter';
import { useFilterOptions } from './useFilterOptions';

const MarketFilters = () => {
  const solutionSettings = useStoreState(state => state.auth.solutionSettings);

  const { setFilters } = useFilters();

  const {
    productOptions,
    groupOptions,
    typeOptions,
    etdOptions,
    publishedDateOptions,
    stateOptions,
    originOptions,
  } = useFilterOptions();

  return (
    <div className="filter-row">
      <FilterChips
        trailing={
          <>
            <PresetForm />
            <PresetsList />
          </>
        }
      >
        {solutionSettings?.matchinglevel !== 'commodity' && (
          <DivisionWarehouseFilter />
        )}
        <FilterDropdown
          id="product"
          label={
            <FormattedMessage
              id="marketplace-filter-field-products"
              description="Label for 'Products' in Marketplace filter"
              defaultMessage="Products"
            />
          }
          type={FilterTypes.CHECKBOXES}
          options={productOptions}
        />
        {groupOptions.length > 0 && (
          <FilterDropdown
            id="group"
            label={
              <FormattedMessage
                id="marketplace-filter-field-product-groups"
                description="label for 'Product groups' in Marketplace filter"
                defaultMessage="Product groups"
              />
            }
            type={FilterTypes.CHECKBOXES}
            options={groupOptions}
          />
        )}
        <FilterDropdown
          id="type"
          label={
            <FormattedMessage
              id="marketplace-filter-field-type"
              description="Label for 'Type' in Marketplace filter"
              defaultMessage="Type"
            />
          }
          type={FilterTypes.CHECKBOXES}
          options={typeOptions}
        />
        <FilterDropdown
          id="etdOption"
          label={
            <FormattedMessage
              id="marketplace-filter-field-etd-short"
              description="label for 'ETD' in Marketplace filter (short)"
              defaultMessage="ETD"
            />
          }
          type={FilterTypes.RADIO}
          options={etdOptions}
          subFieldId="etd"
          subFieldType={FilterTypes.DATE}
        />
        <FilterDropdown
          id="updatedOption"
          label={
            <FormattedMessage
              id="marketplace-filter-field-published"
              description="label for 'Published' in Marketplace filter"
              defaultMessage="Published"
            />
          }
          type={FilterTypes.RADIO}
          options={publishedDateOptions}
          subFieldId="updated"
          subFieldType={FilterTypes.DATE}
        />
        {stateOptions.length > 0 && (
          <FilterDropdown
            id="state"
            label={
              <FormattedMessage
                id="marketplace-filter-field-state"
                description="label for 'State' in Marketplace filter"
                defaultMessage="State"
              />
            }
            type={FilterTypes.CHECKBOXES}
            options={stateOptions}
          />
        )}
        {originOptions.length > 0 && (
          <FilterDropdown
            id="origin"
            label={
              <FormattedMessage
                id="marketplace-filter-field-origin"
                description="label for 'Origin' in Marketplace filter"
                defaultMessage="Origin"
              />
            }
            type={FilterTypes.CHECKBOXES}
            options={originOptions}
          />
        )}
        <Button
          type="buttonlink"
          className="filter-reset"
          onClick={() => setFilters({})}
        >
          <FormattedMessage
            id="filter-button-clear-filter"
            description="Button for clear search filters"
            defaultMessage="Clear all filters"
          />
        </Button>
      </FilterChips>
    </div>
  );
};

export default MarketFilters;
