import React, { useEffect } from 'react';
import type { Order } from '~/models/tenders';
import type { FormComponentProps } from 'antd/lib/form';
import type { WrappedFormUtils } from 'antd/lib/form/Form';
import { Form } from 'antd';
import { InputNumberWidget, SelectWidget } from 'Components/form/widgets';
import { MAX_SAFE_INTEGER } from 'Components/form/utils';
import { Button, Alert } from 'Components/nui';
import { FormattedMessage } from 'react-intl';

type Schema = {
  startprice: { step: number };
  variants: {
    field: {
      fields: {
        price: {
          required: false;
          type: 'Decimal';
        };
        product: {
          required: false;
          type: 'Select';
          choices: [string, string][];
        };
        loading: {
          required: false;
          type: 'Select';
          choices: [string, string][];
        };
      };
    };
  };
};

type InitialOrder = Order['formdata'];
type StateData = {
  index: number;
  id: string;
  product: string;
  price?: number | null;
};
type State = Record<string, StateData>;

const NAME = 'variants' as const;

interface IConfig extends FormComponentProps {
  schema: Schema;
  order: InitialOrder;
}
export default class Variants {
  private schema: Schema;
  private form: WrappedFormUtils<{ variants: State }>;
  private order: InitialOrder;

  constructor({ schema, form, order }: IConfig) {
    this.schema = schema;
    this.form = form;
    this.order = order;

    const initialValue: State = this.order?.variants
      ? Object.fromEntries(
          this.order.variants
            .sort((a, b) => a.product.name.localeCompare(b.product.name))
            .map(({ id, price, product, loading }, index) => [
              id,
              {
                id,
                index,
                product: product.id,
                price: price?.val,
                loading: loading?.id,
              },
            ])
        )
      : {};

    form.getFieldDecorator(NAME, { initialValue });
    // if (!this.values.length) this.create();
  }

  private get state(): State {
    return this.form.getFieldValue(NAME);
  }

  private get values(): StateData[] {
    return Object.values(this.state);
  }

  private get products() {
    return this.schema.variants.field.fields.product.choices.map(
      ([value, label]) => ({ key: value, value, label })
    );
  }
  get skip() {
    return true;
  }

  get name() {
    return NAME;
  }

  private create() {
    let id: string;
    const flag = (next: string) => this.values.find(s => s.id === next);
    do {
      id = (Math.random() * 1e6).toFixed(0);
    } while (flag(id));

    const values = this.values;

    this.form.setFieldsValue({
      variants: {
        ...this.state,
        [id]: { id, product: '', index: values.length },
      },
    });

    return id;
  }

  private onChange(id: string) {
    return (value: StateData) =>
      void this.form.setFieldsValue({
        variants: { ...this.state, [id]: value },
      });
  }

  private onDelete(id: string) {
    return () =>
      void this.form.setFieldsValue({
        variants: Object.fromEntries(
          this.values.filter(v => v.id !== id).map(v => [v.id, v])
        ),
      });
  }

  private choices(product: string) {
    return this.products.filter(
      p =>
        p.value === product ||
        !this.values.map(v => v.product).includes(p.value)
    );
  }

  private loadingChoices(product: string) {
    return (
      this.schema.variants.field.fields.loading.choices[product]?.map(
        ([value, label]) => ({ key: value, value, label })
      ) || []
    );
  }

  serializeField({ variants }: Record<'variants', State>) {
    return Object.fromEntries(
      Object.values(variants).flatMap(({ id, price, product, loading }) =>
        product
          ? [
              [`variants-${id}_price`, price || 0],
              [`variants-${id}_product`, product],
              [`variants-${id}_loading`, loading],
            ]
          : []
      )
    );
  }

  render() {
    const values = this.values;
    return (
      <div className="variants-form">
        <h3 className="variants-title">
          <FormattedMessage
            id="tender-create-order-variants-heading"
            description="Heading for Variants on tenders create/edit order page"
            defaultMessage="Product variants"
          />
        </h3>
        <Alert hasicon type="info">
          <FormattedMessage
            id="tender-create-order-variants-text"
            description="Text body for Variants on tenders create/edit order page"
            defaultMessage="Product variants allow for product sub-categories. To define different variations and prices for this product click on the `Add product variant` button and select the applicable variant and enter the price differential."
          />
        </Alert>
        <div className="inset-form mb-20 p-0">
          {values
            .slice(0)
            .sort((a, b) => a.index - b.index)
            .map(v => (
              <Entry
                key={v.id}
                value={v}
                choices={this.choices(v.product)}
                loadingOptions={this.loadingChoices(v.product)}
                onChange={this.onChange(v.id)}
                schema={this.schema}
                onDelete={v.product.length ? this.onDelete(v.id) : undefined}
              />
            ))}
        </div>
        {!values.find(v => !v.product.length) && (
          <Create onClick={() => void this.create()} />
        )}
      </div>
    );
  }
}

interface IEntry {
  value: StateData;
  choices: { key: string; value: string; label: string }[];
  loadingOptions: { key: string; value: string; label: string }[];
  onChange: (value: StateData) => void;
  schema: Schema;
  onDelete?: () => void;
}
const Entry = ({
  value,
  choices,
  loadingOptions,
  onChange,
  schema,
  onDelete,
}: IEntry) => {
  useEffect(() => {
    // select the first loading option when product is updated
    if (!value.loading && loadingOptions.length === 1) {
      onChange({ ...value, loading: loadingOptions[0]?.value });
    }
  }, [value.product]);

  return (
    <div className="variants-item" data-id={value.id}>
      <Form.Item
        className="product"
        label={
          <FormattedMessage
            id="tender-create-order-variants-field-product-variant"
            description="Label for Product variant field on tenders create/edit order page"
            defaultMessage="Product variant"
          />
        }
        required={true}
      >
        <SelectWidget
          choices={choices}
          type=""
          props={{
            value: value.product,
            onChange(product: string) {
              onChange({ ...value, product });
            },
          }}
        />
      </Form.Item>
      <div className="row pt-20">
        <Form.Item
          className="price"
          label={
            <FormattedMessage
              id="tender-create-order-variants-field-price-differential"
              description="Label for Price differential field on tenders create/edit order page"
              defaultMessage="Price differential"
            />
          }
          required={true}
        >
          <InputNumberWidget
            min={0}
            max={MAX_SAFE_INTEGER}
            step={schema.startprice.step}
            props={{
              value: value.price || 0,
              onChange(data: number) {
                const price = +(
                  Math.floor((data * 1e6) / (schema.startprice.step * 1e6)) *
                  schema.startprice.step
                ).toFixed(6);
                onChange({ ...value, price });
              },
            }}
          />
        </Form.Item>
        <Form.Item
          className="pt-0 loading"
          label={
            <FormattedMessage
              id="orderform-field-loadingdetails-label"
              description="Label for 'Loading details' field on new order form"
              defaultMessage="Loading details"
            />
          }
          required={true}
        >
          <SelectWidget
            choices={loadingOptions}
            type=""
            props={{
              value: value.loading,
              onChange(loading: string) {
                onChange({ ...value, loading });
              },
            }}
          />
        </Form.Item>
      </div>
      {onDelete && (
        <Button
          className="variants-remove"
          type="buttonlink"
          htmlType="button"
          onClick={onDelete}
        >
          <FormattedMessage
            id="tender-create-order-variants-button-remove"
            description="Button for Remove variant on tenders create/edit order page"
            defaultMessage="Remove"
          />
        </Button>
      )}
    </div>
  );
};

interface ICreate {
  onClick(): void;
}
const Create = ({ onClick }: ICreate) => {
  return (
    <div className="variants-new">
      <Button type="reverse" htmlType="button" onClick={onClick}>
        <FormattedMessage
          id="tender-create-order-variants-button-add-variant"
          description="Button for add product variant on tenders create/edit order page"
          defaultMessage="Add product variant"
        />
      </Button>
    </div>
  );
};
