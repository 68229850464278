import React from 'react';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import { useStoreState } from '~/store';
import { IFilterConfig } from 'Components/TableFilter';
import ExtraActions from './ExtraActions';
import { useToggle } from 'react-use';
import qs from 'qs';
import { api } from '~/api';
import { Button } from 'Components/nui';

interface IExportButton {
  filters: IFilterConfig<any>;
}

const ExportButton = ({ filters }: IExportButton) => {
  const [loading, toggle] = useToggle(false);
  const solution = useStoreState(state => state.auth.solution);

  const exportToCSV = async () => {
    toggle(true);
    const params = {
      ...filters.values(),
      solution: solution.id,
    };
    const url = `/orders?${qs.stringify(params, { indices: false })}`;
    await api.getFile(url, 'Orders.csv', 'text/csv');
    toggle(false);
  };

  return (
    <Button
      htmlType="button"
      size="small"
      className="export-button"
      type="secondary"
      loading={loading}
      onClick={exportToCSV}
    >
      <span className="icon-export"></span>
      <span className="show-for-large">Export to</span> CSV
    </Button>
  );
};

export default ({ orders }: { orders: any }) => {
  const { filters } = orders;
  return (
    <div className="full-page-header">
      <h1 className="inline mt-10 mr-20 mb-0">Orders</h1>
      <div className="filter-export order-export-button">
        <ExportButton filters={filters} />
        <ExtraActions orders={orders} />
      </div>

      <ul className="nui-bc right pt-20">
        <li>
          <BreadcrumbRoot active="orders" />
        </li>
        <li>Orders</li>
      </ul>
    </div>
  );
};
