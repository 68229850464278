import Button from 'Components/nui/Button';
import { Popover, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ToggleFavouriteFilter } from './Favourites';
import FilterDropdown from './FilterDropdown';
import { FilterTypes, useFilters } from './context';
import Sort from 'Components/Sort';
import { ISortable, useRolodex } from 'Components/Rolodex';
import {
  getBidOptions,
  getDeliveryFromOptions,
  getProductOptions,
  getProductionMonthOptions,
} from './filterTenders';
import { Order, Tender } from '~/models/tenders';
import FilterChips from './FilterChips';
import Icon from 'Components/Icon';

const TenderFilters = ({
  items,
  sortable,
  filter,
  tender,
}: {
  items: Order[];
  sortable?: ISortable;
  filter?: React.ReactNode;
  tender: Tender;
}) => {
  const rolodex = useRolodex();
  const isAllOpen = rolodex.isAllOpen();

  const deliveryFromOptions = getDeliveryFromOptions(items);
  const productOptions = getProductOptions(items);
  const productionMonthOptions = getProductionMonthOptions(items);
  const bidOptions = getBidOptions(tender);

  const { setFilters, setFavFilterOn } = useFilters();

  return (
    <div className="filter-row">
      <FilterChips
        leading={
          <>
            <div>
              <Button
                className="expand"
                expanded={isAllOpen}
                onClick={rolodex.toggleAll}
              >
                {isAllOpen ? (
                  <Tooltip
                    title={
                      <span className="icon-tooltip">
                        <span className="icon-info-circled" />
                        <FormattedMessage
                          id="tender-details-button-collapse"
                          description="Button to collapse order details on tender details page"
                          defaultMessage="Collapse all"
                        />
                      </span>
                    }
                  >
                    <Icon name="unfold_less" />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      <span className="icon-tooltip">
                        <span className="icon-info-circled" />
                        <FormattedMessage
                          id="tender-details-button-expand"
                          description="Button to expand order details on tender details page"
                          defaultMessage="Expand all"
                        />
                      </span>
                    }
                  >
                    <Icon name="unfold_more" />
                  </Tooltip>
                )}
              </Button>
            </div>
            <span className="mr-5 rolodex-sort-text">
              <FormattedMessage
                id="tender-details-filters-title"
                description="Tender details page filter title"
                defaultMessage="Filters"
              />
            </span>
          </>
        }
        trailing={
          <>
            <div className="filter-chips-title">
              <span className="mr-8">
                <FormattedMessage
                  id="tender-details-sort-by-title"
                  description="Tender details page sort by title"
                  defaultMessage="Sort by"
                />
              </span>
              {!!sortable && <Sort {...sortable} />}
            </div>
            {filter && (
              <Popover
                trigger="click"
                placement="bottomRight"
                content={<div className="rolodex-filter">{filter}</div>}
              >
                <button className="icon-ellipsis" />
              </Popover>
            )}
          </>
        }
      >
        <ToggleFavouriteFilter />
        {deliveryFromOptions.length > 0 && (
          <FilterDropdown
            id="deliveryFrom"
            label={
              <FormattedMessage
                id="tender-details-filters-delivery-from"
                description="Tender details page filter title delivery from"
                defaultMessage="Delivery From"
              />
            }
            type={FilterTypes.CHECKBOXES}
            options={deliveryFromOptions}
          />
        )}
        <FilterDropdown
          id="product"
          label={
            <FormattedMessage
              id="tender-details-filters-product"
              description="Tender details page filter title product"
              defaultMessage="Product"
            />
          }
          type={FilterTypes.CHECKBOXES}
          options={productOptions}
        />
        {!tender.owned && (
          <FilterDropdown
            id="bids"
            label={
              tender.ordername.counter === 'bid' ? (
                <FormattedMessage
                  id="tender-details-filters-bids"
                  description="Tender details page filter title bids"
                  defaultMessage="Bids"
                />
              ) : (
                <FormattedMessage
                  id="tender-details-filters-offers"
                  description="Tender details page filter title offers"
                  defaultMessage="Offers"
                />
              )
            }
            type={FilterTypes.RADIO}
            options={bidOptions}
            showSelectedCount={false}
          />
        )}
        <FilterDropdown
          id="etd"
          label={
            <FormattedMessage
              id="tender-details-filters-etd"
              description="Tender details page filter title ETD"
              defaultMessage="ETD"
            />
          }
          type={FilterTypes.DATE}
          showSelectedCount={false}
        />
        {productionMonthOptions.length > 0 && (
          <FilterDropdown
            id="productionMonth"
            label={
              <FormattedMessage
                id="tender-details-filters-production-month"
                description="Tender details page filter title production month"
                defaultMessage="Production Month"
              />
            }
            type={FilterTypes.CHECKBOXES}
            options={productionMonthOptions}
          />
        )}
        <Button
          type="buttonlink"
          className="filter-reset"
          onClick={() => {
            setFilters({});
            setFavFilterOn?.(false);
          }}
        >
          <FormattedMessage
            id="filter-button-clear-filter"
            description="Button for clear search filters"
            defaultMessage="Clear all filters"
          />
        </Button>
      </FilterChips>
    </div>
  );
};

export default TenderFilters;
