import type { Tender } from '~/models/tenders';
import React from 'react';
import { withPreload } from '~/router';
import * as services from '~/services/tenders';
import { FormattedMessage } from 'react-intl';

type TenderProp = Record<'tender', Tender>;

export default ({ tender }: TenderProp) => {
  return tender.isRunning() && !tender.owned ? (
    <div className="tender-ranking-totals">
      <Display />
    </div>
  ) : null;
};

const Display = withPreload({
  route: 'tender-view',
  preload: services.observe.orders({ requireId: false }),
  size: 'tiny',
})(({ data: { tender, orders } }) => {
  const leading = orders.filter(o => o.counters.mine?.rank === 1).length;
  const losing = orders.filter(o => o.counters.mine?.rank > 1).length;

  if (tender && leading + losing > 0)
    return (
      <>
        {leading > 0 && (
          <span className="counter-summary leading">
            <FormattedMessage
              id="tender-info-summary-leading"
              description="text `leading` for tender summary"
              defaultMessage="{leading} leading"
              values={{ leading: leading }}
            />
          </span>
        )}
        {losing > 0 && (
          <span className="counter-summary losing">
            <FormattedMessage
              id="tender-info-summary-outbid"
              description="text `outbid` for tender summary"
              defaultMessage="{losing} outbid"
              values={{ losing: losing }}
            />
          </span>
        )}
      </>
    );

  return <></>;
});
