import React, { useRef } from 'react';
import { useToggle, useClickAway } from 'react-use';
import { Button } from 'Components/nui';
import { Link } from 'react-router-dom';
import {
  usePartners,
  useCustomers,
  useWarehouses,
  useConsolidation,
  useDeliveries,
  CustomerRequestController,
  useParticipants,
} from '~/store/models';
import { Authorised } from 'Components/Authorised';
import classnames from 'classnames';
import { useStoreState } from '~/store';

export const Breadcrumb = ({ className = '', url, hint, active, title }) => (
  <li className={classnames(className, { active })}>
    <Link to={url} title={hint}>
      {title}
    </Link>
  </li>
);

export default ({ active = '' }) => {
  const ref = useRef(null);
  const [open, toggle] = useToggle(false);
  useClickAway(ref, () => {
    toggle(false);
  });
  const partners = usePartners();
  const customers = useCustomers();
  const warehouses = useWarehouses();
  const consolidation = useConsolidation();
  const deliveries = useDeliveries();
  const participants = useParticipants();
  const isSolutionOwner = useStoreState(state => state.auth.isSolutionOwner);
  const isdao = useStoreState(state => state.auth.isdao);
  const solution = useStoreState(state => state.auth.solution);

  const { freight_types = [], freight = '' } = solution;
  const hasRoutes =
    isSolutionOwner && (freight_types.includes('port') || freight === 'port');

  const isActive = id => id === active;

  const solutionDivisions = useStoreState(
    state => state.auth.solutionDivisions
  );

  const canCreateLiquidity = solutionDivisions.some(s => s.createliquidity);

  // TODO Breadcrumbs should derive their status from the window URL

  return (
    <span ref={ref} className="relative" title="Show pages">
      <Button
        type="buttonlink"
        className="configuration-links inline relative pr-10"
        onClick={toggle}
      >
        <span className="open-configuration-dd">Pages</span>
      </Button>
      {open && (
        <ul className="configuration-options">
          {canCreateLiquidity && (
            <Breadcrumb
              url="/lp-overview"
              hint="View liquidity provider"
              active={isActive('lp-overview')}
              title="LP Overview"
            />
          )}
          <Breadcrumb
            url="/orders"
            hint="View orders"
            active={isActive('orders')}
            title="Orders"
          />
          <Breadcrumb
            url="/trades"
            hint="View trades"
            active={isActive('trades')}
            title="Trades"
          />
          <Authorised to="view" model={participants}>
            <Breadcrumb
              url="/participants"
              hint="Participants"
              active={isActive('participants')}
              title="Participants"
            />
          </Authorised>
          <Authorised to="view" model={consolidation}>
            <Breadcrumb
              url="/consolidation"
              hint="Consolidate trades"
              active={isActive('consolidation')}
              title="Consolidation"
            />
          </Authorised>
          <Authorised to="view" model={deliveries}>
            <Breadcrumb
              url="/deliveries"
              hint="View deliveries"
              active={isActive('deliveries')}
              title="Deliveries"
            />
          </Authorised>
          <Authorised to="view" model={partners}>
            <Breadcrumb
              url="/partners"
              hint="View, add or remove partners"
              active={isActive('partners')}
              title="Partners"
            />
          </Authorised>
          {hasRoutes && (
            <Breadcrumb
              url="/routes"
              hint="Routes"
              active={isActive('routes')}
              title="Routes"
            />
          )}
          <Breadcrumb
            url="/products"
            hint="View, add or edit products"
            active={isActive('products')}
            title="Products"
          />
          <Authorised to="view" model={warehouses}>
            <Breadcrumb
              url="/locations"
              hint="Manage locations"
              active={isActive('locations')}
              title="Locations"
            />
          </Authorised>
          <Authorised to="view" model={customers}>
            <Breadcrumb
              url="/customers"
              hint="Manage customers"
              active={isActive('customers')}
              title="Customers"
            />
          </Authorised>
          <Authorised to="view" model={CustomerRequestController}>
            <Breadcrumb
              url="/customer-requests"
              hint="View and create requests for new customers"
              active={isActive('customer-requests')}
              title="Customer requests"
            />
          </Authorised>
          {(isSolutionOwner || isdao) && (
            <Breadcrumb
              url="/reports"
              hint="View reports"
              active={isActive('reports')}
              title="Reports"
            />
          )}
          <Breadcrumb
            url="/users"
            hint="View users"
            active={isActive('users')}
            title="Users"
          />
        </ul>
      )}
    </span>
  );
};
