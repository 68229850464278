import * as api from './api';

export async function userSchema(userId: string | undefined) {
  return await api.get({
    url: userId ? `/users/${userId}/schema` : `/users/schema`,
    method: 'GET',
  });
}

export async function getUsers(solutionId?: string) {
  const url = solutionId ? `/users?solution=${solutionId}` : '/users';
  return await api.get({
    url: url,
    method: 'GET',
  });
}

export async function roleSchema(userId: string, roleId: string | undefined) {
  const url = roleId
    ? `/users/${userId}/roles/${roleId}/schema`
    : `/users/${userId}/roles/schema`;
  return await api.get({
    url,
    method: 'GET',
  });
}

export async function setUserRoles(
  userId: string,
  roleId: string | undefined,
  data: any
) {
  return await api.modify({
    url: roleId ? `/users/${userId}/roles/${roleId}` : `/users/${userId}/roles`,
    method: roleId ? 'PUT' : 'POST',
    data,
  });
}

export async function updateUserInfo(userId: string | undefined, data: any) {
  const url = userId ? `/users/${userId}` : `/users`;
  const method = userId ? 'PUT' : 'POST';
  return await api.modify({
    url,
    method,
    data,
  });
}

export async function deleteUserRole(userId: string, roleId: string) {
  return await api.modify({
    url: `/users/${userId}/roles/${roleId}`,
    method: 'DELETE',
  });
}

export async function deleteUser(userId: string) {
  return await api.remove({
    url: `/users/${userId}`,
    method: 'DELETE',
  });
}
