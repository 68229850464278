import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { FormWrapper } from 'Components/form';
import {
  mergeSchema,
  normalizeChoices,
  strBoolIsTruthy,
} from 'Components/form/utils';
import FNumber from 'Components/FNumber';
import moment from 'moment-timezone';
import FilesField from 'Components/form/FilesField';
import { api } from '~/api';
import { SelectField } from 'Components/form/fields';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  unitsOfMeasure,
  inArrayIf,
  getDecimalCount,
  getColumnFieldLabel,
  invertOrderType,
} from '~/utils';
import { Alert } from 'Components/nui';
import { useStoreState } from '~/store';

export const useFormWrapper = ({
  form,
  order,
  product,
  schema,
  solution,
  initialData,
  price: basePrice,
}) => {
  const solutionColumns = useStoreState(state => state.auth.solutionColumns);
  const exportIntent = strBoolIsTruthy(form, schema, 'exportable');

  const exportdocsRequired = strBoolIsTruthy(form, schema, 'exportdocs');

  const heatTreatedPalletsRequired = strBoolIsTruthy(
    form,
    schema,
    'heattreatedpallets'
  );

  const getChoicesByDivision =
    ({ choices }) =>
    () => {
      const divisionId = form.getFieldValue('division');
      return normalizeChoices(
        Array.isArray(choices) ? choices : R.propOr([], divisionId, choices)
      );
    };

  const totaldeliveries = R.propOr(1, 'totaldeliveries', order);
  const step = R.prop('qty', order.loading);
  const volume = order.volume.delivery ?? order.volume.available;
  const totalVolume = volume * totaldeliveries;
  const { formatMessage } = useIntl();

  const [files, setFiles] = useState([]);

  const [realPartyID, setRealPartyID] = useState();
  const [realPartyUserChoices, setRealPartyUserChoices] = useState([]);

  useEffect(() => {
    if (!realPartyID) {
      setRealPartyUserChoices([]);
    } else {
      (async () => {
        try {
          const data = await api.getData2(`/client-users/${realPartyID}`);
          setRealPartyUserChoices(R.propOr([], 'choices', data));
        } catch (error) {
          setRealPartyUserChoices([]);
        }
      })();
    }
  }, [realPartyID, setRealPartyUserChoices]);

  let fields = [
    {
      name: 'price',
      label: (
        <>
          <FormattedMessage
            id="marketrow-tradeform-field-price-label"
            description="Label for `Price` field on tradeform in Marketrow"
            defaultMessage="Price"
          />{' '}
          <span className="unit block mt--4">
            {R.path(['price', 'unit'], order)}
          </span>
        </>
      ),
      step: product.step,
      min: product.step,
    },
    {
      name: 'currency',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-currency-label"
          description="Label for `Currency` field on tradeform in Marketrow"
          defaultMessage="Currency"
        />
      ),
      choices: normalizeChoices,
      props: {
        placeholder: (
          <FormattedMessage
            id="marketrow-tradeform-field-currency-placholder"
            description="Placeholder for `Currency` field on tradeform in Marketrow"
            defaultMessage="Select currency"
          />
        ),
      },
    },
    {
      render: () => {
        if (!R.has('currency', schema)) return null;
        const { currency } = form.getFieldsValue();
        const cur = R.find(R.propEq('currency', currency))(order.currencies);
        return (
          <div key="currency-exchange" className="currency-exchange">
            {currency && (
              <>
                <span>
                  <FormattedMessage
                    id="marketrow-tradeform-field-currencyprice-label"
                    description="Label for `Price of Currency ` field on tradeform in Marketrow"
                    defaultMessage="Price for selected currency"
                  />
                </span>
                <strong>
                  <FNumber
                    value={R.pathOr(0, ['val'], cur)}
                    decimalCount={order.priceDecimalCount}
                  />{' '}
                  {R.pathOr(0, ['unit'], cur)}
                </strong>
              </>
            )}
          </div>
        );
      },
    },
    {
      name: 'division',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-division-label"
          description="Label for `Division` field on tradeform in Marketrow"
          defaultMessage="Division"
        />
      ),
      choices: normalizeChoices,
    },
    {
      name: 'realparty',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-realparty-label"
          description="Label for `Company (participant)` field on tradeform in Marketrow"
          defaultMessage="Company (participant)"
        />
      ),
      choices: normalizeChoices,
      props: {
        onChange: x => {
          setRealPartyID(x);
        },
      },
    },
  ];

  const realPartyUserField = new SelectField({
    name: 'realpartyuser',
    label: (
      <FormattedMessage
        id="marketrow-tradeform-field-user-label"
        description="Label for `User` field on tradeform in Marketrow"
        defaultMessage="User"
      />
    ),
    choices: () => normalizeChoices(realPartyUserChoices),
  });

  if (realPartyID) {
    fields.push({
      render: () => <>{realPartyUserField.render({ form })}</>,
      serializeField: values => ({
        realpartyuser: realPartyUserField.serialize(values.realpartyuser),
      }),
    });
  }
  fields.push(
    {
      name: 'volume',
      label: (
        <>
          Volume {!!order.totaldeliveries && 'per delivery'}
          <span className="unit block mt--4">
            {R.path(['loadingunit', 'desc'], product)}
          </span>
        </>
      ),
      required: true,
      step,
      min: () => (order.splittable ? Number(step) : volume),
      max: volume,
      props: () => ({ disabled: !order.splittable }),
    },
    {
      render: () => {
        const currency = form.getFieldValue('currency') || order.price.currency;
        let price = form.getFieldValue('price');
        if (!price) {
          if (currency === order.price.currency) price = basePrice;
          else price = order.getPriceObjByCur(currency).val;
        }

        const volume = form.getFieldValue('volume') || order.volume.pending;
        const unit = R.path(['volume', 'unit'], order);

        if (order.index) return null;

        return (
          <div key="fok-value" className="currency-exchange">
            <span className="label w-auto pr-10 inline-block nowrap">
              <strong className="all-black">
                <FormattedMessage
                  id="marketrow-tradeform-field-totalvalue-label"
                  description="Label for `Total value` field on tradeform in Marketrow"
                  defaultMessage="Total value:"
                />
              </strong>
              <FNumber
                value={product._getValue(
                  price,
                  volume * totaldeliveries,
                  order.loading.id
                )}
                decimalCount={2}
              />{' '}
              {currency}
            </span>

            {order.totaldeliveries && (
              <span className="label w-auto pr-10 inline-block nowrap">
                <strong className="all-black">
                  <FormattedMessage
                    id="marketrow-tradeform-field-totalvolume-label"
                    description="Label for `Total volume` field on tradeform in Marketrow"
                    defaultMessage="Total volume:"
                  />
                </strong>
                <FNumber
                  value={volume * totaldeliveries}
                  decimalCount={order.priceDecimalCount}
                />{' '}
                {R.pathOr(unit, [unit], unitsOfMeasure)}
              </span>
            )}
          </div>
        );
      },
    },
    {
      name: 'files',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-attachments-label"
          description="Label for `Attachments` field on tradeform in Marketrow"
          defaultMessage="Attachments"
        />
      ),
      render: () => {
        if (!R.prop('files', schema)) return null;
        return (
          <FilesField
            key="form-files"
            initialData={initialData}
            accept={R.pathOr([], ['files', 'accepted'], schema).join(', ')}
            files={files}
            setFiles={setFiles}
          />
        );
      },
      serializeField: () => {
        if (!R.prop('files', schema)) return null;
        return files.length ? { files } : undefined;
      },
    },
    {
      name: 'attachments',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-existingattachments-label"
          description="Label for `Existing attachments` field on tradeform in Marketrow"
          defaultMessage="Existing attachments"
        />
      ),
      choices: normalizeChoices,
      props: {
        placeholder: (
          <FormattedMessage
            id="marketrow-tradeform-field-existingattachments-placholder"
            description="Placeholder for `Existing attachments` field on tradeform in Marketrow"
            defaultMessage="Select attachments to keep"
          />
        ),
      },
      initialValue: R.pathOr([], ['attachments', 'value'], schema),
      initialData: R.pathOr([], ['attachments', 'value'], schema),
    },
    {
      name: 'fromaddr',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-deliveryfrom-label"
          description="Label for `Delivery from` field on tradeform in Marketrow"
          defaultMessage="Delivery from"
        />
      ),
      choices: getChoicesByDivision,
      props: {
        placeholder: (
          <FormattedMessage
            id="marketrow-tradeform-field-deliveryfrom-placholder"
            description="Placeholder for `Delivery from` field on tradeform in Marketrow"
            defaultMessage="Select location"
          />
        ),
      },
    },
    {
      name: 'toaddr',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-deliveryto-label"
          description="Label for `Delivery to` field on tradeform in Marketrow"
          defaultMessage="Delivery to"
        />
      ),
      choices: getChoicesByDivision,
      props: {
        placeholder: (
          <FormattedMessage
            id="marketrow-tradeform-field-deliveryfrom-placholder"
            description="Placeholder for `Delivery from` field on tradeform in Marketrow"
            defaultMessage="Select location"
          />
        ),
      },
    },
    {
      name: 'warehouse',
      label:
        order.type === 'bid' ? (
          <FormattedMessage
            id="marketrow-tradeform-field-deliveryfrom-label"
            description="Label for `Delivery from` field on tradeform in Marketrow"
            defaultMessage="Delivery from"
          />
        ) : (
          <FormattedMessage
            id="marketrow-tradeform-field-deliveryto-label"
            description="Label for `Delivery to` field on tradeform in Marketrow"
            defaultMessage="Delivery to"
          />
        ),
      choices: getChoicesByDivision,
      props: {
        placeholder: (
          <FormattedMessage
            id="marketrow-tradeform-field-deliveryfrom-placholder"
            description="Placeholder for `Delivery from` field on tradeform in Marketrow"
            defaultMessage="Select location"
          />
        ),
      },
    },
    {
      name: 'buyer',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-buyer-label"
          description="Label for `Buyer` field on tradeform in Marketrow"
          defaultMessage="Buyer"
        />
      ),
      choices: normalizeChoices,
    },
    {
      name: 'consignee',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-consignee-label"
          description="Label for `Consignee` field on tradeform in Marketrow"
          defaultMessage="Consignee"
        />
      ),
      choices: normalizeChoices,
    },
    {
      name: 'exportable',
      label: (
        <>
          {getColumnFieldLabel(
            invertOrderType(order.type),
            'exportable'
          )(solutionColumns) ?? 'Exportable'}
        </>
      ),
      initialValue: schema?.exportable?.value,
      required: true,
    },

    ...inArrayIf(
      !('exportable' in schema) || (exportIntent && order.type === 'offer'),
      {
        name: 'exportdocs',
        label: (
          <>
            {getColumnFieldLabel(
              invertOrderType(order.type),
              'exportdocs'
            )(solutionColumns) ?? (
              <FormattedMessage
                id="marketrow-tradeform-field-exportdocs-label"
                description="Label for `Export docs` field on tradeform in Marketrow"
                defaultMessage="Export docs"
              />
            )}
            <span className="normal warning">
              (
              <FormattedMessage
                id="marketrow-tradeform-field-exportdocs-warning"
                description="warning for `Export docs` field on tradeform in Marketrow"
                defaultMessage="Fee applies"
              />
              )
            </span>
          </>
        ),
        initialValue: schema?.exportdocs?.value,
        required: true,
        choices: [
          {
            label: 'Yes',
            value: 'true',
            key: 'yes',
          },
          { label: 'Not required', value: 'false', key: 'no' },
        ],
      }
    ),
    {
      render: () =>
        exportIntent &&
        solution?.exportprice?.flatfee &&
        exportdocsRequired && (
          <Alert
            hasicon
            type="info"
            className="alert-fee"
            key="alert-exportfee"
          >
            Please note a{' '}
            <strong>
              {solution.exportprice.price} {solution.currency}
            </strong>{' '}
            flat fee will be added to this order.{' '}
            {solution.exportprice.file && (
              <a
                href={solution.exportprice.file.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                View more information here
              </a>
            )}
          </Alert>
        ),
    },

    ...inArrayIf(exportIntent, {
      name: 'heattreatedpallets',
      label: 'Heat-treated pallets required',
      choices: [
        {
          label: 'Yes',
          value: 'true',
          key: 'yes',
        },
        { label: 'Not required', value: 'false', key: 'no' },
      ],
      initialValue: schema?.heattreatedpallets?.value,
      required: true,
    }),

    {
      render: () =>
        exportIntent &&
        heatTreatedPalletsRequired &&
        totalVolume > 0 &&
        solution?.exportprice?.heattreatedfee && (
          <Alert
            hasicon
            type="info"
            className="pallets-request"
            key="pallets-request"
          >
            <h3>Heat-treated pallets summary</h3>
            <div>
              <blockquote>
                <FNumber
                  value={parseFloat(solution.exportprice.heattreatedfee)}
                  decimalCount={getDecimalCount(
                    parseFloat(solution.exportprice.heattreatedfee)
                  )}
                  unit={solution.currency}
                />{' '}
                &times;{' '}
                <FNumber
                  value={totalVolume}
                  decimalCount={getDecimalCount(totalVolume)}
                />{' '}
                ={' '}
                <FNumber
                  value={
                    parseFloat(solution.exportprice.heattreatedfee) *
                    totalVolume
                  }
                  decimalCount={2}
                  unit={solution.currency}
                />
              </blockquote>
            </div>
          </Alert>
        ),
    },

    ...inArrayIf(exportIntent, {
      name: 'exportcountry',
      label: 'Destination',
      choices: normalizeChoices(schema?.exportcountry?.choices),
      initialValue: schema?.exportcountry?.value,
    }),

    {
      name: 'callofftime',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-callofftimes-label"
          description="Label for `Call off times` field on tradeform in Marketrow"
          defaultMessage="Call off times"
        />
      ),
      required: () => {
        if (!order.etd.from || !order.etd.to) return false;
        return moment(order.etd.from).add(28, 'days') <= moment(order.etd.to);
      },
      props: {
        placeholder: formatMessage({
          id: 'marketrow-tradeform-field-callofftimes-placholder',
          description:
            'Placeholder for `Call off times` field on tradeform in Marketrow',
          defaultMessage: 'Ex: 1 load in week 20\n1 load in week 22',
        }),
      },
      initialValue: R.pathOr('', ['callofftime', 'value'], schema),
      initialData: R.pathOr('', ['callofftime', 'value'], schema),
    },
    {
      name: 'reference',
      label: (
        <>
          <FormattedMessage
            id="marketrow-tradeform-field-reference-label"
            description="Label for `Reference` field on tradeform in Marketrow"
            defaultMessage="Reference"
          />{' '}
          <span className="normal">
            <FormattedMessage
              id="marketrow-tradeform-field-reference-helptext"
              description="helptext for `Reference` field on tradeform in Marketrow"
              defaultMessage="This field is for internal use only, and will not be published."
            />
          </span>
        </>
      ),
    },
    {
      name: 'note',
      label: (
        <FormattedMessage
          id="marketrow-tradeform-field-comment-label"
          description="Label for `Comment` field on tradeform in Marketrow"
          defaultMessage="Comment"
        />
      ),
    }
  );

  let init = initialData || {};
  if (!('volume' in init)) init.volume = order.volume.available;

  return new FormWrapper(form, init, mergeSchema(schema, fields));
};
