import React, { memo } from 'react';
import * as R from 'ramda';
import { capitalize } from '~/utils';
import { Tooltip } from 'Components/nui';

interface IAttributes {
  attributes: { [key: string]: any[] };
}

export default function ProductAttributesTooltip({ attributes }: IAttributes) {
  return (
    <div className="marketplace-attributes-container">
      {Object.keys(attributes)
        .sort()
        .map((k, index, array) => (
          <div className="individual-attributes" key={k}>
            <span className="attributes-key">{capitalize(k)}</span>{' '}
            <span className="attributes-value">
              {R.pathOr('', [k, 0, 'name'], attributes)}
              {index < array.length - 1 && ','}
            </span>
          </div>
        ))}
    </div>
  );
}
