import {
  Data,
  IFilters,
  Filters,
  FilterValue,
  IUpdate,
  IPoll,
  IRemove,
  IUseFilters,
  IUseModel,
  IUsePagination,
  IPagination,
} from './types';
import { useState, useEffect } from 'react';
import * as R from 'ramda';
import moment from 'moment-timezone';
import { useDebounce, useThrottle } from '~/hooks';
import { useLocation, useHistory } from 'react-router-dom';
import { apiError } from '~/errors';

function useFilters<D extends Data>(config: IUseFilters<D>): IFilters {
  const [filters, setFilters] = useState<Filters>(config.initial);
  let { search } = useLocation();
  const { push } = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(search);
    let fromParams: any = {};
    for (const key of Array.from(params.keys())) {
      if (R.includes(key, Object.keys(config.initial))) {
        fromParams[key] = params.getAll(key);
      }
    }
    if (Object.keys(fromParams).length) {
      setFilters({ ...config.initial, ...config.deserialize(fromParams) });
    }
  }, []);

  return {
    filter(name: string, value: FilterValue) {
      const f = { ...filters, [name]: value };
      setFilters(f);
      const serialized = config.serialize(f);
      const params = new URLSearchParams();
      for (const [key, value] of Object.entries(serialized)) {
        if (Array.isArray(value)) {
          for (const v of value) params.append(key, v);
        } else if (value !== undefined) {
          params.append(key, value);
        }
      }
      const location = { search: params.toString() };
      config.actions.tags.changePage({ tag: config.tag, value: 1 });
      push(location);
    },

    value(name: string) {
      return R.prop(name, filters);
    },

    values() {
      return config.serialize(filters);
    },

    reset() {
      setFilters(config.initial);
      const location = { search: '' };
      push(location);
    },

    filtered() {
      return !R.equals(config.initial, filters);
    },

    shouldUpdate() {
      return [filters];
    },
  };
}

function usePagination<D extends Data>({
  state,
  actions,
  defaultTag,
}: IUsePagination<D>): IPagination {
  return {
    page(tag = defaultTag) {
      return state.tags.page(tag);
    },
    limit(tag = defaultTag) {
      return state.tags.limit(tag);
    },
    pages(tag = defaultTag) {
      return state.tags.pages(tag);
    },
    total(tag = defaultTag) {
      return state.tags.total(tag);
    },

    changePage({ tag = defaultTag, value }) {
      actions.tags.changePage({ tag, value });
    },

    changeLimit({ tag = defaultTag, value }) {
      if (state.tags.limit(tag) !== value) actions.tags.reset(tag);
      actions.tags.changeLimit({ tag, value });
    },

    shouldUpdate(tag = defaultTag) {
      return [state.tags.page(tag), state.tags.limit(tag)];
    },
  };
}

interface OnResponse {
  [key: number]: () => void;
}
type OnRequest = () => Promise<void>;
export async function guardRequest(
  request: OnRequest,
  onResponse: OnResponse = {}
) {
  try {
    await request();
  } catch (errors) {
    apiError(errors, onResponse);
  }
}

async function _poll<D extends Data>(props: IPoll<D>) {
  const { tag, config = {}, api, actions, state, getResult, filters } = props;

  if (!state.tags.exists(tag)) return;

  const mark = moment.utc();
  actions.tags.setLoading({ tag, value: true });

  await guardRequest(
    async () => {
      const response = await api.get(
        R.mergeDeepRight(
          {
            params: filters.values(),
            headers: {
              'If-Modified-Since': state.tags.lastModified(tag),
            },
          },
          config
        )
      );

      switch (response.status) {
        case 200: {
          const data = getResult(response);
          actions.add(data);
          actions.tags.insert({ tag, value: data.map(R.prop('id')) });
          if (response.data.filtered)
            actions.tags.remove({
              tag,
              value: response.data.filtered.map(R.prop('id')),
            });
          actions.tags.setLastModified({ tag, value: mark });
          actions.tags.setNoContent({
            tag,
            value: !state.tags.fetch(tag).length,
          });
          break;
        }
        case 204: {
          actions.tags.setNoContent({ tag, value: true });
          actions.tags.reset(tag);
          actions.tags.setTotal({ tag, value: 0 });
          actions.tags.setPages({ tag, value: 1 });
          break;
        }
        default: {
          break;
        }
      }
    },
    {
      304: () => {
        actions.tags.setLastModified({ tag, value: mark });
      },
    }
  );

  actions.tags.setLoading({ tag, value: false });
}

async function _update<D extends Data>(props: IUpdate<D>) {
  const { tag, config = {}, api, actions, state, getResult, filters } = props;
  const mark = moment().utc();

  actions.tags.create(tag);
  actions.tags.setLoading({ tag, value: true });

  await guardRequest(async () => {
    const response = await api.get(
      R.mergeDeepRight(
        {
          params: {
            ...filters.values(),
            limit: state.tags.limit(tag),
            page: state.tags.page(tag),
          },
        },
        config
      )
    );
    const data = getResult(response);

    switch (response.status) {
      case 200: {
        actions.add(data);
        actions.tags.push({ tag, value: data.map(R.prop('id')) });
        actions.tags.setLastModified({ tag, value: mark });
        actions.tags.setNoContent({ tag, value: false });
        break;
      }
      case 204: {
        actions.tags.setNoContent({ tag, value: true });
        actions.tags.reset(tag);
        actions.tags.setTotal({ tag, value: 0 });
        actions.tags.setPages({ tag, value: 1 });
        break;
      }
      default: {
        break;
      }
    }

    if (response.data.total && response.data.pages) {
      actions.tags.setTotal({ tag, value: response.data.total });
      actions.tags.setPages({ tag, value: response.data.pages });
    } else if (data.length <= state.tags.limit(tag)) {
      actions.tags.setTotal({ tag, value: data.length });
      actions.tags.setPages({ tag, value: 1 });
    }
  });

  actions.tags.setLoading({ tag, value: false });
}

async function remove<D extends Data>(props: IRemove<D>) {
  const { tag, model, config = {}, api, actions, state } = props;

  if (!state.tags.exists(tag)) return;

  actions.tags.setLoading({ tag, value: true });

  await guardRequest(async () => {
    const response = await api.delete({ model, ...config });
    switch (response.status) {
      case 204: {
        actions.tags.remove({ tag, value: [model.id] });
        break;
      }
      default: {
        break;
      }
    }
  });

  actions.tags.setLoading({ tag, value: false });
}

type IPollFn<D extends Data> = Partial<Pick<IPoll<D>, 'tag' | 'config'>>;
type IUpdateFn<D extends Data> = Partial<Pick<IUpdate<D>, 'tag' | 'config'>>;
type IRemoveFn<D extends Data> = { tag?: string } & Pick<
  IRemove<D>,
  'config' | 'model'
>;

export function useModel<D extends Data>(config: IUseModel<D>) {
  const {
    defaultTag = '',
    api,
    actions,
    state,
    getResult,
    filterConfig = {},
  } = config;

  const {
    serialize = R.identity,
    deserialize = R.identity,
    initial = {},
  } = filterConfig;

  const filters = useFilters({
    tag: defaultTag,
    actions,
    serialize,
    deserialize,
    initial,
  });
  const pagination = usePagination({ state, actions, defaultTag });

  const poll = useThrottle(_poll, 1000, { leading: true, trailing: true });
  const update = useDebounce(_update, 2000, { leading: true, trailing: true });

  return {
    poll({ tag = defaultTag, config = {} }: IPollFn<D> = {}) {
      actions.tags.setLoading({ tag, value: true });
      return poll({ tag, config, api, actions, state, getResult, filters });
    },

    update({ tag = defaultTag, config = {} }: IUpdateFn<D> = {}) {
      actions.tags.setLoading({ tag, value: true });
      return update({ tag, config, api, actions, state, getResult, filters });
    },

    fetch(tag: string = defaultTag): D[] {
      return state.getTag(tag);
    },

    remove({ tag = defaultTag, model, config = {} }: IRemoveFn<D>) {
      return remove({ tag, model, config, api, actions, state });
    },

    loading(tag: string = defaultTag) {
      return state.tags.isLoading(tag);
    },

    noContent(tag: string = defaultTag) {
      return state.tags.noContent(tag);
    },

    filters,
    pagination,

    shouldUpdate(tag: string = defaultTag) {
      return [...pagination.shouldUpdate(tag), ...filters.shouldUpdate()];
    },
  };
}
