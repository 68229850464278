import React from 'react';
import { useStoreState, useStoreActions } from '~/store';
import classnames from 'classnames';
import { useSiteTitle } from '~/hooks';
import { Button } from 'Components/nui';

const Detail = ({ title, info, className = '' }) => {
  if (!info) return null;

  return (
    <p className={classnames('solution-detail', className)}>
      <span>{title}</span> {info}
    </p>
  );
};

const Solution = ({ solution, active }) => {
  const changeSolution = useStoreActions(state => state.auth.changeSolution);
  const { owner } = solution;

  const onClick = e => {
    e.preventDefault();
    changeSolution(solution.id);
  };

  return (
    <li className={classnames('solution-item', { active })}>
      <div className="solution-bio">
        <img
          src={solution.icon || solution.logo}
          alt={solution.desc}
          className="solution-logo"
        />
        <div className="solution-intro">
          <h3>{solution.name}</h3>
          <span>{owner.name}</span>
        </div>
      </div>

      <div className="solution-attributes-container">
        {
          <Button
            type="secondary"
            size="small"
            onClick={onClick}
            disabled={active}
          >
            Switch
          </Button>
        }
      </div>
    </li>
  );
};

export default () => {
  const solutions = useStoreState(state => state.auth.solutions);
  const solution = useStoreState(state => state.auth.solution);

  useSiteTitle('Solutions');

  return (
    <div className="wrapper">
      <div id="solutions">
        <h2>Solutions</h2>

        <ul className="solutions-list">
          {solutions.map(s => (
            <Solution key={s.id} solution={s} active={s.id === solution.id} />
          ))}
        </ul>
      </div>
    </div>
  );
};
