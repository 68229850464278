import type { Tender, Order } from '~/models/tenders';
import React, { useState } from 'react';
import { Alert, Button } from 'Components/nui';
import {
  LabelledInfo,
  RoutedSummary,
  ListedData,
  StackItem,
} from 'Components/nui/Wizard';
import classnames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { capitalize } from '~/utils';
import * as Data from 'Components/Data';
import * as service from '~/services/tenders';
import { toast } from 'react-toastify';
import { routeUrl, usePathTools } from '~/router';
import { FormattedMessage } from 'react-intl';

type TenderProp = Record<'tender', Tender>;
type OrdersProp = Record<'orders', Order[]>;
type Participants = { included: number; excluded: number };

type IConfig = Partial<TenderProp & OrdersProp>;
export default ({ tender, orders }: IConfig) => {
  return (
    <div className="summary-details">
      {tender ? (
        <>
          <TenderDetails tender={tender} />
          {!!orders?.length && (
            <OrdersDetails tender={tender} orders={orders} />
          )}
          {!!tender.participants && (
            <ParticipantsDetails {...tender.participants} />
          )}
        </>
      ) : (
        <Alert type="info" hasicon>
          <p className="smaller">
            <FormattedMessage
              id="tender-create-summary-initial"
              description="Text for Summary when no tender on tenders create/edit pages"
              defaultMessage="A summary of your tender will appear here. You can save and exit at any step. Once complete, you can publish your tender and notify all selected participants."
            />
          </p>
        </Alert>
      )}
    </div>
  );
};

const infoFmt = 'LL HH:mm Z';
const TenderDetails = ({ tender }: TenderProp) => (
  <LabelledInfo
    data={[
      [
        <FormattedMessage
          id="tender-create-summary-tender-name"
          description="Label for Tender name field on summary for tenders create/edit order page"
          defaultMessage="Tender name"
        />,
        tender.name,
      ],
      [
        <FormattedMessage
          id="tender-create-summary-method"
          description="Label for Method field on summary for tenders create/edit order page"
          defaultMessage="Method"
        />,
        <Data.TenderMethod info={tender} />,
      ],
      [
        <FormattedMessage
          id="tender-create-summary-status-label"
          description="Label for Status field on summary for tenders create/edit order page"
          defaultMessage="Status"
        />,
        <TenderStatus value={tender.status} />,
      ],
      [
        <FormattedMessage
          id="tender-create-summary-start-label"
          description="Label for Start field on summary for tenders create/edit order page"
          defaultMessage="Start"
        />,
        <Data.Datetime value={tender.start} fmt={infoFmt} />,
      ],
      [
        <FormattedMessage
          id="tender-create-summary-finish-label"
          description="Label for Finish field on summary for tenders create/edit order page"
          defaultMessage="Finish"
        />,
        <Data.Datetime value={tender.finish} fmt={infoFmt} />,
      ],
      [
        <FormattedMessage
          id="tender-create-summary-duration-label"
          description="Label for Duration field on summary for tenders create/edit order page"
          defaultMessage="Duration"
        />,
        <Data.Duration value={tender.duration} />,
      ],
    ]}
  />
);

const TenderStatus = ({ value }: Record<'value', string>) => (
  <span className={classnames('status', value)}>
    {
      <FormattedMessage
        id="tender-create-summary-status"
        description="Text for Status field on summary for tenders create/edit order page"
        defaultMessage="{value, select, active{active} finished{finished} published{published} cancelled{cancelled} planning{planning} archived{archived} other{{value}}}"
        values={{ value: value }}
      />
    }
  </span>
);

type IDynamicEntry = Record<'data', React.ReactNode> &
  Partial<Record<'edit' | 'duplicate' | 'title', string>> &
  Record<'onDelete', () => any>;
const DynamicEntry = ({
  title,
  edit,
  duplicate,
  data,
  onDelete,
}: IDynamicEntry) => {
  const [loading, setLoading] = useState(false);

  return (
    <StackItem
      title={
        <>
          {title}
          <span className="edit-delete">
            {edit && (
              <>
                <Link to={edit}>
                  <FormattedMessage
                    id="tender-create-summary-orders-edit-button"
                    description="Button for edit order for tender summary on tenders create/edit pages"
                    defaultMessage="Edit"
                  />
                </Link>
                |
              </>
            )}
            {duplicate && (
              <>
                <Link to={duplicate}>
                  <FormattedMessage
                    id="tender-create-summary-orders-duplicate-button"
                    description="Button for Duplicate order for tender summary on tenders create/edit pages"
                    defaultMessage="Duplicate"
                  />
                </Link>
                |
              </>
            )}
            <Button
              type="buttonlink"
              onClick={() => {
                setLoading(true);
                onDelete();
              }}
              loading={loading}
              disabled={loading}
            >
              <FormattedMessage
                id="tender-create-summary-orders-delete-button"
                description="Button for Delete order for tender summary on tenders create/edit pages"
                defaultMessage="Delete"
              />
            </Button>
          </span>
        </>
      }
      data={data}
    />
  );
};

const editRoutes = (tender: Tender) => {
  if (tender.started()) return [() => undefined, () => undefined] as const;

  const params = (order: Order, mode?: string) => ({
    tenderId: tender.id,
    orderId: order.id,
    mode,
  });

  return [
    (order: Order, mode: string) =>
      routeUrl('tender-edit-orders', params(order, mode)),
    (order: Order) => routeUrl('tender-duplicate-order', params(order)),
  ] as const;
};

const OrdersDetails = ({ tender, orders }: TenderProp & OrdersProp) => {
  const { matchParams } = usePathTools();
  const { mode = 'edit' } = matchParams('tender-edit-details');
  const history = useHistory();

  const [edit, duplicate] = editRoutes(tender);

  return (
    <RoutedSummary
      stepId="orders"
      title={
        <FormattedMessage
          id="tender-create-summary-orders-heading"
          description="Heading for order section for tender summary on tenders create/edit pages"
          defaultMessage="{name, select, offer{Offers} bid{Bids} other {{name}s}}"
          values={{ name: tender.ordername.order }}
        />
      }
    >
      <ListedData className="summary-orders-list">
        {orders.map(order => (
          <DynamicEntry
            key={order.id}
            title={order.product.name}
            edit={edit(order, mode)}
            duplicate={duplicate(order)}
            onDelete={async () => {
              const params = matchParams('tender-edit-orders');
              if (params.orderId === order.id) {
                history.push(
                  routeUrl('tender-edit-orders', { tenderId: tender.id, mode })
                );
              }

              const result = await service.actions.deleteOrder(
                tender.id,
                order.id
              );
              if (result?.success) {
                toast.success(
                  <>
                    {capitalize(order.ordertype)} for {order.product.name}{' '}
                    removed successfully
                  </>
                );
              } else {
                toast.error(
                  <>
                    An error occurred while removing {order.ordertype} for{' '}
                    {order.product.name}
                  </>
                );
              }
            }}
            data={
              <>
                <Data.IndexUnit value={order.startprice} />
                {' | '}
                {order.reserve.price.val && (
                  <>
                    Reserve price: <Data.Price value={order.reserve.price} />
                    {' | '}
                  </>
                )}
                <Data.Price
                  onZero={
                    <FormattedMessage
                      id="generic-any"
                      description="Text Any"
                      defaultMessage="Any"
                    />
                  }
                  value={order.startprice}
                />{' '}
                / <Data.Volume value={order.volume} /> {order.volume.unit}
                {order.attributes ? (
                  <Data.Attributes value={order.attributes} />
                ) : (
                  <br />
                )}
                <strong>ETD</strong> <Data.ETD value={order.etd} />
                {order.productionmonth && (
                  <>
                    <br />
                    <Data.ProductionMonth
                      value={order.productionmonth.display}
                    />
                  </>
                )}
              </>
            }
          />
        ))}
      </ListedData>
    </RoutedSummary>
  );
};

const ParticipantsDetails = ({ included, excluded }: Participants) => (
  <RoutedSummary
    stepId="participants"
    title={
      <FormattedMessage
        id="tender-create-summary-participants-heading"
        description="Heading for Participants section for tender summary on tenders create/edit pages"
        defaultMessage="Participants"
      />
    }
  >
    <LabelledInfo
      data={[
        [
          <FormattedMessage
            id="tender-create-summary-participants-field-included"
            description="Label for Included field for tender summary on tenders create/edit pages"
            defaultMessage="Included"
          />,
          included.toFixed(0),
        ],
        [
          <FormattedMessage
            id="tender-create-summary-participants-field-excluded"
            description="Label for Excluded field for tender summary on tenders create/edit pages"
            defaultMessage="Excluded"
          />,
          excluded.toFixed(0),
        ],
      ]}
    />
  </RoutedSummary>
);
