import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import moment from 'moment-timezone';
import { ColumnTree } from '../hooks/useTable/defineColumns';
import { unitsOfMeasure } from '~/utils';
import * as R from 'ramda';

export const EtdToColumn = ColumnTree.defineNode(
  'etd-to',
  ({ sortDirection, sortColumn, setSort, productById }) => ({
    title: (
      <div>
        <a
          href="#etd-to"
          className={classnames('icon-sort', 'sortem', {
            [sortDirection]: sortColumn === 'etd-to',
          })}
          onClick={() => setSort('etd-to')}
        >
          <FormattedMessage
            id="marketplace-table-subheading-etdto"
            description="Table sub-heading, ETD to time"
            defaultMessage="To"
          />
        </a>
      </div>
    ),
    key: 'etd-to',
    className: 'col-etd-to',
    render: (text, record, index) => {
      const { totaldeliveries, deliveryfrequency, quarteretd } = record;

      if (quarteretd)
        return {
          children: null,
          props: { colSpan: 0 },
        };

      if (deliveryfrequency) {
        const u: string = R.pathOr('', ['volume', 'unit'], record);
        const product = productById(record.product.id);
        const unit: string = product
          ? R.pathOr('', ['loadingunit', 'desc'], product)
          : R.pathOr(u, [u], unitsOfMeasure);

        return (
          <div className="td-content">
            <span className="tiny-label">
              <FormattedMessage
                id="marketplace-table-cellheading-strip-order"
                description="Heading for 'Strip order' loading cell in marketplace table"
                defaultMessage="Strip order"
              />
            </span>
            <div className="delivery-volume nowrap">
              <strong className="all-black">{totaldeliveries}</strong>
              <span className="times-rotate">+</span>
              <strong className="all-black">{record.volume.delivery}</strong>
              <span className="unit">{unit}</span>
            </div>
            <div>
              <span
                className={classnames(
                  'icon-clock',
                  'periodic-delivery',
                  deliveryfrequency
                )}
              >
                {deliveryfrequency}
              </span>
            </div>
          </div>
        );
      }

      return (
        <div className="td-content">
          <span className="tiny-label">
            <FormattedMessage
              id="marketplace-table-cell-etdto-heading-etd"
              description="Table cell heading, To ETD"
              defaultMessage="ETD to"
            />
          </span>
          <span className="nowrap">
            {moment(record.etd.to).format('DD MMM YYYY')}
          </span>
        </div>
      );
    },
  })
);
