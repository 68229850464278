import axios from 'axios';
import CryptoJSCore from 'crypto-js/core';
import Base64 from 'crypto-js/enc-base64';
import sha256 from 'crypto-js/sha256';
import qs from 'qs';
import { getEnv, getHost } from '~/utils';

function base64URLEncode(str: CryptoJSCore.lib.WordArray) {
  return Base64.stringify(str)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

interface IConfig {
  authUrl: string;
  tokenUrl: string;
  // TODO Safe to rely on window.location in production env?
  redirectUri: string;
  clientId: string;
  tokenKey: string;
  verifierKey: string;
  solutionKey: string;
  trustedKey: string;
}
export class AuthService {
  private config: IConfig;

  constructor(config: IConfig) {
    this.config = config;
  }

  // Generate verifier to use in the workflow, and store it in localStorage
  // Implicit workflow will not need this
  generateVerifier() {
    const v = base64URLEncode(CryptoJSCore.lib.WordArray.random(32));
    localStorage.setItem(this.config.verifierKey, v);
    return v;
  }

  loadVerifier() {
    return localStorage.getItem(this.config.verifierKey);
  }

  // Generate the authorize URL to redirect to
  getAuthorizeUrl(verifier: string | CryptoJSCore.lib.WordArray) {
    const {
      clientId: client_id,
      redirectUri: redirect_uri,
      authUrl,
    } = this.config;
    const params = {
      client_id,
      redirect_uri,
      code_challenge: base64URLEncode(sha256(verifier)),
      code_challenge_method: 'S256',
      response_type: 'code',
    };
    const url = authUrl.slice(-1) === '?' ? authUrl : `${authUrl}?`;
    return `${url}${qs.stringify(params)}`;
  }

  redirectToAuthorizeUrl() {
    const verifier = this.generateVerifier();
    const url = this.getAuthorizeUrl(verifier);
    window.location.href = url;
  }

  // for use in redirectUri page which fills in the location.search containing returned 'code'
  // implicit workflow will not need this
  getToken(code: string) {
    const {
      tokenUrl,
      redirectUri: redirect_uri,
      clientId: client_id,
    } = this.config;
    return axios
      .post(
        tokenUrl,
        qs.stringify({
          grant_type: 'authorization_code',
          redirect_uri,
          client_id,
          code_verifier: this.loadVerifier(),
          code,
        })
      )
      .then(resp => resp.data);
  }

  // Store token in localStorage, hello XSS world.
  saveToken(token: any) {
    localStorage.setItem(this.config.tokenKey, JSON.stringify(token));
    localStorage.removeItem(this.config.verifierKey);
  }

  loadToken() {
    return JSON.parse(localStorage.getItem(this.config.tokenKey) || 'null');
  }

  clearToken() {
    localStorage.removeItem(this.config.verifierKey);
    localStorage.removeItem(this.config.tokenKey);
  }

  saveTrusted(code: string) {
    localStorage.setItem(this.config.trustedKey, JSON.stringify(code));
  }

  loadTrusted() {
    return JSON.parse(localStorage.getItem(this.config.trustedKey) || 'null');
  }

  clearTrusted() {
    localStorage.removeItem(this.config.trustedKey);
  }

  saveSolution(sid: string) {
    try {
      sessionStorage.setItem(this.config.solutionKey, sid);
      localStorage.setItem(this.config.solutionKey, sid);
    } catch {
      // Empty
    }
  }

  loadSolution() {
    return (
      sessionStorage.getItem(this.config.solutionKey) ||
      localStorage.getItem(this.config.solutionKey)
    );
  }

  clearSolution() {
    return sessionStorage.removeItem(this.config.solutionKey);
  }
}

export const authService = new AuthService({
  authUrl: getEnv(import.meta.env.VITE_APP_AUTH_URL),
  tokenUrl: getEnv(import.meta.env.VITE_APP_TOKEN_URL),
  // TODO Safe to rely on window.location in production env?
  redirectUri: getEnv(import.meta.env.VITE_APP_REDIRECT_URI) || getHost(),
  clientId: getEnv(import.meta.env.VITE_APP_CLIENT_ID),
  tokenKey: '__t',
  verifierKey: '__v',
  solutionKey: '__s',
  trustedKey: '__trusted',
});
