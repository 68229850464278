import React, { useContext, useState } from 'react';
import { Form, Button, Loading } from 'Components/nui';
import { LoadingState } from 'Components/Layout.js';
import { ClassicResult, ShowResult } from 'Components/Result';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { promisify } from 'es6-promisify';
import * as R from 'ramda';
import { useAsync } from 'react-use';
import { ApiContext } from '~/context';
import { useSiteTitle } from '~/hooks.js';
import { Product } from '~/models';

import { useFormWrapper, submitError } from './New';
import { prepareInitialDataFromSchema } from '~/utils';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useLockBodyScroll } from '~/hooks';

function OrderForm({
  form,
  product,
  order,
  initialData,
  schema,
  submitState,
  handleModalClose,
  solution,
}) {
  const api = useContext(ApiContext);
  const orderType = order.type;

  const updateMarketplace = useStoreActions(
    R.path(['marketplace', 'updateData'])
  );

  // TODO Move this wrapper to outer if it affects performance
  const formWrapper = useFormWrapper({
    form,
    product,
    initialData,
    schema,
    orderType,
    solution,
  });
  // Already bound
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);

  async function handleSubmit(e) {
    e.preventDefault();
    // Client-side validation
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }
    // Server-side validation
    submitState.setLoading(true);
    const serialized = {
      ...formWrapper.serialize(),
      product: product.id,
    };

    const { files, ...fields } = serialized;
    const data = new FormData();
    for (const [name, value] of Object.entries(fields)) {
      if (value !== undefined) {
        if (Array.isArray(value)) for (const v of value) data.append(name, v);
        else data.append(name, value);
      }
    }
    if (!data.has('splittable')) {
      data.append('splittable', false);
    }
    if (files) {
      for (const file of files) data.append('files[]', file);
    }

    try {
      const result = await api.getData(
        { type: 'orders', id: order.id, getResult: x => x },
        null,
        { method: 'put', data }
      );
      // TODO postProcess
      submitState.setResult(result);

      updateMarketplace();
    } catch (err) {
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        err
      );
      if (errors) {
        // TODO scrollIntoView
        formWrapper.setErrors(errors);
      } else {
        // report the unexcepted error
        submitState.setResult(err);
      }
    }
    submitState.setLoading(false);
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        {formWrapper.render()}
        <Form.Item>
          <div className="button-set">
            <Button
              type="primary"
              htmlType="submit"
              loading={submitState.loading}
            >
              <FormattedMessage
                id="orderform-edit-button-save-changes-label"
                description="Label for `Save changes` button on edit order form"
                defaultMessage="Save changes"
              />
            </Button>
            <Button type="simple" onClick={handleModalClose}>
              <FormattedMessage
                id="orderform-edit-button-cancel-label"
                description="Label for `Cancel` button on edit order form"
                defaultMessage="Cancel"
              />
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

const WrappedOrderForm = Form.create()(OrderForm);

const submitSuccess = (result, submitState, handleModalClose) => (
  <ClassicResult
    title={
      <FormattedMessage
        id="orderform-edit-success-heading"
        description="Heading `Thank you` on successful submit on edit order form"
        defaultMessage="Thank you"
      />
    }
  >
    <p>
      {R.prop('message', result)}
      <br />
      <Link to={`/orders/${result.order.id}`}>
        <FormattedMessage
          id="orderform-edit-success-view-order-text"
          description="Label for `View order` text on successful submit on edit order form"
          defaultMessage="View order {pid}"
          values={{ pid: result.order.pid }}
        />
      </Link>
    </p>
    <hr className="mb-15 mt-15" />
    <div className="button-set">
      <Button type="primary" className="mb-5" onClick={handleModalClose}>
        <FormattedMessage
          id="orderform-edit-button-close-panel-label"
          description="Label for `Close panel` button on successful submit on edit order form"
          defaultMessage="Close panel"
        />
      </Button>
    </div>
  </ClassicResult>
);

// Entry component for selecting tradable product to decide form schema.
export function OrderEdit({ match, handleModalClose }) {
  useLockBodyScroll();
  const orderId = match.params.orderId;
  const api = useContext(ApiContext);

  const products = useStoreState(state => state.auth.solutionProducts);

  const [title, setTitle] = useState('Edit order');
  const [order, setOrder] = useState(null);
  const [product, setProduct] = useState(null);

  useSiteTitle(title);

  useAsync(async () => {
    const data = await api.getData({
      type: 'orders',
      id: orderId,
      getResult: x => x.order,
    });

    setTitle(
      <FormattedMessage
        id="orderform-edit-heading"
        description="Heading for `edit {type}` text on edit order form"
        defaultMessage="Edit market {type, select, offer {offer} bid {bid} other {{type}}}"
        values={{ type: data.type }}
      />
    );
    setOrder(data);
  }, [orderId]);

  const solution = useStoreState(state => state.auth.solution);

  const schemaState = useAsync(() => {
    if (!order || R.isEmpty(products)) return Promise.resolve();

    setProduct(new Product(R.find(R.propEq('id', order.product.id), products)));
    const data = api.getData({ type: 'orders', id: `${orderId}/schema` });

    return data;
  }, [order, products]);

  return (
    <div className="wrapper">
      <ShowResult
        renderSuccess={submitSuccess}
        renderError={submitError}
        handleModalClose={handleModalClose}
      >
        {submitState => (
          <div className="nui-form order-form">
            {!product || !order ? (
              <Loading size="medium" />
            ) : (
              <LoadingState state={schemaState}>
                {schema => (
                  <>
                    <h2>
                      {title} <small>{order.pid}</small>
                    </h2>
                    <Form.Item
                      label={
                        <FormattedMessage
                          id="orderform-edit-field-product-label"
                          description="Label for `product` field on edit order form"
                          defaultMessage="Product"
                        />
                      }
                      required={true}
                    >
                      <strong className="block mt--15 mb--10">
                        {product.name}
                      </strong>
                    </Form.Item>
                    <WrappedOrderForm
                      // Reset form data when product changes
                      key={`form-${order.id}`}
                      schema={schema}
                      product={product}
                      order={order}
                      initialData={prepareInitialDataFromSchema(schema)}
                      submitState={submitState}
                      handleModalClose={handleModalClose}
                      solution={solution}
                    />
                  </>
                )}
              </LoadingState>
            )}
          </div>
        )}
      </ShowResult>
    </div>
  );
}
