import type { Tender } from '~/models/tenders';
import React from 'react';
import { Route, Link, Switch } from 'react-router-dom';
import Orders from './Orders';
import {
  TenderOwned,
  TenderInfo,
  TenderStatus,
  TenderActions,
  TenderCountdown,
  TenderActivity,
} from './components';
import Trades from './Trades';
import {
  routeUrl,
  matchMany,
  RoutingTable,
  withPreload,
  usePathTools,
  withRouteX,
} from '~/router';
import { PermissionDenied } from 'Components/InfoMessage';
import classnames from 'classnames';
import * as service from '~/services/tenders';
import RankingTotals from './RankingTotals';
import Report from './Report';
import { FormattedMessage } from 'react-intl';

type TenderProp = Record<'tender', Tender>;
type ChildrenProp = Partial<Record<'children', React.ReactNode>>;
type ClassProp = Partial<Record<'className', string>>;

export const View = withPreload({
  route: 'tender-view',
  preload: service.observe.tender(),
})(({ data: tender }) => {
  const { matchParams } = usePathTools();
  const params = matchParams('tender-view');

  if (['stopped', 'cancelled'].includes(tender.status))
    return (
      <PermissionDenied className="mt--1 mb--1 p-100">
        <FormattedMessage
          id="tender-details-text-cancelled"
          description="Text for cancelled tender in tender details"
          defaultMessage="This tender has been cancelled."
        />

        <br />
        <Link to={routeUrl('tender-overview')}>
          <FormattedMessage
            id="tender-details-link-return"
            description="Link to return to overview in tender details"
            defaultMessage="Return to overview"
          />
        </Link>
      </PermissionDenied>
    );

  return (
    <TenderItem tender={tender} view="detail" className="viewing">
      <RoutingTable
        routes={[Orders, Trades, Report]}
        redirect={routeUrl('tender-orders', params)}
      />
    </TenderItem>
  );
});

export default withRouteX({ name: 'tender-view' })(View);

type ITenderItem = TenderProp & ChildrenProp & ClassProp & { view?: string };
export const TenderItem = ({
  tender,
  className,
  view,
  children,
}: ITenderItem) => (
  <div className={classnames('tender-item', className)}>
    <div className="tender-item-content">
      <TenderHeader tender={tender} view={view} />
      {children}
    </div>
  </div>
);

export const TenderHeader = ({
  tender,
  view,
}: TenderProp & { view?: string }) => {
  const showActivity = tender.owned && tender.isRunning();

  return (
    <>
      {view !== 'detail' && (
        <div className="tender-item-header">
          <h2 className="tender-name">
            <TenderOwned tender={tender} />
            <span
              className={classnames(
                `type-${tender.type}`,
                'mr-10',
                'inline',
                'tender-overview-page'
              )}
            >
              <FormattedMessage
                id="tender-generic-badge-sale-type"
                description="Label for generic buy/sell badge"
                defaultMessage="{tenderType, select, buy {BUY} sell {SELL} other {{tenderType}}}"
                values={{ tenderType: tender.type }}
              />
            </span>
            <Link
              to={routeUrl('tender-view', { tenderId: tender.id })}
              title={`View ${tender.name} details`}
            >
              {tender.name}
            </Link>
          </h2>
          <div className="tender2-status">
            <TenderCountdown tender={tender} />
            <TenderStatus tender={tender} />
          </div>
          <div className="tender2-details">
            <div className="tender2-action-box">
              <Switch>
                <Route
                  exact
                  path={matchMany(
                    'tender-overview',
                    'tender-history',
                    'tender-trades'
                  )}
                >
                  <Link
                    to={routeUrl('tender-view', { tenderId: tender.id })}
                    className="nui-button nui-primary nui-small pb-7 pt-7 inline-block"
                  >
                    <FormattedMessage
                      id="tender-list-button-view"
                      description="Button to view tender on tender list"
                      defaultMessage="View <span>tender</span>"
                      values={{
                        span: chunks => (
                          <span className="show-for-small">{chunks}</span>
                        ),
                      }}
                    />
                  </Link>
                </Route>
                <Route>
                  <div className="tender-activity">
                    {showActivity && <TenderActivity />}
                  </div>
                </Route>
              </Switch>
              <TenderActions tender={tender} />
            </div>
            <TenderInfo tender={tender} />
          </div>
        </div>
      )}
    </>
  );
};
