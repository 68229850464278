import { WarehouseData, AddressData } from '../modelTypes';
import Base from '../Model';
import * as R from 'ramda';

export class Address<T extends AddressData = AddressData> extends Base<T> {
  get description() {
    const details = [
      this.street,
      this.suburb,
      this.city,
      this.geoState,
      this.country.name,
      this.zip,
    ];
    return R.filter(x => !!x, details).join(', ');
  }

  get street() {
    return this.state.street;
  }

  get suburb() {
    return this.state.suburb;
  }

  get zip() {
    return this.state.zip;
  }

  get city() {
    return this.state.city;
  }

  get geoState() {
    return this.state.state;
  }

  get country() {
    return this.state.country;
  }
}

export default class Warehouse extends Address<WarehouseData> {
  get name() {
    return this.state.description;
  }

  get description() {
    return `${this.name} (${this.state.desc})`;
  }

  get default() {
    return this.state.default;
  }

  get active() {
    return this.state.active;
  }

  get port() {
    return this.state.port;
  }
}
