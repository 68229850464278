import React, { useState } from 'react';
import { UsingSchema, useSchema, useCustomerRequest } from './EditContext';
import { FormWrapper } from 'Components/form';
import { mergeSchema } from 'Components/form/utils';
import { promisify } from 'es6-promisify';
import { Button, Alert, Form } from 'Components/nui';
import { toast } from 'react-toastify';
import * as R from 'ramda';
import { NextStep } from 'Components/nui/Wizard';
import { Route, useRouteMatch, useHistory } from 'react-router-dom';

export default () => {
  return (
    <div className="content-users-details">
      <UsingSchema schema="users">
        <UserForm />
      </UsingSchema>
    </div>
  );
};

function useFormWrapper({ form, schema, customer, user }: any) {
  const fields = [
    {
      name: 'role',
      label: 'Role',
      type: 'Select',
      choices: R.pipe(
        R.prop('choices') as (p: any) => any,
        R.map(([value, label]) => ({ value, label, key: value }))
      ),
    },
    {
      name: 'firstname',
      label: 'First name',
      type: 'Input',
    },
    {
      name: 'lastname',
      label: 'Last name',
      type: 'Input',
    },
    {
      name: 'ddiphone',
      label: 'Phone',
      type: 'Input',
      props: { type: 'tel' },
    },
    {
      name: 'mobilephone',
      label: 'Mobile',
      type: 'Input',
    },
    {
      name: 'email',
      label: 'Email address',
      type: 'Input',
      props: { type: 'email', placeholder: 'E.g. you@yourdomain.com' },
    },
    {
      name: 'timezone',
      label: 'Timezone',
      type: 'Select',
      choices: R.pipe(
        R.prop('choices') as (p: any) => any,
        R.map(([value, label]) => ({ value, label, key: value }))
      ),
    },
  ];

  const initialData = { ...user };

  return new FormWrapper(form, initialData, mergeSchema(schema, fields));
}

interface IUser {
  firstname: string;
  lastname: string;
}
const BaseForm = ({ form, schema }: any) => {
  const { customer, actions, validation } = useCustomerRequest();
  const { params } = useRouteMatch<{ userId?: string }>();
  const { userId } = params;
  const user: any = R.find(R.propEq('id', userId), customer.users || []) || {};
  const formWrapper = useFormWrapper({ form, schema, customer, user });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);
  const [loading, setLoading] = useState(false);
  const [last, setLast] = useState<[string, string]>();
  const history = useHistory();

  async function handleSubmit(e: any) {
    e.preventDefault();

    setLoading(true);
    setLast(undefined);

    try {
      await validateFieldsAndScroll();
    } catch (err) {
      setLoading(false);
      return;
    }

    try {
      const data = { id: user.id, ...formWrapper.serialize() };
      const { firstname, lastname } = data;
      await actions.editUser(data);

      const name = `${firstname} ${lastname}`;
      const action = user.id ? 'updated' : 'added';
      setLast([name, action]);

      form.resetFields();
      setLoading(false);
      toast(`${name} has been ${action} successfully`, { type: 'success' });
      history.push(`/customer-requests/${customer.id}/users`);
    } catch (err) {
      if (err?.response && err.response?.status === 400) {
        const validation = err.response?.data?.errors || [];
        if (validation.length) {
          formWrapper.setErrors(validation[0]?.description || []);
        } else {
          // wtf
          toast('Form did not validate.', { type: 'error' });
          console.error('form did not validate', err);
        }
      } else {
        toast(
          'There was an error while submitting your form. Please try again later.',
          { type: 'error' }
        );
      }
      setLoading(false);
    }

    window.scrollTo(0, 0);
  }

  return (
    <div className="nui-form">
      <Form onSubmit={handleSubmit}>
        <div className="form-steps-container">
          {last && (
            <Alert hasicon type="success" className="mb-20">
              <strong className="all-black">{last[0]}</strong> has been{' '}
              {last[1]} successfully.{' '}
              {validation.formComplete ? (
                <>
                  You can add another, or{' '}
                  <NextStep>review form and submit</NextStep>.
                </>
              ) : (
                <>
                  You may add another, or correct the errors on previous steps
                  before submitting the form to Nui for processing.
                </>
              )}
            </Alert>
          )}
          {formWrapper.render()}
          <div className="sticky-btm button-set mt-20 trio trio-for-small">
            <Button
              type="primary"
              disabled={loading}
              loading={loading}
              htmlType="submit"
            >
              Save
            </Button>
            <NextStep />
          </div>
        </div>
      </Form>
    </div>
  );
};

type IWrappedForm = (props: any) => React.ReactElement;
const WrappedForm: IWrappedForm = Form.create()(BaseForm) as any;

const UserForm = () => {
  const schema = useSchema('users');
  return (
    <Route path="/customer-requests/:id/users/:userId?">
      <WrappedForm schema={schema} />
    </Route>
  );
};
