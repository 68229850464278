import type * as Nivo from '@nivo/core';
import type * as Line from '@nivo/line';
import * as Models from '~/models/tenders';
import React, { useState } from 'react';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import DropCheck from 'Components/nui/form/DropCheck';
import classnames from 'classnames';
import { ResponsiveLine } from '@nivo/line';
import { capitalize } from '~/utils';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import moment, { Moment } from 'moment-timezone';

type PTender = Record<'tender', Models.Tender>;
type PReport = Record<'charts', Models.TenderCharts>;
type PChildren = Record<'children', React.ReactNode>;
type PClassName = Partial<Record<'className', string>>;

type FilterState = string[];
type FilterReducer = (current: FilterState) => FilterState;

const nivoFmt = 'YYYY-MM-DDTHH:mm:ss';
const nivoParse = '%Y-%m-%dT%H:%M:%S';

function useOrderFilter(charts: Models.TenderCharts) {
  const [filters, setState] = useState(charts.orders);

  const onChange = (reducer: FilterReducer) => {
    setState(reducer);
  };

  return { filters, onChange };
}

interface IOrderFilter {
  title: string;
  name: string;
  options: [string, string][];
  value: FilterState;
  onChange(reducer: FilterReducer): void;
  orderType: 'bid' | 'offer';
}
const OrderFilter = ({
  title,
  name,
  options,
  value,
  onChange,
  orderType,
}: IOrderFilter) => {
  return (
    <DropCheck
      title={title}
      name={name}
      value={value}
      options={options}
      onChange={(_, values) => void onChange(() => values as string[])}
      itemType={orderType + 's'}
    />
  );
};

function convertTimeOffset(dt: Moment) {
  // @ts-ignore
  const timeZone: string = moment.defaultZone?.name;
  return new Date(dt.clone().toDate().toLocaleString('en-US', { timeZone }));
}

type ChartLegend = { legend?: { id: string; color: string; label: string }[] };
type IFilteredChart = IOrderFilter & PChildren & PClassName & ChartLegend;
const FilteredChart = ({ className, children, ...props }: IFilteredChart) => (
  <div className={classnames('chart-section', className)}>
    <div className="chart-filter filter-section">
      <div className="nui-field-holder">
        <OrderFilter {...props} />
      </div>
    </div>
    <div className="chart">{children}</div>
    {props.legend && (
      <ul className="chart-legend">
        {props.legend.map(item => (
          <li key={item.id}>
            <span className="color-block mr-2 mt-2">
              <ColourSquare colour={item.color} />
            </span>{' '}
            <span className="name mr-15">{item.label}</span>
          </li>
        ))}
      </ul>
    )}
  </div>
);

const extensionMarker = (
  ext: Date,
  rest?: Nivo.CartesianMarkerProps<string>
) => ({
  axis: 'x' as const,
  value: ext as unknown as string, // yuck as shit

  legend: 'Extended time',
  legendPosition: 'top' as const,
  textStyle: { color: '#333', fontSize: '12px' },
  ...rest,
});

type IColourSquare = { colour: string };
export const ColourSquare = ({ colour }: IColourSquare) => (
  <span
    style={{
      background: colour,
      display: 'inline-block',
      width: 12,
      height: 12,
    }}
  />
);

type PriceData = Line.Point['data'] & {
  autobid: boolean;
  dt: string;
  product: string;
  priceStr: string;
  price_unit: string;
  price_currency: string;
};
const PriceTooltip = ({ point }: Line.PointTooltipProps) => {
  const { autobid, priceStr, dt, product, price_unit, price_currency } =
    point.data as PriceData;
  const { color } = point;

  return (
    <div className="price-tooltip nui-tooltip">
      <ul>
        <li className="block w-16">
          <span className="inline-block mt-4">
            <ColourSquare colour={color} />
          </span>
        </li>
        <li className="tooltip-product">
          <strong className="all-black block pl-16 mt--20">{product}</strong>
        </li>
        {autobid && (
          <li className="tooltip-autobid block pl-16">
            <strong className="all-black">Autobid</strong>
          </li>
        )}
        <li className="tooltip-time block pl-16">{dt}</li>
        <li className="tooltip-price block pl-16">
          {priceStr} {price_currency}/{price_unit}
        </li>
      </ul>
    </div>
  );
};

const AutobidMarker = ({
  size,
  color,
  borderWidth,
  datum,
}: Line.PointSymbolProps) => {
  return (
    <g>
      {datum.autobid && (
        <circle
          fill="#fff"
          r={size / 2}
          strokeWidth={borderWidth}
          stroke={color}
        />
      )}
      <circle
        r={size / 5}
        strokeWidth={borderWidth}
        stroke={color}
        fill={color}
      />
    </g>
  );
};

export const CountersNorm = ({ tender, charts }: PTender & PReport) => {
  const { filters, onChange } = useOrderFilter(charts);
  const showAll = filters.length === 0;
  const data = charts.norm.filter(o => showAll || filters.includes(o.id));

  return (
    <FilteredChart
      className="counter-norm-chart"
      title="Select orders"
      name="counter-norm"
      value={filters}
      onChange={onChange}
      options={charts.options}
      orderType={tender.ordername.order}
    >
      <ResponsiveLine
        data={data}
        margin={{ top: 10, right: 10, bottom: 50, left: 50 }}
        xFormat={'time:' + nivoParse}
        xScale={{
          type: 'time',
          format: nivoParse,
          precision: 'second',
          useUTC: false,
          min: tender.start.format(nivoFmt),
          max: tender.extended.format(nivoFmt),
        }}
        yScale={{
          type: 'linear',
          min: 'auto',
        }}
        axisLeft={{
          legend: '% of max price',
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        axisBottom={{
          format: '%H:%M:%S',
          legend: 'Time',
          legendPosition: 'middle',
          legendOffset: 40,
        }}
        markers={
          tender.extramins
            ? [extensionMarker(convertTimeOffset(tender.finish))]
            : undefined
        }
      />
    </FilteredChart>
  );
};

export const CountersPrice = ({ tender, charts }: PTender & PReport) => {
  const { filters, onChange } = useOrderFilter(charts);
  const showAll = filters.length === 0;
  const data = charts.basic.filter(o => showAll || filters.includes(o.id));

  return (
    <FilteredChart
      className="counter-price-chart"
      title="Select all"
      name="counter-price"
      value={filters}
      onChange={onChange}
      options={charts.options}
      legend={data}
      orderType={tender.ordername.order}
    >
      <ResponsiveLine
        data={data}
        margin={{ top: 10, right: 10, bottom: 50, left: 50 }}
        pointSymbol={AutobidMarker}
        pointSize={16}
        pointBorderWidth={1}
        xFormat={'time:' + nivoParse}
        xScale={{
          type: 'time',
          format: nivoParse,
          precision: 'second',
          useUTC: false,
          min: tender.start.format(nivoFmt),
          max: tender.extended.format(nivoFmt),
        }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
        }}
        axisLeft={{
          legend: 'Price',
          legendPosition: 'middle',
          legendOffset: -70,
        }}
        axisBottom={{
          format: '%H:%M:%S',
          legend: 'Time',
          legendPosition: 'middle',
          legendOffset: 50,
          tickRotation: -30,
        }}
        markers={
          tender.extramins
            ? [extensionMarker(convertTimeOffset(tender.finish))]
            : undefined
        }
        isInteractive={true}
        enableCrosshair={true}
        useMesh={true}
        tooltip={PriceTooltip}
        colors={{ datum: 'color' }}
      />
    </FilteredChart>
  );
};

export const CounterHistoryChart = ({ tender, charts }: PTender & PReport) => {
  const { filters, onChange } = useOrderFilter(charts);
  const showAll = filters.length === 0;
  const data = charts.freq.filter(o => showAll || filters.includes(o.id));

  return (
    <FilteredChart
      className="counter-history-chart"
      title="Select orders"
      name="counter-history"
      value={filters}
      onChange={onChange}
      options={charts.options}
      orderType={tender.ordername.order}
    >
      <ResponsiveScatterPlot
        data={data}
        margin={{ top: 10, right: 10, bottom: 50, left: 50 }}
        xFormat={'time:' + nivoParse}
        xScale={{
          type: 'time',
          format: nivoParse,
          precision: 'second',
          useUTC: false,
          min: tender.start.format(nivoFmt),
          max: tender.extended.format(nivoFmt),
        }}
        yScale={{
          type: 'point',
        }}
        axisLeft={{
          legend: capitalize(tender.ordername.order),
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        axisBottom={{
          format: '%H:%M:%S',
          legend: 'Time',
          legendPosition: 'middle',
          legendOffset: 40,
        }}
        markers={
          tender.extramins
            ? [extensionMarker(convertTimeOffset(tender.finish))]
            : undefined
        }
      />
    </FilteredChart>
  );
};

export const CounterHeat = ({ tender, charts }: PTender & PReport) => {
  const { filters, onChange } = useOrderFilter(charts);
  const showAll = filters.length === 0;
  const data = charts.heat.filter(o => showAll || filters.includes(o.label));

  return (
    <FilteredChart
      className="counter-heat-chart"
      title="Select orders"
      name="counter-heat"
      value={filters}
      onChange={onChange}
      options={charts.options}
      orderType={tender.ordername.order}
    >
      <ResponsiveHeatMap
        data={data}
        margin={{ top: 10, right: 10, bottom: 50, left: 50 }}
        axisLeft={{
          legend: capitalize(tender.ordername.order),
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        axisBottom={{
          legend: 'Time',
          legendPosition: 'middle',
          legendOffset: 40,
        }}
      />
    </FilteredChart>
  );
};
