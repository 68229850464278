import React from 'react';
import * as R from 'ramda';
import Address from 'Components/Address';
import { FormattedMessage } from 'react-intl';

export default ({ partners, type }) => {
  const partner = R.path([type], partners);

  if (!partner) return null;

  const { name, email, phone, companyno } = partner;

  return (
    <>
      <h2>{type === 'consignee' ? 'Consignee' : 'Buyer'}</h2>

      <ul className="trade-details-list partners-list details">
        <li className="reversed">
          <strong>
            <FormattedMessage
              id="marketrow-partner-field-name-label"
              description="Label for `Name` field on partner in Marketrow"
              defaultMessage="Name"
            />
          </strong>
          <span>{name}</span>
        </li>
        {email && (
          <li className="reversed">
            <strong>
              <FormattedMessage
                id="marketrow-partner-field-email-label"
                description="Label for `Email` field on partner in Marketrow"
                defaultMessage="Email"
              />
            </strong>
            <span>
              <a href={`mailto:${email}`} title={`${email}`}>
                {email}
              </a>
            </span>
          </li>
        )}
        {phone && (
          <li className="reversed">
            <strong>
              <FormattedMessage
                id="marketrow-partner-field-phone-label"
                description="Label for `Phone` field on partner in Marketrow"
                defaultMessage="Phone"
              />
            </strong>{' '}
            <span>{phone}</span>
          </li>
        )}
        {companyno && (
          <li className="reversed">
            <strong>
              <FormattedMessage
                id="marketrow-partner-field-companyno-label"
                description="Label for `Company number` field on partner in Marketrow"
                defaultMessage="Company no"
              />
            </strong>{' '}
            <span>{companyno}</span>
          </li>
        )}
        <li>
          <strong className="block">
            <FormattedMessage
              id="marketrow-partner-field-address-label"
              description="Label for `Address` field on partner in Marketrow"
              defaultMessage="Address"
            />
          </strong>
          <Address address={partner} />
        </li>
      </ul>
    </>
  );
};
