import React from 'react';

import * as R from 'ramda';
import FNumber from 'Components/FNumber';
import { getDecimalCount } from '~/utils';
import moment from 'moment-timezone';

import * as Data from 'Components/Data';

interface Index {
  type: 'Modifier' | 'Multiplier';
  category: string;
  name: string;
  step: number;
  period: 'Day' | 'Week' | 'Month';
  offset: 'Prior to' | 'Of' | 'After';
}

interface Order {
  _price?: any;
  price: any;
  index?: Index;
  indexdate?: string;
}

type IOperator = { index: Index; price: number };
export const Operator = ({ index, price }: IOperator) =>
  index.type === 'Multiplier' ? (
    <>&times;</>
  ) : price >= 0 ? (
    <>&#43;</>
  ) : (
    <>&minus;</>
  );

type IPriceIndex = { order: Order };
export const PriceIndex = ({ order }: IPriceIndex) => {
  const price = order._price || order.price;

  const step: number = order.index
    ? order.index.step
    : R.propOr(0.01, 'step', price);
  const decimalCount = getDecimalCount(step);

  const val: number = R.pathOr(
    R.propOr(0, 'val', price),
    [price.currency, price.incoterm, 'val'],
    price
  );

  return (
    <>
      {order.index && <Operator index={order.index} price={val} />}
      <FNumber
        value={order.index ? Math.abs(val) : val}
        decimalCount={decimalCount}
      />
    </>
  );
};

type IUnit = { order: Order; priceunit?: string };
export const IndexUnit = ({ order, priceunit }: IUnit) => {
  const unit =
    priceunit !== undefined
      ? priceunit
      : R.path<string>([order._price.currency, 'unit'], order._price);
  const date = moment(order.indexdate);

  return order.index ? (
    <>
      {unit && <span className="block mb-5">{unit}</span>}
      <Data.IndexSummary index={order.index} date={date} />
    </>
  ) : (
    <>{unit}</>
  );
};

export const AltIndexUnit = ({ order, priceunit }: IUnit) => {
  const unit =
    priceunit !== undefined
      ? priceunit
      : R.path<string>([order._price.currency, 'unit'], order._price);
  const date = moment(order.indexdate);

  return order.index ? (
    <>
      {order.index.type === 'Modifier' && unit && (
        <span className="index-unit">{unit}</span>
      )}
      <Data.IndexSummary
        index={order.index}
        date={date}
        className={'trade-page'}
      />
    </>
  ) : (
    <span className="cur-unit">{unit}</span>
  );
};
