import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { useStoreState, useStoreActions } from 'easy-peasy';
import classNames from 'classnames';
import FNumber from 'Components/FNumber';
import { Order } from '~/models';
import { api } from '~/api';
import OwnerButtons from './OwnerButtons';
import PrepopulateButtons from './PrepopulateButtons';
import Tabs from './Tabs';
import Partner from './Partner';
import LoadingDetails from 'Components/Lists/LoadingDetails';
import ExtraDetails from 'Components/Lists/ExtraDetails';
import Comment from 'Components/Lists/Comment';
import Locations from 'Components/Lists/Locations';
import Contact from 'Components/Lists/Contact';
import { Modal, Loading } from 'Components/nui';
import Counterparties from 'Components/Lists/Counterparties';
import Documents from 'Components/Lists/Documents';
import DisplayedPrices from 'Pages/Products/DisplayedPrices';
import { useToggle } from 'react-use';
import { unitsOfMeasure } from '~/utils';
import { FormattedMessage } from 'react-intl';
import { IndexUnit, PriceIndex } from 'Components/nui/PriceIndex';

export default ({ id, warehouse, updated }) => {
  const productById = useStoreState(state => state.auth.productById);
  const isdao = useStoreState(state => state.auth.isdao);
  const solution = useStoreState(state => state.auth.solution);
  const productsTradeActions = useStoreState(
    state => state.auth.userProductsTradeActions
  );
  const setOrderQuestions = useStoreActions(
    action => action.marketdepth.setOrderQuestions
  );

  const [order, setOrder] = useState(null);
  const [curCur, setCurCur] = useState(null);
  const [pricesDrawerVis, setPricesDrawerVis] = useState(false);
  const [refresh, doRefresh] = useToggle();

  const productId = R.path(['product', 'id'], order);
  const product = productId ? productById(productId) : null;

  const p_id = product?.p_id;
  const tradeActions = R.pathOr([], [p_id], productsTradeActions);

  const canViewCustomerPrices = solution.freight && 'sell' in tradeActions;

  useEffect(() => {
    const fetch = async () => {
      let params = {};
      if (warehouse && !isdao) {
        params.warehouse = warehouse.id;
      }
      const data = await api.getData(
        {
          id,
          type: 'orders',
          getResult: x => new Order(x.order),
        },
        params
      );
      setOrder(data);
      setCurCur(data.price.currency);
      setOrderQuestions({ id, questions: data.questions || undefined });
    };

    fetch();
  }, [id, warehouse, refresh, updated]);

  if (!order) {
    return (
      <div className="mb-20 mt-10">
        <Loading size="medium" />
      </div>
    );
  }

  return (
    <div className="offers-bids-details" data-id={id}>
      {canViewCustomerPrices && pricesDrawerVis && (
        <Modal
          close={() => setPricesDrawerVis(false)}
          defaultFooter
          size="medium"
        >
          <DisplayedPrices
            auto
            productId={product.id}
            init={{
              price: R.path(['price', 'val'], order),
              loading: R.path(['loading', 'id'], order),
              fromaddr: R.path(['fromaddr', 0, 'id'], order),
            }}
          />
        </Modal>
      )}

      <div>
        <div className="table-box-three">
          <div className="unit-details">
            <h2 className="details">
              <FormattedMessage
                id="marketrow-orderdetails-header"
                description="Header for Order details in Marketrow"
                defaultMessage="Details"
              />
            </h2>

            {order.currencies && order.currencies.length > 1 && (
              <div className="unit-detail available-currencies">
                <h4>
                  <FormattedMessage
                    id="marketrow-orderdetails-field-availablecurrencies-label"
                    description="Label for `Available currencies` field on Order details in Marketrow"
                    defaultMessage="Available currencies"
                  />
                </h4>
                <ul>
                  {order.currencies.map(item => (
                    <li key={item.currency}>
                      <button
                        className={classNames({
                          active: item.currency === curCur,
                        })}
                        type="button"
                        onClick={() => {
                          setCurCur(item.currency);
                        }}
                      >
                        {item.currency}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="unit-detail unit-detail-price">
              <h4>
                <FormattedMessage
                  id="marketrow-orderdetails-field-price-label"
                  description="Label for `Price` field on Order details in Marketrow"
                  defaultMessage="Price"
                />
              </h4>
              {order._price.status === 'undetermined' ? (
                <span className="openordertypedetails">
                  <FormattedMessage
                    id="marketplace-table-subheading-price-not-available"
                    description="Label for `Not available` field on Order details in Marketrow"
                    defaultMessage="Not available"
                  />
                </span>
              ) : order._price.status === 'open' ? (
                <span
                  className={classNames('openordertypedetails', order.type)}
                >
                  <FormattedMessage
                    id="marketrow-orderdetails-field-open-label"
                    description="Label for `Open` field on Order details in Marketrow"
                    defaultMessage="Open"
                  />{' '}
                  {order.type}
                </span>
              ) : order.index ? (
                <>
                  <PriceIndex order={order} /> <IndexUnit order={order} />
                </>
              ) : order.price.val > 0 ? (
                <>
                  <FNumber
                    value={order.getPriceObjByCur(curCur).val}
                    decimalCount={order.priceDecimalCount}
                  />{' '}
                  <span>{order.getPriceObjByCur(curCur).unit}</span>
                  {R.hasPath(
                    ['_price', order._price.currency, 'tax'],
                    order
                  ) && (
                    <>
                      <h4 className="mt-5">
                        <FormattedMessage
                          id="marketrow-orderdetails-field-tax-label"
                          description="Label for `Tax` field on Order details in Marketrow"
                          defaultMessage="Tax"
                        />{' '}
                      </h4>
                      {R.pathOr(
                        'n/a',
                        ['_price', order._price.currency, 'tax'],
                        order
                      )}{' '}
                      <span>
                        {R.pathOr(
                          'n/a',
                          ['_price', order._price.currency, 'unit'],
                          order
                        )}
                      </span>
                    </>
                  )}
                  {R.hasPath(['_price', 'displayed_currency'], order) && (
                    <>
                      <h4 className="mt-5">
                        <FormattedMessage
                          id="marketrow-orderdetails-field-ownerprice-label"
                          description="Label for `Owner price` field on Order details in Marketrow"
                          defaultMessage="Owner price"
                        />{' '}
                        (
                        {R.pathOr(
                          'n/a',
                          ['_price', 'displayed_incoterm'],
                          order
                        )}
                        )
                      </h4>
                      <FNumber
                        value={R.path(
                          [
                            '_price',
                            order._price.displayed_currency,
                            order._price.displayed_incoterm,
                            'val',
                          ],
                          order
                        )}
                        decimalCount={order.priceDecimalCount}
                      />{' '}
                      <span>
                        {R.pathOr(
                          'n/a',
                          ['_price', order._price.displayed_currency, 'unit'],
                          order
                        )}
                      </span>
                    </>
                  )}
                  {canViewCustomerPrices && (
                    <button
                      className="view-prices"
                      onClick={() => setPricesDrawerVis(true)}
                    >
                      <span>
                        <FormattedMessage
                          id="marketrow-orderdetails-field-customerprices-label"
                          description="Label for `Customer prices` field on Order details in Marketrow"
                          defaultMessage="Customer prices"
                        />
                      </span>
                    </button>
                  )}
                </>
              ) : null}
            </div>

            <div className="unit-detail unit-detail-volume">
              <h4>
                <FormattedMessage
                  id="marketrow-orderdetails-field-volume-label"
                  description="Label for `Volume` field on Order details in Marketrow"
                  defaultMessage="Volume"
                />
              </h4>

              <div>
                <div className="block">
                  <FNumber
                    value={order.volume.available}
                    decimalCount={order.volumeDecimalCount}
                  />{' '}
                  <span>
                    {product
                      ? R.path(['loadingunit', 'desc'], product)
                      : R.pathOr(
                          R.path(['volume', 'unit'], order),
                          [R.path(['volume', 'unit'], order)],
                          unitsOfMeasure
                        )}
                  </span>
                </div>
                {order.totaldeliveries && (
                  <>
                    <span className="qrts inline-block border mt-7 ml--5 nowrap">
                      {order.totaldeliveries} &times;{' '}
                      <FNumber
                        value={order.volume.delivery}
                        decimalCount={order.volumeDecimalCount}
                      />{' '}
                      {order.deliveryfrequency}
                    </span>
                  </>
                )}
                {order.quarteretd && (
                  <>
                    <span className="qrts inline-block border mt-7 ml--5 nowrap">
                      <FNumber
                        value={order.volume.available / 3}
                        decimalCount={order.volumeDecimalCount}
                      />{' '}
                      <FormattedMessage
                        id="marketrow-field-etd-monthly"
                        description="'monthly' label for 'etd' field in Marketrow"
                        defaultMessage="monthly"
                      />
                    </span>
                  </>
                )}
              </div>
            </div>

            {product ? (
              <>
                {order.price.val > 0 && !order.index && (
                  <div className="unit-detail unit-detail-value">
                    <h4>
                      <FormattedMessage
                        id="marketrow-orderdetails-field-value-label"
                        description="Label for `Value` field on Order details in Marketrow"
                        defaultMessage="Value"
                      />
                    </h4>
                    <FNumber
                      value={product._getValue(
                        order.getPriceObjByCur(curCur).val,
                        order.volume.available,
                        order.loading.id
                      )}
                      decimalCount={2}
                    />{' '}
                    <span>{order.getPriceObjByCur(curCur).currency}</span>
                  </div>
                )}
              </>
            ) : (
              <>
                {order.value && (
                  <div className="unit-detail unit-detail-value">
                    <h4>
                      <FormattedMessage
                        id="marketrow-orderdetails-field-value-label"
                        description="Label for `Value` field on Order details in Marketrow"
                        defaultMessage="Value"
                      />
                    </h4>
                    <FNumber
                      value={R.path(['value', 'total'], order)}
                      decimalCount={2}
                    />{' '}
                    <span>{R.path(['value', 'unit'], order)}</span>
                  </div>
                )}
              </>
            )}

            <>
              {('buy' in tradeActions || 'sell' in tradeActions) &&
                (order.is_owner || order.is_broker) && (
                  <OwnerButtons order={order} />
                )}
              <PrepopulateButtons order={order} />
            </>
          </div>

          {product && (
            <div className="trade-options">
              <Tabs
                order={order}
                product={product}
                setCurCur={setCurCur}
                doRefresh={doRefresh}
              />
            </div>
          )}
        </div>

        <div className="table-box-three">
          <Comment record={order} />
          <div className="box">
            {order.attachments && order.attachments.length > 0 && (
              <Documents attachments={order.attachments} />
            )}
            <LoadingDetails
              loading={order.loading}
              unit={R.path(['loadingunit', 'desc'], product)}
            />
            <Locations fromaddr={order.fromaddr} toaddr={order.toaddr} />
          </div>

          <div className="box">
            <ExtraDetails record={order} />

            <Counterparties order={order} />
            <Contact order={order} />

            {order.partners && (
              <>
                {'consignee' in order.partners && (
                  <Partner partners={order.partners} type="consignee" />
                )}
                {'buyer' in order.partners && (
                  <Partner partners={order.partners} type="buyer" />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
