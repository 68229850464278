import * as R from 'ramda';
import { useMemo } from 'react';
import { Order } from '~/models';
import { useStoreState } from '~/store';

function selectDirFn(sortDirection: string) {
  if (sortDirection === 'up') {
    return R.ascend;
  }
  if (sortDirection !== 'down') {
    // TODO: Notify error!?
  }
  return R.descend;
}

function getSorter(
  sortDirection: string,
  sortColumn: string
): <T>(list: T[]) => T[] {
  const dirFn = selectDirFn(sortDirection);
  switch (sortColumn) {
    case 'etd-from':
      return R.sortWith([
        dirFn(R.path(['etd', 'from'])),
        dirFn(R.path(['updated'])),
      ]);
    case 'etd-to':
      return R.sortWith([
        dirFn(R.path(['etd', 'to'])),
        dirFn(R.path(['updated'])),
      ]);
    case 'origins':
      return R.sortWith([
        dirFn(R.path(['origins', 0, 'name'])),
        dirFn(R.path(['updated'])),
      ]);
    case 'locations':
      return R.sortWith([
        dirFn(R.path(['locations', 0, 'name'])),
        dirFn(R.path(['updated'])),
      ]);
    case 'product':
      return R.sortWith([
        dirFn(R.path(['product', 'name'])),
        dirFn(R.path(['updated'])),
      ]);
    case 'created':
      return R.sortWith([dirFn(R.path(['created']))]);
    case 'ref-price':
      return R.sortWith([
        dirFn(R.path(['price', 'std', 'unit'])),
        dirFn(R.path(['price', 'std', 'price'])),
        dirFn(R.path(['updated'])),
      ]);
    case 'price':
      return R.sortWith([
        dirFn(R.path(['price', 'unit'])),
        dirFn(R.path(['price', 'val'])),
        dirFn(R.path(['updated'])),
      ]);
    case 'state':
      return R.sortWith([dirFn(R.path(['state'])), dirFn(R.path(['updated']))]);
    default:
      // TODO: Notify error!?
      return list => list;
  }
}

export default function useSortedData() {
  const sortColumn = useStoreState(state => state.market.sortColumn);
  const sortDirection = useStoreState(state => state.market.sortDirection);
  const orders: Order[] = useStoreState(state => state.market.orders);
  const productById = useStoreState(state => state.auth.productById);
  const hasRole = useStoreState(state => state.auth.hasRole);

  return useMemo(() => {
    if (orders === null) return null;
    const data = orders.map(order => {
      const product = productById(order.product.id);

      let state = 'viewonly';
      if (product) {
        state =
          hasRole('trader') || hasRole('manager')
            ? order.counteronly && !order.is_owner
              ? 'sfc-tradeable'
              : order.tradeable
              ? 'tradeable'
              : 'untradeable'
            : 'untradeable';
      }
      return {
        ...order,
        state,
        key: order.id,
      };
    });

    const sorter = getSorter(sortDirection, sortColumn);
    return sorter(data);
  }, [orders, sortColumn, sortDirection, productById]);
}
