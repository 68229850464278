import React, { Fragment, useEffect } from 'react';
import { useSiteTitle } from '~/hooks';
import Table from 'Components/NuiTable';
import * as R from 'ramda';
import type { ColumnProps } from 'antd/lib/table';
import { Participant, useParticipantsTable } from '~/store/models';
import classnames from 'classnames';
import {
  Tooltip,
  Button,
  Switch,
  Input,
  Alert,
  Loading,
  Select,
} from 'Components/nui';
import YesNo from 'Components/YesNo';
import { WithId } from '~/store/models/types';
import {
  Authorised,
  ACL,
  useAuthorisation,
  Denied,
} from 'Components/Authorised';
import { capitalize, inArrayIf } from '~/utils';
import TableFilter from 'Components/TableFilter';
import FieldHolder from 'Components/nui/form/FieldHolder';
import DropCheck from 'Components/nui/form/DropCheck';
import { useStoreState } from '~/store';
import { toast } from 'react-toastify';
import Helmet from 'Components/Helmet';

type ColProps = ColumnProps<Participant>;

interface ICell {
  children: React.ReactNode;
  index: number;
}
const Cell = ({ children, index, ...rest }: ICell) => (
  <td {...rest}>
    <div className="td-content">{children}</div>
  </td>
);

interface IParticipants {
  fetch: () => Participant[];
  loading: (m?: WithId) => boolean;
  toggleAcceptance: (p: Participant) => Promise<void>;
  changePaymentTerm: (v: string, p: Participant) => Promise<void>;
  sendTradeRequest: (p: Participant) => Promise<void>;
  _acl: ACL;
}
interface Pagination {
  total: () => number;
  pages: () => number;
  page: () => number;
  limit: () => number;
  changePage: (c: { value: number }) => void;
  changeLimit: (c: { value: number }) => void;
}
interface IParticipantsTable {
  participants: IParticipants;
  pagination: Pagination;
}
function ParticipantsTable({ participants, pagination }: IParticipantsTable) {
  const [managementaccess, isManager] = useStoreState(state => [
    state.auth.solutionSettings.managementaccess,
    state.auth.hasRole('manager'),
  ]);
  const { paymentterms } = useStoreState(state => state.auth.solution);
  const { can, cant } = useAuthorisation();
  const cname = (col: string, ...extra: any) =>
    classnames(`col-${col}`, ...extra);

  const canNotManage = managementaccess && !isManager;

  const columns: ColProps[] = [
    {
      title: 'Participant',
      key: 'active',
      className: cname('participant', { viewer: cant('edit', participants) }),
      render: (text, participant) => (
        <div>
          <Authorised to="edit" model={participants}>
            <Authorised to="edit" model={participant}>
              <Switch
                loading={participants.loading(participant)}
                checked={participant.us.docsapproved}
                disabled={
                  !participant.us.editable ||
                  participants.loading(participant) ||
                  canNotManage
                }
                onChange={() => participants.toggleAcceptance(participant)}
              />
            </Authorised>
            <Denied to="edit" model={participant}>
              <button className="view-only">Viewer</button>
            </Denied>
          </Authorised>
          {participant.name}
          <span className="block smaller">
            <a href={participant.website}>{participant.website}</a>
          </span>
        </div>
      ),
    },
    {
      title: <span className="icon-globe" title="location" />,
      key: 'location',
      className: cname('country-code'),
      render: (text, participant) => (
        <span className="country-code">
          <Tooltip
            title={
              <span className="icon-tooltip">
                <span className="icon-info-circled" />
                {participant.address.country.name}
              </span>
            }
          >
            {participant.address.country.name}
          </Tooltip>
        </span>
      ),
    },
    ...inArrayIf(can('edit', participants), {
      title: 'Accepted by them',
      key: 'accepted',
      className: cname('accepted'),
      render: (text: string, participant: Participant) => (
        <YesNo yes={participant.them.docsapproved} />
      ),
    }),
    {
      title: 'Trade relation',
      key: 'relation',
      className: cname('relation'),
      render: (text, participant) => (
        <>{participant.tradeRelation.map(x => capitalize(x)).join(' & ')}</>
      ),
    },
    {
      title: 'Sale trades payment term',
      key: 'sell-payment-term',
      className: cname('sell-payment-term'),
      render: (text, participant) => {
        const disabled =
          !participant.us.editable ||
          participants.loading(participant) ||
          canNotManage;

        if (
          disabled ||
          !participant.tradeRelation.includes('sell') ||
          !paymentterms ||
          paymentterms.length === 1
        ) {
          return participant.sellData.paymentterm?.desc;
        }

        return (
          <Authorised to="edit" model={participants}>
            <Authorised to="edit" model={participant}>
              <div className="nui-fieldset small p-0">
                <div className="select-holder">
                  <Select
                    className=""
                    value={participant.sellData.paymentterm?.id || ''}
                    onChange={value =>
                      participants.changePaymentTerm(value, participant)
                    }
                  >
                    {!participant.sellData.paymentterm && (
                      <option value="">&nbsp;</option>
                    )}
                    {paymentterms.map(item => (
                      <option value={item.id} key={item.id}>
                        {item.desc}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </Authorised>
            <Denied to="edit" model={participant}>
              {participant.sellData.paymentterm?.desc}
            </Denied>
          </Authorised>
        );
      },
    },
    {
      title: 'Purchase trades payment term',
      key: 'buy-payment-term',
      className: cname('buy-payment-term'),
      render: (text, participant) => participant.buyData.paymentterm?.desc,
    },
    {
      title: 'Company no',
      key: 'vat',
      className: cname('vat'),
      render: (text, participant) => participant.companyno,
    },
    {
      title: 'Full address',
      key: 'address',
      className: cname('address'),
      render: (text, participant) => (
        <address>{participant.address.description}</address>
      ),
    },
    ...inArrayIf(can('edit', participants), {
      title: 'Trade request status',
      key: 'status',
      className: cname('status'),
      render: (text: string, participant: Participant) =>
        can('edit', participant) && (
          <div>
            {!participant.them.docsapproved &&
              (participant.them.requested ? (
                <div>
                  <strong className="smaller all-black">
                    Trade request sent on
                  </strong>
                  <div className="block nowrap">
                    {participant.them.requested.format('DD MMMM YYYY HH:mm')}
                  </div>
                </div>
              ) : (
                <>
                  {participant.tradeRelation.length > 0 && (
                    <div className="block">
                      <Button
                        type="secondary"
                        onClick={() =>
                          participants.sendTradeRequest(participant)
                        }
                        loading={participants.loading(participant)}
                        disabled={participants.loading(participant)}
                      >
                        Send trade request
                      </Button>
                    </div>
                  )}
                </>
              ))}
            {!participant.us.docsapproved && participant.us.requested && (
              <div>
                <strong className="smaller all-black">
                  Trade request received on
                </strong>
                <div className="block nowrap">
                  {participant.us.requested.format('DD MMMM YYYY HH:mm')}
                </div>
              </div>
            )}
          </div>
        ),
    }),
    ...inArrayIf(can('edit', participants), {
      title: 'Comment',
      key: 'note',
      className: cname('note'),
      render: (text: string, participant: Participant) =>
        participant.us.updated &&
        !participant.us.editable && (
          <>
            You will be unable to re-select this counterparty prior to{' '}
            {participant.us.updated.add(7, 'days').format('DD MMMM YYYY HH:mm')}
          </>
        ),
    }),
  ];

  const components = { body: { cell: Cell } };

  return (
    <Table
      className="generic-table"
      columns={columns}
      dataSource={R.sort(
        (a, b) => a.name.localeCompare(b.name),
        participants.fetch()
      )}
      loading={{
        indicator: <Loading className="mb-10 mt-10" size="medium" />,
        spinning: !participants.fetch().length && participants.loading(),
      }}
      rowKey={R.prop('id')}
      components={components}
      pagination={{
        size: 'small',
        showSizeChanger: true,
        showTotal: (total, range) =>
          `${range[0]} - ${range[1]} of ${total} items`,
        pageSize: pagination.limit(),
        total: pagination.total(),
        current: pagination.page(),
      }}
      onChange={page => {
        pagination.changePage({ value: R.propOr(0, 'current', page) });
        pagination.changeLimit({ value: R.propOr(0, 'pageSize', page) });
      }}
    />
  );
}

export default function Participants() {
  useSiteTitle('Participants');

  const [solution, toastId, solutionSettings] = useStoreState(state => [
    state.auth.solution,
    state.auth.participantToast,
    state.auth.solutionSettings,
  ]);
  const { filters, pagination, ...participants } = useParticipantsTable();
  const canManageDivisions = useStoreState(
    state => state.auth.canManageDivisions
  );
  const managementaccess = R.pathOr(
    true,
    ['managementaccess'],
    solutionSettings
  );

  const plural =
    R.pathOr(0, ['new_participants'], solution) > 1
      ? 'participants'
      : 'participant';
  const pluralV =
    R.pathOr(0, ['new_participants'], solution) > 1 ? 'are' : 'is';
  const dismissed = filters.value('dismissed');

  useEffect(() => {
    toast.dismiss(toastId);
  }, [toastId]);

  return (
    <Fragment>
      <Helmet>
        <title>Participants</title>
      </Helmet>
      <div className="full-page-header">
        <h1 className="inline w-200 mt-10 mb-0">Participants</h1>

        <TableFilter
          filters={filters}
          total={pagination.total()}
          loading={participants.loading()}
        >
          <FieldHolder name="name" label="Search participant">
            <div className="search-holder">
              <Input
                placeholder="Search"
                type="search"
                value={filters.value('name')}
                onChange={e => {
                  filters.filter('name', e?.target.value || '');
                }}
              />
            </div>
          </FieldHolder>
          <FieldHolder name="companyno" label="Search Company no">
            <div className="search-holder">
              <Input
                placeholder="Search"
                type="search"
                value={filters.value('companyno')}
                onChange={e => {
                  filters.filter('companyno', e?.target.value || '');
                }}
              />
            </div>
          </FieldHolder>
          <Authorised to="edit" model={participants}>
            <FieldHolder name="theiraccept" label="Accepted by them">
              <DropCheck
                name="theiraccept"
                title="Any"
                options={[
                  [1, 'Yes'],
                  [0, 'No'],
                ]}
                value={filters.value('theiraccept')}
                onChange={filters.filter}
              />
            </FieldHolder>
          </Authorised>
          <Authorised to="edit" model={participants}>
            <FieldHolder name="ouraccept" label="Accepted by us">
              <DropCheck
                name="ouraccept"
                title="Any"
                options={[
                  [1, 'Yes'],
                  [0, 'No'],
                ]}
                value={filters.value('ouraccept')}
                onChange={filters.filter}
              />
            </FieldHolder>
          </Authorised>
        </TableFilter>
      </div>
      <div className="full-page-content participants">
        {R.pathOr(0, ['new_participants'], solution) > 0 && (
          <div className="nui-row pt-20 pb-20">
            <Alert className="has-icon">
              <div className="flex spaced">
                <div className="pt-2">
                  {dismissed ? 'Viewing' : `There ${pluralV}`}{' '}
                  {solution.new_participants} new {plural}. Review your trading
                  status for all new participants. De-select any participants
                  that you do not want to trade with.
                </div>

                <div className="nowrap ml-10">
                  {!dismissed && (
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        filters.filter('dismissed', '0');
                      }}
                    >
                      View
                    </Button>
                  )}
                  {(!managementaccess ||
                    (managementaccess && canManageDivisions.length > 0)) && (
                    <Authorised to="edit" model={participants}>
                      <Button
                        type="secondary"
                        size="small"
                        className="ml-10 mr--5"
                        loading={participants.loading()}
                        disabled={participants.loading()}
                        onClick={async () => {
                          await participants.dismiss();
                          filters.reset();
                        }}
                      >
                        Review complete
                      </Button>
                    </Authorised>
                  )}
                </div>
              </div>
            </Alert>
          </div>
        )}
        <ParticipantsTable
          participants={participants}
          pagination={pagination}
        />
      </div>
    </Fragment>
  );
}
